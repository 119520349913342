import React, { useEffect } from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { useAppState } from '../../../../state';
import { useAudioOutputDevices } from '../deviceHooks/deviceHooks';

export default function AudioOutputList() {
  const audioOutputDevices = useAudioOutputDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  useEffect(() => {
    if (activeSinkId === 'default') {
      setActiveSinkId('default')
    } else {
      let lost = true
      audioOutputDevices.map(device => {
        if (device.deviceId === activeSinkId) {
          lost = false
        }
      })
      if (lost) {
        setActiveSinkId('default')
      }
    }
  }, [audioOutputDevices.length])

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 0 ? (
        <FormControl fullWidth>
          <Typography variant="h6">Speaker:</Typography>
          <span className="input-dropdown-wrap" style={{margin: 0}}>
              <select onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId}>
                {audioOutputDevices.map(device => (
                  <option value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </option>
                ))}
              </select>
          </span>
        </FormControl>
      ) : (
        <FormControl fullWidth>
          <Typography variant="h6">Speaker:</Typography>
          <span className="input-dropdown-wrap" style={{margin: 0}}>
              <select onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId}>
                  <option>
                    {activeOutputLabel || 'System Default Audio Output'}
                  </option>
              </select>
          </span>
        </FormControl>
      )}
    </div>
  );
}
