import { useEffect, useState } from 'react';
import { LocalTrackPublication, Participant, RemoteTrackPublication, RemoteVideoTrackPublication } from 'twilio-video';
import { ShowToast } from '../../../../commons/Helpers';
import { f7 as app } from 'framework7-react';
import useVideoContext from '../useVideoContext/useVideoContext';
import { useAppState } from '../../state';


type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

export default function usePublications(participant: Participant) {
  const [publications, setPublications] = useState<TrackPublication[]>([]);
  const { room: { localParticipant } } = useVideoContext()
  const {setIsRemoteMuteVideo} = useAppState();

  useEffect(() => {
    if (!participant) {
      return
    }

    // Reset the publications when the 'participant' variable changes.
    setPublications(Array.from(participant.tracks.values()) as TrackPublication[]);

    const publicationAdded = (publication: TrackPublication) => {
      if (participant !== localParticipant && publication.trackName.indexOf('camera') === 0) {
        setIsRemoteMuteVideo(false);
      }
      setPublications(prevPublications => [...prevPublications, publication]);
    }
    const publicationRemoved = (publication: TrackPublication) => {
      // console.log('fix', participant === localParticipant, publication)
      if (participant !== localParticipant && publication.trackName.indexOf('camera') === 0) {
        setIsRemoteMuteVideo(true);
        // ShowToast(app, "User has turned off video");
      }
      setPublications(prevPublications => prevPublications.filter(p => p !== publication));
    }

    participant.on('trackPublished', publicationAdded);
    participant.on('trackUnpublished', publicationRemoved);
    return () => {
      participant.off('trackPublished', publicationAdded);
      participant.off('trackUnpublished', publicationRemoved);
    };
  }, [participant]);

  return publications;
}
