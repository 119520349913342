import React from 'react';
import ReactDOM from 'react-dom';
import 'framework7/css/framework7.min.css';
import 'framework7/css/framework7.bundle.min.css';
// import 'framework7/css/framework7.rtl.css';
import 'framework7-icons/css/framework7-icons.css'


import './index.css';
// Import F7 Bundle
import Framework7 from 'framework7/framework7-lite.esm.bundle.js';

// Import F7-React Plugin
import Framework7React from 'framework7-react';
// Init F7-React Plugin
import { Provider } from "react-redux";
import { store } from "./redux/store";
// import { PersistGate } from 'redux-persist/lib/integration/react';

import App from './App';
import Setpw from './screens/Setpw'
import { HashRouter as Router, Route, Switch} from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import UnsupportedBrowserWarning
    from "./components/twilio/components/UnsupportedBrowserWarning/UnsupportedBrowserWarning";
Framework7.use(Framework7React);

const Root = () => (
    <UnsupportedBrowserWarning>
    <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
            <Router>
                <Switch>
                    <Route exact path="/" component={App} />
                    <Route exact path="/payback" component={App} />
                    <Route exact path="/googleConnect" component={App} />
                    <Route exact path="/index.html" component={App} />
                    <Route exact path="/rpw/:token" component={Setpw} />
                    <Route exact path="/Admin/:route/:userId" render={(renderProps) => <App isAdminPage {...renderProps} />} />
                </Switch>
            </Router>
        {/* </PersistGate> */}
    </Provider>
    </UnsupportedBrowserWarning>
);

ReactDOM.render(
    Root(), 
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
