import React from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useMainSpeaker from '../../hooks/useMainSpeaker/useMainSpeaker';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';

const Container = styled('aside')(({ theme }) => ({
  padding: '0.5em',
  overflowY: 'auto',
  [theme.breakpoints.down('xs')]: {
    overflowY: 'initial',
    overflowX: 'auto',
    padding: 0,
    display: 'flex',
  },
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
  },
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const participants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const mainParticipant = useMainSpeaker();
  const screenShareParticipant = useScreenShareParticipant();

  let disableAudio = false
  if (mainParticipant === localParticipant || mainParticipant === screenShareParticipant) {
    disableAudio = true
  }

  const defaultHandler = () => {
    if (selectedParticipant === localParticipant && participants.length === 0) {
      setSelectedParticipant(localParticipant)
    }
  }

  return (
    <div className="video-strip" onClick={defaultHandler}>
      {selectedParticipant === localParticipant
          ? participants.map(participant => (
              <Participant
                  key={participant.sid}
                  disableAudio={!disableAudio}
                  participant={participant}
                  isSelected={selectedParticipant === participant}
                  onClick={() => setSelectedParticipant(participant)}
              />
          ))
          : <Participant
              participant={localParticipant}
              disableAudio={!disableAudio}
              isSelected={selectedParticipant === localParticipant}
              onClick={() => setSelectedParticipant(localParticipant)}
          />
      }
      {/*<Participant*/}
      {/*    participant={localParticipant}*/}
      {/*    isSelected={selectedParticipant === localParticipant}*/}
      {/*    onClick={() => setSelectedParticipant(localParticipant)}*/}
      {/*/>*/}
      {/*<ScrollContainer>*/}
      {/*  <Participant*/}
      {/*    participant={localParticipant}*/}
      {/*    isSelected={selectedParticipant === localParticipant}*/}
      {/*    onClick={() => setSelectedParticipant(localParticipant)}*/}
      {/*  />*/}
      {/*  {participants.map(participant => (*/}
      {/*    <Participant*/}
      {/*      key={participant.sid}*/}
      {/*      participant={participant}*/}
      {/*      isSelected={selectedParticipant === participant}*/}
      {/*      onClick={() => setSelectedParticipant(participant)}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</ScrollContainer>*/}
    </div>
  );
}
