import React from 'react';
import { styled } from '@material-ui/core/styles';
import Net0 from '../../../../images/videoCall/network0.png';
import Net1 from '../../../../images/videoCall/network1.png';
import Net2 from '../../../../images/videoCall/network2.png';
import Net3 from '../../../../images/videoCall/network3.png';
import Net4 from '../../../../images/videoCall/network4.png';
import Net5 from '../../../../images/videoCall/network5.png';

const Container = styled('div')({
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  '& div': {
    width: '3px',
    // border: '1px solid black',
    boxSizing: 'content-box',
    '&:not(:last-child)': {
      borderRight: 'none',
    },
  },
});

const STEP = 4;

export default function NetworkQualityLevel({ qualityLevel }: { qualityLevel: number | null }) {
  if (qualityLevel === null) return null;
  return <img src={[Net0, Net1, Net2, Net3, Net4, Net5][qualityLevel]} />
  // return (
  //   <Container>
  //     {[0, 1, 2, 3, 4].map(level => (
  //       <div
  //         key={level}
  //         style={{
  //           height: `${STEP * (level + 1)}px`,
  //           background: qualityLevel > level ? '#26AEB8' : '#ffffff',
  //           marginRight: '2px'
  //         }}
  //       />
  //     ))}
  //   </Container>
  // );
}
