export const GOOGLEMAP_API = process.env.REACT_APP_GEOCODING_URL;
export const GOOGLEMAP_KEY = process.env.REACT_APP_GMAP_KEY;

export const CountryServiceApiSuccessCode = {
    countryListSuccess: 1002,
    provinceListSuccess: 1032,
    cityListSuccess: 1042,
}

export const  CountryServiceApi = {
    countryList: 'country_service/country/list',
    provinceList: 'country_service/country/province/get',
    cityList: 'country_service/city/getbyprovincecode',
    getAddressLatLong: GOOGLEMAP_API
}
