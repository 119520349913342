import React from "react";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function PDFViewer({ iframeUrl, handleAskPassword }) {
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
      <div className="viewPdf">
        <Viewer
          fileUrl={iframeUrl}
          onDocumentAskPassword={handleAskPassword}
          plugins={[defaultLayoutPluginInstance]}
        />
      </div>
    </Worker>
  );
}

export default PDFViewer;
