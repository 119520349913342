import React, { Component } from 'react';
import { f7 as app, Row, Col, Icon, ListInput, f7ready, Block, Button, List, Page, Popup } from 'framework7-react';

// 引入 ECharts 主模块
import echarts from 'echarts/lib/echarts';
// 引入柱状图
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
// 引入折线图
import 'echarts/lib/chart/line';

import 'echarts/lib/chart/custom';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import 'echarts/lib/component/toolbox';
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markArea'

import MenuIcon from '../images/icons/menuList.svg'
import HeartIcon from '../images/icons/ihb.svg'
import FaceIcon1 from '../images/graph/face1.svg'
import FaceIcon2 from '../images/graph/face2.svg'
import FaceIcon3 from '../images/graph/face3.svg'
import FaceIcon4 from '../images/graph/face4.svg'
import FaceIcon5 from '../images/graph/face5.svg'
import MemoNote from '../images/graph/memo_notes.png'
import FetchData from '../commons/fetchData'
import {
  GetMinMaxPressure,
  GetMinMaxPulse,
  GetMinMaxBMI,
  GetMinMaxWeight,
  GetMinMaxBloodGlucose,
  GetMinMaxSleep,
  GetMinMaxStep,
  GetMinMaxTemperature,
  GetMinMaxSpo2,
  GetSomeDaysRangeDate,
  GetConvertDates,
  DispalyDateTime,
  FirstCaseUpper,
  ShowToast,
  showDate,
} from '../commons/Helpers'
import AuthGuard from '../services/AuthGuard'
import moment from 'moment';
import green from '../images/icons/green.png';
import grey from '../images/icons/grey.png';


const FaceIcons = [
  FaceIcon1,
  FaceIcon2,
  FaceIcon3,
  FaceIcon4,
  FaceIcon5
]
const colorList = [
  new echarts.graphic.LinearGradient( //Colour light pink from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#FFC4E6'
    }, {
      offset: 1,
      color: '#FF91C8'
    }]),

  new echarts.graphic.LinearGradient( //Colour pink from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#E02381'
    }, {
      offset: 1,
      color: '#C23579'
    }]),

  new echarts.graphic.LinearGradient( //Colour light green from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#C6F5F8'
    }, {
      offset: 1,
      color: '#97E1E6'
    }]),

  new echarts.graphic.LinearGradient( //Colour green from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#27AEB8'
    }, {
      offset: 1,
      color: '#00989E'
    }]),

  new echarts.graphic.LinearGradient( //watermelon red from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#FF8B8B'
    }, {
      offset: 1,
      color: '#FF5252'
    }]),

  new echarts.graphic.LinearGradient( //purple from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#D7B7FF'
    }, {
      offset: 1,
      color: '#AE81FF'
    }]),

  new echarts.graphic.LinearGradient( //Temperature x1 from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#A1D1FF'
    }, {
      offset: 1,
      color: '#2097FF'
    }]),

  new echarts.graphic.LinearGradient( //Temperature x2 from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#FF5252'
    }, {
      offset: 1,
      color: '#D70000'
    }]),

  new echarts.graphic.LinearGradient( //Spo2 x1 from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#CFEAFF'
    }, {
      offset: 1,
      color: '#A1D1FF'
    }]),

  new echarts.graphic.LinearGradient( //Spo2 x2 from top to bottom
    0, 0, 0, 1, [{
      offset: 0,
      color: '#80D1FF'
    }, {
      offset: 1,
      color: '#49A5FD'
    }]),
];


const ratio = 0.08;
class Graph extends Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.props.onRef(this)
    this.state = {
      BPyAxisMin: 0,
      hide_BPyAxisMin: 60,
      BPyAxisMax: 200,
      plusyAxisMin: 40,
      plusyAxisMax: 160,
      hide_plusyAxisMax: 160,
      // mornAssistBpData: [88, 92, 82, 90, 95, 83, 97],
      // morningBPData: [52, 50, 37, 54, 49, 48, 32],
      // evenAssistBpData: [82, 85, 95, 79, 80, 83, 96],
      // evenBpData: [51, 43, 53, 40, 33, 48, 42],
      // pulseData: [[0.92, 50], [1.08, 50], [1.92, 62], [2.08, 68], [2.92, 78], [3.08, 68], [3.92, 68], [4.08, 78], [4.92, 88], [5.08, 78], [5.92, 68], [6.08, 88], [6.92, 78], [7.08, 96]],
      // weightData: [[0.92, 61.5], [1.08, 62.0], [1.92, 62.1], [2.08, 62.4], [2.92, 62.0], [3.08, 62.1], [3.92, 62.0], [4.08, 62.1], [4.92, 61.8], [5.08, 61.5], [5.92, 61.0], [6.08, 61.5], [6.92, 60.5], [7.08, 60.6],],
      // IHData: [[0.92, 1], [1.08, 1], [1.92, '-'], [2.08, '-'], [2.92, 1], [3.08, '-'], [3.92, 1], [4.08, '-'], [4.92, 1], [5.08, 1], [5.92, '-'], [6.08, 1], [6.92, 1], [7.08, 1]],
      // feelingData: [0, 1, 2, 3, 4, 3, 3]
      mornAssistBpData: [],
      morningBPData: [],
      evenAssistBpData: [],
      evenBpData: [],
      pulseData: [],

      IHData: [],
      weightMin: 40,
      weightMax: 90,
      weightData: [],
      bmiMin: 18.5,
      bmiMax: 23.9,
      bmiData: [],
      feelingData: [],
      memoData: [],

      stepsMin: 0,
      stepsMax: 10000,
      stepsData: [],

      sleepMin: 0,
      sleepMax: 10,
      sleepData: [],
      sleepFullData: [],

      bgMin: 4.0,
      bgMax: 14.0,
      bgData: [],
      // bg:
      // normal => < 7.9,
      // diabetic => >11.1

      temperatureMin: 35.5,
      temperatureMax: 38,
      temperatureData: [],

      spo2Min: 95,
      spo2Max: 100,
      spo2Data: [],

      //history data
      weightHistory: [],
      bloodPressureHistory: [],
      stepHistory: [],
      sleepHistory: [],
      bloodGlucoseHistory: [],
      temperatureHistory: [],
      spo2History: [],

      //Average
      bpAverage: {},
      weightAverag: {},
      stepAverag: {},
      sleepAverag: {},
      bloodGlucoseAverag: {},
      temperatureAverage: {},
      spo2Average: {},
      currentDay: false,
      vitals: [],
    };

    this.startDate = GetSomeDaysRangeDate(moment().format('YYYY-MM-DD'), 6, 'before')
    this.endDate = moment().format('YYYY-MM-DD');
    this.xAxisArr = [
      {
        type: 'category',
        show: false,
        splitLine: { show: false },
        axisTick: {
          alignWithLabel: true
        },
        // data: []
      },
      {
        type: 'value',
        show: false,
        splitLine: { show: false },
        axisTick: {
          alignWithLabel: true,
          interval: 0
        },
        axisLine: {
          axisLine: false,
        },
        axisLabel: {
          show: true //是否显示标签
        },
        axisPointer: {
          triggerTooltip: false
        },
        position: 'bottom',
        // offset:10,
        // show:false,
        // splitNumber:70,
        minInterval: 0.5,
        min: 0.5,
        max: 7.5
      }
    ];

    this.BPPulseChart = null;
    this.BPMChart = null
    // this.IrregularHeartbeatChart = null;
    this.WeightChart = null;
    this.BMIChart = null;
    this.StepsChart = null;
    this.FeelingChart = null;
    this.SleepChart = null;
    this.BloodGlucoseChart = null;
    this.Spo2Chart = null;
    this.TemperatureChart = null;
  }

  initData(fetchData) {
    // console.log(fetchData)
    let { patientSysGoal,
      patientDiaGoal,
      patientWeightHGoal,
      patientWeightLGoal,
      patientSPO2HGoal,
      patientSPO2LGoal,
      weightLowerLimit,
      weightUpperLimit,
      bloodPressureHighLowerLimit,
      bloodPressureHighUpperLimit,
      bloodPressureLowLowerLimit,
      bloodPressureLowUpperLimit
    } = this.props;
    // let { result: { vitals, bloodGlucose, sleep, step}} = FetchData;    //开发测试数据    
    // let { vitals, bloodGlucose, sleep, step, bpAverage, weightAverage, stepAverage, sleepAverage, bloodGlucoseAverage, temperature, temperatureAverage, spo2, spo2Average} = FetchData.result;  //开发测试数据    
    // console.log(vitals);
    let { vitals, bloodGlucose, sleep, step, bpAverage, weightAverage, stepAverage, sleepAverage, bloodGlucoseAverage, temperature, temperatureAverage, spo2, spo2Average } = fetchData;

    let _mornAssistBpData = [];
    let _morningBPData = [];
    let _evenAssistBpData = [];
    let _evenBpData = [];
    let _pulseData = [];
    let _weightData = [];
    let _bmiData = [];
    let _IHData = [];
    let _feelingData = [];
    let _memoData = [];

    this.setState({
      bpAverage: bpAverage,
      weightAverage: weightAverage,
      stepAverage: stepAverage,
      sleepAverage: sleepAverage,
      bloodGlucoseAverage: bloodGlucoseAverage,
      temperatureAverage: temperatureAverage,
      spo2Average: spo2Average,
      vitals: vitals
    })

    if (vitals) {
      let preMinMax = GetMinMaxPressure(vitals, [
        patientSysGoal,
        patientDiaGoal,
        bloodPressureHighLowerLimit,
        bloodPressureHighUpperLimit,
        bloodPressureLowLowerLimit,
        bloodPressureLowUpperLimit,
      ]);
      let pulseMinMax = GetMinMaxPulse(vitals)
      let weightMinMax = GetMinMaxWeight(vitals, [
        patientWeightHGoal,
        patientWeightLGoal,
        weightLowerLimit,
        weightUpperLimit
      ]);

      let bmiMinMax = GetMinMaxBMI(vitals)
      console.log(bmiMinMax,'bmiMinMax')

      let rate = preMinMax.minValue / preMinMax.maxValue
      let _pulseMax = ((pulseMinMax.maxValue - pulseMinMax.minValue) / rate) + pulseMinMax.minValue;

      this.setState({
        BPyAxisMin: preMinMax.minValue,
        BPyAxisMax: preMinMax.maxValue,
        plusyAxisMin: pulseMinMax.minValue,
        plusyAxisMax: pulseMinMax.maxValue,
        weightMin: weightMinMax.minValue,
        weightMax: weightMinMax.maxValue,
        bmiMax:bmiMinMax.maxValue,
        bmiMin:bmiMinMax.minValue
      })

      vitals.map((item, index) => {
        let point1 = (index + 1) - ratio;
        let point2 = (index + 1) + ratio;

        let morn_sys = item.morning ? item.morning.systolic : '-';
        let morn_dia = item.morning ? item.morning.diastolic : '-';
        let diff_morn_bp = (morn_sys !== '-' && morn_dia !== '-') ? (morn_sys - morn_dia) : '-';

        _mornAssistBpData.push(morn_dia);
        _morningBPData.push(diff_morn_bp);

        let even_sys = item.evening ? item.evening.systolic : '-';
        let even_dia = item.evening ? item.evening.diastolic : '-';
        let diff_even_bp = (even_sys !== '-' && even_dia !== '-') ? (even_sys - even_dia) : '-';
        _evenAssistBpData.push(even_dia);
        _evenBpData.push(diff_even_bp);

        //pulse
        let morn_pulse = item.morning ? item.morning.pulse : '-';
        let even_pulse = item.evening ? item.evening.pulse : '-';
        _pulseData.push([point1, morn_pulse]);
        _pulseData.push([point2, even_pulse]);

        //weight
        let morn_weight = item.morning ? item.morning.weight : '-';
        let even_weight = item.evening ? item.evening.weight : '-';
        _weightData.push([point1, morn_weight]);
        _weightData.push([point2, even_weight]);

        //bmi
        let morn_bmi = item.morning && item.morning.bmi ? item.morning.bmi : '-';
        let even_bmi = item.evening && item.evening.bmi ? item.evening.bmi : '-';
        _bmiData.push([point1, morn_bmi]);
        _bmiData.push([point2, even_bmi]);

        //IH
        let morn_ih = item.morning && item.morning.ihb ? item.morning.ihb : '-';
        let even_ih = item.evening && item.evening.ihb ? item.evening.ihb : '-';
        _IHData.push([point1, morn_ih]);
        _IHData.push([point2, even_ih]);

        //felling
        let felling = item.conditions && item.conditions.length > 0 ? item.conditions[0].value : '-';
        _feelingData.push(felling);

        //memo
        let memo = item.memo ? item.memo : '';
        _memoData.push(memo);

      });
    }

    let _bloodGlucoseData = [];
    let _sleepData = [];
    let _sleepFullData = [];
    let _stepData = [];

    if (bloodGlucose) {
      let bloodGlucoseMinMax = GetMinMaxBloodGlucose(bloodGlucose);
      this.setState({
        bgMin: bloodGlucoseMinMax.minValue,
        bgMax: bloodGlucoseMinMax.maxValue,
      })

      Object.keys(bloodGlucose).forEach((item, index) => {
        let point1 = (index + 1) - ratio;
        let point2 = (index + 1) + ratio;

        let morn_bg = bloodGlucose[item] && bloodGlucose[item].am !== 0 && bloodGlucose[item].am !== undefined ? bloodGlucose[item].am : '-';
        let even_bg = bloodGlucose[item] && bloodGlucose[item].pm !== 0 && bloodGlucose[item].pm !== undefined ? bloodGlucose[item].pm : '-';
        _bloodGlucoseData.push([point1, morn_bg]);
        _bloodGlucoseData.push([point2, even_bg]);

      });
    }

    if (sleep) {
      let sleepMinMax = GetMinMaxSleep(sleep);
      this.setState({
        // sleepMin: sleepMinMax.minValue, // start 0
        sleepMax: sleepMinMax.maxValue,
      })

      Object.keys(sleep).forEach((item, index) => {
        let duration = sleep[item] && sleep[item].duration !== undefined ? sleep[item].duration / 60 : '-';
        _sleepFullData.push(sleep[item]);
        _sleepData.push(duration);
      });
    }

    if (step) {
      let stepMinMax = GetMinMaxStep(step);
      this.setState({
        // stepsMin: stepMinMax.minValue,  start 0
        stepsMax: stepMinMax.maxValue,
      })

      Object.keys(step).forEach((item, index) => {
        let _step = step[item] && step[item].step !== 0 && step[item].step !== '0' && step[item].step !== '0.0' && step[item].step !== '0.00' && step[item].step !== undefined ? step[item].step : '-';
        _stepData.push(_step);
      });
    }

    let _temperatureData = [];
    let _spo2Data = [];

    if (temperature) {
      let temperatureMinMax = GetMinMaxTemperature(temperature);
      this.setState({
        temperatureMin: temperatureMinMax.minValue,
        temperatureMax: temperatureMinMax.maxValue,
      })

      Object.keys(temperature).forEach((item, index) => {
        let point1 = (index + 1) - ratio;
        let point2 = (index + 1) + ratio;

        let morn_tem = temperature[item] && temperature[item].am !== 0 && temperature[item].am !== undefined ? temperature[item].am : '-';
        let even_tem = temperature[item] && temperature[item].pm !== 0 && temperature[item].pm !== undefined ? temperature[item].pm : '-';
        _temperatureData.push([point1, morn_tem]);
        _temperatureData.push([point2, even_tem]);
      });
    }

    if (spo2) {
      let spo2MinMax = GetMinMaxSpo2(spo2, [patientSPO2HGoal, patientSPO2LGoal]);
      this.setState({
        spo2Min: spo2MinMax.minValue,
        spo2Max: spo2MinMax.maxValue
      })

      Object.keys(spo2).forEach((item, index) => {
        let point1 = (index + 1) - ratio;
        let point2 = (index + 1) + ratio;

        let morn_spo2 = spo2[item] && spo2[item].am !== 0 && spo2[item].am !== undefined ? spo2[item].am : '-';
        let even_spo2 = spo2[item] && spo2[item].pm !== 0 && spo2[item].pm !== undefined ? spo2[item].pm : '-';
        _spo2Data.push([point1, morn_spo2]);
        _spo2Data.push([point2, even_spo2]);
      });
    }

    //morn bp
    if (
      _mornAssistBpData.length > 0 &&
      _morningBPData.length > 0
    ) {
      this.setState({
        mornAssistBpData: _mornAssistBpData,
        morningBPData: _morningBPData
      })
    }


    //even bp
    if (
      _evenAssistBpData.length > 0 &&
      _evenBpData.length > 0
    ) {
      this.setState({
        evenAssistBpData: _evenAssistBpData,
        evenBpData: _evenBpData
      });
    }

    //pulse
    if (
      _pulseData.length > 0
    ) {
      this.setState({
        pulseData: _pulseData
      });
    }

    //weight
    if (_weightData.length > 0) {
      this.setState({
        weightData: _weightData
      });
    }

    //bmi
    if (_bmiData.length > 0) {
      this.setState({
        bmiData: _bmiData
      });
    }

    //ih
    if (_IHData.length > 0) {
      this.setState({
        IHData: _IHData
      });
    }

    //felling
    if (_feelingData.length > 0) {
      this.setState({
        feelingData: _feelingData
      });
    }

    //memo
    if (_memoData.length > 0) {
      this.setState({
        memoData: _memoData
      });
    }

    // blood glucose
    if (_bloodGlucoseData.length > 0) {
      this.setState({
        bgData: _bloodGlucoseData
      });
    }

    //sleep
    if (_sleepData.length > 0) {
      this.setState({
        sleepData: _sleepData,
        sleepFullData: _sleepFullData
      });
    }

    //step
    if (_stepData.length > 0) {
      this.setState({
        stepsData: _stepData
      });
    }

    //Temperature
    if (_temperatureData.length > 0) {
      this.setState({
        temperatureData: _temperatureData
      });
    }

    if (_spo2Data.length > 0) {
      this.setState({
        spo2Data: _spo2Data
      });
    }

    this.xAxisArr[0].data = GetConvertDates(this.startDate, this.endDate);
  }

  getFetchData(resolve, reject) {
    this.$f7.dialog.preloader();
    AuthGuard.getGraph({ userProfileId: this.props.userId, startDate: this.startDate, endDate: this.endDate + ' 23:59:59' }, (isOk, res) => {
      this.$f7.dialog.close();
      if (isOk) {
        resolve(res)
      } else {
        reject([]);
      }
    });
  }

  prePage() {
    this.endDate = GetSomeDaysRangeDate(this.startDate, 1, 'before');
    this.startDate = GetSomeDaysRangeDate(this.startDate, 7, 'before');
    let self = this;
    new Promise((resolve, reject) => { this.getFetchData(resolve, reject) }).then(data => {
      if (data.vitals && data.vitals !== undefined) {
        self.initData(data);
        self.buildBPPulseGraph();
        self.buildBPMGraph();
        // self.buildIrregularHeartbeat();
        self.buildWeightGraph();
        self.buildBMIGraph();
        self.buildStepsGraph();
        self.buildFeelingGraph();
        self.buildSleepGraph();
        self.buildBloodGlucoseGraph();
        self.buildTemperatureGraph();
        self.buildSpo2();
      }
    })

  }

  nextPage() {
    if (this.endDate !== moment().format('YYYY-MM-DD')) {
      this.startDate = GetSomeDaysRangeDate(this.endDate, 1, 'after');
      this.endDate = GetSomeDaysRangeDate(this.endDate, 7, 'after');
      let self = this;
      new Promise((resolve, reject) => { this.getFetchData(resolve, reject) }).then(data => {
        if (data.vitals && data.vitals !== undefined) {
          self.initData(data);
          self.buildBPPulseGraph();
          self.buildBPMGraph();
          // self.buildIrregularHeartbeat();
          self.buildWeightGraph();
          self.buildBMIGraph();
          self.buildStepsGraph();
          self.buildFeelingGraph();
          self.buildSleepGraph();
          self.buildBloodGlucoseGraph();
          self.buildTemperatureGraph();
          self.buildSpo2();
        }
      })
    }

  }

  getMeasuredataHistory = (_measureType) => {
    let self = this;
    app.preloader.show();
    AuthGuard.getMeasuredataHistory({ userProfileId: this.props.userId, measureType: _measureType }, (isOk, res) => {
      if (isOk) {
        if (_measureType == 'BPM') {
          app.popover.open('.bloodPressureHistory-popup');
          this.setState({
            bloodPressureHistory: res
          })
        }

        if (_measureType == 'BCM') {
          app.popover.open('.weightHistory-popup');
          this.setState({
            weightHistory: res
          })
        }
      } else {
        this.setState({
          bloodPressureHistory: [],
          weightHistory: [],
        })
      }

      // console.log(this.state.weightHistory);
      app.preloader.hide();
    });
  }


  getStepsHistory = () => {
    app.preloader.show();
    AuthGuard.getStepsHistory({ userProfileId: this.props.userId }, (isOk, res) => {
      if (isOk) {
        // console.log(res);
        this.setState({
          stepHistory: res ? res : []
        }, () => {
          app.popover.open('.stepHistory-popup');
        })
      } else {
        this.setState({
          stepHistory: []
        })
      }
      app.preloader.hide();
    })
  }

  getSleepHistory = () => {
    app.preloader.show();
    AuthGuard.getSleepHistory({ userProfileId: this.props.userId, }, (isOk, res) => {
      if (isOk) {
        // console.log(res);
        this.setState({
          sleepHistory: res ? res : []
        }, () => {
          app.popover.open('.sleepHistory-popup');
        })
      } else {
        this.setState({
          sleepHistory: []
        })
      }
      app.preloader.hide();
    })
  }
  getBloodGlucoseHistory = () => {
    app.preloader.show();
    AuthGuard.getBloodGlucoseHistory({ userProfileId: this.props.userId, }, (isOk, res) => {
      if (isOk) {
        console.log(res);
        this.setState({
          bloodGlucoseHistory: res ? res : []
        }, () => {
          app.popover.open('.bloodGlucoseHistory-popup');
        })
      } else {
        this.setState({
          bloodGlucoseHistory: []
        })
      }
      app.preloader.hide();
    })
  }

  tmpertureHisotry = () => {
    app.preloader.show();
    AuthGuard.tmpertureHisotry({ userProfileId: this.props.userId }, (isOk, res) => {
      console.log(isOk)
      if (isOk) {
        console.log(res);
        this.setState({
          temperatureHistory: res ? res : []
        }, () => {
          app.popover.open('.temperatureHistory-popup');
        })
      } else {
        this.setState({
          temperatureHistory: []
        })
      }
      app.preloader.hide();
    })
  }

  spo2History = () => {
    app.preloader.show();
    AuthGuard.spo2History({ userProfileId: this.props.userId }, (isOk, res) => {
      console.log(res)
      console.log(isOk)
      if (isOk) {
        this.setState({
          spo2History: res ? res : []
        }, () => {
          app.popover.open('.spo2History-popup');
        })
      } else {
        this.setState({
          spo2History: []
        })
      }
      app.preloader.hide();
    })
  }


  componentDidMount() {
    let self = this;
    // console.log(this.props);
    new Promise((resolve, reject) => { this.getFetchData(resolve, reject) }).then(data => {
      if (data.vitals && data.vitals !== undefined) {
        self.initData(data);
        self.buildBPPulseGraph();
        self.buildBPMGraph();
        // self.buildIrregularHeartbeat();
        self.buildWeightGraph();
        self.buildBMIGraph();
        self.buildStepsGraph();
        self.buildFeelingGraph();
        self.buildSleepGraph();
        self.buildBloodGlucoseGraph();
        self.buildTemperatureGraph();
        self.buildSpo2();
      }
    })
  }

  componentWillReceiveProps(nextProps) { }

  reBuildGraphs() {
    this.BPPulseChart.setOption(this.BPPulseChartOptions())
    this.BPMChart.setOption(this.BPMChartOptions())
    this.WeightChart.setOption(this.WeightChartOptions())
    this.BMIChart.setOption(this.BMIChartOptions())
    this.StepsChart.setOption(this.StepsChartOptions())
    this.FeelingChart.setOption(this.FeelingChartOptions())
    this.SleepChart.setOption(this.SleepChartOptions())
    this.BloodGlucoseChart.setOption(this.BloodGlucoseChartOptions())
    this.Spo2Chart.setOption(this.Spo2ChartOptions())
    this.TemperatureChart.setOption(this.TemperatureChartOptions())
  }

  sycnData() {
    let self = this;
    new Promise((resolve, reject) => { this.getFetchData(resolve, reject) }).then(data => {
      if (data.vitals && data.vitals !== undefined) {
        self.initData(data);
        setTimeout(() => self.reBuildGraphs(), 300)
      }
    })
  }

  // setBPMaxMinGraph(patientSysGoal, patientDiaGoal) {
  //     let preMinMax = GetMinMaxPressure(this.state.vitals, [patientSysGoal, patientDiaGoal])

  //     this.setState({
  //         hide_BPyAxisMin: preMinMax.minValue,
  //         BPyAxisMax: preMinMax.maxValue
  //     })

  //     this.BPPulseChart.setOption(
  //         {
  //             yAxis: [
  //                 {
  //                     min: this.state.BPyAxisMin,
  //                     max: this.state.BPyAxisMax,
  //                 }
  //             ]
  //         }
  //     )
  // }
  BPPulseChartOptions() {
    let {
      patientSysGoal,
      patientDiaGoal,
      bloodPressureHighUpperLimit,
      bloodPressureLowLowerLimit,
    } = this.props;
    const {
      BPyAxisMin,
      BPyAxisMax,
      mornAssistBpData,
      morningBPData,
      evenAssistBpData,
      evenBpData,
      pulseData,
    } = this.state;
    return {
      grid: {
        left: "9%",
        right: "9%",
        top: "20%",
        bottom: "8%",
        containLabel: false,
        width: "auto",
        show: false,
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          // console.log(params);
          let tipHtml = "";
          let _systolic_m = 0.0;
          let _diastolic_m = 0.0;

          let _systolic_e = 0.0;
          let _diastolic_e = 0.0;
          let _plugins_m = 0.0;
          let _plugins_e = 0.0;
          let seriesName = params.seriesName;
          if (params.componentIndex === 1 || params.componentIndex === 0)
            return tipHtml;
          let plugins_m_index = 0;
          let plugins_e_index = 0;

          plugins_m_index = params.dataIndex * 2;
          plugins_e_index = params.dataIndex * 2 + 1;

          _plugins_m =
            pulseData[plugins_m_index][1] &&
              typeof pulseData[plugins_m_index][1] !== undefined
              ? pulseData[plugins_m_index][1]
              : "-";
          _plugins_e =
            pulseData[plugins_e_index][1] &&
              typeof pulseData[plugins_e_index][1] !== undefined
              ? pulseData[plugins_e_index][1]
              : "-";

          mornAssistBpData[params.dataIndex] = mornAssistBpData[
            params.dataIndex
          ]
            ? mornAssistBpData[params.dataIndex]
            : "";
          morningBPData[params.dataIndex] = morningBPData[params.dataIndex]
            ? morningBPData[params.dataIndex]
            : "-";

          evenAssistBpData[params.dataIndex] = evenAssistBpData[
            params.dataIndex
          ]
            ? evenAssistBpData[params.dataIndex]
            : "";
          evenBpData[params.dataIndex] = evenBpData[params.dataIndex]
            ? evenBpData[params.dataIndex]
            : "-";

          _systolic_e =
            evenAssistBpData[params.dataIndex] +
            evenBpData[params.dataIndex];
          _diastolic_e = evenAssistBpData[params.dataIndex];

          _systolic_m =
            mornAssistBpData[params.dataIndex] +
            morningBPData[params.dataIndex];
          _diastolic_m = mornAssistBpData[params.dataIndex];

          tipHtml =
            '<div class="pop-model pop-bp-ih"><div class="icon-morning">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            _systolic_m +
            " / " +
            _diastolic_m +
            ' mmHg<div class="icon-ih"> &nbsp;&nbsp; &nbsp;&nbsp; ' +
            _plugins_m +
            ' bpm</div></div><div class="icon-evening">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            _systolic_e +
            " / " +
            _diastolic_e +
            ' mmHg<div class="icon-ih"> &nbsp;&nbsp; &nbsp;&nbsp;  ' +
            _plugins_e +
            " bpm</div></div></div>";
          return tipHtml;
        },
        textStyle: {
          // color:'black'
        },
      },
      title: [
        {
          subtext: "mmHg",
          subtextStyle: {
            fontStyle: "italic",
            color: "#000000",
          },
          itemGap: -5,
        },
      ],
      calculable: true,
      xAxis: this.xAxisArr,
      yAxis: [
        {
          type: "value",
          scale: true,
          axisLabel: {
            margin: 50,
            align: "left",
            show: true,
            textStyle: {
              color: "#333",
            },
          },
          axisTick: {
            show: false,
          },
          axisLine: {
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#EFF0F0",
            },
          },
          splitNumber: 3,
          boundaryGap: false,
          min: BPyAxisMin,
          max: BPyAxisMax,
        },
      ],
      series: [
        {
          name: "assist",
          type: "bar",
          stack: "mornBpToal",
          itemStyle: {
            barBorderColor: "rgba(0,0,0,0)",
            color: "rgba(0,0,0,0)",
          },
          emphasis: {
            itemStyle: {
              barBorderColor: "rgba(0,0,0,0)",
              color: "rgba(0,0,0,0)",
            },
          },
          // data: [88, 92, 82, 90, 95, 83, 97]
          data: mornAssistBpData,
        },
        {
          name: "assist",
          type: "bar",
          stack: "evenBpToal",
          itemStyle: {
            barBorderColor: "rgba(0,0,0,0)",
            color: "rgba(0,0,0,0)",
          },
          emphasis: {
            itemStyle: {
              barBorderColor: "rgba(0,0,0,0)",
              color: "rgba(0,0,0,0)",
            },
          },
          // data: [82, 85, 95, 79, 80, 83, 96]
          data: evenAssistBpData,
        },
        {
          name: "morningBP",
          type: "bar",
          stack: "mornBpToal",
          itemStyle: {
            normal: {
              show: true,
              barBorderRadius: 10,
              barBorderColor: "rgba(0,0,0,0)",
              color: colorList[0],
            },
          },
          barWidth: 15,
          // data: [52, 50, 37, 54, 49, 48, 32]
          data: morningBPData,
        },
        {
          name: "eveningBP",
          type: "bar",
          stack: "evenBpToal",
          yAxisIndex: 0,
          itemStyle: {
            normal: {
              show: true,
              barBorderRadius: 10,
              barBorderColor: "rgba(0,0,0,0)",
              color: colorList[1],
              areaStyle: { type: "default" },
            },
          },
          barWidth: 15, //这个属性只需要在最后一个 bar 里面设置
          barGap: "30%", //这个属性只需要在最后一个 bar 里面设置
          data: evenBpData,
        },
        {
          type: "line",
          silent: true,
          markArea: {
            data: [
              [
                {
                  xAxis: "min",
                  yAxis: bloodPressureLowLowerLimit,
                  itemStyle: {
                    color: "#7ED321",
                    opacity: 0.2,
                  },
                },
                {
                  xAxis: "max",
                  yAxis: bloodPressureHighUpperLimit,
                  itemStyle: {
                    color: "",
                  },
                },
              ],
            ],
          },
          markLine: {
            silent: true,
            symbol: "none",
            data: [
              {
                yAxis: patientSysGoal ? patientSysGoal : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientSysGoal ? 1 : 0,
                },
                label: {
                  show: patientSysGoal ? true : false,
                },
              },
              {
                yAxis: patientDiaGoal ? patientDiaGoal : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientDiaGoal ? 1 : 0,
                },
                label: {
                  show: patientDiaGoal ? true : false,
                },
              },
            ],
          },
        },
      ],
    };
  }

  // Blood Pressure
  buildBPPulseGraph() {
    // 基于准备好的dom，初始化echarts实例
    var BPPulseChart = echarts.init(document.getElementById('BPPulseGraph'));
    BPPulseChart.group = 'BPPulseChartGroup';

    // 绘制图表
    BPPulseChart.setOption(this.BPPulseChartOptions());

    window.addEventListener("resize", function () {
      BPPulseChart.resize();
    });

    this.BPPulseChart = BPPulseChart;

    let self = this;
    BPPulseChart.on('click', function (event) {
      self.linkageEchars('BPPulseChart', event)
    });

    BPPulseChart.on('globalout', function (event) {
      self.unlinkageEchars('BPPulseChart', event)
    });
  }

  BPMChartOptions() {
    const { plusyAxisMin, plusyAxisMax, pulseData } = this.state;
    return {
      grid: {
        left: '9%',
        right: '9%',
        top: '30%',
        bottom: '8%',
        containLabel: false,
        width: 'auto',
        show: false
      },
      title: [
        {
          subtext: 'bpm',
          subtextStyle: {
            fontStyle: 'italic',
            color: '#000000'
          },
          itemGap: -5
        }
      ],
      xAxis: this.xAxisArr[1],
      yAxis: {
        type: 'value',
        scale: true,
        axisLabel: {
          margin: 50,
          align: 'left',
          show: true,
          textStyle: {
            color: '#333'
          },
        },
        axisLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        boundaryGap: false,
        splitNumber: 2,
        splitLine: {
          show: true,
          lineStyle: {
            color: '#EFF0F0'
          }
        },
        min: plusyAxisMin,
        max: plusyAxisMax,
      },
      series: [
        {
          name: 'pulse',
          connectNulls: true,
          type: 'line',
          symbol: 'circle',
          symbolSize: 15,
          silent: true,
          animation: false,
          lineStyle: {
            color: '#F1CFE3',
            width: 2,
            type: 'solid'
          },
          itemStyle: {
            color: function (params) {
              return colorList[params.dataIndex % 2];
            }
          },
          data: pulseData,
        }
      ]
    }
  }

  buildBPMGraph() {

    var BPMChart = echarts.init(document.getElementById('BPMGraph'));
    BPMChart.group = 'BPMChartGroup';
    BPMChart.setOption(this.BPMChartOptions());
    window.addEventListener("resize", function () {
      BPMChart.resize();
    });

    this.BPMChart = BPMChart;

    // let self = this;
    // BPMChart.on('click', function (event) {
    //     self.linkageEchars('BPMChart', event)
    // });

    // BPMChart.on('globalout', function (event) {
    //     self.unlinkageEchars('BPMChart', event)
    // });
  }

  // buildIrregularHeartbeat() {
  //     const { IHData } = this.state;
  //     var IrregularHeartbeatChart = echarts.init(document.getElementById('IHGraph'));
  //     IrregularHeartbeatChart.group = 'IrregularHeartbeatChartGroup';
  //     IrregularHeartbeatChart.setOption({
  //         grid: {
  //             left: '8.8%',
  //             right: '8.5%',
  //             bottom: 0,
  //             top: 0,
  //             containLabel: false,
  //             show: false,
  //         },
  //         xAxis: this.xAxisArr[1],
  //         yAxis: {
  //             name: 'Irregular \nHeartbeat',
  //             nameLocation: 'center',
  //             nameTextStyle: {
  //                 fontStyle: 'oblique',
  //                 color: '#000000',
  //                 fontSize: 10,
  //                 fontWeight: 'bolder',
  //                 align: 'left'
  //             },
  //             nameRotate: 0,
  //             nameGap: 45,
  //             type: 'value',
  //             scale: true,
  //             axisLabel: { show: false, },
  //             axisTick: { show: false, },
  //             boundaryGap: false,
  //             axisLine: { show: false, },
  //             splitLine: { show: false },
  //         },
  //         series: [
  //             {
  //                 symbolSize: [20, 16],
  //                 symbol: 'image://' + HeartIcon,
  //                 name: 'irregularHeartbeat',
  //                 type: "line",
  //                 animation: false,
  //                 lineStyle: {
  //                     color: 'rgb(0,0,0,0)'
  //                 },
  //                 itemStyle: {
  //                     normal: {
  //                         borderWidth: 5
  //                     }
  //                 },
  //                 data: IHData,

  //             }
  //         ]
  //     });
  //     window.addEventListener("resize", function () {
  //         IrregularHeartbeatChart.resize();
  //     });

  //     this.IrregularHeartbeatChart = IrregularHeartbeatChart;

  //     let self = this;
  //     IrregularHeartbeatChart.on('click', function (event) {
  //         self.linkageEchars('IrregularHeartbeatChart', event)
  //     });

  // }

  WeightChartOptions() {
    const { weightMin, weightMax, weightData } = this.state;
    const { patientWeightHGoal, patientWeightLGoal, weightLowerLimit, weightUpperLimit } = this.props
    return {
      grid: {
        left: "8.8%",
        right: "8.5%",
        bottom: "6%",
        top: "15%",
        containLabel: false,
        show: false,
      },
      title: {
        itemGap: -5,
        subtext: "kg",
        subtextStyle: {
          fontStyle: "italic",
          color: "#000000",
        },
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          let tipHtml = "";
          let weight_m = 0.0;
          let weight_e = 0.0;
          let _keyIndex = params.dataIndex;

          if (params.dataIndex % 2) {
            weight_m =
              weightData[_keyIndex - 1] &&
                weightData[_keyIndex - 1][1] !== undefined
                ? weightData[_keyIndex - 1][1]
                : "-";
            weight_e =
              weightData[_keyIndex][1] &&
                weightData[_keyIndex][1] !== undefined
                ? weightData[_keyIndex][1]
                : "-";
          } else {
            weight_m =
              weightData[_keyIndex][1] &&
                weightData[_keyIndex][1] !== undefined
                ? weightData[_keyIndex][1]
                : "-";
            weight_e =
              weightData[_keyIndex + 1] &&
                weightData[_keyIndex + 1][1] !== undefined
                ? weightData[_keyIndex + 1][1]
                : "-";
          }

          tipHtml =
            '<div class="pop-model pop-bp-weight"><div class="icon-morning">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            weight_m +
            ' kg</div><div class="icon-evening">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            weight_e +
            " kg</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[1],
      yAxis: {
        type: "value",
        scale: true,
        axisLabel: {
          margin: 50,
          align: "left",
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameGap: 90,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
        splitNumber: 5,
        min: weightMin,
        max: weightMax,
      },
      series: [
        {
          name: "weight",
          connectNulls: true,
          type: "line",
          symbol: "circle",
          symbolSize: 15,
          animation: false,
          lineStyle: {
            color: "#E5F4F7",
            width: 2,
            type: "solid",
          },
          itemStyle: {
            color: function (params) {
              return colorList[(params.dataIndex % 2) + 2];
            },
          },
          markArea: {
            data: [
              [
                {
                  xAxis: 0,
                  yAxis: weightLowerLimit,
                  itemStyle: {
                    color: "#7ED321",
                    opacity: 0.2,
                  },
                },
                {
                  xAxis: 7.5,
                  yAxis: weightUpperLimit,
                  itemStyle: {
                    color: "",
                  },
                },
              ],
            ],
          },
          markLine: {
            silent: true,
            symbol: "none",
            data: [
              {
                yAxis: patientWeightHGoal ? patientWeightHGoal : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientWeightHGoal ? 1 : 0,
                },
                label: {
                  show: patientWeightHGoal ? true : false,
                },
              },
              {
                yAxis: patientWeightLGoal ? patientWeightLGoal : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientWeightLGoal ? 1 : 0,
                },
                label: {
                  show: patientWeightLGoal ? true : false,
                },
              },
            ],
          },
          data: weightData,
        },
      ],
    };
  }

  buildWeightGraph() {

    var WeightChart = echarts.init(document.getElementById('WeightGraph'));
    WeightChart.group = 'WeightChartGroup';
    WeightChart.setOption(this.WeightChartOptions());
    window.addEventListener("resize", function () {
      WeightChart.resize();
    });
    this.WeightChart = WeightChart;

    let self = this;
    WeightChart.on('click', function (event) {
      self.linkageEchars('WeightChart', event)
    });

    WeightChart.on('globalout', function (event) {
      self.unlinkageEchars('WeightChart', event)
    });
  }

  BMIChartOptions() {
    const { bmiData ,bmiMin, bmiMax } = this.state;
    return {
      grid: {
        left: "8.8%",
        right: "8.5%",
        bottom: "6%",
        top: "30%",
        containLabel: false,
        show: false,
      },
      title: {
        itemGap: -5,
        subtext: "bmi",
        subtextStyle: {
          fontStyle: "italic",
          color: "#000000",
        },
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          let tipHtml = "";
          let bmi_m = 0.0;
          let bmi_e = 0.0;
          let _keyIndex = params.dataIndex;

          if (params.dataIndex % 2) {
            bmi_m =
              bmiData[_keyIndex - 1] &&
                bmiData[_keyIndex - 1][1] !== undefined
                ? bmiData[_keyIndex - 1][1]
                : "-";
            bmi_e =
              bmiData[_keyIndex][1] && bmiData[_keyIndex][1] !== undefined
                ? bmiData[_keyIndex][1]
                : "-";
          } else {
            bmi_m =
              bmiData[_keyIndex][1] && bmiData[_keyIndex][1] !== undefined
                ? bmiData[_keyIndex][1]
                : "-";
            bmi_e =
              bmiData[_keyIndex + 1] &&
                bmiData[_keyIndex + 1][1] !== undefined
                ? bmiData[_keyIndex + 1][1]
                : "-";
          }

          tipHtml =
            '<div class="pop-model pop-bp-bmi"><div class="icon-morning">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            bmi_m +
            ' </div><div class="icon-evening">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            bmi_e +
            "</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[1],
      yAxis: {
        type: "value",
        scale: true,
        axisLabel: {
          margin: 50,
          align: "left",
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameGap: 90,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
        splitNumber: 3,
        min: bmiMin,
        max: bmiMax,
      },
      series: [
        {
          name: "bmi",
          connectNulls: true,
          type: "line",
          symbol: "circle",
          symbolSize: 15,
          animation: false,
          lineStyle: {
            color: "#E5F4F7",
            width: 2,
            type: "solid",
          },
          itemStyle: {
            color: function (params) {
              return colorList[(params.dataIndex % 2) + 2];
            },
          },
          data: bmiData,
        },
      ],
    };
  }

  buildBMIGraph() {

    var BMIChart = echarts.init(document.getElementById('BMIGraph'));
    BMIChart.group = 'BMIChartGroup';
    BMIChart.setOption(this.BMIChartOptions());
    window.addEventListener("resize", function () {
      BMIChart.resize();
    });
    this.BMIChart = BMIChart;

    let self = this;
    BMIChart.on('click', function (event) {
      self.linkageEchars('BMIChart', event)
    });

    BMIChart.on('globalout', function (event) {
      self.unlinkageEchars('BMIChart', event)
    });
  }

  StepsChartOptions() {
    const { stepsMin, stepsMax, stepsData } = this.state;
    return {
      grid: {
        left: "8.8%",
        right: "8.5%",
        bottom: "6%",
        top: "15%",
        containLabel: false,
        show: false,
      },
      title: {
        itemGap: -5,
        subtext: "no. of steps",
        subtextStyle: {
          fontStyle: "italic",
          color: "#000000",
        },
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          console.log(params.value)
          let tipHtml = "";
          tipHtml =
            '<div class="pop-model pop-step"><div class="icon-step">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            params.value +
            " steps</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[0],
      yAxis: {
        type: "value",
        scale: true,
        axisLabel: {
          margin: 50,
          align: "left",
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameGap: 90,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
        splitNumber: 5,
        min: stepsMin,
        max: stepsMax,
      },
      series: [
        {
          name: "Steps",
          type: "bar",
          yAxisIndex: 0,
          itemStyle: {
            normal: {
              show: true,
              barBorderRadius: 10,
              barBorderColor: "rgba(0,0,0,0)",
              color: colorList[4],
              areaStyle: { type: "default" },
            },
          },
          barWidth: 15, //这个属性只需要在最后一个 bar 里面设置
          barGap: "30%", //这个属性只需要在最后一个 bar 里面设置
          data: stepsData,
        },
      ],
    };
  }

  buildStepsGraph() {

    var StepsChart = echarts.init(document.getElementById('StepsGraph'));
    StepsChart.group = 'StepsChartGroup';
    StepsChart.setOption(this.StepsChartOptions());

    window.addEventListener("resize", function () {
      StepsChart.resize();
    });

    this.StepsChart = StepsChart;

    let self = this;
    StepsChart.on('click', function (event) {
      self.linkageEchars('StepsChart', event)
    });

    StepsChart.on('globalout', function (event) {
      self.unlinkageEchars('StepsChart', event)
    });
  }

  FeelingChartOptions() {
    const { feelingData, memoData } = this.state;
    return {
      grid: {
        left: "8.5%",
        right: "8.5%",
        top: "9%",
        bottom: "9%",
        containLabel: false,
        width: "auto",
        show: false,
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          let tipHtml = "";
          let hasMemo = memoData[params.dataIndex] ? "has-mome" : "";
          tipHtml =
            '<div class="pop-model pop-feeings "><div class="icon-felling ' +
            hasMemo +
            '">';
          if (hasMemo) {
            tipHtml =
              tipHtml +
              '<div class="icon-memo memo1" ><img src=' +
              MemoNote +
              ' alt=""/> </div>';
          }

          if (params.value === "1") {
            tipHtml =
              tipHtml +
              '<div class="icon-memo memo2" ><img src=' +
              FaceIcon1 +
              ' alt=""/> </div>';
          }

          if (params.value === "2") {
            tipHtml =
              tipHtml +
              '<div class="icon-memo memo2" ><img src=' +
              FaceIcon2 +
              ' alt=""/> </div>';
          }

          if (params.value === "3") {
            tipHtml =
              tipHtml +
              '<div class="icon-memo memo2" ><img src=' +
              FaceIcon3 +
              ' alt=""/> </div>';
          }

          if (params.value === "4") {
            tipHtml =
              tipHtml +
              '<div class="icon-memo memo2" ><img src=' +
              FaceIcon4 +
              ' alt=""/> </div>';
          }

          if (params.value === "5") {
            tipHtml =
              tipHtml +
              '<div class="icon-memo memo2" ><img src=' +
              FaceIcon5 +
              ' alt=""/> </div>';
          }

          tipHtml = tipHtml + "</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[0],
      yAxis: {
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
      },
      series: [
        {
          symbolSize: 30,
          symbol: (v, params) => {
            return "image://" + FaceIcons[v - 1];
          },
          type: "line",
          lineStyle: {
            opacity: 0,
          },
          data: feelingData,
        },
      ],
    };
  }

  buildFeelingGraph() {
    var FeelingChart = echarts.init(document.getElementById('FeelingGraph'));
    FeelingChart.group = 'FeelingChartGroup';
    FeelingChart.setOption(this.FeelingChartOptions());
    window.addEventListener("resize", function () {
      FeelingChart.resize();
    });

    this.FeelingChart = FeelingChart;

    let self = this;
    FeelingChart.on('click', function (event) {
      self.linkageEchars('FeelingChart', event)
    });

    FeelingChart.on('globalout', function (event) {
      self.unlinkageEchars('FeelingChart', event)
    });
  }

  SleepChartOptions() {
    const { sleepMin, sleepMax, sleepData, sleepFullData } = this.state;
    return {
      grid: {
        left: "8.8%",
        right: "8.5%",
        bottom: "6%",
        top: "15%",
        containLabel: false,
        show: false,
      },
      title: {
        itemGap: -5,
        subtext: "hours",
        subtextStyle: {
          fontStyle: "italic",
          color: "#000000",
        },
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          let tipHtml = "";
          let _wake_up_time = "--";
          let _sleep_time = "--";
          let _duration = "--";

          if (sleepFullData[params.dataIndex]) {
            var _item = sleepFullData[params.dataIndex];
            _wake_up_time = showDate(
              _item.date + " " + _item.wake_up_time + ":00",
              6
            );
            _sleep_time = showDate(
              _item.date + " " + _item.sleep_time + ":00",
              6
            );
            if (_item.duration % 60) {
              _duration =
                parseInt(_item.duration / 60) +
                " h " +
                (_item.duration % 60) +
                " min";
            } else {
              _duration = parseInt(_item.duration / 60) + " h ";
            }
          }

          tipHtml =
            '<div class="pop-model pop-bp-sleep"><div class="icon-morning">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            _wake_up_time +
            ' </div><div class="icon-evening">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            _sleep_time +
            '</div><div class="sleep-duration">' +
            _duration +
            "</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[0],
      yAxis: {
        type: "value",
        scale: true,
        axisLabel: {
          margin: 50,
          align: "left",
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameGap: 90,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
        splitNumber: 5,
        min: sleepMin,
        max: sleepMax,
      },
      series: [
        {
          name: "Sleep",
          type: "bar",
          yAxisIndex: 0,
          itemStyle: {
            normal: {
              show: true,
              barBorderRadius: 10,
              barBorderColor: "rgba(0,0,0,0)",
              color: colorList[5],
              areaStyle: { type: "default" },
            },
          },
          barWidth: 15, //这个属性只需要在最后一个 bar 里面设置
          barGap: "30%", //这个属性只需要在最后一个 bar 里面设置
          data: sleepData,
        },
      ],
    };
  }

  // SleepGraph
  buildSleepGraph() {

    var SleepChart = echarts.init(document.getElementById('SleepGraph'));
    SleepChart.group = 'SleepChartGroup';
    SleepChart.setOption(this.SleepChartOptions());

    window.addEventListener("resize", function () {
      SleepChart.resize();
    });

    this.SleepChart = SleepChart;

    let self = this;
    SleepChart.on('click', function (event) {
      self.linkageEchars('SleepChart', event)
    });

    SleepChart.on('globalout', function (event) {
      self.unlinkageEchars('SleepChart', event)
    });
  }

  BloodGlucoseChartOptions() {
    const { bgMin, bgMax, bgData } = this.state;
    const { patientGlucoseAfterMealFrom, patientGlucoseAfterMealTo, patientGlucoseBeforeMealFrom, patientGlucoseBeforeMealTo } = this.props
    return {
      grid: {
        left: "8.8%",
        right: "8.5%",
        bottom: "6%",
        top: "15%",
        containLabel: false,
        show: false,
      },
      title: {
        itemGap: -5,
        subtext: "mmol/L",
        subtextStyle: {
          fontStyle: "italic",
          color: "#000000",
        },
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          let tipHtml = "";
          let bg_m = 0.0;
          let bg_e = 0.0;
          let _keyIndex = params.dataIndex;

          if (params.dataIndex % 2) {
            bg_m =
              bgData[_keyIndex - 1] &&
                bgData[_keyIndex - 1][1] &&
                bgData[_keyIndex - 1][1] !== undefined
                ? bgData[_keyIndex - 1][1]
                : "-";
            bg_e =
              bgData[_keyIndex][1] && bgData[_keyIndex][1] !== undefined
                ? bgData[_keyIndex][1]
                : "-";
          } else {
            bg_m =
              bgData[_keyIndex][1] &&
                bgData[_keyIndex][1] &&
                bgData[_keyIndex][1] !== undefined
                ? bgData[_keyIndex][1]
                : "-";
            bg_e =
              bgData[_keyIndex + 1] &&
                bgData[_keyIndex + 1][1] !== undefined
                ? bgData[_keyIndex + 1][1]
                : "-";
          }
          if (!bg_m) {
            bg_m = "-";
          }
          if (!bg_e) {
            bg_e = "-";
          }

          tipHtml =
            '<div class="pop-model pop-bg"><div class="icon-morning">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            bg_m +
            ' mmol/L</div><div class="icon-evening">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            bg_e +
            " mmol/L</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[1],
      yAxis: {
        type: "value",
        scale: true,
        axisLabel: {
          margin: 50,
          align: "left",
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameGap: 90,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
        splitNumber: 5,
        min: bgMin,
        max: bgMax,
      },
      series: [
        {
          name: "bloodGlucose",
          connectNulls: true,
          type: "line",
          symbol: "circle",
          symbolSize: 15,
          animation: false,
          lineStyle: {
            color: "#F1CFE3",
            width: 2,
            type: "solid",
          },
          itemStyle: {
            color: function (params) {
              return colorList[params.dataIndex % 2];
            },
          },
          markLine: {
            silent: true,
            symbol: "none",
            data: [
              {
                yAxis: patientGlucoseAfterMealFrom ? patientGlucoseAfterMealFrom : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientGlucoseAfterMealFrom ? 1 : 0,
                },
                label: {
                  show: patientGlucoseAfterMealFrom ? true : false,
                },
              },
              {
                yAxis: patientGlucoseAfterMealTo ? patientGlucoseAfterMealTo : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientGlucoseAfterMealTo ? 1 : 0,
                },
                label: {
                  show: patientGlucoseAfterMealTo ? true : false,
                },
              },
              {
                yAxis: patientGlucoseBeforeMealFrom ? patientGlucoseBeforeMealFrom : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientGlucoseBeforeMealFrom ? 1 : 0,
                },
                label: {
                  show: patientGlucoseBeforeMealFrom ? true : false,
                },
              },
              {
                yAxis: patientGlucoseBeforeMealTo ? patientGlucoseBeforeMealTo : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientGlucoseBeforeMealTo ? 1 : 0,
                },
                label: {
                  show: patientGlucoseBeforeMealTo ? true : false,
                },
              },
            ],
          },
          data: bgData,
        },
      ],
    };
  }

  // Blood Glucose
  buildBloodGlucoseGraph() {

    var BloodGlucoseChart = echarts.init(document.getElementById('BloodGlucoseGraph'));
    BloodGlucoseChart.group = 'BloodGlucoseChartGroup';
    BloodGlucoseChart.setOption(this.BloodGlucoseChartOptions());

    window.addEventListener("resize", function () {
      BloodGlucoseChart.resize();
    });

    this.BloodGlucoseChart = BloodGlucoseChart;

    let self = this;
    BloodGlucoseChart.on('click', function (event) {
      self.linkageEchars('BloodGlucoseChart', event)
    });

    BloodGlucoseChart.on('globalout', function (event) {
      self.unlinkageEchars('BloodGlucoseChart', event)
    });
  }

  TemperatureChartOptions() {
    let { temperatureMin, temperatureMax, temperatureData } = this.state;
    return {
      grid: {
        left: "8.8%",
        right: "8.5%",
        bottom: "6%",
        top: "15%",
        containLabel: false,
        show: false,
      },
      title: {
        itemGap: -5,
        subtext: "℃",
        subtextStyle: {
          fontStyle: "italic",
          color: "#000000",
        },
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          let tipHtml = "";
          let tempe_m = 0.0;
          let tempe_e = 0.0;
          let _keyIndex = params.dataIndex;

          if (params.dataIndex % 2) {
            tempe_m =
              temperatureData[_keyIndex - 1] &&
                temperatureData[_keyIndex - 1][1] !== undefined
                ? temperatureData[_keyIndex - 1][1]
                : "-";
            tempe_e =
              temperatureData[_keyIndex][1] &&
                temperatureData[_keyIndex][1] !== undefined
                ? temperatureData[_keyIndex][1]
                : "-";
          } else {
            tempe_m =
              temperatureData[_keyIndex][1] &&
                temperatureData[_keyIndex][1] !== undefined
                ? temperatureData[_keyIndex][1]
                : "-";
            tempe_e =
              temperatureData[_keyIndex + 1] &&
                temperatureData[_keyIndex + 1][1] !== undefined
                ? temperatureData[_keyIndex + 1][1]
                : "-";
          }
          if (!tempe_m) {
            tempe_m = "-";
          }
          if (!tempe_e) {
            tempe_e = "-";
          }

          tipHtml =
            '<div class="pop-model pop-bp-tempe"><div class="icon-morning">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            tempe_m +
            ' °C</div><div class="icon-evening">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            tempe_e +
            " °C</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[1],
      yAxis: {
        type: "value",
        scale: true,
        axisLabel: {
          margin: 50,
          align: "left",
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameGap: 90,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
        splitNumber: 5,
        min: temperatureMin,
        max: temperatureMax,
      },
      series: [
        {
          name: "temperature",
          connectNulls: true,
          type: "line",
          symbol: "circle",
          symbolSize: 15,
          animation: false,
          lineStyle: {
            color: "#D5E5FC",
            width: 2,
            type: "solid",
          },
          itemStyle: {
            color: function (params) {
              return colorList[(params.dataIndex % 2) + 6];
            },
          },
          data: temperatureData,
        },
      ],
    };
  }


  buildTemperatureGraph() {

    var TemperatureChart = echarts.init(document.getElementById('Temperature'));
    TemperatureChart.group = 'TemperatureChartGroup';
    TemperatureChart.setOption(this.TemperatureChartOptions());
    window.addEventListener("resize", function () {
      TemperatureChart.resize();
    });

    this.TemperatureChart = TemperatureChart;

    let self = this;
    TemperatureChart.on('click', function (event) {
      self.linkageEchars('TemperatureChart', event)
    });

    TemperatureChart.on('globalout', function (event) {
      self.unlinkageEchars('TemperatureChart', event)
    });
  }

  Spo2ChartOptions() {
    let { spo2Min, spo2Max, spo2Data } = this.state;
    let { patientSPO2HGoal, patientSPO2LGoal } = this.props
    return {
      grid: {
        left: "8.8%",
        right: "8.5%",
        bottom: "6%",
        top: "15%",
        containLabel: false,
        show: false,
      },
      title: {
        itemGap: -5,
        subtext: "%",
        subtextStyle: {
          fontStyle: "italic",
          color: "#000000",
        },
      },
      tooltip: {
        show: true,
        // alwaysShowContent:true,
        transitionDuration: 0,
        padding: 0,
        trigger: "item",
        axisPointer: {
          type: "none",
          snap: true,
        },
        position: "top",
        // triggerOn:'mousemove|click',
        triggerOn: "click",
        formatter: function (params) {
          let tipHtml = "";
          let spo2_m = 0.0;
          let spo2_e = 0.0;
          let _keyIndex = params.dataIndex;

          if (params.dataIndex % 2) {
            spo2_m =
              spo2Data[_keyIndex - 1] &&
                spo2Data[_keyIndex - 1][1] !== null &&
                spo2Data[_keyIndex - 1][1] !== undefined
                ? spo2Data[_keyIndex - 1][1]
                : "-";
            spo2_e =
              spo2Data[_keyIndex][1] &&
                spo2Data[_keyIndex][1] !== null &&
                spo2Data[_keyIndex][1] !== undefined
                ? spo2Data[_keyIndex][1]
                : "-";
          } else {
            spo2_m =
              spo2Data[_keyIndex][1] &&
                spo2Data[_keyIndex][1][1] !== null &&
                spo2Data[_keyIndex][1] !== undefined
                ? spo2Data[_keyIndex][1]
                : "-";
            spo2_e =
              spo2Data[_keyIndex + 1] &&
                spo2Data[_keyIndex + 1] !== null &&
                spo2Data[_keyIndex + 1][1] !== undefined
                ? spo2Data[_keyIndex + 1][1]
                : "-";
          }
          if (!spo2_m) {
            spo2_m = "-";
          }
          if (!spo2_e) {
            spo2_e = "-";
          }
          tipHtml =
            '<div class="pop-model pop-spo2"><div class="icon-morning">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            spo2_m +
            ' %</div><div class="icon-evening">&nbsp;&nbsp; &nbsp;&nbsp; ' +
            spo2_e +
            " %</div></div>";
          return tipHtml;
        },
      },
      xAxis: this.xAxisArr[1],
      yAxis: {
        type: "value",
        scale: true,
        axisLabel: {
          margin: 50,
          align: "left",
          show: true,
          textStyle: {
            color: "#333",
          },
        },
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        nameGap: 90,
        boundaryGap: false,
        splitLine: {
          show: true,
          lineStyle: {
            color: "#EFF0F0",
          },
        },
        splitNumber: 5,
        min: spo2Min,
        max: spo2Max,
      },
      series: [
        {
          name: "Spo2",
          connectNulls: true,
          type: "line",
          symbol: "circle",
          symbolSize: 15,
          animation: false,
          lineStyle: {
            color: "#CBE0FE",
            width: 2,
            type: "solid",
          },
          itemStyle: {
            color: function (params) {
              return colorList[(params.dataIndex % 2) + 8];
            },
          },
          markLine: {
            silent: true,
            symbol: "none",
            data: [
              {
                yAxis: patientSPO2HGoal ? patientSPO2HGoal : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientSPO2HGoal ? 1 : 0,
                },
                label: {
                  show: patientSPO2HGoal ? true : false,
                },
              },
              {
                yAxis: patientSPO2LGoal ? patientSPO2LGoal : 0,
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "solid",
                  color: "#CE3A7F",
                  opacity: patientSPO2LGoal ? 1 : 0,
                },
                label: {
                  show: patientSPO2LGoal ? true : false,
                },
              },
            ],
          },
          data: spo2Data,
        },
      ],
    };
  }

  // spo2
  buildSpo2() {

    var Spo2Chart = echarts.init(document.getElementById('Spo2'));
    Spo2Chart.group = 'Spo2ChartGroup';
    Spo2Chart.setOption(this.Spo2ChartOptions());

    window.addEventListener("resize", function () {
      Spo2Chart.resize();
    });

    this.Spo2Chart = Spo2Chart;

    let self = this;
    Spo2Chart.on('click', function (event) {
      self.linkageEchars('Spo2Chart', event)
    });

    Spo2Chart.on('globalout', function (event) {
      self.unlinkageEchars('Spo2Chart', event)
    });
  }


  unlinkageEchars(type, event) {
    this.setState({
      currentDay: false
    })

    this.BPPulseChart.dispatchAction({
      type: 'hideTip'
    })

    // this.IrregularHeartbeatChart.dispatchAction({
    //     type: 'hideTip'
    // })

    this.BPMChart.dispatchAction({
      type: 'hideTip'
    })

    this.WeightChart.dispatchAction({
      type: 'hideTip'
    })

    this.BMIChart.dispatchAction({
      type: 'hideTip'
    })

    this.StepsChart.dispatchAction({
      type: 'hideTip'
    })

    this.FeelingChart.dispatchAction({
      type: 'hideTip'
    })

    this.SleepChart.dispatchAction({
      type: 'hideTip'
    })

    this.BloodGlucoseChart.dispatchAction({
      type: 'hideTip'
    })

    this.TemperatureChart.dispatchAction({
      type: 'hideTip'
    })

    this.Spo2Chart.dispatchAction({
      type: 'hideTip'
    })
  }

  linkageEchars(type, event) {
    console.log(type);
    let everyDayDates = this.xAxisArr[0].data;
    let {
      morningBPData,
      evenBpData,
      pulseData,
      weightData,
      bmiData,
      IHData,
      feelingData,
      bgData,
      sleepData,
      stepsData,
      temperatureData,
      spo2Data
    } = this.state;

    let _seriesIndex = event.seriesIndex;
    let _seriesName = event.seriesName;
    let _dataIndex = event.dataIndex;
    let _componentIndex = event.componentIndex;
    let _name = event.name;
    console.log(_name)
    let _currentDay = 0;
    // console.table({ _seriesIndex, _seriesName, _dataIndex, _componentIndex})
    if (_name) {
      _currentDay = everyDayDates.findIndex((item, index) => {
        return _name === item
      });
    } else {
      _currentDay = parseInt(_dataIndex / 2);
    }

    this.setState({
      currentDay: _currentDay
    })

    // echarts.connect([
    // this.BPPulseChart,
    // this.WeightChart,
    // this.StepsChart,
    // this.FeelingChart,
    // this.SleepChart,
    // this.BloodGlucoseChart,
    // ])

    //Temperature
    if (type !== 'TemperatureChart') {
      let ___dataIndex = _dataIndex;
      console.log(_componentIndex)
      if (type === 'BPPulseChart') {
        if (_componentIndex % 2 === 0) {
          ___dataIndex = _dataIndex * 2
        }

        if (_componentIndex % 2) {
          ___dataIndex = _dataIndex * 2 + 1
        }
      } else if (['StepsChart', 'FeelingChart', 'SleepChart'].includes(type)) {
        ___dataIndex = temperatureData[_dataIndex * 2] && temperatureData[_dataIndex * 2][1] ? _dataIndex * 2 : _dataIndex * 2 + 1
      }
      console.log(___dataIndex)
      this.TemperatureChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: ___dataIndex,//第几个tooltip
      });
    }

    //BPPulseChart
    if (type !== 'BPPulseChart') {
      let __seriesIndex = _seriesIndex;
      let __dataIndex = _dataIndex;
      if (type === 'WeightChart' || type === 'BMIChart' || type === 'BloodGlucoseChart' || type === 'Spo2Chart' || type === 'TemperatureChart') {
        __dataIndex = parseInt(_dataIndex / 2)
        __seriesIndex = _dataIndex % 2 ? 3 : 2
      } else if (['StepsChart', 'FeelingChart', 'SleepChart'].includes(type)) {
        __seriesIndex = Number.isNaN(morningBPData[_dataIndex]) || morningBPData[_dataIndex] === '-' ? 3 : 2
      }

      this.BPPulseChart.dispatchAction({
        type: 'showTip',
        seriesIndex: __seriesIndex,//第几条series
        dataIndex: __dataIndex,//第几个tooltip
      });
    }

    //IrregularHeartbeatChart
    // if (type !== 'IrregularHeartbeatChart') {
    // this.IrregularHeartbeatChart.dispatchAction({
    //     type: 'showTip',
    //     seriesIndex: _seriesIndex,//第几条series
    //     dataIndex: _dataIndex,//第几个tooltip
    // });
    // }

    //WeightChart
    if (type !== 'WeightChart') {
      let ___dataIndex = _dataIndex;
      if (type === 'BPPulseChart') {
        if (_componentIndex === 2) {
          ___dataIndex = _dataIndex * 2
        }

        if (_componentIndex === 3) {
          ___dataIndex = _dataIndex * 2 + 1
        }
      } else if (['StepsChart', 'FeelingChart', 'SleepChart'].includes(type)) {
        ___dataIndex = weightData[_dataIndex * 2] && weightData[_dataIndex * 2][1] ? _dataIndex * 2 : _dataIndex * 2 + 1
      }

      this.WeightChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: ___dataIndex,//第几个tooltip
      });
    }

    //BMIChart
    if (type !== 'BMIChart') {
      let ___dataIndex = _dataIndex;
      if (type === 'BPPulseChart') {
        if (_componentIndex === 2) {
          ___dataIndex = _dataIndex * 2
        }

        if (_componentIndex === 3) {
          ___dataIndex = _dataIndex * 2 + 1
        }
      } else if (['StepsChart', 'FeelingChart', 'SleepChart'].includes(type)) {
        ___dataIndex = weightData[_dataIndex * 2] && weightData[_dataIndex * 2][1] ? _dataIndex * 2 : _dataIndex * 2 + 1
      }

      this.BMIChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: ___dataIndex,//第几个tooltip
      });
    }

    //StepsChart
    if (type !== 'StepsChart') {
      let __dataIndex = _dataIndex;
      if (type === 'WeightChart' || type === 'BMIChart' || type === 'BloodGlucoseChart' || type === 'Spo2Chart' || type === 'TemperatureChart') {
        __dataIndex = parseInt(_dataIndex / 2)
      }
      this.StepsChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: __dataIndex,//第几个tooltip
      });
    }

    //FeelingChart
    if (type !== 'FeelingChart') {
      let __dataIndex = _dataIndex;
      if (type === 'WeightChart' || type === 'BMIChart' || type === 'BloodGlucoseChart' || type === 'Spo2Chart' || type === 'TemperatureChart') {
        __dataIndex = parseInt(_dataIndex / 2)
      }

      this.FeelingChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: __dataIndex,//第几个tooltip
      });
    }

    //SleepChart
    if (type !== 'SleepChart') {
      let __dataIndex = _dataIndex;
      if (type === 'WeightChart' || type === 'BMIChart' || type === 'BloodGlucoseChart' || type === 'Spo2Chart' || type === 'TemperatureChart') {
        __dataIndex = parseInt(_dataIndex / 2)
      }
      this.SleepChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: __dataIndex,//第几个tooltip
      });
    }

    //BloodGlucoseChart
    if (type !== 'BloodGlucoseChart') {
      let ___dataIndex = _dataIndex;
      if (type === 'BPPulseChart') {
        if (_componentIndex === 2) {
          ___dataIndex = _dataIndex * 2
        }

        if (_componentIndex === 3) {
          ___dataIndex = _dataIndex * 2 + 1
        }
      } else if (['StepsChart', 'FeelingChart', 'SleepChart'].includes(type)) {
        ___dataIndex = bgData[_dataIndex * 2] && bgData[_dataIndex * 2][1] !== '-' && bgData[_dataIndex * 2][1] ? _dataIndex * 2 : _dataIndex * 2 + 1
      }

      this.BloodGlucoseChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: ___dataIndex,//第几个tooltip
      });
    }

    //Spo2Chart
    if (type !== 'Spo2Chart') {
      let ___dataIndex = _dataIndex;
      if (type === 'BPPulseChart') {
        if (_componentIndex === 2) {
          ___dataIndex = _dataIndex * 2
        }

        if (_componentIndex === 3) {
          ___dataIndex = _dataIndex * 2 + 1
        }
      } else if (['StepsChart', 'FeelingChart', 'SleepChart'].includes(type)) {
        ___dataIndex = spo2Data[_dataIndex * 2] && spo2Data[_dataIndex * 2][1] ? _dataIndex * 2 : _dataIndex * 2 + 1
      }

      this.Spo2Chart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,//第几条series
        dataIndex: ___dataIndex,//第几个tooltip
      });
    }
  }

  render() {
    let everyDayDates = GetConvertDates(this.startDate, this.endDate);

    return (
      <div className="graph-container">
        <Row className="popup-map">
          <Col className="popbar popbar-left"></Col>
          <Col className={`popbar ${this.state.currentDay === 0 ? 'popbar-day1' : ''}`}></Col>
          <Col className={`popbar ${this.state.currentDay === 1 ? 'popbar-day2' : ''}`}></Col>
          <Col className={`popbar ${this.state.currentDay === 2 ? 'popbar-day3' : ''}`}></Col>
          <Col className={`popbar ${this.state.currentDay === 3 ? 'popbar-day4' : ''}`}></Col>
          <Col className={`popbar ${this.state.currentDay === 4 ? 'popbar-day5' : ''}`}></Col>
          <Col className={`popbar ${this.state.currentDay === 5 ? 'popbar-day6' : ''}`}></Col>
          <Col className={`popbar ${this.state.currentDay === 6 ? 'popbar-day7' : ''}`}></Col>
          <Col className="popbar popbar-right"></Col>
        </Row>
        <Row className="block_title">
          <Col width="50">
            Graph
                    </Col>

          <Block className="navTitle footerBtn">
            <Button className="fill-but-abb" large raised fill onClick={this.props.btnAddData}>Add Data</Button>
            <Button className="fill-but-abb" large raised fill onClick={this.props.btnSetGoal}>Set Goal</Button>
            <Button className="fill-but-abb" large raised fill onClick={this.props.btnSetLimit}>Set Limit</Button>
          </Block>


        </Row>
        <Row className="graph-dayline">
          <Col onClick={this.prePage.bind(this)} style={{ position: 'relative' }}>
            {/* <Icon f7="arrowtriangle_left_fill" className="iconArrow" ></Icon> */}
            <img src={green} className="img-block iconArrow" style={{ width: '10px', top: '-14px', left: '10px' }} />
          </Col>

          {everyDayDates.map((item, index) => {
            return <Col key={`${item}` + index}> {item} </Col>
          })}

          {
            this.endDate === moment().format('YYYY-MM-DD') ?
              (<Col style={{ position: 'relative' }}>
                {/* <Icon f7="arrowtriangle_right_fill" className="iconArrow grayIconArrow"></Icon> */}
                <img src={grey} className="img-block iconArrow" style={{ width: '10px', top: '-14px', right: '10px', transform: 'rotate(180deg)' }} />
              </Col>) : (
                <Col onClick={this.nextPage.bind(this)} style={{ position: 'relative' }}>
                  {/* <Icon f7="arrowtriangle_right_fill" className="iconArrow"></Icon> */}
                  <img src={grey} className="img-block iconArrow" style={{ width: '10px', top: '-14px', right: '10px', transform: 'rotate(180deg)' }} />
                </Col>
              )
          }

        </Row>

        {/* Temperature */}
        <Row className="graph-block-header bgc-green" >
          <Col className="graph-block-title-left fwb">
            Temperature
                    </Col>
          <Col className="graph-block-title-right">
            <img onClick={this.tmpertureHisotry.bind(this)} src={MenuIcon} alt="" className="graph-menu canClick" />
          </Col>
        </Row>
        <Row className="graph-block bgc-green" >
          <Col className="graphContainer">
            <div id="Temperature" style={{ height: 200 }}></div>
          </Col>
        </Row>


        <Row className="graph-block-fotter bgc-green">
          <Col className="graph-ave-desc">
            This Week's Average <span className="fwb">
              {this.state.temperatureAverage && this.state.temperatureAverage.average ? this.state.temperatureAverage.average : '--'}
                        ℃ </span>
          </Col>
          <Col className="graph-ave-desc">
            Last Week's Average <span className="fwb">
              {this.state.temperatureAverage && this.state.temperatureAverage.day_7_ago_average ? this.state.temperatureAverage.day_7_ago_average : '--'}
                        ℃ </span>
          </Col>
        </Row>

        {/* bp && Pulse && Irregular Heartbeat  graph*/}
        <Row className="graph-block-header bgc-pink" >
          <Col className="graph-block-title-left fwb">
            Blood Pressure & Heartbeat
                    </Col>
          <Col className="graph-block-title-right">
            <img onClick={this.getMeasuredataHistory.bind(this, 'BPM')} src={MenuIcon} alt="" className="graph-menu canClick" />
          </Col>
        </Row>
        <Row className="graph-block bgc-pink">
          <Col className="graphContainer">
            <div id="BPPulseGraph" style={{ height: 120 }}></div>
            <div id="BPMGraph" style={{ height: 80 }}></div>
            {/* <div id="IHGraph" style={{ height: 50 }}></div> */}
          </Col>
        </Row>
        <Row className="graph-block-fotter bgc-pink">
          <Col className="graph-ave-desc">
            This Week's Average <span className="fwb">
              {this.state.bpAverage && this.state.bpAverage.average && this.state.bpAverage.average.highAverage ? this.state.bpAverage.average.highAverage : '--'}
                        /
                        {this.state.bpAverage && this.state.bpAverage.average && this.state.bpAverage.average.lowAverage ? this.state.bpAverage.average.lowAverage : '--'}
                        mmHg </span>
          </Col>
          <Col className="graph-ave-desc">
            Last Week's Average <span className="fwb">
              {this.state.bpAverage && this.state.bpAverage.day_7_ago_average && this.state.bpAverage.day_7_ago_average.highAverage ? this.state.bpAverage.day_7_ago_average.highAverage : '--'}
                        /
                        {this.state.bpAverage && this.state.bpAverage.day_7_ago_average && this.state.bpAverage.day_7_ago_average.lowAverage ? this.state.bpAverage.day_7_ago_average.lowAverage : '--'}
                        mmHg </span>
          </Col>
        </Row>
        {/* weight  graph*/}
        <Row className="graph-block-header bgc-green" >
          <Col className="graph-block-title-left fwb">
            Weight
                    </Col>
          <Col className="graph-block-title-right">
            <img onClick={this.getMeasuredataHistory.bind(this, 'BCM')} src={MenuIcon} alt="" className="graph-menu canClick" />
          </Col>
        </Row>
        <Row className="graph-block bgc-green" >
          <Col className="graphContainer">
            <div id="WeightGraph" style={{ height: 200 }}></div>
            <div id="BMIGraph" style={{ height: 100, marginTop: '10px' }}></div>
          </Col>
        </Row>
        <Row className="graph-block-fotter bgc-green">
          <Col className="graph-ave-desc">
            This Week's Average <span className="fwb">
              {this.state.weightAverage && this.state.weightAverage.average ? this.state.weightAverage.average : '--'}
                        kg </span>
          </Col>
          <Col className="graph-ave-desc">
            Last Week's Average <span className="fwb">
              {this.state.weightAverage && this.state.weightAverage.day_7_ago_average ? this.state.weightAverage.day_7_ago_average : '--'}
                        kg </span>
          </Col>
        </Row>

        {/* steps  graph*/}
        <Row className="graph-block-header bgc-pink" >
          <Col className="graph-block-title-left fwb">
            Steps
                    </Col>
          <Col className="graph-block-title-right">
            <img onClick={this.getStepsHistory.bind(this)} src={MenuIcon} alt="" className="graph-menu canClick" />
          </Col>
        </Row>
        <Row className="graph-block bgc-pink" >
          <Col className="graphContainer">
            <div id="StepsGraph" style={{ height: 200 }}></div>
          </Col>
        </Row>
        <Row className="graph-block-fotter bgc-pink">
          <Col className="graph-ave-desc">
            This Week's Average <span className="fwb">
              {this.state.stepAverage && this.state.stepAverage.average ? this.state.stepAverage.average : '--'}
                        steps </span>
          </Col>
          <Col className="graph-ave-desc">
            Last Week's Average <span className="fwb"> {this.state.stepAverage && this.state.stepAverage.day_7_ago_average ? this.state.stepAverage.day_7_ago_average : '--'}  steps </span>
          </Col>
        </Row>

        {/* feeling  graph*/}
        <Row className="graph-block-header bgc-yellow" >
          <Col className="graph-block-title-left fwb">
            Mood
          </Col>
          <Col className="graph-block-title-right">

          </Col>
        </Row>
        <Row className="graph-block bgc-yellow" >
          <Col className="graphContainer">
            <div id="FeelingGraph" style={{ height: 200 }}></div>
          </Col>
        </Row>

        {/* sleep  graph*/}
        <Row className="graph-block-header bgc-purple" >
          <Col className="graph-block-title-left fwb">
            Sleep
                    </Col>
          <Col className="graph-block-title-right">
            <img onClick={this.getSleepHistory.bind(this)} src={MenuIcon} alt="" className="graph-menu canClick" />
          </Col>
        </Row>
        <Row className="graph-block bgc-purple" >
          <Col className="graphContainer">
            <div id="SleepGraph" style={{ height: 200 }}></div>
          </Col>
        </Row>
        <Row className="graph-block-fotter bgc-purple">
          <Col className="graph-ave-desc">
            This Week's Average <span className="fwb">
              {this.state.sleepAverage && this.state.sleepAverage.average ? this.state.sleepAverage.average : '--'}
                         hours </span>
          </Col>
          <Col className="graph-ave-desc">
            Last Week's Average <span className="fwb">
              {this.state.sleepAverage && this.state.sleepAverage.day_7_ago_average ? this.state.sleepAverage.day_7_ago_average : '--'}
                         hours </span>
          </Col>
        </Row>

        {/* Blood  Glucose*/}
        <Row className="graph-block-header bgc-pink" >
          <Col className="graph-block-title-left fwb">
            Blood  Glucose
                    </Col>
          <Col className="graph-block-title-right">
            <img onClick={this.getBloodGlucoseHistory.bind(this)} src={MenuIcon} alt="" className="graph-menu canClick" />
          </Col>
        </Row>
        <Row className="graph-block bgc-pink" >
          <Col className="graphContainer">
            <div id="BloodGlucoseGraph" style={{ height: 200 }}></div>
          </Col>
        </Row>
        <Row className="graph-block-fotter bgc-pink">
          <Col className="graph-ave-desc">
            This Week's Average <span className="fwb">
              {this.state.bloodGlucoseAverage && this.state.bloodGlucoseAverage.average ? this.state.bloodGlucoseAverage.average : '--'}
                        mmol/L </span>
          </Col>
          <Col className="graph-ave-desc">
            Last Week's Average <span className="fwb">
              {this.state.bloodGlucoseAverage && this.state.bloodGlucoseAverage.day_7_ago_average ? this.state.bloodGlucoseAverage.day_7_ago_average : '--'}
                        mmol/L </span>
          </Col>
        </Row>

        {/* spo2 */}
        <Row className="graph-block-header bgc-lightGreen" >
          <Col className="graph-block-title-left fwb">
            SPO2
                    </Col>
          <Col className="graph-block-title-right">
            <img onClick={this.spo2History.bind(this)} src={MenuIcon} alt="" className="graph-menu canClick" />
          </Col>
        </Row>
        <Row className="graph-block bgc-lightGreen" >
          <Col className="graphContainer">
            <div id="Spo2" style={{ height: 200 }}></div>
          </Col>
        </Row>
        <Row className="graph-block-fotter bgc-lightGreen">
          <Col className="graph-ave-desc">
            This Week's Average <span className="fwb">
              {this.state.spo2Average && this.state.spo2Average.average ? this.state.spo2Average.average : '--'}
                        % </span>
          </Col>
          <Col className="graph-ave-desc">
            Last Week's Average <span className="fwb">
              {this.state.spo2Average && this.state.spo2Average.day_7_ago_average ? this.state.spo2Average.day_7_ago_average : '--'}
                        % </span>
          </Col>
        </Row>


        <Row className="graph-block-fotter bgc-lightGreen">
          <Popup
            className="historyPopUP bloodPressureHistory-popup"
          >
            <Page>
              <div className="popHeader">
                <Row>
                  <Col><div className="title">Blood  Pressure History</div></Col>
                </Row>
                <Row>
                  <Col width="35">
                    DATE
                                </Col>
                  <Col width="35">
                    BLOOD PRESSURE
                                    <span className="subTitle">mmHg</span>
                  </Col>
                  <Col width="15">
                    PULSE
                                    <span className="subTitle">bpm</span>
                  </Col>
                  {/* <Col width="10">
                                        IHB
                                    </Col> */}
                  <Col width="15">
                    NOTES
                                    </Col>
                </Row>
              </div>
              <div className="grayLine"></div>
              <div className="popBody">
                {Object.keys(this.state.bloodPressureHistory).map((item, index) => {
                  return (
                    <React.Fragment key={`bp_${item}`}>
                      <Row>
                        <Col>
                          <span className="dataDate"> {item}</span>
                        </Col>
                      </Row>
                      {
                        this.state.bloodPressureHistory && this.state.bloodPressureHistory[item] ? (
                          this.state.bloodPressureHistory[item].map((iitem, iindex) => {
                            return (
                              <React.Fragment key={`bp_${iitem.id}`}>
                                <Row>
                                  <Col width="35">
                                    {DispalyDateTime(iitem.search_device_date_from)}
                                  </Col>
                                  <Col width="35">
                                    <span className={`dataForm ${FirstCaseUpper(iitem.location)}`}>{FirstCaseUpper(iitem.location)}</span>
                                    <span>{iitem.measure1} / {iitem.measure2}</span>
                                  </Col>
                                  <Col width="15">
                                    {iitem.measure3}
                                  </Col>
                                  {/* <Col width="10">
                                                                        {iitem.irregular_heart_rate === 1 ? (
                                                                            <img src={HeartIcon} alt="" />
                                                                        ) : ''}
                                                                    </Col> */}
                                  <Col width="15">
                                    {iitem.note}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          })
                        ) : ''
                      }
                      <br />
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="popFotter">
                <div className="grayLine"></div>
                <Button
                  className="btn-red"
                  popupClose
                >
                  Close
                            </Button>
              </div>
            </Page>
          </Popup>

          {/* weightHistory */}
          <Popup
            className="historyPopUP weightHistory-popup"
          >
            <Page>
              <div className="popHeader">
                <Row>
                  <Col><div className="title">Weight History</div></Col>
                </Row>
                <Row>
                  <Col width="25">
                    DATE
                                    </Col>
                  <Col width="20">
                    WEIGHT
                                    <span className="subTitle">kg</span>
                  </Col>
                  <Col width="10">
                    BMI
                                    </Col>
                  <Col width="10" className="wb-hh">
                    VISCERAL FAT
                                    </Col>
                  <Col width="15" className="wb-hh">
                    SKELETAL MUSCLE
                                    </Col>
                  <Col width="10" className="wb-hh">
                    BODY AGE
                                    </Col>
                  <Col width="15" className="wb-hh">
                    RESTIG METABOLISM
                                    </Col>
                  <Col width="10">
                    NOTES
                                    </Col>
                </Row>
              </div>
              <div className="grayLine"></div>
              <div className="popBody">
                {Object.keys(this.state.weightHistory).map((item, index) => {
                  return (
                    <React.Fragment key={`weight_${item}`}>
                      <Row>
                        <Col>
                          <span className="dataDate"> {item}</span>
                        </Col>
                      </Row>
                      {
                        this.state.weightHistory && this.state.weightHistory[item] ? (
                          this.state.weightHistory[item].map((iitem, iindex) => {
                            return (
                              <React.Fragment key={`weight_${iitem.id}`}>
                                <Row>
                                  <Col width="25">
                                    {DispalyDateTime(iitem.search_device_date_from)}
                                  </Col>
                                  <Col width="20">
                                    <span className={`dataForm ${FirstCaseUpper(iitem.location)}`}>{FirstCaseUpper(iitem.location)}</span>
                                    <span>{iitem.measure1}</span>
                                  </Col>
                                  <Col width="10">
                                    {iitem.measure2}
                                  </Col>
                                  <Col width="10">{iitem.visceral_fat < 0 ? '--' : iitem.visceral_fat}</Col>
                                  <Col width="15">{iitem.skeletal_muscle < 0 ? '--' : iitem.skeletal_muscle}</Col>
                                  <Col width="10">{iitem.body_age < 0 ? '--' : iitem.body_age}</Col>
                                  <Col width="15">{iitem.resting_metabolism < 0 ? '--' : iitem.resting_metabolism}</Col>
                                  <Col width="10">
                                    {iitem.note}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          })
                        ) : ''
                      }
                      <br />
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="popFotter">
                <div className="grayLine"></div>
                <Button
                  className="btn-red"
                  popupClose
                >
                  Close
                            </Button>
              </div>
            </Page>
          </Popup>

          {/* stepHistory */}
          <Popup
            // opened
            className="historyPopUP stepHistory-popup"
          >
            <Page>
              <div className="popHeader">
                <Row>
                  <Col><div className="title">Step History</div></Col>
                </Row>
                <Row>
                  <Col width="40">
                    DATE
                                </Col>
                  <Col width="40">
                    STEPS
                                </Col>
                  <Col width="20">
                    NOTES
                                    </Col>
                </Row>
              </div>
              <div className="grayLine"></div>
              <div className="popBody">
                {Object.keys(this.state.stepHistory).map((item, index) => {
                  return (
                    <React.Fragment key={`step_${item}`}>
                      <Row>
                        <Col>
                          <span className="dataDate"> {item}</span>
                        </Col>
                      </Row>
                      {
                        this.state.stepHistory && this.state.stepHistory[item] ? (
                          this.state.stepHistory[item].map((iitem, iindex) => {
                            return (
                              <React.Fragment key={`step_${iitem.id}`}>
                                <Row>
                                  <Col width="40">
                                    {showDate(iitem.date, 5)}
                                  </Col>
                                  <Col width="40">
                                    <span>{iitem.step}</span>
                                  </Col>
                                  <Col width="20">
                                    {iitem.note}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          })
                        ) : ''
                      }
                      <br />
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="popFotter">
                <div className="grayLine"></div>
                <Button
                  className="btn-red"
                  popupClose
                >
                  Close
                            </Button>
              </div>
            </Page>
          </Popup>


          {/* sleepHistory */}
          <Popup
            // opened
            className="historyPopUP sleepHistory-popup"
          >
            <Page>
              <div className="popHeader">
                <Row>
                  <Col><div className="title">Sleep History</div></Col>
                </Row>
                <Row>
                  <Col width="20">
                    DATE
                                    </Col>
                  <Col width="45">
                    SLEEP
                                    <span className="subTitle">Sleep time - Wake up</span>
                  </Col>
                  <Col width="10">
                    HOURS
                                    </Col>
                  <Col width="20">
                    NOTES
                                    </Col>
                </Row>
              </div>
              <div className="grayLine"></div>
              <div className="popBody">
                {Object.keys(this.state.sleepHistory).map((item, index) => {
                  return (
                    <React.Fragment key={`sleep_${item}`}>
                      <Row>
                        <Col>
                          <span className="dataDate"> {item}</span>
                        </Col>
                      </Row>
                      {
                        this.state.sleepHistory && this.state.sleepHistory[item] ? (
                          this.state.sleepHistory[item].map((iitem, iindex) => {
                            return (
                              <React.Fragment key={`sleep_${iitem.id}`}>
                                <Row>
                                  <Col width="20">
                                    {showDate(iitem.date, 5)}
                                  </Col>
                                  <Col width="45">
                                    <span className={`dataForm ${FirstCaseUpper(iitem.location)}`}>{FirstCaseUpper(iitem.location)}</span>
                                    <span>{showDate(iitem.date + ' ' + iitem.sleep_time, 6)} - {showDate(iitem.date + ' ' + iitem.wake_up_time, 6)}</span>
                                  </Col>
                                  <Col width="10">
                                    {iitem.hour}
                                  </Col>
                                  <Col width="20">
                                    {iitem.note}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          })
                        ) : ''
                      }
                      <br />
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="popFotter">
                <div className="grayLine"></div>
                <Button
                  className="btn-red"
                  popupClose
                >
                  Close
                            </Button>
              </div>
            </Page>
          </Popup>



          {/* bloodGlucoseHistory */}
          <Popup
            // opened
            className="historyPopUP bloodGlucoseHistory-popup"
          >
            <Page>
              <div className="popHeader">
                <Row>
                  <Col><div className="title">Blood Glucose History</div></Col>
                </Row>
                <Row>
                  <Col width="30">
                    DATE
                  </Col>
                  <Col width="30">
                    BLOOD GLUCOSE
                    <span className="subTitle">mmol/L</span>
                  </Col>
                  <Col width="25">
                    BEFORE/AFTER MEAL
                  </Col>
                  <Col width="15">
                    NOTES
                  </Col>
                </Row>
              </div>
              <div className="grayLine"></div>
              <div className="popBody">
                {Object.keys(this.state.bloodGlucoseHistory).map((item, index) => {
                  // console.log(this.state.bloodGlucoseHistory)
                  return (
                    <React.Fragment key={`bloodGlucose_${item}`}>
                      <Row>
                        <Col>
                          <span className="dataDate"> {item}</span>
                        </Col>
                      </Row>
                      {
                        this.state.bloodGlucoseHistory && this.state.bloodGlucoseHistory[item] ? (
                          this.state.bloodGlucoseHistory[item].map((iitem, iindex) => {
                            return (
                              <React.Fragment key={`bloodGlucose_${iitem.id}`}>
                                <Row>
                                  <Col width="30">
                                    {showDate(iitem.datetime, 7)}
                                  </Col>
                                  <Col width="30">
                                    <span className={`dataForm ${FirstCaseUpper(iitem.location)}`}>{FirstCaseUpper(iitem.location)}</span>
                                    <span style={{ color: iitem.blood_glucose >= 11.1 ? 'red' : null }}>{iitem.blood_glucose}</span>
                                  </Col>
                                  <Col width="25">
                                    {iitem.tag == 1 ? 'Before meal' : null}
                                    {iitem.tag == 2 ? 'After meal' : null}
                                  </Col>
                                  <Col width="15">
                                    {iitem.note}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          })
                        ) : ''
                      }
                      <br />
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="popFotter">
                <div className="grayLine"></div>
                <Button
                  className="btn-red"
                  popupClose
                >
                  Close
                            </Button>
              </div>
            </Page>
          </Popup>


          {/* temperatureHistory */}
          <Popup
            // opened
            className="historyPopUP temperatureHistory-popup"
          >
            <Page>
              <div className="popHeader">
                <Row>
                  <Col><div className="title">Temperature History</div></Col>
                </Row>
                <Row>
                  <Col width="40">
                    DATE
                                    </Col>
                  <Col width="40">
                    TEMPERATURE
                                    <span className="subTitle">℃</span>
                  </Col>
                  <Col width="20">
                    NOTES
                                </Col>
                </Row>
              </div>
              <div className="grayLine"></div>
              <div className="popBody">
                {Object.keys(this.state.temperatureHistory).map((item, index) => {
                  // console.log(this.state.temperatureHistory)
                  return (
                    <React.Fragment key={`bloodGlucose_${item}`}>
                      <Row>
                        <Col>
                          <span className="dataDate"> {item}</span>
                        </Col>
                      </Row>
                      {
                        this.state.temperatureHistory && this.state.temperatureHistory[item] ? (
                          this.state.temperatureHistory[item].map((iitem, iindex) => {

                            return (
                              <React.Fragment key={`bloodGlucose_${iitem.id}`}>
                                <Row>
                                  <Col width="40">
                                    {DispalyDateTime(iitem.datetimeFull)}
                                  </Col>
                                  <Col width="40">
                                    <span className={`dataForm ${FirstCaseUpper(iitem.location)}`}>{FirstCaseUpper(iitem.location)}</span>
                                    <span style={{ color: iitem.temperature >= 37.9 ? 'red' : null }}>{iitem.temperature}</span>
                                  </Col>
                                  <Col width="20">
                                    {iitem.note}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          })
                        ) : ''
                      }
                      <br />
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="popFotter">
                <div className="grayLine"></div>
                <Button
                  className="btn-red"
                  popupClose
                >
                  Close
                            </Button>
              </div>
            </Page>
          </Popup>


          {/* Spo2 */}
          <Popup
            // opened
            className="historyPopUP spo2History-popup"
          >
            <Page>
              <div className="popHeader">
                <Row>
                  <Col><div className="title">SPO2 History</div></Col>
                </Row>
                <Row>
                  <Col width="40">
                    DATE
                                    </Col>
                  <Col width="40">
                    SPO2
                                    <span className="subTitle">%</span>
                  </Col>
                  <Col width="20">
                    NOTES
                                    </Col>
                </Row>
              </div>
              <div className="grayLine"></div>
              <div className="popBody">
                {Object.keys(this.state.spo2History).map((item, index) => {
                  return (
                    <React.Fragment key={`bloodGlucose_${item}`}>
                      <Row>
                        <Col>
                          <span className="dataDate"> {item}</span>
                        </Col>
                      </Row>
                      {
                        this.state.spo2History && this.state.spo2History[item] ? (
                          this.state.spo2History[item].map((iitem, iindex) => {
                            return (
                              <React.Fragment key={`bloodGlucose_${iitem.id}`}>
                                <Row>
                                  <Col width="40">
                                    {DispalyDateTime(iitem.datetimeFull)}
                                  </Col>
                                  <Col width="40">
                                    <span className={`dataForm ${FirstCaseUpper(iitem.location)}`}>{FirstCaseUpper(iitem.location)}</span>
                                    <span style={{ color: iitem.spo2 >= 92 ? 'red' : null }}>{iitem.spo2}</span>
                                  </Col>
                                  <Col width="20">
                                    {iitem.note}
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )
                          })
                        ) : ''
                      }
                      <br />
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="popFotter">
                <div className="grayLine"></div>
                <Button
                  className="btn-red"
                  popupClose
                >
                  Close
                            </Button>
              </div>
            </Page>
          </Popup>


        </Row>
      </div>
    );
  }
}

export default Graph;