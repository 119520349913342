import { FETCH_SESSIONLIST, FETCH_REDEEMSESSION } from "../actionTypes";

const initalState = {
    sessionListData: {},
}

export default function (state = initalState, action) {
    // console.log(state);
    // console.log(action);
    const { sessionListData } = state;
    var sessionListUpdateData = sessionListData;
    switch (action.type) {
        case FETCH_SESSIONLIST:
            return {
                ...state,
                sessionListData: action.sessionListResult,
            };
        case FETCH_REDEEMSESSION:
            const _sessionList = {
                result:{}
            };
            if (action.sessionUpdated){
                let list = sessionListUpdateData.result;
                if(action.sessionUpdated.id){
                    let index = list.findIndex((e) => e.id === action.sessionUpdated.id);
                    list[index] = action.sessionUpdated;
                    _sessionList.result = list
                }
            }
            return {
                ...state,
                sessionListData: _sessionList
            };    
        default:
            return state;
    }
}