import { EThree } from '@virgilsecurity/e3kit-browser';
import { ErrorCode } from '../commons/E3KitConstants';
class E3Kit {
  register = null;
  eThree = null;
  group = null;
  findUsersCard = [];

  constructor(props) {
    this.tokenCallback = props.tokenFunction ? props.tokenFunction : null;
    this.register = props.register ? props.register : null;
  }

  initEthree = async () => {
    try {
      this.eThree = await EThree.initialize(() => {
        return this.tokenCallback;
      });
      return true;
    } catch (error) {
      console.error(`initEthree: ${error}`);
      return false;
    }
  };

  /**注册E3kit
   * 功能检查用户是否已经在本地存储中保存了私钥，
   * 以及在Virgil Cloud上是否已发布了公钥。
   * 如果用户没有它们，该函数将为用户生成一个新的密钥对，
   * 将私钥保存在本地，然后在Virgil Cloud上发布公钥（供其他用户参考）
   */
  registerEthree = async (keyPassword = '') => {
    try {
      await this.eThree.register();
      if(keyPassword){
        let backupPrivateKeyRes =  await this.backupPrivateKey(keyPassword);
        if(backupPrivateKeyRes.status){
            console.log("registerEthree backupPrivateKeyRes ok");
        }else{
            console.error("registerEthree backupPrivateKeyRes error!");
            return {
              status: false,
              errorCode: '',
            }; 
        }
      }
      return {
        status: true,
        errorCode: '',
      };
    } catch (error) {
      if (
        error.name === ErrorCode.IdentityAlreadyExistsError ||
        error.name === ErrorCode.MultipleCardsError
      ) {
        console.error(`registerEthreeError: ${error}`);
        await this.cleanupEthree();
        return {
          status: false,
          errorCode: error.name,
        };
      } else {
        console.error(`registerEthreeError: ${error}`);
        return {
          status: false,
          errorCode: error.name,
        };
      }
    }
  };

  //取消注册用户
  /**使用eThree.unregister将从本地存储中删除私钥并
   * 从Virgil Cloud中删除公钥的方法，从而逆转注册功能的操作。
   * 请注意，此方法不会删除用户的密钥备份。
   */
  unregisterEthree = async () => {
    try {
      await this.eThree.cleanup();
      await this.eThree.unregister();
    } catch (error) {
      console.error(`${this.register} unregisterEthree: ${error}`);
    }
  };

  //注销用户
  /**清除方法从用户的设备中删除用户的私钥，
   * 以便为生成新的密钥对（旋转密钥对）做好准备，
   * 或抓住当前设备对用户数据的访问
   * 如果将来需要在另一台设备上使用当前私钥，请确保进行备份。
   * 要从其设备中删除用户的私钥，
   */
  cleanupEthree = async () => {
    try {
      let cleanupRes = await this.eThree.cleanup();
      console.log(cleanupRes);
    } catch (error) {
      console.error(`${this.register} cleanupEthree: ${error}`);
    }
  };

  //重置用户
  /**
   * 重置用户意味着生成新的密钥对，在本地保存新的私钥，
   * 在Virgil Cloud上发布新的公共密钥并将旧发布的旧密钥标记为过时。
   * 如果发布了用户的公钥，但私钥已丢失（并且没有备份）或被盗（变得无效），请使用此流程。
   * 在执行此流程之前，必须删除现有的私钥并删除私钥备份（如果有）
   * 如果用户在进行备份之前丢失了私钥，则将无法解密以前加密的数据
   */
  rotatePrivateKey = async () => {
    try {
      console.log(`start of snippet: e3kit_rotate_private_key`);
      await this.eThree.rotatePrivateKey();
      console.log(`end of snippet: e3kit_rotate_private_key`);
    } catch (error) {
      if (error.name === ErrorCode.PrivateKeyAlreadyExistsError) {
        await this.eThree.cleanup();
        this.log(`Cleaned up. Trying again...`);
        await this.eThree.rotatePrivateKey();
      } else if (error.name === ErrorCode.MultipleCardsError) {
        console.error(`rotatePrivateKeyError: ${error}`);
      } else {
        console.error(`rotatePrivateKeyError: ${error}`);
      }
    }
  };

  //备份用户私钥
  /**
   * 帮助用户备份私钥
   */
  backupPrivateKey = async (userKeyPasswrod) => {
    try {
      await this.eThree.backupPrivateKey(userKeyPasswrod);
      return {
        status: true,
      };
    } catch (error) {
      if (error.name === ErrorCode.CloudEntryExistsError) {
        console.log("备份私钥已经存在！");
        return {
            status: true,
        };        
      } else {
        console.error(`backupPrivateKeyError: ${error}`);
        return {
          status: false,
        };
      }
    }
  };

  // 创建群聊
  createGroup = async (groupId, users_id) => {
    // groupId = 'unique_group_id';
    console.group("createGroup");
    console.log(groupId, users_id);
    try {
      let participants = await this.findUsers(users_id);
      this.group = await this.eThree.createGroup(groupId, participants.res);
      console.log(this.group);
      return {
        status: true,
        errorCode: ''
      };
    } catch (error) {
      console.log(error.name);
      if (error.name === ErrorCode.MissingPrivateKeyError) {
        return {
          status: false,
          errorCode: error.name,
        };
      } else if (error.name === ErrorCode.GroupTicketAlreadyExistsError) {
        return {
          status: false,
          errorCode: error.name,
        };
        // await this.deleteGroup(groupId);
        // await this.createGroup(groupId, users_id);
      } else {
        console.error(`createGroup: ${error}`);
        return {
          status: false,
          errorCode: error.name,
        };
      }
    }
    console.groupEnd();
  };

  //获取组成员
  findUsers = async (usersID) => {
    // usersID = ['bob@myapp.com', 'carol@myapp.com']
    try {
      let participants = await this.eThree.findUsers(usersID);
      return {
        status: true,
        res: participants,
      };
    } catch (error) {
      if (error.message === ErrorCode.UserJWTExpired) {
        console.error(`findUsersError: ${error}`);
        return {
          status: false,
          errorCode: error.message,
        };
      }else if (error.name === ErrorCode.UsersNotFoundError) {
        console.error(`findUsersError: ${error}`);
        return {
          status: false,
          errorCode: error.name,
        };
      } else if (error.name === ErrorCode.UsersFoundWithMultipleCardsError) {
        console.error(`findUsersError: ${error}`);
        return {
          status: false,
          errorCode: error.name,
        };
      } else {
        console.error(`findUsersError: ${error}`);
        return {
          status: false,
          errorCode: error.name,
        };
      }
    }
  };

  //判断本地是否有备份密钥
  hasLocalPrivateKey = async () => {
    try {
      let hasLocalPrivateKey = await this.eThree.hasLocalPrivateKey();
      return hasLocalPrivateKey;
    } catch (error) {
      console.error(`hasLocalPrivateKey: ${error}`);
      return false;
    }
  };

  //恢复秘钥
  restorePrivateKey = async (keyPassword) => {
    try {
      let res = await this.eThree.restorePrivateKey(keyPassword);
      return {
        status: true,
        res: res
      };
    } catch (error) {
        console.error(`restorePrivateKeyError: ${error}`);
        return {
          status: false,
          res: error.name,
        };
    }
  };

  //删除备份
  //如果您以前进行过用户的私钥备份，则可以轻松地从Virgil Cloud中删除此备份
  resetPrivateKeyBackup = async () => {
    try {
      await this.eThree.cleanup();
      await this.eThree.resetPrivateKeyBackup();
      console.log(`resetPrivateKeyBackup ok`);
    } catch (error) {
      console.error(`resetPrivateKeyBackup: ${error}`);
      return false;
    }
  };

  //将用户密码设为备份密码
  derivePasswords = async (userprofileID) => {
    try {
      const { loginPassword, backupPassword } = await EThree.derivePasswords(
        userprofileID
      );
      return backupPassword;
    } catch (error) {
      console.error(`derivePasswords: ${error}`);
      return false;
    }
  };

  //其他参与者希望访问已经存在的小组
  //groupLeaderId 是群主的id
  /**
   *现在，其他参与者（鲍勃和卡罗尔）希望访问爱丽丝的小组。他们必须使用该loadGroup方法启动小组会议。它会在本地加载并保存团体票。此功能需要指定组identifier和组所有者的卡 
   */
  loadGroup = async (groupId, groupLeaderId) => {
    // const groupId = 'unique_group_id';
    let groupLeaderCard = null; 
    try {
      groupLeaderCard = await this.findUsers(groupLeaderId);
      if(groupLeaderCard.errorCode && groupLeaderCard.errorCode === ErrorCode.UsersFoundWithMultipleCardsError){
        return {
            status: false,
            errorCode: groupLeaderCard.errorCode
        };
      }
      console.log("loadGroup-findUsers-groupLeaderId====>");
      console.log(`groupId --->${groupId}`);
      console.log(`groupLeaderId---->${groupLeaderId}`);
      console.log("groupLeaderCard", groupLeaderCard);
      console.log("<====loadGroup-findUsers-groupLeaderId");
      this.group = null;
      this.group = await this.eThree.loadGroup(groupId, groupLeaderCard.res);
      console.log('loadgroup',this.group)
      return {
        status: true,
        errorCode: '',
      };
    } catch (error) {
      console.log(error);
      console.error(`loadGroupError: ${error}`);
      if (error.message === ErrorCode.GroupIdNotFoundError || error.name === ErrorCode.UserNotAcceptError) {
        return {
          status: false,
          errorCode: error.message,
        };
      } else if (error.name === ErrorCode.VirgilCryptoError) {
        console.log(groupLeaderCard);
        return {
          status: false,
          errorCode: error.name,
        };
      } else {
        console.error(`loadGroupError: ${error}`);
        return {
          status: false,
          errorCode: error.name,
        };
      }
    }
  };

  //获取本地小组实例
  /**
   * 您可以使用该getGroup方法从本地存储中检索组实例。
   */
  getGroup = async (groupId) => {
    // const groupId = 'unique_group_id';
    try {
      this.group = await this.eThree.getGroup(groupId);
      return this.group;
    } catch (error) {
      console.error(`getGroup: ${error}`);
    }
  };

  //给小组添加成员
  addGroupUser = async (addUserID) => {
    // addUserID = john@myapp.com
    try {
      const newParticipant = await this.findUsers(addUserID);
      console.log(newParticipant);
      if(newParticipant.status){
        await this.group.add(newParticipant.res);
        await this.group.update();
        return {
          status: true,
        };

      } else {
        return {
          status: false
        };
      }
    } catch (error) {
      if (error.name === ErrorCode.UsersNotFoundError) {
        return {
          status: false,
          errorCode: error.name,
        };
      } else {
        console.error(`addGroupUser: ${error}`);
        return {
          status: false,
          errorCode: error.name,
        };
      }
    }
  };

  newLoadGroup = (newGroup) => {
    try {
      this.group = newGroup;
    } catch (error) {
      console.error(`load new Group: ${error}`);
    }
  };

  //删除小组成员
  /**
   *
   * @param {*} delUserID
   */
  delGroupUser = async (delUserID) => {
    // delUserID = 'john@myapp.com'
    try {
      const existingParticipant = await this.findUsers(delUserID);
      console.log(existingParticipant);
      await this.group.remove(existingParticipant.res);
      await this.group.update();
      console.log(`${delUserID} 删除成功！`);
    } catch (error) {
      console.error(`delGroupUser: ${error}`);
    }
  };

  //更新群聊
  /**
   * 如果您的网上论坛进行了更改（例如添加新的参与者或删除现有的参与者），
   * 则每个群组聊天参与者都必须通过致电来更新加密密钥，
   * update或者通过致电loadGroup：
   */
  updateGroup = async () => {
    try {
      await this.group.update();
    } catch (error) {
      console.error(`updateGroup: ${error}`);
    }
  };

  //删除群聊
  /**
   *
   */
  delGroup = async (groupId) => {
    try {
      let res = await this.eThree.deleteGroup(groupId);
      console.log("delGroup Success!!", res);
      return {
        status: true,
        res: res,
      };
    } catch (error) {
      console.error(`delGroup: ${error}`);
      return {
        status: false,
      }; 
    }
  };

  //加密消息_组
  encryptText = async (messageToEncrypt) => {
    try {
      let encryptedMsg = await this.group.encrypt(messageToEncrypt);
      return encryptedMsg;
    } catch (error) {
      console.log(error.name);
      console.error(`encryptText: ${error}`);
      return false;
    }
  };

  //加密文件_组
  encryptFiles = async (file) => {
    try {
      const { encryptedSharedFile, fileKey } = await this.eThree.encryptSharedFile(file);
      console.log('加密成功结果',encryptedSharedFile,fileKey)
      let encryptedFileKeyForGroup = await this.group.encrypt(fileKey);
      console.log('加密组成功结果',encryptedFileKeyForGroup)
      return {encryptedFileKeyForGroup: encryptedFileKeyForGroup,encryptedSharedFile: encryptedSharedFile};
    } catch (error) {
      console.log(`decryptFile : ${error}`);
      return {};
    }
    
  };
  
  //解密消息_组
  decryptText = async (msgSenderID, encryptedMsg) => {
    // msgSenderID = 'alice@myapp.com'
    let messageSenderCardInfo = null;
    try {
      if(this.findUsersCard && this.findUsersCard.length > 0){
        messageSenderCardInfo = this.findUsersCard.find(item => (item.msgSenderID == msgSenderID));
        if(!messageSenderCardInfo){
          let senderCard = await this.findUsers(msgSenderID);
          messageSenderCardInfo = {msgSenderID: msgSenderID,messageSenderCard: senderCard};
          this.findUsersCard.push({msgSenderID: msgSenderID,messageSenderCard: senderCard})
        }
      }else{
        let senderCard = await this.findUsers(msgSenderID);
        messageSenderCardInfo = {msgSenderID: msgSenderID,messageSenderCard: senderCard};
        this.findUsersCard.push({msgSenderID: msgSenderID,messageSenderCard: senderCard})
      }
      let decryptedMsg = await this.group.decrypt(encryptedMsg, messageSenderCardInfo.messageSenderCard.res);
      return decryptedMsg;
    } catch (error) {
      console.log(`decryptText : ${error}`);
      return false;
    }
  };

  //解密文件_组
  decryptFiles = async (encryptedFileKeyForGroup,encryptedSharedFile,msgSenderID) => {
    let messageSenderCardInfo = null;
    try {
      if(this.findUsersCard && this.findUsersCard.length > 0){
        messageSenderCardInfo = this.findUsersCard.find(item => (item.msgSenderID == msgSenderID));
        if(!messageSenderCardInfo){
          let senderCard = await this.findUsers(msgSenderID);
          messageSenderCardInfo = {msgSenderID: msgSenderID,messageSenderCard: senderCard};
          this.findUsersCard.push({msgSenderID: msgSenderID,messageSenderCard: senderCard})
        }
      }else{
        let senderCard = await this.findUsers(msgSenderID);
        messageSenderCardInfo = {msgSenderID: msgSenderID,messageSenderCard: senderCard};
        this.findUsersCard.push({msgSenderID: msgSenderID,messageSenderCard: senderCard})
      }
      console.log('finduserjei :',messageSenderCardInfo);
      const decryptedFileKey = await this.group.decrypt(encryptedFileKeyForGroup, messageSenderCardInfo.messageSenderCard.res);
      console.log('finduserjeikey :',decryptedFileKey);
      const decryptedSharedFile = await this.eThree.decryptSharedFile(
        encryptedSharedFile,
        decryptedFileKey,
        messageSenderCardInfo.messageSenderCard.res);
        console.log('finduserjeifile :',decryptedSharedFile);
      return decryptedSharedFile;
    } catch (error) {
        console.log(`decryptFile : ${error}`);
    }
  };
}

export default E3Kit;