import React, { Component } from 'react';
import { f7 as app, Page, Row, Col, Button, Appbar, View, Link, Tabs, Tab, Badge, Popup, Block, Icon, Preloader, Fab, PageContent} from 'framework7-react';
import {DispalyDateTime,ShowToast} from '../commons/Helpers'
import { WeekEveryDay, dayEveryTime, ROUTES, ApiUrl } from '../commons/Constants'
import UploadFiles from '../components/UploadFiles';
import AuthGuard from '../services/AuthGuard';
import Downloader from 'js-file-downloader';
import Pagination from '../components/Pagination';
import sousuo from '../images/icons/sousuo.png';
class MedicationList extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            name: '',
            status:'',
            uploadConfig:{},
            medication:[],
            total: 0,
            csvUrl: '',
            medication_documents: [],
            addCsvSuccess: [],
            disableFunction: false,
            page: 1,
            limit: 10,
            update_file_status: false,
         }
         this.handlePagination = this.handlePagination.bind(this);
    }

    // isNumber(num) {
    //     return typeof num === 'number';
    // }

    handlePagination (item) {
        const {page} = item;
        if (typeof page === 'number') {
            this.setState({
                page: page,
                // limit: 10,
            },()=>{
                this.btnSearchMedication();
            });
        }
    }

    onChange = e => {
        let { name, value } = e.target;
        this.setState({ name: value });
    }

    componentWillMount() {

    }

    componentDidMount() {
        //2 子组件 componentDidMount 中接收 onRef 并把 this 传给父组件
        this.props.onRef(this);
        this.btnSearchMedication();
        // this.getCsvUrl();
    }

    getCsvUrl(){
        AuthGuard.getMedicationCsvUrl({
            filename: 'Medication_Template'
        }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    csvUrl: res,
                })
            }
        })
    }

    downloadCsv(){
        app.preloader.show()
        new Downloader({ 
            url: ApiUrl.medTemplateFile,
        })
        .then(function () {
            // Called when download ended
            app.preloader.hide()
        })
        .catch(function (error) {
            // Called when an error occurred
        });
    }

    childFunction() {
        this.btnSearchMedication();
    }    

    componentWillUpdate() {
        app.preloader.hide();
    }


    btnSearchMedication(is_clear = false) {
        let { name, status, page, limit } = this.state;
        let new_page = page;
        if(is_clear){
            new_page = 1;
        }
        AuthGuard.getMedication({
            name: name,
            status: status,
            page:new_page,
            limit:limit,
        }, (isOk, res,total) => {
            if (isOk) {
                this.setState({
                    medication: res,
                    total: total,
                })
            }else{
                this.setState({
                    medication: [],
                    total: 0,
                })
            }
        })
    }

    uploadSuccess = (type, res) => {
        console.log(type, res)
        let _medication_documents = this.state.medication_documents;
        _medication_documents.push(res);
        this.setState({
            medication_documents: _medication_documents,
            disableFunction: true,
            update_file_status:false,
        })
    }

    uploadError = (type, res) => {
        console.log(type, res)
        this.setState({
            update_file_status: true,
        })
    }

    delDoc = () => {
        app.dialog.confirm('Are you sure delete it?', ()=> {
            app.preloader.show()
            this.setState({
                medication_documents: [],
                disableFunction: false,
            })
            app.preloader.hide();
        });
    }

    confirmBill(){
        if(this.state.medication_documents.length > 0){
            let file_name2 = this.state.medication_documents[0].s3url;
            app.preloader.show();
            AuthGuard.uploadMedicationCsv({
                fileName:file_name2
            } , (isOk, res) => {
                app.preloader.hide();
                if (isOk) {
                    this.setState({
                        addCsvSuccess:res,
                        medication_documents: [],
                        disableFunction: false,
                    })
                    this.btnSearchMedication();
                    app.popover.close('.uploadMedications-popup');
                    app.popover.open('.addMedication-popup');
                } else {
                    ShowToast(app, 'Add medication fail.');
                }
            });
        }else{
            app.dialog.alert('Please upload files.', 'Alert', () => {
            });
        }
    }

    clearMedicationPopup() {
        app.popover.close('.addMedication-popup')
    }

    addMedicationBtn = () => {
        app.views.main.router.navigate(ROUTES.addMedication, {
            // props: { role_id: id, role_accounts: role_accounts },
        });
    }

    btnMedicationDetail = (id,e) => {
        app.views.main.router.navigate(ROUTES.addMedication, {
            props: { medication_id: id},
        });
    }

    render() { 
        return (  
            <div>
                <Row>
                    <Col width="50">
                        <Row>
                            <Col width="30">
                                <Button className="fill-blue-btn fillBtn" popupOpen=".uploadMedications-popup" raised>Upload Medications</Button>
                            </Col>
                            <Col width="30">
                            <Button className="fill-blue-btn fillBtn" onClick={this.addMedicationBtn.bind()} raised>Add Medication</Button>
                            </Col>
                            <Col width="40"></Col>
                        </Row>
                    </Col>
                    <Col width="50">
                        <div className="txt-right">
                        No. of Medication: <b> {this.state.total} </b>
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row className="search-panel">
                    <Col width="10" className="search-filter filter-w">
                        Filters:
                    </Col>
                    <Col width="35">
                        <Row>
                            <Col className="search-input">
                                <input
                                    type='text'
                                    name="medicationName"
                                    className="nameOrId"
                                    placeholder="Medication Name"
                                    onChange={this.onChange}
                                />
                                {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                                <img src={sousuo} className="img-block" />
                            </Col>
                        </Row>
                    </Col>
                    <Col width="35">
                        <Row>
                            <Col className="search-input input-dropdown-wrap">
                                <select
                                    name="status"
                                    value={this.state.status}
                                    onChange={(e) => {
                                        this.setState({ status: e.target.value });
                                    }}
                                >
                                    <option value="">Status</option>
                                    <option value="active">ACTIVE</option>
                                    <option value="inactive">INACTIVE</option>
                                </select>
                            </Col>
                        </Row>
                    </Col>
                    <Col width="20">
                        <Row>
                            <Col width="100" medium="50">
                                <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearchMedication.bind(this,true)} >Search</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>  
                <div className="data-table card pateintItem">
                    <table id="tbl">
                        <thead>
                            <tr>
                                <th className="label-cell">NAME</th>
                                <th className="medium-only">TYPE</th>
                                <th className="medium-only">UNIT <br />PRICE</th>
                                <th className="medium-only">PURPOSE</th>
                                <th className="medium-only">PRECAUTIONS</th>
                                <th className="medium-only">MEASUREU <br />NIT</th>
                                <th className="medium-only">METHOD OF<br />CONSUMPTION</th>
                                <th className="medium-only">QTY</th>
                                <th className="medium-only">DOSAGE</th>
                                <th className="medium-only">FREQUENCY</th>
                                <th className="medium-only">DURATION</th>
                                <th className="medium-only">STATUS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.medication.map((item, index) => {
                                return (
                                    <tr key={item.id} onClick={this.btnMedicationDetail.bind(this,item.id)}>
                                        <td className="numeric-cell">{item.name}</td>
                                        <td className="numeric-cell">{item.type}</td>
                                        <td className="numeric-cell">{item.selling_price}</td>
                                        <td className="numeric-cell">{item.purpose}</td>
                                        <td className="numeric-cell">{item.precautions}</td>
                                        <td className="numeric-cell">{item.measurement_unit}</td>
                                        <td className="numeric-cell">{item.method_of_consumption}</td>
                                        <td className="numeric-cell">{item.quantity}</td>
                                        <td className="numeric-cell">{item.dosage}</td>
                                        <td className="numeric-cell">{item.frequency}</td>
                                        <td className="numeric-cell">{item.duration}</td>
                                        {item.status == 'active' ?
                                            <td className="numeric-cell actice-color">Active</td> : 
                                            <td className="numeric-cell inactice-color">Inactive</td>
                                        }
                                    </tr>
                                )
                            })}
                            {this.state.medication !== null && this.state.medication.length > 0 ? <tr style={{display:'none'}}><td colSpan="12"></td></tr> : <tr><td colSpan="12"><div style={{textAlign:'center'}}>No new medication</div></td></tr>}
                        </tbody>
                    </table>
                </div>
                <div style={{textAlign:'right',marginRight:'10px'}}>
                    <Pagination cur={this.state.page} all={Math.ceil(this.state.total / 10)} space={5} cb={this.handlePagination} />
                </div>
                <Popup
                    className="uploadMedications-popup"
                    swipeToClose
                    swipeHandler=".swipe-handler"
                >
                    <Page>
                        <div className="table-title">
                            <div className="table-title-left">
                                <div className="table-title-left-title">
                                    Upload Medication List
                                </div>
                                <div className="table-title-left-sub-title">
                                    File type must be .csv .xls
                                </div>
                            </div>
                            <div className="table-title-right">
                                <Button className="outline-blue-btn" onClick={this.downloadCsv.bind(this)}>Download Template</Button>
                            </div>
                        </div>
                        <div className="data-table mt20 inVoiceTable" >
                            <table>
                                <thead>
                                    <tr>
                                        <th className="label-cell">Date & Time</th>
                                        <th className="numeric-cell">Document Name</th>
                                        <th className="label-only">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {this.state.medication_documents.length > 0 ? this.state.medication_documents.map((item)=> {
                                    return (
                                        <tr key={item.s3url}>
                                            <td className="label-cell">{DispalyDateTime(item.time, 2, true).date} <br /> <span className="table-cell-desc">{DispalyDateTime(item.time, 2, true).time}</span></td>
                                            <td className="numeric-cell txt-green">{item.fileName}</td>
                                            <td className="label-only">
                                                <div className="table-btns">
                                                    {/* <Button href={item.public_file_url} external outline raised target="_blank" className="outline-blue-btn cell_btn">
                                                        View
                                                    </Button> */}
                                                    <Button outline raised className="outline-pink-btn cell_btn" onClick={this.delDoc.bind(this)} disabled={!this.state.disableFunction}> Delete </Button>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                }
                                ) : <tr></tr>}
                                    <tr>
                                        <td colSpan="3">
                                            <span style={{ display: this.state.medication_documents.length < 1 && !this.state.disableFunction ? 'block' : 'none' }}>
                                                <UploadFiles
                                                    maxFilesize='5'
                                                    maxFiles='1'
                                                    uploadSuccess={this.uploadSuccess.bind(this)}
                                                    uploadError={this.uploadError.bind(this)}
                                                    dropzoneSelector={'medication' + Date.now().toString()}
                                                    acceptedFiles='.csv, application/vnd.ms-excel, text/csv'
                                                    // uploadConfig={this.state.uploadConfig}
                                                />
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="txt-left">
                            <span className="errorMsg">{this.state.update_file_status ? 'This file type is not supported.' : ''}</span>
                        </div>
                        <br/>
                        <div className="footerLeftBtn">
                            <Button className="navbarBtn outline-blue-btn" popupClose>Close</Button>
                            <Button className="navbarBtn fill-blue-btn" onClick={this.confirmBill.bind(this)} disabled={!this.state.disableFunction} raised fill> Confirm </Button>
                        </div>
                    </Page>
                </Popup>
                <Popup
                    className="addMedication-popup"
                >
                    <Page>
                        <div style={{ marginTop: '25%', textAlign: 'center' }}>
                            <div className="addSuccessInfo">
                                {this.state.addCsvSuccess != '' ? <div><p><b>Upload Medications List Success!</b></p><p>{this.state.addCsvSuccess.total} medications has been created. <br />{this.state.addCsvSuccess.failed} failed to be created, please check <br />the file for errors<br /></p>{
                                    this.state.addCsvSuccess.errorMessage.split('<br/>').map((i,j) => <p key={j} style={{ color: 'red' }} >{i}</p>)
                                }</div>
                                 : <p></p>
                                }
                            </div>
                        </div>
                        <div className="footerCallPop">
                            <Row>
                                <Col>
                                    <Button fill raised className="fill-blue-btn" onClick={()=>this.clearMedicationPopup()}> OKAY </Button>
                                </Col>
                            </Row>
                        </div>
                    </Page>
                </Popup>
            </div>
        );
    }
}
 
export default MedicationList;