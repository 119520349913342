import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import rootReducers from './reducers'
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import {composeWithDevTools} from 'redux-devtools-extension/developmentOnly';

// const persistConfig = {
//     key: 'root',
//     storage: storage,
//     stateReconciler: autoMergeLevel2 // 查看 'Merge Process' 部分的具体情况
// };
// const myPersistReducer = persistReducer(persistConfig, rootReducers)

const initialState = {};
const middleware = [thunk];

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE__?
//     window.__REDUX_DEVTOOLS_EXTENSION__COMPOSE__({}) : compose

// const enhancer = composeEnhancers(applyMiddleware(...middleware));

// console.log(process.env.NODE_ENV);
// const devtools = process.env.NODE_ENV !== 'development'
//     ? x => x /* eslint-disable no-underscore-dangle */
//     : window.__REDUX_DEVTOOLS_EXTENSION__
//     && window.__REDUX_DEVTOOLS_EXTENSION__();
// /* eslint-enable no-underscore-dangle */
// console.log(devtools)

export const store = createStore(
//     myPersistReducer,
    rootReducers,
    initialState,
    composeWithDevTools(
        applyMiddleware(...middleware),
        // devtools
    ),
//     // enhancer
);

// export const persistor = persistStore(store)