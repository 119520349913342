import AuthGuard from '../services/AuthGuard';
import { 
    FETCH_LOGIN ,
    FETCH_SESSIONLIST,
    FETCH_REDEEMSESSION,
    FETCH_LOGOUT,
    FETCH_PATIENTLIST,
    FETCH_CHATLIST,
    FETCH_PATIENTDETAIL,
    FETCH_CREATEDIALOG,
    FETCH_UPDATEUSERGOAL,
    FETCH_OTPLOGIN
} from "./actionTypes";

export const UserLogin = (userID, password ) => dispatch => {

    AuthGuard.login({ userID, password}, (isOk, res) => {
        dispatch({
            type: FETCH_LOGIN,
            loginResult: res,
        });
    });
}

export const otpLogin = (userID, otp_type, otp_code) => dispatch =>{
    AuthGuard.otpLogin({ userID, otp_type, otp_code }, (isOk, res) => {
        dispatch({
            type: FETCH_OTPLOGIN,
            otpLoginResult: res,
        });
    });
}

export const UserLogout = () => dispatch => {
    AuthGuard.logout((isOk, res) => {
        dispatch({
            type: FETCH_LOGOUT,
            logoutResult: res,
        });
    });
}

export const GetSessionList = (session_id, mobile_number, email, status_code) => dispatch =>{
    AuthGuard.getSessionList({ session_id, mobile_number, email, status_code},(isOk ,res ) =>{
        dispatch({
            type: FETCH_SESSIONLIST,
            sessionListResult: isOk ? res : {},
        });
    });
}

export const RedeemSessionStatus = (id) => dispatch =>{
    AuthGuard.redeemSessionStatus({ id }, (isOk, res) => {
        dispatch({
            type: FETCH_REDEEMSESSION,
            sessionUpdated: (res ? res : false),
        });
    });
}


export const GetPatientList = (accountIDorName, email, day7) => dispatch => {
    AuthGuard.getPatientList({ accountIDorName, email, day7 }, (isOk, res) => {
        dispatch({
            type: FETCH_PATIENTLIST,
            patientListResult: isOk ? res : {},
        });
    });
}

export const GetChatList = () => dispatch => {
    AuthGuard.getChatList({ }, (isOk, res) => {
        dispatch({
            type: FETCH_CHATLIST,
            chatListResult: isOk ? res : {},
        });
    });
}

export const GetPatientDetail = (userProfileId) => dispatch => {
    AuthGuard.getPatientDetail({userProfileId:userProfileId}, (isOk, res) => {
        dispatch({
            type: FETCH_PATIENTDETAIL,
            patientDetail: isOk ? res : {},
        });
    });
}

export const CreateDialog = (userProfileId) => dispatch => {
    AuthGuard.createDialog({ userId: userProfileId }, (isOk, res) => {
        dispatch({
            type: FETCH_CREATEDIALOG,
            dialogInfo: isOk ? res : {},
        });
    });
}

export const updateUserGoal = (_userProfileId, _sysGoal, _diaGoal) => dispatch => {
    AuthGuard.upateUserGoalBP({ userProfileId: _userProfileId, sysGoal: _sysGoal, diaGoal: _diaGoal}, (isOk, res) => {
        dispatch({
            type: FETCH_UPDATEUSERGOAL,
            userGoal: isOk ? res : {},
        });
    });
}