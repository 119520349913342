import React, { Component } from 'react';
import { f7 as app, Row, Col } from 'framework7-react';
import AuthGuard from '../services/AuthGuard';
import { isInvalid } from '../commons/Helpers';
class CountryProvinceCity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            provinceList: [],
            cityList: [],
            selected_country_code: '',
            selected_country_name: '',
            selected_province_code: '',
            selected_province_name: '',
            selected_city_code: '',
            selected_city_name: '',
            disableSelectProvince: false,
            disableSelectCity: false
        }
    }

    componentDidMount() {
        this.getCountryList()
    }

    componentWillReceiveProps(nextporps) {
        // this.setState({
        //     selected_country_code: nextporps.selectedCountryCode || '',
        //     selected_province_code: nextporps.selectedProvinceCode || '',
        //     selected_city_code: nextporps.selectedCityCode || ''
        // },()=>{
        //     this.updateSelectList()
        // })
    }

    getCountryList() {
        AuthGuard.getCountryList({
            page: 1,
            limit: 99999
        }, (isoK, res) => {
            // console.log(res)
            if (isoK) {
                this.setState({
                    countryList: res
                })
            }
        })
    }

    getProvinceList() {
        let { selectedProvinceCode } = this.props
        let self = this
        AuthGuard.getProvinceList({
            page: 1,
            limit: 99999,
            code: this.state.selected_country_code
        }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    provinceList: res[0].province_list,
                    cityList: [],
                    disableSelectProvince: false,
                    disableSelectCity: false
                }, () => {

                    // console.log(this.state.cityList)
                    // console.log(this.state.selected_city_code)
                    // console.log(this.state.selected_country_code)

                    // console.log(selectedProvinceCode)
                    if (selectedProvinceCode) {
                        self.provinceChange(selectedProvinceCode, 'propsChange')
                    }
                })
            }
        })
    }

    getCityList() {
        let { selectedCityCode } = this.props
        let self = this

        if (this.state.selected_city_code !== 'SG') {
            AuthGuard.getCityList({
                page: 1,
                limit: 99999,
                province_code: this.state.selected_province_code
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        cityList: res,
                        disableSelectCity: false
                    }, () => {
                        if (selectedCityCode) {
                            self.cityChange(selectedCityCode, 'propsChange')
                        }
                    })
                }
            })
        } else {
            AuthGuard.getCityList({
                page: 1,
                limit: 99999,
                province_code: this.state.selected_province_code
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        cityList: res,
                        disableSelectCity: false
                    }, () => {
                        // if (selectedCityCode) {
                        //     self.cityChange(selectedCityCode, 'propsChange')
                        // }
                    })
                }
            })
        }


    }

    countryChange(e, type) {
        let country_code = ''
        if (type === 'propsChange') {
            country_code = e
        } else {
            country_code = e.target.value
        }
        let { countryList } = this.state
        let selectedCountry = countryList.find(item => item.code === country_code)
        this.setState({
            selected_country_code: country_code,
            selected_country_name: selectedCountry ? selectedCountry.name : 'unknown',
            disableSelectProvince: true,
            disableSelectCity: true,
        }, () => {
            this.getProvinceList()

            if (this.state.selected_country_code === 'SG') {

                this.setState({
                    selected_province_code: 'SG-SG',
                    selected_province_name: 'SINGAPORE',
                    disableSelectCity: true,
                    selected_city_code: 'SG',
                    selected_city_name: 'SINGAPORE'
                }
                )
                // ,

                // () => {
                //     // this.getCityList()

                // console.log('countryChange')

                //     this.setState({
                //         selected_city_code: 'SG',
                //         selected_city_name: 'SINGAPORE'
                //     }, () => {
                //         // this.getCityList()

                //         console.log(this.state.selected_city_code, this.state.selected_city_name)

                //     });

                // });
            }

        });
    }

    provinceChange(e, type) {
        let province_code = ''
        if (type === 'propsChange') {
            province_code = e
        } else {
            province_code = e.target.value
        }

        let { provinceList } = this.state
        let selectedProvince = provinceList.find(item => item.code === province_code)


        // console.log(provinceList)
        // console.log(selectedProvince)
        if (selectedProvince) {
            this.setState({
                selected_province_code: province_code,
                selected_province_name: selectedProvince.name,
                disableSelectCity: true
            }, () => {
                this.getCityList()
            });
        } else {
            this.setState({
                selected_province_code: 'SG-SG',
                selected_province_name: 'SINGAPORE',
                disableSelectCity: true,
                selected_city_code: 'SG',
                selected_city_name: 'SINGAPORE'
            }, () => {
                this.getCityList()

                // console.log(this.state.selected_city_code)

            });
        }

    }

    cityChange(e, type) {

        let city_code = ''
        if (type === 'propsChange') {
            city_code = e
        } else {
            city_code = e.target.value
        }

        let { cityList } = this.state
        let selectedCity = cityList.find(item => item.code === city_code)
        if (!isInvalid(selectedCity)) {
            this.setState({
                selected_city_code: city_code,
                selected_city_name: selectedCity.name
            });
        }

    }

    updateSelectList() {
        let { selectedCountryCode } = this.props
        if (selectedCountryCode) {
            this.countryChange(selectedCountryCode, 'propsChange')
        }
    }

    render() {
        return (
            <div>
                <Row noGap>
                    <Col className="formSelect search-input input-dropdown-wrap">
                        <select
                            value={this.state.selected_country_code}
                            onChange={this.countryChange.bind(this)}
                        >
                            <option value="">Please Select</option>
                            {this.state.countryList && this.state.countryList.map((item, index) => {
                                return <option value={item.code} key={item.id}>{item.name}</option>
                            })}
                        </select>
                    </Col>
                    <Col className="formSelect search-input input-dropdown-wrap">
                        <select
                            value={this.state.selected_province_code}
                            onChange={this.provinceChange.bind(this)}
                            disabled={this.state.disableSelectProvince}
                        >
                            <option value="">Please Select</option>
                            {this.state.provinceList && this.state.provinceList.map((item, index) => {
                                return <option value={item.code} key={item.id}>{item.name}</option>
                            })}
                        </select>
                    </Col>
                    <Col className="formSelect search-input input-dropdown-wrap">
                        <select
                            value={this.state.selected_city_code}
                            onChange={this.cityChange.bind(this)}
                            disabled={this.state.disableSelectCity}
                        >
                            <option value="">Please Select</option>
                            {this.state.selected_country_code !== 'SG' ? this.state.cityList && this.state.cityList.map((item, index) => {
                                return <option value={item.code} key={item.id}>{item.name}</option>
                            }) : <option value='SG'>SINGAPORE</option>
                            }
                        </select>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CountryProvinceCity;