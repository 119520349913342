import React, { Component } from 'react';
import { f7 as app, View, Page, Link, Row, Col, Button, List, ListItem, Block, Icon } from 'framework7-react';
import SideBar from '../components/SideBar'
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { InputBlur, VerificationFields, ShowToast, setCache, justEmail, formatEmail, currencyType, getCache } from '../commons/Helpers';

class AddProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addedAccount:false,
            addedAccounts: [],
            teleconsultation_currency: 'SGD',
            teleconsultation_fee:'0.00',
            teleconsultation_symbol:'$',
            totalAmount:0.00,
            providers:[
                {
                    name: '',
                    email: ''
                }
            ]
        }
        
        this.coachProfile = AuthGuard.getCoachProfile();
    }

    componentDidMount() {
        let self = this;
        this.getMerchantInfo()
        setTimeout(this.paybackInfo.bind(this), 100)
    }

    getMerchantInfo () {
        let { clinic_user_profile_id } =  this.coachProfile
        AuthGuard.merchantDetail({
            id: clinic_user_profile_id
        },(isOk ,res)=>{
            if (isOk){
                this.setState({
                    ...res,
                    totalAmount: res.teleconsultation_fee
                })
            }
        })
    }

    backToList () {
        app.views.main.router.navigate(ROUTES.setting, { props: {activeTabType: 'manageAccountsTab'} })
    }

    paybackInfo () {
        let transaction_id = getCache('transaction_id');
        if (!transaction_id && this.props.params && this.props.params.token) {
            window.history.pushState({}, null, window.location.origin + '/#/');
            app.views.main.router.navigate(ROUTES.setting, { props: {activeTabType: 'manageAccountsTab'} })
            return;
        }

        if (transaction_id && this.props.params && this.props.params.token) {
            let itv = setInterval(() => {
                app.preloader.visible || app.preloader.show();
            }, 100)
            app.preloader.show()
            AuthGuard.checkoutInfo({
                'transaction_id': transaction_id,
                'response': this.props.params.token
            }, (isOk, res) => {
                app.preloader.hide()
                clearInterval(itv)

                if (isOk) {
                    // 兼容接口返回对象的情况
                    // {1: "gavin+24@iappsasia.com"}
                    if (typeof res === 'object') {
                        res = Object.values(res)
                    }
                    this.setState({
                        addedAccount: true,
                        addedAccounts: res
                    })
                }
                setCache('transaction_id', '')

                window.history.pushState({}, null, window.location.origin + '/#/');
            })
        }
    }

    createAccountBtn = () => {
        let { teleconsultation_currency, teleconsultation_fee } = this.state
        let providers = this.state.providers
        let getError = false
        if (providers.length){
            providers.map((item, index)=>{
                if (!item.name){
                    getError = true
                    item.name_error = true
                }else{
                    item.name_error = false
                }

                if (!item.email) {
                    getError = true
                    item.email_error = true
                }else{
                    item.email_error = false
                }
            })
        }else{
            ShowToast(app, 'Your must fill account info first.')
            return false
        }
        this.setState({
            providers: providers
        })

        if (getError){
            return false
        }
        
        let _providers = providers.map((item, index) => {
            return {
                name: item.name,
                email: item.email,
                currency_code: teleconsultation_currency,
                unit_price: teleconsultation_fee
            }
        })

        let itv = setInterval(() => {
            app.preloader.visible || app.preloader.show();
        }, 100)
        app.preloader.show()
        AuthGuard.addProvider({
            list_doctor: JSON.stringify(_providers)
        }, (isok, res) => {
            app.preloader.hide()
            clearInterval(itv)

            if (!isok) {
                setCache('transaction_id', '')
                ShowToast(app, res.message)
                return
            }

            if (res.paymentRequest && res.paymentRequest.ocbc_submit_url) {
                // 跳转支付
                setCache('transaction_id', res.transaction.id);
                window.location.href = res.paymentRequest.ocbc_submit_url + '?' + res.paymentRequest.param_query_string;
            } else if (teleconsultation_fee == 0 && res.email_array) {
                // 不用付钱

                // 兼容接口返回对象的情况
                // {1: "gavin+24@iappsasia.com"}
                if (typeof res.email_array === 'object') {
                    res.email_array = Object.values(res.email_array)
                }
                this.setState({
                    addedAccount: true,
                    addedAccounts: res.email_array
                })
            }
            // this.setState({
            //     addedAccount: true
            // })
        })

    }

    inputBlur = (index, e) =>{
        if(formatEmail(e.target.value)){

        }else{
            ShowToast(app, 'Invalid email.')
        }
        
        //
    }

    addProvider = ()=>{
        let { providers, teleconsultation_fee} = this.state
        let _providers = providers
        _providers.push({
            name: '',
            email: ''
        })

        this.setState({
            providers: _providers,
            totalAmount: _providers.length ? _providers.length * parseFloat(teleconsultation_fee) : teleconsultation_fee
        })
    }

    delProvider = (index)=>{
        let { providers, teleconsultation_fee} = this.state
        let _providers = providers
        _providers.splice(index , 1)
        this.setState({
            providers: _providers,
            totalAmount: _providers.length ? _providers.length * parseFloat(teleconsultation_fee) : 0.00
        })
    }

    handleChange = (index, type, e)=>{
        let _providers = this.state.providers
        _providers[index][type] = e.target.value;
        this.setState({
            providers : _providers
        })
    }
    
    AddAccountForm() {
        return (
            <form id='create-new-provider'>
                <div className="subTitle">
                    The fee for 1 teleconsultation provider account is {currencyType(this.state.teleconsultation_fee, this.state.teleconsultation_currency)} 
                </div>
                <div className="formProviders">
                    {this.state.providers.map((item,index)=>{
                        return (
                            <div className="formProviderItem" key={`provider_${index}`}>
                                <Row>
                                    <Col>
                                        Provider #{index + 1} 
                                        <span onClick={this.delProvider.bind(this, index)} className="canClick">
                                            <Icon className="ml20 iconXmark" f7="trash_circle_fill"></Icon>
                                        </span>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col width="25" className="formLabel InputRequired">
                                        Name
                                    </Col>
                                    <Col width="35">
                                        <input
                                            type="text"
                                            name="provider_name"
                                            className="formInput input-with-value input-invalid"
                                            placeholder="Enter Name"
                                            value={this.state.providers[index].name}
                                            onChange={this.handleChange.bind(this, index, 'name')}
                                        />
                                        {this.state.providers[index].name_error ? <span className="errorMsg">This is required.</span> : ''}
                                    </Col>
                                    <Col width="40"></Col>
                                </Row>
                                <Row>
                                    <Col width="25" className="formLabel InputRequired">
                                        Email
                                    </Col>
                                    <Col width="35">
                                        <input
                                            type="email"
                                            onBlur={this.inputBlur.bind(this,index)}
                                            name="provider_email"
                                            className="formInput input-with-value input-invalid"
                                            placeholder="Enter Email"
                                            value={this.state.providers[index].email}
                                            onChange={this.handleChange.bind(this,index ,'email')}
                                        />
                                        {this.state.providers[index].email_error ? <span className="errorMsg">This is required.</span> : ''}
                                    </Col>
                                    <Col width="40"></Col>
                                </Row>
                                {this.state.providers.length > index + 1  ? 
                                    <Row>
                                        <Col width="60">
                                            <div className="hrLine mt20 mb20 text-gray"></div>
                                        </Col>
                                        <Col width="40"/>
                                    </Row>
                                 : <br/>}
                            </div>
                        )
                    }) }
                </div>
                <div>
                    <Row>
                        <Col width='30'>
                            <Button className="outline-blue-btn" onClick={this.addProvider.bind(this)}> + Add Provider</Button>
                        </Col>
                    </Row>
                </div>
                <br />
                <br />
                <div className="hrLine"></div>
                <br/>
                <br />
                <Row>
                    <Col width='30'>
                        Total Payable : <b> {currencyType(this.state.totalAmount, this.state.teleconsultation_currency) }</b>
                    </Col>
                </Row>
                
                
            </form>
        )
    }

    addDoctorPro() {
        app.popover.open('.popover-menu');
    }

    // btnSave() {
    //     var list_doctor = [];
    //     list_doctor.push({
    //         name: this.state.drName,
    //         email: this.state.drEmail,
    //         unit_price: 0
    //     })
    //     AuthGuard.addDoctor({
    //         list_doctor: JSON.stringify(list_doctor),
    //     }, (isOk, res) => {
    //         console.log(res)
    //         if (isOk) {
    //             // self.setState({
    //             // //     patientHeight: formData.height,
    //             // //     patientNote: formData.note
    //             // });
    //             app.popover.close('.popover-menu');
    //             app.preloader.hide();
    //         }
    //     });
    // }

    render() {
        return (
            <FramePage
                name={ROUTES.addAccount}
            >
                <div className="navBar">
                    <Link animate={false} color='gray' back> Settings </Link> &gt; <span>{this.state.user_profile_id ? "Edit Provider Account" : "Add Provider Account"}</span>
                </div>
                <Row className="navTitleBar">
                    <Col> {this.state.user_profile_id ? "Edit Provider Account" : this.state.addedAccount ? "Payment Success"  :  "Add Provider Account"} </Col>
                    <Col>
                        <div className="footerBtn">
                            {this.state.addedAccount ? '' : <Button className="navbarBtn outline-blue-btn" onClick={this.backToList}>Cancel</Button> }
                            {this.state.addedAccount ? '' : <Button className="navbarBtn fill-blue-btn" onClick={this.createAccountBtn} raised fill>{this.state.user_profile_id ? "Update" : "Proceed to pay"} </Button>}
                        </div>
                    </Col>
                </Row>
                <div className="white-block">
                    {this.state.addedAccount ? <AddAccountSuccess added={this.state.addedAccounts}/> : this.AddAccountForm()}
                    {/* <Block className="xhx-bb">the fee for I doctor account is $0.00</Block>
                    <div className="user-detail">
                        ffff
                    </div>
                    <Button className="navbarBtn outline-blue-btn" onClick={this.addDoctorPro.bind(this)}>
                        + Add Doctor
                    </Button> */}
                </div>

                {/* <Popover className="popoverModal popover-menu">
                    <BlockTitle>Edit Personal Details</BlockTitle>

                    <List noHairlinesMd>
                        <Block>

                            <label>Name:</label>
                            <input
                                type='text'
                                name="drName"
                                className="search-input"
                                placeholder="Input Name"
                                value={this.state.drName}
                                onChange={(e) => {
                                    this.setState({ drName: e.target.value });
                                }}
                            />

                            <label>Email:</label>
                            <input
                                type='text'
                                name="drEmail"
                                className="search-input"
                                placeholder="Input Email"
                                value={this.state.drEmail}
                                onChange={(e) => {
                                    this.setState({ drEmail: e.target.value });
                                }}
                            />

                        </Block>
                    </List>

                    <div className="popFooter">
                        <Button onClick={this.btnSave.bind(this)} className="btn-red">Save</Button>
                    </div>
                </Popover> */}
            </FramePage>
        );
    }

}

const AddAccountSuccess = (props) => {
    const backToList = () => {
        let currentRoute = app.views.main.router.currentRoute.path
        app.views.main.router.clearPreviousHistory()
        if (currentRoute === ROUTES.setting) {
            app.views.main.router.back()
        } else {
            app.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'manageAccountsTab' } })
        }
    }

    return (
        <div className="fwl">
            {props.added.length} Provider accounts has been created for 
            <br />
            <div className="txt-red">
                {props.added.map(i => 
                    <p key='i'>{i}</p>
                )}
            </div>
            <br />
            A link has been sent to the new account email to set new password. 
            Please advise provider to set password and login with new account.
            <br/>
            <br />
            <Row>
                <Col width='15'>
                    <Button className="fillBtn outline-blue-btn" raised onClick={backToList}>Back  to list</Button>
                </Col>
            </Row>
        </div>
    )
}


export default AddProvider;