import React, { Component } from 'react';
import { f7 as app, Button, Row, Col, Icon, Tabs, Tab } from 'framework7-react';
import { Link } from 'framework7-react';
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard'
import { showDate, DispalyDateTime } from '../commons/Helpers';
import SideBar from '../components/SideBar.js'
import TimePopUp from '../components/TimesPopUp.js';
import rili from '../images/icons/rili.png';
import sousuo from '../images/icons/sousuo.png';
export default class Reports extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTabTypes: 'settlementTab',
            drName: '',
            settleMentData: [],
            total_billed: '',
            total_delivery: '',
            total_payable: '',
            subMentData: [],
            sub_total_billed: '',
            sub_total_delivery: '',
            sub_total_payable: '',
            disMentData: [],
            aggregateData: [],
            dis_total_billed: '',
            dis_total_delivery: '',
            dis_total_payable: '',
            order_type: '',
            dis_medication_name: [],
            subsidys_type: '',
            medications_name: '',
            searchFlag: true,
            searchSubFlag: true,
            searchDisFlag: true,
            coachName: AuthGuard.getCoachProfile().coachName,
            settDateSearchFrom: '',
            settDateSearchTo: '',
            subsDateSearchFrom: '',
            subsDateSearchTo: '',
            dailDateSearchFrom: '',
            dailDateSearchTo: '',
            drList: '',
            openDateTimesPopType: '',
        }
        this.coachProfile = AuthGuard.getCoachProfile();
    }


    componentDidMount() {
        this.settLists()
        this.subLists()
        this.disLists()


        this.getDrList()

        AuthGuard.getReportMedicationNameList({}, (isOk, res) => {
            console.log(res)
            if (isOk){
                this.setState({
                    dis_medication_name: res || []
                })
            }                                    
        })
    }


    getDrList() {
        AuthGuard.getDrlist({
            clinic_id: this.coachProfile.clinic_info_id
        }, (isok, res) => {

            if (isok) {
                this.setState({
                    drList: res
                })
            }
        })
    }

    getChildrenMsg = (result, msg) => {
        console.log(result, msg)

        let ostrArr = msg.split("/");
        let onewStr = ostrArr[2] + "-" + ostrArr[1] + "-" + ostrArr[0];

        console.log(onewStr)

        if (this.state.openDateTimesPopType == 'settDateSearchFrom') {
            this.setState({
                settDateSearchFrom: onewStr
            })
        } else if (this.state.openDateTimesPopType == 'settDateSearchTo') {
            this.setState({
                settDateSearchTo: onewStr
            })
        } else if (this.state.openDateTimesPopType == 'subsDateSearchFrom') {
            this.setState({
                subsDateSearchFrom: onewStr
            })
        } else if (this.state.openDateTimesPopType == 'subsDateSearchTo') {
            this.setState({
                subsDateSearchTo: onewStr
            })
        } else if (this.state.openDateTimesPopType == 'dailDateSearchFrom') {
            this.setState({
                dailDateSearchFrom: onewStr
            })
        } else if (this.state.openDateTimesPopType == 'dailDateSearchTo') {
            this.setState({
                dailDateSearchTo: onewStr
            })
        }


    }

    settLists() {
        AuthGuard.reportsSettlementList({
        }, (isOk, res) => {
            // console.log(res)
            if (isOk) {
                this.setState({
                    settleMentData: res.result,
                    total_billed: res.total_billed,
                    total_delivery: res.total_delivery,
                    total_payable: res.total_payable
                })

            }
        })
    }

    subLists() {
        AuthGuard.reportsSubsidies({
        }, (isOk, res) => {
            // console.log(res)
            if (isOk) {
                this.setState({
                    subMentData: res.result,
                    sub_total_billed: res.total_billed,
                    sub_total_delivery: res.total_delivery,
                    sub_total_payable: res.total_payable
                })

            }
        })
    }

    disLists() {
        AuthGuard.reportsDispensary({
        }, (isOk, res) => {
            // console.log(res)
            if (isOk) {

                // let itemNames = res.result.map((item, index) => {
                //     let itemName = [];
                //     itemName = item.medication_name

                //     return itemName
                // })

                // let disMentMedicationName = itemNames.filter(function (item, index, self) {
                //     return self.indexOf(item) == index;
                // })

                // console.log(disMentMedicationName)

                this.setState({
                    disMentData: res.result,
                    aggregateData: res.aggregate,
                    dis_total_billed: res.total_billed,
                    dis_total_delivery: res.total_delivery,
                    dis_total_payable: res.total_payable,
                })

            }
        })
    }

    tabClicks = (type) => {
        this.setState({
            activeTabTypes: type
        })
    }

    btnClear(type) {
        if (type === 'Settlement') {
            this.setState({
                order_type: '',
                drName: '',
                settDateSearchFrom: '',
                settDateSearchTo: '',
                searchFlag: true
            })
            this.settLists()
        }

        if (type === 'Subsidies') {
            this.setState({
                subMentData:[],
                subsidys_type: '',
                subsDateSearchFrom: '',
                subsDateSearchTo: '',
                searchSubFlag: true
            },()=>{
                this.subLists()
            })
        }

        if (type === 'Dispensary') {
            this.setState({
                medications_name: '',
                dailDateSearchFrom: '',
                dailDateSearchTo: '',
                searchDisFlag: true
            })
            this.disLists()
        }

    }



    btnSearchSettlement(type) {

        if (type === 'Settlement') {
            AuthGuard.reportsSettlementList({
                order_type: this.state.order_type,
                doctor_name: this.state.drName,
                date_from: this.state.settDateSearchFrom,
                date_to: this.state.settDateSearchTo,
            }, (isOk, res) => {
                // console.log(res)
                if (isOk) {
                    this.setState({
                        settleMentData: res.result,
                        total_billed: res.total_billed,
                        total_delivery: res.total_delivery,
                        total_payable: res.total_payable
                    })

                }
            })
        }

        if (type === 'Subsidies') {
            AuthGuard.reportsSubsidies({
                subsidies_type: this.state.subsidys_type,
                date_from: this.state.subsDateSearchFrom,
                date_to: this.state.subsDateSearchTo,
            }, (isOk, res) => {
                // console.log(res)
                if (isOk) {
                    this.setState({
                        subMentData: res.result,
                    })

                }
            })
        }

        if (type === 'Dispensary') {
            AuthGuard.reportsDispensary({
                medication_name: this.state.medications_name,
                date_from: this.state.dailDateSearchFrom,
                date_to: this.state.dailDateSearchTo,
            }, (isOk, res) => {
                // console.log(res)
                if (isOk) {
                    this.setState({
                        disMentData: res.result,
                        aggregateData: res.aggregate,
                        dis_total_billed: res.total_billed,
                        dis_total_delivery: res.total_delivery,
                        dis_total_payable: res.total_payable,
                    })

                }
            })
        }

    }

    btnExport(type) {

        if (type === 'Settlement') {
            AuthGuard.reportsExport({
            }, (isOk, res) => {
                console.log(isOk, res)
                if (isOk) {
                    window.open(res)
                }
            })
        }

        if (type === 'Subsidies') {
            AuthGuard.reportsSubExport({
            }, (isOk, res) => {
                console.log(isOk, res)
                if (isOk) {
                    window.open(res.result)
                }
            })
        }

        if (type === 'Dispensary') {
            AuthGuard.reportsDisExport({
            }, (isOk, res) => {
                console.log(isOk, res)
                if (isOk) {
                    window.open(res.result)
                }
            })
        }

    }

    DateTimesPop(type) {
        app.popup.open('.Times-popup');

        this.setState({
            openDateTimesPopType: type
        })

    }

    renderSettlement() {

        return (
            <>
                <div className="search-panel">
                    <Row>
                        <Button raised fill className="fill-blue-btn" onClick={this.btnExport.bind(this, 'Settlement')}>Export</Button>
                    </Row>
                    <Row className="reports-search">
                        <Col width="5" className="search-filter">
                            Filters:
                            </Col>
                        <Col width="35">
                            <Row>
                                <Col className="search-input input-dropdown-wrap">
                                    <select name="search-filters" className="search-filters"
                                        style={{ color: this.state.searchFlag === true ? 'gray' : 'black' }}
                                        value={this.state.order_type}
                                        onChange={(e) => {
                                            this.setState({ order_type: e.target.value, searchFlag: false });
                                        }}>
                                        <option value="" disabled>Order Type</option>
                                        <option value="telemedicine">Telemedicine</option>
                                        <option value="marketplace">Marketplace</option>

                                    </select>
                                </Col>
                            </Row>
                        </Col>
                        <Col width="35">
                            <Row>
                                <Col className="search-input">
                                    <input
                                        type='text'
                                        name="dorctorName"
                                        id="dorctorName"
                                        placeholder="Doctor Name"
                                        value={this.state.drName}
                                        onChange={(e) => {
                                            this.setState({ drName: e.target.value });
                                        }}
                                    />
                                    {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                                    <img src={sousuo} className="img-block" />
                                </Col>
                            </Row>
                        </Col>
                        <Col width="25">
                            <Row>
                                <Col width="100" medium="50">
                                    <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearchSettlement.bind(this, 'Settlement')}>Search</Button>
                                </Col>
                                <Col width="100" medium="50">

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="reports-search settDateSearch-box">
                        <Col width="5"> </Col>
                        {/* <Col width="35" className="search-input">
                        <input
                            type='date'
                            name="settDateSearch"
                            className="settDateSearch"
                            placeholder=""
                            value={this.state.settDateSearch}
                            onChange={(e) => {
                                this.setState({ settDateSearch: e.target.value });
                            }}
                        />
                    </Col> */}
                        <Col className="search-input" width='35' style={{ marginRight: '10px', color: 'black', position: 'relative' }}>
                            <input
                                type='text'
                                name="settDateSearchFrom"
                                className=""
                                placeholder="Date From"
                                value={this.state.settDateSearchFrom ? this.state.settDateSearchFrom : ''}
                                onChange={(e) => console.log(e.target.value)}

                            />
                            <img src={rili} className="Rili" alt="Rili" onClick={this.DateTimesPop.bind(this, 'settDateSearchFrom')} />

                        </Col>
                        <Col className="search-input" width='35' style={{ marginRight: '10px', color: 'black', position: 'relative' }}>
                            <input
                                type='text'
                                name="settDateSearchTo"
                                className=""
                                placeholder="Date To"
                                value={this.state.settDateSearchTo ? this.state.settDateSearchTo : ''}
                                onChange={(e) => console.log(e.target.value)}

                            />
                            <img src={rili} className="Rili" alt="Rili" onClick={this.DateTimesPop.bind(this, 'settDateSearchTo')} />

                        </Col>
                        <Col width="25">
                            <Row>
                                <Col width="100" medium="50">
                                    <Button type="button" outline raised className="outline-pink-btn" onClick={this.btnClear.bind(this, 'Settlement')}>Clear</Button>
                                </Col>
                                <Col width="100" medium="50">

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                {/* {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() || AuthGuard.CanViewHistoryVideoCallList() ? */}

                {/* {AuthGuard.CanSetting() || AuthGuard.CanEditSessionIdStatus() ? */}
                <div className="data-table reports-table">
                    <table id="tbl">
                        <thead>
                            <tr>                                
                                <th className="medium-cell" style={{width:"10%"}}>ORDER NO.</th>
                                <th className="medium-only">ORDER TYPE</th>
                                <th className="medium-only date">DATE</th>
                                <th className="medium-only">QUEUE NO</th>
                                <th className="medium-only">PATIENT ID</th>
                                <th className="medium-only">PATIENT NAME</th>
                                <th className="medium-only">DOCTOR NAME</th>
                                <th className="medium-only">CLINIC ID</th>
                                <th className="medium-only">CLINIC NAME</th>
                                <th className="medium-only">ITEM SOLD</th>
                                <th className="medium-only">COUPON CODE</th>
                                <th className="medium-only">BILLED</th>
                                <th className="medium-only">CLINIC DELIVERY CHARGE</th>
                                <th className="medium-only">TOTAL PAYABLE TO CLINIC</th>
                            </tr>
                        </thead>
                        <tbody className="reports-tbody">
                            {this.state.settleMentData.length > 0 && this.state.settleMentData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="label-cell">{item.transactionID}</td>
                                        <td className="medium-only">{item.transaction_type_display_name}</td>
                                        <td className="medium-only date">
                                            <div className="">{item.created_at}</div>
                                        </td>
                                        <td className="medium-only">{item.queue_number}</td>
                                        <td className="medium-only">{item.patientID}</td>
                                        <td className="medium-only">{item.patinet_name}</td>
                                        <td className="medium-only">
                                            {item.doctor_name}
                                        </td>
                                        <td className="medium-only">
                                            {item.clinicID}
                                        </td>
                                        <td className="medium-only">{item.clinic_name}</td>
                                        <td className="medium-only">
                                            {item.product_code}
                                        </td>
                                        <td className="medium-only">{item.coupon_code}</td>
                                        <td className="medium-only">
                                            <span> {item.billed !== null ? '$' + item.billed : '$0.00'}</span>
                                        </td>
                                        <td className="medium-only">
                                            <span> {item.delivery_charge !== null ? '$' + item.delivery_charge : '$0.00'}</span>
                                        </td>
                                        <td className="medium-only">
                                            <span> {item.total !== null ? '$' + item.total : '$0.00'}</span>
                                        </td>
                                    </tr>

                                )
                            })}
                            <tr>
                                {/* <td className="medium-only total">TOTAL</td> */}
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total"></td>
                                <td className="medium-only total">TOTAL</td>
                                <td className="medium-only">${this.state.total_billed}</td>
                                <td className="medium-only">${this.state.total_delivery}</td>
                                <td className="medium-only">${this.state.total_payable}</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
                {/* : <div className="permission-prompt">You are not authorized to view this page</div>} */}

            </>
        )
    }

    renderSubsidies() {
        return (
            <>
                <div className="search-panel">

                    <Row>
                        <Button raised fill className="fill-blue-btn" onClick={this.btnExport.bind(this, 'Subsidies')}>Export</Button>
                    </Row>
                    <Row className="reports-search subsDateSearch-box">
                        <Col width="5" className="search-filter">
                            Filters:
                            </Col>

                        <Col className="search-input" width='35' style={{ marginRight: '10px', color: 'black', position: 'relative' }}>
                            <input
                                type='text'
                                name="subsDateSearchFrom"
                                className=""
                                placeholder="Date From"
                                value={this.state.subsDateSearchFrom ? this.state.subsDateSearchFrom : ''}
                                onChange={(e) => console.log(e.target.value)}

                            />
                            <img src={rili} className="Rili" alt="Rili" onClick={this.DateTimesPop.bind(this, 'subsDateSearchFrom')} />

                        </Col>

                        <Col className="search-input" width='35' style={{ marginRight: '10px', color: 'black', position: 'relative' }}>
                            <input
                                type='text'
                                name="subsDateSearchTo"
                                className=""
                                placeholder="Date To"
                                value={this.state.subsDateSearchTo ? this.state.subsDateSearchTo : ''}
                                onChange={(e) => console.log(e.target.value)}

                            />
                            <img src={rili} className="Rili" alt="Rili" onClick={this.DateTimesPop.bind(this, 'subsDateSearchTo')} />

                        </Col>

                        <Col width="25">
                            <Row>
                                <Col width="100" medium="50">
                                    <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearchSettlement.bind(this, 'Subsidies')}>Search</Button>
                                </Col>
                                <Col width="100" medium="50">

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="reports-search subsDateSearch-box">
                        <Col width="5" className="search-filter">

                        </Col>

                        <Col width="35" className="search-input input-dropdown-wrap">
                            <select name="search-filters" className="search-filters"
                                style={{ color: this.state.searchSubFlag === true ? 'gray' : 'black' }}
                                value={this.state.subsidys_type ? this.state.subsidys_type : ''}
                                onChange={(e) => {
                                    this.setState({ subsidys_type: e.target.value, searchSubFlag: false });
                                }}>
                                <option value="" disabled>Subsidy Type</option>
                                <option value="chas">CHAS</option>
                                <option value="medisave">Medisave</option>
                                <option value="others">Others</option>
                            </select>
                        </Col>

                        <Col width='35'>

                        </Col>

                        <Col width="25">
                            <Row>
                                <Col width="100" medium="50">
                                    <Button type="button" outline raised className="outline-pink-btn" onClick={this.btnClear.bind(this, 'Subsidies')}>Clear</Button>
                                </Col>
                                <Col width="100" medium="50">

                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </div>
                <div className="data-table reports-table">
                    <table>
                        <thead>
                            <tr>
                                <th className="label-cell">ORDER NO.</th>
                                <th className="numeric-cell">ORDER TYPE</th>
                                <th className="medium-only">DATE</th>
                                <th className="medium-only">QUEUE NO</th>
                                <th className="medium-only">PATIENT ID</th>
                                <th className="medium-only">PATIENT NAME</th>
                                <th className="medium-only">DOCTOR NAME</th>
                                <th className="medium-only">SUBSIDIES TYPE</th>
                                <th className="medium-only">SUBSIDIES AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody className="reports-tbody">
                            {this.state.subMentData.length > 0 && this.state.subMentData.map((item, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="label-cell">{item.transactionID}</td>
                                        <td className="medium-only">{item.transaction_type_display_name}</td>
                                        <td className="medium-only date">
                                            <div className="">{item.created_at}</div>
                                        </td>
                                        <td className="medium-only">{item.queue_number}</td>
                                        <td className="medium-only">{item.patientID}</td>
                                        <td className="medium-only">{item.patinet_name}</td>
                                        <td className="medium-only">
                                            {item.doctor_name}
                                        </td>
                                        <td className="medium-only">
                                            {item.subsidy_type}
                                        </td>
                                        <td className="medium-only">${item.subsidy_amount}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    renderDispensary() {
        // console.log('=>=>=>=>')
        // console.log(this.state.dis_medication_name)
        return (
            <>
                <div className="search-panel">

                    <Row>
                        <Button raised fill className="fill-blue-btn" onClick={this.btnExport.bind(this, 'Dispensary')}>Export</Button>
                    </Row>
                    <Row className="reports-search dailDateSearch-box">
                        <Col width="5" className="search-filter">
                            Filters:
                            </Col>

                        <Col className="search-input" width='35' style={{ marginRight: '10px', color: 'black', position: 'relative' }}>
                            <input
                                type='text'
                                name="dailDateSearchFrom"
                                className=""
                                placeholder="Date From"
                                value={this.state.dailDateSearchFrom ? this.state.dailDateSearchFrom : ''}
                                onChange={(e) => console.log(e.target.value)}

                            />
                            <img src={rili} className="Rili" alt="Rili" onClick={this.DateTimesPop.bind(this, 'dailDateSearchFrom')} />

                        </Col>


                        <Col className="search-input" width='35' style={{ marginRight: '10px', color: 'black', position: 'relative' }}>
                            <input
                                type='text'
                                name="dailDateSearchTo"
                                className=""
                                placeholder="Date To"
                                value={this.state.dailDateSearchTo ? this.state.dailDateSearchTo : ''}
                                onChange={(e) => console.log(e.target.value)}

                            />
                            <img src={rili} className="Rili" alt="Rili" onClick={this.DateTimesPop.bind(this, 'dailDateSearchTo')} />

                        </Col>
                        <Col width="25">
                            <Row>
                                <Col width="100" medium="50">
                                    <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearchSettlement.bind(this, 'Dispensary')}>Search</Button>
                                </Col>
                                <Col width="100" medium="50">

                                </Col>
                            </Row>
                        </Col>
                    </Row>


                    <Row className="reports-search dailDateSearch-box">
                        <Col width="5" className="search-filter">
                        </Col>

                        <Col width="35" className="search-input input-dropdown-wrap">
                            <select name="search-filters" className="search-filters"
                                style={{ color: this.state.searchDisFlag === true ? 'gray' : 'black' }}
                                value={this.state.medications_name ? this.state.medications_name : ''}
                                onChange={(e) => {
                                    this.setState({ medications_name: e.target.value, searchDisFlag: false });
                                }}>
                                <option value="" disabled>Medication Name</option>
                                <option value="">All</option>
                                {this.state.dis_medication_name && this.state.dis_medication_name.map((item, index) => {
                                    return <option value={item} key={"search" + index}>{item}</option>
                                })}

                            </select>
                        </Col>


                        <Col width='35'>

                        </Col>
                        <Col width="25">
                            <Row>
                                <Col width="100" medium="50">
                                    <Button type="button" outline raised className="outline-pink-btn" onClick={this.btnClear.bind(this, 'Dispensary')}>Clear</Button>
                                </Col>
                                <Col width="100" medium="50">

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <div className="data-table reports-table" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <table style={{ width: '75%' }}>
                        <thead>
                            <tr>
                                <th className="label-cell">ORDER NO.</th>
                                <th className="numeric-cell">ORDER TYPE</th>
                                <th className="medium-only">DATE</th>
                                <th className="medium-only">PATIENT ID</th>
                                <th className="medium-only">PATIENT NAME</th>
                                <th className="medium-only">DOCTOR NAME</th>
                                {/* <th className="medium-only">CLINIC ID</th> */}
                                <th className="medium-only">MEDICATION NAME</th>
                                <th className="medium-only">MEDICATION QTY DISPENSED</th>
                            </tr>
                        </thead>
                        <tbody className="reports-tbody">
                            {this.state.disMentData.length > 0 && this.state.disMentData.map((item, index) => {
                                return (
                                    <tr key={"disMent" + index}>
                                        <td className="label-cell">{item.transactionID}</td>
                                        <td className="medium-only">{item.transaction_type_display_name}</td>
                                        <td className="medium-only date">
                                            <div className="">{item.created_at}</div>
                                        </td>
                                        <td className="medium-only">{item.patientID}</td>
                                        <td className="medium-only">{item.patinet_name}</td>
                                        <td className="medium-only">{item.doctor_name}</td>
                                        <td className="medium-only">
                                            {item.medication_name}
                                        </td>
                                        {/* <td className="medium-only">
                                            {item.clinicID}
                                        </td> */}
                                        <td className="medium-only">
                                            {item.medication_quantity}
                                        </td>
                                    </tr>

                                )
                            })}
                        </tbody>
                    </table>


                    <table style={{ width: '20%' }}>
                        <thead>
                            <tr>
                                <th className="medium-only">MEDICATION NAME</th>
                                <th className="medium-only">TOTAL QTY DISPENSED</th>
                            </tr>

                        </thead>
                        <tbody className="reports-tbody">
                            {this.state.aggregateData.map((item, index) => {
                                return (
                                    <tr key={"disMentData" + index}>
                                        <td className="medium-only">
                                            {item.name}
                                        </td>
                                        <td className="medium-only">
                                            {item.quantity}
                                        </td>
                                    </tr>

                                )
                            })}
                        </tbody>
                    </table>

                </div>
            </>
        )
    }


    render() {
        return (
            <div className="reports-page">
                <FramePage
                    name={ROUTES.reports}
                >
                    <Row className="navTitleBar navTitleName">
                        <Col> Reports </Col>
                        <Col>
                            <Row>
                                <Col width="70"></Col>
                                <Col width="30">{this.state.coachName}</Col>
                            </Row>
                        </Col>

                    </Row>
                    <div className="clinicDetail white-block">
                        <Row>
                            <Col>
                                <div className="cellTabbar tabbar">
                                    <Link
                                        tabLink="#settlementTab"
                                        tabLinkActive
                                        onClick={this.tabClicks.bind(this, 'settlementTab')}
                                    >
                                        Daily Settlement Report
                                    </Link>
                                    <Link
                                        tabLink="#subsidiesTab"
                                        onClick={this.tabClicks.bind(this, 'subsidiesTab')}
                                    >
                                        Daily Subsidies Report
                                    </Link>
                                    <Link
                                        tabLink="#dispensaryTab"
                                        onClick={this.tabClicks.bind(this, 'dispensaryTab')}
                                    >
                                        Daily Dispensary Report
                                    </Link>
                                </div>
                                <Tabs animated>
                                    <Tab id="settlementTab" className="page-content" tabActive>
                                        {this.renderSettlement()}
                                    </Tab>
                                    <Tab id="subsidiesTab" className="page-content">
                                        {this.renderSubsidies()}
                                    </Tab>
                                    <Tab id="dispensaryTab" className="page-content" >
                                        {this.renderDispensary()}
                                    </Tab>

                                </Tabs>
                            </Col>
                        </Row>
                    </div>

                    <TimePopUp
                        userProfileId={this.coachProfile.userProfileId}
                        drList={this.state.drList}
                        parent={this}
                    />

                </FramePage>
            </div>

        );
    }
}


