import React, { Component } from "react";
import { f7 as app, Page, Link } from "framework7-react";
import SideBar from "./SideBar";
import AuthGuard from "../services/AuthGuard";
import events from "../services/events";
import { formateTime } from "../commons/Helpers";

var autoLogoutTimer;

class FramePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consultationDetailPopupOpen: AuthGuard.consultationDetailPopupOpen,
      videoCallTime: AuthGuard.videoCallTime,
    };
    this.coachProfile = AuthGuard.getCoachProfile();
  }

  componentDidMount() {
    let self = this;
    events.on("SetConsultationDetailPopupOpen", (data) => {
      // do something
      self.setState({
        consultationDetailPopupOpen: data.show,
      });
      AuthGuard.setConsultationDetailPopupOpen(data.show);
    });

    events.on("IntervalVideoCallTime", (data) => {
      self.setState({
        videoCallTime: AuthGuard.videoCallTime,
      });
    });
  }

  render() {
    return (
      <>
        <Page
          name={this.props.name}
          onPageReinit={this.props.onPageReinit}
          onPageBeforeOut={this.props.onPageBeforeOut}
          onPageBeforeRemove={this.props.onPageBeforeRemove}
        >
          <div
            className={`videoCallPopBar ${
              this.state.consultationDetailPopupOpen ? "showPop" : ""
            }`}
          >
            <Link popupOpen=".consultationDetailPage-popup">
              Resume Video Call {formateTime(this.state.videoCallTime)}
            </Link>
          </div>
          <div
            className="mainDiv"
            style={{
              marginTop: this.state.consultationDetailPopupOpen
                ? "55px"
                : "0px",
            }}
          >
            {!AuthGuard.isRedirect && <SideBar page={this.props.name} />}
            <div
              className="rightMain"
              style={{
                marginLeft: AuthGuard.isRedirect ? "0" : "9%",
                width: AuthGuard.isRedirect ? "100%" : "91%",
              }}
            >
              {this.props.children}
            </div>
          </div>
        </Page>
      </>
    );
  }
}

export default FramePage;
