import React, { Component } from 'react';
import { f7 as app, Row, Col, View, Page, Popup, PageContent, Button } from "framework7-react";
// import VideoCallV2 from "../components/VideoCallV2";
import moment from 'moment';
import VideoCallV3 from "../components/VideoCallV3";
import { formateTime } from '../commons/Helpers'
import AuthGuard from '../services/AuthGuard'
import { ROUTES } from '../commons/Constants'
import events from '../services/events';
import ComingMP3 from '../sounds/coming.mp3'
import IconCancel from '../images/icons/icon_cancel.svg'

class ConsultationDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userQueueId: "",
      userProfileId: "",
      patientName: "",
      nric: "",
      mobile: "",
      email: "",
      SessionID: "",
      videoCallTime: 0,
      doctorNotes: "",
      originUser: {},
      videoCallId: "",
      ended: false,
      userRejectPopupShown: false,
      userRejectPopupRestAt: '',
    };
  }

  componentDidMount() {
    let self = this;
    events.on("IntervalVideoCallTime", (data) => {
      self.setState({
        videoCallTime: AuthGuard.videoCallTime,
      });
    });
    events.on("EndConsultation", () => {
      this.setState({ended: true})
    })

    this.handleUserRejectPopup();
  }

  componentWillReceiveProps(props) {
    // console.log(props);
  }

  componentDidUpdate() {
    app.preloader.hide();
  }

  handleUserRejectPopup() {
    events.on('pushNotificationCall', () => {
      this.setState({
        userRejectPopupShown: false,
        userRejectPopupRestAt: new Date(),
      }, () => {
        app.popup.close('.UserReject-popup')
      }) 
    })
    events.on('StartVideoCall', () => {
      this.setState({
        userRejectPopupShown: false,
        userRejectPopupRestAt: new Date(),
      }, () => {
        app.popup.close('.UserReject-popup')
      }) 
    })
    events.on('UserRejectVideoCall', (data) => {
      if (this.state.userRejectPopupShown || (this.state.userRejectPopupRestAt && moment(data.at).isBefore(this.state.userRejectPopupRestAt))) {
        return;
      }
      this.setState({
        userRejectPopupShown: true
      }, () => {
        app.popup.open('.UserReject-popup')
      })
    })
  }

  getPatientInfo() {
    AuthGuard.userFullInfo({
      user_profile_id: this.state.userProfileId
    }, (isOk, res) => {
      if (isOk) {
        let { user_info } = res;
        this.setState({
          user_info: user_info
        })
      }
    })
  }

  closeVideoCallPopUp() {
    app.popup.close('.consultationDetailPage-popup');
  }

  videoCallPopupOpen() {
    if (AuthGuard.twilioInitialized) {
      return;
    }
    AuthGuard.twilioInitialized = true;

    // app.preloader.show();
    this.setState({
      ...AuthGuard.videoCallInfo,
      ended: false,
    });
    let self = this;

    AuthGuard.getVideoCallInfo({ id: this.state.videoCallId }, (isok, res) => {
      if (isok) {
        self.setState(
          {
            ...res,
            patientName: res.name,
          },
          () => {
            // setTimeout(() => {
              self.refVideoCall.initialize();
            // }, 200);
          }
        );
      }
    });

    AuthGuard.getNotes({ id: this.state.videoCallId }, (isok, res) => {
      if (isok) {
        this.setState({
          doctorNotes: res.doctor_notes ? res.doctor_notes : "",
        });
      }
    });

    events.emit('StartVideoCall', { videoCallId: this.state.videoCallId });
    // this.countFun();
  }

  videoCallPopupOpend = () => {
    AuthGuard.switchVideoCallTimer("on");
  };

  getVidelCallRemoteUser() {
    return this.refVideoCall.remoteUser;
  }

  getVidelCallLocalUser() {
    return this.refVideoCall.localUser;
  }

  endConsultation() {
    let self = this;
    this.refVideoCall.toolBarFun.call(this.refVideoCall, "EndConsultation", (endType) => {
      if (endType === "endCall") {
        AuthGuard.drEndcall(
          {
            queue_id: this.state.videoCallId,
            // email: this.state.email,
            // dialing_code: this.state.originUser.dialing_code,
            // mobile_number: this.state.originUser.mobile_number,
          },
          (isok, res) => {
            app.popup.close(".consultationDetailPage-popup");
            let currentRoute = app.views.main.router.currentRoute.path
            app.views.main.router.clearPreviousHistory()
            if (currentRoute === ROUTES.consultationSummary) {
              events.emit('SetConsultationSummaryVideoCallID', { videoCallId: self.state.videoCallId });
            } else {
              app.views.main.router.navigate(ROUTES.consultationSummary, {
                props: { videoCallId: self.state.videoCallId },
                reloadCurrent: true
              });
            }
            self.setState({
              originUser: {},
            });

            AuthGuard.switchVideoCallTimer("off");
            AuthGuard.setVideoCallInfo({});
          }
        );
      } else {
        AuthGuard.cancelVideoCall(
          {
            id: this.state.videoCallId,
            // email: this.state.email,
            // dialing_code: this.state.originUser.dialing_code,
            // mobile_number: this.state.originUser.mobile_number,
          },
          (isok, res) => {
            app.popup.close(".consultationDetailPage-popup");
            let currentRoute = app.views.main.router.currentRoute.path
            app.views.main.router.clearPreviousHistory()
            if (currentRoute === ROUTES.consultationSummary) {
              events.emit('SetConsultationSummaryVideoCallID', { videoCallId: self.state.videoCallId });
            } else {
              app.views.main.router.navigate(ROUTES.consultationSummary, {
                props: { videoCallId: self.state.videoCallId },
                reloadCurrent: true
              });
            }
            self.setState({
              originUser: {},
            });

            AuthGuard.switchVideoCallTimer("off");
            AuthGuard.setVideoCallInfo({});
          }
        );
      }
    })
  }

  endVideoCall(endType, e) {
    let self = this;
    // console.log(self.refVideoCall.remoteUser);
    if (endType === "endCall") {
      // AuthGuard.drEndcall(
      //   {
      //     queue_id: this.state.videoCallId,
      //     // email: this.state.email,
      //     // dialing_code: this.state.originUser.dialing_code,
      //     // mobile_number: this.state.originUser.mobile_number,
      //   },
      //   (isok, res) => {
      app.popup.close(".consultationDetailPage-popup");
      let currentRoute = app.views.main.router.currentRoute.path
      app.views.main.router.clearPreviousHistory()
      if (currentRoute === ROUTES.consultationSummary) {
        events.emit('SetConsultationSummaryVideoCallID', { videoCallId: self.state.videoCallId });
      } else {
        app.views.main.router.navigate(ROUTES.consultationSummary, {
          props: { videoCallId: self.state.videoCallId },
          reloadCurrent: true
        });
      }
      self.setState({
        originUser: {},
      });
      //   }
      // );
    } else {
      // AuthGuard.cancelVideoCall({ id: this.state.videoCallId },
      //   (isOk, message) => {
      events.emit('SetConsultationSummaryVideoCallID', { videoCallId: this.state.videoCallId });
      let currentRoute = app.views.main.router.currentRoute.path
      app.views.main.router.clearPreviousHistory()
      if (currentRoute !== ROUTES.consultationSummary) {
        app.views.main.router.navigate(ROUTES.consultationSummary, {
          props: { videoCallId: self.state.videoCallId },
          reloadCurrent: true
        });
      }
      self.setState({
        originUser: {},
      });
      app.popup.close(".consultationDetailPage-popup");
      //   }
      // );
      this.setState({
        doctorNotes: "",
      });
    }

    AuthGuard.switchVideoCallTimer("off");
  }

  onDoctorNoteBlur = () => {
    if (this.state.doctorNotes) {
      AuthGuard.saveNotes(
        {
          id: this.state.videoCallId,
          doctor_notes: this.state.doctorNotes,
        },
        (isOk, res) => {
          if (isOk) {
            console.log(res);
          }
        }
      );
    }
  };

  viewVitalGraph = () => {
    this.closeVideoCallPopUp();
    app.views.main.router.navigate(ROUTES.patientdetail, {
      props: { userId: this.state.userProfileId },
      reloadCurrent: true,
      animate: false,
    });
  };

  pushNotificationCall = () => {
    AuthGuard.twilioCanReconnect = true
    events.emit('pushNotificationCall')
    AuthGuard.pushNotificationCall({
      id: this.state.videoCallId
    }, (ok, res) => {
      if (ok) {
        app.dialog.alert('Success')
        this.setState({ended: false})
      } else {
        app.dialog.alert(res.message ? res.message : 'Failed')
      }
    })
  }

  recallWhenUserReject = () => {
    AuthGuard.pushNotificationCall({
      id: this.state.videoCallId
    }, (ok, res) => {
      if (ok) {
        app.dialog.alert('Success')
        if (this.state.ended) {
          AuthGuard.twilioCanReconnect = true
          events.emit('pushNotificationCall')
          this.setState({ended: false})
        }
        events.emit('StartVideoCall')
      } else {
        app.dialog.alert(res.message ? res.message : 'Failed')
      }
    })
  }

  render() {
    return (
      <Popup
        id="consultationDetailPopup"
        onPopupOpen={this.videoCallPopupOpen.bind(this)}
        onPopupOpened={this.videoCallPopupOpend}
        // opened
        className="consultationDetailPage-popup"
        push
        tabletFullscreen
      >
        <View name="consultationDetailView">
          <Page name="ConsultationDetailPage" bgColor="white">
            <PageContent id="CDPopupPageContent">
              <div className="navBar">
                <Row>
                  <Col>
                    <span style={{ color: "gray" }}> Video Appointments </span>
                    &gt; <span>{this.state.patientName}</span>
                  </Col>
                  <Col className="navBarRight">
                    <div className="fwb mr20">
                      {formateTime(this.state.videoCallTime)}
                    </div>
                    <Button
                      className="navbarBtn btn-large outline-blue-btn mr10"
                      raised
                      onClick={() => this.refVideoCall.videoCallForm.current.btnSave.call(this.refVideoCall.videoCallForm.current)}
                    >
                      Save Draft
                    </Button>
                    <Button
                      className="navbarBtn btn-large outline-blue-btn mr10"
                      raised
                      onClick={() => this.refVideoCall.videoCallForm.current.btnViewVitalGraph.call(this.refVideoCall.videoCallForm.current)}
                    >
                      View Vital Graph
                    </Button>
                    {this.state.ended && <Button
                      className="navbarBtn btn-large outline-blue-btn mr10"
                      raised
                      onClick={this.pushNotificationCall.bind(this)}
                    >
                      Call
                    </Button>}
                    <Button
                      className="navbarBtn btn-large fill-pink-btn mr10"
                      raised
                      fill
                      onClick={this.endConsultation.bind(this)}
                    >
                      End Consultation
                    </Button>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col width="100">
                  <VideoCallV3
                    onRef={(ref) => {
                      this.refVideoCall = ref;
                    }}
                    originUser={this.state.originUser}
                    videoCallId={this.state.videoCallId}
                    endVideoCall={this.endVideoCall.bind(this)}
                  />
                  {/* <VideoCallV2
                    onRef={(ref) => {
                      this.refVideoCall = ref;
                    }}
                    originUser={this.state.originUser}
                    videoCallId={this.state.videoCallId}
                    endVideoCall={this.endVideoCall.bind(this)}
                  /> */}
                </Col>
              </Row>
              <br />
              <br />
            </PageContent>
            <Popup
                className="UserReject-popup"
                swipeToClose
                swipeHandler=".swipe-handler"
            >
                <Page>
                    <div>
                        <img src={IconCancel} alt="" className="call-cancel" />
                    </div>
                    <div>
                    <div> {this.state.patientName} has declined your call. <br />Please try to recall the user again.</div>
                    </div>
                    <div className="footerCallPop">
                        <Row>
                            <Col>
                                <Button fill raised onClick={() => app.popup.close('.UserReject-popup')} className="fill-blue-btn" > Cancel </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button outline raised onClick={this.recallWhenUserReject.bind(this)} className="outline-blue-btn"> Recall  </Button>
                            </Col>
                        </Row>
                    </div>
                </Page>
            </Popup>
          </Page>
        </View>
        <audio src={ComingMP3} controls="controls" id="comingMP3" hidden>
          Your browser does not support the <code>audio</code> element.
        </audio>
      </Popup>
    );
  }
}

export default ConsultationDetail;