import React from 'react';
import {f7 as app, Card, Row, Col} from "framework7-react";
import {LocalVideoTrack} from 'twilio-video';
import VideoTrack from '../VideoTrack/VideoTrack';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Loading from '../../../../images/icons/roomConnecting.png';

export default function LocalVideoPreview() {
    const {localTracks} = useVideoContext();

    const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

    // return videoTrack ? <VideoTrack track={videoTrack} isLocal /> : null;
    return (
        <>
            <div className="localStream localStreamLayout1">
                <div>
                    <p>Loading...</p>
                    <img src={Loading} className="rotate"></img>
                    <p>Please check your connection if <br/>the call is taking too long to load</p>
                </div>
            </div>
            <div className="remoteStream localStreamLayout1"/>
        </>
    )
}
