import React, { PropsWithChildren, useState } from 'react';
import {f7 as app} from 'framework7-react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import enhanceMessage from './enhanceMessage';
import { TwilioError } from 'twilio-video';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import AuthGuard from "../../../../services/AuthGuard";
import {AlertMsg} from "../../../../commons/Constants";

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {};
  const enhancedMessage = enhanceMessage(message, code);

  const { userName, getToken } = useAppState();
  const [roomName] = useState<string>('');
  const { connect } = useVideoContext();

  const reconnect = () => {
    dismissError()
    // @ts-ignore
    AuthGuard.drStartCall({ id: AuthGuard.videoCallInfo.videoCallId }, (isok, res) => {
      console.log('reconnect', { originUser: res })
      if (isok) {
        connect(res.doctorToken)
      }
    })
  }

  // error && app.dialog.confirm(enhancedMessage, 'Info', () => dismissError(), reconnect)

  error && app.dialog.create({
    title: 'Info',
    text: enhancedMessage,
    buttons: [
      {
        text: 'OK',
        onClick: dismissError(),
      },
      {
        text: 'Reconnect',
        color: 'red',
        onClick: reconnect
      },
    ],
  }).open();
  return <></>

  // return (
  //   <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
  //     <DialogTitle>ERROR</DialogTitle>
  //     <DialogContent>
  //       <DialogContentText>{enhancedMessage}</DialogContentText>
  //       {code && (
  //         <pre>
  //           <code>Error Code: {code}</code>
  //         </pre>
  //       )}
  //     </DialogContent>
  //     <DialogActions>
  //       <Button onClick={() => reconnect()} color="primary" autoFocus>
  //         RECONNECT
  //       </Button>
  //       <Button onClick={() => dismissError()} color="primary">
  //         OK
  //       </Button>
  //     </DialogActions>
  //   </Dialog>
  // );
}

export default ErrorDialog;
