import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {LocalAudioTrack, LocalVideoTrack, Participant, RemoteAudioTrack, RemoteVideoTrack} from 'twilio-video';

import BandwidthWarning from '../BandwidthWarning/BandwidthWarning';
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff/useIsTrackSwitchedOff';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import VideocamOff from '../../../../images/videoCall/IconVideo2.png';
import VideocamOff2 from '@material-ui/icons/VideocamOff';
import ScreenShare from '@material-ui/icons/ScreenShare';
import MicOff from "@material-ui/icons/MicOff";
import AudioLevelIndicator from "../AudioLevelIndicator/AudioLevelIndicator";
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useIsTrackEnabled from '../../hooks/useIsTrackEnabled/useIsTrackEnabled';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gridArea: 'participantList',
  },
  isVideoSwitchedOff: {
    '& video': {
      filter: 'blur(4px) grayscale(1) brightness(0.5)',
    },
  },
  identity: {
    // background: 'rgba(0, 0, 0, 0.7)',
    // padding: '0.1em 0.3em',
    margin: '1.5em 1em 1.5em 1em',
    display: 'flex',
    width: 'min-content',
    // display: 'none',
    '& img': {
      height: '24px',
      width: 'auto',
    },
  },
  infoContainer: {
    position: 'absolute',
    zIndex: 1,
    height: '100%',
    padding: '0.4em',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
});

interface MainParticipantInfoProps {
  participant: Participant;
  children: React.ReactNode;
}

export default function MainParticipantInfo({ participant, children }: MainParticipantInfoProps) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { room: { localParticipant }, } = useVideoContext();

  const publications = usePublications(participant);
  const audioPublication = publications.find(p => p.kind === 'audio');
  const videoPublication = publications.find(p => p.trackName.includes('camera'));
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'));
  const isVideoEnabled = Boolean(videoPublication);
  const audioTrack = useTrack(audioPublication) as LocalAudioTrack | RemoteAudioTrack;

  const videoTrack = useTrack(screenSharePublication || videoPublication);
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack as LocalVideoTrack | RemoteVideoTrack);
  const isAudioEnabled = useIsTrackEnabled(audioTrack as LocalAudioTrack | RemoteAudioTrack);
  const isScreenShareEnabled = publications.find(p => p.trackName.includes('screen'));
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  let isLocal = false
  if (participant === localParticipant || participant === screenShareParticipant) {
    isLocal = true
  }

  return (
    <div
      data-cy-main-participant
      className={clsx(classes.container, { [classes.isVideoSwitchedOff]: isVideoSwitchedOff })}
    >
      <div className={classes.infoContainer}>
        <h4 className={classes.identity}>
          <NetworkQualityLevel qualityLevel={networkQualityLevel} />
          <AudioLevelIndicator audioTrack={audioTrack} background="white"/>
          {!isVideoEnabled && <img src={VideocamOff} />}
          {/* {isScreenShareEnabled && <ScreenShare/>} */}
        </h4>
      </div>
      {isVideoSwitchedOff && <BandwidthWarning />}
      {children}
      <div className="status-indicator">
          {(isLocal || audioTrack) && !isVideoEnabled && <p><VideocamOff2 /> <span>{isLocal ? 'Your' : 'User'} video is off</span></p>}
          {(isLocal || audioTrack) && !isAudioEnabled && <p><MicOff /> <span>{isLocal ? 'You are' : 'User is'} on mute</span></p>}
      </div>
    </div>
  );
}
