import React, { Component } from 'react';
import { f7 as app, Row, Col, Icon, ListInput, ListItemContent, Link, BlockTitle, BlockFooter, Badge, Popover, View, f7ready, Block, Button, List, Page, Input, Preloader, ListItemRow, ListItem } from 'framework7-react';
import { connect } from 'react-redux'
// import { GetPatientDetail, updateUserGoal } from '../redux/actions'
import SideBar from '../components/SideBar'
import Select from "react-dropdown-select";
import { ShowToast, showAge, showGender, InputBlur, InputFoucs, GetValue, GetArrNum, GetArrMiddleNum } from '../commons/Helpers'
import ChatPopUp from '../components/ChatPopUp'
import Graph from '../components/Graph'
import AuthGuard from '../services/AuthGuard'
import { FETCH_UPDATEUSERGOAL } from '../redux/actionTypes';
import { ROUTES } from '../commons/Constants';
import FramePage from '../components/FramePage';
import ReschedulePopUp from '../components/ReschedulePopUp'


class RegisterNewUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nationality: 'Singaporean',
            nationalityList: ['Singaporean'],
            patientName: 'Register New User',
            programOptions: [{ label: 'Please select', value: '' }],
            program_ids: [''],
            countryList: [{ name: 'Singapore', code: 'SG' }],
            country_code: 'SG',
            country_name: 'Singapore',
            dialingCodeList: [],
            dialing_code: '',
            sgDialingCode: []
        }
    }

    componentDidMount() {
        this.getCountryList()
        this.getNationality()
        this.loadAvaliablePrograms()
    }

    getNationality() {
        AuthGuard.getNationalityList({
            attribute: 'nationality'
        }, (isOk, res) => {
            if (isOk) {
                let { list: nationality } = res
                this.setState({
                    nationalityList: nationality.SG,
                })
            }
        })
    }

    getCountryList() {
        const colorStyle = {
            display: 'inline-block',
            verticalAlign: 'middle',
            width: '18px',
            height: '18px',
            marginRight: '10px',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat'
        };
        AuthGuard.getCountryList({
            page: 1,
            limit: 99999
        }, (isoK, res) => {
            let countryList = []
            let dialingCodeList = []

            let sgDialingCode = []
            res.map(i => {

                countryList.push({
                    code: i.code,
                    country_no: i.country_no,
                    name: i.name,
                })
                dialingCodeList.push({
                    label: <span>
                        <span style={{ ...colorStyle, backgroundImage: "url(" + i.flag_image_url.url.s + ")" }}></span>
                        +{i.dialing_code}
                    </span>,
                    value: i.dialing_code,
                })

                if (i.code == "SG") {

                    sgDialingCode.push({
                        label: <span>
                            <span style={{ ...colorStyle, backgroundImage: "url(" + i.flag_image_url.url.s + ")" }}></span>
                        +{i.dialing_code}
                        </span>,
                        value: i.dialing_code,
                    })
                }

            }) // .sort((a, b) => a.name.localeCompare(b.name))
            if (isoK) {
                this.setState({
                    countryList: countryList,
                    dialingCodeList: dialingCodeList,
                    sgDialingCode:sgDialingCode
                })

            }
        })
    }

    loadAvaliablePrograms() {
        let coachProfile = AuthGuard.getCoachProfile();
        AuthGuard.findProgramByMerchantId({
            clinicInfoId: coachProfile.clinic_info_id
        }, (ok, res) => {
            let programOptions = [{ label: 'Please select', value: '' }]
            if (ok) {
                res.map(item => {
                    programOptions.push({
                        label: item.name,
                        value: item.id,
                    })
                })
            }

            this.setState({
                programOptions: programOptions
            })
        })

    }

    componentWillReceiveProps(props) {

    }


    // setPatientState(props) {
    //     this.setState({
    //         // patientName: props.patientDetail.name,

    //     });
    // }

    componentDidUpdate() {

    }

    goBack() {
        app.views.main.router.navigate(ROUTES.patientlist);
    }

    onPageBeforeRemove() {
        const self = this;
        // Destroy popup when page removed
        if (self.popup) self.popup.destroy();
        if (self.calendar) self.calendar.destroy('#reschedule-calendar');
    }

    onRef = (ref) => {
        this.child = ref
    }

    oncancel() {
        console.log("cancel")
    }

    onconfirm() {
        var formData = app.form.convertToData('#reg-new-user');
        formData.program_ids = [...new Set(this.state.program_ids.filter(i => i !== ''))];
        formData.dialingCode = this.state.dialing_code;
        formData.nationality = this.state.nationality;
        formData.country_name = this.state.country_name;
        let coachProfile = AuthGuard.getCoachProfile();
        formData.clinic_info_id = coachProfile.clinic_info_id;
        AuthGuard.addUser(formData, (ok, res) => {
            if (!ok) {
                if (typeof res === 'string') {
                    app.dialog.alert(res)
                } else if (typeof res.message != 'string' && typeof res.message === 'string') {
                    app.dialog.alert(res.message)
                } else {
                    app.dialog.alert('Add user failed')
                }
                return
            }
            app.dialog.alert('Add Success', this.goBack.bind(this))
        })
    }

    onCountryChange(evt) {
        let code = evt.target.value
        let name = this.state.countryList.filter(i => i.code === code).pop()
        this.setState({ country_code: code, country_name: name })
    }

    onProgramChange(idx, evt) {
        let program_ids = this.state.program_ids
        program_ids[idx] = evt.target.value
        this.setState({ program_ids: program_ids })
    }

    onDialingCodeChange(values) {

        console.log(values)

        this.setState({
            dialing_code: values[0].value
        })
    }

    onNationalityChange(e) {
        this.setState({ nationality: e.target.value });
    }

    onAddProgram() {
        let program_ids = this.state.program_ids
        program_ids.push('')
        console.log(this.state.program_ids, this.state.programOptions)
        this.setState({ program_ids: program_ids })
    }

    render() {
        const userDetailDom = <Row className="block_title wide">
            <Col width="150">
                User Details
                </Col>
            <List inlineLabels noHairlinesMd style={{ width: '550px' }}>

                <ListInput
                    label="First Name:*"
                    name="firstName"
                    type="text"
                    outline
                    required
                    validate
                >
                </ListInput>

                <ListInput
                    label="Last Name:*"
                    name="lastName"
                    type="text"
                    outline
                    required
                    validate
                >
                </ListInput>

                <li className="">
                    <div className="item-content item-input item-input-outline">
                        <div className="item-inner" style={{ display: 'flex', alignItems: 'flex-start' }}>
                            <div className="item-title item-label">Mobile No.:*</div>
                            <div style={{ display: 'inline-block', width: '40%' }}>
                                <Select
                                    options={this.state.dialingCodeList}
                                    values={this.state.sgDialingCode.length > 0 ? this.state.sgDialingCode : [] }
                                    style={{ border: 'none' }}
                                    placeholder="Dialing Code"
                                    name='dialingCode'
                                    onChange={this.onDialingCodeChange.bind(this)}
                                    className='country-select input-dropdown'
                                />
                            </div>
                            <div style={{ display: 'inline-block', width: '60%' }}>
                                <Input
                                    name="mobile"
                                    type="tel"
                                    outline
                                    required
                                    validate
                                />
                            </div>
                        </div>
                    </div>
                </li>

                <ListInput
                    label="Date of birth:*"
                    name="birth"
                    type="date"
                    outline
                    defaultValue="1980-01-01"
                    placeholder="Please choose..."
                >
                    {/* <Icon icon="demo-list-icon" slot="media" /> */}
                </ListInput>

                <ListInput
                    label="Nationality:*"
                    type="select"
                    name="nationality"
                    value={this.state.nationality}
                    onChange={this.onNationalityChange.bind(this)}
                    required
                    outline
                >
                    {this.state.nationalityList.map((item, index) => {
                        return <option value={item} key={item}>{item}</option>
                    })}
                </ListInput>

                <ListInput
                    label="Identification No.:*"
                    name="nric"
                    type="text"
                    outline
                    required
                    validate
                >
                </ListInput>

                <ListInput
                    label="Email:*"
                    name="email"
                    type="email"
                    outline
                    required
                    validate
                >
                </ListInput>

                <ListInput
                    label="Gender:*"
                    name="gender"
                    type="select"
                    defaultValue=""
                    outline
                >
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </ListInput>

                <ListInput
                    label="Marital status:"
                    name="marital_status"
                    type="select"
                    defaultValue=""
                    outline
                >
                    <option value=""></option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Divorced">Divorced</option>
                </ListInput>

                <ListInput
                    label="Drug Allergy:"
                    name="drug_allergy"
                    type="text"
                    outline
                // required
                // validate
                >
                </ListInput>
            </List>
        </Row>
        const userAddressDom = <Row className="block_title wide">
            <Col width="95">
                User Address
            </Col>
            <List inlineLabels noHairlinesMd style={{ width: '550px' }}>
                <ListInput
                    label="Country:"
                    type="select"
                    name="country_code"
                    value={this.state.country_code}
                    onChange={this.onCountryChange.bind(this)}
                    outline
                >
                    {this.state.countryList.map(i => <option key={i.name} value={i.code}>{i.name}</option>)}
                </ListInput>

                <ListInput
                    label="Postal Code:"
                    name="postal_code"
                    type="text"
                    outline
                    validate
                >
                </ListInput>

                <ListInput
                    label="Street:"
                    name="address"
                    type="text"
                    outline
                    validate
                >
                </ListInput>

                <ListInput
                    label="Unit No.:"
                    name="unit_no"
                    type="text"
                    outline
                    validate
                >
                </ListInput>
            </List>
        </Row>
        const addProgramDom = (
            <Row className="block_title wide bg-gray">
                <Col width="95">Add User To Monitoring Program(s)?</Col>
                <List inlineLabels noHairlinesMd>
                    {/* <ListItemContent className="item-input-outline">
                    <div className="item-title item-label">Select Program:</div>
                    <div style={{minWidth: "300px"}}>{this.state.programOptions && this.state.programOptions.length
                        ? <MultiSelect
                            className="multiSelect"
                            options={this.state.programOptions}
                            selected={this.state.program_ids}
                            onSelectedChanged={selected => this.setState({ program_ids: selected })}
                        />
                        : null
                    }</div>
                </ListItemContent> */}
                    {this.state.program_ids.length > 0 && this.state.program_ids.map((i, idx) =>
                        idx === 0 ? (
                            <ul key={"ul" + i.value}>
                                <div style={{ display: "inline-block" }}>
                                    <ListInput
                                        label={idx ? " " : "Select Program:"}
                                        type="select"
                                        style={{ width: "550px" }}
                                        value={this.state.program_ids[idx]}
                                        onChange={this.onProgramChange.bind(this, idx)}
                                        outline
                                    >
                                        {this.state.programOptions.map((i) => (
                                            <option key={i.value} value={i.value}>
                                                {i.label}
                                            </option>
                                        ))}
                                    </ListInput>
                                </div>
                                <Button
                                    onClick={this.onAddProgram.bind(this)}
                                    style={{ marginTop: "-10px", display: "inline-block" }}
                                    color="custom-blue"
                                    borderColor="custom-blue"
                                >
                                    <Icon f7="plus_app"></Icon>
                                </Button>
                            </ul>
                        ) : (
                                <ListInput
                                    label={idx ? " " : "Select Program:"}
                                    type="select"
                                    style={{ width: "550px" }}
                                    value={this.state.program_ids[idx]}
                                    onChange={this.onProgramChange.bind(this, idx)}
                                    outline
                                >
                                    {this.state.programOptions.map((i) => (
                                        <option key={i.label} value={i.value}>
                                            {i.label}
                                        </option>
                                    ))}
                                </ListInput>
                            )
                    )}
                </List>
            </Row>
        );

        return (
            <FramePage
                name={ROUTES.registerNewUser}
                onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}
            >
                <div className="navBar">
                    <Link onClick={this.goBack.bind(this)} animate={false} color='gray'> Monitoring list </Link> &gt; <span>{this.state.patientName}</span>
                </div>
                <Row className="navTitleBar">
                    <Col>
                        Register New User
                        </Col>
                    <Col>
                        <div className="footerBtn">
                            <Button back className="navbarBtn btn-large fill-gradient-red-btn" onClick={this.oncancel.bind(this)} raised>Cancel</Button>
                            <Button className="navbarBtn  btn-large fill-blue-btn" onClick={this.onconfirm.bind(this)} raised fill> Confirm</Button>
                        </div>
                    </Col>
                </Row>


                <form className="user-detail" id="reg-new-user">
                    <Row className="bg-gray">
                        <Col width="50">
                            {userDetailDom}
                        </Col>
                        <Col width="50">
                            {userAddressDom}
                        </Col>
                    </Row>
                    {addProgramDom}
                </form>
            </FramePage>
        );
    }
}
const mapStateToProps = (state) => {

    // const { patientList: { patientDetail, dialogInfo } } = state;

    // return {
    //     patientDetail,
    //     dialogInfo
    // };
};

export default connect(
    // mapStateToProps,
    // {
    //     GetPatientDetail,
    //     updateUserGoal
    // }
)(RegisterNewUser)