import React, { Component } from 'react';
import { f7 as app, View, Page, Link, Row, Col, Button, Block, NavRight, Navbar, Popup } from 'framework7-react';

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {  

        }
    }

    modalPopupClose(){
        
    }

    render() { 
        return (
            <Popup className="modal-popup" opened={this.props.modalOpened} onPopupClosed={this.props.modalPopupClose}>
                <Page>
                    <Block>
                        <div dangerouslySetInnerHTML={{ __html: this.props.descHtml }}></div>
                    </Block>
                    <div className="footerModalPop">
                        <Row>
                            <Col>
                                <Button fill disabled={this.props.loadingBtn} raised onClick={this.props.onSave} className="fill-blue-btn" > Yes </Button>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <Button outline raised popupClose className="outline-blue-btn"> No  </Button>
                            </Col>
                        </Row>
                    </div>
                </Page>
            </Popup>
        )
    }
}
 
export default Modal;