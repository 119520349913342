import React, { Component } from 'react';
import { f7 as app, Row, Col, Icon, ListInput, Link, Input, BlockTitle, BlockFooter, Popover, View, f7ready, Block, Button, List, Page, ListItem, Radio } from 'framework7-react';
import { connect } from 'react-redux'
import { GetPatientDetail, updateUserGoal } from '../redux/actions'
import SideBar from '../components/SideBar'
import UploadFiles from '../components/UploadFiles'
import { ShowToast, showAge, showGender, InputBlur, showDate, InputFoucs, GetValue, GetArrNum, GetArrMiddleNum, getCache, DispalyDateTime, showStatus, showDeliveryStatus, dateOperator, OperatorDay, MoneyToFixed } from '../commons/Helpers'
import AuthGuard from '../services/AuthGuard'
import { FETCH_UPDATEUSERGOAL } from '../redux/actionTypes';
import { ROUTES } from '../commons/Constants'
import FramePage from '../components/FramePage';
import ReschedulePopUp from '../components/ReschedulePopUp.js';
import TimePopUp from '../components/TimesPopUp.js';

import searchImg from '../images/icons/search.png';
import rili from '../images/icons/rili.png';
import deleteIcon from '../images/icons/delete.png';
import { filter } from 'fuzzy';
import MultiSelect from "@khanacademy/react-multi-select";
import events from '../services/events';

let itv = null;

var date = showDate(new Date(), 2);
var strArr = date.split("-");
var newStr = strArr[2] + "/" + strArr[1] + "/" + strArr[0]
class ConsultationSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userQueueId: '',
            userProfileId: '',
            patientName: '',
            nric: '',
            mobile: '',
            email: '',
            SessionID: '',
            totalNum: 0.00,
            videoCallId: props.videoCallId,
            // videoCallId:'',
            payStatus: '',
            doctorNotes: '',
            user_info: {
                drug_allergy: ''
            },
            uploadConfig: {},
            invoiceDocuments: [],
            normalDocuments: [],
            drList: [],
            delivery: {},
            delivery_required: 'Yes',
            consultationNoteOpen: false,
            subsidy_select: '',
            medical_certificate_list: [],
            day_time: '',
            end_time: '',
            start_time: '',
            type: 0,
            mc_number: '',
            mc_number_check: '1',
            medication_name: '',
            medication_unit_price: '',
            medication_qty: '',
            medication_purpose: '',
            medication_precaution: '',
            medication_dosege: '',
            medication_discount: '',
            mcIndex: '',
            mcTimeType: '',
            medicationOption: [],
            medicatSelected: [],
            medicationList: [],
            addMcBorderFlag: false,
            transactionId: '',
            mcID: '',
            able_edit_delivery_status: false,
            consultation_fee: 0.00,
            onSubsidy: [
                {
                    status: "",
                    subsidy_amount: ""
                }
            ],
            discount_percentage: '',
            discount_amount: '',
            other_fees: '',
            other_fees_description: '',
            medication_fee: 0.00,
            subsidy_amount: '',
            allMedicationFee: 0.00,
            subNums: 0.00,
            disableFunctionTwo: false,
            disableFunctionThree: false,
            colorFlag: true,
            lastDiagnosisSearch: '',
            isDiagnosisSearchTyping: false,
            diagnosisList: [],
            diagnosisOptions: [],
            diagnosisSelected: [],
            searchFlag: true,
            delivery_type: '',
            video_call_date: '',
            video_call_time: '',
            dayWrongFlag: false,
        }
        this.coachProfile = AuthGuard.getCoachProfile();
    }

    componentDidMount() {
        let self = this;
        events.on('SetConsultationSummaryVideoCallID', (data) => {
            self.setState({
                videoCallId: data.videoCallId,
                // userQueueId: '',
                // userProfileId: '',
                // patientName: '',
                // nric: '',
                // mobile: '',
                // email: '',
                // SessionID: '',
                totalNum: 0.00,
                // videoCallId:'',
                // payStatus: '',
                doctorNotes: '',
                user_info: {
                    drug_allergy: ''
                },
                uploadConfig: {},
                invoiceDocuments: [],
                normalDocuments: [],
                drList: [],
                delivery: {},
                delivery_required: 'Yes',
                consultationNoteOpen: false,
                subsidy_select: '',
                medical_certificate_list: [],
                day_time: '',
                end_time: '',
                start_time: '',
                type: 0,
                mc_number: '',
                mc_number_check: '1',
                medication_unit_price: '',
                medication_qty: '',
                medication_purpose: '',
                medication_precaution: '',
                medication_dosege: '',
                medication_discount: '',
                mcIndex: '',
                mcTimeType: '',
                medicationOption: [],
                medicatSelected: [],
                medicationList: [],
                addMcBorderFlag: false,
                transactionId: '',
                mcID: '',
                able_edit_delivery_status: false,
                consultation_fee: 0.00,
                onSubsidy: [
                    {
                        status: "",
                        subsidy_amount: ""
                    }
                ],
                discount_percentage: '',
                discount_amount: '',
                other_fees: '',
                other_fees_description: '',
                // medication_fee: 0.00,
                subsidy_amount: '',
                // allMedicationFee: 0.00,
                subNums: 0.00,
                disableFunctionTwo: false,
                disableFunctionThree: false,
                colorFlag: true,
                lastDiagnosisSearch: '',
                isDiagnosisSearchTyping: false,
                diagnosisList: [],
                diagnosisOptions: [],
                diagnosisSelected: [],
                searchFlag: true,
                delivery_type: ''
            }, () => {
                self.getSummaryinfo();
                self.getDrList();
            })
        });
        events.emit('SetConsultationSummaryVideoCallID', { videoCallId: this.state.videoCallId });
        // self.getSummaryinfo();
        // this.getPatientInfo()
        // itv = setInterval(this.getmedicationOption.bind(this), 1000)
        self.getmedicationOption()
    }

    componentWillReceiveProps(props) {
        console.log(props)
    }

    componentDidUpdate() {
        app.preloader.hide();
    }


    onDiagnosisSearch(options, txt) {
        if (txt === this.state.lastDiagnosisSearch) {
            return this.state.diagnosisOptions
        }
        this.setState({
            lastDiagnosisSearch: txt,
            isDiagnosisSearchTyping: true,
        })
        setTimeout(this.doDiagnosisSearch.bind(this, txt), 500)
        return this.state.diagnosisOptions;
    }

    doDiagnosisSearch(txt) {
        if (txt !== this.state.lastDiagnosisSearch) {
            return this.state.diagnosisOptions;
        }

        if (txt == '') {
            return
        }

        this.setState({
            isDiagnosisSearchTyping: false
        })
        app.preloader.show();
        let diagnosisOptions = JSON.parse(JSON.stringify(this.state.diagnosisList));
        AuthGuard.diagnosisSearch({
            code: txt
        }, (ok, res) => {
            app.preloader.hide();
            console.log(ok, res)

            if (!ok) {
                if (res.status_code == 11008 || res.status_code == 400) {
                    ShowToast(app, 'No results found');
                    return
                }
            }


            if (!ok || this.state.lastDiagnosisSearch !== res.code) {
                return
            }


            res.list.slice(0, 100).map(i => {
                if (this.state.diagnosisSelected.includes(i.id)) {
                    return;
                }
                diagnosisOptions.push({
                    label: i.display,
                    value: i.id,
                })
            })

            this.setState({
                diagnosisOptions: diagnosisOptions,
            })
        })
        return diagnosisOptions;
    }

    onDiagnosisChange(selected) {

        console.log(selected)

        this.setState({
            diagnosisSelected: selected,
            diagnosisList: this.state.diagnosisOptions.filter(i => selected.includes(i.value))
        }, () => {

            console.log(this.state.diagnosisSelected, this.state.diagnosisList)
        })

    }

    deleteDiagnosisSelected(deleteId, index) {

        if (this.state.diagnosisSelected && this.state.diagnosisSelected.length > 0) {

            let selected = this.state.diagnosisSelected.filter(i => i !== deleteId)
            this.onDiagnosisChange(selected)

            console.log(selected)

        } else {

            let _delList = this.state.diagnosisList

            console.log(_delList)

            _delList.splice(index, 1)

            this.setState({
                diagnosisList: _delList
            })

        }

        console.log(deleteId, this.state.diagnosisSelected, this.state.diagnosisOptions, this.state.diagnosisList)
    }

    deleteSubSelected(index) {

        console.log(index)
        let _delList = this.state.onSubsidy

        console.log(_delList[index])

        _delList.splice(index, 1)

        let subNum = _delList.map((item) => {
            return parseFloat(item.subsidy_amount)
        })

        let ss = 0;
        for (let i = subNum.length - 1; i >= 0; i--) {
            ss += subNum[i];
        }

        this.setState({
            onSubsidy: _delList,
            subNums: ss
        }, () => {

            let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0
            let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(this.state.allMedicationFee) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00))
            if (count_discount_percentage) {
                feeSum *= count_discount_percentage
            }

            if (this.state.discount_percentage == 100) {
                feeSum = 0;
                ShowToast(app, 'the total payable amount is $0!');
            }

            feeSum = feeSum - (this.state.subNums ? parseFloat(this.state.subNums) : 0.00)
            if (feeSum < 0) {
                ShowToast(app, 'The total amount cannot be negative!');
            } else {
                this.setState({
                    totalNum: feeSum
                }, () => {
                    console.log(this.state.totalNum)
                })
            }
        })

    }

    getmedicationOption() {

        if (!AuthGuard.listMedications.length) {
            return;
        }

        // clearInterval(itv)

        let _medicationOption = AuthGuard.listMedications.map(i => {
            return {
                label: i.name,
                value: i.id,
            }
        })

        console.log(_medicationOption)

        this.setState({
            medicationOption: _medicationOption
        })

        // console.log(this.state.medicationOption)
    }

    onMedicationSearchs(options, txt) {
        // console.log(options, txt)
        // let txt = evt.target.value

        let medicationOption = filter(txt, AuthGuard.listMedications, {
            pre: '<i>',
            post: '</i>',
            extract: el => el.name ? el.name : ''
        }).slice(0, 10).map(i => {
            return {
                label: i.original.name,
                value: i.original.id
            }
        })
        return medicationOption

    }

    onMedicationChange(selected) {

        let _medicatSelected = this.state.medicatSelected.concat(selected)
        // let _medicatSelected = selected.concat(this.state.medicatSelected)

        let temp = {},//用于id判断重复
            result = [];//最后的新数组<br>//遍历c数组，将每个item.id在temp中是否存在值做判断，如不存在则对应的item赋值给新数组，并将temp中item.id对应的key赋值，下次对相同值做判断时便不会走此分支，达到判断重复值的目的；

        _medicatSelected.map((iitem, index) => {
            if (!temp[iitem]) {
                result.push(iitem);
                temp[iitem] = true
                // console.log(result, iitem)
            }
        })

        console.log(result, _medicatSelected)

        this.setState({
            medicatSelected: result,
            // medicationList: AuthGuard.listMedications.filter(i => selected.includes(i.id))
        }, () => {

            let _medicationList = AuthGuard.listMedications.filter(i => selected.includes(i.id)).map(i => {
                let item = {
                    id: i.id,
                    dosage: i.dosage,
                    name: i.name,
                    qty: parseInt(i.quantity),
                    unit_price: parseFloat(i.selling_price),
                    // qty: i.quantity,
                    // unit_price: i.selling_price,
                    purpose: i.purpose,
                    precautions: i.precautions,
                    price: MoneyToFixed(i.selling_price * i.quantity * (1 - (parseInt(i.discount ? i.discount : 0) / 100))),
                    // price: 0.00,
                    discount: '',
                    user_request: '--',
                    inventoryID: i.inventory_id,
                    expiry_date: showDate(i.expiry_date, 2),
                    inventory_type: i.inventory_type,
                    frequency: i.frequency,
                }
                return item
            })
            let _medicationLists;
            // let _medicationLists = this.state.medicationList.concat(_medicationList)
            if (this.state.medicationList && this.state.medicationList !== []) {
                _medicationLists = _medicationList.concat(this.state.medicationList)
            } else {
                _medicationLists = _medicationList

            }

            // console.log(_medicationLists, _medicationLists.length)

            let _temp = {},//用于id判断重复
                _result = [];//最后的新数组<br>//遍历c数组，将每个item.id在temp中是否存在值做判断，如不存在则对应的item赋值给新数组，并将temp中item.id对应的key赋值，下次对相同值做判断时便不会走此分支，达到判断重复值的目的；
            _medicationLists.map((item, index) => {
                if (!_temp[item.id]) {
                    _result.push(item);
                    _temp[item.id] = true
                }
            })

            // console.log(_result, _medicationLists)

            this.setState({
                medicationList: _result
            }, () => {
                // console.log(_result, this.state.medicationList)
                let oPrice = this.state.medicationList.map((item => {
                    return item.unit_price * item.qty * (1 - (parseInt(item.discount ? item.discount : 0) / 100))
                }))

                this.sum(oPrice)
            })
        }
        )
    }

    sum(price, amedication) {
        let s = 0;
        for (let i = price.length - 1; i >= 0; i--) {
            s += price[i];
        }
        if (amedication) {
            s = s - amedication

        }

        console.log(s)

        let subNum = this.state.onSubsidy.map((item) => {
            return item.subsidy_amount
        })

        let ss = 0;
        for (let i = subNum.length - 1; i >= 0; i--) {
            ss += parseFloat(subNum[i]);
        }
        console.log(this.state.onSubsidy)
        console.log(subNum, ss)

        let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0
        let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(s) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00))
        if (count_discount_percentage) {
            feeSum *= count_discount_percentage
        }

        if (this.state.discount_percentage == 100) {
            feeSum = 0;
            ShowToast(app, 'the total payable amount is $0!');
        }

        feeSum = feeSum - (ss ? parseFloat(ss) : 0.00)
        // if (feeSum >= 0) {
        this.setState({
            subNums: ss,
            allMedicationFee: s,
            totalNum: feeSum
        }, () => {

            console.log(parseFloat(this.state.consultation_fee), parseFloat(s), (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00), (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00), parseFloat(this.state.discount_percentage), parseFloat(ss), amedication)
        })
        // } else {
        //     ShowToast(app, 'Your amount is incorrect, please enter again!');
        // }


    }

    subtractMedication(price) {
        let _medication_fee = this.state.allMedicationFee - price

        let subNum = this.state.onSubsidy.map((item) => {
            return item.subsidy_amount
        })

        let ss = 0;
        for (let i = subNum.length - 1; i >= 0; i--) {
            ss += parseFloat(subNum[i]);
        }

        let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0
        let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(_medication_fee) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00))
        if (count_discount_percentage) {
            feeSum *= count_discount_percentage
        }
        if (this.state.discount_percentage == 100) {
            feeSum = 0;
            ShowToast(app, 'the total payable amount is $0!');
        }
        feeSum = feeSum - (ss ? parseFloat(ss) : 0.00)

        this.setState({
            allMedicationFee: MoneyToFixed(_medication_fee),
            totalNum: feeSum
        }, () => {
            console.log(_medication_fee, this.state.totalNum)

        })


    }


    getPatientInfo() {
        AuthGuard.userFullInfo({
            user_profile_id: this.state.userProfileId
        }, (isOk, res) => {
            if (isOk) {
                let { user_info } = res;
                this.setState({
                    user_info: user_info
                })
            }
        })
    }


    goAppointment() {
        this.$f7router.back(ROUTES.appointments, {
            ignoreCache: true,
            force: true
        })
    }

    getSummaryinfo() {
        let self = this;
        let itv = setInterval(() => {
            app.preloader.visible || app.preloader.show();
        }, 100)
        AuthGuard.getVideoCallInfo({ id: this.state.videoCallId }, (isok, res) => {
            clearInterval(itv)

            if (isok) {
                self.setState({
                    ...res,
                    userProfileId: res.userProfileId ? res.userProfileId : '',
                    patientName: res.name,
                    payStatus: res.status,
                    uploadConfig: {
                        user_profile_id: res.userProfileId,
                        user_queue_id: res.userQueueId
                    },
                    invoiceDocuments: res?.document ? res?.document.filter((item) => {
                        return item.file_type === 'INVOICE'
                    }) : [],
                    normalDocuments: res?.document ? res?.document.filter((item) => {
                        return item.file_type === 'NORMAL'
                    }) : [],
                    doctorNotes: res.doctor_notes ? res.doctor_notes : this.state.doctorNotes,
                    userQueueId: res.userQueueId ? res.userQueueId : '',
                    transactionId: res.transaction_id ? res.transaction_id : '',
                    able_edit_delivery_status: res.able_edit_delivery_status,
                    delivery_status: res.delivery_status ? res.delivery_status : '',
                    delivery_type: res.delivery_type ? res.delivery_type : '',
                    video_call_date: res.video_call_date,
                    video_call_time: res.video_call_time
                    // totalNum: res.total
                }, () => {
                    self.UpdateAnyData();
                    self.getPayment();
                    self.getQueryMc();
                    console.log(res.diagnosis)
                    if (res.diagnosis && res.diagnosis.length && res.diagnosis.length >= 1) {
                        let itv = setInterval(() => {
                            app.preloader.visible || app.preloader.show();
                        }, 100)
                        app.preloader.show()
                        self.setState({
                            diagnosisList: res.diagnosis.map((i => {
                                let item = {
                                    label: i.name,
                                    value: i.id,
                                }
                                return item
                            }))
                        }, () => {
                            clearInterval(itv)
                            app.preloader.hide()
                            console.log(this.state.diagnosisList, this.state.diagnosisSelected)
                            console.log(res.able_edit_delivery_status, this.state.able_edit_delivery_status)
                        })

                    }
                })
            }
        })
    }

    getQueryMc() {
        let itv = setInterval(() => {
            app.preloader.visible || app.preloader.show();
        }, 100)
        app.preloader.show()
        AuthGuard.queryMc({
            transaction_id: this.state.transactionId,
        }, (isok, res) => {
            clearInterval(itv)
            app.preloader.hide()
            console.log(res)
            if (isok) {
                this.setState({
                    medical_certificate_list: res,
                })
            }

        })
    }

    getPayment() {
        let itv = setInterval(() => {
            app.preloader.visible || app.preloader.show();
        }, 100)
        app.preloader.show()
        AuthGuard.queryPaymentList({
            transaction_id: this.state.transactionId,
            user_queue_id: this.state.userQueueId
        }, (isok, res) => {
            clearInterval(itv)
            app.preloader.hide()
            console.log(res, res.consultation_fee)
            if (isok) {
                this.setState({
                    consultation_fee: res.consultation_fee ? res.consultation_fee : 0.00,
                    // medicationList: res.medication,
                    allMedicationFee: res.medication_fee ? res.medication_fee : 0.00,
                    totalNum: res.total ? res.total : 0.00,
                    medicationList: res.medication ? res.medication.map(i => {
                        let item = {
                            id: i.inventory_id,
                            dosage: i.dosage,
                            name: i.name,
                            // qty: parseInt(i.quantity),
                            // unit_price: parseFloat(i.selling_price),
                            qty: i.qty,
                            unit_price: i.unit_price,
                            purpose: i.purpose,
                            precautions: i.precautions,
                            price: i.unit_price * i.qty * (1 - (parseInt(i.discount ? i.discount : 0) / 100)),
                            // price: 0.00,
                            discount: i.discount,
                            user_request: i.user_request,
                            inventoryID: i.inventoryID,
                            expiry_date: showDate(i.expiry_date, 2),
                            inventory_type: i.inventory_type,
                            frequency: i.frequency,
                        }
                        return item
                    }) : null
                })

                if (res.subsidy && res.subsidy.toString().length > 2) {
                    this.setState({
                        onSubsidy: res.subsidy
                    })
                }

                if (res.other[0]) {
                    this.setState({
                        other_fees: res.other ? res.other[0].other_fees : 0.00,
                        other_fees_description: res.other ? res.other[0].other_fees_description : '',
                        discount_amount: res.other ? res.other[0].discount_amount : 0.00,
                        discount_percentage: res.other ? res.other[0].discount_percentage : 0.00,
                    })
                }
                if (res.discount[0]) {
                    this.setState({
                        discount_amount: res.discount ? res.discount[0].discount_amount : 0.00,
                        discount_percentage: res.discount ? res.discount[0].discount_percentage : 0.00,
                    })
                }

                let _medicatSelected = res.medication ? res.medication.map(i => {
                    let item = i.inventory_id

                    return item;
                }) : null
                console.log(_medicatSelected)

                this.setState({
                    medicatSelected: _medicatSelected
                })

            }

        })
    }

    UpdateAnyData = () => {
        let _disableFunction = false;
        if (this.state.payStatus) {
            if (AuthGuard.CanEditConsultationSummary() && (this.state.payStatus === 'cancel' ||
                this.state.payStatus === 'completed'

            )) {
                _disableFunction = true;
            }


            if (AuthGuard.CanEditConsultationSummary() && (this.state.payStatus === 'cancel' ||
                this.state.payStatus === 'completed' ||
                this.state.payStatus === 'pending' ||
                this.state.payStatus === 'rescheduled' ||
                this.state.payStatus === 'paid' ||
                this.state.payStatus === 'pick_up' ||
                this.state.payStatus === 'on_route' ||
                this.state.payStatus === 'delivered')) {
                this.setState({
                    disableFunctionTwo: true
                })
            }

            if (this.state.able_edit_delivery_status === true) {
                if (AuthGuard.CanEditConsultationSummary() && (
                    this.state.payStatus === 'pending' ||
                    this.state.payStatus === 'paid' ||
                    this.state.payStatus === 'delivered')) {
                    this.setState({
                        disableFunctionThree: true
                    })
                }
            }
            console.log(this.state.able_edit_delivery_status)
            if (this.state.able_edit_delivery_status === false) {
                if (AuthGuard.CanEditConsultationSummary() && (this.state.payStatus === 'cancel' ||
                    this.state.payStatus === 'completed' ||
                    this.state.payStatus === 'pending' ||
                    this.state.payStatus === 'rescheduled' ||
                    this.state.payStatus === 'paid' ||
                    this.state.payStatus === 'pick_up' ||
                    this.state.payStatus === 'on_route' ||
                    this.state.payStatus === 'delivered')) {
                    this.setState({
                        disableFunctionThree: true
                    })
                }
            }


        }
        this.setState({
            disableFunction: _disableFunction,
        })
    }
    onChange = e => {
        let { name, value } = e.target;
        this.setState({ [name]: value });
    }

    inputBlur = (atrName, type, e) => {
        let res = InputBlur(app, atrName, this.$$("input[name='" + atrName + "']"), type);
        if (!res) {
            this.setState({
                [atrName]: ''
            });
        }
    }

    confirmBill() {

        if (this.state.discount_amount > 100) {
            ShowToast(app, 'The discount amount cannot be greater than 100!');
        } else if (this.state.dayWrongFlag == true) {
            ShowToast(app, 'Please select Date Start again, the day is wrong!');
        } else {
            this.confirmBillInfo()

        }

    }

    confirmBillInfo() {


        var _arr = [];
        this.state.diagnosisList.map(item => {
            return _arr.push(item.value)
        })

        let _onSubsidyArr = [];
        this.state.onSubsidy.map(item => {
            // console.log(item)
            if (item.status == '' || item.subsidy_amount == '') {
                console.log(item)
            } else {
                return _onSubsidyArr.push(item)
            }
        })

        console.log(_onSubsidyArr)

        let self = this;
        app.preloader.show();


        AuthGuard.createBill({
            id: this.props.videoCallId,
            doctor_notes: this.state.doctorNotes,
            total: this.state.totalNum ? MoneyToFixed(this.state.totalNum) : 0.00,
            delivery_required: this.state.delivery_required,
            consultation_fee: this.state.consultation_fee ? this.state.consultation_fee : 0.00,
            medication_fees: this.state.allMedicationFee ? MoneyToFixed(this.state.allMedicationFee) : 0.00,
            other_fees: this.state.other_fees ? this.state.other_fees : 0.00,
            other_fees_description: this.state.other_fees_description,
            discount_amount: this.state.discount_amount ? this.state.discount_amount : 0.00,
            discount_percentage: this.state.discount_percentage ? this.state.discount_percentage : 0.00,
            // subsidy: this.state.onSubsidy ? JSON.stringify(this.state.onSubsidy) : '[]',
            subsidy: _onSubsidyArr ? JSON.stringify(_onSubsidyArr) : '[]',
            medication_list: JSON.stringify(this.state.medicationList),
            delivery_status: this.state.able_edit_delivery_status == true ? this.state.delivery_status : this.state.payStatus,
            diagnosis: this.state.diagnosisList ? JSON.stringify(_arr) : '',
            list_medical: JSON.stringify(this.state.medical_certificate_list)

        }, (isok, messsage) => {
            app.preloader.hide();
            if (isok) {

                app.dialog.alert(messsage, () => {
                    this.goAppointment()

                });

                self.getSummaryinfo();
            } else {
                ShowToast(app, 'save Fail');
            }

        })

    }

    saveBill() {

        console.log(this.state.totalNum)
        if (this.state.discount_amount > 100) {
            ShowToast(app, 'The discount amount cannot be greater than 100!');
        } else if (this.state.dayWrongFlag == true) {
            ShowToast(app, 'Please select Date Start again, the day is wrong!');
        } else {
            this.saveInfo()
        }
    }

    saveInfo() {

        var _arr = [];
        this.state.diagnosisList.map(item => {
            return _arr.push(item.value)
        })

        let _onSubsidyArr = [];
        this.state.onSubsidy.map(item => {
            // console.log(item)
            if (item.status == '' || item.subsidy_amount == '') {
                console.log(item)
            } else {
                return _onSubsidyArr.push(item)
            }
        })

        console.log(_onSubsidyArr)


        app.preloader.show();

        AuthGuard.saveconsultationSummary({
            id: this.props.videoCallId,
            doctor_notes: this.state.doctorNotes,
            total: this.state.totalNum ? MoneyToFixed(this.state.totalNum) : 0.00,
            consultation_fee: this.state.consultation_fee ? this.state.consultation_fee : 0.00,
            medication_fees: this.state.allMedicationFee ? MoneyToFixed(this.state.allMedicationFee) : 0.00,
            other_fees: this.state.other_fees ? this.state.other_fees : 0.00,
            other_fees_description: this.state.other_fees_description ? this.state.other_fees_description : '',
            discount_amount: this.state.discount_amount ? this.state.discount_amount : 0.00,
            discount_percentage: this.state.discount_percentage ? this.state.discount_percentage : 0.00,
            // subsidy: this.state.onSubsidy ? JSON.stringify(this.state.onSubsidy) : '[]',
            subsidy: _onSubsidyArr ? JSON.stringify(_onSubsidyArr) : '[]',
            medication_list: JSON.stringify(this.state.medicationList),
            delivery_status: this.state.able_edit_delivery_status == true ? this.state.delivery_status : this.state.payStatus,
            diagnosis: this.state.diagnosisList ? JSON.stringify(_arr) : '',
            list_medical: JSON.stringify(this.state.medical_certificate_list),
        }, (isOk, messsage) => {
            app.preloader.hide();
            if (isOk) {
                app.dialog.alert(messsage, () => {
                    this.goAppointment()

                });
            } else {
                ShowToast(app, 'save Fail');
            }
        })

    }


    getDrList() {
        let { clinic_info_id } = this.coachProfile;
        AuthGuard.getDrlist({
            clinic_id: clinic_info_id
        }, (isok, res) => {
            // console.log(res)
            if (isok) {
                this.setState({
                    drList: res
                })
            }
        })
    }

    scheduleNextAppt() {
        app.popup.open('.Reschedule-popup');
    }


    delDoc = (id, file_type, e) => {
        let self = this;
        if (id) {
            app.dialog.confirm('Are you sure delete it?', function () {
                app.preloader.show()
                AuthGuard.delPdf({ id: id }, (isOk, res) => {
                    if (isOk) {
                        if (file_type === 'NORMAL') {
                            self.state.normalDocuments.splice(self.state.normalDocuments.findIndex(item => item.id === id), 1)
                            self.setState({
                                normalDocuments: self.state.normalDocuments
                            })
                        } else {
                            self.state.invoiceDocuments.splice(self.state.invoiceDocuments.findIndex(item => item.id === id), 1)
                            self.setState({
                                invoiceDocuments: self.state.invoiceDocuments
                            })
                        }
                    }

                    app.preloader.hide();
                })
            });
        }
    }

    uploadSuccess = (type, res) => {
        let _invoice_documents = [];
        let _normal_documents = this.state.normalDocuments;
        switch (type) {
            case 'invoice':
                _invoice_documents.push(res);
                break;
            case 'normal':
                _normal_documents.push(res);
            default:
                break;
        }

        if (type === 'invoice') {
            this.setState({
                invoiceDocuments: _invoice_documents
            })
        }

        if (type === 'normal') {
            this.setState({
                normalDocuments: _normal_documents
            })
        }
    }

    consultNotesOpen() {
        this.setState({
            consultationNoteOpen: true
        })

        console.log(this.state.consultationNoteOpen)

    }

    consultNotesOpenFalse() {
        this.setState({
            consultationNoteOpen: false
        })

        console.log(this.state.consultationNoteOpen)
    }

    addSubsidy() {

        console.log(this.state.onSubsidy)
        if (!this.state.disableFunctionTwo) {
            let _list = this.state.onSubsidy

            console.log(_list)
            _list.push({
                status: "",
                subsidy_amount: '',
            })

            this.setState({
                _onSubsidy: _list
            })
        }
    }

    renderInvoiceTable() {
        return (
            <div className="data-table mt20 inVoiceTable" >
                <div className="data-table-title">Invoice</div>
                {this.state.disableFunction ? "" : <i className="fwl item-no">Only pdf files are accepted, max size is 5MB</i>}
                <table>
                    <thead>
                        <tr>
                            <th className="label-cell">Date & Time</th>
                            <th className="numeric-cell">Document Name</th>
                            <th className="label-only">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.invoiceDocuments.length > 0 || this.state.disableFunction ? this.state.invoiceDocuments.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td className="label-cell">{DispalyDateTime(item.created_at, 2, true).date} <br /> <span className="table-cell-desc">{DispalyDateTime(item.created_at, 2, true).time}</span></td>
                                    <td className="numeric-cell txt-green">{item.file_name}</td>
                                    <td className="label-only" align="center">
                                        <div className="table-btns">
                                            <Button href={item.public_file_url} external outline raised target="_blank" className="outline-blue-btn cell_btn">
                                                View
                                            </Button>
                                            <Button outline raised className="outline-pink-btn cell_btn" onClick={this.delDoc.bind(this, item.id, item.file_type)} disabled={this.state.disableFunction}> Delete </Button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : (
                            <></>
                        )}

                        <tr>
                            <td colSpan="3">
                                <span style={{ display: this.state.invoiceDocuments.length < 1 && !this.state.disableFunction ? 'block' : 'none' }}>
                                    <UploadFiles
                                        maxFilesize='5'
                                        maxFiles='1'
                                        uploadSuccess={this.uploadSuccess.bind(this)}
                                        dropzoneSelector='invoice'
                                        acceptedFiles='application/pdf'
                                        uploadConfig={this.state.uploadConfig}
                                    />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }

    renderConsultationDocuments() {

        return (
            <div className="data-table mt20 consultationDocumentsTable" >
                <div className="data-table-title">Consultation Documents</div>
                {this.state.disableFunction ? "" : <p className="fwl item-no">These documents will be sent to patient, password protected with the last 4 characters of patients identification no.</p>}
                <table>
                    <thead>
                        <tr>
                            <th className="label-cell">Date & Time</th>
                            <th className="numeric-cell">Document Name</th>
                            <th className="label-only">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.normalDocuments.length > 0 ? this.state.normalDocuments.map((item) => {
                            return (
                                <tr key={item.id}>
                                    <td className="label-cell">{DispalyDateTime(item.created_at, 2, true).date} <br /> <span className="table-cell-desc">{DispalyDateTime(item.created_at, 2, true).time}</span></td>
                                    <td className="numeric-cell txt-green">{item.file_name}</td>
                                    <td className="label-only">
                                        <div className="table-btns">
                                            <Button href={item.public_file_url} external outline raised target="_blank" className="outline-blue-btn cell_btn">
                                                View
                                            </Button>
                                            <Button outline raised className="outline-pink-btn cell_btn" onClick={this.delDoc.bind(this, item.id, item.file_type)} disabled={this.state.disableFunctionTwo}> Delete </Button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }
                        ) : <tr></tr>}
                        {this.state.disableFunctionTwo ? <></> : <tr>
                            <td colSpan="3" >
                                <UploadFiles
                                    maxFilesize='5'
                                    maxFiles='5'
                                    uploadSuccess={this.uploadSuccess.bind(this)}
                                    dropzoneSelector='normal'
                                    acceptedFiles='application/pdf'
                                    uploadConfig={this.state.uploadConfig}
                                />
                            </td>
                        </tr>}
                    </tbody>
                </table>
            </div>
        )
    }


    getChildrenMsg = (result, msg) => {
        console.log(result, msg)

        console.log(this.state.mcTimeType)

        if (this.state.mcTimeType === 'start') {
            this.setState({
                start_time: msg
            })

            this.onChangeStartTime();


        } else if (this.state.mcTimeType === 'end') {
            this.setState({
                end_time: msg
            })

            this.onChangeEndTime();

        }

    }

    timesPop(type, index, mcID) {

        if (!this.state.disableFunctionTwo) {

            app.popup.open('.Times-popup');
            console.log(type, index)

            this.setState({
                mcIndex: index,
                mcTimeType: type,
                mcID: mcID
            })
            console.log(mcID)
        }

    }

    openPreview(mcId) {
        AuthGuard.mcUrl({
            id: mcId
        }, (isOk, res) => {
            // console.log(isOk,res)
            if (isOk) {
                window.open(res)
            }
        })
    }

    onChangeDayTime = (value, index) => {

        console.log(value, index)

        if (value >= 0) {

            let _medical_certificate_list = this.state.medical_certificate_list
            _medical_certificate_list[index].day_time = value

            // console.log(_medical_certificate_list)

            this.setState({
                medical_certificate_list: _medical_certificate_list
            })


            let ostrArr = _medical_certificate_list[index].start_time.split("/");
            let onewStr = ostrArr[2] + "/" + ostrArr[1] + "/" + ostrArr[0]

            _medical_certificate_list[index].end_time = dateOperator(onewStr, value, "+")

            console.log(_medical_certificate_list, newStr, onewStr)
        }
    }


    onChangeStartTime = () => {

        // console.log(this.state.mcIndex, this.state.start_time, this.state.mcID)
        let _medical_certificate_list = this.state.medical_certificate_list;

        if (this.state.mcID !== '') {
            if (_medical_certificate_list && _medical_certificate_list[this.state.mcIndex] && _medical_certificate_list[this.state.mcIndex].start_time) {

                console.log(_medical_certificate_list[this.state.mcIndex])

                _medical_certificate_list[this.state.mcIndex].start_time = this.state.start_time

            }


        } else {

            _medical_certificate_list[this.state.mcIndex].start_time = this.state.start_time

        }

        let ostrArr = _medical_certificate_list[this.state.mcIndex].start_time.split("/");
        let onewStr = ostrArr[2] + "-" + ostrArr[1] + "-" + ostrArr[0];

        let istrArr = _medical_certificate_list[this.state.mcIndex].end_time.split("/");
        let inewStr = istrArr[2] + "-" + istrArr[1] + "-" + istrArr[0];

        if (OperatorDay(inewStr, onewStr) >= 0) {
            _medical_certificate_list[this.state.mcIndex].day_time = OperatorDay(inewStr, onewStr)
            this.setState({
                medical_certificate_list: _medical_certificate_list,
                dayWrongFlag: false
            })

        } else {
            this.setState({
                dayWrongFlag: true
            })
            ShowToast(app, 'Please select MC Date Start again, the day is wrong!');
        }
        console.log(this.state.medical_certificate_list, this.state.mcIndex)
        console.log(onewStr, inewStr)
    }


    onChangeEndTime = () => {

        console.log(this.state.mcIndex, this.state.end_time, this.state.mcID)

        let _medical_certificate_list = this.state.medical_certificate_list


        if (this.state.mcID !== '') {
            if (_medical_certificate_list && _medical_certificate_list[this.state.mcIndex] && _medical_certificate_list[this.state.mcIndex].end_time) {
                // console.log(_medical_certificate_list[this.state.mcIndex].end_time)

                _medical_certificate_list[this.state.mcIndex].end_time = this.state.end_time

            }

        } else {
            _medical_certificate_list[this.state.mcIndex].end_time = this.state.end_time

        }


        let ostrArr = _medical_certificate_list[this.state.mcIndex].start_time.split("/");
        let onewStr = ostrArr[2] + "-" + ostrArr[1] + "-" + ostrArr[0];

        let istrArr = _medical_certificate_list[this.state.mcIndex].end_time.split("/");
        let inewStr = istrArr[2] + "-" + istrArr[1] + "-" + istrArr[0];

        if (OperatorDay(inewStr, onewStr) >= 0) {
            _medical_certificate_list[this.state.mcIndex].day_time = OperatorDay(inewStr, onewStr)

            this.setState({
                medical_certificate_list: _medical_certificate_list,
                dayWrongFlag: false
            })

        } else {
            this.setState({
                dayWrongFlag: true
            })
            ShowToast(app, 'Please select MC Date Start again, the day is wrong!');
        }
        console.log(this.state.medical_certificate_list, this.state.mcIndex)
        console.log(onewStr, inewStr)

    }

    onChangeCertifyName = (value, index) => {

        // console.log(value, index)

        let _medical_certificate_list = this.state.medical_certificate_list
        _medical_certificate_list[index].certify_name = value

        console.log(_medical_certificate_list)

        this.setState({
            medical_certificate_list: _medical_certificate_list
        })
    }


    onChangeSubSatus = (value, index) => {

        console.log(value, index)
        if (!this.state.disableFunctionTwo) {

            if (this.state.onSubsidy) {
                let _onSubsidy = this.state.onSubsidy
                _onSubsidy[index].status = value

                console.log(_onSubsidy)

                this.setState({
                    onSubsidy: _onSubsidy,
                    searchFlag: false
                })

            }
        }
    }

    onChangeSubAmount = (value, index) => {

        console.log(value, index)

        if (value >= 0) {
            let _onSubsidy = this.state.onSubsidy
            _onSubsidy[index].subsidy_amount = value

            this.setState({
                onSubsidy: _onSubsidy
            }, () => {
                let subNum = this.state.onSubsidy.map((item) => {

                    if (item.subsidy_amount === '') {
                        item.subsidy_amount = 0
                    }

                    return parseFloat(item.subsidy_amount)
                })

                console.log(this.state.onSubsidy)
                console.log(subNum)

                let ss = 0;
                for (let i = subNum.length - 1; i >= 0; i--) {
                    ss += subNum[i];
                }

                this.setState({
                    subNums: ss
                }, () => {
                    console.log(ss, this.state.subNums)

                })

                let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0
                let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(this.state.allMedicationFee) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00))

                if (count_discount_percentage) {
                    feeSum *= count_discount_percentage
                }
                if (this.state.discount_percentage == 100) {
                    feeSum = 0;
                    ShowToast(app, 'the total payable amount is $0!');
                }
                feeSum = feeSum - (ss ? parseFloat(ss) : 0.00)
                let _feeSum = feeSum.toFixed(2)

                console.log(_feeSum)

                if (feeSum < 0) {
                    ShowToast(app, 'The total amount cannot be negative!');
                } else {
                    this.setState({
                        totalNum: parseFloat(_feeSum)
                    }, () => {
                        console.log(this.state.totalNum)
                    })
                }

            })
        } else {
            ShowToast(app, 'Yonly enter numbers between 1-100.');
        }
    }


    listTypeChange(type, typeNum, index) {
        console.log('type:' + type, 'typeNum:' + typeNum, 'index:' + index)

        let _medical_certificate_list = this.state.medical_certificate_list

        if (type === 'typeOne') {
            _medical_certificate_list[index].type = '0'

            this.setState({
                medical_certificate_list: _medical_certificate_list
            })

            console.log(this.state.medical_certificate_list)
        } else {
            _medical_certificate_list[index].type = '1'

            this.setState({
                medical_certificate_list: _medical_certificate_list
            })

            console.log(this.state.medical_certificate_list)
        }

    }

    addMCList() {

        let _list = this.state.medical_certificate_list

        _list.push({
            certify_name: "",
            // created_at: "",
            // created_by: "",
            day_time: "",
            // deleted_at: null,
            // deleted_by: null,
            end_time: "",
            id: "",
            mc_number: "",
            mc_number_check: "",
            nric: this.state.nric,
            patient_name: this.state.patientName,
            start_time: newStr,
            transaction_id: this.state.transactionId,
            type: "",
            updated_at: null,
            updated_by: null,
            user_profile_id: this.state.userProfileId,
            user_queue_id: this.state.userQueueId,
        })

        this.setState({
            medical_certificate_list: _list
        }, () => {

            console.log(this.state.medical_certificate_list)
        })
    }

    delMC(mcID, index) {
        console.log(mcID)
        if (mcID) {
            AuthGuard.mcDelete({
                id: mcID
            }, (isOk, res) => {

                console.log(res)

                let _delList = this.state.medical_certificate_list

                console.log(_delList)

                _delList.splice(index, 1)

                this.setState({
                    medical_certificate_list: _delList
                })

            })
        } else {

            let _delList = this.state.medical_certificate_list

            console.log(_delList)

            _delList.splice(index, 1)

            this.setState({
                medical_certificate_list: _delList
            })
            // console.log(this.state.medical_certificate_list)
        }

    }

    deleteTd(index, item) {

        console.log(index, item)
        if (item.user_request === 'REQUIRED' || item.user_request === '--') {
            if (!this.state.disableFunctionTwo) {

                this.setState({
                    colorFlag: false
                })

                // if (this.state.medicatSelected && this.state.medicatSelected.length > 0) {
                let selected = this.state.medicatSelected.filter(i => i !== item.id)
                // this.onMedicationChange(selected)

                //     console.log(selected)
                // } else {
                let _List = this.state.medicationList

                _List[index].user_request = 'NOT_REQUIRED'

                _List.splice(index, 1)

                this.setState({
                    medicationList: _List,
                    medicatSelected: selected
                }, () => {
                    this.subtractMedication(item.price)
                    console.log(this.state.medicationList, this.state.medicatSelected, selected)
                })
                // }

            }

        }

    }

    otherFeesDesc(value) {

        if (value >= 0) {

            this.setState({
                other_fees: value
            }, () => {

                let subNum = this.state.onSubsidy.map((item) => {
                    return parseFloat(item.subsidy_amount)
                })

                console.log(this.state.onSubsidy)
                console.log(subNum)

                let ss = 0;
                for (let i = subNum.length - 1; i >= 0; i--) {
                    ss += subNum[i];
                }

                this.setState({
                    subNums: ss
                }, () => {
                    // console.log(ss, this.state.subNums)
                    let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0
                    let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(this.state.allMedicationFee) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00))
                    if (count_discount_percentage) {
                        feeSum *= count_discount_percentage
                    }

                    if (this.state.discount_percentage == 100) {
                        feeSum = 0;
                        ShowToast(app, 'the total payable amount is $0!');
                    }

                    feeSum = feeSum - (ss ? parseFloat(ss) : 0.00)
                    if (feeSum < 0) {
                        ShowToast(app, 'The total amount cannot be negative!');
                    } else {
                        this.setState({
                            totalNum: feeSum
                        }, () => {
                            console.log(this.state.totalNum)
                        })
                    }
                })



            })
        } else {
            ShowToast(app, 'Yonly enter numbers between 1-100.');
        }

        console.log(this.state.other_fees)
    }

    changeMedicationName(value, index) {
        console.log(value, index)
        let _medicationList = this.state.medicationList;

        // if (_medicationList && _medicationList[index] && _medicationList[index].purpose) {

        _medicationList[index].name = value

        // }

        this.setState({
            medicationList: _medicationList
        })

        console.log(this.state.medicationList)
    }

    changeMedicationUnitPrice(value, index, item) {

        let _delemediction = []
        this.state.medicationList.map((item, index) => {
            if (item.user_request === "NOT_REQUIRED") {
                _delemediction.push(item.price)
            }

        })

        let s = 0;
        for (let i = _delemediction.length - 1; i >= 0; i--) {
            s += _delemediction[i];
        }

        console.log(s, _delemediction)

        let _medicationList = this.state.medicationList;

        // console.log(_medicationList[index].unit_price)
        // if (_medicationList && _medicationList[index] && _medicationList[index].unit_price) {

        _medicationList[index].unit_price = value

        _medicationList[index].price = value * parseInt(item.qty) * (1 - (parseInt(item.discount ? item.discount : 0) / 100))


        // }

        this.setState({
            medicationList: _medicationList,
        }, () => {
            let oPrice = this.state.medicationList.map((item => {
                return item.unit_price * item.qty * (1 - (parseInt(item.discount ? item.discount : 0) / 100))
            }))

            this.sum(oPrice, s)

        })

        console.log(item, this.state.medicationList)

    }

    changeMedicationQuantity(value, index, item) {
        console.log(value, index, item)

        let _delemediction = []
        this.state.medicationList.map((item, index) => {
            if (item.user_request === "NOT_REQUIRED") {
                _delemediction.push(item.price)
            }

        })

        let s = 0;
        for (let i = _delemediction.length - 1; i >= 0; i--) {
            s += _delemediction[i];
        }

        let _medicationList = this.state.medicationList;

        // if (_medicationList && _medicationList[index] && _medicationList[index].qty) {

        _medicationList[index].qty = value
        _medicationList[index].price = item.unit_price * value * (1 - (parseInt(item.discount ? item.discount : 0) / 100))

        // }

        this.setState({
            medicationList: _medicationList,
        }, () => {
            let oPrice = this.state.medicationList.map((item => {
                return item.unit_price * item.qty * (1 - (parseInt(item.discount ? item.discount : 0) / 100))
            }))

            this.sum(oPrice, s)
        })

        console.log(this.state.medicationList)
    }

    changeMedicationPurpose(value, index) {
        console.log(value, index)
        let _medicationList = this.state.medicationList;

        // if (_medicationList && _medicationList[index] && _medicationList[index].purpose) {

        _medicationList[index].purpose = value

        // }

        this.setState({
            medicationList: _medicationList
        })

        console.log(this.state.medicationList)
    }

    changeMedicationPercautions(value, index) {
        console.log(value, index)
        let _medicationList = this.state.medicationList;

        // if (_medicationList && _medicationList[index] && _medicationList[index].precautions) {

        _medicationList[index].precautions = value

        // }

        this.setState({
            medicationList: _medicationList
        })

        console.log(this.state.medicationList)
    }

    changeMedicationDosage(value, index) {
        console.log(value, index)
        let _medicationList = this.state.medicationList;

        // if (_medicationList && _medicationList[index] && _medicationList[index].dosage) {

        _medicationList[index].dosage = value

        // }

        this.setState({
            medicationList: _medicationList
        })

        console.log(this.state.medicationList)
    }

    changeMedicationDiscount(value, index, item) {
        console.log(value, index)

        let _delemediction = []
        this.state.medicationList.map((item, index) => {
            if (item.user_request === "NOT_REQUIRED") {
                _delemediction.push(item.price)
            }

        })

        let os = 0;
        for (let i = _delemediction.length - 1; i >= 0; i--) {
            os += _delemediction[i];
        }

        let _medicationList = this.state.medicationList;

        if (_medicationList && _medicationList[index]) {

            _medicationList[index].discount = value
            _medicationList[index].price = item.unit_price * item.qty * (1 - (parseFloat(value ? value : 0) / 100))

            console.log(_medicationList, _medicationList[index].discount)

        }

        this.setState({
            medicationList: _medicationList,
        }, () => {
            let oPrice = this.state.medicationList.map((item => {
                return item.unit_price * item.qty * (1 - (parseFloat(value ? value : 0) / 100))
            }))

            // this.sum(oPrice)

            let _op = this.state.medicationList.map((item => {
                return item.price
            }))

            let s = 0;
            for (let i = _op.length - 1; i >= 0; i--) {
                s += _op[i];
            }

            let subNum = this.state.onSubsidy.map((item) => {
                return parseFloat(item.subsidy_amount)
            })

            let ss = 0;
            for (let i = subNum.length - 1; i >= 0; i--) {
                ss += subNum[i];
            }

            console.log(this.state.onSubsidy, subNum, ss)


            let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0
            let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(s) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00)) - (os ? parseFloat(os) : 0.00)
            if (count_discount_percentage) {
                feeSum *= count_discount_percentage
            }
            if (this.state.discount_percentage == 100) {
                feeSum = 0;
                ShowToast(app, 'the total payable amount is $0!');
            }
            feeSum = feeSum - (ss ? parseFloat(ss) : 0.00)

            this.setState({
                allMedicationFee: s - os,
                totalNum: feeSum
            }, () => {
                console.log(_op, s, this.state.medicationList)
            })

        })

    }

    discountAmount(value) {

        if (value >= 0) {

            this.setState({
                discount_amount: value
            }, () => {

                let subNum = this.state.onSubsidy.map((item) => {
                    return parseFloat(item.subsidy_amount)
                })

                console.log(this.state.onSubsidy)
                console.log(subNum)

                let ss = 0;
                for (let i = subNum.length - 1; i >= 0; i--) {
                    ss += subNum[i];
                }

                this.setState({
                    subNums: ss
                }, () => {

                    let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0
                    let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(this.state.allMedicationFee) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00))
                    if (count_discount_percentage) {
                        feeSum *= count_discount_percentage
                    }
                    if (this.state.discount_percentage == 100) {
                        feeSum = 0;
                        ShowToast(app, 'the total payable amount is $0!');
                    }
                    feeSum = feeSum - (ss ? parseFloat(ss) : 0.00)

                    console.log(parseFloat(this.state.subNums), feeSum)

                    if (feeSum < 0) {
                        ShowToast(app, 'The total amount cannot be negative!');
                    } else {
                        this.setState({
                            totalNum: feeSum
                        }, () => {
                            console.log(this.state.totalNum)
                        })
                    }
                })
            })

            console.log('0:' + this.state.consultation_fee, '1:' + this.state.allMedicationFee, '2:' + this.state.other_fees_description, '3:' + this.state.discount_amount, '4:' + this.state.subNums, '5:' + this.state.discount_percentage)
        } else {
            ShowToast(app, 'Yonly enter numbers between 1-100.');
        }

        console.log(value)

    }

    discountPercentage(value) {

        console.log(value)

        if (value >= 0 && value < 101) {
            this.setState({
                discount_percentage: value
            }, () => {
                let subNum = this.state.onSubsidy.map((item) => {
                    return parseFloat(item.subsidy_amount)
                })

                // console.log(this.state.onSubsidy)
                // console.log(subNum)

                let ss = 0;
                for (let i = subNum.length - 1; i >= 0; i--) {
                    ss += subNum[i];
                }

                this.setState({
                    subNums: ss
                }, () => {

                    let count_discount_percentage = parseFloat(this.state.discount_percentage) ? (1 - parseFloat(this.state.discount_percentage) / 100) : 0

                    let feeSum = (parseFloat(this.state.consultation_fee) + parseFloat(this.state.allMedicationFee) + (this.state.other_fees ? parseFloat(this.state.other_fees) : 0.00) - (this.state.discount_amount ? parseFloat(this.state.discount_amount) : 0.00))
                    if (count_discount_percentage !== 0) {
                        feeSum *= count_discount_percentage
                    }
                    if (this.state.discount_percentage == 100) {

                        feeSum = 0;
                        ShowToast(app, 'the total payable amount is $0!');
                    }

                    console.log(count_discount_percentage, feeSum)

                    feeSum = feeSum - (ss ? parseFloat(ss) : 0.00)
                    if (feeSum < 0) {
                        ShowToast(app, 'The total amount cannot be negative!');
                    } else {
                        this.setState({
                            totalNum: feeSum
                        }, () => {
                            console.log(this.state.totalNum)
                        })
                    }
                })
            })

        } else {
            ShowToast(app, 'Yonly enter numbers between 1-100.');
        }
    }

    render() {
        return (
            <FramePage
                name={ROUTES.consultationSummary}
            >
                <div className="navBar">
                    <div style={{ paddingLeft: '0.625rem' }}>
                        <Link onClick={this.goAppointment.bind(this)} color='gray'> Video Appointments </Link> &gt; <span>{this.state.patientName}</span>
                    </div>
                    <div className="footerBtn" style={{ paddingRight: '0.625rem' }}>
                        <Button className="navbarBtn btn-large outline-blue-btn" onClick={this.scheduleNextAppt.bind(this)} raised>Schedule Next Appointment</Button>
                        {this.state.disableFunctionThree === false ? (this.state.payStatus == "no_show" ?  null : <>
                            <Button className="navbarBtn btn-large outline-blue-btn" onClick={this.saveBill.bind(this)} raised>Save</Button>
                        </>) : null
                        }
                        {!this.state.disableFunctionTwo ? (this.state.payStatus == "no_show" ?  null : <>
                            <Button className="navbarBtn btn-large fill-blue-btn" onClick={this.confirmBill.bind(this)} raised fill> Confirm</Button>
                        </>) : null
                        }
                    </div>

                </div>

                <div className="consultationSummary-content">

                    <Row className="navTitleBar">
                        <Col>
                            Consultation Summary
                        </Col>
                        {/* <Col>
                        <div className="footerBtn">
                            <Button className="navbarBtn btn-large outline-blue-btn" onClick={this.scheduleNextAppt.bind(this)} raised>Schedule Next Appointment</Button>
                            {this.state.disableFunctionThree === false ? <>
                                <Button className="navbarBtn btn-large outline-blue-btn" onClick={this.saveBill.bind(this)} raised>Save</Button></> : null
                            }
                            {!this.state.disableFunctionTwo ? <>
                                <Button className="navbarBtn btn-large fill-blue-btn" onClick={this.confirmBill.bind(this)} raised fill> Confirm</Button> </> : null
                            }
                        </div>
                    </Col> */}
                    </Row>

                    <div className="whit-block">
                        <Row className="cs-block">
                            <Col width="45">

                                <Col style={{ alignItems: this.state.able_edit_delivery_status == true ? 'center' : '' }}>
                                    <span className="cs-left" style={{ marginRight: this.state.able_edit_delivery_status == true ? '3rem' : '' }}> Status:</span>
                                    {this.state.able_edit_delivery_status == false || this.state.payStatus == "no_show" ?
                                        <span className="cs-right">{showStatus(this.state.payStatus)}</span>

                                        : <Col className='search-input input-dropdown-wrap' style={{ width: '100%' }}>
                                            <select
                                                style={{ marginRight: '3rem' }}
                                                name="delivery"
                                                value={this.state.delivery_status ? this.state.delivery_status : ''}
                                                onChange={(e) => {
                                                    this.setState({ delivery_status: e.target.value });
                                                }}
                                            >
                                                <option value="" disabled>{showStatus(this.state.payStatus)} </option>
                                                {this.state.delivery_type === 'clinic_delivery' ? <option value="delivering">On Route</option> : null}
                                                {this.state.payStatus !== 'delivered' ? <option value="completed">Delivered</option> : null}
                                            </select>
                                        </Col>
                                    }
                                </Col><br />
                                <Col>
                                    <span className="cs-left"> Queue No.:</span>
                                    <span className="cs-right">{this.state.queue_no ? this.state.queue_no : '- -'}</span>
                                </Col><br />
                                <Col>
                                    <span className="cs-left"> Order No.:</span>
                                    <span className="cs-right">{this.state.order_no ? this.state.order_no : '- -'}</span>
                                </Col><br />
                                <Col>
                                    <span className="cs-left"> Date & Time:</span>
                                    <span className="cs-right">{this.state.datetime ? this.state.datetime : '- -'} </span>
                                </Col><br />
                                <Col>
                                    <span className="cs-left"> Call Date & Time:</span>
                                    <span className="cs-right">{this.state.video_call_date && this.state.video_call_time ? this.state.video_call_date + ',' + this.state.video_call_time : '- -'} </span>
                                </Col><br />
                                <Col>
                                    <span className="cs-left"> Doctor:</span>
                                    <span className="cs-right">{this.state.doctor_name ? this.state.doctor_name : '- -'}</span>
                                </Col><br />
                                <Col>
                                    <span className="cs-left"> Delivery Status:</span>
                                    <span className="cs-right">{this.state.deliveryStatus ? showDeliveryStatus(this.state.deliveryStatus) : '- -'}</span>
                                </Col>
                            </Col>
                        </Row>
                    </div>
                    <div className="gray-block">
                        <Row className="block_title" style={{ marginBottom: '10px' }}>
                            <Col width="45">
                                Patient Details
                            </Col>
                            <Col width="45">
                                <Link className="txt-green canClick" href={ROUTES.patientInfo} reloadCurrent reloadAll routeProps={{ user_profile_id: this.state.userProfileId }}> View full patient information </Link>
                            </Col>
                        </Row>
                        <Row className="cell_list mt20">
                            {/* <Col> */}
                            <Col width="50">
                                <Row className="cell_item">
                                    <Col width="40">Name: </Col>
                                    <Col width="60"> {this.state.patientName} </Col>
                                </Row>
                                <Row className="cell_item">
                                    <Col width="40">Identification No.: </Col>
                                    <Col width="60"> {this.state.nric} </Col>
                                </Row>
                            </Col>
                            <Col width="50">
                                <Row className="cell_item">
                                    <Col width="40">Mobile No.: </Col>
                                    <Col width="60"> {this.state.mobile} </Col>
                                </Row>
                                <Row className="cell_item">
                                    <Col width="40">Email: </Col>
                                    <Col width="60"> {this.state.email} </Col>
                                </Row>
                                <Row className="cell_item">
                                    <Col width="40">Drug Allergy: </Col>
                                    <Col width="60" style={{ color: '#B31D65' }}> {this.state.drug_allergy} </Col>
                                </Row>

                            </Col>
                            {/* </Col> */}
                        </Row>

                    </div>

                    {/* doctor's Diagnosis */}
                    <Block></Block>
                    <div className="gray-block">
                        <Row className="block_title">
                            <Col width="45">
                                Consultation Notes
                            </Col>
                            <Col width="45">
                                {this.state.consultationNoteOpen === false ?
                                    <span className="consult-notes" onClick={this.consultNotesOpen.bind(this)}> + </span> : <span className="consult-notes" onClick={this.consultNotesOpenFalse.bind(this)}> - </span>}
                            </Col>
                        </Row>
                        {this.state.consultationNoteOpen === true ? <Row className="cell_list">
                            <Col>
                                <Row className="cell_item" style={{ marginBottom: "15px" }}>
                                    <Col>
                                        <Input
                                            className="doctorNotes"
                                            outline
                                            type="textarea"
                                            placeholder="Notes"
                                            name="doctor_notes"
                                            value={this.state.doctorNotes}
                                            onChange={(e) => {
                                                this.setState({ doctorNotes: e.target.value });
                                            }}
                                            resizable
                                            disabled={this.state.disableFunctionTwo}
                                        />
                                    </Col>
                                </Row>
                                <Row className="addDiagnosisRow">
                                    <Col width="70">
                                        <Row className="cell_list mt20" noGap>
                                            <Col width="30" className="formLabel">
                                                Diagnosis:
                                            </Col>
                                            <Col width="70" className="multiSelect disableSelectAll">
                                                {/* <MultiSelect
                                                name="status_code"
                                                options={this.state.statusCodeOptions}
                                                selected={this.state.diagnosisSelected}
                                                filterOptions={this.onStatusCodeSearch.bind(this)}
                                                onSelectedChanged={this.onStatusCodeChange.bind(this)}
                                            ></MultiSelect> */}
                                                <MultiSelect
                                                    name="diagnosis"
                                                    options={this.state.diagnosisOptions}
                                                    selected={this.state.diagnosisSelected}
                                                    filterOptions={this.onDiagnosisSearch.bind(this)}
                                                    onSelectedChanged={this.onDiagnosisChange.bind(this)}
                                                    disabled={this.state.disableFunctionTwo}
                                                ></MultiSelect>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col width="30"></Col>
                                </Row>

                                {this.state.diagnosisList && this.state.diagnosisList.map((item, index) =>
                                    <Row key={item.value}>
                                        <Col width="70">
                                            <Row>
                                                <Col width="30"></Col>
                                                <Col width="70">{item.label}</Col>
                                            </Row>
                                        </Col>
                                        <Col width="30">
                                            <Button onClick={this.deleteDiagnosisSelected.bind(this, item.value, index)} disabled={this.state.disableFunctionTwo}>
                                                <Icon f7="xmark_circle" size="20px" color="red"></Icon>
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row> : ''}

                    </div>

                    {/* Consultation Documents*/}
                    {this.state.payStatus === 'cancel' ? '' : <>
                        <Block></Block>
                        <div className="gray-block">
                            <Row>
                                <Col>
                                    {this.renderConsultationDocuments()}
                                </Col>
                            </Row>
                        </div>

                        {/* <Block></Block>
                <div className="gray-block">
                    <Row className="block_title">
                        <Col width="95">
                            Delivery
                        </Col>
                    </Row>
                    <Row className="cell_list">
                        <Col width="70">
                            <Row className="cell_item">
                                <div className="fwl">
                                    <i>If delivery is required , user will select the delivery method.</i>
                                </div>
                                <div className="fwl">
                                    <i>Once payment complete, an email will be sent to you to track the rider's status</i>
                                </div>
                            </Row>
                            
                            <Row className="cell_item mt20">
                                <Col width="30">Delivery Required: </Col>
                                <Col width="70">
                                    <Row className="search-input input-dropdown-wrap">
                                        <select
                                            name="delivery_required"
                                            value={this.state.delivery_required ? this.state.delivery_required : ''}
                                            onChange={(e) => {
                                                this.setState({ delivery_required: e.target.value });
                                            }}
                                            disabled={this.state.disableFunction}
                                        >
                                            <option value="">Select</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="cell_item mt20 deliverInfo">
                                <Col width="30">Deliver method: </Col>
                                <Col width="70">
                                    <b className="mt20">{this.state.delivery ? this.state.delivery.name : '-'}</b>

                                    <div>{this.state.delivery && this.state.delivery.type !== 'self_collect' ? 'Deliver in ~ ' + this.state.delivery.day_required + 'hr' : ""}</div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div> */}

                        {/* Medical Certificate */}
                        <Block></Block>

                        <div className="gray-block ">
                            <div className='mc-lists'>

                                {this.state.medical_certificate_list && this.state.medical_certificate_list.map((item, index) => {

                                    return (
                                        <div key={item.id ? item.id : ''} className='medical-certificate-lists' style={{ marginBottom: '15px', paddingBottom: '15px', borderBottom: '1px solid gainsboro' }}>

                                            <Row className="block_title">
                                                <Col width="50">
                                                    Medical Certificate {item.mc_number ? item.mc_number : ''}
                                                </Col>
                                                <Col width="25" style={{ display: 'flex', justifyContent: item.id ? 'space-between' : 'flex-end' }}>
                                                    {
                                                        item.id ?
                                                            <Col width="10" className="preview-box">
                                                                <Button outline raised className="cell_btn outline-blue-btn" raised onClick={this.openPreview.bind(this, item.id)}>Preview</Button>
                                                            </Col> : null
                                                    }
                                                    <Col width="10">
                                                        <Button outline raised className="outline-pink-btn cell_btn" onClick={this.delMC.bind(this, item.id, index)} disabled={this.state.disableFunctionTwo}> Delete </Button>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row className="cell_list">
                                                <Col width='40'>
                                                    <Row className="cell_item">
                                                        <Col width="30">
                                                            No.of Day:
                                                        </Col>
                                                        <Col width='55' style={{ paddingRight: '10px' }}>
                                                            <div className="iconPreInput">
                                                                <input
                                                                    type='text'
                                                                    name="day_time"
                                                                    className="formInput"
                                                                    value={item.day_time ? item.day_time : ''}
                                                                    onChange={(e) => this.onChangeDayTime(e.target.value, index)}
                                                                    disabled={this.state.disableFunctionTwo}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="cell_list">
                                                <Col width='60'>
                                                    <Row className="cell_item">
                                                        <Col width="30">
                                                            Date Start:
                                                        </Col>
                                                        <Col width='70'>
                                                            <Col className="iconPreInput" width='30' style={{ marginRight: '10px', color: 'black' }}>
                                                                <input
                                                                    type='text'
                                                                    name="start_time"
                                                                    className="formInput"
                                                                    value={item.start_time ? item.start_time : ''}
                                                                    onChange={(e) => console.log(e.target.value, index)}
                                                                    disabled={this.state.disableFunctionTwo}
                                                                />
                                                                <img src={rili} className="Rili" alt="Rili" onClick={this.timesPop.bind(this, 'start', index, item.id)} />

                                                            </Col>
                                                            <Col className="iconPreInput" width='30' style={{ color: 'black' }}>
                                                                <input
                                                                    type='text'
                                                                    name="end_time"
                                                                    className="formInput"
                                                                    value={item.end_time ? item.end_time : ''}
                                                                    onChange={(e) => console.log(e.target.value, index)}
                                                                    disabled={this.state.disableFunctionTwo}
                                                                />
                                                                <img src={rili} className="Rili" alt="Rili" onClick={this.timesPop.bind(this, 'end', index, item.id)} />
                                                            </Col>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row className="cell_list">
                                                <Col className="cell_item" width='100' style={{ marginBottom: '10px', paddingTop: '10px' }}>
                                                    <List
                                                        className="normalFormCheckBox"
                                                    >

                                                        <Radio name={`demo-radio-inline${index}`} value="" defaultChecked={item.type && item.type == 0 ? true : false} onChange={this.listTypeChange.bind(this, 'typeOne', item.type, index)} disabled={this.state.disableFunctionTwo}>
                                                            <span className="col-30" style={{ marginLeft: '0' }}>
                                                                This is to certify that the patient is Unfit for school/work from <b>{item.start_time}</b> to <b>{item.end_time}</b> for <b>{item.day_time}</b> days.
                                                            </span>
                                                        </Radio>

                                                    </List>

                                                </Col>
                                                <Col className="cell_item" width='100'>

                                                    <List
                                                        className="normalFormCheckBox"
                                                    >

                                                        <Radio name={`demo-radio-inline${index}`} defaultChecked={item.type && item.type == 1 ? true : false} onChange={this.listTypeChange.bind(this, 'typeTwo', item.type, index)} disabled={this.state.disableFunctionTwo}>
                                                            {/* <Col width='30'> */}
                                                            <span className="col-30" style={{ marginLeft: '0', marginRight: '20px' }}>This is to certify that the patient should be excused from</span>
                                                            <div className="iconPreInput" style={{ width: '50rem' }}>
                                                                <input
                                                                    type='text'
                                                                    name="certify_name"
                                                                    className="formInput"
                                                                    value={item.certify_name ? item.certify_name : ''}
                                                                    placeholder='eg.Work / School / NS'
                                                                    onChange={(e) => this.onChangeCertifyName(e.target.value, index)}
                                                                    disabled={this.state.disableFunctionTwo}
                                                                />
                                                            </div>
                                                            {/* </Col> */}
                                                        </Radio>

                                                        <Col width='30'>
                                                            <span>from <b>{item.start_time}</b> to <b>{item.end_time}</b> for <b>{item.day_time}</b> days.</span>
                                                        </Col>
                                                    </List>

                                                </Col>
                                            </Row>


                                        </div>
                                    )
                                })}

                            </div>
                            <Row className="cell_list">
                                <Button className="navbarBtn btn-large outline-blue-btn" raised onClick={this.addMCList.bind(this)} disabled={this.state.disableFunctionTwo}>+ Add MC</Button>

                            </Row>
                        </div>


                        {/* Medication */}
                        <Block></Block>
                        <div className="gray-block">
                            <Row className="block_title">
                                <Col width="95">
                                    Medication
                                </Col>
                            </Row>
                            <Row className="cell_list" style={{ alignItems: 'center' }}>
                                <Col width='50'>
                                    <Row className="cell_item" style={{ justifyContent: 'flex-start' }}>
                                        <Col width="30">
                                            Search Name:
                                        </Col>
                                        <Col width='55' className="multiSelect disableSelectAll" style={{ paddingRight: '10px' }}>
                                            <MultiSelect
                                                // className="multiSelect"
                                                name="sessions_id"
                                                placeholder="Session ID"
                                                options={this.state.medicationOption}
                                                selected={this.state.medicatSelected}
                                                filterOptions={this.onMedicationSearchs.bind(this)}
                                                disabled={this.state.disableFunctionTwo}
                                                onSelectedChanged={this.onMedicationChange.bind(this)}
                                            ></MultiSelect>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col width='50'>
                                    <Row className="cell_item" style={{ justifyContent: 'flex-start' }}>
                                        <Col width="30">Drug Allergy: </Col>
                                        <Col width="55" style={{ color: '#B31D65' }}> {this.state.drug_allergy} </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <div className="data-table medication-table" style={{ marginTop: '15px' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            <th className="medium-only"></th>
                                            <th className="label-cell">NAME</th>
                                            <th className="numeric-cell">UNIT PRICE$</th>
                                            <th className="numeric-cell">QTY</th>
                                            <th className="numeric-cell">PURPOSE</th>
                                            <th className="numeric-cell">PRECAUTION</th>
                                            <th className="medium-only">DOSAGE</th>
                                            <th className="medium-only">DISCOUNT%</th>
                                            <th className="medium-only">PRICE</th>
                                            <th className="medium-only">USER REQUEST</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.medicationList && this.state.medicationList.map((item, index) => {
                                            // console.log(this.state.medicationList)
                                            return (
                                                <tr key={'medication' + index}>

                                                    <td className="medium-only td-delete">
                                                        <img src={deleteIcon} className="Delete" alt="Delete" onClick={this.deleteTd.bind(this, index, item)} />
                                                        <div style={{ position: item.user_request === 'NOT_REQUIRED' ? 'relative' : '', display: item.user_request === 'NOT_REQUIRED' ? 'block' : 'none' }}>
                                                            <div style={{ width: '80vw', position: 'absolute', top: ' -1.1rem', left: '3rem', borderBottom: '1px solid #000' }}></div>
                                                        </div>
                                                    </td>

                                                    <td className="label-cell">

                                                        <Input
                                                            className="doctorNotes medicationInput"
                                                            outline
                                                            type="textarea"
                                                            placeholder=""
                                                            name="doctor_name"
                                                            value={item.name}
                                                            // onChange={(e) => {
                                                            //     this.setState({ medication_name: e.target.value });
                                                            // }}
                                                            onChange={(e) => this.changeMedicationName(e.target.value, index)}
                                                            resizable
                                                        // disabled={this.state.disableFunction || item.name ? true : false}
                                                        />

                                                    </td>
                                                    <td className="medium-only">

                                                        <Input
                                                            className="doctorNotes medicationInput"
                                                            outline
                                                            type="textarea"
                                                            placeholder=""
                                                            name="medication_selling_price"
                                                            value={item.unit_price}
                                                            onChange={(e) => this.changeMedicationUnitPrice(e.target.value, index, item)}
                                                            resizable
                                                            disabled={this.state.disableFunctionTwo || item.user_request === 'NOT_REQUIRED'}
                                                        />

                                                    </td>
                                                    <td className="medium-only">
                                                        <Input
                                                            className="doctorNotes medicationInput"
                                                            outline
                                                            type="textarea"
                                                            placeholder=""
                                                            name="medication_quantity"
                                                            value={item.qty}
                                                            onChange={(e) => this.changeMedicationQuantity(e.target.value, index, item)}
                                                            resizable
                                                            disabled={this.state.disableFunctionTwo || item.user_request === 'NOT_REQUIRED'}
                                                        />

                                                    </td>
                                                    <td className="medium-only">

                                                        <Input
                                                            className="doctorNotes medicationInput"
                                                            outline
                                                            type="textarea"
                                                            placeholder=""
                                                            name="medication_purpose"
                                                            value={item.purpose ? item.purpose : ''}
                                                            onChange={(e) => this.changeMedicationPurpose(e.target.value, index)}
                                                            resizable
                                                            disabled={this.state.disableFunctionTwo || item.user_request === 'NOT_REQUIRED'}
                                                        />

                                                    </td>
                                                    <td className="medium-only">


                                                        <Input
                                                            className="doctorNotes medicationInput"
                                                            outline
                                                            type="textarea"
                                                            placeholder=""
                                                            name="doctor_notes"
                                                            value={item.precautions ? item.precautions : ''}
                                                            onChange={(e) => this.changeMedicationPercautions(e.target.value, index)}
                                                            resizable
                                                            disabled={this.state.disableFunctionTwo || item.user_request === 'NOT_REQUIRED'}
                                                        />

                                                    </td>
                                                    <td className="medium-only">

                                                        <Input
                                                            className="doctorNotes medicationInput"
                                                            outline
                                                            type="textarea"
                                                            placeholder=""
                                                            name="doctor_notes"
                                                            value={item.dosage ? item.dosage : ''}
                                                            onChange={(e) => this.changeMedicationDosage(e.target.value, index)}
                                                            resizable
                                                            disabled={this.state.disableFunctionTwo || item.user_request === 'NOT_REQUIRED'}
                                                        />

                                                    </td>
                                                    <td className="medium-only">

                                                        <Input
                                                            className="doctorNotes medicationInput"
                                                            outline
                                                            type="textarea"
                                                            placeholder=""
                                                            name="medication_discount"
                                                            min={0}
                                                            max={100}
                                                            value={item.discount}
                                                            onChange={(e) => this.changeMedicationDiscount(e.target.value, index, item)}
                                                            resizable
                                                            disabled={this.state.disableFunctionTwo || item.user_request === 'NOT_REQUIRED'}
                                                        />

                                                    </td>
                                                    <td className="medium-only">

                                                        ${MoneyToFixed(item.price)}

                                                    </td>

                                                    <td className="medium-only" style={{ color: item.user_request === 'REQUIRED' ? '#B31D65' : 'gray', textAlign: item.user_request === 'REQUIRED' ? 'center' : '' }}>
                                                        {showStatus(item.user_request)}
                                                    </td>

                                                </tr>

                                            )
                                        })}


                                    </tbody>
                                </table>
                            </div>
                        </div>


                        {/*  Invoice*/}
                        <Block></Block>
                        <div className="gray-block">
                            <Row className="block_title" style={{ marginBottom: '10px' }}>
                                <Col width="95">
                                    Payment
                                </Col>
                            </Row>
                            <Row className="cell_list">
                                <Col width="60" style={{ paddingBottom: '15px', borderBottom: '1px solid #f9f9f9' }}>
                                    <Row className="cell_item">
                                        <Col width="30">Consultation fee: </Col>
                                        <Col width="70">
                                            ${MoneyToFixed(this.state.consultation_fee ? this.state.consultation_fee : 0.00)}
                                        </Col>
                                    </Row>
                                    <Row className="cell_item">
                                        <Col width="30"> Medication fee: </Col>
                                        <Col width="70">
                                            ${MoneyToFixed(this.state.allMedicationFee ? this.state.allMedicationFee : this.state.medication_fee)}
                                        </Col>
                                    </Row>
                                    <Row className="cell_item">
                                        <Col width="30">Other fees: </Col>
                                        <Col width="70">
                                            <div className="payment-box-left">
                                                <div className="iconPreInput">

                                                    <Input
                                                        className="doctorNotes"
                                                        outline
                                                        type="text"
                                                        name="other_fees"
                                                        value={this.state.other_fees_description ? this.state.other_fees_description : ''}
                                                        placeholder="Description"
                                                        onChange={(e) => {
                                                            this.setState({ other_fees_description: e.target.value })
                                                        }}
                                                        resizable
                                                        disabled={this.state.disableFunctionTwo}
                                                    />
                                                </div>
                                            </div>

                                            <span className="kge"></span>

                                            <div className="payment-box-right">

                                                <Input
                                                    className="doctorNotes"
                                                    outline
                                                    type="text"
                                                    placeholder="$0.00"
                                                    name="other_fees"
                                                    value={this.state.other_fees}
                                                    onChange={(e) => this.otherFeesDesc(e.target.value)}
                                                    resizable
                                                    disabled={this.state.disableFunctionTwo}
                                                />
                                            </div>

                                        </Col>


                                    </Row>
                                    <Row className="cell_item" style={{ marginBottom: '10px' }}>
                                        <Col width="30">Discount:</Col>
                                        <Col width="70">

                                            {/* <Col width='30'> */}
                                            <div className="payment-box-left">
                                                <div className="iconPreInput">

                                                    <Input
                                                        className="doctorNotes"
                                                        outline
                                                        type="text"
                                                        placeholder="$0.00"
                                                        name="discount_amount"
                                                        value={this.state.discount_amount}
                                                        onChange={(e) => this.discountAmount(e.target.value)}
                                                        resizable
                                                        disabled={this.state.disableFunctionTwo}
                                                    />
                                                </div>
                                            </div>

                                            <span className="kge">or</span>

                                            <div className="payment-box-right">
                                                <div className="iconPreInput">

                                                    <Input
                                                        className="doctorNotes"
                                                        outline
                                                        type="text"
                                                        placeholder="Percentage           %"
                                                        name="discount_percentage"
                                                        value={this.state.discount_percentage}
                                                        onChange={(e) => this.discountPercentage(e.target.value)}
                                                        resizable
                                                        disabled={this.state.disableFunctionTwo}
                                                    />
                                                </div>
                                            </div>

                                        </Col>

                                    </Row>

                                    <Row className="cell_item">
                                        <Col width="20">
                                            Discount applies to total bill
                                        </Col>
                                    </Row>
                                    <Row className="cell_item sub">
                                        <Row style={{ width: '30%' }}>Subsidy:</Row>
                                        <Row style={{ width: '70%' }}>

                                            {this.state.onSubsidy && this.state.onSubsidy.map((item, index) => {

                                                {
                                                    if (this.state.discount_percentage == 100) {
                                                        item.subsidy_amount = 0
                                                    }

                                                    console.log(this.state.onSubsidy)
                                                }

                                                return (
                                                    <div key={index} className="sub-boxs">
                                                        <Row style={{ justifyContent: 'end' }} className="sub-box">

                                                            <Col width="35" className='search-input input-dropdown-wrap' style={{ height: '35px' }}>

                                                                <select
                                                                    style={{ marginRight: '3rem', color: this.state.searchFlag === true ? 'gray' : 'black' }}
                                                                    name="subsidy"
                                                                    value={item.status}
                                                                    onChange={(e) => this.onChangeSubSatus(e.target.value, index)}
                                                                    disabled={this.state.disableFunctionTwo || this.state.totalNum == 0 && this.state.discount_percentage == 100}
                                                                >
                                                                    <option value="" disabled> Select one </option>
                                                                    <option value="chas">CHAS</option>
                                                                    <option value="medisave">Medisave</option>
                                                                    <option value="others">Others</option>
                                                                </select>

                                                            </Col>

                                                            <span className="kge"></span>
                                                            <Col width='35'>
                                                                <div className="iconPreInput">
                                                                    <Input
                                                                        className="doctorNotes"
                                                                        outline
                                                                        type="text"
                                                                        placeholder="$0.00"
                                                                        name="subsidy_amount"
                                                                        value={item.subsidy_amount ? item.subsidy_amount : ''}
                                                                        onChange={(e) => this.onChangeSubAmount(e.target.value, index)}
                                                                        resizable
                                                                        disabled={this.state.disableFunctionTwo || this.state.totalNum == 0 && this.state.discount_percentage == 100}
                                                                    />
                                                                </div>

                                                            </Col>
                                                            {
                                                                index == 0 ?
                                                                    <div className="addSub">
                                                                        <span className="consult-notes" onClick={this.addSubsidy.bind(this)}> + </span>
                                                                    </div> : ''
                                                            }

                                                            {
                                                                index > 0 ?
                                                                    <div className="delete-sub">
                                                                        <Button onClick={this.deleteSubSelected.bind(this, index)}
                                                                            disabled={this.state.disableFunctionTwo}
                                                                        >
                                                                            <Icon f7="xmark_circle" size="20px" color="red"></Icon>
                                                                        </Button>
                                                                    </div> : ''

                                                            }

                                                        </Row>

                                                    </div>
                                                )
                                            })}

                                        </Row>

                                    </Row>

                                </Col>

                            </Row>

                            <Row className="f9-delimiter">
                                <Col></Col>
                            </Row>

                            <Row className="cell_item" style={{ paddingTop: '10px', fontSize: '20px', justifyContent: 'flex-start' }}>
                                <div width="20" style={{ width: '18%' }}>
                                    Total Payable:
                                </div>
                                <div width="80">
                                    ${MoneyToFixed(this.state.totalNum)}
                                </div>
                            </Row>
                            <Row className="cell_list">
                                <Col>
                                    {/* {this.renderInvoiceTable()} */}
                                </Col>
                            </Row>

                        </div>
                        <Row style={{ paddingLeft: '8px' }}>
                            {!this.state.disableFunction ?
                                <p className="mt10 txt-gray">
                                    Once you have completed the summary & uploading the documents, please click confirm and patient will receive the invoice and payment link.
                                </p>
                                : ''}
                        </Row>
                    </>}

                </div>
                <ReschedulePopUp
                    userProfileId={this.state.userProfileId}
                    drList={this.state.drList}
                />

                <TimePopUp
                    userProfileId={this.state.userProfileId}
                    drList={this.state.drList}
                    parent={this}
                />

            </FramePage >
        );
    }
}

export default ConsultationSummary;