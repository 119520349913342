import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import Menu from '../MenuBar/Menu/Menu'
import useVideoContext from "../../hooks/useVideoContext/useVideoContext";
import useParticipantNetworkQualityLevel
    from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import NetworkQualityLevel from '../NewtorkQualityLevel/NetworkQualityLevel';
import useMainSpeaker from "../../hooks/useMainSpeaker/useMainSpeaker";
import useSelectedParticipant from "../VideoProvider/useSelectedParticipant/useSelectedParticipant";
import {VideoContext} from "../VideoProvider";
import useLocalVideoToggle from "../../hooks/useLocalVideoToggle/useLocalVideoToggle";
import useLocalAudioToggle from "../../hooks/useLocalAudioToggle/useLocalAudioToggle";
import { useAppState } from '../../state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            backgroundColor: 'rgba(0,0,0,0.2)',
            display: 'flex',
            position: 'absolute',
            alignSelf: 'flex-end',
            justifyContent: 'space-between',
            zIndex: 1,
            opacity: 0,
            visibility: 'hidden',
            '&.showControls, &:hover': {
                opacity: 1,
                visibility: 'visible',
            },
            [theme.breakpoints.down('xs')]: {
                bottom: `${theme.sidebarMobileHeight + 3}px`,
            },
        },
    })
);

export default function Controls() {
    const classes = useStyles();
    const roomState = useRoomState();
    const isReconnecting = roomState === 'reconnecting';
    // const isUserActive = useIsUserActive();
    // const showControls = isUserActive || roomState === 'disconnected';
    const showControls = true;
    const {room: {localParticipant},} = useVideoContext();
    const [selectedParticipant] = useSelectedParticipant();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const {isRemoteMuteVideo, isRemoteMuteAudio} = useAppState();

    const networkQualityLevel = useParticipantNetworkQualityLevel(selectedParticipant || localParticipant);

    return (
        <div className={clsx(classes.container, {showControls})}>
            <div className="menu-local-net-level">
                {/* {roomState !== 'disconnected' && <NetworkQualityLevel qualityLevel={networkQualityLevel}/>} */}
            </div>
            <div>
                <ToggleVideoButton disabled={isReconnecting}/>
                <VideoContext.Consumer>
                    {
                        ({endCall}) =>
                            <EndCallButton endCall={endCall}/>
                    }
                </VideoContext.Consumer>
                {/*{roomState !== 'disconnected' && <EndCallButton />}*/}
                <ToggleAudioButton disabled={isReconnecting}/>
                {/*{roomState !== 'disconnected' && <ToggleScreenShareButton disabled={isReconnecting} />}*/}
            </div>
            <div>
                <Menu/>
            </div>
            {/* <div className="status-indicator">
                {isRemoteMuteVideo && <p><VideocamOff /> <span>User video is off</span></p>}
                {isRemoteMuteAudio && <p><MicOff /> <span>User is on mute</span></p>}
                {isVideoEnabled || <p><VideocamOff /> <span>Your video is off</span></p>}
                {isAudioEnabled || <p><MicOff /> <span>You are on mute</span></p>}
            </div> */}
        </div>
    );
}
