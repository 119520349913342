import React, { useEffect, useState } from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import { f7 as app } from "framework7-react";

import CallEnd from '@material-ui/icons/CallEnd';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import {useAppState} from '../../../state';
import useRoomState from "../../../hooks/useRoomState/useRoomState";
import {VideoContext} from "../../VideoProvider";
import AuthGuard from "../../../../../services/AuthGuard";
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import events from '../../../../../services/events';
import { ShowToast, playAudio } from '../../../../../commons/Helpers';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        fab: {
            margin: theme.spacing(1),
        },
    })
);

export default function EndCallButton(endCall: {endCall: any}) {
    const classes = useStyles();
    const {room, localTracks} = useVideoContext();
    const roomState = useRoomState();
    const {uploadLog} = useAppState();
    const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
    const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
    const [canEndThrottle, setCanEndThrottle] = useState(false);

    const endConsultation = () => {
        app.popup.close(".settings-popup");
        if (room.state && room.state !== 'disconnected') {
            localTracks.forEach(track => track.stop())
            AuthGuard.localVideoTrack && AuthGuard.localVideoTrack.stop()
            playAudio('endCallMp3', false);
            (new Promise(() => room.disconnect())).then(() => {}).catch(() => {})
            ShowToast(app, "You have left the room");
        }
    }

    useEffect(() => {
        events.on("EndConsultation", endConsultation)
        events.on("Logout", endConsultation)
        events.on("AutoLogout", endConsultation)
        return () => {
            events.off("EndConsultation", endConsultation)
            events.off("Logout", endConsultation)
            events.off("AutoLogout", endConsultation)
        }
    }, [endConsultation, roomState])

    const tryEndCall = async () => {
        if (canEndThrottle || roomState !== 'connected') {
            return
        }
        setCanEndThrottle(true);
        setTimeout(() => {
            setCanEndThrottle(false);
        }, 1000)
        AuthGuard.drCloseVideo({
            // @ts-ignore
            userQueueId: AuthGuard.videoCallInfo.videoCallId
        }, (isok, res) => {
            if (isok) {
                uploadLog()
                // @ts-ignore
                endCall.endCall("IconEndCall", () => {
                    events.emit("EndConsultation")
                })
            } else {
                ShowToast(app, 'End Call failed');
            }
        })
    }

    return (
        <Tooltip title={'End Call'} onClick={tryEndCall} placement="top"
                 PopperProps={{disablePortal: true}}>
            <Fab className={classes.fab} color="primary">
                <CallEnd/>
            </Fab>
        </Tooltip>
    );
}
