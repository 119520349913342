import React, { Component } from 'react';
import { f7 as app, Card, CardContent, Button, Popover, List, ListItem, Popup, Page, BlockTitle, Toggle } from "framework7-react";
import Draggable, { DraggableCore } from "react-draggable";
import VideoCallForm from '../screens/VideoCallForm'
import { ShowToast, isInvalid } from '../commons/Helpers';
import AuthGuard from '../services/AuthGuard';
import { AGORA_APPID } from '../commons/Constants';
import TwilioVideo from './twilio/index'
import events from '../services/events';

class VideoCallV3 extends Component {
  constructor(props) {
    super(props);
    this.videoCallForm = React.createRef();
    this.state = {
      draggableAbled: false,
      layoutType: "VideoCallV2Layout1",
      tempLayoutType: "VideoCallV2Layout1",
      localStream: undefined,
      remoteStreamList: [],
      Video: "IconVideo1",
      MuteMic: "IconMuteMic1",
      resolution: "1080p",
      windowWidth: 0,
      windowHeight: 0,
      video: true,
      audio: true,
      showProfile:false,
      readyCallSuccess:false,
      tellPatientIncallSuccess: false,
      twilioToken: '',
      SwitchVideo:false
    };


    //agora config
    // this.appId = "ee19e812ea244863b7b3dbd3c13966ae";
    // this.channelName = "ourheartvoice.testchannel";
    // this.streamId = 12345;
    this.appId = AGORA_APPID
    this.channelName = ""
    this.streamId = ''
    this.mode = "rtc"
    this.codec = "vp8"
    this.token = undefined

    this.screenToken = undefined
    this.screenStreamId = ''
    this.cameraId = ""
    this.microphoneId = ""

    this.localUser = null;
    this.remoteUser = null;
    this.remoteUsers = [];
    this._remoteStream =  undefined;
    this.localStream = undefined;
    this.localStatTimer = null;

    // client state
    this.initializing = false;
    this.published = false;
    this.joined = false;
    this.cameras = [];
    this.microphones = [];
    this.dialing_code = "";
    this.mobile_number = "";

    //share screen
    this.screenSharePublished = false
    this.localScreenShare = ""
    this.screenClient = ""
    this.initializingScreenShare = false

    this.scrollTo = true
    
  }

  componentDidMount() {
    this.props.onRef(this);
    let self = this;
    // this.initialize();

    // window.addEventListener("scroll", this.handleScroll.bind(this)); //监听滚动
    this.$$("#CDPopupPageContent").on("scroll", function () {
      // console.log(this.scrollTop);
      self.handleScroll(this);
    });
  }

  componentWillUnmount() {
    console.log('componentWillUnmount')
    // window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll = (evt) => {
    // console.log(evt.scrollTop);
    // console.log(evt.scrollHeight);
    // console.log(evt.clientHeight);
    // console.log(this.$$(window).width());
    // console.log(this.$$(window).height());
    let self = this
    if (evt.scrollTop >= 600) {
      this.setState({
        draggableAbled: true,
        layoutType: "VideoCallV2Layout3",
        // windowWidth: this.$$(window).width(),
        windowWidth: this.$$(window).width() - 360,
        // windowHeight:this.$$(window).height()
        windowHeight: 0,
      },()=>{
        // if(self.state.layoutType === 'VideoCallV2Layout3') return 
        
      });

      if(this.scrollTo){
        console.log(this.scrollTo)
        // this.$$("#CDPopupPageContent").scrollTo(50, 100, () => {
        //   console.log('1232')
        //     self.scrollTo = false
        // });
      }
    } else {
      if (this.state.layoutType === "VideoCallV2Layout3") {
          if (evt.scrollTop <= 20) {
            this.setState({
              draggableAbled: false,
              layoutType: this.state.tempLayoutType,
              windowWidth: 0,
              windowHeight: 0,
            });
          this.scrollTo = true
          }
        }
      }
  };

  toolBarFun = (actionType, then) => {
    let self = this;
    if (actionType === "IconEndCall") {
      app.dialog.create({
        title: 'End Call',
        text: 'Are you sure you want to end this consultation?',
        buttons: [
          {
            text: 'Yes',
            color: 'red',
            onClick: async () => {
              // await this.videoCallForm.current.checkChangesBeforeCloseVideo()
              // self.playAudio('endCallMp3', false);
              // self.leave();
              then()
            }
          },
          {
            text: 'No',
            color: 'blue',
            onClick: () => {
              console.log('no')
            }
          },
        ],
        verticalButtons: true,
      }).open();
    } else if (actionType === "EndConsultation") {
      app.dialog.create({
        title: 'End Call',
        text: 'Are you sure you want to end this consultation?',
        buttons: [
          {
            text: 'Yes',
            color: 'red',
            onClick: async () => {
              await this.videoCallForm.current.checkChangesBeforeCloseVideo()
              self.leave();
              events.emit('EndConsultation');
              let endType = AuthGuard.patientInCall ? "endCall" : "cancel";
              AuthGuard.patientInCall = false;
              if (AuthGuard.videoCallInfo.chat.status === 'progress') {
                endType = 'endCall'
              }
              then(endType)
            }
          },
          {
            text: 'No',
            color: 'blue',
            onClick: () => {
              console.log('no')
            }
          },
        ],
        verticalButtons: true,
      }).open();
    } else if (actionType === "video") {
      this.settingVideo();
    } else if (actionType === "mic") {
      this.settingMic();
    }
  };

  initialize = async () => {
    this.sendVideoUrlToOriginUser();
    this.initializing = true;
    let { originUser } = this.props;
    if (this.localUser) return;

    if (originUser.doctor_uid !== undefined) {
      this.streamId = originUser.doctor_uid;
      this.channelName = originUser.channel_code_hash;
      this.token = originUser.doctor_token;
      this.dialing_code = originUser.dialing_code;
      this.mobile_number = originUser.mobile_number;
    }
    AuthGuard.twilioToken = originUser.doctorToken
    AuthGuard.videoCallInfo.canConnect = true
    setTimeout(() => app.popup.open(".settings-popup"), 1000);
  };

  sendVideoUrlToOriginUser = ()=>{
    let { dialingCode, mobileNumber } = this.props.originUser;
    if (this.state.readyCallSuccess) {

    } else {
      AuthGuard.readyCall({ queue_id: this.props.videoCallId, dialing_code: dialingCode, mobile_number: mobileNumber }, (isok, res) => {
        // console.log(res);
        if (isok) {
          this.setState({
            readyCallSuccess: true
          })
        } else {
          this.setState({
            readyCallSuccess: false
          })
        }
      })
    }
  }

  getScreenToken = ()=>{
    return new Promise((resolve, reject) => {
      AuthGuard.AGTken({
        id: this.props.videoCallId
      }, (isOk, res) => {
        if (isOk) {
          resolve(res)
        } else {
          this.notify("Share screen init error!", "error");
          reject(false)
        }
      })
    })
  }


  _parseError(err) {
    if (err instanceof Error) {
      return String(err);
    } else {
      return JSON.stringify(err);
    }
  }
  notify(msg, type = "info") {
    // this.snackbar = true;
    // this.color = type;
    this.text = msg;
    ShowToast(app, msg);
    console.log(msg);
  }

  playAudio(audioID, _loop = true) {
    var audio = document.getElementById(audioID);
    if (audio !== null) {
      if (audio.paused) {
        audio.play();// 播放
        document.getElementById(audioID).loop = _loop;
      }
    }
  }

  closeAudio(audioID) {
    var audio = document.getElementById(audioID);
    if (audio !== null) {
      if (!audio.paused) {
        audio.pause();// 播放
        audio.load();
        document.getElementById(audioID).loop = false;
      }
    }
  }

  tellPatientInCall() {
    if (this.state.tellPatientIncallSuccess) {

    } else {
      AuthGuard.tellPatientIncall({ id: this.props.videoCallId }, (isok, res) => {
        if (isok) {
          this.setState({
            tellPatientIncallSuccess: true
          })
          AuthGuard.switchVideoCallTimer('on');
        } else {
          this.setState({
            tellPatientIncallSuccess: false
          })
        }
      });
    }

  }

  leave = async  () => {
        // this.leaving = true;
    let self = this;
    let endType = this.state.tellPatientIncallSuccess ? "endCall" : "cancel";
    this.setState({
      // SwitchCamera: "IconSwitchCamera1",
      Video: "IconVideo1",
      MuteMic: "IconMuteMic1",
      tellPatientIncallSuccess: false,
      readyCallSuccess: false,
    });
    try {
      if (
        this.localUser &&
        this.localUser.stream
      ) {
        this.localUser.stream.stop();
        this.localUser.stream.close();
        this.client.unpublish(this.localUser.stream);
        this.published = false;
        this.localUser = null;
        this.client.leave();
      } else {
        this.published = false;
        this.localUser = null;
      }

      if (this.localScreenShare && this.localScreenShare.stream){
        this.localScreenShare.stream.stop();
        this.localScreenShare.stream.close();
        this.screenClient.unpublish(this.localScreenShare.stream);
        this.screenSharePublished = false;
        this.localScreenShare = null;
        this.screenClient.leave();
      }else{
        this.screenSharePublished = false;
        this.localScreenShare = null;
      }

      console.log(this.remoteUser)
      if (this.remoteUser && this.remoteUser.stream) {
        this.remoteUser.stream.stop();
        this.remoteUser.stream.close();
        this.remoteUser = null;
      }
      
      this.client = null;
      this.screenClient = '';
      this.remoteUsers = [];
      this.joined = false;
    } catch (err) {
      console.error(err);
      this.notify(err);
    } finally {
      if (this.localStatTimer) clearInterval(this.localStatTimer)
      // this.leaving = false;
      setTimeout(() => {
        this.videoCallForm.current.endVideoCall()
        // self.props.endVideoCall(endType);
      }, 100);
    }
  };

  switchVideoFun(type){
    console.log('switchVideoFun')
    if (this.state.layoutType !== 'VideoCallV2Layout1') return
    this.setState({
      SwitchVideo: !this.state.SwitchVideo
    })  
  }

  settingVideo = (props) => {
    if (!this.localUser || !this.localUser.stream) {
      return;
    }

    if (this.state.Video === "IconVideo1") {
      this.setState({
        Video: "IconVideo2",
        video: false,
      });
    } else {
      this.setState({
        Video: "IconVideo1",
        video: true,
      });
    }
  };

  settingMic = () => {
    if (!this.localUser || !this.localUser.stream) {
      return;
    }

    if (this.state.MuteMic === "IconMuteMic1") {
      this.setState({
        MuteMic: "IconMuteMic2",
        audio: false,
      });
    } else {
      this.setState({
        MuteMic: "IconMuteMic1",
        audio: true,
      });
    }
  };

  settingClick = (actionType) => {
    if (actionType === 'showProfile') {
      this.showProfile()
      return false 
    }

    if (actionType === "layout") {
      app.popup.open(".changeVideoLayout-popup");
    }
    if (actionType === "quality") {
      app.popup.open(".Resolution-popup");
    }
    if (actionType === "share"){
      this.initScreenShare()
    }
    app.popover.close(".popover-moremenu");
  };

  showProfile = () => {
    this.setState({
      showProfile: !this.state.showProfile
    })
  }

  layoutClick = (actionType) => {
    if (actionType === "v1") {
      this.setState({
        layoutType: "VideoCallV2Layout1",
        tempLayoutType: "VideoCallV2Layout1",
      });
    }
    if (actionType === "v2") {
      this.setState({
        layoutType: "VideoCallV2Layout2",
        tempLayoutType: "VideoCallV2Layout2",
      });
    }
    app.popover.close(".changeVideoLayout-popup");
  };
  resolutionClick = (num) => {
    this.setState({
      resolution: num
    })
    this.localUser.stream.setVideoProfile(num);
    app.popover.close(".Resolution-popup");
  };

  handleDrag = (ev, ui) => {
    // console.log(ev);
    // console.log(ui);
    this.setState({
      windowWidth: ui.x,
      windowHeight: ui.y,
    });
  };

  render() {
    if (this.remoteUsers && this.remoteUsers.length > 0) {
      this.remoteUsers.map((remoteStream, index) => {
        remoteStream.stream.getStats((stats) => {
          this._remoteStream = {
            ...stats,
            uid: remoteStream.stream.getId(),
          }
        });
      });
    }
    
    return (
      <div className="draggable-box">
        <Draggable
          handle=".draggableHandle"
          defaultPosition={{
            x: 0,
            y: 0,
          }}
          position={{ x: this.state.windowWidth, y: this.state.windowHeight }}
          disabled={!this.state.draggableAbled}
          grid={[25, 25]}
          scale={1}
          onStart={() => true}
          bounds="parent"
          defaultClassName={`${
            this.state.draggableAbled ? "react-draggable" : ""
          }`}
          onDrag={this.handleDrag}
          // onStop={this.handleStop}
        >
          <div
            className={`${this.state.draggableAbled ? "draggableHandle" : ""}`}
          >
            <div className={`VideoCallV2 VideoCallV3 ${this.state.tempLayoutType}`}>
              {AuthGuard.twilioToken && <TwilioVideo token={AuthGuard.twilioToken} endCall={this.toolBarFun.bind(this)} />}
            </div>
          </div>
        </Draggable>
        {/* </div> */}
        <VideoCallForm ref={this.videoCallForm}/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br />
        <br />
        <Popup
          className="changeVideoLayout-popup"
          swipeToClose
          tabletFullscreen
        >
          <Page>
            <h3>Change Video Layout</h3>
            <div className="leftLayout">
              <div
                className={`leftLayout1 canClick ${
                  this.state.layoutType === "VideoCallV2Layout1"
                    ? "bgGray"
                    : "bgGreen"
                }`}
                onClick={() => this.layoutClick("v1")}
              >
                <div className="leftLayout11"></div>
                <div className="leftLayout12"></div>
              </div>
              <div
                className={`leftLayout2 canClick ${
                  this.state.layoutType === "VideoCallV2Layout2"
                    ? "bgGray"
                    : "bgGreen"
                }`}
                onClick={() => this.layoutClick("v2")}
              >
                <div className="leftLayout21"></div>
                <div className="leftLayout22"></div>
              </div>
            </div>
          </Page>
        </Popup>
        <Popup className="Resolution-popup" swipeToClose tabletFullscreen>
          <Page>
            <BlockTitle>Video Quality</BlockTitle>
            <List>
              <ListItem
                title="1080P"
                className={`canClick ${
                  this.state.resolution === "1080p" ? "txt-green" : ""
                }`}
                onClick={() => this.resolutionClick("1080p")}
              ></ListItem>
              <ListItem
                title="720P"
                className={`canClick ${
                  this.state.resolution === "720p" ? "txt-green" : ""
                }`}
                onClick={() => this.resolutionClick("720p")}
              ></ListItem>
              <ListItem
                title="480P"
                className={`canClick ${
                  this.state.resolution === "480p" ? "txt-green" : ""
                }`}
                onClick={() => this.resolutionClick("480p")}
              ></ListItem>
              <ListItem
                title="360P"
                className={`canClick ${
                  this.state.resolution === "360p" ? "txt-green" : ""
                }`}
                onClick={() => this.resolutionClick("360p")}
              ></ListItem>
              <ListItem
                title="240P"
                className={`canClick ${
                  this.state.resolution === "240p" ? "txt-green" : ""
                }`}
                onClick={() => this.resolutionClick("240p")}
              ></ListItem>
            </List>
          </Page>
        </Popup>
      </div>
    );
  }
}
 

export default VideoCallV3;