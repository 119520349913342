import React, { Component } from 'react'
import {
    f7 as app,
    Page,
    Button,
    LoginScreenTitle,
    List,
    Input,    
} from 'framework7-react';
export class ForgetPassWord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            submitLoading: true
        }
    }

    onPageInit() {

    }

    btnfw() {

    }

    render() {
        return (
            <Page loginScreen name="forgetPwPage" onPageInit={this.onPageInit.bind(this)}>
                <LoginScreenTitle>
                    <div>Authenticate</div>
                    <div className="login-sub-title">We just sent you an email with a code.<br/>Enter it to verify it is you.</div>
                </LoginScreenTitle>
                <List form id="my-form">
                    <div className="mt list-block">
                        <div className="mt">
                            <div className="col-right">
                                <Input
                                    required
                                    noStoreData
                                    clearButton
                                    type="text"
                                    className="username"
                                    placeholder="Enter email"
                                    onInput={(e) => {
                                        this.setState({ username: e.target.value });
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </List>
                <List>
                    <div className="message"></div>
                    <Button color="white" raised large className={`btnPw mt fill-blue-btn ${this.state.submitLoading ? '' : 'disabled'}`} onClick={this.btnfw.bind(this)}>
                        Next
                    </Button>
                </List>
            </Page>
        );
    }
}
export default ForgetPassWord;