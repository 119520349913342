import React, { Component } from 'react'
import {
    f7 as app,
    Page,
    Button,
    LoginScreenTitle,
    List,
    Input
} from 'framework7-react';
import { ROUTES } from '../commons/Constants';
import cceye from '../images/icons/cceye.png'
import eye from '../images/icons/eye.png'

import AuthGuard from '../services/AuthGuard';
import {encryptRequest} from '../commons/Helpers';
class Restpw extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resetPwSuccess: false,
            submitLoading: false,
            new_pw: '',
            confirm_pw: '',
            eye1Url: eye,
            eye2Url: eye,
            newFlag: 0,
            conFlag: 0,
        }
    }

    onPageInit() {

    }

    toLogin() {
        this.$f7router.back(ROUTES.login)
    }

    renderRTSuccess() {
        return (
            <>
                <LoginScreenTitle>
                    <div>Success</div>
                    <div className="login-sub-title">Your password has been reset.</div>
                </LoginScreenTitle>
                <List>
                    <Button color="white" raised large className={`btnPw mt fill-blue-btn`} onClick={this.toLogin.bind(this)}>
                        Go to Login
                    </Button>
                </List>
            </>
        )
    }

    resetPw() {
        let { new_pw, confirm_pw } = this.state
        let { email, userProfileID,otp } = this.props

        // console.log(otp)

        // return;

        if (new_pw !== confirm_pw) {
            this.$$('.message').html('The new password does not match, please check and try again.')
        } else {
            this.setState({
                submitLoading: true
            })
            AuthGuard.resetPassword({
                email: email,
                newPassword: encryptRequest(confirm_pw),
                userProfileId: userProfileID,
                otp_code:otp
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        resetPwSuccess: true,
                        submitLoading: false
                    })
                } else {
                    this.$$('.message').html('submit error.')
                }
            })
        }
    }

    eyeShow(type = 'newPassProfile', v) {

        var newPass = document.querySelector("#newPassword input")
        var conPass = document.querySelector("#confirmPassword input")


        if (type == 'newPassProfile') {
            if (this.state.newFlag == 0) {
                newPass.type = 'text';
                this.setState({
                    eye1Url: cceye,
                    newFlag: 1
                })
            } else {
                newPass.type = 'password';
                this.setState({
                    eye1Url: eye,
                    newFlag: 0
                })
            }
            // console.log(newPass)
        } else {
            if (this.state.conFlag == 0) {
                conPass.type = 'text';
                this.setState({
                    eye2Url: cceye,
                    conFlag: 1
                })
            } else {
                conPass.type = 'password';
                this.setState({
                    eye2Url: eye,
                    conFlag: 0
                })
            }
            // console.log(conPass)
        }
    }

    renderRestPw() {
        return (
            <>
                <LoginScreenTitle>
                    <div>Reset Password</div>
                    <div className="login-sub-title">The new password must contain at least 8</div>
                    <div className="login-sub-title">characters with alphabets and numbers.</div>
                </LoginScreenTitle>
                <List form id="my-form">
                    <div className="mt list-block">
                        <div className="mt">
                            <div className="col-right restpw">
                                <Input
                                    required
                                    noStoreData
                                    clearButton
                                    type="password"
                                    className="new_pw"
                                    id="newPassword"
                                    placeholder="New Password"
                                    onInput={(e) => {
                                        this.setState({ new_pw: e.target.value });
                                    }}
                                />
                                <img src={this.state.eye1Url} id="eye" alt="" onClick={this.eyeShow.bind(this, 'newPassProfile')} />
                            </div>
                        </div>
                        <div className="mt">
                            <div className="col-right restpw">
                                <Input
                                    required
                                    noStoreData
                                    clearButton
                                    type="password"
                                    className="confirm_pw"
                                    id="confirmPassword"
                                    placeholder="Confirm New Password"
                                    onInput={(e) => {
                                        this.setState({ confirm_pw: e.target.value });
                                    }}
                                />
                                <img src={this.state.eye2Url} id="eye" alt="" onClick={this.eyeShow.bind(this, 'conPassProfile')} />
                            </div>
                        </div>
                    </div>
                </List>
                <List>
                    <div className="message"></div>
                    <Button color="white" raised large className={`btnPw mt fill-blue-btn ${this.state.submitLoading ? 'disabled' : ''}`} onClick={this.resetPw.bind(this)}>
                        Confirm
                    </Button>
                </List>
            </>
        )
    }

    render() {
        return (
            <Page loginScreen name="forgetPwPage" id="forgetpw" onPageInit={this.onPageInit.bind(this)}>
                {this.state.resetPwSuccess ? this.renderRTSuccess() : this.renderRestPw()}
            </Page>
        )
    }
}

export default Restpw;