import React, { useState } from 'react';
import { Button, Dialog, DialogActions, Tab, Tabs, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ConnectionOptions from '../ConnectionOptions/ConnectionOptions';
import { DeviceSelector } from '../DeviceSelector/DeviceSelector';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '525px',
      minHeight: '400px',
      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 32px)',
      },
      '& .inputSelect': {
        width: '100%',
      },
    },
    button: {
      float: 'right',
      background: '#07B5C8',
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        margin: '16px',
      },
    },
  })
);

export default function SettingsDialog({ onClose }: { onClose: () => void }) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="video-settings">
      {/*<Tabs value={selectedTab} onChange={handleChange}>*/}
      {/*  <Tab label="Devices" />*/}
      {/*  <Tab label="Settings" />*/}
      {/*</Tabs>*/}
      <DeviceSelector className={classes.container} hidden={selectedTab !== 0} />
      {/*<ConnectionOptions className={classes.container} hidden={selectedTab !== 1} />*/}
    </div>
  );
}
