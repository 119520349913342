import React, { Component } from 'react';
import { f7 as app, View, Page, Link, Row, Col, Button, List, ListItem, Block } from 'framework7-react';
import SideBar from '../components/SideBar'
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { InputBlur, VerificationFields, ShowToast } from '../commons/Helpers';
import Modal from '../components/Modal';


class AddAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roleList: [],
            role_id: '',
            role_code: '',
            _role_code: '',
            user_profile_id: props.user_profile_id,
            name: '',
            email: '',
            app_start_word: '',
            professional_profile: '',
            addedAccount: false,
            navBarTitle: 'Add New Staff Account',
            delStaffModalOpen: false,
            loadingDelStaffBtn: false
        }
        this.coachProfile = AuthGuard.getCoachProfile();
        console.log(this.props)

    }

    componentDidMount() {
        let self = this;
        AuthGuard.getClinicRoleList({}, (isOk, res) => {
            if (isOk) {
                self.setState({
                    roleList: res
                })
            }
        })

        if (this.state.user_profile_id) {
            AuthGuard.getStaffDetail({
                user_profile_id: this.state.user_profile_id
            }, (isOk, res) => {
                console.log(res);
                if (isOk) {
                    self.setState({
                        name: res.name,
                        email: res.email,
                        role_code: res.role_code,
                        _role_code: res.role_code,
                        role_name: res.role_name,
                        role_id: res.role_id,
                        app_start_word: res.app_start_word,
                        professional_profile: res.professional_profile
                    })
                }
            })
        }
    }


    createAccountBtn = () => {
        var formData = app.form.convertToData('#create-new-role');
        if (!this.state.role_id) {
            ShowToast(app, 'Role is required !')
            return false;
        }

        if (!this.state._role_code) {
            ShowToast(app, 'Role Code is required !')
            return false;
        }
        this.setState({
            emailMsg: ''
        })
        if (this.state._role_code === 'doctor_user') {
            if (this.state.user_profile_id) {
                var noError = VerificationFields(this, app,
                    [
                        'name',
                        'app_start_word',
                    ]
                );
            } else {
                var noError = VerificationFields(this, app,
                    [
                        'name',
                        'email',
                        'app_start_word',
                    ]
                );
            }

        } else if (this.state._role_code === 'coach_user') {
            var noError = VerificationFields(this, app,
                [
                    'name'
                ]
            );
        } else {
            if (this.state.user_profile_id) {
                var noError = VerificationFields(this, app,
                    [
                        'name'
                    ]
                );
            } else {
                var noError = VerificationFields(this, app,
                    [
                        'name',
                        'email',
                    ]
                );
            }

        }


        if (noError) {
            app.preloader.show();
            // console.log(formData);
            let _formData = formData;
            _formData.role_code = this.state._role_code;
            console.log(this.state.user_profile_id)
            if (this.state.user_profile_id) {
                _formData.user_profile_id = this.state.user_profile_id;
            }

            if (this.state._role_code === 'coach_user' || this.state._role_code === "doctor_user") {
                _formData.role_id = this.state.role_id
            }

            if (this.state.user_profile_id) {
                _formData.email = this.state.email
            }

            AuthGuard.saveStaff(_formData, (isOk, res) => {
                if (isOk) {
                    if (!this.state.user_profile_id) {
                        this.setState({
                            addedAccount: true
                        })
                    } else {
                        app.dialog.alert(res, 'Alert', () => {
                            app.views.main.router.back();
                        });
                    }
                } else {
                    if(res.status_code == '11108'){
                        this.setState({
                            emailMsg: res.message
                        })
                    }
                }
                app.preloader.hide();
            });
        } else {
            console.log('Field Error!');
        }
    }

    delStaffPopupclose() {
        this.setState({
            delStaffModalOpen: false
        })
    }

    delStaffSave() {
        app.preloader.show()
        this.delStaffPopupclose()
        AuthGuard.delStaff({
            user_profile_id: this.state.user_profile_id
        }, (isOk, msg) => {
            if (isOk) {
                ShowToast(app, msg)
                this.$f7.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'manageAccountsTab' } })
            } else {
                ShowToast(app, msg)
            }

            app.preloader.hide()
        })
    }

    delAccountBtn = (e) => {
        this.setState({
            delStaffModalOpen: true
        })
    }

    inputBlur(atrName, type, e) {
        let res = InputBlur(app, atrName, this.$$("input[name='" + atrName + "']"), type);
        if (!res) {
          this.setState({
            [atrName]: "",
          });
        }
    }

    AddAccountForm() {
        return (
            <form id='create-new-role'>
                <Modal
                    tilte=""
                    modalOpened={this.state.delStaffModalOpen}
                    modalPopupClose={this.delStaffPopupclose.bind(this)}
                    descHtml={`
                        Are you sure you want to <span class="txt-red">Delete</span> this staff Account?<br/>
                    `}
                    onSave={this.delStaffSave.bind(this)}
                    loadingBtn={this.state.loadingDelStaffBtn}
                />
                <Row>
                    <Col width="25" className="formLabel InputRequired">
                        Select Role
                        </Col>
                    <Col width="45">
                        <div className={`${this.state.role_code === 'coach_user' || this.state.role_code === 'doctor_user' ? '' : 'search-input input-dropdown-wrap'}`}>
                            {this.state.role_code === 'coach_user' || this.state.role_code === 'doctor_user' ? (
                                <span>
                                    {this.state.role_name}
                                </span>
                            ) : (
                                    <select
                                        name="role_id"
                                        value={this.state.role_id}
                                        disabled={this.state.user_profile_id && (this.state.role_code === 'coach_user' || this.state.role_code === 'doctor_user')}
                                        onChange={(e) => {
                                            this.setState({
                                                role_id: e.target.value,
                                                _role_code: e.target[e.target.selectedIndex].getAttribute('data-rolecode')
                                            });
                                        }}
                                    >
                                        <option>Select a Role</option>
                                        {this.state.roleList.map((item, index) => {
                                            return (
                                                <option key={item.id} data-rolecode={item.role_code} value={item.role_id} style={{ display: item.role_name === 'Teleconsultation Provider' ? 'none' : 'block' }}>{item.role_name}</option>
                                            )
                                        })}
                                    </select>
                                )}

                        </div>
                    </Col>
                    <Col width="30"></Col>
                </Row>
                <Row>
                    <Col width="25" className="formLabel InputRequired">
                        Name
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="name"
                            className="formInput input-with-value input-invalid"
                            placeholder="Name"
                            value={this.state.name}
                            onBlur={this.inputBlur.bind(this, 'name', 'text')}
                            onChange={(e) => {
                                this.setState({ name: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>
                <Row>
                    <Col width="25" className="formLabel InputRequired">
                        Email
                    </Col>
                    <Col width="45">
                        {this.state.role_code === 'coach_user' || this.state.user_profile_id ? (
                            <p>{this.state.email}</p>
                        ) : (
                                <input
                                    type='email'
                                    name="email"
                                    className="formInput input-with-value input-invalid"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onBlur={this.inputBlur.bind(this, 'email', 'email')}
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value });
                                    }}
                                />
                            )}
                    </Col>
                    <Col width="30"></Col>
                </Row>
                <Row>
                    <Col width="25"></Col>
                    <Col width="75"><p style={{color:'red'}}>{this.state.emailMsg ? this.state.emailMsg : ''}</p></Col>
                </Row>
                {this.state._role_code === 'doctor_user' ? (
                    <React.Fragment>
                        <Row>
                            <Col width="25" className="formLabel InputRequired">
                                Whatsapp Start word
                        </Col>
                            <Col width="45">
                                <input
                                    type='text'
                                    name="app_start_word"
                                    className="formInput input-with-value input-invalid"
                                    placeholder="Whatsapp Start word"
                                    value={this.state.app_start_word}
                                    onBlur={this.inputBlur.bind(this, 'app_start_word', 'text')}
                                    onChange={(e) => {
                                        this.setState({ app_start_word: e.target.value });
                                    }}
                                />
                            </Col>
                            <Col width="30"></Col>
                        </Row>
                        <Row>
                            <Col width="25" className="formLabel ">
                                Professional Profile
                        </Col>
                            <Col width="45">
                                <textarea
                                    rows="8"
                                    // cols=""
                                    type='text'
                                    name="professional_profile"
                                    className="formTextarea input-with-value input-invalid"
                                    placeholder="Professional Profile"
                                    value={this.state.professional_profile}
                                    onBlur={this.inputBlur.bind(this, 'professional_profile', 'text')}
                                    onChange={(e) => {
                                        this.setState({ professional_profile: e.target.value });
                                    }}
                                />
                            </Col>
                            <Col width="30"></Col>
                        </Row>
                    </React.Fragment>
                ) : ""}
            </form>
        )
    }

    render() {
        return (
            <FramePage
                name={ROUTES.addAccount}
            >
                <div className="navBar">
                    <Link back animate={false} color='gray'> Settings </Link> &gt; <span>{this.state.user_profile_id ? "Edit Staff Account" : "Add Staff Account"}</span>
                </div>
                <Row className="navTitleBar">
                    <Col> {this.state.user_profile_id ? "Edit Staff Account" : "Add Staff Account"} </Col>
                    <Col>
                        <div className="footerBtn">
                            <Button back className="navbarBtn outline-blue-btn" raised >Cancel</Button>
                            {!(this.state.role_code === 'doctor_user' || this.state.role_code === 'coach_user')  && this.state.user_profile_id &&
                                <Button className="navbarBtn" onClick={this.delAccountBtn} fill color="red">Delete</Button>
                            }
                            {this.state.addedAccount ? '' : <Button className="navbarBtn fill-blue-btn" onClick={this.createAccountBtn} raised fill>{this.state.user_profile_id ? "Update" : "Confirm"} </Button>}
                        </div>
                    </Col>
                </Row>
                <div className="white-block">
                    {this.state.addedAccount ? <AddAccountSuccess /> : this.AddAccountForm()}
                </div>
            </FramePage>
        );
    }
}

const AddAccountSuccess = (props) => {

    return (
        <div className="fwl">
            Please check your email for a temporary password and click on the link provided to
            create your new password.
            <br /><br />
            This is done for security reasons.
        </div>
    )
}


export default AddAccount;