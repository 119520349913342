import React, { Component } from "react";
import FramePage from "../components/FramePage";
import {
  f7 as app,
  Row,
  Col,
  Button,
  Checkbox,
  Link,
  Tabs,
  Tab,
  Page,
  Popup,
  Icon,
  Fab,
  ListItem,
  List,
  Input,
} from "framework7-react";
import { ROUTES } from "../commons/Constants";
import {
  showDate,
  showStatus,
  ShowToast,
  getValdots,
} from "../commons/Helpers";
import AuthGuard from "../services/AuthGuard";
import Downloader from "js-file-downloader";
import sousuo from "../images/icons/sousuo.png";
import rili from "../images/icons/rili.png";
import UploadFilesV2 from "../components/UploadFilesV2";
import _ from "lodash";
import PDFViewer from "./PDFViewer";

var calendarToDate1 = null;
var calendarToDate2 = null;

class LabReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showScroolTopBtn: false,
      currentTabId: "pendingLabRpTab",
      nameOrAccountID: "",
      status: "",
      date_from: "",
      date_to: "",
      totalCount: null,
      pendingLabReps: [],
      archiveLabReps: [],
      iframeUrl: "",
      checkedids: [],
      checkedid: "",
      requestLoading: "",
      noResulstMsg:
        "There are no records found for this specific period or criteria.",
      report_documents: [],
      progress: 0,
      patientSearch: "",
      patientSearchId: "",
      showAuto: "none",
      beforeSearch: "",
      searchList: [],
      appDateList: [],
      appDateInfo: {},
      showCard: "",
      bodyInfo: null,
      report_upload_status: "static", //static,start,success,fail,complete
      fileTypeList: [
        { code: "2D Echo", name: "2D Echo", type: "radiology" },
        {
          code: "Audiometry",
          name: "Audiometry",
          type: "other",
        },
        {
          code: "Bone Mass Density",
          name: "Bone Mass Density",
          type: "radiology",
        },
        { code: "Chest X-Ray", name: "Chest X-Ray", type: "radiology" },
        {
          code: "COVID-19 Vaccine Certificate",
          name: "COVID-19 Vaccine Certificate",
          type: "other",
        },
        {
          code: "Fundus Image Screening",
          name: "Fundus Image Screening",
          type: "radiology",
        },
        { code: "Health Report", name: "Health Report", type: "lab" },
        { code: "Lab Result", name: "Lab Result", type: "lab" },
        { code: "Liver Elastography Scan", name: "Liver Elastography Scan", type: "radiology" },
        { code: "Mammogram", name: "Mammogram", type: "radiology" },
        {
          code: "Metabolic Syndrome",
          name: "Metabolic Syndrome",
          type: "radiology",
        },
        { code: "Others", name: "Others", type: "other" },
        { code: "Pap Smear", name: "Pap Smear", type: "radiology" },
        { code: "Patient Notes", name: "Patient Notes", type: "other" },
        { code: "Potassium", name: "Potassium", type: "lab" },
        { code: "QUS-Quantatitive Ultrasonography", name: "QUS-Quantatitive Ultrasonography", type: "radiology" },
        { code: "Resting ECG", name: "Resting ECG", type: "radiology" },
        { code: "Spirometry", name: "Spirometry", type: "radiology" },
        { code: "Stool Test", name: "Stool Test", type: "lab" },
        {
          code: "Stress Echocardiogram",
          name: "Stress Echocardiogram",
          type: "radiology",
        },
        { code: "Treadmill", name: "Treadmill", type: "radiology" },
        { code: "Trending Report", name: "Trending Report", type: "lab" },
        {
          code: "Ultrasound Abdomen",
          name: "Ultrasound Abdomen",
          type: "radiology",
        },
        {
          code: "Ultrasound Breast",
          name: "Ultrasound Breast",
          type: "radiology",
        },
        {
          code: "Ultrasound Breast & Mammogram",
          name: "Ultrasound Breast & Mammogram",
          type: "radiology",
        },
        {
          code: "Ultrasound Carotid",
          name: "Ultrasound Carotid",
          type: "radiology",
        },
        { code: "Ultrasound HBS", name: "Ultrasound HBS", type: "radiology" },
        {
          code: "Ultrasound Kidney",
          name: "Ultrasound Kidney",
          type: "radiology",
        },
        { code: "Ultrasound KUB", name: "Ultrasound KUB", type: "radiology" },
        {
          code: "Ultrasound Liver",
          name: "Ultrasound Liver",
          type: "radiology",
        },
        {
          code: "Ultrasound Prostate",
          name: "Ultrasound Prostate",
          type: "radiology",
        },
        {
          code: "Ultrasound Pelvis",
          name: "Ultrasound Pelvis",
          type: "radiology",
        },
        {
          code: "Ultrasound Thyroid",
          name: "Ultrasound Thyroid",
          type: "radiology",
        },
        { code: "Urine and Stool", name: "Urine and Stool", type: "lab" },
        { code: "Urine Test", name: "Urine Test", type: "lab" },
      ],
      fileType: "2D Echo",
      LRList: [6, 9, 12, 18, 24, 30, 36, "Unable to see"],
      nearVisionList: [4.5, 5, 6, 7, 8, 10, 12, 14, 18, 24, 36, 48],
      colourVisionList: [
        "Normal Vision",
        "Red/Green Deficiency",
        "Total Deficiency",
      ],
      carotidLRList: ["Below 50%", "Between 50% to 74.99%", "Above 75%"],
    };
    this.page = 1;
    this.limit = 10;
    this._nameOrAccountID = "";
    this._status = "";
    this._date_from = "";
    this._date_to = "";
    this.transferDateSort = "asc";
  }

  componentDidMount() {
    let self = this;
    this.btnSearch();
    calendarToDate1 = app.calendar.create({
      inputEl: "#from-to-date-calendar1",
      ...self.initCalendarParams(),
    });

    calendarToDate2 = app.calendar.create({
      inputEl: "#from-to-date-calendar2",
      ...self.initCalendarParams(),
    });

    this.$$(".page-content").on("scroll", function() {
      if (this.scrollTop > 20) {
        self.setState({
          showScroolTopBtn: true,
        });
      } else {
        self.setState({
          showScroolTopBtn: false,
        });
      }
    });
  }

  initCalendarParams() {
    let self = this;
    return {
      locale: "en",
      maxDate: new Date(),
      rangePicker: true,
      dayNamesShort: ["S", "M", "T", "W", "T", "F", "S"],
      weekHeader: true,
      firstDay: 7,
      dateFormat: "dd M yyyy",
      rangesClasses: [
        {
          cssClass: "from-to-date-calendar", //string CSS class
          range: function(date) {
            return true;
          },
        },
      ],
      on: {
        calendarChange: function(c, value) {
          if (value.length > 1) {
            self.setState({
              date_from: showDate(value[0], 2),
              date_to: showDate(value[1], 2),
            });
          } else {
            self.setState({
              date_from: showDate(value[0], 2),
              date_to: showDate(value[0], 2),
            });
          }
        },
      },
    };
  }

  getPendingLabReps() {
    app.preloader.show();
    this.setState({
      requestLoading: true,
    });
    AuthGuard.getLabReports(
      {
        nameOrAccountID: this._nameOrAccountID,
        status: this._status,
        date_from: this._date_from,
        date_to: this._date_to,
        limit: this.limit,
        page: this.page,
        transfer_date_sort: this.transferDateSort,
        type: "pending",
      },
      (isOk, res) => {
        app.preloader.hide();
        if (isOk) {
          this.setState((state) => {
            var hash = {};
            let pendingLabReps = [];
            pendingLabReps = state.pendingLabReps.concat(res.result);
            pendingLabReps = pendingLabReps.reduce((item, next) => {
              (hash[next.id] ? "" : (hash[next.id] = true)) && item.push(next);
              return item;
            }, []);
            return {
              pendingLabReps,
              totalCount: res.total ? res.total : null,
              requestLoading: false,
            };
          });
        } else {
          // ShowToast(app, res.message || "Not found!");
          this.setState({
            pendingLabReps: [],
            totalCount: null,
            requestLoading: false,
          });
        }
      }
    );
  }

  getArchiveLabReps() {
    app.preloader.show();
    this.setState({
      requestLoading: true,
    });
    AuthGuard.getLabReports(
      {
        nameOrAccountID: this._nameOrAccountID,
        status: this._status,
        date_from: this._date_from,
        date_to: this._date_to,
        limit: this.limit,
        page: this.page,
        type: "archive",
      },
      (isOk, res) => {
        app.preloader.hide();
        if (isOk) {
          this.setState((state) => {
            var hash = {};
            let archiveLabReps = [];
            archiveLabReps = state.archiveLabReps.concat(res.result);
            archiveLabReps = archiveLabReps.reduce((item, next) => {
              (hash[next.id] ? "" : (hash[next.id] = true)) && item.push(next);
              return item;
            }, []);
            return {
              archiveLabReps,
              totalCount: res.total ? res.total : null,
              requestLoading: false,
            };
          });
        } else {
          // ShowToast(app, res.message || "Not found!");
          this.setState({
            archiveLabReps: [],
            totalCount: null,
            requestLoading: false,
          });
        }
      }
    );
  }

  onPageBeforeRemove() {}

  onPageBeforeOut() {}

  onPageReinit() {}
  scrollTop() {
    this.$$(".page-content").scrollTop(0, 600);
  }

  tabClick(type) {
    // if (type === "archive") {
    //   this.getArchiveLabReps()
    // }else{
    //   this.getPendingLabReps();
    // }
  }

  TabShow(e) {
    this.setState(
      {
        currentTabId: this.$$(e).attr("id"),
      },
      () => {
        this.btnClear();
      }
    );
  }

  onChange = (e) => {
    // console.log(e)
    let { name, value } = e.target;
    this.setState({ [name]: value });
  };

  btnClear(e) {
    this.page = 1;
    console.log(calendarToDate1.getValue());
    calendarToDate1.$inputEl.val("");

    console.log(calendarToDate2.getValue());
    calendarToDate2.$inputEl.val("");
    this._nameOrAccountID = "";
    this._status = "";
    this._date_from = "";
    this._date_to = "";
    this.page = 1;
    this.setState(
      {
        nameOrAccountID: "",
        date_from: "",
        date_to: "",
        status: "",
        pendingLabReps: [],
        archiveLabReps: [],
      },
      () => {
        this.btnSearch();
      }
    );
  }

  btnSearch() {
    const {
      currentTabId,
      date_from,
      date_to,
      nameOrAccountID,
      status,
    } = this.state;

    this._nameOrAccountID = nameOrAccountID;
    this._status = status;
    this._date_from = date_from;
    this._date_to = date_to;
    this.page = 1;

    if (currentTabId === "pendingLabRpTab") {
      this.setState(
        {
          pendingLabReps: [],
        },
        () => {
          this.getPendingLabReps();
        }
      );
    } else {
      this.setState(
        {
          archiveLabReps: [],
        },
        () => {
          this.getArchiveLabReps();
        }
      );
    }
  }

  loadMoreData() {
    const { currentTabId } = this.state;
    this.page = this.page + 1;
    if (currentTabId === "pendingLabRpTab") {
      this.getPendingLabReps();
    } else {
      this.getArchiveLabReps();
    }
  }

  handlePreview(item) {
    let url = item.file_url;
    this.setState(
      {
        iframeUrl: url,
        checkedid: item.id,
        filePw: item.nric,
      },
      () => {
        app.popup.open(".previewLabRpFile-popup");
      }
    );
  }

  handleDownload(item) {
    let url = item.file_url;
    // let url = 'https://omron-dev.s3.ap-southeast-1.amazonaws.com//document/queue/a5fa47fc-a3aa-446c-ab29-d0e3535a8158.pdf';
    let filename = item.report_name;
    app.preloader.show();
    new Downloader({
      url: url,
      filename: filename,
    })
      .then((res) => {
        app.preloader.hide();
        console.log(res);
      })
      .catch((error) => {
        app.preloader.hide();
        console.log(error);
        ShowToast(app, "Network Error!");
      });
  }

  closePreviewLabRpFilePopUp() {
    app.popup.close(".previewLabRpFile-popup");
  }

  UpdateLabRpStatus(status) {
    let findNoAccountFoundForAssigned = false;
    if (status === "assigned") {
      this.state.checkedids.map((pId) => {
        let obj = this.state.pendingLabReps.find((iitem) => iitem.id === pId);
        if (obj.status === "no_account_found") {
          findNoAccountFoundForAssigned = true;
        }
      });
    }

    if (findNoAccountFoundForAssigned) {
      app.dialog.alert(
        "Records with no HeartVoice account found cannot be assigned. Please check the Pending list for these outstanding records.",
        "Error"
      );
      return false;
    }

    if (this.state.checkedids.length < 1) {
      ShowToast(app, "Please select at least one item.");
      return false;
    }
    app.preloader.show();

    if (status === "assigned") {
      this.checkReportsFun({
        ids: JSON.stringify(this.state.checkedids),
        status,
      });
      return false;
    } else {
      this.handleUpdateLabReports({
        ids: JSON.stringify(this.state.checkedids),
        status: status,
      });
    }
  }

  handleUpdateLabReports({ ids, status }) {
    AuthGuard.updateLabReports(
      {
        ids,
        status,
      },
      (isOk, res) => {
        app.preloader.hide();
        if (isOk) {
          this.setState(
            {
              checkedids: [],
              checkedid: "",
              status: status,
            },
            () => {
              this.btnClear();
              // app.tab.show("#archiveLabRpTab", true);
            }
          );
        } else {
          app.dialog.alert(res.message || "Update Error!", "Error");
        }
      }
    );
  }

  handleManuallySent(type, e) {
    console.log(type, e);
    if (type === "all") {
      this.UpdateLabRpStatus("manually_sent");
    } else {
      let id = [];
      id.push(type);
      this.setState(
        {
          checkedids: id,
        },
        () => {
          this.closePreviewLabRpFilePopUp();
          this.UpdateLabRpStatus("manually_sent");
        }
      );
    }
  }

  checkReportsFun({ ids, status }) {
    console.log("checkReportsFun>>>>>>>>>>", { ids, status });
    let self = this;
    AuthGuard.updateLabReportsStatusCheck({ ids, status }, (isOk, res) => {
      console.log(isOk, res);

      if (isOk && res) {
        self.handleUpdateLabReports({ ids, status });
      } else {
        app.preloader.hide();
        app.dialog.confirm(
          `${res.message}` + "&#10;" + " Are you sure you want to continue?",
          function() {
            self.handleUpdateLabReports({ ids, status });
          }
        );
      }
    });
  }

  handleAssign(type, e) {
    if (type === "all") {
      this.UpdateLabRpStatus("assigned");
    } else {
      let id = [];
      id.push(type);
      this.setState(
        {
          checkedids: id,
        },
        () => {
          this.closePreviewLabRpFilePopUp();
          this.UpdateLabRpStatus("assigned");
        }
      );
    }
  }

  handleMerge(type, e) {
    if (type === "all") {
      this.UpdateLabRpStatus("merge");
    } else {
      let id = [];
      id.push(type);
      this.setState(
        {
          checkedids: id,
        },
        () => {
          this.closePreviewLabRpFilePopUp();
          this.UpdateLabRpStatus("merge");
        }
      );
    }
  }

  handleDoNotAssign(type, e) {
    console.log(type, e);
    if (type === "all") {
      this.UpdateLabRpStatus("unassigned");
    } else {
      let id = [];
      id.push(type);
      console.log(id, type);
      this.setState(
        {
          checkedids: id,
        },
        () => {
          this.closePreviewLabRpFilePopUp();
          this.UpdateLabRpStatus("unassigned");
        }
      );
    }
  }

  handleCheckBoxChange(event) {
    const target = event.target;
    const _checked = target.type === "checkbox" ? target.checked : target.value;
    const _id = target.value;
    let _ids = this.state.checkedids;
    if (_checked) {
      if (_id === "all") {
        _ids = [];
        this.state.pendingLabReps.map((item) => {
          _ids.push(item.id);
        });
      } else {
        _ids.push(_id);
      }
      this.setState({
        checkedids: _ids,
      });
    } else {
      if (_id === "all") {
        _ids = [];
      } else {
        _ids.splice(
          _ids.findIndex((item) => item === _id),
          1
        );
      }
      this.setState({
        checkedids: _ids,
      });
    }
  }

  goToPatientDetail(user_profile_id, e) {
    if (user_profile_id) {
      app.views.main.router.navigate(ROUTES.patientInfo, {
        props: { user_profile_id: user_profile_id },
      });
    }
  }
  searchFocus() {
    this.setState({
      showAuto: "block",
    });
  }
  searchBlur() {
    setTimeout(() => {
      if (
        !this.state.searchList.find((x) => x.name == this.state.patientSearch)
      ) {
        this.setState({
          patientSearch: "",
          patientSearchId: "",
        });
      }
      this.setState({
        showAuto: "none",
      });
    }, 300);
  }
  selectAutoItem(val) {
    this.setState(
      {
        patientSearch: val.name,
        patientSearchId: val.patient_id,
        bodyInfo: null,
        appDateInfo: {
          id: "",
        },
      },
      () => {
        AuthGuard.getPatientDateList(
          {
            id: this.state.patientSearchId,
          },
          (isOk, res) => {
            if (isOk) {
              if (res) {
                this.setState({
                  appDateList: res,
                });
              }
            } else {
              this.setState({
                appDateList: [],
              });
            }
          }
        );
      }
    );
  }
  getSearchList = () => {
    if (this.state.beforeSearch != this.state.patientSearch) {
      this.setState(
        {
          beforeSearch: this.state.patientSearch,
        },
        () => {
          AuthGuard.searchPatientList(
            {
              fuzzy_param: this.state.patientSearch,
              access_all_patients_data: AuthGuard.CanViewPatientData()
                ? "1"
                : "0",
              page: 1,
              limit: 999
            },
            (isOk, res) => {
              if (isOk) {
                this.setState({
                  searchList: res,
                  showAuto: "block",
                });
                console.log(res);
              }
            }
          );
        }
      );
    }
  };
  searchChange(e) {
    this.setState({
      patientSearch: e.target.value,
    });
    _.debounce(this.getSearchList, 1000)();
  }
  handleGetBodyInfo = () => {
    AuthGuard.getMeasurementsDetail(
      {
        ...this.state.appDateInfo,
      },
      (isOk, res) => {
        console.log(res);
        for (const key in res) {
          if (key != "bone_mass_density" && key != "framingham_risk_score") {
            for (const key2 in res[key]) {
              if (!res[key][key2]) {
                res[key][key2] = {
                  id: "",
                  value: "",
                };
              }
            }
          }
        }
        this.setState({
          bodyInfo: res,
        });
      }
    );
  };
  appDateChange(e) {
    this.setState(
      {
        appDateInfo: this.state.appDateList.find((x) => x.id == e.target.value),
        bodyInfo: null,
      },
      () => {
        this.handleGetBodyInfo();
      }
    );
  }
  switchCard(val) {
    console.log(val);
    if (this.state.showCard == val) {
      val = "";
    }
    this.setState({
      showCard: val,
    });
  }
  handleBodyInfoChange = (type, e) => {
    console.log(type, e.target.value, e.target.name);
    let { name, value } = e.target;
    let data = _.cloneDeep(this.state.bodyInfo);
    if (name == "systoilc" || name == "diatoilc") {
      e.target.value = value.replace(/[^0-9]/g, "");
    }
    if (name == "height") {
      e.target.value = getValdots(e.target.value, 1);
      // e.target.value = e.target.value.replace(/[^0-9]/g, "") * 1;
      // e.target.value == 0 && (e.target.value = "");
      // if (e.target.value) {
      //   e.target.value = (e.target.value / 10).toFixed(1);
      // }
    }
    if (type == "bone_mass_density" || type == "framingham_risk_score") {
      data[type][name] = value;
    } else {
      if (!data[type][e.target.name]) {
        data[type][e.target.name] = { id: "", value: "" };
      }
      data[type][e.target.name].value = e.target.value;
    }
    this.setState(
      {
        bodyInfo: data,
      },
      () => {
        data = _.cloneDeep(this.state.bodyInfo);
        if (name == "height" || name == "weight") {
          if (!data?.vitals?.height?.value || !data?.vitals?.weight?.value) {
            data.vitals.bmi.value = "";
          } else {
            data.vitals.bmi.value = (
              data.vitals.weight.value /
              ((data.vitals.height.value / 100) *
                (data.vitals.height.value / 100))
            ).toFixed(2);
          }
          this.setState({
            bodyInfo: data,
          });
        }
        if (name == "waist_circumference" || name == "hip") {
          if (
            !data?.vitals?.waist_circumference?.value ||
            !data?.vitals?.hip?.value
          ) {
            data.vitals.waist_hip_ratio.value = "";
          } else {
            data.vitals.waist_hip_ratio.value = (
              data.vitals.waist_circumference.value / data.vitals.hip.value
            ).toFixed(2);
          }
          this.setState({
            bodyInfo: data,
          });
        }
      }
    );
  };
  saveBodyInfo(tab, isGenerate = false) {
    let tabdata = _.cloneDeep(this.state.bodyInfo[tab]);
    if (tab == "vitals") {
      console.log(tabdata);
      if (
        (tabdata?.height?.value &&
          (tabdata?.height?.value < 10 || tabdata?.height?.value > 300)) ||
        (tabdata?.systoilc?.value &&
          (tabdata?.systoilc?.value < 30 || tabdata?.systoilc?.value > 300)) ||
        (tabdata?.diatoilc?.value &&
          (tabdata?.diatoilc?.value < 20 || tabdata?.diatoilc?.value > 200))
      ) {
        return;
      }
    }
    let obj = {
      [tab]: JSON.stringify({ ...tabdata }),
      ...this.state.appDateInfo,
    };
    if (tab == "bone_mass_density") {
      obj.generate_bmd = true;
    }
    if (tab == "carotid") {
      obj.generate_carotid = true;
    }
    if (isGenerate === true) {
      AuthGuard.generateMeasurementsReport(obj, (isOk, res) => {
        if (isOk) {
          this.handleGetBodyInfo();
          ShowToast(app, "Updated sucess.");
        } else {
          ShowToast(app, res);
        }
      });
    } else {
      AuthGuard.saveMeasurementsDetail(obj, (isOk, res) => {
        if (isOk) {
          this.handleGetBodyInfo();
          ShowToast(app, "Updated sucess.");
        } else {
          ShowToast(app, res);
        }
      });
    }
  }

  handleSort(key, type) {
    if (key === "transfer_date_sort") {
      this.transferDateSort = type;
    }

    this.setState(
      {
        pendingLabReps: [],
      },
      () => {
        this.getPendingLabReps();
      }
    );
  }

  renderPendingLabRpTable() {
    return (
      <>
        <Row className="search-panel" style={{ paddingBottom: "0px" }}>
          <Col width="10" className="search-filter">
            Filters:
          </Col>
          <Col width="35">
            <Row>
              <Col className="search-input">
                <input
                  type="text"
                  name="nameOrAccountID"
                  className="nameOrId"
                  placeholder="Name or ID Number"
                  value={this.state.nameOrAccountID}
                  onChange={this.onChange}
                />
                {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                <img src={sousuo} className="img-block" />
              </Col>
            </Row>
          </Col>
          <Col width="35">
            <Row>
              <Col className="formSelect search-input input-dropdown-wrap">
                <select
                  name="status"
                  value={this.state.status}
                  onChange={this.onChange}
                >
                  <option value="">All</option>
                  <option value="pending_assign">Pending Assignment</option>
                  <option value="no_account_found">No Account Found</option>
                </select>
              </Col>
            </Row>
          </Col>
          <Col width="20">
            <Row>
              <Col width="100" medium="50">
                <Button
                  type="button"
                  fill
                  raised
                  className="fill-pink-btn"
                  onClick={this.btnSearch.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="search-panel" style={{ paddingTop: "0px" }}>
          <Col width="10" className="search-filter"></Col>
          <Col width="35">
            <Row>
              <Col className="search-input">
                <input
                  type="text"
                  className="input-with-value input-invalid"
                  placeholder="Search Transfer From Date - To Date"
                  id="from-to-date-calendar1"
                  onChange={(e) => {
                    console.log(e);
                  }}
                />
                <Icon f7="calendar" size="20px" color="gray"></Icon>
              </Col>
            </Row>
          </Col>
          <Col width="35"></Col>
          <Col width="20">
            <Row>
              <Col width="100" medium="50">
                <Button
                  type="button"
                  outline
                  raised
                  className="outline-pink-btn"
                  onClick={this.btnClear.bind(this)}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="data-table">
          <table>
            <thead>
              <tr>
                <th className="label-cell" width="5%">
                  <Checkbox
                    value="all"
                    checked={
                      this.state.pendingLabReps.length > 0 &&
                      this.state.pendingLabReps.length ===
                        this.state.checkedids.length
                    }
                    className="FormCheckBox"
                    onChange={this.handleCheckBoxChange.bind(this)}
                  ></Checkbox>
                </th>
                <th className="label-cell" width="10%">
                  NAME
                </th>
                <th className="label-cell">HV ID</th>
                <th className="label-cell">
                  IDENTIFICATION <br /> NUMBER
                </th>
                <th className="label-cell">DATE OF BIRTH</th>
                <th className="label-cell">FILE NAME</th>
                <th className="label-cell">
                  <span
                    className="arrow_up"
                    onClick={this.handleSort.bind(
                      this,
                      "transfer_date_sort",
                      "asc"
                    )}
                  ></span>
                  <span
                    className="arrow_down"
                    onClick={this.handleSort.bind(
                      this,
                      "transfer_date_sort",
                      "desc"
                    )}
                  ></span>
                  <span className="arrow_title">TRANSFER DATE</span>
                </th>
                <th className="label-cell">STATUS</th>
                <th className="label-cell fwl" width="22%"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.pendingLabReps && this.state.pendingLabReps.length > 0
                ? this.state.pendingLabReps.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td className="label-cell">
                          <Checkbox
                            value={item.id}
                            className="FormCheckBox"
                            checked={this.state.checkedids.includes(item.id)}
                            onChange={this.handleCheckBoxChange.bind(this)}
                          ></Checkbox>
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.name || "-"}
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.accountID || "-"}
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.nric || "-"}
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.dob ? showDate(item.dob, 9) : "-"}
                        </td>
                        {/* <td className="origin-name" onClick={this.goToPatientDetail.bind(this, item.user_profile_id)}>{item.origin_name ?? "-"}<Icon f7="info_circle_fill" className="tooltipIcon" tooltip="124123"></Icon></td> */}
                        <td
                          className="origin-name"
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          <Icon
                            className="tooltipIcon"
                            tooltip={item.report_name ?? "-"}
                          >
                            {item.report_name ?? "-"}
                          </Icon>
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.created_at || "-"}
                        </td>
                        <td
                          className={`lab-rp-${item.status}`}
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {showStatus(item.status)}
                        </td>
                        <td align="center">
                          <Row>
                            <Col>
                              <Button
                                outline
                                raised
                                className="outline-blue-btn"
                                onClick={this.handlePreview.bind(this, item)}
                              >
                                PREVIEW
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                raised
                                className="fill-blue-btn"
                                onClick={this.handleDownload.bind(this, item)}
                              >
                                DOWNLOAD
                              </Button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    );
                  })
                : false}

              {this.state.totalCount > this.state.pendingLabReps.length ? (
                <tr>
                  <td align="center" colSpan="8">
                    <span
                      className="txt-gray"
                      onClick={this.loadMoreData.bind(this)}
                    >
                      {this.state.requestLoading
                        ? "Searching please wait..."
                        : "Click to load more..."}
                    </span>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td align="center" colSpan="8">
                    <span className="txt-gray">
                      {this.state.requestLoading
                        ? "Searching please wait..."
                        : this.state.totalCount !== 0 &&
                          this.state.totalCount ===
                            this.state.pendingLabReps.length
                        ? ""
                        : this.state.noResulstMsg}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
  renderArchiveLabRpTable() {
    return (
      <>
        <Row className="search-panel" style={{ paddingBottom: "0px" }}>
          <Col width="10" className="search-filter">
            Filters:
          </Col>
          <Col width="35">
            <Row>
              <Col className="search-input">
                <input
                  type="text"
                  name="nameOrAccountID"
                  className="nameOrId"
                  placeholder="Name or ID Number"
                  value={this.state.nameOrAccountID}
                  onChange={this.onChange}
                />
                <Icon f7="search" size="20px" color="gray"></Icon>
              </Col>
            </Row>
          </Col>
          <Col width="35">
            <Row>
              <Col className="formSelect search-input input-dropdown-wrap">
                <select
                  name="status"
                  value={this.state.status}
                  onChange={this.onChange}
                >
                  <option value="">All</option>
                  <option value="assigned">Assigned</option>
                  <option value="manually_sent">Manually Sent</option>
                  <option value="unassigned">Unassigned</option>
                </select>
              </Col>
            </Row>
          </Col>
          <Col width="20">
            <Row>
              <Col width="100" medium="50">
                <Button
                  type="button"
                  fill
                  raised
                  className="fill-pink-btn"
                  onClick={this.btnSearch.bind(this)}
                >
                  Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="search-panel" style={{ paddingTop: "0px" }}>
          <Col width="10" className="search-filter"></Col>
          <Col width="35">
            <Row>
              <Col className="search-input">
                <input
                  type="text"
                  className="input-with-value input-invalid"
                  placeholder="Search Archived From Date - To Date"
                  id="from-to-date-calendar2"
                  onChange={(e) => {
                    console.log(e);
                  }}
                />
                <Icon f7="calendar" size="20px" color="gray"></Icon>
              </Col>
            </Row>
          </Col>
          <Col width="35"></Col>
          <Col width="20">
            <Row>
              <Col width="100" medium="50">
                <Button
                  type="button"
                  outline
                  raised
                  className="outline-pink-btn"
                  onClick={this.btnClear.bind(this)}
                >
                  Clear
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="data-table">
          <table>
            <thead>
              <tr>
                <th className="label-cell"> NAME </th>
                <th className="label-cell">HV ID</th>
                <th className="label-cell">
                  IDENTIFICATION <br /> NUMBER
                </th>
                <th className="label-cell">DATE OF BIRTH</th>
                <th className="label-cell">FILE NAME</th>
                <th className="label-cell">TRANSFER DATE</th>
                <th className="label-cell">ARCHIVED DATE</th>
                <th className="label-cell">STATUS</th>
                <th className="label-cell fwl" width="22%"></th>
              </tr>
            </thead>
            <tbody>
              {this.state.archiveLabReps && this.state.archiveLabReps.length > 0
                ? this.state.archiveLabReps.map((item, index) => {
                    return (
                      <tr key={item.id}>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.name || "-"}
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.accountID || "-"}
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.nric || "-"}
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.dob ? showDate(item.dob, 9) : "-"}
                        </td>
                        <td
                          className="origin-name"
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          <Icon
                            className="tooltipIcon"
                            tooltip={item.report_name ?? "-"}
                          >
                            {item.report_name ?? "-"}
                          </Icon>
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.created_at || "-"}
                        </td>
                        <td
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {item.archive_date || "-"}
                        </td>
                        <td
                          className={`lab-rp-${item.status}`}
                          onClick={this.goToPatientDetail.bind(
                            this,
                            item.user_profile_id
                          )}
                        >
                          {showStatus(item.status)}
                        </td>
                        <td align="center">
                          <Row>
                            <Col>
                              <Button
                                outline
                                raised
                                className="outline-blue-btn"
                                onClick={this.handlePreview.bind(this, item)}
                              >
                                PREVIEW
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                raised
                                className="fill-blue-btn"
                                onClick={this.handleDownload.bind(this, item)}
                              >
                                DOWNLOAD
                              </Button>
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    );
                  })
                : false}

              {this.state.totalCount > this.state.archiveLabReps.length ? (
                <tr>
                  <td align="center" colSpan="8">
                    <span
                      className="txt-gray"
                      onClick={this.loadMoreData.bind(this)}
                    >
                      {this.state.requestLoading
                        ? "Searching please wait..."
                        : "Click to load more..."}
                    </span>
                  </td>
                </tr>
              ) : (
                <tr>
                  <td align="center" colSpan="8">
                    <span className="txt-gray">
                      {this.state.requestLoading
                        ? "Searching please wait..."
                        : this.state.totalCount !== 0 &&
                          this.state.totalCount ===
                            this.state.archiveLabReps.length
                        ? ""
                        : this.state.noResulstMsg}
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  uploadSuccess = (type, res) => {
    console.log(type, res);
    let _report_documents = this.state.report_documents;
    _report_documents.push(res);
    this.setState({
      report_documents: _report_documents,
      disableFunction: true,
      report_upload_status: "success",
    });
  };

  delDoc = () => {
    app.dialog.confirm("Are you sure delete it?", () => {
      app.preloader.show();
      this.setState({
        report_documents: [],
        disableFunction: false,
        report_upload_status: "static",
      });
      app.preloader.hide();
    });
  };

  addFile = () => {
    app.preloader.show();
    let _isUpdateFileName = document.querySelector(".isUpdateFileName")
      .childNodes[0].childNodes[0];
    let _isSendDocument = document.querySelector(".isSendDocument")
      .childNodes[0].childNodes[0];
    let isUpdateFileName = "N";
    let isSendDocument = "N";
    if (_isUpdateFileName.checked) {
      isUpdateFileName = "Y";
    }
    if (_isSendDocument.checked) {
      isSendDocument = "Y";
    }
    let _report_documents = this.state.report_documents;
    let _currentName = "";
    let _originalName = "";
    if (_report_documents) {
      _currentName = _report_documents[0].currentName;
      _originalName = _report_documents[0].originalName;
    } else {
      return;
    }

    AuthGuard.reportAddFile(
      {
        currentName: _currentName,
        originalName: _originalName,
        fileType: this.state.fileType,
        report_type:
          this.state.fileTypeList.find((o) => o.code === this.state.fileType)
            ?.type || "",
        isUpdateFileName: isUpdateFileName,
        isSendDocument: isSendDocument,
      },
      (isOk, res) => {
        app.preloader.hide();
        if (isOk) {
          this.setState({
            report_upload_status: "complete",
            report_documents: [],
            report_documents_result: res,
          });
        } else {
          ShowToast(app, res);
        }
      }
    );
  };

  backUploadPage = () => {
    this.setState({
      report_documents: [],
      disableFunction: false,
      report_upload_status: "static",
      report_documents_result: [],
    });
  };

  handleAskPassword = (e) => {
    console.log();
    let pw = this.state.filePw;
    let _pw = pw.substring(pw.length - 4, pw.length);
    _pw && e.verifyPassword(_pw);
  };

  renderFileUploadTable() {
    return (
      <>
        {this.state.report_upload_status == "success" ||
        this.state.report_upload_status == "static" ? (
          <Row style={{ margin: "1em" }}>
            <Col>
              Please upload any health documents for the user here in PDF format
              and name the file according to the user’s NRIC. If you wish to
              upload multiple files, please put them all in a folder and upload
              the ZIP folder instead! Do note that the maximum file size limit
              is 50MB.
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Row style={{ margin: "1em" }}>
          <Col width="70">
            <div className="mt10 inVoiceTable">
              {this.state.report_upload_status == "success" ? (
                <div>
                  {this.state.report_documents.length > 0
                    ? this.state.report_documents.map((item) => {
                        return (
                          <div
                            className="reportUpload"
                            style={{ height: "5em" }}
                          >
                            <div className="float-left">
                              <Icon
                                f7="doc"
                                style={{ color: "black", width: "1.5em" }}
                              ></Icon>
                              <b>{item.originalName}</b>
                            </div>
                            <div className="float-right">
                              <div className="float-right-module">
                                <div className="float-right-module-right">
                                  <Button
                                    outline
                                    raised
                                    className="outline-pink-btn cell_btn"
                                    onClick={this.delDoc.bind(this)}
                                  >
                                    {" "}
                                    Remove{" "}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })
                    : ""}
                  <br />
                  <Row>
                    <Col width="10">
                      <p>File Type:</p>
                    </Col>
                    <Col width="30">
                      <div className="search-input input-dropdown-wrap">
                        <select
                          value={this.state.fileType}
                          onChange={(e) => {
                            this.setState({ fileType: e.target.value });
                          }}
                        >
                          {this.state.fileTypeList.map((item, index) => {
                            return (
                              <option value={item.code} key={item.code}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col width="60" className="reportUpload-checkbox">
                      <List>
                        <ListItem
                          checkbox
                          className="isUpdateFileName"
                          title="Update File Name as File Type upon upload"
                          name="isUpdateFileName"
                        />
                        <ListItem
                          checkbox
                          className="isSendDocument"
                          title="Send document to HeartVoice user upon upload"
                          name="isSendDocument"
                        />
                      </List>
                    </Col>
                  </Row>
                </div>
              ) : this.state.report_upload_status == "complete" ? (
                <div>
                  <Row>
                    <Col width="80">
                      <div className="report-success-border">
                        <p className="report-text">Success</p>
                        <p className="report-number">
                          {this.state.report_documents_result.successTotal}
                        </p>
                      </div>
                      <div className="report-error-border">
                        <p className="report-text">Errors</p>
                        <p className="report-number">
                          {this.state.report_documents_result.errorTotal}
                        </p>
                      </div>
                      <div className="report-total">
                        Total Files Uploaded:
                        {this.state.report_documents_result.total}
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : (
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  border="0"
                  bordercolor="white"
                >
                  <tbody>
                    <tr>
                      <td colSpan="3">
                        <span
                          style={{
                            display:
                              this.state.report_documents.length < 1 &&
                              !this.state.disableFunction
                                ? "block"
                                : "none",
                          }}
                        >
                          <UploadFilesV2
                            maxFilesize="50"
                            maxFiles="1"
                            uploadSuccess={this.uploadSuccess.bind(this)}
                            dropzoneSelector={"report" + Date.now().toString()}
                            acceptedFiles=".pdf, application/pdf,application/zip"
                            // uploadConfig={this.state.uploadConfig}
                          />
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
          </Col>
        </Row>
        {this.state.report_upload_status == "success" ? (
          <Row>
            <Col width="100">
              <Button
                outline
                raised
                className="fill-blue-btn report-upload-button"
                onClick={this.addFile.bind(this)}
              >
                Upload!
              </Button>
            </Col>
          </Row>
        ) : (
          ""
        )}
        {this.state.report_upload_status == "complete" ? (
          <div>
            <Row>
              <Col width="100">
                {this.state.report_documents_result.errorData.length > 0 ? (
                  <table
                    cellPadding="0"
                    cellSpacing="0"
                    style={{ textAlign: "left" }}
                  >
                    <thead>
                      <tr>
                        <th>File Name</th>
                        <th>Type of Error</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.report_documents_result.errorData.map(
                        (item) => {
                          return (
                            <tr key={item.time}>
                              <td>{item.fileName}</td>
                              <td>{item.fileType}</td>
                              <td>{item.discription}</td>
                            </tr>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                ) : (
                  ""
                )}
              </Col>
            </Row>
            <Row>
              <Col width="100">
                <Button
                  outline
                  raised
                  className="outline-blue-btn report-upload-button"
                  style={{ border: "1px solid #22A7BB", width: "15em" }}
                  onClick={this.backUploadPage.bind(this)}
                >
                  Back to File Upload
                </Button>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
  renderGenerateTab() {
    return (
      <>
        <Row className="cell_item justify-content-start align-items-center">
          <Col width="20">Patient : </Col>
          <Col width="80">
            <Col
              className="search-input"
              style={{ width: 400, margin: "unset" }}
            >
              <input
                type="text"
                name="patientSearch"
                className="nameOrId"
                autoComplete="off"
                placeholder="Search Patient by Name or ldentification Number"
                value={this.state.patientSearch}
                onChange={this.searchChange.bind(this)}
                onFocus={this.searchFocus.bind(this)}
                onBlur={this.searchBlur.bind(this)}
              />
            </Col>
            <List
              style={{ display: this.state.showAuto, width: 400 }}
              className="autoComplete"
            >
              {this.state.searchList.map((ite) => {
                if (
                  ite.identification_number
                    .toLowerCase()
                    .includes(this.state.patientSearch.toLowerCase()) ||
                  ite.name
                    .toLowerCase()
                    .includes(this.state.patientSearch.toLowerCase())
                ) {
                  return (
                    <ListItem
                      key={ite.patient_id}
                      onClick={this.selectAutoItem.bind(this, ite)}
                      className="autoList"
                      title={ite.name + " (" + ite.clinic_alias + ")"}
                    />
                  );
                }
              })}
            </List>
          </Col>
        </Row>
        <Row className="cell_item justify-content-start align-items-center">
          <Col width="20">Appointment Date : </Col>
          <Col width="80">
            <Row>
              <Col
                className="formSelect search-input input-dropdown-wrap"
                style={{ width: 407, margin: "unset", height: 36 }}
              >
                <select
                  name="appDateId"
                  value={this.state.appDateInfo?.id}
                  onChange={this.appDateChange.bind(this)}
                >
                  <option value="">Please Select</option>
                  {this.state.appDateList.map((ite) => {
                    return (
                      <option key={ite.id} value={ite.id}>
                        {ite.date}
                      </option>
                    );
                  })}
                </select>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.state.bodyInfo && (
          <>
            <div className="generate-card">
              <Row
                className="generate-header"
                onClick={this.switchCard.bind(this, 1)}
              >
                <Row className="block_title">Vitals</Row>
              </Row>
              {this.state.showCard == 1 && (
                <div className="block_content">
                  <Row className="content_title">Blood Pressure Profile</Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Systolic:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="systoilc"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.systoilc?.value || ""
                          }
                        />
                      </div>
                      {Boolean(
                        this.state.bodyInfo?.vitals?.systoilc?.value &&
                          (this.state.bodyInfo?.vitals?.systoilc?.value < 30 ||
                            this.state.bodyInfo?.vitals?.systoilc?.value > 300)
                      ) && (
                        <div style={{ color: "red" }}>
                          {"30 <= Systolic BP <= 300"}
                        </div>
                      )}
                    </Col>
                    <Col width="25">
                      <div>Diastolic:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="diatoilc"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.diatoilc?.value || ""
                          }
                        />
                      </div>
                      {Boolean(
                        this.state.bodyInfo?.vitals?.diatoilc?.value &&
                          (this.state.bodyInfo?.vitals?.diatoilc?.value < 20 ||
                            this.state.bodyInfo?.vitals?.diatoilc?.value > 200)
                      ) && (
                        <div style={{ color: "red" }}>
                          {"20 <= Diastolic BP <= 200"}
                        </div>
                      )}
                    </Col>
                    <Col width="25">
                      <div>Heart Rate:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="pulse_rate"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.pulse_rate?.value || ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_title">Personal Profile</Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Height(cm):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="height"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.height?.value || ""
                          }
                        />
                      </div>
                      {Boolean(
                        this.state.bodyInfo?.vitals?.height?.value &&
                          this.state.bodyInfo?.vitals?.height?.value > 300
                      ) && (
                        <div style={{ color: "red" }}>max value is 300.</div>
                      )}
                      {Boolean(
                        this.state.bodyInfo?.vitals?.height?.value &&
                          this.state.bodyInfo?.vitals?.height?.value < 10
                      ) && <div style={{ color: "red" }}>min value is 10.</div>}
                    </Col>
                    <Col width="25">
                      <div>Weight(Kg):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="number"
                          name="weight"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.weight?.value || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>BMI:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="bmi"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={this.state.bodyInfo?.vitals?.bmi?.value || ""}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Waist Circumference (cm):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="number"
                          name="waist_circumference"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.waist_circumference
                              ?.value || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Hip Circumference (cm):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="number"
                          name="hip"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={this.state.bodyInfo?.vitals?.hip?.value || ""}
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Waist Hip Ratio:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="waist_hip_ratio"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.waist_hip_ratio
                              ?.value || ""
                          }
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Body Fat Analysis(%):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="body_fat"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.body_fat?.value || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Basal Metabolic Rate:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="basalMetabolic"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.basalMetabolic
                              ?.value || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Visceral Fat:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="visceral_fat"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.visceral_fat?.value ||
                            ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Skeletal Muscle(%):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="skeletal_muscle"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.skeletal_muscle
                              ?.value || ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Visual acuity:</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 144, margin: "unset" }}
                      >
                        <select
                          name="visual_acuity"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.visual_acuity?.value ||
                            ""
                          }
                        >
                          <option value="">Please Select</option>
                          <option value="Aided">Aided</option>
                          <option value="Unaided">Unaided</option>
                        </select>
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Left:</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 144, margin: "unset" }}
                      >
                        <select
                          name="left"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={this.state.bodyInfo?.vitals?.left?.value || ""}
                        >
                          <option value="">Please Select</option>
                          {this.state.LRList.map((ite) => {
                            return (
                              <option key={ite} value={ite}>
                                {ite}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Right:</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 144, margin: "unset" }}
                      >
                        <select
                          name="right"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.right?.value || ""
                          }
                        >
                          <option value="">Please Select</option>
                          {this.state.LRList.map((ite) => {
                            return (
                              <option key={ite} value={ite}>
                                {ite}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Near Vision:</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 144, margin: "unset" }}
                      >
                        <select
                          name="near_vision"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.near_vision?.value ||
                            ""
                          }
                        >
                          <option value="">Please Select</option>
                          {this.state.nearVisionList.map((ite) => {
                            return (
                              <option key={ite} value={ite}>
                                {ite}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Colour Vision:</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 144, margin: "unset" }}
                      >
                        <select
                          name="colour_vision"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.colour_vision?.value ||
                            ""
                          }
                        >
                          <option value="">Please Select</option>
                          {this.state.colourVisionList.map((ite) => {
                            return (
                              <option key={ite} value={ite}>
                                {ite}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Left Tonometry:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="leftTonometry"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.leftTonometry?.value ||
                            ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Right Tonometry:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="rightTonometry"
                          onChange={(e) => {
                            this.handleBodyInfoChange("vitals", e);
                          }}
                          value={
                            this.state.bodyInfo?.vitals?.rightTonometry
                              ?.value || ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="cell_item justify-content-end align-items-center">
                    <Button
                      fill
                      className="generate-button"
                      onClick={this.saveBodyInfo.bind(this, "vitals", true)}
                      style={{ marginRight: 20 }}
                    >
                      Generate & Assign
                    </Button>
                    <Button
                      fill
                      className="generate-button"
                      onClick={this.saveBodyInfo.bind(this, "vitals")}
                    >
                      Save
                    </Button>
                  </Row>
                </div>
              )}
            </div>

            <div className="generate-card">
              <Row
                className="generate-header"
                onClick={this.switchCard.bind(this, 2)}
              >
                <Row className="block_title">Bone Mass Density</Row>
              </Row>
              {this.state.showCard == 2 && (
                <div className="block_content">
                  <Row className="content_body">
                    <Col width="25">
                      <div>Foot Supporter:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="footSupporter"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density
                              ?.footSupporter || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Site:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="site"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.site || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>BQI:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="bqi"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.bqi || ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>T-Score:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="tScore"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.tScore || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>T-Ratio:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="tRatio"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.tRatio || ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>Z-Score:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="zScore"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.zScore || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>Z-Ratio:</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="zRatio"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.zRatio || ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="25">
                      <div>SOS(m/s):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="sos"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.sos || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25">
                      <div>BUA(db/MHz):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="text"
                          name="bua"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.bua || ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="75">
                      <div>Comments:</div>
                      <div>
                        <Input
                          className="content_fill_input"
                          fill
                          outline
                          type="text"
                          name="comments"
                          onChange={(e) => {
                            this.handleBodyInfoChange("bone_mass_density", e);
                          }}
                          value={
                            this.state.bodyInfo?.bone_mass_density?.comments ||
                            ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="25" className="flex-end">
                      <Button
                        style={{ width: 100 }}
                        className="generate-button"
                        onClick={this.saveBodyInfo.bind(
                          this,
                          "bone_mass_density"
                        )}
                      >
                        Generate
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </div>

            <div className="generate-card">
              <Row
                className="generate-header"
                onClick={this.switchCard.bind(this, 3)}
              >
                <Row className="block_title">Carotid</Row>
              </Row>
              {this.state.showCard == 3 && (
                <div className="block_content">
                  <Row className="content_body">
                    <Col width="33">
                      <div>Carotid Right Mean IMT:</div>
                      <div>
                        <Input
                          className="content_long_input"
                          outline
                          type="text"
                          name="carotidRightMeanImt"
                          onChange={(e) => {
                            this.handleBodyInfoChange("carotid", e);
                          }}
                          value={
                            this.state.bodyInfo?.carotid?.carotidRightMeanImt
                              ?.value || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="33">
                      <div>Carotid Right Mean IMT Percentile:</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 194, margin: "unset" }}
                      >
                        <select
                          name="carotidRightMeanImtPercentile"
                          onChange={(e) => {
                            this.handleBodyInfoChange("carotid", e);
                          }}
                          value={
                            this.state.bodyInfo?.carotid
                              ?.carotidRightMeanImtPercentile?.value || ""
                          }
                        >
                          <option value="">Please Select</option>
                          {this.state.carotidLRList.map((ite) => {
                            return (
                              <option key={ite} value={ite}>
                                {ite}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col width="33">
                      <div>Carotid Right(Plague):</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 194, margin: "unset" }}
                      >
                        <select
                          name="carotidRightPlaque"
                          onChange={(e) => {
                            this.handleBodyInfoChange("carotid", e);
                          }}
                          value={
                            this.state.bodyInfo?.carotid?.carotidRightPlaque
                              ?.value || ""
                          }
                        >
                          <option value="">Please Select</option>
                          <option value="Absent">Absent</option>
                          <option value="Present">Present</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body">
                    <Col width="33">
                      <div>Carotid Left Mean IMT:</div>
                      <div>
                        <Input
                          className="content_long_input"
                          outline
                          type="text"
                          name="carotidLeftMeanImt"
                          onChange={(e) => {
                            this.handleBodyInfoChange("carotid", e);
                          }}
                          value={
                            this.state.bodyInfo?.carotid?.carotidLeftMeanImt
                              ?.value || ""
                          }
                        />
                      </div>
                    </Col>
                    <Col width="33">
                      <div>Carotid Left Mean IMT Percentile:</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 194, margin: "unset" }}
                      >
                        <select
                          name="carotidLeftMeanImtPercentile"
                          onChange={(e) => {
                            this.handleBodyInfoChange("carotid", e);
                          }}
                          value={
                            this.state.bodyInfo?.carotid
                              ?.carotidLeftMeanImtPercentile?.value || ""
                          }
                        >
                          <option value="">Please Select</option>
                          {this.state.carotidLRList.map((ite) => {
                            return (
                              <option key={ite} value={ite}>
                                {ite}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </Col>
                    <Col width="33">
                      <div>Carotid Left(Plague):</div>
                      <div
                        className="formSelect search-input input-dropdown-wrap"
                        style={{ width: 194, margin: "unset" }}
                      >
                        <select
                          name="carotidLeftPlaque"
                          onChange={(e) => {
                            this.handleBodyInfoChange("carotid", e);
                          }}
                          value={
                            this.state.bodyInfo?.carotid?.carotidLeftPlaque
                              ?.value || ""
                          }
                        >
                          <option value="">Please Select</option>
                          <option value="Absent">Absent</option>
                          <option value="Present">Present</option>
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body justify-content-end">
                    <Button
                      style={{ width: 100 }}
                      className="generate-button"
                      onClick={this.saveBodyInfo.bind(this, "carotid")}
                    >
                      Generate
                    </Button>
                  </Row>
                </div>
              )}
            </div>

            <div className="generate-card">
              <Row
                className="generate-header"
                onClick={this.switchCard.bind(this, 4)}
              >
                <Row className="block_title">Framingham Risk Score</Row>
              </Row>
              {this.state.showCard == 4 && (
                <div className="block_content">
                  <Row className="content_body">
                    <Col width="33">
                      <div>Risk(%):</div>
                      <div>
                        <Input
                          className="content_input"
                          outline
                          type="number"
                          name="risk"
                          onChange={(e) => {
                            this.handleBodyInfoChange(
                              "framingham_risk_score",
                              e
                            );
                          }}
                          value={
                            this.state.bodyInfo?.framingham_risk_score?.risk ||
                            ""
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="content_body justify-content-end">
                    <Button
                      style={{ width: 50 }}
                      className="generate-button"
                      onClick={this.saveBodyInfo.bind(
                        this,
                        "framingham_risk_score"
                      )}
                    >
                      Save
                    </Button>
                  </Row>
                </div>
              )}
            </div>
          </>
        )}
      </>
    );
  }
  render() {
    return (
      <FramePage
        name={ROUTES.labReports}
        onPageBeforeOut={this.onPageBeforeOut.bind(this)}
        onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}
        onPageReinit={this.onPageReinit}
      >
        <Fab
          className="scrolltotop"
          style={{ display: this.state.showScroolTopBtn ? "block" : "none" }}
          position="right-bottom"
          onClick={this.scrollTop.bind(this)}
        >
          <Icon
            ios="f7:arrow_up"
            aurora="f7:arrow_up"
            md="material:arrow_upward"
          ></Icon>
        </Fab>
        <div className="navBar"></div>
        <Row className="navTitleBar">
          <Col> Lab Reports </Col>
        </Row>
        <Row className="viewContent">
          <Col>
            <div className="cellTabbar tabbar">
              <Link
                tabLink="#pendingLabRpTab"
                className="badgeTab"
                tabLinkActive
                onClick={this.tabClick.bind(this, "pending")}
              >
                Pending
              </Link>
              <Link
                tabLink="#archiveLabRpTab"
                className="badgeTab"
                onClick={this.tabClick.bind(this, "archive")}
              >
                Archive
              </Link>
              <Link
                tabLink="#fileUploadTab"
                className="badgeTab"
                onClick={this.tabClick.bind(this, "File Upload")}
              >
                File Upload
              </Link>
              <Link
                tabLink="#generateTab"
                className="badgeTab"
                onClick={this.tabClick.bind(this, "generate")}
              >
                Generate Report
              </Link>
            </div>
            <Tabs animated>
              <Tab
                id="pendingLabRpTab"
                className=""
                tabActive
                onTabShow={this.TabShow.bind(this)}
              >
                {this.renderPendingLabRpTable()}
              </Tab>
              <Tab
                id="archiveLabRpTab"
                className=""
                onTabShow={this.TabShow.bind(this)}
              >
                {this.renderArchiveLabRpTable()}
              </Tab>
              <Tab
                id="fileUploadTab"
                className=""
                onTabShow={this.TabShow.bind(this)}
              >
                {this.renderFileUploadTable()}
              </Tab>
              <Tab
                id="generateTab"
                className=""
                onTabShow={this.TabShow.bind(this)}
              >
                {this.renderGenerateTab()}
              </Tab>
            </Tabs>
          </Col>
        </Row>
        {this.state.currentTabId === "pendingLabRpTab" ? (
          <Row>
            <Col>
              <div className="rp-footer">
                <Row>
                  <Col width="50">
                    <Row>
                      <Col>
                        <Button
                          raised
                          className="fill-pink-btn"
                          onClick={this.handleDoNotAssign.bind(this, "all")}
                          disabled={this.state.checkedids.length < 1}
                        >
                          DO NOT ASSIGN
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          raised
                          className="fill-blue-btn"
                          onClick={this.handleManuallySent.bind(this, "all")}
                          disabled={this.state.checkedids.length < 1}
                        >
                          MANUALLY SENT
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          raised
                          className="fill-blue-btn"
                          onClick={this.handleAssign.bind(this, "all")}
                          disabled={this.state.checkedids.length < 1}
                        >
                          ASSIGN
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          raised
                          className="fill-blue-btn"
                          onClick={this.handleMerge.bind(this, "all", "merge")}
                          disabled={this.state.checkedids.length < 2}
                        >
                          REGENERATE
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : (
          ""
        )}
        <Popup
          className="previewLabRpFile-popup"
          tabletFullscreen
          swipeToClose
          swipeHandler=".swipe-handler"
        >
          <Page>
            <div className="previewLabRpFile-header">
              <Link
                onClick={this.closePreviewLabRpFilePopUp.bind(this)}
                iconOnly
              >
                <Icon
                  ios="f7:multiply_circle_fill"
                  aurora="f7:multiply_circle_fill"
                  md="material:multiply_circle_fill"
                  className="iconXmark"
                ></Icon>
              </Link>
            </div>
            <Row className="iframeBox">
              <Col>
                {this.state.iframeUrl && (
                  <PDFViewer
                    iframeUrl={this.state.iframeUrl}
                    handleAskPassword={this.handleAskPassword.bind(this)}
                  />
                )}
              </Col>
            </Row>
            <div
              className="previewLabRpFile-footer"
              style={{
                display:
                  this.state.currentTabId === "pendingLabRpTab"
                    ? "block"
                    : "none",
              }}
            >
              <Row>
                <Col>
                  <Button
                    disabled={this.state.loadingCancelBtn}
                    raised
                    onClick={this.handleManuallySent.bind(
                      this,
                      this.state.checkedid
                    )}
                    className="fill-blue-btn"
                  >
                    MANUALLY SENT
                  </Button>
                </Col>
                <Col>
                  <Button
                    raised
                    className="fill-blue-btn"
                    onClick={this.handleAssign.bind(this, this.state.checkedid)}
                  >
                    ASSIGN
                  </Button>
                </Col>
                <Col>
                  <Button
                    raised
                    className="fill-pink-btn"
                    onClick={this.handleDoNotAssign.bind(
                      this,
                      this.state.checkedid
                    )}
                  >
                    DO NOT ASSIGN
                  </Button>
                </Col>
              </Row>
            </div>
          </Page>
        </Popup>
      </FramePage>
    );
  }
}

export default LabReports;
