import React from 'react';
import Video from 'twilio-video';
import {Container, Link, Typography, Paper, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {isAndroid, isIOS, isMacOs, isWindows, isSafari, isIPad13} from "react-device-detect";
import ChromeLogo from '../../../../images/browser/chrome.svg';
import FireFoxLogo from '../../../../images/browser/firefox.png';
import EdgeLogo from '../../../../images/browser/edge.jpeg';
import SafariLogo from '../../../../images/browser/safari.jpeg';


/**
 https://www.twilio.com/docs/video/javascript#supported-browsers
          Chrome  Firefox  Safari  Edge (Chromium)
 Android    ✓        ✓        -        -
 iOS        -        -        ✓        -
 Linux      ✓        ✓        -        -
 macOS      ✓        ✓        ✓        ✓
 Windows    ✓        ✓        -        ✓
 */
export default function ({children}: { children: React.ReactElement }) {

  if (!Video.isSupported || ((isIOS || isIPad13) && !isSafari)) {
    let chrome = {name: 'Chrome', icon: ChromeLogo, link: 'https://www.google.com/chrome'}
    let firefox = {name: 'Firefox', icon: FireFoxLogo, link: 'https://www.mozilla.org/en-US/firefox/'}
    let safari = {name: 'Safari', icon: SafariLogo, link: 'https://www.apple.com/hk/en/safari/'}
    let edge = {name: 'Edge', icon: EdgeLogo, link: 'https://www.microsoft.com/en-us/edge'}
    let supported = {
      android: [chrome, firefox],
      ios: [safari],
      linux: [chrome],
      macos: [chrome],
      windows: [chrome],
    }

    let supports = supported.linux;
    if (isWindows) {
      supports = supported.windows
    } else if (isMacOs) {
      supports = supported.macos
    } else if (isIOS || isIPad13) {
      supports = supported.ios
    } else if (isAndroid) {
      supports = supported.android
    }
    return (
      <div className="twilio-unsupported">
        <div>
            <p>
              Sorry, your browser is incompatible with our service.
            </p>
            <p>
              Please open in the following browsers:
            </p>
            <div className="browsers">
              {
                supports.map(i => <div>
                  <img src={i.icon} onClick={() => window.location.href = i.link} />
                  <span>{i.name}</span>
                </div>)
              }
            </div>
        </div>
      </div>
    );
  }

  return children;
}
