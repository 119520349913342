import React, { Component } from 'react';
import { f7 as app, Card, CardContent, Button, Icon, Row, Col, Link, Popover, List, ListItem, Input } from 'framework7-react';
import { WeekEveryDay } from '../commons/Constants';
import { ShowToast, checkValidTimeSlots, insertStr, CompareTime, convertTimeSlots, convertTimeStamp } from '../commons/Helpers';
import green from '../images/icons/green.png';
import grey from '../images/icons/grey.png';

class TimeSlots extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onTimeSlotsShiftsChange: false,
            availableTimeSlotsShifts: props.availableTimeSlotsShifts,
            checkAvailableTimeSlotsShifts: props.checkAvailableTimeSlotsShifts,
            timeSlotsShifts: props.timeSlotsShifts,
            selectedWeeks: [],
            timeslot_start: '',
            timeslot_end: '',
            duration:props.duration
        }
    }

    delShift(day, index) {
        let self = this;
        let _timeSlotsShifts = JSON.parse(JSON.stringify(this.state.timeSlotsShifts));
        app.dialog.confirm('Are you sure you want to delete it?', function () {
            _timeSlotsShifts = _timeSlotsShifts.map((item, i) => {
                if (item.day === day) {
                    item.data.splice(index, 1)
                }
                return item
            })

            _timeSlotsShifts = _timeSlotsShifts.filter(item => item.data.length > 0)

            self.updateTimeSlot(_timeSlotsShifts);
        });
    }

    updateTimeSlot(_timeSlotsShifts) {
        this.setState({
            timeSlotsShifts: _timeSlotsShifts
        }, () => {
            this.props.setTimeSlots(_timeSlotsShifts)
        })
    }

    CompareTimeSlots(timeSlotsArr, newTimeSlots) {
        let { start, end } = newTimeSlots
        let noError = true
        if (timeSlotsArr.length < 1) return false

        timeSlotsArr.forEach((item, index) => {
            if (convertTimeStamp(start) >= convertTimeStamp(item.start) && convertTimeStamp(start) <= convertTimeStamp(item.end)) {
                noError = false;
                return false;
            }
            if (convertTimeStamp(end) >= convertTimeStamp(item.start) && convertTimeStamp(end) <= convertTimeStamp(item.end)) {
                noError = false;
                return false;
            }

            if (convertTimeStamp(item.start) >= convertTimeStamp(start) && convertTimeStamp(item.start) <= convertTimeStamp(end)) {
                noError = false;
                return false;
            }
            if (convertTimeStamp(item.end) >= convertTimeStamp(start) && convertTimeStamp(item.end) <= convertTimeStamp(end)) {
                noError = false;
                return false;
            }
        })
        return noError
    }

    isTimeSlotAvalible(day, newTimeSlot) {
        if (!this.state.availableTimeSlotsShifts) {
            return false;
        }

        let timeSlotsArr = this.state.availableTimeSlotsShifts.find(i => i.day == day);
        if (!timeSlotsArr) {
            return false;
        }
        timeSlotsArr = timeSlotsArr.data;

        let { start, end } = newTimeSlot
        let noError = false

        timeSlotsArr.forEach((item, index) => {
            if (convertTimeStamp(start) >= convertTimeStamp(item.start) && convertTimeStamp(end) <= convertTimeStamp(item.end)) {
                noError = true;
                return true;
            }
        })
        return noError
    }

    addTimeSlots() {
        let { timeslot_start, timeslot_end, selectedWeeks, timeSlotsShifts, availableTimeSlotsShifts } = this.state;
        if (selectedWeeks.length < 1) {
            ShowToast(app, 'Oops! Please select a week at least.');
            return false;
        }
        if (availableTimeSlotsShifts && this.state.checkAvailableTimeSlotsShifts && !availableTimeSlotsShifts.length) {
            ShowToast(app, 'Oops! No time slot available.');
            return false;
        }

        if (!timeslot_start || !timeslot_end) {
            ShowToast(app, 'Please input a valid time slot for Start Time / End Time');
            return false;
        }

        if (!CompareTime(timeslot_start, timeslot_end)) {
            ShowToast(app, 'Oops! Start Time must < End Time');
            return false;
        }

        let _timeSlotsShifts = JSON.parse(JSON.stringify(timeSlotsShifts))
        let slot = {
            start: timeslot_start,
            end: timeslot_end,
            max_patients_per_slot:'',
            lead_time:'',
            duration:this.state.duration
        };

        selectedWeeks.map((item, index) => {
            if (availableTimeSlotsShifts && this.state.checkAvailableTimeSlotsShifts && !this.isTimeSlotAvalible(item, slot)) {
                ShowToast(app, 'This timeslot is not within clinic opening hours for teleconsultation.');
                return;
            }

            if (!_timeSlotsShifts.find(i => i.day == item)) {
                _timeSlotsShifts.push({
                    day: item,
                    data: [slot]
                })
                return;
            }
            _timeSlotsShifts.map((iitem, iindex) => {
                if (iitem.day !== item) {
                    return;
                }
                if (!this.CompareTimeSlots(iitem.data, slot)) {
                    return;
                }

                iitem.data.push(slot)
                return iitem
            })
        })

        this.updateTimeSlot(_timeSlotsShifts)
    }

    onChange = e => {
        let { name, value } = e.target;
        if (value.length && !value.match(/^([0-1]?[0-9]?|2[0-3]?):?[0-5]?[0-9]?$/g)) {
            ShowToast(app, String(value) + ' is not a valid time');
            value = value.substr(0, value.length - 1)
        }
        this.setState({ [name]: value });
    }

    onCheckboxChange = e => {
        let { name, value, checked } = e.target;
        var _selectedWeeks = this.state.selectedWeeks;
        if (checked) {
            _selectedWeeks.push(parseInt(value) + 1)

        } else {
            _selectedWeeks.splice(_selectedWeeks.indexOf(parseInt(value) + 1), 1);
        }

        let _selectedWeeksName = _selectedWeeks.map((item, index) => {
            return WeekEveryDay[item - 1];
        })

        this.setState({
            selectedWeeks: _selectedWeeks,
            selectedWeeksName: _selectedWeeksName.join(', ')
        })

        console.log(this.state.selectedWeeks)

        let multipleSelectSelect = document.querySelector('.multipleSelect-select')
        if (this.state.selectedWeeks.length >= 4) {
            multipleSelectSelect.style.height = 'auto'
        }

    }

    timeSlotsBlur(e) {
        let { name, value } = e.target;
        let getError = false;
        let field_name = 'Start Time'
        if (name === 'timeslot_end') {
            field_name = 'End Time'
        }
        if (value.trim()) {
            if (!isNaN(parseInt(value)) && value.indexOf(":") === -1) {
                if ([...value].length === 3) {
                    value = insertStr(value, 1, ':')
                } else if ([...value].length === 4) {
                    value = insertStr(value, 2, ':')
                }
            }

            let _timeSlot = checkValidTimeSlots(value.trim())
            if (_timeSlot) {
                this.setState({ [name]: value });
            } else {
                this.setState({ [name]: '' });
                ShowToast(app, 'Please input a valid time slot for ' + field_name);
            }
        }
    }

    renderTimeSlots() {
        return (
            <Row className="WeekTimeSlots">
                <Col width="45">
                    Start:
                    <Input
                        type="text"
                        placeholder="9:00"
                        name="timeslot_start"
                        value={this.state.timeslot_start}
                        onBlur={this.timeSlotsBlur.bind(this)}
                        onChange={this.onChange}
                    />
                </Col>
                <Col width="10" className="dashline">—</Col>
                <Col width="45">
                    End:
                    <Input
                        type="text"
                        placeholder="9:00"
                        name="timeslot_end"
                        value={this.state.timeslot_end}
                        onChange={this.onChange}
                        onBlur={this.timeSlotsBlur.bind(this)}
                    />
                </Col>
            </Row>
        )
    }

    renderTimeSlotsBlock(data, day, index) {
        return (
            <div className="SlotsBlock">
                <div className="SlotsTime"> {data.start} - {data.end} </div>
                <div className="canClick" onClick={this.delShift.bind(this, day, index)}>
                    <Icon f7="multiply_circle_fill" className="iconXmark" ></Icon>
                   
                </div>
            </div>
        )
    }

    renderTabelTrTimeSlots = () => {
        let { timeSlotsShifts } = this.state
        return (
            <tr>
                {WeekEveryDay.map((day, index) => {
                    return (
                        <td key={`slotsBlock-${index}`}>
                            {timeSlotsShifts.length > 0 ? timeSlotsShifts.map((item, iindex) => {
                                if (item.day === index + 1 && item.data.length > 0) {
                                    return (
                                        <React.Fragment key={`ttt-${iindex}`}>
                                            {item.data.map((data, iiindex) => {
                                                return (
                                                    <React.Fragment key={`SlotsBlock-${iiindex}`}>
                                                        {this.renderTimeSlotsBlock(data, item.day, iiindex)}
                                                    </React.Fragment>
                                                )
                                            })}
                                        </React.Fragment>
                                    )
                                } else {
                                    return ''
                                }
                            }) : null}
                        </td>
                    )
                })}
            </tr>
        )
    }

    render() {
        return (
            <>
                <Row className="rowAddTimeSlots">
                    <Col width="45">
                        <div className="multipleSelect">
                            <div className="multipleSelect-title">Day(s):</div>
                            <Link className="multipleSelect-select" popoverOpen=".popover-select">
                                <span>{this.state.selectedWeeksName ? this.state.selectedWeeksName : 'select day(s)'} </span>
                                <Icon f7="arrowtriangle_down_fill"></Icon>
                              
                            </Link>
                        </div>
                        <Popover className="popover-select">
                            <List className="FormCheckBox">
                                {WeekEveryDay.map((item, index) => {
                                    return <ListItem onChange={this.onCheckboxChange} key={`list-${index}`} checkbox title={item} value={index} name="select-checkbox"></ListItem>
                                })}
                            </List>
                        </Popover>
                    </Col>
                    <Col width="35">
                        {this.renderTimeSlots()}
                    </Col>
                    <Col width="20">
                        <Button onClick={this.addTimeSlots.bind(this)} className="addShiftBtn fill-blue-btn">Add Shift</Button>
                    </Col>
                </Row>
                <br />
                <Row className="timeSlotsTableRow">
                    <Col>
                        <table className="timeSlotsTable">
                            <thead>
                                <tr>
                                    {WeekEveryDay.map((day, index) => {
                                        return (
                                            <th key={`weekSt${index}`} className="checkboxTable" align="center">
                                                <div className={`checkboxBtn`}>
                                                    <span>{day}</span>
                                                </div>
                                            </th>
                                        )
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderTabelTrTimeSlots()}
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </>
        )
    }
}

export default TimeSlots;