// react redux const
export const FETCH_LOGIN = 'FETCH_LOGIN';
export const FETCH_OTPLOGIN = 'FETCH_OTPLOGIN'
export const FETCH_SESSIONLIST = 'FETCH_SESSIONLIST';
export const FETCH_REDEEMSESSION = 'FETCH_REDEEMSESSION';
export const FETCH_LOGOUT = 'FETCH_LOGOUT';
export const FETCH_PATIENTLIST = 'FETCH_PATIENTLIST';
export const FETCH_CHATLIST = 'FETCH_CHATLIST';
export const FETCH_PATIENTDETAIL = 'FETCH_PATIENTDETAIL';
export const FETCH_CREATEDIALOG = 'FETCH_CREATEDIALOG';
export const FETCH_GETMESSAGELIST = 'FETCH_GETMESSAGELIST';
export const FETCH_UPDATEUSERGOAL = 'FETCH_UPDATEUSERGOAL';


