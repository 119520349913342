import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server'
import { ApiUrl, RespondCode, XAPP } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard'
import { ShowToast } from '../commons/Helpers'
import { f7 as app, Icon, Button, Progressbar } from 'framework7-react';
import { CLIENT_RENEG_LIMIT } from 'tls';
import ic_warning from '../images/icons/ic_warning.png';


var dropzone = '';
class UploadFilesV2 extends Component {
    constructor(props) {
        super(props);
        // console.log(props);
        this.state = {
            onLoading: false,
            uploadStatus: 'static',
            progress: 0,
            errorMsg: '',
            file_name: '',
            is_cancel: false
        }

        this.handleAddedfile = this.handleAddedfile.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        // this.callbackDropArray = [() => console.log('Hi!'), () => console.log('Ho!')];
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.uploadprogress = this.uploadprogress.bind(this);
        this.handleComplete = this.handleComplete.bind(this);
        this.handleCanceled = this.handleCanceled.bind(this);
        this.handleSending = this.handleSending.bind(this);

        this.componentConfig = {
            iconFiletypes: ['.jpg', '.png', '.gif'],
            showFiletypeIcon: true,
            postUrl: ApiUrl.reportUploadFiles,
            dropzoneSelector: 'div.' + props.dropzoneSelector,
        };
        this.djsConfig = {
            autoProcessQueue: true,
            addRemoveLinks: true,
            maxFiles: props.maxFiles,
            maxFilesize: 50,
            // acceptedFiles: "image/jpeg,image/png,image/gif,image/bmp,application/pdf",
            acceptedFiles: props.acceptedFiles,
            headers: {
                "X-authorization": AuthGuard.isAuthenticated,
                "X-app": XAPP
            },
            paramName: "files",
            timeout: 120 * 1000, //x 秒
            previewsContainer: '.dPBox',
            dictFileTooBig: 'The specified file cannot be uploaded as it has exceed the maximum file size limit.',
            dictInvalidInputType: 'The specified file cannot be uploaded. Only PDF format is allowed.',
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview dz-file-preview">
                    <div className="dz-details">
                        <div className="dz-filename"><span data-dz-name="true"></span></div>
                        <img data-dz-thumbnail="true" />
                    </div>
                    <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
                    <div className="dz-success-mark"><span>✔</span></div>
                    <div className="dz-error-mark"><span>✘</span></div>
                    <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
                </div>
            ),
        }
        this.eventHandlers = {
            init: dz => dropzone = dz,
            addedfile: this.handleAddedfile,
            removedfile: this.handleRemove,
            // drop: this.callbackDropArray,            
            uploadprogress: this.uploadprogress,
            canceled: this.handleCanceled,
            sending: this.handleSending,
            success: this.handleSuccess,
            error: this.handleError,
            complete: this.handleComplete,
        }
    }


    componentDidMount() { }

    componentWillReceiveProps(props) {
        let { dropzoneSelector } = props;
    }

    componentDidUpdate() {
        // console.log('componentDidUpdate');
    }

    handleAddedfile = async (file) => {
        const {
            onFileUploaded, fileType = 'contract', maxFiles, maxFilesize
        } = this.props;
        this.setState({
            file_name: file.name,
            cur_files: file,
            is_cancel: false,
        })
        // 限制文件大小
        if (file.size > 1024 * 1014 * parseInt(maxFilesize)) {
            this.setState({
                errorMsg: 'The specified file cannot be uploaded as it has exceed the maximum file size limit.'
            })
            return;
        }
        // 到达最大个数后不执行上传
        if (file.name.indexOf('&') !== -1 || file.name.indexOf('/') !== -1 || file.name.indexOf('\\') !== -1) {
            ShowToast(app, 'File name cannot contain special characters "&"/""\\"');
            return;
        }
        if (file.contentFiles && file.contentFiles >= maxFiles) {
            ShowToast(app, 'Reached the maximum number of uploaded files');
            return;
        }
    }

    handleRemove(file) {
        console.log('file has been removed')
    }

    callbackDropArray(file) {
        console.log('callbackDropArray')
    }

    handleSuccess(file, apiBackRes) {
        console.log('handleSuccess')
        if(this.state.uploadStatus == 'static'){
            return;
        }
        if (apiBackRes.status_code === RespondCode.reportUploadFilesSuccess) {
            let { message, result } = apiBackRes;
            this.setState({
                progress: 100,
                uploadStatus: 'uploaded',
                file_name: result.originalName,
                is_cancel: false,
            })
            setTimeout(() => {
                this.props.uploadSuccess(this.props.dropzoneSelector, result)
            },2000)
        } else {
            let { message, result } = apiBackRes;
            this.setState({
                progress: 100,
                uploadStatus: 'uploaded',
                file_name: result.originalName,
                is_cancel: false,
            })
            setTimeout(() => {
                let errMsg = 'Upload File';
                if (message !== undefined && message && message !== '') {
                    errMsg = message
                } else {
                    errMsg = errMsg;
                }
                this.setState({
                    progress: 0,
                    uploadStatus: 'fail',
                    errorMsg: errMsg,
                    file_name: result.originalName,
                    is_cancel: false,
                })
            },2000)
        }
    }

    handleError(file, errorMessage) {
        console.log('handleError')
        let errMsg = 'Upload File';
        if (errorMessage !== undefined && errorMessage && errorMessage !== '') {
            if(errorMessage == "You can't upload files of this type."){
                errMsg = 'The specified file cannot be uploaded. Only PDF format is allowed.'
            }else{
                errMsg = errorMessage;
            }
        }
        this.setState({
            progress: 0,
            uploadStatus: 'fail',
            errorMsg: errMsg,
            file_name: file.name,
            is_cancel: false,
        })
    }

    uploadprogress(file, progress, bytesSent) {
        if(this.state.is_cancel){
            return;
        }
        if (this.state.uploadStatus == 'start') {
            this.setState({
                progress: progress,
            })
        } else {
            if(this.state.uploadStatus == 'static' && this.state.progress > 0) return;
            this.setState({
                progress: progress,
                uploadStatus: 'start'
            })
        }
    }

    handleComplete(file, msg) {
        console.log(msg);
        dropzone.removeFile(file);
        // this.dropzone.removeAllFiles();
        app.dialog.close();
    }

    handleCanceled(file, msg) {
        console.log(file);
        console.log(msg);
    }

    handleSending(file, xhr, formData) {
        xhr.ontimeout = function (e) {
            console.log("Argh Upload timed out")
            app.dialog.close();
            ShowToast(app, "Argh Upload timed out!", 'center', 3000);
        };
    }

    uploadFile = () => {
        this.$$("." + this.props.dropzoneSelector).click();
    }

    cancelFile = () => {
        this.setState({
            uploadStatus: 'static',
            progress: 0,
            errorMsg: '',
            is_cancel: true,
        })
    }

    retryFile = () => {
        const {maxFilesize} = this.props;
        if (this.state.cur_files && this.state.cur_files.size <= 1024 * 1014 * parseInt(maxFilesize) && ~".pdf, application/pdf,application/zip".indexOf(this.state.cur_files.type) ) {
            this.setState({
                progress: 0,
                errorMsg: '',
                is_cancel: false,
                // file_name: ''
            }, () => {
                dropzone.uploadFile(this.state.cur_files)
            })
        }
    }

    render() {
        return (
            <div className="">
                <div style={{ display: this.state.uploadStatus == 'static' ? 'block' : 'none' }}>
                    <div className="dPBox" style={{ display: 'none' }}>
                        <div className="dropzone-previews"></div>
                    </div>
                    <div className={` uploadFileBlock ${this.props.dropzoneSelector}`}>
                        <Icon f7="cloud_upload" className="iconArrow"></Icon>
                        <div>
                            Drag & drop your files here OR Browse from your computer
                    </div>
                        <div className="ml20">
                            <Button onClick={this.uploadFile} className="outline-blue-btn">here</Button>
                        </div>
                    </div>

                    <DropzoneComponent
                        config={this.componentConfig}
                        eventHandlers={this.eventHandlers}
                        djsConfig={this.djsConfig}
                    />
                </div>
                <div style={{ display: this.state.uploadStatus == 'start' ? 'block' : 'none' }}>
                    <div className="reportUpload">
                        <div className="float-left"><Icon f7="doc" style={{ color: 'black',width:'1.5em' }}></Icon><b>{this.state.file_name}</b></div>
                        <div className="float-right">
                            <div className="float-right-module">
                                <div className="float-right-module-right"><Button outline raised className="outline-pink-btn cell_btn" onClick={this.cancelFile.bind(this)}> cancel </Button></div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <p style={{ margin: '1em' }}><Progressbar progress={this.state.progress} id="demo-inline-progressbar" color="#22A7BB"></Progressbar></p>
                    </div>
                </div>
                <div style={{ display: this.state.uploadStatus == 'uploaded' ? 'block' : 'none' }}>
                    <div className="reportUpload">
                        <div className="float-left"><Icon f7="doc" style={{ color: 'black',width:'1.5em' }}></Icon><b>{this.state.file_name}</b></div>
                        <div className="float-right">
                            <div className="float-right-module">
                                <div className="float-right-module-right" style={{color:'gray',marginTop:'0.5em',fontWeight:'bolder'}}>Uploaded!</div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <p style={{ margin: '1em' }}><Progressbar progress={this.state.progress} id="demo-inline-progressbar" color="#22A7BB"></Progressbar></p>
                    </div>
                </div>
                <div style={{ display: this.state.uploadStatus == 'fail' ? 'block' : 'none' }}>
                    <div className="reportUpload">
                        <div className="float-left" style={{ color: 'red',display:'flex' }}><img src={ic_warning} style={{width:'1.9em',height:'1.9em'}}/><Icon f7="doc" style={{ color: 'red',width:'1.5em' }}></Icon><b style={{marginTop:'0.5em'}}>{this.state.file_name}</b></div>
                        <div className="float-right">
                            <div className="float-right-module">
                                <div className="float-right-module-right" style={{ display: 'inline-flex' }}>
                                    <div style={{ display: 'inline', margin: '0.5em', color: 'gray', fontWeight: 'bolder' }}>Upload Failed</div>
                                    <Button outline raised className="outline-blue-btn cell_btn" style={{ display: 'inline', width: '10em', marginRight: '1em' }} onClick={this.retryFile.bind(this)}> Retry </Button>
                                    <Button outline raised className="outline-pink-btn cell_btn" style={{ display: 'inline' }} onClick={this.cancelFile.bind(this)}> cancel </Button>
                                </div>
                            </div>
                        </div>
                        <br />
                        <br />
                        <p style={{ margin: '1em' }}><Progressbar progress={this.state.progress} id="demo-inline-progressbar"></Progressbar></p>
                    </div>
                    <p style={{ color: 'red' }}>{this.state.errorMsg ? this.state.errorMsg : ''}</p>
                </div>
            </div>
        );
    }
}

export default UploadFilesV2;