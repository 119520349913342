import React, { Component } from 'react';
import { f7 as app, View, Page, Link, Row, Col, Button, List, ListItem, Block, Icon } from 'framework7-react';
import SideBar from '../components/SideBar'
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { InputBlur, VerificationFields, ShowToast, setCache, justEmail, formatEmail, currencyType, getCache } from '../commons/Helpers';

const reminderOptions = [
    {value: 0, label: "At time of"},
    {value: 5, label: "5 min before"},
    {value: 10, label: "10 min before"},
    {value: 15, label: "15 min before"},
    {value: 30, label: "30 min before"},
    {value: 60, label: "1 hour before"},
    {value: 24 * 60, label: "1 day before"},
]
const notifiedOptions = [
    {name: "remindAppointmentCreate", label: "A new appointment is created"},
    {name: "remindAppointmentUpdate", label: "An appointment time is updated"},
    {name: "remindAppointmentDelete", label: "An appointment is cancelled or removed"},
]

class GoogleConnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reminder_value: 15,
            syncFinished: false,
            notifiedValues: {
                remindAppointmentCreate: 1,
                remindAppointmentUpdate: 1,
                remindAppointmentDelete: 1,
            },
            calendar_account: 'Loading...',
            calendar_names: ['Loading...'],
        }
    }

    componentDidMount() {
        this.getGoogleConnectInfo()
        this.getCalendarConfig()
        this.getCoachInfo()
    }

    getCalendarConfig() {
        app.preloader.show()
        AuthGuard.calendarSyncInfo({}, (ok, res) => {
            app.preloader.hide()
            if (typeof res === 'undefined') {
                this.setState({
                    syncFinished: true
                })
                return
            }
            if (typeof res.remind_before != 'undefined') {
                this.state.reminder_value = parseInt(res.remind_before)
            }
            if (typeof res.remind_appointment_create != 'undefined') {
                this.state.notifiedValues.remindAppointmentCreate = parseInt(res.remind_appointment_create)
            }
            if (typeof res.remind_appointment_update != 'undefined') {
                this.state.notifiedValues.remindAppointmentUpdate = parseInt(res.remind_appointment_update)
            }
            if (typeof res.remind_appointment_delete != 'undefined') {
                this.state.notifiedValues.remindAppointmentDelete = parseInt(res.remind_appointment_delete)
            }
            this.setState({
                remind_before: this.state.remind_before,
                notifiedValues: this.state.notifiedValues,
            }, () => {
                this.setState({
                    syncFinished: true,
                })
            })
        })
    }

    getGoogleConnectInfo() {
        AuthGuard.googleConnectInfo({}, (ok, res) => {
            if (ok) {
                this.setState({
                    calendar_account: res.email
                })
            } else {
                app.dialog.alert('Connect to google failed')
                window.history.pushState({}, null, window.location.origin + '/#/');
                app.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'appointmentTab' } }) 
            }
        })
    }
    
    getCoachInfo() {
        const clinic_info_id = AuthGuard.getCoachProfile().clinic_info_id;
        AuthGuard.findClinicInfoByClinicid({
            clinic_info_id: clinic_info_id
        }, (ok, res) => {
            if (ok) {
                this.setState({
                    calendar_names: res.doctor_list.map(i => 'HeartVoice - Dr. '.concat(i.doctorinfo.name))
                })
            }
        })
    }

    backToSetting () {
        window.history.pushState({}, null, window.location.origin + '/#/');
        app.views.main.router.navigate(ROUTES.setting, { props: {activeTabType: 'appointmentTab'} })
    }

    onReminderChange(e) {
        this.setState({
            reminder_value: e.target.value
        })
    }
    
    onConfirm() {
        var formData = app.form.convertToData('#update-calendar');
        notifiedOptions.map(i => {
            let name = i.name
            formData[name] = formData[name].length
        })

        app.preloader.show()
        AuthGuard.calendarSyncOn(formData, (ok, res) => {
            app.preloader.hide()
            if (!ok) {
                ShowToast(app, 'create google calender failed.')
                return
            } 

            this.backToSetting()
        })
    }

    render() {
        return (
            <FramePage name={ROUTES.googleConnect} >
                <div className="navBar">
                    <Link animate={false} color='gray' onClick={this.backToSetting}> Settings </Link> &gt; <span>Sync to Google Calendar</span>
                </div>
                <Row className="navTitleBar">
                    <Col> Sync to Google Calendar </Col>
                </Row>

                <Row>
                    <Col>
                        <div className="footerBtn">
                            <Button className="navbarBtn outline-blue-btn" onClick={this.backToSetting.bind(this)}>Cancel</Button>
                            <Button className="navbarBtn fill-blue-btn" disabled={!this.state.syncFinished} onClick={this.onConfirm.bind(this)} raised fill>Confirm</Button>
                        </div>
                    </Col>
                </Row>

                <div className="gray-block">
                    <Row className="block_title">
                        <Col>Calendar</Col>
                        <p>These calendars will be created for your clinic</p>
                    </Row>
                    <p className="txt-red">{this.state.calendar_account}</p>
                    {this.state.calendar_names.map(i => <p>{i}</p>)}
                </div>

                <form className="gray-block" id="update-calendar">
                    <Row className="block_title">
                        <Col>Set up notification preference</Col>
                    </Row>
                    <p style={{lineHeight: "2.5em",display: "flex", justifyContent: "flex-start", gap: "6px"}}>
                        Get reminders
                        <span className="search-input input-dropdown-wrap" style={{margin: 0}}>
                            <select
                                value={this.state.reminder_value}
                                onChange={this.onReminderChange.bind(this)}
                                name="remindBefore"
                            >
                                {reminderOptions.map((item, index) => {
                                    return <option value={item.value} key={item.value}>{item.label}</option>
                                })}
                            </select>
                        </span>
                        an event/appointment
                    </p>
                    <Row>
                        <Col width="25" className="formLabel">
                            Get notification when
                        </Col>
                        <Col width="75">
                            <List
                                noHairlines
                                noHairlinesBetween
                                className="FormCheckBox gray"
                            >
                                {this.state.syncFinished && notifiedOptions.map(i => {
                                    return <ListItem
                                    checkbox key={i.value} title={i.label} name={i.name} defaultChecked={this.state.notifiedValues[i.name]}></ListItem>
                                })}
                            </List>
                        </Col>
                    </Row>
                </form>
            </FramePage>
        );
    }

}

export default GoogleConnect;