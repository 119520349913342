import React, { Component } from 'react'
import * as Constants from '../commons/Constants';
import moment from 'moment';
import { f7 as app, Badge } from 'framework7-react';
import { ROUTES } from '../commons/Constants'
import chat from '../images/icons/chat.png';

class PatientItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {

    }

    computeAvgBPBgColor() {
        let patient = this.props.patient;
        let tempS = 0;
        let tempD = 0;
        let colorArray = [
            Constants.BG_WHITE,
            Constants.BG_YELLOW,
            Constants.BG_ORANGE,
            Constants.BG_RED,

        ];

        if (patient.avg_sys_5days >= 160) {
            tempS = 3;
        } else if (patient.avg_sys_5days >= 140) {
            tempS = 2;
        } else if (patient.avg_sys_5days >= 130) {
            tempS = 1;
        } else {
            tempS = 0;
        }


        if (patient.avg_dia_5days >= 100) {
            tempD = 3;
        } else if (patient.avg_dia_5days >= 90) {
            tempD = 2;
        } else if (patient.avg_dia_5days >= 80) {
            tempD = 1;
        } else {
            tempD = 0;
        }

        return tempS >= tempD ? colorArray[tempS] : colorArray[tempD];
    }

    computeHBPBgColor(value) {
        if (value >= 80) {
            return Constants.BG_RED;
        } else if (value >= 60) {
            return Constants.BG_ORANGE;
        } else if (value >= 40) {
            return Constants.BG_YELLOW;
        } else {
            return Constants.BG_WHITE;
        }
    }

    computeIHBBgColor(value) {
        value = Math.round(value);
        if (value >= 80) {
            return Constants.BG_RED;
        } else if (value >= 60) {
            return Constants.BG_ORANGE;
        } else if (value >= 40) {
            return Constants.BG_YELLOW;
        } else {
            return Constants.BG_WHITE;
        }
    }
    computeADBgColor(value) {

        if (value <= 0 || value === null || value === '' || value === undefined) {
            return Constants.BG_WHITE;
        }
        value = Math.round(value);
        if (value > 0 && value <= 20) {
            return Constants.BG_RED;
        } else if (value <= 40) {
            return Constants.BG_ORANGE;
        } else if (value <= 60) {
            return Constants.BG_YELLOW;
        } else {
            return Constants.BG_WHITE;
        }
    }

    computeSIEBgColor(value) {
        value = Math.round(value);
        if (value >= 60) {
            return Constants.BG_RED;
        } else if (value >= 40) {
            return Constants.BG_ORANGE;
        } else if (value >= 20) {
            return Constants.BG_YELLOW;
        } else {
            return Constants.BG_WHITE;
        }
    }

    goPatientDetail(user_profile_id, e) {
        app.views.main.router.navigate(ROUTES.patientdetail, {
            props: { userId: user_profile_id}
        });
    }

    render() {

        // console.log(this.props.patient)

        let patient = this.props.patient;
        let status = patient.sortingPoints.ranking.label;

        let avgBpBgColor = this.computeAvgBPBgColor();
        let HBPRes = 0;
        if (patient.total_no_of_readings !== '' && patient.total_no_of_readings !== null && patient.total_no_of_readings !== undefined) {
            HBPRes = Math.round(patient.threshold_crossed_no / patient.total_no_of_readings * 10000) / 100.00;
        }

        let HighBpBgColor = this.computeHBPBgColor(HBPRes);
        let IHBBgColor = this.computeIHBBgColor(patient.ihb);
        let AdhereBgColor = this.computeADBgColor(patient.adherence);
        let SideEffBgColor = this.computeSIEBgColor(patient.side_effect);

        let item_status = 'status ';
        if (status === Constants.INDEX_HIGH) {
            item_status += 'hight';
        } else if (status === Constants.INDEX_MID_HIGH) {
            item_status += 'middle_h';
        } else if (status === Constants.INDEX_MEDIUM) {
            item_status += 'middle';
        } else if (status === Constants.INDEX_LOW) {
            item_status += 'low';
        } else if (status === Constants.INDEX_NA) {
            item_status += 'blank';
        }

        let birth = moment(patient.birth, 'YYYYMMDD');
        let age = birth.isValid() ? moment().diff(birth, 'years') : '-';

        let avg_bp_day;
        if (patient.avg_sys_5days === 0 && patient.avg_dia_5days === 0) {
            avg_bp_day = '- / -';
        } else if (patient.avg_sys_5days === null && patient.avg_dia_5days !== null) {

            avg_bp_day = '- /' + Math.round(patient.avg_dia_5days);
        } else if (patient.avg_sys_5days !== null && patient.avg_dia_5days === null) {
            avg_bp_day = Math.round(patient.avg_sys_5days) + '/ -';
        } else if (patient.avg_sys_5days === null && patient.avg_dia_5days === null) {
            avg_bp_day = '- / -';
        } else {
            avg_bp_day = Math.round(patient.avg_sys_5days) + '/ ' + Math.round(patient.avg_dia_5days);
        }

        let _avg_pulse_5days = '-';
        if (patient.avg_pulse_5days !== 0 && patient.avg_pulse_5days !== null) {
            _avg_pulse_5days = Math.round(patient.avg_pulse_5days);
        }


        let gender = "-";
        if (patient.gender && typeof patient.gender == 'string') {
            if (patient.gender[0].toUpperCase() === 'F') {
                gender = 'F';
            }
            if (patient.gender[0].toUpperCase() === 'M') {
                gender = 'M';
            }
        };

        return (
            <tr onClick={this.goPatientDetail.bind(this, patient.user_profile_id)} className="canClick">
                {this.props.patientFilterHeader.find(item => item.code === 'risk').checked ?
                    <td className={` label-cell ${item_status}`}></td>
                    : null}

                {this.props.patientFilterHeader.find(item => item.code === 'mon-id').checked ?
                    <td className="medium-only item-no"> {patient.accountID} </td>
                    : null}
                {this.props.patientFilterHeader.find(item => item.code === 'mon-name').checked ?
                    <td className="medium-only"> {patient.name}  </td>
                    : null}
                {this.props.patientFilterHeader.find(item => item.code === 'sex').checked ?
                    <td className="medium-only gender"> {gender}  </td> : null}
                {this.props.patientFilterHeader.find(item => item.code === 'age').checked ?
                    <td className="numeric-cell age"> {age}  </td> : null}
                {this.props.patientFilterHeader.find(item => item.code === 'bmi').checked ?
                    <td className="medium-only bmi">
                        {patient.avg_bmi_5days
                            ? patient.avg_bmi_5days
                            : '-'}
                        <span className="table-cell-desc goal">
                            {patient.avg_weight_5days
                                ? patient.avg_weight_5days
                                : '-'}
                        </span>
                    </td> : null}
                {this.props.patientFilterHeader.find(item => item.code === 'bp').checked ?
                    <td className={`medium-only avg-bp ${avgBpBgColor}`}>
                        {avg_bp_day}
                        <span className="table-cell-desc goal">
                            {patient.sys_goal && patient.dia_goal
                                ? patient.sys_goal + '/' + patient.dia_goal
                                : '- / -'}
                        </span>
                    </td> : null}
                {this.props.patientFilterHeader.find(item => item.code === 'pr').checked ?
                    <td className={`medium-only avg-pr`}>
                        {_avg_pulse_5days}
                    </td> : null}
                {this.props.patientFilterHeader.find(item => item.code === 'ihb').checked ?
                    <td className={`medium-only ihb ${IHBBgColor}`}>
                        <span className="item-ihb">
                            <span className={patient.ihb !== null && patient.ihb !== 0 ? 'ihb' : 'no_ihb'} />
                        </span>
                        <span className="txt-num">{patient.ihb !== null && patient.ihb !== 0 ? Math.round(patient.ihb) : '-'}</span>
                    </td> : null}
                {this.props.patientFilterHeader.find(item => item.code === 'spO2').checked ?
                    <td className={`medium-only avg-spo2`}>
                        {patient.avg_spo2
                            ? patient.avg_spo2
                            : '-'}

                        <span className="table-cell-desc goal">
                            {patient.avg_temperature
                                ? patient.avg_temperature
                                : '-'}</span>
                    </td> : null}
                {this.props.patientFilterHeader.find(item => item.code === 'glucose').checked ?
                    <td className={`medium-only avg_blood-glucose`}>
                        {patient.avg_blood_glucose
                            ? patient.avg_blood_glucose
                            : '-'}

                        {/* <span className="table-cell-desc goal">
                        {patient.limit
                            ? patient.limit
                            : '-'}</span> */}
                    </td> : null}
                {/* <td className="medium-only"><span className="oneLine"> {patient.program_name} </span> <span className="table-cell-desc">{patient.program_status ? patient.program_status : '--' }  </span></td> */}
                {this.props.patientFilterHeader.find(item => item.code === 'chat').checked ?
                    <td className="medium-only">
                        {/* <div style={{ display: patient.unread_messages_count ? 'block': 'none' }} className="badgeNo">•••</div> */}
                        {patient.unread_messages_count ? <Badge className="badgeNo">{patient.unread_messages_count}</Badge> : (patient.dialog && patient.dialog.length > 0 ? <img src={chat} style={{width:'1.5em'}}/> : '')}

                    </td> : null}
            </tr>
        );
    }
}

export default PatientItem;