import React, { Component } from 'react';
import { f7 as app, Row, Col, Icon, ListInput, Link, BlockTitle, BlockFooter, Badge, Popover, View, f7ready, Block, Button, List, Page, Input, Preloader } from 'framework7-react';
import { connect } from 'react-redux'
import { GetPatientDetail, updateUserGoal } from '../redux/actions'
import SideBar from '../components/SideBar'
import { showTime, showTimeReverse, getLocalTime } from '../commons/Helpers.js'
import {
    ShowToast,
    showAge,
    showGender,
    InputBlur,
    InputFoucs,
    GetValue,
    GetArrNum,
    GetArrMiddleNum,
    isInvalid,
} from "../commons/Helpers";
import ChatPopUp from '../components/ChatPopUp'
import Graph from '../components/Graph'
import AuthGuard from '../services/AuthGuard'
import { FETCH_UPDATEUSERGOAL } from '../redux/actionTypes';
import { ROUTES, ApiUrl } from '../commons/Constants';
import FramePage from '../components/FramePage';
import ReschedulePopUp from '../components/ReschedulePopUp'
import Modal from '../components/Modal';
import { Ring } from 'echarts/lib/util/graphic';
import deleteIcon from '../images/icons/delete.png';
import {CREDENTIALS} from '../commons/Helpers'
const QB = window.QB;
QB.init(CREDENTIALS.appId, CREDENTIALS.authKey, CREDENTIALS.authSecret, { debug: { mode: 1}});

class PatientDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            program_ids: [''],
            programOptions: [{ label: 'Please select', value: '' }],
            programJoined: [],
            programPending: [],
            patientName: '',
            patientAge: 0,
            patientGender: 'male',
            patientHeight: '',
            patientNote: '-',
            patientSysGoal: '',
            patientDiaGoal: '',
            popupOpened: false,
            oldDialogList: [],
            dialogList: [],
            chat_userId: '',
            userAvatar: false,
            showPicker: 'none',
            updatedGoal: false,
            showHealthReview: false,
            unread_messages_count: '',
            drList: [],
            patientSysGoalForm: '',
            patientSysGoalTo: '',
            patientDiaGoalForm: '',
            patientDiaGoalTo: '',
            patientWeightFrom: '',
            patientWeightTo: '',
            patientSteps: '',
            patientSleep: '',
            patientSPO2From: '',
            patientSPO2To: '',
            patientGlucoseBeforeMealFrom: '',
            patientGlucoseBeforeMealTo: '',
            patientGlucoseAfterMealFrom: '',
            patientGlucoseAfterMealTo: '',
            bloodPressureHighUpperLimit: '',
            bloodPressureHighLowerLimit: '',
            bloodPressureLowUpperLimit: '',
            bloodPressureLowLowerLimit: '',
            weightUpperLimit: '',
            weightLowerLimit: '',
            addDatabloodPressureHight: '',
            addDatabloodPressureLow: '',
            addDataWeight: '',
            addDataSpo2: '',
            addDataSteps: '',
            addDataSleep: '',
            addDataGlucose: '',
            addDataPulse: '',
            bloodGlucoseBeforeMeal: '',
            bloodGlucoseAfterMeal: '',
            glocoseFlag: 1,
            glocoseTwoFlag: 1,
            limitHpError: false,
            limitWeightError: false,
            addDataSleepStart: '',
            addDataSleepEnd: '',
            addDataSleepStartTime: 'pm',
            addDataSleepEndTime: 'am',
            savingAddDataBtn: false,
            savingSetLimitBtn: false,
            savingSetGoalBtn: false,
            addTemperature: '',
            unChatLoad: false,
            is_open: true,
            is_loading_end: false,
            isFrame: false,
        }
        this.coachProfile = AuthGuard.getCoachProfile();
        this.MIN_SYS = 90;
        this.MAX_SYS = 139;
        this.MIN_DIA = 60;
        this.MAX_DIA = 89;
        this.MIN_Weight = 1;
        this.MAX_Weight = 550;
        this.arrSysGoal = GetArrNum(this.MIN_SYS, this.MAX_SYS);
        this.arrDiaGoal = GetArrNum(this.MIN_DIA, this.MAX_DIA);
    }

    componentWillMount() {
        this.setState({
            unread_messages_count: 0
        })
    }


    componentDidMount() {
        let self = this;
        this.$f7ready((f7) => {
            // if (this.props.patientDetail) {
                // this.setPatientState(this.props);
            // }

            setTimeout(() => {
                self.loadAvaliablePrograms()
                self.getPatientDetail();
                self.getDrList();
                // AuthGuard.initDrE3kit();
                // self.getGraph();
            }, 1000);

        });

        this.findProgramByUserIdAndProgramType()

        // console.log(this.props)
        this.getUserGoalInfo()

        this.getUserLimitInfo()
        if(window.self !== window.top){
            this.setState({
                isFrame: true
            })
        }
    }

    componentWillUnmount(){
        this.destroyedQB();
        this.$$('.chat-pop-close').click();
    }

    onDisconnectedListener = ()=>{
        console.log("onDisconnectedListener")
    }

    destroyedQB() {
        QB.chat.disconnect();
        QB.chat.onDisconnectedListener = this.onDisconnectedListener;
    }

    initChatInfo = () =>{
        // app.dialog.preloader('We are retrieving the chat, please wait.');
        this.destroyedQB();
        let self = this;
        if(self.state.oldDialogList && self.state.oldDialogList.length > 0){
            AuthGuard.getQBLoinPw({},(isOk , res)=>{
                if (isOk){
                    this.setState({
                        chat_drId: res.chat_user_id
                    })
                    setTimeout(() => {
                        var user = {
                            id: res.chat_user_id,
                            login: res.login_name,
                            pass: res.password
                        };
                        this.$f7ready((f7) => {
                            QB.createSession({ login: user.login, password: user.pass }, async function (err, res) {
                                if (res) {
                                    await QB.chat.connect({ userId: user.id, password: user.pass }, async function (err, roster) {
                                        if (err) {
                                            console.log("onConnected-Err");
                                            console.log(err);
                                            app.dialog.close();
                                        } else {
                                            console.log("onConnected");
                                            console.log(roster);
                                            // AuthGuard.ChatDeviceInfo = [];
                                            AuthGuard.allDrInfo = null;
                                            for(let diglog = 0 ; diglog < self.state.oldDialogList.length ;diglog++){
                                                let newData = self.state.oldDialogList;
                                                let dialogJid = QB.chat.helpers.getRoomJidFromDialogId(newData[diglog]._id);
                                                await QB.chat.muc.join(dialogJid, function(error, result) {});
                                                // await AuthGuard.initE3kitStart(newData[diglog]);
                                            }
                                            self.setState({
                                                dialogList: self.state.oldDialogList,
                                            },()=>{
                                                self.setState({
                                                    // popupOpened: true,
                                                    is_open: false,
                                                })
                                                app.dialog.close();
                                                if(self.state.is_loading_end){
                                                    self.setState({
                                                        popupOpened: true,
                                                    })
                                                }
                                            });
                                        }
                                    });
                                    
                                } else {
                                    app.dialog.close();
                                    console.log(err);
                                }
                            });
                        });
                    }, 200);
                }
            })
        }
    }

    componentWillReceiveProps(props) {
        if (props.patientDetail !== '' && props.patientDetail !== null) {
            this.setPatientState(props);
        }
    }

    findProgramByUserIdAndProgramType() {

        AuthGuard.findProgramByUserIdAndProgramType({
            user_profile_id: this.props.userId
        }, (ok, res) => {
            if (ok) {
                let programJoined = this.state.programJoined;
                let programPending = this.state.programPending;
                res.buy_program_array.map(i => programJoined.unshift({ label: i.program_name, value: i.program_id, service_access_id: i.service_access_id, created_at: getLocalTime(i.created_at) }))
                res.invitation_array.map(i => programPending.unshift({ label: i.program_name, value: i.program_id, service_access_id: i.service_access_id }))
                this.setState({
                    programJoined: programJoined,
                    programPending: programPending,
                }, () => {
                    // console.log(programJoined, this.state.programJoined)
                })
            }
        })
    }

    getProfileImage() {
        AuthGuard.getProfileImage({ userProfileId: this.props.userId }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    userAvatar: res.url.s
                });
            }
        });
    }

    setPatientState(props) {
        console.log('detail',props.patientDetail)
        let new_dialog = [];
        // if(props.patientDetail && props.patientDetail.dialog &&  props.patientDetail.dialog.length > 0){
        //     props.patientDetail.dialog.map((item)=>{
        //         let dialog_temp = item;
        //         if(dialog_temp.program_version){
        //             dialog_temp.program_id = dialog_temp.program_id + dialog_temp.program_version;
        //         }
        //         new_dialog.push(dialog_temp);
        //     });
        // }
        this.setState({
            patientName: props.patientDetail.name,
            patientAge: showAge(props.patientDetail.birth),
            patientGender: props.patientDetail.gender,
            unread_messages_count: props.patientDetail.dialog ? props.patientDetail.unread_messages_count : 0,
            // patientGender: showGender(props.patientDetail.gender,'full'),
            patientHeight: props.patientDetail.height ? props.patientDetail.height : '',
            patientNote: props.patientDetail.notes,
            oldDialogList: new_dialog,
            accountID: props.patientDetail.accountID ? props.patientDetail.accountID : '',
            // patientSysGoal: props.patientDetail.sys_goal ? props.patientDetail.sys_goal : '',
            // patientDiaGoal: props.patientDetail.dia_goal ? props.patientDetail.dia_goal : '',
        },()=>{
            this.setState({
                unChatLoad: true
            })
            // this.initChatInfo();
        });
    }

    getUserGoalInfo() {
        let self = this;
        AuthGuard.getGoalInfo({
            userProfileId: this.props.userId,
        }, (isOk, res) => {
            if (isOk) {
                self.setState({
                    // patientSysGoalForm: res.bp_high,
                    // patientDiaGoalTo: res.bp_low,
                    patientSysGoal: res.bp_high,
                    patientDiaGoal: res.bp_low,
                    // patientDiaGoalForm: res.bp_low,
                    // patientDiaGoalTo: res.bp_low_to,
                    patientWeightFrom: res.weight,
                    patientWeightTo: res.weight_to,
                    // patientSteps: res.step,
                    // patientSleep: res.sleep,
                    patientSPO2From: res.spo2,
                    patientSPO2To: res.spo2_to,
                    patientGlucoseBeforeMealFrom: res.glucose,
                    patientGlucoseBeforeMealTo: res.glucose_to,
                    patientGlucoseAfterMealFrom: res.glucose_after_meal,
                    patientGlucoseAfterMealTo: res.glucose_after_meal_to
                });

            }
        });
    }

    getUserLimitInfo() {
        let self = this;
        AuthGuard.getLimitInfo({
            userProfileId: this.props.userId,
        }, (isOk, res) => {
            // console.log(res)
            if (isOk) {
                self.setState({
                    bloodPressureHighUpperLimit: res.bp_high_ul,
                    bloodPressureHighLowerLimit: res.bp_high_ll,
                    bloodPressureLowUpperLimit: res.bp_low_ul,
                    bloodPressureLowLowerLimit: res.bp_low_ll,
                    weightUpperLimit: res.weight_ul,
                    weightLowerLimit: res.weight_ll
                });

            }
        });
    }


    componentDidUpdate() {
        // app.preloader.hide();
        this.popoverClosed();
    }

    getPatientDetail() {
        // app.preloader.show();
        this.props.GetPatientDetail(
            this.props.userId
        );
        // this.getProfileImage();
    }

    getDrList() {
        let { clinic_info_id } = this.coachProfile;
        AuthGuard.getDrlist({
            clinic_id: clinic_info_id
        }, (isok, res) => {
            if (isok) {
                this.setState({
                    drList: res
                })
            }
        })


    }

    btnEdit() {
        app.popover.open('.popover-menu');
    }

    addDataDetailInfo() {
        let self = this;
        AuthGuard.addDataInfo({
            userProfileId: this.props.userId,
            timezone: 'Asia/Singapore',
            bloodPressureHight: this.state.addDatabloodPressureHight,
            bloodPressureLow: this.state.addDatabloodPressureLow,
            weight: this.state.addDataWeight,
            spo2: this.state.addDataSpo2,
            step: this.state.addDataSteps,
            bloodGlucose: this.state.glocoseFlag == 1 && this.state.glocoseTwoFlag == 1 ? this.state.addDataGlucose : '',
            bloodGlucoseBeforeMeal: this.state.glocoseFlag == 0 ? this.state.addDataGlucose : '',
            bloodGlucoseAfterMeal: this.state.glocoseTwoFlag == 0 ? this.state.addDataGlucose : '',
            pulse: this.state.addDataPulse,
            sleepStart: this.state.addDataSleepStart ? this.state.addDataSleepStart : '',
            sleepEnd: this.state.addDataSleepEnd ? this.state.addDataSleepEnd : '',
            temperature: this.state.addTemperature ? this.state.addTemperature : '',
        }, (isOk, res) => {
            app.preloader.hide();
            this.setState({
                savingAddDataBtn: false
            })
            console.log(isOk, res)
            if (isOk) {
                self.setState({
                    addDatabloodPressureHight: '',
                    addDatabloodPressureLow: '',
                    addDataWeight: '',
                    addDataSteps: '',
                    addDataSleep: '',
                    addDataSpo2: '',
                    addDataGlucose: '',
                    addDataPulse: '',
                    addDataSleepStart: '',
                    addDataSleepEnd: '',
                    glocoseFlag: 1,
                    glocoseTwoFlag: 1,
                    addTemperature: '',
                    // bloodGlucoseBeforeMeal: '',
                    // bloodGlucoseAfterMeal: ''
                })
                app.popover.close('.popover-addData');
                this.child.sycnData();
            }

        });
    }

    btnSave(type) {
        let self = this;
        if (type === 'userProfile') {
            var formData = app.form.convertToData('#edit-user-profile');
            formData.age = GetValue(app, 'age', this.$$("input[name='age']"), 'number', true);
            formData.height = GetValue(app, 'height', this.$$("input[name='height']"), 'number', true);
            if (formData.age && formData.height) {
                app.preloader.show();
                // alert(JSON.stringify(formData));
                AuthGuard.upateUserInfo({
                    userProfileId: this.props.userId,
                    height: formData.height,
                    notes: formData.note
                }, (isOk, res) => {
                    if (isOk) {
                        self.setState({
                            patientHeight: formData.height,
                            patientNote: formData.note
                        });
                        app.popover.close('.popover-menu');
                        app.preloader.hide();
                    }
                });
            }
        }

        let { patientDiaGoal, patientSysGoal } = app.form.convertToData('#set-user-goal');
        let _patientDiaGoal = patientDiaGoal ? parseInt(patientDiaGoal) : 0;
        let _patientSysGoal = patientSysGoal ? parseInt(patientSysGoal) : 0;



        if (type === 'addData') {
            app.preloader.show();
            this.setState({
                savingAddDataBtn: true
            })

            if (this.state.addDataSleepStartTime === 'pm') {
                this.setState({
                    addDataSleepStart: showTime(this.state.addDataSleepStart)
                })
            } else {
                this.setState({
                    addDataSleepStart: showTimeReverse(this.state.addDataSleepStart)
                })
            }

            if (this.state.addDataSleepEndTime === 'pm') {
                this.setState({
                    addDataSleepEnd: showTime(this.state.addDataSleepEnd)
                })
            } else {
                this.setState({
                    addDataSleepEnd: showTimeReverse(this.state.addDataSleepEnd)
                })
            }

            if (this.state.addDataSleepStart == '' && this.state.addDataSleepEnd == '') {
                app.dialog.alert('Sleep Start and Sleep End Input error')
                this.setState({
                    savingAddDataBtn: false
                })
            } else if (this.state.addDataSleepStart == '') {
                app.dialog.alert('Sleep Start Input error')
                this.setState({
                    savingAddDataBtn: false
                })
            } else if (this.state.addDataSleepEnd == '') {
                app.dialog.alert('Sleep End Input error')
                this.setState({
                    savingAddDataBtn: false
                })
            } else {
                this.addDataDetailInfo()
            }
        }

        if (type === "setGoal") {
            // if (patientSysGoal !== '') {
                // if (_patientSysGoal < this.MIN_SYS || _patientSysGoal > this.MAX_SYS) {
                //     ShowToast(app, 'Invalid Sys Goal Value (eg. >' + this.MIN_SYS + ' , <' + this.MAX_SYS + ')')
                //     console.log('Blood Pressure Systolic')
                // } else if (_patientDiaGoal < this.MIN_DIA || _patientDiaGoal > this.MAX_DIA) {
                //     ShowToast(app, 'Invalid Dia Goal Value (eg. >' + this.MIN_DIA + ' , <' + this.MAX_DIA + ')')
                //     console.log('Blood Pressure Diastolic')
                // } else {
                app.preloader.show();
                this.setState({
                    savingSetGoalBtn: true
                });

                // if (parseFloat(this.state.patientWeightFrom) > parseFloat(this.state.patientWeightTo)) {
                //     ShowToast(app, "Weight(BMI) 'from value' cannot be greater than 'to value'")
                //     console.log('Weight')
                //     this.setState({
                //         savingSetGoalBtn: false
                //     });
                // } else if (parseFloat(this.state.patientSPO2From) > parseFloat(this.state.patientSPO2To)) {
                //     ShowToast(app, "SpO2(%) 'from value' cannot be greater than 'to value'")
                //     console.log('SpO2', parseFloat(this.state.patientSPO2From) > parseFloat(this.state.patientSPO2To), this.state.patientSPO2From, this.state.patientSPO2To)
                //     this.setState({
                //         savingSetGoalBtn: false
                //     });
                // } else if (parseFloat(this.state.patientGlucoseBeforeMealFrom) > parseFloat(this.state.patientGlucoseBeforeMealTo)) {
                //     ShowToast(app, "Glucose before meal 'from value' cannot be greater than 'to value'")
                //     console.log('Glucose before meal')
                //     this.setState({
                //         savingSetGoalBtn: false
                //     });
                // } else if (parseFloat(this.state.patientGlucoseAfterMealFrom) > parseFloat(this.state.patientGlucoseAfterMealTo)) {
                //     ShowToast(app, "Glucose after meal(mmol) 'from value' cannot be greater than 'to value'")
                //     console.log('Glucose after meal')
                //     this.setState({
                //         savingSetGoalBtn: false
                //     });
                // } else {
                this.setGoalInfo();
                // }
                // }
            // }
        }
    }

    setGoalInfo() {
        AuthGuard.setGoalUpdate({
            userProfileId: this.props.userId,
            // bloodPressureHight: this.state.patientSysGoalForm,
            // bloodPressureLow: this.state.patientDiaGoalTo,
            bloodPressureHight: this.state.patientSysGoal,
            bloodPressureLow: this.state.patientDiaGoal,
            weight: this.state.patientWeightFrom,
            weight_to: this.state.patientWeightTo,
            // step: this.state.patientSteps,
            // sleep: this.state.patientSleep,
            spo2: this.state.patientSPO2From,
            spo2_to: this.state.patientSPO2To,
            glucose: this.state.patientGlucoseBeforeMealFrom,
            glucose_to: this.state.patientGlucoseBeforeMealTo,
            glucose_after_meal: this.state.patientGlucoseAfterMealFrom,
            glucose_after_meal_to: this.state.patientGlucoseAfterMealTo,
        }, (isOk, res) => {
            this.setState({
                savingSetGoalBtn: false
            });
            if (isOk) {
                app.popover.close('.popover-goal');
                this.child.sycnData();
                app.preloader.hide();
            }
        });
    }

    checkBPOk = () => {
        let checkBPSysMin = Math.min(
            this.MIN_SYS,
            this.MAX_SYS,
            this.state.bloodPressureHighUpperLimit,
            this.state.bloodPressureHighLowerLimit
        );
    }

    setLimit() {
        console.log(this.MIN_SYS,
            this.MAX_SYS,
            this.state.bloodPressureHighUpperLimit,
            this.state.bloodPressureHighLowerLimit)
        let checkBPSysMin = Math.min(
            this.MIN_SYS,
            this.MAX_SYS,
            this.state.bloodPressureHighUpperLimit,
            this.state.bloodPressureHighLowerLimit
        );

        let checkBPSysMax = Math.max(
            this.MIN_SYS,
            this.MAX_SYS,
            this.state.bloodPressureHighUpperLimit,
            this.state.bloodPressureHighLowerLimit
        );
        // console.log(checkBPSys);
        app.preloader.show();
        this.setState({
            savingSetLimitBtn: true
        });

        // if (this.state.bloodPressureHighUpperLimit > this.state.bloodPressureHighLowerLimit) {
        //     ShowToast(app, "'from value' cannot be greater than 'to value'")
        //     this.setState({
        //         savingSetLimitBtn: false
        //     });
        // } else if (this.state.bloodPressureLowUpperLimit > this.state.bloodPressureLowLowerLimit) {
        //     ShowToast(app, "'from value' cannot be greater than 'to value'")
        //     this.setState({
        //         savingSetLimitBtn: false
        //     });
        // } else if (this.state.weightUpperLimit > this.state.weightLowerLimit) {
        //     ShowToast(app, "'from value' cannot be greater than 'to value'")
        //     this.setState({
        //         savingSetLimitBtn: false
        //     });
        // } else {

        AuthGuard.setLimitUpdate({
            userProfileId: this.props.userId,
            bloodPressureHighUpperLimit: this.state.bloodPressureHighUpperLimit,
            bloodPressureHighLowerLimit: this.state.bloodPressureHighLowerLimit,
            bloodPressureLowUpperLimit: this.state.bloodPressureLowUpperLimit,
            bloodPressureLowLowerLimit: this.state.bloodPressureLowLowerLimit,
            weightUpperLimit: this.state.weightUpperLimit,
            weightLowerLimit: this.state.weightLowerLimit
        }, (isOk, res) => {
            this.setState({
                savingSetLimitBtn: false,
            });
            if (isOk) {
                app.popover.close('.popover-limit');
                app.preloader.hide();
                this.child.reBuildGraphs()
            }
        });
        // }

    }

    setLimitPopoverClosed() {
        this.getUserLimitInfo()
    }

    popoverClosed(type = 'userProfile', e) {
        if (type === 'userProfile') {
            var formData = {
                'name': this.state.patientName,
                'age': this.state.patientAge + ' years old',
                'gender': this.state.patientGender,
                'height': this.state.patientHeight ? this.state.patientHeight + ' cm' : '',
                'note': this.state.patientNote,
            }
            app.form.fillFromData('#edit-user-profile', formData);
        } else {
            if (type === 'addData') {

            }
            if (type === 'setGoal') {
                this.getUserGoalInfo()
            }

            if (type === 'userProfile') {
                this.getPatientDetail()
            }
        }
    }

    inputBlur(atrName, type, e) {
        let res = InputBlur(app, atrName, this.$$("input[name='" + atrName + "']"), type);
        if (!res) {
            this.setState({
                [atrName]: "",
            });
        }
    }

    inputFoucs(atrName, type, e) {
        InputFoucs(app, atrName, this.$$("input[name='" + atrName + "']"), type);
    }

    goBack() {
        app.views.main.router.navigate(ROUTES.patientlist);
    }

    btnSetGoal() {

        // this.$$('.goalPicker').remove();
        app.popover.open('.popover-goal');
        let displaySys = this.state.patientSysGoal < this.MIN_SYS ? GetArrMiddleNum(this.arrSysGoal) : this.state.patientSysGoal;
        let displayDia = this.state.patientDiaGoal < this.MIN_DIA ? GetArrMiddleNum(this.arrDiaGoal) : this.state.patientDiaGoal;
    }

    btnSetLimit() {
        app.popover.open('.popover-limit');
    }

    btnAddData() {
        app.popover.open('.popover-addData');
    }

    createDialog = () => {
        // this.childChatPopup.loadWasm()
        if(this.state.is_open){
            // this.initChatInfo()
            this.setState({
                is_loading_end: true,
            })
            app.dialog.preloader('We are retrieving the chat, please wait.');
        }else{
            this.setState({
                popupOpened: true,
            })
        }
        
    }

    healthReview = () => {
        this.setState({
            popupOpened: true,
            showHealthReview: true
        })
    }

    scheduleAppointment = () => {
        app.popup.open('.Reschedule-popup');
    }

    onPageBeforeRemove() {
        const self = this;
        // Destroy popup when page removed
        if (self.popup) self.popup.destroy();
        if (self.calendar) self.calendar.destroy('#reschedule-calendar');
    }

    btnViewPatientInfo() {
        app.views.main.router.navigate(ROUTES.patientInfo, {
            props: { user_profile_id: this.props.userId },
            reloadCurrent: true,
            animate: false,
        });
    }

    hasRadMsg = () => {
        this.setState({
            unread_messages_count: 0
        })
    }

    onRef = (ref) => {
        this.child = ref
    }

    loadAvaliablePrograms() {
        let coachProfile = AuthGuard.getCoachProfile();
        AuthGuard.findProgramByMerchantId({
            clinicInfoId: coachProfile.clinic_info_id,
            status: 'active',
        }, (ok, res) => {
            let programOptions = [{ label: 'Please select', value: '' }]
            if (ok) {
                res.map(item => {
                    programOptions.push({
                        label: item.name,
                        value: item.id
                    })
                })
            }

            this.setState({
                programOptions: programOptions
            })
        })

    }

    onProgramChange(idx, evt) {
        let program_ids = this.state.program_ids
        program_ids[idx] = evt.target.value
        this.setState({ program_ids: program_ids })
    }

    onAddProgram(idx) {
        let program_ids = this.state.program_ids
        program_ids.push('')
        this.setState({ program_ids: program_ids }, () => {
            this.onConfirmAddProgram(idx)
        })
    }

    onConfirmAddProgram(idx) {
        let joined = this.state.programJoined.map(i => i.value)
        let added = this.state.program_ids.filter(i => i !== '' && !joined.includes(i))
        if (!added.length) {

            let _delList = this.state.program_ids

            console.log(_delList)

            _delList.splice(idx, 1)

            this.setState({
                program_ids: _delList
            }, () => {
                console.log(this.state.program_ids)
            })

            return app.dialog.alert('please select a valid program')
        }
        added = [...new Set(added)]
        AuthGuard.addProgramsByUserId({
            user_profile_id: this.props.userId,
            program_ids: JSON.stringify(added),
        }, (ok, res, msg) => {
            if (ok) {
                // added.map(i => this.state.programPending.unshift(this.state.programOptions.filter(o => o.value == i).pop()))
                this.setState({
                    program_ids: [''],
                    // programPending: this.state.programPending,
                }, () => {

                    AuthGuard.findProgramByUserIdAndProgramType({
                        user_profile_id: this.props.userId
                    }, (ok, res) => {
                        if (ok) {
                            console.log(res)

                            let _programPending = res.invitation_array.map(i => {
                                return {
                                    label: i.program_name,
                                    value: i.program_id,
                                    service_access_id: i.service_access_id
                                }
                            })

                            let _programJoined = res.buy_program_array.map(i => {
                                return {
                                    label: i.program_name,
                                    value: i.program_id,
                                    service_access_id: i.service_access_id
                                }
                            })

                            this.setState({
                                programPending: _programPending,
                                programJoined: _programJoined,
                            }, () => {
                                console.log(_programPending, this.state.programPending,_programJoined,this.state.programJoined)
                            })
                        }
                    })

                })
                return app.dialog.alert('add success')
            }
            return app.dialog.alert('add failed')
        })
    }

    onChangeClucose(type) {


        if (type === 'before') {

            if (this.state.glocoseFlag === 0) {
                this.setState({
                    glocoseFlag: 1
                })
            } else if (this.state.glocoseFlag === 1) {
                this.setState({
                    glocoseFlag: 0,
                    glocoseTwoFlag: 1
                })
            }

            console.log(this.state.glocoseFlag)

        } else {

            if (this.state.glocoseTwoFlag === 0) {
                this.setState({
                    glocoseTwoFlag: 1
                })
            } else if (this.state.glocoseTwoFlag === 1) {
                this.setState({
                    glocoseFlag: 1,
                    glocoseTwoFlag: 0
                })
            }
        }

    }

    addDataSleepStartChange() {
        // showTime(this.state.addDataSleepStart)


        if (this.state.addDataSleepStartTime === 'pm') {
            this.setState({
                addDataSleepStartTime: 'am'
            }, () => {
                if (this.state.addDataSleepStart !== '') {
                    this.setState({
                        addDataSleepStart: showTimeReverse(this.state.addDataSleepStart)
                    })
                }
            })
        } else if (this.state.addDataSleepStartTime === 'am') {
            this.setState({
                addDataSleepStartTime: 'pm'
            }, () => {
                if (this.state.addDataSleepStart !== '') {
                    this.setState({
                        addDataSleepStart: showTime(this.state.addDataSleepStart)
                    })
                }

            })
        }

        console.log(this.state.addDataSleepStart, this.state.addDataSleepStart)
    }

    addDataSleepEndChange() {

        if (this.state.addDataSleepEndTime === 'am') {
            this.setState({
                addDataSleepEndTime: 'pm'
            }, () => {
                if (this.state.addDataSleepEnd !== '') {
                    this.setState({
                        addDataSleepEnd: showTime(this.state.addDataSleepEnd)
                    })
                }
            })
        } else if (this.state.addDataSleepEndTime === 'pm') {
            this.setState({
                addDataSleepEndTime: 'am'
            }, () => {
                if (this.state.addDataSleepEnd !== '') {
                    this.setState({
                        addDataSleepEnd: showTimeReverse(this.state.addDataSleepEnd)
                    })
                }

            })
        }

        console.log(this.state.addDataSleepEnd, this.state.addDataSleepEnd)
    }

    openRemovePop(uid, uname, type) {
        // app.dialog.confirm('Are you sure you want to remove user from ' + uname, 'Warning!', () => {
        //     this.removeJoinedProgram(uid)
        // });

        app.dialog.create({
            title: 'Warning!',
            text: 'Are you sure you want to remove user from ' + uname,
            buttons: [
                {
                    text: 'Yes',
                    color: 'red',
                    onClick: () => {
                        this.removeJoinedProgram(uid, type)
                    }
                },
                {
                    text: 'No',
                    color: 'blue',
                    onClick: () => {
                        console.log('no')
                    }
                },
            ],
            verticalButtons: true,
        }).open();

    }

    chatPopUpRef = (ref) =>{
        this.childChatPopup = ref
    }

    removeJoinedProgram(uid,type) {
        AuthGuard.removeJoinedProgram({
            service_access_id: uid
        }, (ok, res) => {
            if (ok) {
                ShowToast(app, res.message)
                AuthGuard.findProgramByUserIdAndProgramType({
                    user_profile_id: this.props.userId
                }, (ok, res) => {
                    if (ok) {

                        if (type == 'Pending') {
                            let _programPending = res.invitation_array.map(i => {
                                return {
                                    label: i.program_name,
                                    value: i.program_id,
                                    service_access_id: i.service_access_id
                                }
                            })

                            this.setState({
                                programPending: _programPending,
                            }, () => {
                                console.log(res.invitation_array, _programPending, this.state.programPending)
                            })
                        } else {

                            let _programJoined = res.buy_program_array.map(i => {
                                return {
                                    label: i.program_name,
                                    value: i.program_id,
                                    service_access_id: i.service_access_id
                                }
                            })

                            this.setState({
                                programJoined: _programJoined,
                            }, () => {
                                console.log(res.buy_program_array, _programJoined, this.state.programJoined)
                            })
                        }
                    }
                })
            } else {
                ShowToast(app, res.message)
            }

        })
    }

    render() {
        const addProgramDom = (
            <Col className="cell_item">
                <Row>Monitoring Programs: </Row>
                <List inlineLabels noHairlinesMd className="thin-list-select">
                    {this.state.programJoined.length > 0
                        ? this.state.programJoined.map((i) => (
                            <ul key={i.value}>
                                <div style={{ display: "inline-block", width: '70%' }}>
                                    {/* <ListInput
                                        key={i.value}
                                        type="select"
                                        style={{ width: "450px" }}
                                        value={i.value}
                                        disabled
                                        outline
                                    >
                                        <option key={i.label} value={i.value}>
                                            {i.label}
                                        </option>
                                    </ListInput> */}
                                    {i.label}
                                </div>
                                <div style={{ display: "inline-block", color: "#5BB3C4", cursor: "pointer", marginLeft: '30px' }} onClick={this.openRemovePop.bind(this, i.service_access_id, i.label, 'programJoined')}>
                                    <img src={deleteIcon} style={{ width: '20px' }} className="Delete" alt="Delete" />
                                </div>
                                <div>
                                    <i style={{ fontSize: '12px' }}>joined {i.created_at}</i>
                                </div>
                            </ul>
                        ))
                        : ""}
                </List>
                <List inlineLabels noHairlinesMd className="thin-list-select">
                    {this.state.programPending.length > 0
                        ? this.state.programPending.map((i) => (
                            <ul key={i.value}>
                                <div style={{ display: "inline-block", width: '75%' }}>
                                    {/* <ListInput
                                        key={i.value}
                                        type="select"
                                        style={{ width: "450px" }}
                                        value={i.value}
                                        disabled
                                        outline
                                    >
                                        <option key={i.label} value={i.value}>
                                            {i.label}
                                        </option>
                                    </ListInput> */}
                                    {i.label}
                                </div>
                                <div style={{ display: "inline-block" }}>Pending</div>
                                <div style={{ display: "inline-block", color: "#5BB3C4", cursor: "pointer", marginLeft: '30px' }} onClick={this.openRemovePop.bind(this, i.service_access_id, i.label, 'Pending')}>
                                    <img src={deleteIcon} style={{ width: '20px' }} className="Delete" alt="Delete" />
                                </div>
                            </ul>
                        ))
                        : ""}
                </List>
                <List inlineLabels noHairlinesMd className="thin-list-select">
                    {this.state.program_ids.length > 0 &&
                        this.state.program_ids.map((i, idx) =>
                            idx === 0 ? (
                                <ul key={'ul' + i.value}>
                                    <div style={{ display: "inline-block" }}>
                                        <ListInput
                                            type="select"
                                            style={{ width: "450px" }}
                                            value={this.state.program_ids[idx]}
                                            onChange={this.onProgramChange.bind(this, idx)}
                                            outline
                                        >
                                            {this.state.programOptions.map((i) => (
                                                <option key={i.value} value={i.value}>
                                                    {i.label}
                                                </option>
                                            ))}
                                        </ListInput>
                                    </div>
                                    <Button
                                        onClick={this.onAddProgram.bind(this, idx)}
                                        style={{ marginTop: "-5px", display: "inline-block" }}
                                        color="custom-blue"
                                        borderColor="custom-blue"
                                    >
                                        <Icon f7="plus_app"></Icon>
                                    </Button>
                                    {/* <Button
                                        onClick={this.onConfirmAddProgram.bind(this)}
                                        style={{ marginTop: "-5px", display: "inline-block" }}
                                        color="custom-blue"
                                        borderColor="custom-blue"
                                    >
                                        <Icon f7="checkmark_alt"></Icon>
                                    </Button> */}
                                </ul>
                            ) : (
                                    <ListInput
                                        type="select"
                                        style={{ width: "450px" }}
                                        value={this.state.program_ids[idx]}
                                        onChange={this.onProgramChange.bind(this, idx)}
                                        outline
                                        key={'programLists' + idx}
                                    >
                                        {this.state.programOptions.map((i, index) => (
                                            <option key={'program' + index} value={i.value}>
                                                {i.label}
                                            </option>
                                        ))}
                                    </ListInput>
                                )
                        )}
                </List>
            </Col>
        );

        return (
            <FramePage
                name={ROUTES.patientdetail}
                onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}
            >
                {!this.state.isFrame &&
                <div className="navBar">
                    <Link onClick={this.goBack.bind(this)} animate={false} color='gray'> Monitoring list </Link> &gt; <span>{this.state.patientName}</span>
                </div>
                }
                <Row className="navTitleBar">
                    <Col>
                        User Details
                        </Col>
                    <Col>
                        <div className="footerBtn">
                            <Button onClick={this.scheduleAppointment.bind(this)} className="navbarBtn outline-blue-btn">
                                Schedule Appointment
                            </Button>
                            <Button onClick={this.healthReview.bind(this)} className="navbarBtn outline-blue-btn">
                                Health Review
                            </Button>
                            {/* {this.state.oldDialogList && this.state.oldDialogList.length > 0 ? 
                            <Button onClick={this.createDialog.bind(this)} className="ChatBtn outline-blue-btn" disabled={this.state.unChatLoad ? '' : 'disabled'}>
                                {this.state.unread_messages_count ? <Badge>{this.state.unread_messages_count}</Badge> : ''}
                                CHAT
                            </Button> : ''} */}
                        </div>
                        {/* <Link badge="5"  className="ChatBtn" onClick={this.createDialog.bind(this)} raised fill>Chat</Link> */}

                    </Col>
                </Row>
                <div className="gray-block">
                    <Row className="block_title">
                        <Col width="70">
                            Personal Details
                            </Col>
                        <Col className="btn-green canClick" onClick={this.btnViewPatientInfo.bind(this)} width="30">
                            View full patient information
                        </Col>
                    </Row>
                    <Row className="cell_list" style={{ lineHeight: '2em' }}>
                        <Col>
                            <Row className="cell_item">
                                <Col width="30">Name: </Col>
                                <Col width="70">{this.state.patientName}</Col>
                            </Row>
                            <Row className="cell_item">
                                <Col width="30">Age: </Col>
                                <Col width="70">{this.state.patientAge ? this.state.patientAge + ' years old' : '-'} </Col>
                            </Row>
                            <Row className="cell_item">
                                <Col width="30">Gender: </Col>
                                <Col width="70">{showGender(this.state.patientGender, 'full')} </Col>
                            </Row>
                            <Row className="cell_item">
                                <Col width="30">Height: </Col>
                                <Col width="70">{this.state.patientHeight ? this.state.patientHeight + ' cm' : '-'} </Col>
                            </Row>
                            <Row className="cell_item">
                                <Col width="30">Note: </Col>
                                <Col width="70">{this.state.patientNote}</Col>
                            </Row>
                        </Col>
                        <Col>
                            {addProgramDom}
                        </Col>
                    </Row>
                </div>
                <div className="gray-block">
                    {this.props.userId ?
                        <Graph
                            btnSetGoal={this.btnSetGoal.bind(this)}
                            btnSetLimit={this.btnSetLimit.bind(this)}
                            btnAddData={this.btnAddData.bind(this)}
                            patientSysGoal={this.state.patientSysGoal}
                            patientDiaGoal={this.state.patientDiaGoal}
                            patientWeightHGoal={this.state.patientWeightFrom}
                            patientWeightLGoal={this.state.patientWeightTo}
                            patientSPO2HGoal={this.state.patientSPO2From}
                            patientSPO2LGoal={this.state.patientSPO2To}
                            weightLowerLimit={this.state.weightLowerLimit}
                            weightUpperLimit={this.state.weightUpperLimit}
                            bloodPressureHighLowerLimit={this.state.bloodPressureHighLowerLimit}
                            bloodPressureHighUpperLimit={this.state.bloodPressureHighUpperLimit}
                            bloodPressureLowLowerLimit={this.state.bloodPressureLowLowerLimit}
                            bloodPressureLowUpperLimit={this.state.bloodPressureLowUpperLimit}
                            patientGlucoseAfterMealFrom={this.state.patientGlucoseAfterMealFrom}
                            patientGlucoseAfterMealTo={this.state.patientGlucoseAfterMealTo}
                            patientGlucoseBeforeMealFrom={this.state.patientGlucoseBeforeMealFrom}
                            patientGlucoseBeforeMealTo={this.state.patientGlucoseBeforeMealTo}
                            userId={this.props.userId}
                            onRef={this.onRef}
                        />
                        :
                        <div style={{ width: '100%', margin: '0 auto', textAlign: 'center' }}>
                            <Preloader color="green"></Preloader>
                        </div>
                    }
                </div>
                {/* edit personnal details */}
                <Popover className="popoverModal popover-menu" closeByOutsideClick={false} onPopoverClosed={this.popoverClosed.bind(this, 'userProfile')}>
                    <BlockTitle>Edit Personal Details</BlockTitle>
                    <form id="edit-user-profile">
                        <List noHairlinesMd>
                            <ListInput
                                label="Name:"
                                type="text"
                                name="name"
                                required
                                placeholder="Input Name"
                                validate
                                readonly
                            />

                            <ListInput
                                label="Age:"
                                type="text"
                                name="age"
                                required
                                validate
                                placeholder="Input Age"
                                onBlur={this.inputBlur.bind(this, 'age', 'number')}
                                onFocus={this.inputFoucs.bind(this, 'age', 'number')}
                                readonly
                            />

                            <li>
                                <div className="item-content item-input">
                                    <div className="item-inner">
                                        <div className="item-title item-label">Gender</div>
                                        <div className="item-input-wrap form-input">
                                            <select className="gender" name="gender" defaultValue={this.state.patientGender} style={{ pointerEvents: 'none' }}>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <ListInput
                                label="Height:"
                                type="text"
                                required
                                validate
                                name="height"
                                placeholder="Input Height"
                                onBlur={this.inputBlur.bind(this, 'height', 'number')}
                                onFocus={this.inputFoucs.bind(this, 'height', 'number')}
                            />

                            <ListInput
                                className="form-textarea"
                                label="Notes:"
                                outline
                                type="textarea"
                                name="note"
                                rows="10"
                                resizable
                                placeholder="Input Notes"
                            />
                        </List>
                    </form>
                    <div className="popFooter">
                        <Button onClick={this.btnSave.bind(this, 'userProfile')} className="btn-red">Save</Button>
                    </div>
                </Popover>
                {/* edit goal */}
                <Popover className="popoverModal popover-goal" closeByOutsideClick={false} onPopoverClosed={this.popoverClosed.bind(this, 'setGoal')}>
                    <BlockTitle>Set Goal</BlockTitle>
                    <Block>
                        <form id="set-user-goal">
                            <h5>Blood Pressure:</h5>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <span>Systolic</span>
                                    <Input
                                        type="number"
                                        placeholder="Sys Goal"
                                        name="patientSysGoal"
                                        value={this.state.patientSysGoal ? (this.state.patientSysGoal).replace(/[^\d^\.]+/g, '') : ""}
                                        info='mmHg'
                                        onChange={(e) => {
                                            this.setState({ patientSysGoal: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">    </div>
                                <div className="input-dia-goals">
                                    <span>Diastolic</span>
                                    <Input
                                        type="number"
                                        placeholder="Dia Goal"
                                        name="patientDiaGoal"
                                        value={this.state.patientDiaGoal ? (this.state.patientDiaGoal).replace(/[^\d^\.]+/g, '') : ""}
                                        info='mmHg'
                                        onChange={(e) => {
                                            this.setState({ patientDiaGoal: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <h5>Weight(BMI):</h5>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <span>From</span>
                                    <Input
                                        type="number"
                                        placeholder="Weight"
                                        name="weight"
                                        value={this.state.patientWeightFrom ? (this.state.patientWeightFrom).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientWeightFrom: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">    </div>

                                <div className="input-sys-goals">
                                    <span>To</span>
                                    <Input
                                        type="number"
                                        placeholder="Weight"
                                        name="weight"
                                        value={this.state.patientWeightTo ? (this.state.patientWeightTo).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientWeightTo: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>
                            <h5>SpO2(%):</h5>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <span>From</span>
                                    <Input
                                        type="number"
                                        placeholder="SpO2"
                                        name="spO2_from"
                                        value={this.state.patientSPO2From ? (this.state.patientSPO2From).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientSPO2From: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">    </div>

                                <div className="input-sys-goals">
                                    <span>To</span>
                                    <Input
                                        type="number"
                                        placeholder="SpO2"
                                        name="spO2_to"
                                        value={this.state.patientSPO2To ? (this.state.patientSPO2To).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientSPO2To: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                            <h5>Glucose before meal(mmol):</h5>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <span>From</span>
                                    <Input
                                        type="number"
                                        placeholder="before meal"
                                        name="glocose_before_meal_from"
                                        value={this.state.patientGlucoseBeforeMealFrom ? (this.state.patientGlucoseBeforeMealFrom).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientGlucoseBeforeMealFrom: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">     </div>

                                <div className="input-sys-goals">
                                    <span>To</span>
                                    <Input
                                        type="number"
                                        placeholder="before meal"
                                        name="glocose_before_meal_to"
                                        value={this.state.patientGlucoseBeforeMealTo ? (this.state.patientGlucoseBeforeMealTo).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientGlucoseBeforeMealTo: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                            <h5>Glucose after meal(mmol):</h5>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <span>From</span>
                                    <Input
                                        type="number"
                                        placeholder="after meal"
                                        name="glocose_after_meal_from"
                                        value={this.state.patientGlucoseAfterMealFrom ? (this.state.patientGlucoseAfterMealFrom).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientGlucoseAfterMealFrom: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">     </div>

                                <div className="input-sys-goals">
                                    <span>To</span>
                                    <Input
                                        type="number"
                                        placeholder="after meal"
                                        name="glocose_after_meal_to"
                                        value={this.state.patientGlucoseAfterMealTo ? (this.state.patientGlucoseAfterMealTo).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ patientGlucoseAfterMealTo: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                        </form>
                    </Block>
                    <div className="popFooter">
                        <Button disabled={this.state.savingSetGoalBtn} onClick={this.btnSave.bind(this, 'setGoal')} className="btn-red">Save</Button>
                    </div>
                </Popover>


                <Popover className="popoverModal popover-limit" closeByOutsideClick={false} onPopoverClosed={this.setLimitPopoverClosed.bind(this)}>
                    <BlockTitle>Set Limit
                        <p>You will be alerted when patient exceed this threshold</p>
                    </BlockTitle>
                    <Block>
                        <form id="set-user-limit">
                            <h5>Blood Pressure:</h5>
                            <div className={`errorMsg ${this.state.limitHpError ? 'showDiv' : 'hideDiv'}`}>
                                SYS normal range: {this.MIN_SYS} - {this.MAX_SYS} mm Hg;
                                DIA normal range: {this.MIN_DIA} - {this.MAX_DIA} mm Hg;
                            </div>

                            <p>Alert When Higher Than</p>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <Input
                                        type="number"
                                        placeholder="Systolic"
                                        name="bloodPressureHighUpperLimit"
                                        value={this.state.bloodPressureHighUpperLimit ? (this.state.bloodPressureHighUpperLimit).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ bloodPressureHighUpperLimit: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">  /  </div>
                                <div className="input-dia-goals">
                                    <Input
                                        type="number"
                                        placeholder="Diastolic"
                                        name="bloodPressureHighLowerLimit"
                                        value={this.state.bloodPressureHighLowerLimit ? (this.state.bloodPressureHighLowerLimit).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ bloodPressureHighLowerLimit: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                            <p>Alert When Lower Than</p>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <Input
                                        type="number"
                                        placeholder="Systolic"
                                        name="bloodPressureLowUpperLimit"
                                        value={this.state.bloodPressureLowUpperLimit ? (this.state.bloodPressureLowUpperLimit).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ bloodPressureLowUpperLimit: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">  /  </div>
                                <div className="input-dia-goals">
                                    <Input
                                        type="number"
                                        placeholder="Diastolic"
                                        name="bloodPressureLowLowerLimit"
                                        value={this.state.bloodPressureLowLowerLimit ? (this.state.bloodPressureLowLowerLimit).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ bloodPressureLowLowerLimit: e.target.value });
                                        }}
                                    />
                                </div>
                            </div>

                            <h5>Weight:</h5>
                            <div className={`errorMsg ${this.state.limitWeightError ? 'showDiv' : 'hideDiv'}`}>
                                Weight normal range: {this.MIN_Weight} - {this.MAX_Weight} mm Hg;
                            </div>

                            <p>Alert When Higher Than</p>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <Input
                                        type="number"
                                        placeholder="Weight"
                                        name="weightUpperLimit"
                                        value={this.state.weightUpperLimit ? (this.state.weightUpperLimit).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ weightUpperLimit: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">  Kg  </div>
                            </div>

                            <p>Alert When Lower Than</p>
                            <div className="input-goals">
                                <div className="input-sys-goals">
                                    <Input
                                        type="number"
                                        placeholder="Weight"
                                        name="weightLowerLimit"
                                        value={this.state.weightLowerLimit ? (this.state.weightLowerLimit).replace(/[^\d^\.]+/g, '') : ""}
                                        onChange={(e) => {
                                            this.setState({ weightLowerLimit: e.target.value });
                                        }}
                                    />
                                </div>
                                <div className="input-diff">  Kg  </div>
                            </div>
                        </form>
                    </Block>
                    <div className="popFooter">
                        <Button disabled={this.state.savingSetLimitBtn} onClick={this.setLimit.bind(this)} className="btn-red">Save</Button>
                    </div>
                </Popover>

                <Popover className="popoverModal popover-addData" closeByOutsideClick={false} onPopoverClosed={this.popoverClosed.bind(this, 'addData')}>
                    <BlockTitle>Add Data</BlockTitle>
                    <Block className="add-data-box">
                        <form id="add-date">
                            <h5>Blood Pressure:</h5>
                            <Row className="cell_item">
                                <div className="input-goals">
                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=""
                                            name="Systolic"
                                            value='Systolic'
                                            disabled
                                        />
                                    </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=''
                                            name="Diastolic"
                                            value='Diastolic'
                                            disabled
                                        />
                                    </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=''
                                            name="Pulse Rate"
                                            value='Pulse Rate'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="input-goals">

                                    <div className="input-sys-goals">
                                        <Input
                                            type="number"
                                            placeholder="High"
                                            name="addDatabloodPressureHight"
                                            value={this.state.addDatabloodPressureHight ? (this.state.addDatabloodPressureHight).replace(/[^\d^\.]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDatabloodPressureHight: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="input-diff">  /  </div>

                                    <div className="input-dia-goals">
                                        <Input
                                            type="number"
                                            placeholder="Low"
                                            name="addDatabloodPressureLow"
                                            value={this.state.addDatabloodPressureLow ? (this.state.addDatabloodPressureLow).replace(/[^\d^\.]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDatabloodPressureLow: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="input-diff">    </div>

                                    <div className="input-dia-goals">
                                        <Input
                                            type="number"
                                            placeholder="Pulse"
                                            name="addDataPulse"
                                            value={this.state.addDataPulse ? (this.state.addDataPulse).replace(/[^\d^\.]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDataPulse: e.target.value });
                                            }}
                                        />
                                    </div>
                                </div>
                            </Row>

                            <Row className="cell_item">
                                <div className="input-goals" style={{ marginTop: '15px' }}>
                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=""
                                            name="Weight:"
                                            value='Weight:'
                                            disabled
                                        />
                                    </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>

                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=''
                                            name="Steps:"
                                            value='Steps:'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="input-goals">
                                    <div className="input-sys-goals">

                                        <Input
                                            type="number"
                                            placeholder="Weight"
                                            name="weight"
                                            value={this.state.addDataWeight ? (this.state.addDataWeight).replace(/[^\d^\.]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDataWeight: e.target.value });
                                            }}
                                        />

                                    </div>
                                    <div className="input-diff">  kg  </div>
                                    <div className="input-diff">     </div>

                                    <div className="input-sys-goals">

                                        <Input
                                            type="number"
                                            placeholder="Steps"
                                            name="addDataSteps"
                                            value={this.state.addDataSteps ? (this.state.addDataSteps).replace(/[^\d^\.]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDataSteps: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="input-diff">  steps  </div>
                                </div>
                            </Row>


                            <h5>Sleep:</h5>
                            <Row className="cell_item">
                                <div className="input-goals">
                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=""
                                            name="Start"
                                            value='Start'
                                            disabled
                                        />
                                    </div>

                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>

                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=''
                                            name="End"
                                            value='End'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="input-goals">
                                    <div className="input-sys-goals">

                                        <Input
                                            type="text"
                                            placeholder="Sleep"
                                            name="addDataSleepStart"
                                            value={this.state.addDataSleepStart ? (this.state.addDataSleepStart).replace(/[^\d^\:]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDataSleepStart: (e.target.value).replace(/[^\0-9\:]/g, '') });
                                            }}
                                        />

                                    </div>
                                    <div className="input-diff" style={{ position: 'relative' }} onClick={this.addDataSleepStartChange.bind(this)}>
                                        <span>{this.state.addDataSleepStartTime}</span>
                                        <span className="arrow_up"></span>
                                        <span className="arrow_down"></span>
                                    </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>

                                    <div className="input-sys-goals">

                                        <Input
                                            type="text"
                                            placeholder="Sleep"
                                            name="addDataSleepEnd"
                                            value={this.state.addDataSleepEnd ? (this.state.addDataSleepEnd).replace(/[^\d^\:]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDataSleepEnd: (e.target.value).replace(/[^\0-9\:]/g, '') });
                                            }}
                                        />
                                    </div>
                                    <div className="input-diff" style={{ position: 'relative', marginRight: '10px' }} onClick={this.addDataSleepEndChange.bind(this)}>
                                        <span>{this.state.addDataSleepEndTime}</span>
                                        <span className="arrow_up"></span>
                                        <span className="arrow_down"></span>
                                    </div>
                                </div>
                            </Row>

                            <Row className="cell_item">
                                <div className="input-goals" style={{ marginTop: '15px' }}>
                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=""
                                            name="SPO2:"
                                            value='SPO2:'
                                            disabled
                                        />
                                    </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>

                                    <div className="input-sys-goals no-border-bom">
                                        <Input
                                            type="text"
                                            placeholder=''
                                            name="Temperature:"
                                            value='Temperature:'
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="input-goals">
                                    <div className="input-sys-goals">

                                        <Input
                                            type="number"
                                            placeholder="Spo2"
                                            name="addDataSpo2"
                                            value={this.state.addDataSpo2}
                                            onChange={(e) => {
                                                this.setState({ addDataSpo2: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="input-diff">  %  </div>
                                    <div className="input-diff">     </div>
                                    <div className="input-diff">     </div>

                                    <div className="input-sys-goals" style={{ marginLeft: '5px' }}>

                                        <Input
                                            type="number"
                                            placeholder=""
                                            name="addDataSteps"
                                            value={this.state.addTemperature ? (this.state.addTemperature).replace(/[^\d^\.]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addTemperature: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="input-diff">  ℃  </div>
                                </div>
                            </Row>


                            <span style={{ marginTop: '15px' }}>Glucose:</span>
                            <Row className="cell_item" style={{ display: 'flex', justifyContent: 'flex-start' }}>

                                <div className="input-goals" style={{ width: '40%' }}>
                                    <div className="input-sys-goals">
                                        <Input
                                            type="number"
                                            placeholder="Glucose"
                                            name="addDataGlucose"
                                            value={this.state.addDataGlucose ? (this.state.addDataGlucose).replace(/[^\d^\.]+/g, '') : ""}
                                            onChange={(e) => {
                                                this.setState({ addDataGlucose: e.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className="input-diff">  mmol  </div>
                                </div>
                                <Block className="navTitle footerBtn">
                                    <Button className={` ${this.state.glocoseFlag === 0 ? 'fill-but-abb' : 'fill-gray-abb '}`} onClick={this.onChangeClucose.bind(this, 'before')}>Before Meal</Button>
                                    <Button className={` ${this.state.glocoseTwoFlag === 0 ? 'fill-but-abb' : 'fill-gray-abb '}`} onClick={this.onChangeClucose.bind(this, 'after')}>After Meal</Button>
                                </Block>
                            </Row>
                        </form>
                    </Block>
                    <div className="popFooter">
                        <Button disabled={this.state.savingAddDataBtn} onClick={this.btnSave.bind(this, 'addData')} className="btn-red">Save</Button>
                    </div>
                </Popover>

                {/* <ChatPopUp
                    onRef={this.chatPopUpRef}
                    popupOpened={this.state.popupOpened}
                    onPopupClosed={() => {this.setState({ popupOpened: false, showHealthReview: false })}}
                    hasRadMsg={this.hasRadMsg.bind(this)}
                    dialogList={this.state.dialogList}
                    user_profile_id={this.props.userId}
                    userName={this.state.patientName}
                    userAvatar={this.state.userAvatar}
                    type="patientDetailChatPop"
                    showHealthReview={this.state.showHealthReview}
                    chat_drId={this.state.chat_drId}
                /> */}

                <ReschedulePopUp
                    // rescheduleQid={this.state.rescheduleQid}
                    userProfileId={this.props.userId}
                    drList={this.state.drList}
                />
            </FramePage>
        );
    }
}
const mapStateToProps = (state) => {

    const { patientList: { patientDetail, dialogInfo } } = state;

    return {
        patientDetail,
        dialogInfo
    };
};

export default connect(
    mapStateToProps,
    {
        GetPatientDetail,
        updateUserGoal
    }
)(PatientDetail)