import Login from './screens/Login';
import ForgetPassword from './screens/ForgetPassword';
import FWVerifyOtp from './screens/FWVerifyOtp';
import Restpw from './screens/Restpw';
import SessionList from './screens/SessionList';
import Appointments from './screens/Appointments';
import PatientList from './screens/PatientList';
import Setting from './screens/Setting';
import PatientDetail from './screens/PatientDetail';
import ConsultationSummary from './screens/ConsultationSummary';
import AddAccountRole from './screens/AddAccountRole'
import { ROUTES } from './commons/Constants';
import AuthGuard from './services/AuthGuard';
import AddAccount from './screens/AddAccount';
import PatientInfo from './screens/PatientInfo';
import Help from './components/Help';
import RegisterNewUser from './screens/RegisterNewUser';
import AddProvider from './screens/AddProvider';
import GoogleConnect from './screens/GoogleConnect';
import AddMedication from './screens/AddMedication';
import Reports from './screens/Reports';
import LabReports from "./screens/LabReports";


export const routes = [
    {
        path: ROUTES.login,
        component: Login,
        options: {
            pushState: true
        }
        // beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission],
    },
    {
        path: ROUTES.logout,
        component: Login
    },
    {
        path: ROUTES.forgetPassword,
        component: ForgetPassword
    },
    {
        path: ROUTES.fwOtp,
        component: FWVerifyOtp
    },
    {
        path: ROUTES.restpw,
        component: Restpw
    },
    // {
    //     path: ROUTES.setPw,
    //     component: Setpw
    // },
    {
        path: ROUTES.patientlist,
        options: {
            transition: 'f7-circle',
        },
        component: PatientList,
        beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission, AuthGuard.isProfileComplete.bind(AuthGuard)],
    },
    {
        path: ROUTES.sessionlist,
        options: {
            transition: 'f7-circle',
        },
        component: SessionList,
        beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission, AuthGuard.isProfileComplete.bind(AuthGuard)],
    },
    {
        path: ROUTES.appointments,
        options: {
            transition: 'f7-circle',
            pushState: true
        },
        component: Appointments,
        beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission, AuthGuard.isProfileComplete.bind(AuthGuard)],
    },
    {
        path: ROUTES.setting,
        options: {
            transition: 'f7-circle'
        },
        component: Setting,
        beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission],
    },
    {
        path: ROUTES.labReports,
        options: {
            transition: 'f7-circle'
        },
        component: LabReports,
        beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission],
    },
    
    // {
    //     path: ROUTES.help,
    //     options: {
    //         transition: 'f7-circle'
    //     },
    //     component: Help,
    //     beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission],
    // },
    {
        name: 'patientdetail',
        path: ROUTES.patientdetail,
        options: {
            transition: 'f7-circle',
        },
        component: PatientDetail
    },
    {
        name: 'registerNewUser',
        path: ROUTES.registerNewUser,
        options: {
            transition: 'f7-circle',
        },
        component: RegisterNewUser
    },
    {
        name: 'consultationSummary',
        path: ROUTES.consultationSummary,
        options: {
            transition: 'f7-circle',
        },
        component: ConsultationSummary
    },
    {
        path: ROUTES.addAccountRole,
        options: {
            transition: 'f7-circle',
        },
        component: AddAccountRole
    },
    {
        path: ROUTES.addAccount,
        options: {
            transition: 'f7-circle',
        },
        component: AddAccount
    },
    {
        path: ROUTES.patientInfo,
        options: {
            transition: 'f7-circle',
        },
        component: PatientInfo
    },
    {
        path: ROUTES.addProvider,
        options: {
            transition: 'f7-circle',
        },
        component: AddProvider
    },
    {
        path: ROUTES.googleConnect,
        options: {
            transition: 'f7-circle',
        },
        component: GoogleConnect
    },
    {
        path: ROUTES.addMedication,
        options: {
            transition: 'f7-circle',
        },
        component: AddMedication,
    },
    {
        path: ROUTES.reports,
        options: {
            transition: 'f7-circle',
        },
        component: Reports,
        beforeEnter: [AuthGuard.checkAuth, AuthGuard.checkPermission, AuthGuard.isProfileComplete.bind(AuthGuard)],
    },

];
