import { combineReducers } from "redux";
import loginReducer from './loginReducer'
import sessionReducer from './sessionReducer'
import patientReducer from './patientReducer'
import chatReducer from './chatReducer'

export default combineReducers({
    userLogin:loginReducer,
    sessionList: sessionReducer,
    patientList: patientReducer,
    chatList: chatReducer
});