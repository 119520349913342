import { JSEncrypt } from "jsencrypt";
import { RSAPublicKey, QB_APPID, QB_AUTHKEY, QB_AUTHSECRET, OS_NAME } from '../commons/Constants';
import moment from 'moment';
import numeral from 'numeral'
import currencyFormatter from 'currency-formatter'

/**
 * Rounding decimals
 *
 * @param {Number} value
 * @param {Number} decimals
 * @returns {Number}
 */
export const Round = (value, decimals) => {
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

/**
 * Rounding down decimals
 *
 * @param {Number} value
 * @param {Number} decimals
 * @returns {Number}
 */
export const RoundDown = (value, decimals) => {
    return Number(Math.floor(value + 'e' + decimals) + 'e-' + decimals);
};

/**
 * Rounding up decimals
 *
 * @param {Number} value
 * @param {Number} decimals
 * @returns {Number}
 */
export const RoundUp = (value, decimals) => {
    return Number(Math.ceil(value + 'e' + decimals) + 'e-' + decimals);
};


/**
 * Get OS
 *
 * @returns String
 */

const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (/android/i.test(userAgent)) {
        return OS_NAME.android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return OS_NAME.ios;
    }

    return 'unknown';
};

export const DEVICE = {
    android: getMobileOperatingSystem() === OS_NAME.android,
    ios: getMobileOperatingSystem() === OS_NAME.ios,
    isWebview:
        /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent) || typeof JSInterface !== 'undefined',
    isLocalhost: window.location.hostname.startsWith('localhost')
};


export const encryptRequest = (password) => {
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(RSAPublicKey);
    return encrypt.encrypt(password);
}

export const CREDENTIALS = {
    appId: QB_APPID,
    authKey: QB_AUTHKEY,
    authSecret: QB_AUTHSECRET
}

//获取sessionStorage存储数据
export const getCache = name => {
    if (!name) return
    return window.sessionStorage.getItem(name)
}

//获取sessionStorage存储数据
export const getCacheObj = name => {
    if (!name) return
    if (getCache(name)) {
        let cacheData = JSON.parse(getCache(name));
        if (typeof cacheData === 'object') {
            return cacheData
        }
    }
    return '';
}
//设置sessionStorage存储数据
export const setCache = (name, content) => {
    if (!name) return
    if (typeof content !== 'string') {
        content = JSON.stringify(content)
    }
    window.sessionStorage.setItem(name, content)
}
//删除sessionStorage存储数据
export const removeCache = (name) => {
    if (!name) return
    window.sessionStorage.removeItem(name)
}
//获取localStorage存储数据
export const get_local_cache = name => {
    if (!name) return
    return window.localStorage.getItem(name)
}
//设置localStorage存储数据
export const set_local_cache = (name, content) => {
    if (!name) return
    if (typeof content !== 'string') {
        content = JSON.stringify(content)
    }
    window.localStorage.setItem(name, content)
}
//删除localStorage存储数据
export const remove_local_cache = (name) => {
    if (!name) return
    window.localStorage.removeItem(name)
}

export const ShowToast = ($this, title, _position = 'center', _closeTimeout = 2000) => {
    $this.toast.create({
        text: title,
        position: _position,
        closeTimeout: _closeTimeout,
    }).open();
    return true;
}

export const showNotification = ($this) => {
    return $this.notification.create({
        icon: '<i class="icon demo-icon">7</i>',
        title: 'Framework7',
        titleRightText: 'now',
        subtitle: 'Notification with close on click',
        text: 'Click me to close',
        closeOnClick: true,
        on: {
            close: function () {
                $this.dialog.alert('Notification closed');
            },
        },
    });
}

export const showAge = (birth) => {
    let birth_day = moment(birth, 'YYYYMMDD');
    return birth_day.isValid() ? moment().diff(birth_day, 'years') : '-';
}

export const showStatus = (status_code) => {
    if (status_code === 'completed' || status_code === 'paid') {
        return "Paid";
    }

    if (status_code === 'cancel') {
        return "Cancelled";
    }

    if (status_code === 'processing') {
        return "Processing";
    }

    if (status_code === 'confirmed') {
        return "Pending Payment";
    }

    if (status_code === 'pending') {
        return "Pending";
    }

    if (status_code === 'paying') {
        return "Paying";
    }

    if (status_code === 'pick_up') {
        return "Pick Up";
    }

    if (status_code === 'on_route') {
        return "On Route";
    }

    if (status_code === 'delivered') {
        return "Delivered";
    }

    if (status_code === 'NOT_REQUIRED') {
        return "NOT REQUIRED";
    }

    if (status_code === "pending_assign") {
        return "PENDING ASSIGNMENT";
    }

    if (status_code === "no_account_found") {
        return "NO ACCOUNT FOUND";
    }

    if (status_code === "manually_sent") {
        return "MANUALLY SENT";
    }
    if (status_code === "no_show") {
        return "NO SHOW";
    }
    return status_code.toUpperCase()

}

export const showDeliveryStatus = (status_code) => {
    // Pickup, On Route, Completed, Pending
    if (status_code === 'Pickup') {
        return "Pick Up";
    }
    if (status_code === 'On Route') {
        return "On Route";
    }
    if (status_code === 'Completed') {
        return "Completed";
    }
    if (status_code === 'Pending') {
        return "Pending";
    }
    return status_code.toUpperCase()
}

export const showGender = (_gender, dispaly = 'full') => {
    let gender = '';
    if (_gender !== null) {
        gender = _gender === 0 ? 'F' : 'M';
        if (_gender === 'female') {
            if (dispaly === 'full') {
                gender = 'Female';
            } else {
                gender = 'F';
            }

        }
        if (_gender === 'male') {
            if (dispaly === 'full') {
                gender = 'Male';
            } else {
                gender = 'M';
            }
        }
    } else {
        gender = "-"
    };

    return gender;
}

export const showDate = (date, type = '') => {
    if (type === 9) {
        return moment(date).format('DD MMMM YYYY');
    } else if (type === 8) {
        return moment(date).format('HH:mm');
    } else if (type === 7) {
        return moment(date).format('DD MMM H:mm A');
    } else if (type === 6) {
        return moment(date).format('H:mm a');
    } else if (type === 5) {
        return moment(date).format('DD MMM');
    } else if (type === 4) {
        return moment(date).format('dddd');
    } else if (type === 3) {
        return moment(date).format('YYYY-MM-DD H:mm');
    } else if (type === 2) {
        return moment(date).format('YYYY-MM-DD');
    } else if (type === 1) {
        return moment(date).format('H:mm');
    } else {
        return moment(date).format('DD/MM/YYYY');
    }


}

export const getLocalTime = (nS) => {
    var date = new Date(parseInt(nS) * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear();
    var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '/';
    var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '/';
    var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
    var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
    var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

    var strDate = D + M + Y;
    return strDate

}

export const InputBlur = (app, name, obj, type) => {
    var _val = obj.val();
    var result = true;
    var _name = name;
    var fieldName = name.replace(/_/ig, ' ');
    fieldName = fieldName.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());

    if (type === 'time') {
        if (_val !== '' && !justTime(obj)) {
            ShowToast(app, fieldName + ' must be a Time Format.(eg 9:00)');
            obj.val('');
            result = false
            // obj.focus();
        }
    }
    if (type === 'password') {

    }

    if (type === 'decimal') {
        if (_name === 'delivery_time') {
            justDecimal(obj, 1)
            if (!obj.val()) {
                result = false;
            }
        }
    }

    if (type === 'money') {
        justMoney(obj);
        if (!obj.val()) {
            result = false;
        }
    }

    if (type === 'email') {
        if (!justEmail(obj)) {
            ShowToast(app, fieldName + ' must be a ' + type);
            obj.val('');
            result = false;
        }
    }

    if (type === 'postal') {
        if (!justPostalCode(obj)) {
            ShowToast(app, fieldName + ' must be a ' + type);
            obj.val('');
            result = false;
        }
    }

    if (type === 'mobile') {
        if (!justMobileNo(obj)) {
            ShowToast(app, fieldName + ' must be a ' + type);
            obj.val('');
            result = false;
        }
    }

    if (type === 'url') {
        if (!justURL(obj)) {
            ShowToast(app, fieldName + ' must be a ' + type);
            obj.val('');
            result = false;
        }
    }

    if (type === 'number') {
        switch (name) {
            case 'age':
                _val = parseInt(Math.abs(numeral(_val).value()));
                if (!/^\d+$/.test(_val)) {
                    ShowToast(app, fieldName + ' must be a ' + type);
                    obj.val('');
                    result = false;
                } else if (_val < 1 || _val > 120) {
                    ShowToast(app, fieldName + ' must be  [1, 120] years old!');
                    obj.val('');
                    result = false;
                } else {
                    obj.val(_val + ' years old');
                }
                break;
            case 'height':
                _val = Math.round(Math.abs(numeral(_val).value()) * 100) / 100;
                if (_val < 50 || _val > 238) {
                    ShowToast(app, fieldName + ' must be  [50, 238] cm!');
                    obj.val('');
                    result = false;
                } else {
                    obj.val(_val + ' cm');
                }
                break;
            default:
                if (justInt(obj)) {
                    ShowToast(app, fieldName + ' must be a ' + type);
                    obj.val('');
                    result = false;
                }
                break;
        }
    }
    return result;
}

export const InputFoucs = (app, name, obj, type) => {
    var _val = obj.val();
    if (type === "number") {
        _val = Math.abs(numeral(_val).value()) ? Math.abs(numeral(_val).value()) : '';
        obj.val(_val);
    }
}

function justInt(obj) {
    var re = /[^0-9]/g;
    return re.test(obj.val());
}

export const justEmail = (obj) => {
    console.log(obj)
    console.log(obj.val())
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(obj.val()).toLowerCase());
}

export const formatEmail = (val) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(val).toLowerCase());
}

function justMobileNo(obj) {
    var reg = /^(8|9)\d{7}$/;
    return reg.test(obj.val());
}

function justURL(obj) {
    //判断URL地址的正则表达式为:http(s)?://([\w-]+\.)+[\w-]+(/[\w- ./?%&=]*)?
    //下面的代码中应用了转义字符"\"输出一个字符"/"
    var Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
    var objExp = new RegExp(Expression);
    return objExp.test(obj.val());

}
// function justIDNo(obj) {
//     var reg = /^[ST][0-9]{7}[JZIHGFEDCBA]$/;
//     return reg.test($(obj).val());
// }
// function justFIN(obj) {
//     var reg = /^[FG][0-9]{7}[XWUTRQPNMLK]$/;
//     return reg.test($(obj).val());
// }
function justPostalCode(obj) {
    var reg = /^[0-9]{6}$/;
    return reg.test(obj.val());
}

// function upperCase(obj) {
//     $(obj).val($(obj).val().toUpperCase());
// }

// function justUEN(obj) {
//     return validateUEN($(obj).val());
// }
export function justFloat(str) {
    var reg = /^(-?\d+)(\.\d+)?$/;
    return reg.test(str);
}
function justTime(obj) {
    var reg = /^((([0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])|(24:00))$/;
    return reg.test(obj.val());
}

function justMoney(obj) {
    var n = obj.val();
    n = n.replace(/[^\d.]/g, "");
    n = n.replace(/\.{2,}/g, ".");
    n = n.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    n = n.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');
    if (n.indexOf(".") < 0 && n != "") {
        n = parseFloat(n);
    } else {
        // n = 0;
    }

    if (n === 0) {
        obj.val('0.00');
    } else if (n === '') {
        obj.val('');
    } else {
        obj.val(parseFloat(n).toFixed(2));
    }
}

function justDecimal(obj, digits = 2) {
    var n = obj.val();
    n = n.replace(/[^\d.]/g, "");
    n = n.replace(/\.{2,}/g, ".");
    n = n.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    n = n.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');

    if (n.indexOf(".") < 0 && n != "") {
        n = parseFloat(n);

    } else {
        // n = 0
    }

    if (n === "") {
        obj.val('');
    } else {
        obj.val(parseFloat(n).toFixed(digits));
    }


}
// function moneyFormat(obj) {
//     var n = obj.value;
//     if (n === '') {
//         // n='0.00';
//     } else {
//         var xsd = n.toString().split(".");
//         if (xsd.length == 1) {
//             n = '$' + n.toString() + ".00";
//         }
//         if (xsd.length > 1) {
//             if (xsd[0] == '') {
//                 xsd[0] = '0';
//                 n = xsd[0] + n.toString();
//             }
//             if (xsd[1] == '') {
//                 n = n.toString() + "00";
//             } else if (xsd[1].length < 2) {
//                 n = n.toString() + "0";
//             } else if (xsd[1].length > 2) {
//                 n = xsd[0] + '.' + xsd[1].substring(0, 2);
//             }
//         }
//         var str = n.substring(0, 1);
//         if (n !== '' && str !== '$') {
//             n = '$' + n;
//         }
//         $(obj).val(n);
//     }
// }

// function moneyFormatV2(num) {
//     num = num.toString().replace(/\$|\,/g, '');
//     if (isNaN(num))
//         num = "0";
//     sign = (num == (num = Math.abs(num)));
//     num = Math.floor(num * 100 + 0.50000000001);
//     cents = num % 100;
//     num = Math.floor(num / 100).toString();
//     if (cents < 10)
//         cents = "0" + cents;
//     for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
//         num = num.substring(0, num.length - (4 * i + 3)) + ',' +
//             num.substring(num.length - (4 * i + 3));
//     return (((sign) ? '' : '-') + num + '.' + cents);
// }
// function moneyFormatV3(s, n) {//zqy s:(float)1111111.4567    n=2: return->1,111,111.47
//     var k = s.charAt(0);
//     if (k === '-') {
//         s = s.replace('-', '');
//     }
//     n = Number(n) > 0 && Number(n) <= 20 ? Number(n) : 0;
//     s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
//     var l = s.split(".")[0].split("").reverse(),
//         r = s.split(".")[1];
//     t = "";
//     for (i = 0; i < l.length; i++) {
//         t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
//     }
//     if (n > 0) {
//         var num = t.split("").reverse().join("") + "." + r;
//     } else {
//         var num = t.split("").reverse().join("");
//     }
//     if (k === '-') {
//         num = '-' + num;
//     }
//     return num;

// }
// function moneyFormatReturn(s) {
//     return parseFloat(s.replace(/[^\d\.-]/g, ""));
// }

// function floatFormat(obj) {
//     if (!justFloat(obj)) $(obj).val('');
//     var n = obj.value;
//     if (n !== '') {
//         var xsd = n.toString().split(".");
//         if (xsd.length == 1) {
//             n = n.toString() + ".00";
//         }
//         if (xsd.length > 1) {
//             if (xsd[0] == '') {
//                 xsd[0] = '0';
//                 n = xsd[0] + n.toString();
//             }
//             if (xsd[1] == '') {
//                 n = n.toString() + "00";
//             } else if (xsd[1].length < 2) {
//                 n = n.toString() + "0";
//             } else if (xsd[1].length > 2) {
//                 n = xsd[0] + '.' + xsd[1].substring(0, 2);
//             }
//         }
//         $(obj).val(n);
//     }
// }

export const VerificationFields = (_this, app, requiredFields) => {
    var noError = true;
    if (requiredFields.length > 0) {
        requiredFields.map((name, index) => {
            var fieldName = name.replace(/_/ig, ' ');
            fieldName = fieldName.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase());
            if (!GetValue(app, fieldName, _this.$$("input[name='" + name + "']"), 'text', true)) {
                noError = false;
            }
        });
    }
    return noError;
}

export const GetValue = (app, name, obj, type, required) => {
    var _val = obj.val();
    if (type === "number") {
        switch (name) {
            case 'age':
                _val = Math.abs(numeral(_val).value()) ? parseInt(Math.abs(numeral(_val).value())) : '';
                if (required && _val === '' || _val === 0) {
                    ShowToast(app, name + ' is required !');
                    // obj.focus();
                    return false;
                }
                break;
            case 'height':
                _val = Math.round(Math.abs(numeral(_val).value()) * 100) / 100;
                if (required && _val === '' || _val === 0) {
                    ShowToast(app, name + ' is required !');
                    // obj.focus();
                    return false;
                }
                break;
            case 'clinic_contact_no':
                if (required && _val === '' || _val === 0) {
                    ShowToast(app, name + ' is required !');
                    // obj.focus();
                    return false;
                }
                break;
            default:
                if (required && _val === '' || _val === 0) {
                    ShowToast(app, name + ' is required !');
                    // obj.focus();
                    return false;
                }
                break;
        }
    } else {

        if (required && _val === '' && _val.trim() === '') {
            ShowToast(app, name + ' is required !');
            return false;
        }
    }


    return _val;
}

export const momentMsgFormat = (created_at) => {

    let timestr = moment(created_at).startOf('day').fromNow()
    let unixtime = moment().unix() * 1000
    let odate = new Date(new Date().setHours(0, 0, 0, 0)).getTime() // 当天0点

    // 大于3分钟
    if (unixtime - created_at >= (3 * 60 * 1000)) {
        // 大于今天的0点，即为今天的时间
        // if (created_at >= odate) {
            timestr = moment(created_at).format('h:mm') + ' ' + moment(created_at).format('a');
        // } else if (moment(created_at).year() === moment().year()) {
        //     timestr = moment(created_at).format('DD/MM h:mm a')
        // } else {
        //     timestr = moment(created_at).format('DD/MM/YYYY h:mm a')
        // }
    } else {
        timestr = 'just';
    }
    return timestr;

    // return moment(created_at).format("YYYY-MM-DD h:mm a");
}

export const TimestampFormat = (timestamp) => {
    function zeroize(num) {
        return (String(num).length == 1 ? '0' : '') + num;
    }

    var curTimestamp = parseInt(new Date().getTime() / 1000); //当前时间戳
    var timestampDiff = curTimestamp - timestamp; // 参数时间戳与当前时间戳相差秒数

    var curDate = new Date(curTimestamp * 1000); // 当前时间日期对象
    var tmDate = new Date(timestamp * 1000);  // 参数时间戳转换成的日期对象

    var Y = tmDate.getFullYear(), m = tmDate.getMonth() + 1, d = tmDate.getDate();
    // var H = tmDate.getHours(), i = tmDate.getMinutes(), s = tmDate.getSeconds();

    if (timestampDiff < 60) { // 一分钟以内
        return "Just";
        // } else if (timestampDiff < 3600) { // 一小时前之内
        // return Math.floor(timestampDiff / 60) + " minutes ago";
        // return "An hour ago";
    } else if (curDate.getFullYear() === Y && curDate.getMonth() + 1 === m && curDate.getDate() === d) {
        // return 'Today' + zeroize(H) + ':' + zeroize(i);
        return 'Today';
    } else {
        // 获取当天 0 点的时间戳
        // var timeStamp = new Date(new Date().setHours(0, 0, 0, 0)) / 1000;
        var timeStamp = new Date(new Date(new Date().toLocaleDateString()).getTime()) / 1000;
        // 一天是86400秒   故 7 天前的时间戳为
        var SevenDayAgo = timeStamp - 86400 * 6;
        var newDate = new Date((curTimestamp - 86400) * 1000); // 参数中的时间戳加一天转换成的日期对象
        if (newDate.getFullYear() === Y && newDate.getMonth() + 1 === m && newDate.getDate() === d) {
            // return '昨天' + zeroize(H) + ':' + zeroize(i);
            return 'Yesterday';
        } else if(SevenDayAgo < timestamp){
            let day = tmDate.getDay();
            let text = '';
            switch (day) {
                case 0:
                    text = "Sunday";
                    break;
                case 1:
                    text = "Monday";
                    break;
                case 2:
                    text = "Tuesday";
                    break;
                case 3:
                    text = "Wednesday";
                    break;
                case 4:
                    text = "Thursday";
                    break;
                case 5:
                    text = "Friday";
                    break;
                case 6:
                    text = "Saturday";
                    break;
            }
            return text;
        }
        // else if (curDate.getFullYear() === Y) {
        //     // return zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
        //     return zeroize(d) + '/' + zeroize(m);
        // }
         else {
            // return Y + '年' + zeroize(m) + '月' + zeroize(d) + '日 ' + zeroize(H) + ':' + zeroize(i);
            return zeroize(d) + '/' + zeroize(m) + '/' + Y;
        }
    }
}


/**
 * GetArrNum
 * @param  {Number} max
 * @param  {Number} min
 * @return {Array} Array after transform
 */
export const GetArrNum = (min = 0, max) => {
    let arr = [];
    let num = min;
    while (num <= max) {
        arr.push(num);
        num += 1;
    }
    return arr;
};

/**
 * GetArrMiddleNum
 * @param  {Array} array
 * @return {Array} Array after transform
 */
export const GetArrMiddleNum = (array) => {
    if (array.length > 0) {
        let index = Math.ceil(array.length / 2);
        return array[index];
    }
    return false;
};


/**
 * GetMinMaxPressure
 * @function
 * @param {any} [data=[]]
 * @param {any} [external=[]]
 * @returns {JSX.Element}
 */
export const GetMinMaxPressure = (data = [], external = []) => {
    let numbers = [];
    data.forEach((item) => {
        let { morning, evening } = item;
        numbers.push(
            morning ? morning.systolic : 0,
            morning ? morning.diastolic : 0,
            evening ? evening.systolic : 0,
            evening ? evening.diastolic : 0
        );

    });

    numbers = [...numbers];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));

    if (!numbers.length) {
        numbers = [60, 200];
    }

    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));

    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;
    return {
        minValue,
        maxValue
    };
};

/**
 * GetMinMaxPulse
 * @function
 * @param {any} [data=[]]
 * @param {any} [external=[]]
 * @returns {Object}
 */
export const GetMinMaxPulse = (data = [], external = []) => {
    let numbers = [];
    data.forEach((item) => {
        let { morning, evening } = item;
        numbers.push(
            morning ? morning.pulse : 0,
            evening ? evening.pulse : 0
        );

    });

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));

    if (!numbers.length) {
        numbers = [40, 160];
    }
    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };
};


/**
* GetMinMaxBloodGlucose
* @function
* @param {any} [data=[]]
* @param {any} [external=[]]
* @returns {Object}
*/
export const GetMinMaxBloodGlucose = (data = {}, external = []) => {
    let numbers = [];
    Object.keys(data).forEach((item) => {
        let am = data[item] && data[item].am !== undefined ? data[item].am : 0;
        let pm = data[item] && data[item].pm !== undefined ? data[item].pm : 0;
        numbers.push(
            am ? am : 0,
            pm ? pm : 0
        );
    });

    numbers = [...numbers];

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    if (!numbers.length) {
        numbers = [4.0, 14.0];
    }

    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };
}

export const GetMinMaxSleep = (data = [], external = []) => {
    let numbers = [];
    Object.keys(data).forEach((item) => {
        let duration = data[item] && data[item].duration !== undefined ? data[item].duration / 60 : 0;
        numbers.push(duration);
    });

    numbers = [...numbers];

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    if (!numbers.length) {
        numbers = [0, 24];
    }

    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };
}

export const GetMinMaxStep = (data = [], external = []) => {
    let numbers = [];
    Object.keys(data).forEach((item) => {
        let step = data[item] && data[item].step !== undefined ? data[item].step : 0;
        numbers.push(step);
    });

    numbers = [...numbers];

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    if (!numbers.length) {
        numbers = [0, 100000];
    }

    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };
}

/**
 * GetMinMaxBMI
 * @function
 * @param {any} [data=[]]
 * @param {any} [external=[]]
 * @returns {Object}
 */
export const GetMinMaxBMI = (data = [], external = []) => {
    let numbers = [];
    data.forEach((item) => {
        let { morning, evening } = item;
        numbers.push(
            morning ? morning.bmi : 0,
            evening ? evening.bmi : 0
        );
    });

    numbers = [...numbers];

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    console.log(numbers)
    if (!numbers.length) {
        numbers = [0, 40];
    }
    console.log(numbers,'numbers')
    numbers = [...numbers, ...external];
    console.log(numbers,'numbers1')
    numbers = numbers.filter((num) => !isNaN(Number(num)));
    console.log(numbers, 'numbers2')
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);
    console.log(minValue, maxValue, numbers)
    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };

}

/**
 * GetMinMaxWeight
 * @function
 * @param {any} [data=[]]
 * @param {any} [external=[]]
 * @returns {Object}
 */
export const GetMinMaxWeight = (data = [], external = []) => {
    let numbers = [];
    data.forEach((item) => {
        let { morning, evening } = item;
        numbers.push(
            morning ? morning.weight : 0,
            evening ? evening.weight : 0
        );
    });

    numbers = [...numbers];

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    if (!numbers.length) {
        numbers = [40, 100];
    }
    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };
};

/**
 * GetMinMaxTemperature
 * @function
 * @param {any} [data=[]]
 * @param {any} [external=[]]
 * @returns {Object}
 */
export const GetMinMaxTemperature = (data = [], external = []) => {
    let numbers = [];
    Object.keys(data).forEach((item) => {
        let am = data[item] && data[item].am !== undefined ? data[item].am : 0;
        let pm = data[item] && data[item].pm !== undefined ? data[item].pm : 0;
        numbers.push(
            am ? am : 0,
            pm ? pm : 0
        );
    });

    numbers = [...numbers];

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    if (!numbers.length) {
        numbers = [35.5, 45];
    }

    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };
};

/**
 * GetMinMaxSpo2
 * @function
 * @param {any} [data=[]]
 * @param {any} [external=[]]
 * @returns {Object}
 */
export const GetMinMaxSpo2 = (data = [], external = []) => {
    let numbers = [];
    Object.keys(data).forEach((item) => {
        let am = data[item] && data[item].am !== undefined ? data[item].am : 0;
        let pm = data[item] && data[item].pm !== undefined ? data[item].pm : 0;
        numbers.push(
            am ? am : 0,
            pm ? pm : 0
        );
    });

    numbers = [...numbers];

    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    if (!numbers.length) {
        numbers = [95, 100];
    }

    numbers = [...numbers, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);

    minValue = Math.floor(minValue / 10) * 10;
    maxValue = Math.ceil(maxValue / 10) * 10;

    return {
        minValue,
        maxValue
    };
};

export const GetConvertDates = (startdate, enddate) => {
    let everyDaysDisplay = [];
    var sd = moment(startdate);
    var ed = moment(enddate);

    for (var m = moment(sd); m.diff(ed, 'days') <= 0; m.add(1, 'days')) {
        if (moment(m).year() === moment().year()) {
            everyDaysDisplay.push(m.format('MMM DD'));
        } else {
            everyDaysDisplay.push(m.format('MMM DD YYYY'));
        }
    }

    return everyDaysDisplay;
}

export const GetSomeDaysRangeDate = (date, days = 7, type = 'after') => {
    if (type == 'before') {
        return moment(date).subtract('days', days).format('YYYY-MM-DD')
    } else {
        return moment(date).add(days, 'days').format('YYYY-MM-DD');
    }

}

export const formateTime = (t = 0) => {
    let result = parseInt(t)
    let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
    let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
    let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
    return `${h}:${m}:${s}`;
}

export const DispalyDateTime = (date, type = 1, timestamp = false) => {
    if (timestamp) {
        date = moment.unix(date);
    }
    if (type === 1) {
        return moment(date).format('DD MMM YY h:mm A')
    }

    if (type === 2) {
        return {
            date: moment(date).format('DD MMM YYYY'),
            time: moment(date).format('h:mm A'),
        }
    }
}
// 这个方法只使用于graph 
export const FirstCaseUpper = (str) => {
    if (str === 'unknown') str = 'other'
    return str.toLowerCase().replace(/( |^)[a-z]/g, (L) => L.toUpperCase()); // 执行代码
}

export const CheckTimeSlots = (time1, time2, time3 = '', time4 = '') => {
    let formats = ["YYYY-MM-DD LT", "YYYY-MM-DD h:mm:ss A", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"];
    let newT1 = moment().format('YYYY-MM-DD') + ' ' + ([...time1].length === 4 ? '0' + time1 : time1);
    let newT2 = moment().format('YYYY-MM-DD') + ' ' + ([...time2].length === 4 ? '0' + time2 : time2);

    if (time1 && time2 && time3 && time4) {

        let newT3 = moment().format('YYYY-MM-DD') + ' ' + ([...time3].length === 4 ? '0' + time3 : time3);
        let newT4 = moment().format('YYYY-MM-DD') + ' ' + ([...time4].length === 4 ? '0' + time4 : time4);
        if (
            moment(newT1, formats, true).isValid() &&
            moment(newT2, formats, true).isValid() &&
            moment(newT3, formats, true).isValid() &&
            moment(newT4, formats, true).isValid()
        ) {

            if (moment(newT1).isBefore(newT2)) {
                if (moment(newT2).isBefore(newT3)) {
                    if (moment(newT3).isBefore(newT4)) {
                        return true;
                    }
                }
            }
        }
    }

    if (time1 && time2) {
        if (
            moment(newT1, formats, true).isValid() &&
            moment(newT2, formats, true).isValid()
        ) {
            if (moment(newT1).isBefore(newT2)) {
                return true;
            }
        }
    }



    return false;

}

export const _splitTime = (startTime, endTime, duration) => {

    let addMins = duration * 60;
    let returnArray = [];
    startTime = moment().format('YYYY-MM-DD') + ' ' + ([...startTime].length === 4 ? '0' + startTime : startTime);
    endTime = moment().format('YYYY-MM-DD') + ' ' + ([...endTime].length === 4 ? '0' + endTime : endTime);

    startTime = moment(startTime).valueOf() / 1000;
    endTime = moment(endTime).valueOf() / 1000;


    while (startTime <= endTime) {
        let arr = {};
        arr.slot = moment(startTime * 1000).format('H:mm');
        arr.duration = duration;
        startTime += addMins;
        returnArray.push(arr);
    }

    return returnArray;
}

export const _explodeTimeSlot = (timeslot, duration) => {
    let slots = [];
    timeslot.data.map(i => slots = slots.concat(_splitTime(i.start, i.end, duration)))
    return slots;
}

export const getSlotTimes = (timeslots, duration, lead_time) => {
    let res = [];
    timeslots = timeslots.length > 0 ? timeslots : JSON.parse(timeslots);
    timeslots.map((item, index) => {
        let obj = {};
        let slorts = _explodeTimeSlot(item, duration, lead_time);
        obj.slorts = slorts;
        obj.weekday = item.day;
        res.push(obj)
    })
    return res;
}

export const diffDateTime = (day1, day2) => {
    if (moment(day1).isBefore(day2)) {
        return true;
    } else {
        return false;
    }
}

export const getTimestamp = (type = 1) => {
    if (type == 1) {
        return moment().unix();
    }
}

export const playAudio = (audioID, loop = true) => {
    var audio = document.getElementById(audioID);
    if (audio !== null) {
        if (audio.paused) {
            audio.play();// 播放
            document.getElementById(audioID).loop = loop;
        }
    }
}

export const closeAudio = (audioID, loop = false) => {
    var audio = document.getElementById(audioID);
    if (audio !== null) {
        if (!audio.paused) {
            audio.pause();// 播放
            audio.load();
            document.getElementById(audioID).loop = loop;
        }
    }
}
export const currentUtcTime = () => {
    return moment.utc().format("X").valueOf();
}

export const checkValidTimeSlots = (timeSlot) => {
    if (timeSlot.match(/^([0-1]?[0-9]|2[0-3]):?[0-5][0-9]$/g)) {
        let _timeStamp = moment(convertTimeSlots(timeSlot)).format("X").valueOf()
        let startTimeStamp = moment(convertTimeSlots('00:00')).format("X").valueOf()
        let entTimeStamp = moment(convertTimeSlots('23:59')).format("X").valueOf()
        if (parseInt(_timeStamp) >= parseInt(startTimeStamp) && parseInt(_timeStamp) <= parseInt(entTimeStamp)) {
            return timeSlot
        }
    }
    return false
}

/**
 * Convert TimeSlot to TimeStamp
 * @param {*} timeSlot1 
 */
export const convertTimeStamp = (timeSlot1) => {
    let _timeSlot1 = convertTimeSlots(timeSlot1)
    let _timeStamp1 = moment(_timeSlot1).format("X").valueOf()
    return parseInt(_timeStamp1);
}
/**
 * return (timeSlot1 < timeSlot2)
 * @param {*} timeSlot1 
 * @param {*} timeSlot2 
 */
export const CompareTime = (timeSlot1, timeSlot2) => {
    let _timeSlot1 = convertTimeSlots(timeSlot1)
    let _timeSlot2 = convertTimeSlots(timeSlot2)
    let _timeStamp1 = moment(_timeSlot1).format("X").valueOf()
    let _timeStamp2 = moment(_timeSlot2).format("X").valueOf()
    if (parseInt(_timeStamp1) >= parseInt(_timeStamp2)) {
        return false
    } else {
        return true
    }
}

export const convertTimeSlots = (timeSlots) => {
    let formats = ["YYYY-MM-DD LT", "YYYY-MM-DD h:mm:ss A", "YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"]
    let time = moment().format('YYYY-MM-DD') + ' ' + ([...timeSlots].length === 4 ? '0' + timeSlots : timeSlots)

    if (moment(time, formats, true).isValid()) {
        return time
    } else {
        return false
    }
}

export const parseTime = (time, cFormat = '') => {

    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if (('' + time).length === 10) time = parseInt(time) * 1000
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1]
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

export const insertStr = (soure, start, newStr) => {

    return soure.slice(0, start) + newStr + soure.slice(start);
}

export const convertImgToBase64 = (url, callback, outputFormat) => {
    var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'),
        img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL(outputFormat || 'image/png');
        callback.call(this, dataURL);
        canvas = null;
    };
    img.src = url;
}

export const currencyType = (_value, _code) => {
    return currencyFormatter.format(_value, { code: _code });
}

export const isInvalid = (val) => {
    if (typeof val === 'undefined' || val === undefined) {
        return true
    } else {
        return false
    }
}

export const isFunction = (functionToCheck) => {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}

export const dateOperator = (date, days, operator) => {
    date = date.replace(/-/g, "/"); //更改日期格式
    var nd = new Date(date);
    nd = nd.valueOf();
    if (operator == "+") {
        nd = nd + days * 24 * 60 * 60 * 1000 - 1;
    } else if (operator == "-") {
        nd = nd - days * 24 * 60 * 60 * 1000;
    } else {
        return false;
    }
    nd = new Date(nd);

    var y = nd.getFullYear();
    var m = nd.getMonth() + 1;
    var d = nd.getDate();
    if (m <= 9) m = "0" + m;
    if (d <= 9) d = "0" + d;
    // var cdate = y + "/" + m + "/" + d;
    var cdate = d + "/" + m + "/" + y;
    return cdate;
}

export const OperatorDay = (date1, date2) => {
    date1 = date1.replace(/-/g, "")
    date2 = date2.replace(/-/g, "")
    let iDays = parseInt((date1 - date2) / 1000 / 60 / 60 / 24) + 1;
    return iDays
}

export const checkInRange = (number, external) => {
    let numbers = [number, ...external];
    numbers = numbers.filter((num) => !!num && !isNaN(Number(num)));
    let minValue = Math.min(...numbers);
    let maxValue = Math.max(...numbers);
    console.log(minValue, maxValue);
    if (number > minValue && number < maxValue) {
        return true
    } else {
        return false
    }
};

export const MoneyToFixed = (obj) => {

    return parseFloat(obj).toFixed(2)

}

export const showTime = (tempTime) => {

    if (tempTime !== '') {
        let _tempTime = tempTime.split(':')
        if (_tempTime[0] >= 0 && 12 >= _tempTime[0]) {
            if (_tempTime.length === 2) {
                let tempTimeStr24 = parseInt(_tempTime[0]) + parseInt(12) + ":" + _tempTime[1]
                console.log(tempTime, _tempTime, _tempTime.length, tempTimeStr24)

                return tempTimeStr24
            } else if (_tempTime.length === 1) {
                let tempTimeStr24 = parseInt(_tempTime[0]) + parseInt(12) + ":00"
                console.log(tempTime, _tempTime, _tempTime.length, tempTimeStr24)
                return tempTimeStr24
            }

        } else if (_tempTime[0] > 12 && 24 >= _tempTime[0]) {

            if (_tempTime.length === 2) {
                console.log(tempTime)
                return tempTime
            } else if (_tempTime.length === 1) {
                if (_tempTime[0].length === 2) {
                    let tempTime = _tempTime[0] + ":00"
                    console.log(_tempTime[0], _tempTime[0].length)
                    return tempTime
                } else if (_tempTime[0].length === 1) {
                    let tempTime = "0" + _tempTime[0] + ":00"
                    console.log(_tempTime[0], _tempTime[0].length)
                    return tempTime
                }
            }
        } else {
            return ''
        }
    }

}


export const showTimeReverse = (tempTime) => {

    if (tempTime !== '') {
        let _tempTime = tempTime.split(':')

        if (_tempTime[0] > 12 && 24 >= _tempTime[0]) {
            if (_tempTime.length === 2) {
                let tempTimeStr12 = parseInt(_tempTime[0]) - parseInt(12) + ":" + _tempTime[1]
                return tempTimeStr12

            } else if (_tempTime.length === 1) {
                let tempTimeStr12 = parseInt(_tempTime[0]) + parseInt(12) + ":00"
                console.log(tempTime, _tempTime, _tempTime.length, tempTimeStr12)
                return tempTimeStr12
            }
        } else if (_tempTime[0] >= 0 && 12 >= _tempTime[0]) {
            if (_tempTime.length === 2) {
                console.log(tempTime)
                return tempTime
            } else if (_tempTime.length === 1) {

                if (_tempTime[0].length === 2) {
                    let tempTime = _tempTime[0] + ":00"
                    console.log(_tempTime[0], _tempTime[0].length)
                    return tempTime
                } else if (_tempTime[0].length === 1) {
                    let tempTime = "0" + _tempTime[0] + ":00"
                    console.log(_tempTime[0], _tempTime[0].length)
                    return tempTime
                }

            }
        } else {
            return ''
        }
    }
}

export const isImgType = (type) => {
    if (typeof type === "undefined" || type === undefined) return false;
    let filleArr =  type.split('/');
    let suffix = filleArr[filleArr.length -1];
    if(suffix !== ''){
        suffix =  suffix.toLocaleLowerCase()
    }
    return [
      "png",
      "jpg",
      "jpeg",
      "bmp",
      "gif",
      "webp",
      "psd",
      "svg",
      "tiff",
    ].includes(suffix);
}
export const isPdfType = (type) => {
    if (typeof type === "undefined" || type === undefined) return false;
    let filleArr =  type.split('/');
    let suffix = filleArr[filleArr.length -1];
    if(suffix !== ''){
        suffix =  suffix.toLocaleLowerCase()
    }
    return [
      "pdf"
    ].includes(suffix);
}
export const isMessageType = (type) => {
    if (typeof type === "undefined" || type === undefined) return false;
    let filleArr =  type.split('/');
    let suffix = filleArr[filleArr.length -1];
    if(suffix !== ''){
        suffix =  suffix.toLocaleLowerCase()
    }
    return [
      "pdf",
      "png",
      "jpg",
      "jpeg",
      "bmp",
      "gif",
      "webp",
      "psd",
      "svg",
      "tiff",
    ].includes(suffix);
}
// 数组是否有交集
export const isIntersect = (arr1, arr2) => {
    let start = [Math.min(...arr1), Math.min(...arr2)];
    let end = [Math.max(...arr1), Math.max(...arr2)];
    return Math.max(...start) < Math.min(...end);
}

// 正数,小数点后做多dots位
export const getValdots = (params, dots = 1) => {
    params = params.replace(/[^\d.]/g, "");
    params = params.replace(/^\./g, "");
    params = params.replace(/\.{2,}/g, ".");
    params = params.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
    let str = "^(\\-)*(\\d+)\\.(";
    for (let index = 0; index < dots; index++) {
      str += "\\d";
    }
    str += ").*$";
    params = params.replace(new RegExp(str), "$1$2.$3");
    return params;
  };