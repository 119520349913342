import React, { Component } from 'react'
import {
    f7 as app,
    List,
    Input,
    Page,
    Button,
    LoginScreenTitle,
    Row,
    Col,
    View,
    Link
} from 'framework7-react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { encryptRequest, ShowToast, setCache, removeCache, get_local_cache } from '../commons/Helpers';
import { ROUTES, APPENV } from '../commons/Constants';
import { UserLogin, otpLogin } from '../redux/actions'
import AuthGuard from '../services/AuthGuard';
import Logo from '../images/icons/icon_HV_Long.png'
import cceye from '../images/icons/cceye.png';
import eye from '../images/icons/eye.png'
import ReCAPTCHA from "react-google-recaptcha"
import sha256 from "sha256";
import { v4 as uuidv4 } from 'uuid';
import events from '../services/events';
const recaptchaRef = React.createRef();

export class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            token: '',
            userType: '',
            pageFrom: '',
            username: '',
            password: '',
            otpCode1: '',
            otpCode2: '',
            otpCode3: '',
            otpCode4: '',
            otpCode5: '',
            otpCode6: '',
            focusCode: '',
            actionKey: 'input',
            UNAutofocus: false,
            PWAutofocus: false,
            sendOtp: false,
            recaptcha_verify: false,
            flag: 0,
            eyeUrl: eye,
            seconds: 60,
            otpCodeVal: '',
            one_time_password: '',
            isEmailFlag: true,
        }
        this._handleKeyDown = this._handleKeyDown.bind(this);
    }

    componentDidMount() {
        window.addEventListener('keydown', this._handleKeyDown, false);

    }


    _handleKeyDown = (e) => {
        const key = e.key;
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this._handleKeyDown);

    }

    componentWillReceiveProps(props) {
        // console.log(props);
        let self = this;
        app.preloader.hide();

        if (props.userLoginData !== undefined && props.userLoginData !== null) {
            if (
                props.userLoginData.token !== undefined &&
                props.userLoginData.token !== null
            ) {
                AuthGuard.SetAuthenticated(props.userLoginData);
                events.emit('loginSuccess');
                setTimeout(() => {
                    setCache('drInfoUpdated', '')
                    setCache('clinicInfoUpdated', '')
                    AuthGuard.DrDevice = null;
                    AuthGuard.newDrDevice = null;
                    AuthGuard.allDrInfo = null;
                    AuthGuard.ChatDeviceInfo = [];
                    // AuthGuard.initNewDrE3kit();
                    if (AuthGuard.isDoctor() && !AuthGuard.isDoctorProfileComplete()) {
                        app.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'myProfileTab' } })
                    } else if (AuthGuard.isAdmin() && !AuthGuard.isClinicProfileComplete()) {
                        app.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'clinicDetailsTab' } })
                    } else {
                        self.goToVideoCallPage();
                    }
                }, 100);
            } else {
                // this.loginFail(props.userLoginData.message);
                this.checkOtpFail(props.userLoginData.message);
            }
        }
    }

    goToVideoCallPage() {
        this.$$('.message').html('');
        window.removeEventListener("keydown", this._handleKeyDown, false);
        setTimeout(() => {
            if (AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() || AuthGuard.CanViewHistoryVideoCallList() || AuthGuard.CanEditConsultationSummary()) {
                app.views.main.router.navigate(ROUTES.appointments)
            } else {
                app.views.main.router.navigate(ROUTES.patientlist)
            }
        }, 100);
    }

    goToCheckOTP() {
        let self = this;
        this.$$('.message').html('');
        this.setState({
            sendOtp: true
        }, () => {
            self.$$("input[name='otpCode1']").focus();
        })

    }

    checkOtpFail(msg) {
        this.$$('.otpErr').html(msg);
        this.setState({
            otpCode1: '',
            otpCode2: '',
            otpCode3: '',
            otpCode4: '',
            otpCode5: '',
            otpCode6: '',
        });

    }

    loginFail(msg) {
        let { message } = msg;
        this.$$('.message').html(message);
        this.$$('.username input').val('');
        this.$$('.password input').val('');
        this.setState({
            username: '',
            password: '',
        });
    }

    getNonceNum(resolve, reject, params) {
        AuthGuard.getNonceNum(params, (isOk, res) => {
            if (isOk) {
                resolve(res)
            } else {
                reject(false)
            }
        })
    }

    verifyNonceNum(resolve, reject, params) {
        AuthGuard.verifyNonceNum(params, (isOk, res) => {
            if (isOk) {
                resolve(true)
            } else {
                reject(false)
            }
        })
    }

    sendOtp(resolve, reject, params) {
        console.log(params)
        AuthGuard.sendOtp(params, (isOk, res) => {
            if (isOk) {
                resolve(res);

            } else {
                reject(false)
            }
        })

    }

    btnlogin() {
        let self = this;
        app.preloader.show();
        if (!this.state.username || !this.state.password) {
            this.$$('.message').html('Please input Email and Password.');
            app.preloader.hide();
        } else {
            let userID = this.state.username
            let pwd = encryptRequest(this.state.password)
            let request_id = uuidv4()
            let params = { 'request_id': request_id }
            let nonce_num = '';
            new Promise((resolve, reject) => { this.getNonceNum(resolve, reject, params) }).then(data => {
                nonce_num = data
                let sign = sha256(userID + pwd + nonce_num)
                params['userID'] = userID
                params['password'] = pwd
                params['sign'] = sign
                return new Promise((resolve, reject) => { this.verifyNonceNum(resolve, reject, params) })
            }).then(data => {
                if (data) {
                    let params = { 'userID': userID, password: pwd }
                    AuthGuard.sendOtp(params, (isOk, res) => {
                        if (isOk) {
                            if (res.one_time_password == 'Mobile') {
                                this.setState({
                                    one_time_password: 'SMS'
                                })
                            } else {
                                this.setState({
                                    one_time_password: 'EMAIL'
                                })
                            }

                            console.log(res.one_time_password)

                            self.goToCheckOTP();

                            let timer = setInterval(() => {
                                this.setState((preState) => ({
                                    seconds: preState.seconds - 1,
                                }), () => {
                                    if (this.state.seconds == 0) {
                                        clearInterval(timer);
                                    }
                                });
                            }, 1000)

                        } else {
                            self.loginFail(res);
                        }
                        app.preloader.hide();
                    });
                }
            })
        }
    }

    changePwBtn() {
        app.views.main.router.navigate(ROUTES.forgetPassword, {
            reloadCurrent: true,
            ignoreCache: true,
            history: false
        })
    }

    verifyOtp() {
        let otpCode = this.state.otpCode1 + this.state.otpCode2 + this.state.otpCode3 + this.state.otpCode4 + this.state.otpCode5 + this.state.otpCode6;
        if (otpCode.length === 6) {
            app.preloader.show();
            // this.props.otpLogin(this.state.username, 'EMAIL', otpCode);
            console.log(this.props)
        }
    }

    inputFoucs(name, type = 'add') {
        if (type == 'add') {
            if (this.state.actionKey === 'del') return;
            if (name === 'otpCode1') {
                this.$$("input[name='otpCode2']").focus();
            }

            if (name === 'otpCode2') {
                this.$$("input[name='otpCode3']").focus();
            }

            if (name === 'otpCode3') {
                this.$$("input[name='otpCode4']").focus();
            }

            if (name === 'otpCode4') {
                this.$$("input[name='otpCode5']").focus();
            }

            if (name === 'otpCode5') {
                this.$$("input[name='otpCode6']").focus();
            }

            if (name === 'otpCode6') {
                this.$$("input[name='otpCode6']").focus();
            }
            this.verifyOtp();
        } else {
            // console.log(name)
            if (name === 'otpCode6') {
                this.$$("input[name='otpCode6']").val('');
                this.$$("input[name='otpCode5']").focus();
            }

            if (name === 'otpCode5') {
                this.$$("input[name='otpCode5']").val('');
                this.$$("input[name='otpCode4']").focus();
            }

            if (name === 'otpCode4') {
                this.$$("input[name='otpCode4']").val('');
                this.$$("input[name='otpCode3']").focus();
            }

            if (name === 'otpCode3') {
                this.$$("input[name='otpCode3']").val('');
                this.$$("input[name='otpCode2']").focus();
            }

            if (name === 'otpCode2') {
                this.$$("input[name='otpCode2']").val('');
                this.$$("input[name='otpCode1']").focus();
            }

            if (name === 'otpCode1') {
                this.$$("input[name='otpCode1']").val('');
            }

            this.setState({
                [name]: ''
            })
        }

    }

    onChange = e => {
        let self = this;
        let { name, value } = e.target;
        // console.log(name, value);
        this.setState({ [name]: value }, () => {
            self.inputFoucs(name, 'add', 'onChange');
        });
    };

    onFucus = e => {
        let { name, value } = e.target;
        this.setState({
            focusCode: name
        })
    }

    resendOTP = () => {
        // this.setState({
        //     otpCode1: '',
        //     otpCode2: '',
        //     otpCode3: '',
        //     otpCode4: '',
        //     otpCode5: '',
        //     otpCode6: '',
        // });
        // this.$$("input[name='otpCode1']").val('');
        // this.$$("input[name='otpCode2']").val('');
        // this.$$("input[name='otpCode3']").val('');
        // this.$$("input[name='otpCode4']").val('');
        // this.$$("input[name='otpCode5']").val('');
        // this.$$("input[name='otpCode6']").val('');

        this.setState({
            otpCodeVal: ''
        })

        app.preloader.show();
        if (this.state.one_time_password == 'EMAIL') {

            app.preloader.show()
            AuthGuard.loginSendEmail({
                otpType: 'EMAIL',
                username: this.state.username
            }, (isOk, res) => {
                console.log(isOk, res)
                if (isOk) {
                    ShowToast(app, 'OTP Code has resend!')

                    this.state.seconds = 60;
                    let timer = setInterval(() => {
                        this.setState((preState) => ({
                            seconds: preState.seconds - 1,
                        }), () => {
                            if (this.state.seconds == 0) {
                                clearInterval(timer);
                            }
                        });
                    }, 1000)
                    this.state.seconds = 60;

                } else {
                    ShowToast(app, res.message)
                }
                app.preloader.hide()
            });

        } else if (this.state.one_time_password == 'SMS') {
            AuthGuard.sendOtp({ userID: this.state.username, password: encryptRequest(this.state.password) }, (isOk, res) => {
                if (isOk) {
                    ShowToast(app, 'OTP Code has resend!')

                    this.state.seconds = 60;
                    let timer = setInterval(() => {
                        this.setState((preState) => ({
                            seconds: preState.seconds - 1,
                        }), () => {
                            if (this.state.seconds == 0) {
                                clearInterval(timer);
                            }
                        });
                    }, 1000)
                    this.state.seconds = 60;


                } else {
                    // ShowToast(app, res);
                }
                app.preloader.hide();
            });
        }

    }

    onPageInit() {
        // console.log('onPageInit')
    }

    checkOtpCode = (val) => {
        this.setState({ otpCodeVal: val }, () => {
            if ([...val].length >= 6) {
                this.verifiOTP()
            }
        });

    }

    verifiOTP = () => {
        app.preloader.show()

        this.props.otpLogin(this.state.username, this.state.one_time_password, this.state.otpCodeVal);

        app.preloader.hide()
    }

    sendOTPToEmail = () => {

        app.preloader.show()
        AuthGuard.loginSendEmail({
            otpType: 'EMAIL',
            username: this.state.username
        }, (isOk, res) => {
            console.log(isOk, res)
            if (isOk) {

                this.setState({
                    sendOtp: true,
                    isEmailFlag: false,
                    one_time_password: 'EMAIL'
                })

            } else {
                ShowToast(app, res.message)
            }
            app.preloader.hide()
        });

    }

    renderCheckOtp() {
        return (
            <>
                <LoginScreenTitle>
                    <div></div>
                    <div>Authenticate</div>
                    {
                        this.state.one_time_password == 'SMS' ?
                            <div className="login-sub-title">We just sent you a SMS with a code</div> :
                            <div className="login-sub-title">We just sent you an email with a code</div>
                    }
                    <div className="login-sub-title"> Enter it to verify it is you. </div>
                </LoginScreenTitle>
                <List id="checkotp-form">
                    <div className="mt list-block">
                        <div className="mt item-content">
                            <div className="col-right otpCodes">
                                <Input
                                    type="text"
                                    maxlength={6}
                                    noStoreData
                                    name="otpCode"
                                    className="otpCode"
                                    value={this.state.otpCodeVal}
                                    onInput={(e) => {
                                        this.checkOtpCode(e.target.value)
                                    }}
                                    placeholder="0 0 0 0 0 0"
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                    </div>
                </List>
                <List>

                    <div className="otpErr"></div>
                    <Button disabled={this.state.seconds == 0 ? false : true} className="resendOTPBtn" color="gray" onClick={this.resendOTP} style={{ color: this.state.seconds == 0 ? '#56B3C4' : '#8e8e93' }}> Resend OTP </Button> <span style={{ display: this.state.seconds !== 0 ? 'inline-block' : 'none', fontSize: 'small' }}> <span style={{ color: '#8e8e93', fontWeight: 'bolder' }}>in</span> <span className="cb">{this.state.seconds} secs</span></span>
                    {
                        this.state.one_time_password == 'SMS' ? <Button className="outline-blue-btn sendEmail-btn" onClick={this.sendOTPToEmail}>
                            Send OTP to email instead
                   </Button> : null
                    }

                </List>
            </>
        )
    }

    eyeShow() {
        var inputPass = document.querySelector('#password input')

        // console.log(inputPass)

        if (this.state.flag == 0) {
            inputPass.type = 'text';
            this.setState({
                eyeUrl: cceye,
                flag: 1
            })
        } else {
            inputPass.type = 'password';
            this.setState({
                eyeUrl: eye,
                flag: 0
            })
        }

        // console.log(this.state.flag)

    }

    onEmailInput(e) {
        const forgetpwTitleEmail = document.querySelector(".forgetpw-title-email")
        this.setState({ username: e.target.value });
        // console.log(e.target.value)

        if (e.target.value == '') {
            forgetpwTitleEmail.style.display = "none"
        }
    }

    onBlur() {
        const forgetpwTitleEmail = document.querySelector(".forgetpw-title-email");
        forgetpwTitleEmail.style.display = "none";
        const forgetpwTitlePassword = document.querySelector(".forgetpw-title-password");
        forgetpwTitlePassword.style.display = "none";
    }

    onFocusInput() {
        const forgetpwTitleEmail = document.querySelector(".forgetpw-title-email");
        forgetpwTitleEmail.style.display = "block";
    }

    onFocusInputPassword() {
        const forgetpwTitlePassword = document.querySelector(".forgetpw-title-password");
        forgetpwTitlePassword.style.display = "block";

    }

    onPasswordInput(e) {
        const forgetpwTitlePassword = document.querySelector(".forgetpw-title-password")
        this.setState({ password: e.target.value });

        if (e.target.value == '') {
            forgetpwTitlePassword.style.display = "none"
        }
    }


    renderLogin() {
        return (
            <>
                <LoginScreenTitle>
                    <img src={Logo} className="logo" alt="" />
                    <div>Login</div>
                    {/* <div>Health Dashboard</div> */}
                    {/* <div className="login-sub-title">Enter your admin details below</div> */}
                    <p className="login-sub-title">Welcome, please login to HeartVoice Dashboard</p>
                </LoginScreenTitle>
                <List form id="my-form">
                    <div className="mt list-block">
                        <div className="mt item-content">
                            <div className="col-right">
                                <Row className="forgetpw">
                                    <Col>
                                        <span className="forgetpw-title-email">Email</span>
                                        <Input
                                            required
                                            noStoreData
                                            clearButton
                                            type="text"
                                            className="username"
                                            placeholder="Email"
                                            onFocus={this.onFocusInput}
                                            // onBlur={this.onBlur}
                                            onInput={this.onEmailInput.bind(this)}
                                        />
                                    </Col>

                                </Row>
                            </div>
                        </div>
                        <div className="mt item-content">
                            <div className="col-right">
                                <Row className="forgetpw">
                                    <Col className="forgetpw-pass">
                                        <span className="forgetpw-title-password">Password</span>
                                        <Input
                                            required
                                            noStoreData
                                            clearButton
                                            type="password"
                                            className="password"
                                            id="password"
                                            placeholder="Password"
                                            onFocus={this.onFocusInputPassword}
                                            // onBlur={this.onBlur}
                                            onInput={this.onPasswordInput.bind(this)}
                                        />
                                        <img src={this.state.eyeUrl} id="eye" alt="" onClick={this.eyeShow.bind(this)} />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="mt item-bottom">
                            <div className="message"></div>
                            <Button className="fwbtn" small onClick={this.changePwBtn.bind(this)}>Forget Password</Button>
                            {/* <Link reloadAll href={ROUTES.forgetPassword} className="fwbtn" small >Forget Password</Link> */}
                        </div>
                    </div>
                </List>
                <List>
                    {APPENV !== 'LOCAL' ? <ReCAPTCHA
                        style={{ display: this.state.username != '' && this.state.password != '' ? 'block' : 'none' }}
                        ref={recaptchaRef}
                        // size="invisible"
                        type='image'
                        onChange={this.recaptchaChange}
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    /> : ''}
                    {APPENV !== 'LOCAL' ?
                        <Button color="white" raised large className={`btnlogin mt fill-blue-btn ${this.state.recaptcha_verify ? '' : 'disabled'}`} onClick={this.btnlogin.bind(this)}>
                            Login
                        </Button> :
                        <Button color="white" raised large className={`btnlogin mt fill-blue-btn`} onClick={this.btnlogin.bind(this)}>
                            Login
                        </Button>
                     }
                </List>
            </>
        )
    }

    recaptchaChange = (value) => {
        this.setState({ recaptcha_verify: true })
    }


    recaptchaChange = (value) => {
        this.setState({ recaptcha_verify: true })
    }


    render() {
        return (
            <Page name="loginPage" loginScreen id="login" onPageInit={this.onPageInit.bind(this)}>
                {this.state.sendOtp ? this.renderCheckOtp() : this.renderLogin()}
            </Page>
        )
    }
}

Login.propTypes = {
    UserLogin: PropTypes.func.isRequired,
    userLoginData: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return state.userLogin;
};


export default connect(
    mapStateToProps,
    { UserLogin, otpLogin }
)(Login)

