import React, { Component } from 'react';
import { f7 as app, Popup, Page, Link, Navbar, NavLeft, Block, Row, Col, Preloader, PageContent ,Icon, Input,Popover, List , ListItem,Button} from 'framework7-react';
import axios from 'axios'
import doctorAvatar from '../images/icons/doctor.png'
import IconLoading from "../images/icons/icon-loading.gif";
import Robot from '../images/icons/icon_robot_white.png';
import attach from '../images/icons/attach.png';
import hvgreen from '../images/icons/hvgreen.png';
import red from '../images/icons/red.png';
import png from '../images/icons/png.png';
import docx from '../images/icons/docx.png';
import jpg from '../images/icons/jpg.png';
import pdf from '../images/icons/pdf.png';
import ppt from '../images/icons/ppt.png';
import xls from '../images/icons/xls.png';
import csv from '../images/icons/csv.png';
import chat_send from '../images/icons/chat_send.png';
import patientAvator from '../images/icons/gender.svg';
import AuthGuard from '../services/AuthGuard';
import moment from 'moment';
import Downloader from "js-file-downloader";
import { EThree , KeyPairType } from "@virgilsecurity/e3kit-browser";
import E3Kit from "../services/E3Kit";
import { momentMsgFormat, ShowToast, CREDENTIALS, TimestampFormat, isImgType, isPdfType,isMessageType } from '../commons/Helpers'
const QB = window.QB;
// QB.init(CREDENTIALS.appId, CREDENTIALS.authKey, CREDENTIALS.authSecret, { debug: { mode: 1}});
// QB.init(CREDENTIALS.appId, CREDENTIALS.authKey, CREDENTIALS.authSecret,{
//     chatProtocol: { active: 1 },
// });

const last = arr => arr[arr.length - 1];
function formatDate(date) {
    /* eslint no-confusing-arrow: 0 */
    const dateString = date.toDateString().split(" ")
    var d = dateString[2]
    var m = dateString[1]
    var y = dateString[3]
    // const dateStr = `${pad(date.getDate())}/${pad(date.getMonth() + 1)}/${date.getFullYear()}`;
    const dateStr = `${d}/${m}/${y}`;
    // const timeStr = `${pad(date.getHours())}:${pad(date.getMinutes())}`;
    return `${dateStr}`;
}
var _ = require("lodash");
class ChatPopUp extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            showPreloader: false,
            limit:8,         
            chat_drId:null,
            isEmpty:false,
            msgList:[],
            sending:true,
            dialog_recipient_avatarUrl: patientAvator,
            dialog_sender_avatarUrl: Robot,
            switchBar: Robot,
            last_message_date_sent: app.utils.now(),
            chatWithPatient:true,
            prtLoading:false,
            scrollDown:true,
            dataAppendType:'before',
            getMsgHistory:false,
            clientHeight:false,
            selectedMsg:{},
            dialogList: [],
            xmpp_room_jid: '',
            files:[],
            is_expired: false,
            cur_click_img: {},
            fileSize: 1000 * 1000 * 10,
            imgSize: 35,
            newDocList: [],
        }
        this.coachProfile = AuthGuard.getCoachProfile();
        this.DrDevice = null
    }
        
    async initQBConnect (){
        app.preloader.show();
        this.setState({
            chat_drId: this.props.chat_drId,
        })
        let self = this;
        if (this.props.userAvatar) {
            this.setState({
                dialog_recipient_avatarUrl: this.props.userAvatar
            });
        }
        let init_status = await this.initE3kit(this.state.dialogList[0]);
        if(init_status){
            self.getchatMessageList();
        }
        setTimeout(() => {
            QB.chat.onMessageListener = self.onMessage;
            QB.chat.onDisconnectedListener = self.onDisconnectedListener;
        }, 200);
    }
    
    destroyedQB() {
        QB.chat.disconnect();
        QB.chat.onDisconnectedListener = this.onDisconnectedListener;
    }

    getchatFilesList(){
        // equivalent of the above code
        QB.content.list(function(err, data) {
            console.log('FFFFFF----')
            console.log(err)
            console.log(data)
            console.log('----FFFFFF')
        });
    }

    getchatMessageList(){
        let self = this;
        var params = {
            chat_dialog_id: this.state.curdialogId,
            limit: this.state.limit,
            sort_desc: 'date_sent',
            date_sent: { lt: [this.state.last_message_date_sent] },
        };
        QB.chat.message.list(params, function (error, messages) {
            if (messages.items !== undefined && messages.items != null) {
                self.messageFilter(messages.items.sort((a, b) => a.date_sent - b.date_sent));
            } else {
                ShowToast(app , 'QB,Server Error!');
                self.setState({
                    showPreloader:false
                });
                self.props.hasRadMsg();
            }
            app.dialog.close();
        });
    }

    onMessage = (userId, message) => {
        let self = this;
        let ArrayMsg = [];
        var fileUrl = null
        var imageHTML = null; 
        if (message.extension && message.extension.save_to_history === '1') {
            if(message.extension.dialog_id != this.state.curdialogId){
                let oldCount = this.$$('.title-left-badge_' + message.extension.dialog_id).html()
                this.$$('.title-left-badge_' + message.extension.dialog_id).html(parseInt(oldCount) + 1)
                this.$$('.title-left-badge_' + message.extension.dialog_id).css('display','block')
                return;
            }
            ArrayMsg = [
              {
                _id: message.id,
                message: message.body,
                date_sent: message.extension.date_sent,
                sender_id: userId,
                attachments: message.extension.attachments ? message.extension.attachments : [],
                encryptKey: message.extension.encryptKey ? message.extension.encryptKey : '',
              },
            ];
            this.setState({
                dataAppendType:'after'
            });
             
            let lastItem =  last(this.state.msgList);
            if (lastItem && lastItem._id !== undefined && lastItem._id !== null) {
                var esh = this.$$("#" + lastItem._id + "")[0].getBoundingClientRect().top;
                if (esh - this.state.clientHeight <= this.state.clientHeight ){
                    this.setState({
                        scrollDown:true
                    });
                }
                else {
                    this.setState({
                        scrollDown: false
                    });
                    if(ArrayMsg && ArrayMsg[0].sender_id.toString() != this.state.chat_drId.toString()){
                        ShowToast(app, 'You received a new message,Please Check');
                    }
                }
            }
            self.messageFilter(ArrayMsg);
        }
    }

    onDisconnectedListener = ()=>{
        console.log("onDisconnectedListener")
    }

    getUserMessage(){
        this.setState({
            last_message_date_sent: app.utils.now(),
            scrollDown: true,
        })
        let self = this;
        this.showPreloader();
        self.initQBConnect();
        
    }
    getNotiList(){
        // console.log(this.state.showPreloader);
        if (this.state.showPreloader) {
            ShowToast(app , "Loading, Please wait!" );
            return false;
        };
        // if (this.state.switchBar == Robot){
            this.setState({
                switchBar: this.state.dialog_recipient_avatarUrl,
                chatWithPatient:false,
                showPreloader: true,
                msgList: []
            })

            let notifiArr = [];
            this.destroyedQB();
            app.preloader.show();
            
            AuthGuard.getNotiList({ userProfileId: this.props.user_profile_id, limit: 200000, page: 1, time_zone: 'Asia/Singapore' }, (isOk, res) => {
                if (isOk) {
                    res.map((item, index) => {
                        let result = {}
                        result._id = item.id;
                        result.message = item.message;
                        result.date_sent = item.send_time;
                        result.send_time = TimestampFormat(result.date_sent);
                        result.sender_id = item.sender_id;
                        result.type = 'left';
                        result.message_name = 'Systerm';
                        result.avatar = Robot;
                        notifiArr.push(result);
                    })

                } else {

                }
                this.setState({
                    showPreloader: false
                })
                app.preloader.hide();
                this.setState({
                    msgList: notifiArr
                });
            }); 

        // }else{
            // this.setState({
            //     switchBar: Robot,
            //     chatWithPatient: true,
            //     msgList: []
            // })
            // this.getUserMessage();
        // }
    }

    async componentWillReceiveProps(props){
        let self = this;
        let scroolUP = 0
        this.$f7ready((f7) => {
            this.$$('#'+this.props.type).on('scroll', function () {            
                if (self.state.chatWithPatient){
                    self.setState({
                        clientHeight: this.clientHeight //滚动可视区域高度
                    });
                    if (scroolUP < this.scrollTop){ //scrool down
                        // console.log('down')    
                    }else{ //scrool up
                        // console.log("prtLoading: " + self.state.prtLoading, "scrollDown: " + self.state.scrollDown);
                        if (this.scrollTop <= 60 && !self.state.prtLoading && !self.state.scrollDown) {
                            
                            self.setState({
                                dataAppendType: 'before',
                            });
                            self.ptrLoadMore();
                        }    
                    }
                    scroolUP = this.scrollTop;
                };
            })
        });
        let oneDialogId = '';
        if(this.props.dialogList && this.props.dialogList.length > 0 && !props.showHealthReview && props.popupOpened){
            if(self.state.dialogList.length > 0){
                setTimeout(() => {
                    self.getchatMessageList();
                }, 200);
                return;
            }
            let _newDocList = [];
            let is_init = false;
            for(let diglog = 0 ; diglog < this.props.dialogList.length ;diglog++){
                let newData = self.props.dialogList;
                newData[diglog].user_profile_id = this.props.user_profile_id;
                let newdigList = await AuthGuard.initE3kitStart(newData[diglog]);
                if(newdigList){
                    _newDocList.push(newData[diglog])
                    self.setState({
                        dialogList: _newDocList,
                    },()=>{
                        if(_newDocList.length > 0 && !is_init){
                            is_init = true;
                            oneDialogId = _newDocList[0]._id;
                            self.setState({
                                curdialogId: oneDialogId,
                                xmpp_room_jid: _newDocList[0].xmpp_room_jid,
                                cur_programs_id : _newDocList[0].program_id,
                            },()=>{
                                AuthGuard.getNewAllDrInfo({dialog_id: oneDialogId,}, (isOk) => {
                                    if (isOk) {
                                        setTimeout(() => {
                                            self.getUserMessage();
                                        }, 200);
                                    }
                                });
                                let now_date = new Date().getTime();
                                let now_date_timestamp = self.getExpiredDate(_newDocList[0].expired_at);
                                if((_newDocList[0].program_status && _newDocList[0].program_status == 'deactivate') || now_date > now_date_timestamp){
                                    self.$$('.no-messagebar').css('display','none')
                                    self.$$('.expire_text').css('display','block')
                                }else{
                                    self.$$('.no-messagebar').css('display','block')
                                }
                                if(_newDocList[0].unread_messages_count || _newDocList[0].unread_messages_count == '0' || _newDocList[0].unread_messages_count == 0){
                                    this.$$('.title-left-badge_' + _newDocList[0]._id).html(0)
                                    this.$$('.title-left-badge_' + _newDocList[0]._id).css('display','none')
                                }
                            })
                        }
                    })
                    if(_newDocList.length > 1){
                        if(newData[diglog].unread_messages_count == '0' || newData[diglog].unread_messages_count == 0){
                            this.$$('.title-left-badge_' + newData[diglog]._id).html(0)
                            this.$$('.title-left-badge_' + newData[diglog]._id).css('display','none')
                        }
                    }
                }
            }
        }
        if (props.showHealthReview){
            self.getNotiList();
        }
    }

    getExpiredDate(expired_date){
        return new Date(new Date(parseInt(expired_date) * 1000).toUTCString()).setHours(23, 59, 59, 999);
    }

    initE3kit = async (defultPrograms) => {
        this.DrDevice = null;
        app.preloader.show();
        if(AuthGuard.ChatDeviceInfo && AuthGuard.ChatDeviceInfo.length > 0){
            let curDevice = AuthGuard.ChatDeviceInfo.find((item) => {return item.programs_id == defultPrograms.program_id && item.user_profile_id == defultPrograms.user_profile_id})
            if(curDevice){
                let cur_group = curDevice.e3kit;
                if(!AuthGuard.newDrDevice){
                    AuthGuard.initNewDrE3kit();
                }
                AuthGuard.newDrDevice.newLoadGroup(cur_group);
                this.DrDevice = AuthGuard.newDrDevice;
                app.preloader.hide();
                return true;
            }else{
                app.dialog.close();
                app.preloader.hide();
                //失败处理
                app.dialog.create({
                    title: 'Error',
                    text: 'Unable to start a secure chat. Please try again.',
                    buttons: [
                      {
                        text: 'OK',
                        color: 'red',
                        onClick: async () => {
                            app.preloader.show();
                            await AuthGuard.initE3kitStart(defultPrograms);
                            let curDeviceV2 = AuthGuard.ChatDeviceInfo.find((item) => {return item.programs_id == defultPrograms.program_id && item.user_profile_id == defultPrograms.user_profile_id})
                            if(curDeviceV2){
                                let cur_group = curDeviceV2.e3kit;
                                if(!AuthGuard.newDrDevice){
                                    AuthGuard.initNewDrE3kit();
                                }
                                this.DrDevice = AuthGuard.newDrDevice.newLoadGroup(cur_group);
                                this.getchatMessageList();
                            }else{
                                this.$$('.no-messagebar').css('display','none')
                                ShowToast(app , 'Unable to start a secure chat. Please try again.');
                            }
                            app.preloader.hide();
                        }
                      },
                    ],
                    verticalButtons: true,
                  }).open();
                  return false;
            }
        }else{
            app.dialog.close();
            app.preloader.hide();
            //失败处理
            app.dialog.create({
                title: 'Error',
                text: 'Unable to start a secure chat. Please try again.',
                buttons: [
                  {
                    text: 'OK',
                    color: 'red',
                    onClick: async () => {
                        app.preloader.show();
                        await AuthGuard.initE3kitStart(defultPrograms);
                        let curDeviceV2 = AuthGuard.ChatDeviceInfo.find((item) => {return item.programs_id == defultPrograms.program_id && item.user_profile_id == defultPrograms.user_profile_id})
                        if(curDeviceV2){
                            let cur_group = curDeviceV2.e3kit;
                            if(!AuthGuard.newDrDevice){
                                AuthGuard.initNewDrE3kit();
                            }
                            this.DrDevice = AuthGuard.newDrDevice.newLoadGroup(cur_group);
                            this.getchatMessageList();
                        }else{
                            this.$$('.no-messagebar').css('display','none')
                            ShowToast(app , 'Unable to start a secure chat. Please try again.');
                        }
                        app.preloader.hide();
                    }
                  },
                ],
                verticalButtons: true,
              }).open();
              return false;
        }
    }

    componentDidUpdate() {
        this.props.onRef(this)
        app.preloader.hide();
    }

    componentDidMount(){
        this.destroyedQB();
        this.setState({
            prtLoading: false,
            scrollDown:true,
            msgList:[]
        });
    }

    componentWillUnmount(){
        this.$$('.chat-pop-close').click();
    }

    showPreloader(){
        this.setState({
            showPreloader: true
        });
    }

    hidePreloader(){
        this.setState({
            showPreloader: false
        });
    }
    
    // 下拉加载更多
    ptrLoadMore(){
        console.log('ptrLoadMore')
        this.showPreloader();
        const self = this;
        if (self.state.prtLoading) {            
        } else {
            const [firstMsg ,...otherMsg] =  self.state.msgList;
            self.setState({
                last_message_date_sent: firstMsg ? firstMsg.date_sent : '',
                prtLoading:true
            })
            console.log("getchatMessageList 1");
            self.getchatMessageList();
        };
    }

    scrollToUP() {
        let lastItem  = this.state.msgList.find((e) => { if (e.date_sent === this.state.last_message_date_sent)  return e });
        if (lastItem !== undefined && lastItem._id !== undefined && lastItem._id !== null){
            var esh = this.$$("#"+ lastItem._id +"")[0].getBoundingClientRect().top;
            this.$$('#' + this.props.type).scrollTo(0, esh - 242, 0, () => {

            })
        }
    }

    scrollToBottom(){
        var sh = this.$$('#' + this.props.type).prop('scrollHeight');
        this.$$('#' + this.props.type).scrollTop(sh, 0, () => { });
        this.setState({
            scrollDown: false
        })
        
    }

    messageFilter = async (data) => {
        if (data.length < 1)
        {
            this.setState({
                prtLoading: false
            });
            this.hidePreloader();
            ShowToast(app, 'Oops! No more new messages...');
            return;
        } 
        let message = [];
        let messageName = '';
        let self = this
        data.map(async (item, index) => {
          let result = {};
          result._id = item._id;
          result.message = item.message;
          result.date_sent = item.date_sent;
          result.send_time = TimestampFormat(result.date_sent);
          result.sender_id = item.sender_id;
          result.attachments = item.attachments ? item.attachments : [];
          if(item.attachments  && item.attachments.length > 0){
            let fileUID = item.attachments[0].id;
            result.fileUrl = QB.content.privateUrl(fileUID);
            result.fileType = item.attachments[0].type;
            result.encryptKey = item.encryptKey;
            result.name = item.attachments[0].name;
          }
          let senderNameJSON  = AuthGuard.getDRNameByAccountID(result.sender_id)
          if (this.state.chat_drId.toString() !== item.sender_id.toString()) {
            result.type = "left";
            let senderName = '';
            let positionName = '';
            if(senderNameJSON){
                if(senderNameJSON.role_name && senderNameJSON.role_name == 'Super Admin'){
                    let new_doc_name = 'Teleconsultation Provider';
                    positionName = ' - ' + new_doc_name;
                }else if(senderNameJSON.role_name && senderNameJSON.role_name){
                    positionName = ' - ' + senderNameJSON.role_name;
                }
                senderName = senderNameJSON.name;
            }
            if (result.message_name === messageName) {
              result.message_name = "";
            } else {
              result.message_name = senderName;
              result.positionName = positionName;
            }
            if(senderNameJSON && senderNameJSON.profile_image_url){
                result.avatar = senderNameJSON.profile_image_url.url.o;
            }else{
                result.avatar = patientAvator;
            }
          } else {
            result.type = "right";
            if (result.message_name === messageName) {
              result.message_name = "";
            } else {
              result.message_name = "You";
            }
            if(senderNameJSON && senderNameJSON.coach_photo){
                result.avatar = senderNameJSON.coach_photo.url.o;
            }else{
                result.avatar = doctorAvatar;
            }
          }
          messageName = result.message_name;
          message.push(result);
        });
        let message_temp = [];
        for (const iterator of message) {
            if(iterator.attachments && iterator.attachments.length > 0){
                message_temp.push(iterator);
                continue;
            }
            let senderNameJSON  = AuthGuard.getDRNameByAccountID(iterator.sender_id)
            // console.log('decryptTextCardInfo',senderNameJSON)
            let new_user_id = '';
            if(senderNameJSON && senderNameJSON.user_profile_id){
                new_user_id = senderNameJSON.user_profile_id
            }else if(senderNameJSON && senderNameJSON.id){
                new_user_id = senderNameJSON.id
            }
            if(iterator.type === 'left'){
                try{
                    iterator.message = await this.DrDevice.decryptText(new_user_id,iterator.message);
                    if(!iterator.message) continue;
                    message_temp.push(iterator);
                } catch(error){
                    continue;
                    // iterator.message = "";
                    // console.log("right:" + error.message);
                }
            }else if(iterator.type === 'right'){
                try{
                    iterator.message = await this.DrDevice.decryptText(new_user_id,iterator.message);
                    if(!iterator.message) continue;
                    message_temp.push(iterator);
                } catch(error){
                    continue;
                    // iterator.message = "";
                    // console.log(error)
                    // console.log("left:" + error.message);
                }
            }
        }
        // message =  message.filter((item) => {
        //   return item.message;
        // });
        message = message_temp;
        this.hidePreloader();
        this.setState(state => {
            var hash = {};
            let msgList = [];
            if (state.dataAppendType === 'after'){
                msgList = state.msgList.concat(message);
            }else{
                msgList = message.concat(state.msgList);
            }

            msgList = msgList.reduce((item, next)=>{
                (hash[next._id] ? '' : hash[next._id] = true) && item.push(next);
                return item
            }, [])            

            return {
                msgList,
                prtLoading: false
            };
        },()=>{
            if (!this.state.scrollDown && this.state.dataAppendType === 'before') {
                this.scrollToUP()
            } else if (!this.state.scrollDown){

            }else{
                setTimeout(() => {
                    this.scrollToBottom();
                }, 200);   
            }
            this.setState({
                dataAppendType: 'before'
            });
        });
    }

    chatPopupOpen(){
        this.showPreloader();
        let self = this;
        setTimeout(() => {
            // self.getListMsg();
        }, 200);
        
    }
    
    chatPopupClose(){
        // this.EtreeUnRegister()
        this.hidePreloader();
        this.setState({
            last_message_date_sent: app.utils.now(),
            scrollDown: true,
            msgList:[],
            chatWithPatient: true,
        })
    } 

    btnUploadFile (){
        console.log("btnUploadFile");
        // app.popover.close('.uploadMedications-popup');
        app.popover.open('.uploadChat-popup');
        // this.$$("input[name=msgFile]").click();
    }

    uploadFileBtn (){
        this.$$("input[name=msgFile]").click();
    }

    readAllBytesAsUInt8Array  (path) {
        return new Promise((resolve, reject) => {
            try {
                let xhr = new XMLHttpRequest();
                xhr.open("get", path, true);
                xhr.responseType = "blob";
                xhr.onload = function() {
                    if (this.status === 200) {
                        const reader = new FileReader();
                        reader.onload = function(event) {
                          // console.log("reader.result", reader.result);
                          resolve(event.target.result);
                        };
                        reader.readAsArrayBuffer(this.response);
                    }
                };
                xhr.send();
            } catch (e) {
                reject(e);
            }
        })
    }

    fileToByteArray(file) {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();
                let fileByteArray = [];
                reader.readAsArrayBuffer(file);
                reader.onloadend = (evt) => {
                    if (evt.target.readyState === FileReader.DONE) {
                        let arrayBuffer = evt.target.result,
                          array = new Uint8Array(arrayBuffer);
                            for (let byte of array) {
                                fileByteArray.push(byte);
                            }
                    }
                    resolve(fileByteArray);
                }
            }
            catch (e) {
                reject(e);
            } 
        })
    }

    InputFileSend = async() =>{
        app.dialog.preloader('Loading');
        let loopCount = 0;
        let self = this;
        this.state.files.map(async(item) => {
            var inputFile = item;
            let encryptFile = null;
            let encryptKey = null;
            try {
                const {encryptedFileKeyForGroup,encryptedSharedFile} = await this.DrDevice.encryptFiles(inputFile);
                encryptFile = encryptedSharedFile;
                encryptKey = encryptedFileKeyForGroup;
            } catch (error) {
                console.log("file encrypt error: " + error.message);
            }
            if(!encryptFile || !encryptKey) {app.dialog.close();return;}
            let file_type = '';
            if(!inputFile.type){
                if(inputFile.name.split('.')[1].indexOf('docx') >= 0 || inputFile.name.split('.')[1].indexOf('DOCX') >= 0){
                    file_type = 'application/msword';
                }else if(inputFile.name.split('.')[1].indexOf('xlsx') >= 0 || inputFile.name.split('.')[1].indexOf('XLSX') >= 0){
                    file_type = 'application/vnd.ms-excel';
                }else if(inputFile.name.split('.')[1].indexOf('pptx') >= 0 || inputFile.name.split('.')[1].indexOf('PPTX') >= 0){
                    file_type = 'application/vnd.ms-powerpoint';
                }
            }else{
                file_type = inputFile.type;
            }
            var fileParams = {
            name: inputFile.name,
            file: encryptFile, //inputFile
            type: file_type,
            size: inputFile.size,
            public: false,
            };
            // upload
            await QB.content.createAndUpload(fileParams, function(err, response) {
                if (err) {
                    console.log(err);
                } else {
                    self.$$("input[name=msgFile]").val('')
                    console.log(response);
                    var uploadedFile = response;
                    var uploadedFileId = response.id;
                    var fileUID = response.uid;
                    console.log(uploadedFile);
                    console.log(uploadedFileId);
                    self.btnSendFile(fileParams, fileUID,encryptKey,file_type);
                }
                loopCount++;
                if(parseInt(loopCount) == self.state.files.length){
                    app.dialog.close();
                    self.setState({
                        files: []
                    })
                }
            });
        });
        if(self.state.files.length == 0){
            app.dialog.close();
        }
    }

    blobToDataURI(blob) {
        return new Promise((resolve, reject) => {
            try {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function (e) {
                    resolve(e.target.result);
                }
            } catch (e) {
                reject(e);
            }
        })
     }

    dataURItoBlob(base64Data) {
        return new Promise((resolve, reject) => {
                //console.log(base64Data);//data:image/png;base64,
            try {
                var byteString = '';
                if(base64Data.indexOf('base64') < 0){
                    byteString = atob(base64Data);
                }else if(base64Data.split(',')[0].indexOf('base64') >= 0){
                    byteString = atob(base64Data.split(',')[1]);//base64 解码
                }else{
                    byteString = unescape(base64Data.split(',')[1]);
                }
                // var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];//mime类型 -- image/png
                // var arrayBuffer = new ArrayBuffer(byteString.length); //创建缓冲数组
                // var ia = new Uint8Array(arrayBuffer);//创建视图
                var ia = new Uint8Array(byteString.length);//创建视图
                for(var i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                resolve(ia);
                // var blob = new Blob([ia], {
                //     type: mimeString
                // });
                // return blob;
            } catch (e) {
                reject(e);
            }
        })
     }

    handleDecryptFile = async (msg)=>{
        let fileArrayBuffer = await this.readAllBytesAsUInt8Array(msg.fileUrl);
        let new_encryptFile = new Blob([new Uint8Array(fileArrayBuffer).buffer]);
        try {
            let groupKey = await this.dataURItoBlob(msg.encryptKey);
            let senderNameJSON  = AuthGuard.getDRNameByAccountID(msg.sender_id)
            let new_user_id = '';
            if(senderNameJSON && senderNameJSON.user_profile_id){
                new_user_id = senderNameJSON.user_profile_id
            }else if(senderNameJSON && senderNameJSON.id){
                new_user_id = senderNameJSON.id
            }
            let encryptFile = await this.DrDevice.decryptFiles(groupKey,new_encryptFile,new_user_id);
            let decryptedData2 = encryptFile;
            return decryptedData2 
        } catch (error) {
            console.log("file dencrypt error: " + error.message);
        }
    }

    convertFileToUrl = (file)=>{
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(file);
        return imageUrl;
    }

    onImgload = async (msg ,e) => {
        console.log("urrentTarget:::" + e.currentTarget.getAttribute("src"));
        let oldSrc = e.currentTarget.getAttribute("src");
        if(oldSrc !== IconLoading) return false
        let { _id , fileUrl } = msg
        try {
            let decryptedFileData = await this.handleDecryptFile(msg);
            let file_url = this.convertFileToUrl(decryptedFileData);
            this.$$(".chatImg-" + _id).attr('src', file_url);
        } catch (error) {
            console.log("onImgload" + error.message);   
        }
    }

    haneleMsgMenuPopover = ()=>{
        this.setState({
            selectedMsg : {} 
        })
    }

    preivewImg = (message,e)=>{
        // let _photos = []
        // this.state.msgList.map( item =>{
        //     let { fileType, fileUrl } = item
        //     if(isImgType(fileType) && fileUrl){
        //         _photos.push(fileUrl)
        //     }
        // })
        // let { _id , fileType } = message;
        // let oldSrc = this.$$('.chatImg-' + _id).attr("src");
        // if(oldSrc === IconLoading) return false
        // // let _index = _photos.findIndex((item) => item === fileUrl);
        // if(fileType && isImgType(fileType)){
        //     var myPhotoBrowserStandalone = app.photoBrowser.create({
        //       photos: [oldSrc],
        //       theme: "light",
        //       type: "popup",
        //     });
        //     // myPhotoBrowserStandalone.open(_index)
        //     myPhotoBrowserStandalone.open()
        // }
        app.popover.open('.previewChatImg-popup');
        let { _id , fileType } = message;
        let oldSrc = this.$$('.chatImg-' + _id).attr("src");
        if(oldSrc === IconLoading) return false
        if(fileType && isImgType(fileType)){
            this.setState({
                iframe_img_Url: oldSrc,
                cur_click_img:message,
                imgSize: 36
            },()=>{
                this.$$('.previewChatImg-popup .iframeBox img').css('width','36vw');
            })
        }
    }

    saveData = (name, file) => {
        let reader = new FileReader(); //new一个FileReader实例
        let self = this;
        reader.readAsDataURL(file);
        reader.onload = (event) => {
          console.log(event.srcElement.result)
          let base64Byte = event.srcElement.result
          new Downloader({
            url: base64Byte,
            filename: name,
          }).then(function() {
            app.preloader.hide();
          });
        };
    }

    downloadFile = async () =>{
        app.preloader.show();
        let cur_msg = this.state.selectedMsg;
        if (cur_msg.fileUrl && cur_msg.attachments && cur_msg.attachments.length > 0) {
          let decryptedFileData = await this.handleDecryptFile(cur_msg);
          let file_url = this.convertFileToUrl(decryptedFileData);
          let file_name = cur_msg.name;
          if (cur_msg.fileUrl) {
            new Downloader({
              url: file_url,
              filename: file_name,
            })
              .then(function(res) {
                    app.preloader.hide();
              })
              .catch((e) => {
                    console.log(e.message);
              });
          }
        } 
        app.popover.close(".popover-msgmenu");
    }

    downloadImg = async () =>{
        app.preloader.show();
        let cur_msg = this.state.cur_click_img
        if (cur_msg.fileUrl && cur_msg.attachments && cur_msg.attachments.length > 0) {
          let decryptedFileData = await this.handleDecryptFile(cur_msg);
          let file_url = this.convertFileToUrl(decryptedFileData);
          let file_name = cur_msg.name;
          if (cur_msg.fileUrl) {
            new Downloader({
              url: file_url,
              filename: file_name,
            })
              .then(function(res) {
                    app.preloader.hide();
              })
              .catch((e) => {
                    console.log(e.message);
              });
          }
        } 
        app.popover.close(".popover-msgmenu");
    }

    // delMsg = ()=>{
    //     let { _id ,type } = this.state.selectedMsg
    //     let self = this;
    //     //删除msg
    //     if (_id) {
    //       let _params = {}
    //       if (type ==="left") {
    //         _params = { force: 1 };
    //       }
    //       QB.chat.message.delete(_id, _params, function(err, res) {
    //         if (res) {
    //           self.state.msgList.splice(self.state.msgList.findIndex(item => item._id === _id), 1)
    //           ShowToast(app, 'delete success!')
    //         } else {
    //           console.log(err);
    //           ShowToast(app, "delete error!");
    //         }
    //       });
    //     }
    //     app.popover.close(".popover-msgmenu");
    // }
    onMsgClick = async (msg, e) =>{
        e.preventDefault()
        let { attachments, message ,type ,_id} = msg;
        this.setState({
          selectedMsg: msg,
        },()=>{
            app.popover.open(".popover-msgmenu", ".msgID_" + msg._id);
        });
        // if(attachments && attachments.length > 0 ){ 
        //     attachments.map( item =>{
        //         //download 
        //         QB.content.getFile(item.uid, function(err, file) {
        //           console.log(err)
        //           console.log(file)
        //         });  
        //     })
        // }

        // if(message){
            // try {
            //     console.log(message)
            //     let _msg =  await this.DrEThree.authDecrypt(message, this.patientCard);    
            //     console.log(_msg)
            // } catch (error) {
            //     console.error(`msg decrypt:. [Message: ${error.message}]` );
            // }
        // }
    }

    btnSendMsg = async () =>{
        var msg = this.$$('.'+this.props.type+'_chatMessage').val().trim();
        let encryptMsg = '';
        try {
            encryptMsg =  await this.DrDevice.encryptText(msg);
        } catch (error) {

        }
        if(!encryptMsg) return;
        if (msg){
            var message = {
                type: "groupchat",
                body: encryptMsg,
                extension: {
                    save_to_history: 1,
                    dialog_id: this.state.curdialogId
                },
                markable: 1
            };
            let chat_drId = Number.parseInt(this.state.chat_drId);
            let message_id = QB.chat.send(this.state.xmpp_room_jid, message);
            if(message_id){
                let msgObj = [
                  {
                    _id: message_id,
                    message: encryptMsg,
                    date_sent: app.utils.now(),
                    sender_id: chat_drId,
                  },
                ];
                this.setState({
                    dataAppendType:'after',
                    scrollDown:true
                });
                console.log("messageFilter msg: " + JSON.stringify(msgObj));
                // this.messageFilter(msgObj);
                this.$$('.' + this.props.type + '_chatMessage').val('');
                this.$$('.' + this.props.type + '_chatMessage').css('height','33px');
                this.pushNoti(encryptMsg);
            }
            this.$$('.send-link').css('display','none');
        }else{
            ShowToast(app, 'Please Enter Message!');
            this.$$('.' + this.props.type + '_chatMessage').focus();
        }
    }

    btnSendFile = async (fileParams ,fileUID ,encryptKey,file_type) =>{
        // let msg = fileParams.name;
        // let encryptMsg = msg;
        // try {
        //   encryptMsg =  await this.DrDevice.encryptText(msg);
        // } catch (error) {
        //     console.error('authEncrypt :' + error.message)
        // }
        // if (msg){
            var message = {
              type: "groupchat",
              body: null,
              extension: {
                save_to_history: 1,
                dialog_id: this.state.curdialogId,
                attachments: [
                  {
                    id: fileUID,
                    type: file_type,
                    "content-type": file_type,
                    name: fileParams.name,
                    
                  },
                ],
                encryptKey: await this.blobToDataURI(new Blob([encryptKey.buffer])),
              },
              markable: 1,
            };
            let chat_drId = Number.parseInt(this.state.chat_drId);
            let message_id = QB.chat.send(this.state.xmpp_room_jid, message);
            let fileUrl = ''
            if (fileUID) {
                fileUrl = QB.content.privateUrl(fileUID);
            }
            if(message_id){
                app.popover.close('.uploadChat-popup');
                let msgObj = [
                  {
                    _id: message_id,
                    message: null,
                    date_sent: app.utils.now(),
                    sender_id: chat_drId,
                    attachments: [
                      {
                        id: fileUID,
                        type: file_type,
                        "content-type": file_type,
                        name: fileParams.name,
                      },
                    ],
                    encryptKey: await this.blobToDataURI(new Blob([encryptKey.buffer])),
                    fileUrl: fileUrl,
                    fileType: fileParams.type,
                  },
                ];
                this.setState({
                    dataAppendType:'after',
                    scrollDown:true
                });
                console.log("messageFilter file: " + JSON.stringify(msgObj));
                // this.messageFilter(msgObj);
                // this.$$('.' + this.props.type + '_chatMessage').val('');
                let msg = '';
                if(fileParams.type.indexOf('image') != -1){
                    msg = 'Image'
                }else{
                    msg = 'File'
                }
                let encryptMsg = '';
                try {
                  encryptMsg =  await this.DrDevice.encryptText(msg);
                } catch (error) {
                    console.error('authEncrypt :' + error.message)
                }
                this.pushNoti(encryptMsg);
            }
        // }else{
        //     ShowToast(app, 'Please Enter Message!');
            // this.$$('.' + this.props.type + '_chatMessage').focus();
        // }
    }

    pushNoti(msg){
        let dr_id = this.coachProfile.userProfileId;
        AuthGuard.pushNotification({ user_id: this.props.user_profile_id, title: this.coachProfile.clinic_name, message: msg,group_id: this.state.curdialogId,program_id: this.state.cur_programs_id,sender_user_profile_id: dr_id}, (isOk, res) => {
            // console.log(res);
            if (isOk) {

            } else {

            }
        });
    }

    tabProgramsChat = async (diginfo,e) => {
        let self  = this;
        if(diginfo && diginfo._id && diginfo._id != this.state.curdialogId){
            app.dialog.preloader('Loading...');
            let init_status = await this.initE3kit(diginfo);
            if(init_status){
                self.setState({
                    curdialogId: diginfo._id,
                    cur_programs_id: diginfo.program_id,
                    xmpp_room_jid: diginfo.xmpp_room_jid,
                    msgList: [],
                    last_message_date_sent: app.utils.now(),
                    scrollDown: true,
                },()=>{
                    this.$$('.title-left-badge_' + diginfo._id).html(0)
                    this.$$('.title-left-badge_' + diginfo._id).css('display','none')
                    let now_date = new Date().getTime();
                    let now_date_timestamp = this.getExpiredDate(diginfo.expired_at);
                    if((diginfo.program_status && diginfo.program_status == 'deactivate') ||  now_date > now_date_timestamp){
                        this.$$('.no-messagebar').css('display','none')
                        this.$$('.expire_text').css('display','block')
                    }else{
                        this.$$('.no-messagebar').css('display','block')
                        this.$$('.expire_text').css('display','none')
                    }
                    self.getchatMessageList();
                });
            }
        }
    }
    imgEnlarge = () => {
        let img_size = this.state.imgSize + 20;
        if(img_size > 90){
            img_size = 90;
        }
        this.$$('.previewChatImg-popup .iframeBox img').css('width',img_size + 'vw');
        this.setState({
            imgSize: img_size,
        })
    }

    imgShrink = () => {
        let img_size = this.state.imgSize - 20;
        if(img_size <= 0){
            img_size = 10;
        }
        this.$$('.previewChatImg-popup .iframeBox img').css('width',img_size + 'vw');
        this.setState({
            imgSize: img_size,
        })
    }

    imgFullScreen = () => {
        let img_size = 90;
        this.$$('.previewChatImg-popup .iframeBox img').css('width',img_size + 'vw');
        this.setState({
            imgSize: img_size,
        })
    }

    AddChat() {
        var sendTime = '';
        var sendBtn = this.state.sending ? 'pointerEvents: "none"' : ''; 
        var chatUserName = '';
        return (
            <Page>
                <PageContent 
                    className={this.state.chatWithPatient ? "chatPopup" : "chatPopup notifications"}
                    id={this.props.type}
                    style={{marginBottom: '6em'}}
                >
                    <Block style={{height: "calc(100% - 60px)"}}>
                        <div className="text-align-center" style={{ paddingTop: '2rem', display: 'block' }}>                            
                            {this.state.showPreloader ? <Preloader color="green"></Preloader>:''}
                        </div>
                        <div className="messages-content">
                            <div className="messages">
                                
                                {this.state.msgList.map((message , index)=>{
                                    var date_sent = moment.unix(message.date_sent);
                                    var _date_sent = momentMsgFormat(date_sent);
                                    // console.log(message.date_sent, date_sent, _date_sent)
                                    if (message.date_sent === undefined){
                                        var showDay = "";
                                        sendTime = '';
                                    }else{
                                        var showDay = sendTime !== message.send_time ? message.send_time : "";
                                        sendTime = message.send_time;
                                    }
                                    let showimg = '';
                                    var showChatUserName = chatUserName !== message.message_name ? message.message_name : '';
                                    var positionName = chatUserName !== message.message_name ? message.positionName : '';
                                    if(message.type == 'left' && showChatUserName == ''){
                                        _date_sent = '';
                                        showimg = '';
                                    }else if(message.type == 'left'){
                                        showimg = message.avatar;
                                    }
                                    if(message.type == 'right' && showChatUserName == ''){
                                        _date_sent = '';
                                        showimg = '';
                                    }else if(message.type == 'right' && showChatUserName){
                                        showChatUserName = '';
                                        showimg = '';
                                    }
                                    chatUserName = message.message_name;
                                    return (
                                        <React.Fragment key={message._id}>
                                            <div className="messages-title">
                                                <b>{showDay}</b>
                                            </div>
                                            <div id={message._id} className={"message " + (message.type === 'left' ? "message-received" : "message-sent") + " message-tail message-last"} >
                                                
                                                <div className={`message-content msgID_${message._id}`} onContextMenu={this.onMsgClick.bind(this,message)}>
                                                    <div className="message-name">{showChatUserName}</div>
                                                    {_date_sent ? <div className="message-header" style={{display:'inline-flex'}}><div className="message-img" style={{ backgroundImage: 'url(' + showimg + ')',display:'inline' }}></div> <div style={{display:'inline',marginTop:'0.3em'}}>{showChatUserName}{positionName}     {_date_sent}</div></div> : ''}
                                                    <div className="message-bubble">
                                                        {/* <div className="message-text-header">Text header</div> */}
                                                        {
                                                        isImgType(message.fileType) && message.fileUrl ? (
                                                            <div className="message-image" onClick={this.preivewImg.bind(this, message)}>
                                                                <img src={IconLoading} onLoad={this.onImgload.bind(this, message)} data-large={message.fileUrl} className={`lazy-img chatImg-${message._id}`} alt={message.name}/>
                                                            </div>
                                                        ) : (isPdfType(message.fileType) ? (
                                                            <div className="message-text" onClick={this.oneMsgClick.bind(this,message)}>
                                                                <div className="upload-img-left">
                                                                    <img src={pdf} />
                                                                </div><div className="upload-img-right">{message.name}</div>
                                                            </div>
                                                        ) : (message.fileType && !isMessageType(message.fileType) ? (
                                                            <div className="message-text">
                                                                 <div className="upload-img-left">
                                                                    {message.name.indexOf('.csv') != -1 || message.name.indexOf('.CSV') != -1 ? <img src={csv} /> : ''}
                                                                    {message.name.indexOf('.docx') != -1 || message.name.indexOf('.DOCX') != -1  || message.name.indexOf('.doc') != -1 || message.name.indexOf('.DOC') != -1 ? <img src={docx} /> : ''}
                                                                    {message.name.indexOf('.ppt') != -1 || message.name.indexOf('.PPT') != -1  || message.name.indexOf('.pptx') != -1 || message.name.indexOf('.PPTX') != -1 ? <img src={ppt} /> : ''}
                                                                    {message.name.indexOf('.xls') != -1 || message.name.indexOf('.XLS') != -1  || message.name.indexOf('.xlsx') != -1 || message.name.indexOf('.XLSX') != -1 ? <img src={xls} /> : ''}
                                                                </div><div className="upload-img-right">{message.name}</div>
                                                            </div>
                                                        ) : (<div className={`message-text message-text_${message._id}`} onClick={this.click_url.bind(this,message._id)}>{this.state.chatWithPatient ? this.checkURL(message.message,message._id) : message.message}</div>)
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )                                    
                                })}
                            </div>
                        </div>
                    </Block>
                </PageContent>
                <Popover className="popover-msgmenu" onPopoverClose={this.haneleMsgMenuPopover.bind(this)}>
                    <List simple-list>
                        {/* <ListItem onClick={this.preivewImg.bind(this)} style={{display: isImgType(this.state.selectedMsg.fileType) ? 'block' : 'none',cursor:'pointer'}} title="Preview" /> */}
                        <ListItem onClick={this.downloadFile.bind(this)} style={{cursor:'pointer'}} title="Download" />
                        {/* <ListItem onClick={this.delMsg.bind(this)} style={{cursor:'pointer'}} title="Delete" /> */}
                    </List>
                </Popover>  
            </Page>
        )}

    checkURL = (message,id) => {
        // 从字符串中提取url
        if(!message){
            return;
        }
        let self = this;
        var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
        let _msg = message.replace(urlRegex, function(url,b,c) {
            // var url2 = (c == 'www.') ?  'http://' +url : url;
            let href = '<span class="newurl_'+id+'" style="color:blue;font-weight:900;cursor: pointer;text-decoration:underline;">'+url+'</span>'
            self.$$('.message-text_'+id).css('display','block');
            return href;
        })
        self.$$('.message-text_'+id).html(_msg);
    }

    click_url = (id) => {
        if(!id) return;
        let newUrl = this.$$('.newurl_'+id).html();
        if(!newUrl){
            return;
        }
        if(newUrl.indexOf('http') == -1){
            newUrl = 'http://' + newUrl;
        }
        window.open(newUrl);
    }

    chat_send_status = () => {
        let message_values = this.$$('.'+this.props.type+'_chatMessage').val().trim();
        if(!message_values || message_values == '' || message_values == null || message_values == undefined || message_values == 'undefined'){
            this.$$('.send-link').css('display','none');
        }else{
            this.$$('.send-link').css('display','block');
        }
    }

    render() {
        return (
            <div>
            <Popup 
                className="chat-popup"
                tabletFullscreen
                // swipeHandler=".swipe-handler"
                // backdrop={false}
                closeByBackdropClick={true}
                opened={this.props.popupOpened} 
                onPopupOpen={this.chatPopupOpen.bind(this)} 
                onPopupClose={this.chatPopupClose.bind(this)} 
                onPopupClosed={this.props.onPopupClosed}>
                <Navbar noHairline innerClass="chatNavBar" style={{display:'none'}}>
                    <NavLeft>
                        <Link popupClose className="chat-pop-close">&lt; Back to Details</Link>
                    </NavLeft>
                </Navbar>
                {this.state.chatWithPatient ? 
                <Row>
                    <Col width="25">
                        <div className="side-panle">
                            <p>Chats</p>
                            <List>
                                {this.state.dialogList.map((dialogInfo , index)=>
                                    <ListItem key={dialogInfo._id} title={dialogInfo.name} className={dialogInfo._id == this.state.curdialogId ? "side-item-white" : "side-item-gray"} onClick={this.tabProgramsChat.bind(this, dialogInfo)}>
                                        <div slot="content"><div className={"title-left-badge title-left-badge_"+dialogInfo._id}>{dialogInfo.unread_messages_count}</div></div>
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    </Col>
                    <Col width="75">
                        <Row className="avatarHeader">
                            <Col>
                                {/* <img src={this.state.dialog_recipient_avatarUrl} alt="" className="avatar" /> */}
                                {this.props.userName}
                            </Col>
                        </Row>
                        {/* <Fab position="right-bottom" slot="fixed" color="gray" className="fabBtnForSwitch">
                            <img src={this.state.switchBar} alt="" className="switchBar" onClick={this.getNotiList.bind(this)}/>
                        </Fab> */}
                        {this.AddChat()}
                        <div className="toolbar messagebar-v2"></div>
                        <div className="toolbar expire_text">
                            <div>This program has expired, please add user again to continue chat.</div>
                        </div>
                        <div className="toolbar messagebar no-messagebar" style={{display:'none'}}>
                            <div className="toolbar-inner">
                                <div className="messagebar-area">
                                    <textarea onChange={this.chat_send_status.bind(this)} className={`resizable ${this.props.type}_chatMessage`} placeholder="Type something..."></textarea>
                                </div>
                                <a className="link upload-link" href="#!" onClick={this.btnUploadFile.bind(this)}>
                                    <img src={attach} className="send-file-img" alt=""/>
                                </a>
                                <a className="link send-link" href="#!" onClick={this.btnSendMsg.bind(this)}>
                                    <img src={chat_send} className="send-file-img" alt=""/>
                                </a>
                            </div>
                        </div>
                        <div style={{display:'none'}}>
                            <Input type='file' id='msgFile' name="msgFile" onChange={this.InputFileChange.bind(this)}/>
                        </div>
                    </Col>
                </Row> : 
                <div>
                <Row className="avatarHeader" style={{left:'5%'}}>
                    <Col>
                        <img src={Robot} alt="" className="avatar" />
                        {'Notifications'}
                    </Col>
                </Row>
                {this.AddChat()}</div>}
            </Popup>
                <Popup
                    className="uploadChat-popup"
                    swipeToClose
                    swipeHandler=".swipe-handler"
                >
                    <Page>
                        <div style={{float:'right'}} onClick={this.closeUpload.bind(this)}><img src={hvgreen} className="delect-file-img" alt=""/></div>
                        <div className="upload-title">
                            <div className="upload-title-top">
                                <div className="upload-top-subtitle">
                                    Attach & Upload Files
                                </div>
                                <div className="upload-top-subtitle-bottom">
                                    File type must be either .png .jpg .docx .pdf .ppt .csv .xls
                                </div>
                            </div>
                        </div>
                        <div className="mt20">
                            <div className="drop-border" id="drop_zone" onDrop={this.dropHandler.bind(this)} onDragOver={this.dragOverHandler.bind(this)}>
                                <div className="drop-border-content">Drag and Drop files here <br />or <span  onClick={this.uploadFileBtn.bind(this)} style={{color:'rgb(126, 181, 192)',cursor:'pointer'}}>Browse</span></div>
                            </div>
                        </div>
                        <div className="mt20">
                            {this.state.files ? this.state.files.map((fileInfo,index) =>{
                                return (<div key={index} className="upload-border">
                                    <div className="upload-img-left">
                                        {fileInfo.name.indexOf('.pdf') != -1 || fileInfo.name.indexOf('.PDF') != -1 ? <img src={pdf} /> : ''}
                                        {fileInfo.name.indexOf('.csv') != -1 || fileInfo.name.indexOf('.CSV') != -1 ? <img src={csv} /> : ''}
                                        {fileInfo.name.indexOf('.jpg') != -1 || fileInfo.name.indexOf('.JPG') != -1 ? <img src={jpg} /> : ''}
                                        {fileInfo.name.indexOf('.docx') != -1 || fileInfo.name.indexOf('.DOCX') != -1  || fileInfo.name.indexOf('.doc') != -1 || fileInfo.name.indexOf('.DOC') != -1 ? <img src={docx} /> : ''}
                                        {fileInfo.name.indexOf('.ppt') != -1 || fileInfo.name.indexOf('.PPT') != -1  || fileInfo.name.indexOf('.pptx') != -1 || fileInfo.name.indexOf('.PPTX') != -1 ? <img src={ppt} /> : ''}
                                        {fileInfo.name.indexOf('.xls') != -1 || fileInfo.name.indexOf('.XLS') != -1  || fileInfo.name.indexOf('.xlsx') != -1 || fileInfo.name.indexOf('.XLSX') != -1 ? <img src={xls} /> : ''}
                                        {fileInfo.name.indexOf('.png') != -1 || fileInfo.name.indexOf('.PNG') != -1 ? <img src={png} /> : ''}
                                    </div>
                                    <div className="upload-img-right">
                                        <div className="upload-border-left">{fileInfo.name}</div>
                                        <div className="red-right" onClick={this.delectFile.bind(this,fileInfo)}><img src={red} className="delect-red-img" alt=""/></div>
                                        <div className="upload-border-right">Upload on {formatDate(new Date())}</div>
                                        <div style={{clear:'both'}}></div>
                                        <div className="upload-border-bar" data-progress="100"></div>
                                    </div>
                                    <div style={{clear:'both'}}></div>
                                </div>)
                            }) : ''}
                        </div>
                        <div className="txt-left">
                            <span className="errorMsg">{this.state.update_file_status ? 'This file type is not supported.' : ''}</span>
                        </div>
                        <br/>
                        <div className="footerBtn">
                            <Button className="close-pop" popupClose disabled="disabled"></Button>
                            <Button className="navbarBtn fill-blue-btn" onClick={this.InputFileSend.bind(this)} raised fill> Done </Button>
                        </div>
                    </Page>
                </Popup>
                <Popup
                    className="previewChatFile-popup"
                    tabletFullscreen
                    swipeToClose
                    swipeHandler=".swipe-handler"
                    >
                    <Page>
                        <Row>
                            <Col style={{textAlign:'right',margin:'1em 1em 0 0'}}>
                                <img src={red} className="delect-file-img" onClick={this.clickFilePop.bind(this)} alt=""/>
                            </Col>
                        </Row>
                        <Row className="iframeBox">
                        <Col>
                            <iframe src={this.state.iframeUrl ? this.state.iframeUrl : ''} frameBorder="0" />
                        </Col>
                        </Row>
                    </Page>
                </Popup>
                <Popup
                    className="previewChatImg-popup"
                    tabletFullscreen
                    swipeToClose
                    swipeHandler=".swipe-handler"
                    >
                    <Page>
                        <Row className="img-close">
                            <Col style={{textAlign:'right',margin:'1em 3em 0 0'}}>
                                <img src={red} className="delect-file-img" onClick={this.clickImgPop.bind(this)} alt=""/>
                            </Col>
                        </Row>
                        <Row className="iframeBox">
                            <Col>
                            <div className="imgage-module">
                                <div className="img-module"><img src={this.state.iframe_img_Url ? this.state.iframe_img_Url : ''} /></div>
                                <div className="icon-module"><Button
                                onClick={this.imgEnlarge.bind(this)}
                                style={{ marginTop: "-5px", display: "inline-block" }}
                                color="custom-blue"
                                borderColor="custom-blue"
                                >
                                    <Icon f7="plus_app"></Icon>
                                </Button>
                                <br />
                                <br />
                                <Button
                                onClick={this.imgShrink.bind(this)}
                                style={{ marginTop: "-5px", display: "inline-block" }}
                                color="custom-blue"
                                borderColor="custom-blue"
                                >
                                    <Icon f7="minus_square"></Icon>
                                </Button>
                                <br />
                                <br />
                                <Button
                                onClick={this.imgFullScreen.bind(this)}
                                style={{ marginTop: "-5px", display: "inline-block" }}
                                color="custom-blue"
                                borderColor="custom-blue"
                                >
                                    <Icon f7="expand"></Icon>
                                </Button>
                                </div>
                            </div>
                            </Col>
                        </Row>
                        <Row className="img-bottom">
                            <Col>
                                <Button className="outline-blue-btn" onClick={this.downloadImg.bind(this)}> Download Image</Button>
                            </Col>
                        </Row>
                    </Page>
                </Popup>
            </div>
        );
    }
    closeUpload = () => {
        this.setState({
            files: []
        })
        this.$$('.close-pop').click();
    }
    delectFile = (messageInfo) => {
        let filesList = this.state.files;
        let newArr = filesList.filter(item => item.name !== messageInfo.name);
        this.setState({
            files: newArr,
        })
    }

    oneMsgClick = (message) => {
        this.setState({
            iframeUrl: '',
        }, async () => {
            app.popover.open('.previewChatFile-popup');
            let decryptedFileData = await this.handleDecryptFile(message);
            let file_url = this.convertFileToUrl(decryptedFileData);
            axios.get(file_url,{responseType: 'blob'}).then(res => {
                var urlCreator = window.URL || window.webkitURL;
                this.setState({
                    iframeUrl: urlCreator.createObjectURL(new Blob([res.data], { type: "application/pdf"}))
                })
            })
        }) 
    }
    clickFilePop = () => {
        app.popover.close('.previewChatFile-popup');
    }

    clickImgPop = () => {
        app.popover.close('.previewChatImg-popup');
    }

    dragOverHandler = (ev) =>{
        console.log('File(s) in drop zone');
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
    }
    isType = (type,name) => {
        if(type == 'application/pdf' || type == 'image/jpeg' || type == 'image/png' || type == 'image/jpg' || type == 'text/csv' || type == 'application/vnd.ms-excel' || type == 'application/msword' || type == 'application/vnd.ms-powerpoint'){
            return true;
        }else if(name.indexOf('.docx') != -1 || name.indexOf('.DOCX') != -1 || name.indexOf('.xlsx') != -1 || name.indexOf('.XLSX') != -1 || name.indexOf('.pptx') != -1 || name.indexOf('.PPTX') != -1){
            return true;
        }else{
            return false;
        }
    }
    dropHandler = (ev) => {
        console.log('File(s) dropped');
        // Prevent default behavior (Prevent file from being opened)
        ev.preventDefault();
        let files = this.state.files;
        if (ev.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.items.length; i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    if(!file) return;
                    if(file.size >= this.state.fileSize){
                        ShowToast(app, 'The file that you have selected is larger than the maximum limit of 10MB. Unable to send file.');
                    }else if(!this.isType(file.type,file.name)){
                        ShowToast(app, 'The file type does not support upload.');
                    }else{
                        let result_files = this.state.files.find(element => element.name == file.name);
                        if(!result_files){
                            files.push(file);
                        }else{
                            ShowToast(app, 'Please upload a different file.');
                        }
                    }
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            for (var i = 0; i < ev.dataTransfer.files.length; i++) {
                if(!ev.dataTransfer.files) return;
                if(ev.dataTransfer.files.size >= this.state.fileSize){
                    ShowToast(app, 'The file that you have selected is larger than the maximum limit of 10MB. Unable to send file.');
                }else if(!this.isType(ev.dataTransfer.files.type,ev.dataTransfer.files.name)){
                    ShowToast(app, 'The file type does not support upload.');
                }else{
                    let result_files = this.state.files.find(element => element.name == ev.dataTransfer.files[i].name);
                    if(!result_files){
                        files.push(ev.dataTransfer.files[i]);
                    }else{
                        ShowToast(app, 'Please upload a different file.');
                    }
                }
            }
        }
        this.setState({
            files: files
        })
    }

    InputFileChange = () => {
        let files = this.state.files;
        let fileInfo = this.$$("input[name=msgFile]")[0].files[0];
        if(!fileInfo) return;
        if(fileInfo.size >= this.state.fileSize){
            ShowToast(app, 'The file that you have selected is larger than the maximum limit of 10MB. Unable to send file.');
        }else if(!this.isType(fileInfo.type,fileInfo.name)){
            ShowToast(app, 'The file type does not support upload.');
        }else{
            files.push(fileInfo);
            this.setState({
                files: files
            })
        }
    }   
}

export default ChatPopUp;