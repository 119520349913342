import React, { Component } from 'react';
import { f7 as app, View, Page, Row, Col, Icon, Button, Block, Popover, BlockTitle, List, ListInput, ListItem } from 'framework7-react';
import { connect } from 'react-redux'
import { GetPatientList } from '../redux/actions'
import AuthGuard from '../services/AuthGuard';
import PatientItem from '../screens/PatientItem'
import SideBar from '../components/SideBar'
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants'
import PatientsRules from '../business/PatientsRules';
// import '../commons/Sort.js'
import {ShowToast} from '../commons/Helpers';
import MemoNote from '../images/graph/memo_notes.png';
import Face1 from '../images/graph/face1.svg';
import Face2 from '../images/graph/face2.svg';
import Face3 from '../images/graph/face3.svg';
import Face4 from '../images/graph/face4.svg';
import Face5 from '../images/graph/face5.svg';
import sousuo from '../images/icons/sousuo.png';

class PatientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            accountIDorName: '',
            monitoringProgram: '',
            day7:'',
            email: '',
            total: 0,
            patientListData: [],
            movies: [],
            checked: true,
            Sortcol: -1,
            Sort_flg: [true, true, true, true],
            cnt: 0,
            colidx: 0,
            programOptions: [],
            searchMPFlag: true,
            patientFilterHeader: AuthGuard.getSearchPatientsFilter(),
            coachName: AuthGuard.getCoachProfile().coachName,
        }

    }

    componentWillMount() {

    }


    componentDidMount() {
        let self = this;
        setTimeout(() => {
            // self.getListPatient();
            // self.getPatientDetail();

        }, 200);

        self.loadAvaliableProgram()

        var monitoringColumnsData = sessionStorage.getItem('MonitoringColumns');
        if (monitoringColumnsData !== null) {
            var monitoringColumnsDatas = monitoringColumnsData.split(",");

            console.log(monitoringColumnsData, monitoringColumnsDatas)

            let tbl_thead_tr_th = document.querySelectorAll("#tbl thead tr th");
            // let table_tbody_tr = document.querySelectorAll("#tbl tbody tr");
            let checks = document.getElementsByName('demo-checkbox');
            for (let i = 0; i < monitoringColumnsDatas.length; i++) {
                for (let j = 0; j < tbl_thead_tr_th.length; j++) {
                    if (i == j) {
                        checks[i].checked = false;
                        tbl_thead_tr_th[j].style.display = 'none';
                        // table_tbody_tr[i].style.display = 'none'

                        console.log(i, j, tbl_thead_tr_th[j], checks[i])

                    }
                }
            }
        }


    }

    componentWillReceiveProps(props) {
        const { patientListData } = props;
        if (patientListData !== undefined && patientListData !== null) {
            app.preloader.hide();
            this.setState({
                patientListData: patientListData
            });

        }
    }

    componentDidUpdate() {
        // app.preloader.hide();
        // this.popoverClosed();
    }

    onPageReinit = () => {
        console.log('onPageReinit');
        setTimeout(() => {
            this.getListPatient();
        }, 200);
    }


    loadAvaliableProgram() {
        let coachProfile = AuthGuard.getCoachProfile();
        AuthGuard.findProgramByMerchantId({
            clinicInfoId: coachProfile.clinic_info_id
        }, (ok, res) => {
            let programOptions = []
            if (ok) {
                res.map(item => {
                    programOptions.push({
                        label: item.name,
                        value: item.id,
                    })
                })
            }

            this.setState({
                programOptions: programOptions
            })

            // console.log(res, this.state.programOptions)
        })

    }

    getListPatient = () => {        
        if(!this.state.accountIDorName && !this.state.email && !this.state.day7){
            ShowToast(app, 'Please select at least one filter');
            return 
        }
        app.preloader.show();
        this.props.GetPatientList(
            this.state.accountIDorName,
            // this.state.monitoringProgram,            
            this.state.email,
            this.state.day7,
        );
    }

    btnSearchPatient() {
        this.getListPatient();
    }

    btnClear() {
        this.setState({
            accountIDorName: '',
            // monitoringProgram: '',
            day7: '',
            email: '',
            searchMPFlag: true
        });
        this.setState({
            patientListData: []
        });
    }


    tablesort(th, iRow, iCol, dataType) {

        let table = document.querySelector('#tbl'); //table
        let rows = table.tHead.rows; //thead

        let tbody = table.tBodies[0]; //tbody
        let colRows = tbody.rows; //tbody 里所有的tr
        let aTrs = new Array;

        let _val1 = '';
        let _val2 = '';
        let value1;
        let value2;


        for (let f = 0; f < colRows.length; f++) {

            aTrs.push(colRows[f]);

        }

        let len = aTrs.length;


        if (table.sortCol == iCol) {
            aTrs.reverse();
        } else {
            // aTrs.sort();

            for (var i = 0; i < len; i++) {

                for (var j = i + 1; j < len; j++) {

                    //获取每行分割后数组的第二个值,即文本值
                    value1 = aTrs[i].childNodes[iCol].innerHTML;
                    //获取下一行分割后数组的第二个值,即文本值
                    value2 = aTrs[j].childNodes[iCol].innerHTML;

                    // console.log(aTrs[i].childNodes[iCol])
                    // console.log(aTrs[j].childNodes[iCol])

                    //接下来是数字\字符串等的比较
                    if (dataType == "int") {

                        if (isNaN(parseInt(value1))) {
                            value1 = 0
                        }

                        if (isNaN(parseInt(value2))) {
                            value2 = 0
                        }

                        value1 = value1 == "" ? 0 : value1;
                        value2 = value2 == "" ? 0 : value2;
                        if (parseInt(value1) > parseInt(value2)) {
                            var temp = aTrs[j];
                            aTrs[j] = aTrs[i];
                            aTrs[i] = temp;
                        }

                        // console.log(value1,value2)

                    } else if (dataType == "string") {

                        if (value1.localeCompare(value2) > 0) {//
                            var temp = aTrs[j];
                            aTrs[j] = aTrs[i];
                            aTrs[i] = temp;

                            // console.log(dataType)
                        }
                    } else {
                        // console.log(dataType)

                        let value1Flag = value1.indexOf('img') != -1;
                        let value2Flag = value2.indexOf('img') != -1;


                        if (value1Flag == true) {
                            value1 = 0
                        } else {

                            _val1 = value1.split('</span>')
                            value1 = parseInt(_val1[0].split('">')[1])
                        }
                        if (value2Flag == true) {
                            value2 = 0
                        } else {

                            _val2 = value2.split('</span>')
                            value2 = parseInt(_val2[0].split('">')[1])
                        }

                        if (isNaN(value1)) {
                            value1 = 0.5
                        }

                        if (isNaN(value2)) {
                            value2 = 0.5
                        }

                        // console.log(value1)
                        // console.log(value2)

                        value1 = value1 == "" ? 0 : value1;
                        value2 = value2 == "" ? 0 : value2;
                        if (parseFloat(value1) > parseFloat(value2)) {
                            var temp = aTrs[j];
                            aTrs[j] = aTrs[i];
                            aTrs[i] = temp;
                        }

                    }
                }
            }

        }

        let oFragment = document.createDocumentFragment();
        for (let i = 0; i < aTrs.length; i++) {
            oFragment.appendChild(aTrs[i]);
        }
        tbody.appendChild(oFragment);

        table.sortCol = iCol;

    }


    renderUserListTable() {
        const userTR = [];

        if (this.state.patientListData.length > 0) {
            let _listPatients = PatientsRules.parseData(this.state.patientListData)
            _listPatients.map((patient) => {
                userTR.push(
                    <PatientItem patientFilterHeader={this.state.patientFilterHeader} patient={patient} key={patient.user_profile_id} />
                );
            });

        }

        return (
            <div className="data-table card pateintItem">

                <table id="tbl">
                    <thead>
                        <tr>
                            {this.state.patientFilterHeader.find(item => item.code === 'risk').checked ?
                                <th className="label-cell" id="th0" onClick={this.tablesort.bind(this, 'tbl', 0, 11, 'img')}> RISK </th>
                                : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'mon-id').checked ?
                                <th className="medium-only" id="th1" onClick={this.tablesort.bind(this, 'tbl', 0, 1, 'string')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">ID</span>
                                </th>
                                : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'mon-name').checked ?
                                <th className="medium-only" id="th2" onClick={this.tablesort.bind(this, 'tbl', 0, 2, 'string')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">NAME</span>
                                </th> : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'sex').checked ?
                                <th className="medium-only" id="th3" onClick={this.tablesort.bind(this, 'tbl', 0, 3, 'string')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">SEX</span>
                                </th> : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'age').checked ?
                                <th className="numeric-cell" id="th4" onClick={this.tablesort.bind(this, 'tbl', 0, 4, 'int')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">AGE</span>
                                </th> : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'bmi').checked ?
                                <th className="medium-only" id="th5" onClick={this.tablesort.bind(this, 'tbl', 0, 5, 'int')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">AVG BMI</span>
                                    <span className="table-cell-desc tableThC">WT[kg]</span>
                                </th> : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'bp').checked ?
                                <th className="medium-only" id="th6" onClick={this.tablesort.bind(this, 'tbl', 0, 6, 'int')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">AVG BP</span>
                                    <span className="table-cell-desc tableThC">mmHg</span>
                                </th> : null}
                            {/* <th className="medium-only">HIGH BP/MEAS <span className="table-cell-desc tableThC">times</span></th> */}
                            {this.state.patientFilterHeader.find(item => item.code === 'pr').checked ?
                                <th className="medium-only" id="th7" onClick={this.tablesort.bind(this, 'tbl', 0, 7, 'int')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">AVG PR</span>
                                    <span className="table-cell-desc tableThC">bpm</span>
                                </th> : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'ihb').checked ?
                                <th className="medium-only" id="th8" onClick={this.tablesort.bind(this, 'tbl', 0, 8, 'int')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">IHB</span>
                                    <span className="table-cell-desc tableThC">%</span>
                                </th> : null}
                            {/* <th className="medium-only">MONITORING PROGRAM</th> */}
                            {this.state.patientFilterHeader.find(item => item.code === 'spO2').checked ?
                                <th className="medium-only" id="th9" onClick={this.tablesort.bind(this, 'tbl', 0, 9, 'int')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">SpO2</span>
                                    <span className="table-cell-desc tableThC">%</span>
                                </th> : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'glucose').checked ?
                                <th className="medium-only" id="th10" onClick={this.tablesort.bind(this, 'tbl', 0, 10, 'int')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">Glucose</span>
                                    <span className="table-cell-desc tableThC">mmol</span>
                                </th> : null}
                            {this.state.patientFilterHeader.find(item => item.code === 'chat').checked ?
                                <th className="medium-only" id="th11" onClick={this.tablesort.bind(this, 'tbl', 0, 11, 'img')}>
                                    <span className="arrow_up"></span>
                                    <span className="arrow_down"></span>
                                    <span className="arrow_title">CHAT</span>
                                </th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        {userTR}
                    </tbody>
                </table>


            </div>
        );
    }

    resNewUser() {
        app.views.main.router.navigate(ROUTES.registerNewUser);
    }

    btnColumns() {
        app.popover.open('.popover-column');

    }


    onMoviesChange = () => {
        const _checked = true
        AuthGuard.setSearchPatientsFilter('', _checked)
        this.setState({
            patientFilterHeader: AuthGuard.getSearchPatientsFilter()
        })
        console.log(AuthGuard.getSearchPatientsFilter())
    }

    handleFilterCheck(event) {
        const target = event.target;
        const _checked = target.type === 'checkbox' ? target.checked : target.value;
        const _code = target.name;
        AuthGuard.setSearchPatientsFilter(_code, _checked)
        this.setState({
            patientFilterHeader: AuthGuard.getSearchPatientsFilter()
        })
        console.log(AuthGuard.getSearchPatientsFilter())
    }

    onChangeChecked() {
        let tbl_thead_tr_th = document.querySelectorAll("#tbl thead tr th");
        let table_tbody_tr = document.querySelectorAll("#tbl tbody tr")
        let checks = document.getElementsByName('demo-checkbox');
        let arr = [];

        // console.log(tbl_thead_tr_th)

        for (let i = 0; i < checks.length; i++) {
            // checks[i].checked
            // console.log(i, checks[i].checked)
            if (checks[i].checked == false) {
                arr.push(i)
                // console.log(i, checks[i].checked)

                for (let j = 0; j < tbl_thead_tr_th.length; j++) {
                    if (i == j) {
                        tbl_thead_tr_th[j].style.display = "none"

                        // console.log(j)

                        for (let z = 0; z < table_tbody_tr.length; z++) {
                            let table_tbody_tr_td = table_tbody_tr[z].childNodes[j]
                            // console.log(z)
                            // console.log(table_tbody_tr[z])
                            // console.log(table_tbody_tr_td)
                            table_tbody_tr_td.style.display = "none"

                        }
                    }

                }

                app.popover.close('.popover-column');
            } else {
                for (let j = 0; j < tbl_thead_tr_th.length; j++) {
                    if (i == j) {
                        tbl_thead_tr_th[j].style.display = "table-cell"

                        console.log(j)


                        for (let z = 0; z < table_tbody_tr.length; z++) {
                            let table_tbody_tr_td = table_tbody_tr[z].childNodes[j]
                            // console.log(z)
                            // console.log(table_tbody_tr[z])
                            // console.log(table_tbody_tr_td)
                            table_tbody_tr_td.style.display = "table-cell"

                        }
                    }

                }
                app.popover.close('.popover-column');
            }
        }

        console.log(arr)

        sessionStorage.setItem('MonitoringColumns', arr);

        // console.log(tbl_thead_tr_th.length)
        // console.log(table_tbody_tr)
    }

    monitoringProgramChange(e) {
        console.log(e.target.value)
        this.setState({
            monitoringProgram: e.target.value,
            searchMPFlag: false
        })

    }

    day7Change(e) {
        console.log(e.target.value)
        this.setState({
            day7: e.target.value,
            searchMPFlag: false
        })
    }

    render() {
        return (
            <FramePage
                name={ROUTES.patientlist}
                onPageReinit={this.onPageReinit}
            >
                <div className="navBar"></div>
                <Row className="navTitleName">
                    <Col width="70"></Col>
                    {/* <Col width="40" style={{textAlign:'center'}}>
                        Only patients with active record last 7 days are displayed
                    </Col> */}
                    <Col width="30">{this.state.coachName}</Col>
                </Row>
                <Row className="navTitleBar">
                    <Block className="navTitle">
                        <Col>
                            User Monitoring list
                        </Col>
                        <Col className="navTitlePatientsLength">
                            No. of Patients : <span className="fb">{this.state.patientListData.length}</span>
                        </Col>
                    </Block>
                    <Block className="navTitle footerBtn">
                        <Button large raised onClick={this.btnColumns.bind(this)}>Columns</Button>
                        <Button className="fill-but-abb" large raised fill onClick={this.resNewUser.bind(this)}>Register New User</Button>
                    </Block>
                </Row>
                <Row className="search-panel">
                    <Col width="10" className="search-filter">Filters:</Col>
                    <Col width="35">
                        <Row>
                            <Col className="search-input">
                                <input
                                    type='text'
                                    name="accountIDorName"
                                    className="nameOrId"
                                    placeholder="Name or ID"
                                    value={this.state.accountIDorName}
                                    onChange={(e) => {
                                        this.setState({ accountIDorName: e.target.value });
                                    }}
                                />
                                {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                                <img src={sousuo} className="img-block" alt=''/>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="search-input">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder="Email"
                                    value={this.state.email}
                                    onChange={(e) => {
                                        this.setState({ email: e.target.value });
                                    }}
                                />
                                {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                                <img src={sousuo} className="img-block" alt='' />
                            </Col>
                        </Row>
                    </Col>
                    <Col width="35">
                        {/* <Row> */}
                            {/* <Col className="search-input">
                                <input
                                    type='text'
                                    name="monitoring_program"
                                    placeholder="Monitoring Program"
                                    value={this.state.monitoringProgram}
                                    onChange={(e) => {
                                        this.setState({ monitoringProgram: e.target.value });
                                    }}
                                />
                                <Icon f7="search" size="20px" color="gray"></Icon>
                            </Col> */}
                            {/* <Col className="formSelect search-input input-dropdown-wrap">
                                <select
                                    style={{ color: this.state.searchMPFlag === true ? 'gray' : 'black' }}
                                    value={this.state.monitoringProgram}
                                    onChange={this.monitoringProgramChange.bind(this)}
                                    className="mp-g"
                                >
                                    <option value="" disabled>Monitoring Program</option>
                                    {this.state.programOptions && this.state.programOptions.map((item, index) => {
                                        return <option value={item.label} key={"program" + item.value}>{item.label}</option>
                                    })}
                                </select>
                            </Col> */}
                        {/* </Row> */}
                        <Row>
                            <Col className="formSelect search-input input-dropdown-wrap">
                                <select
                                    style={{ color: this.state.searchMPFlag === true ? 'gray' : 'black' }}
                                    value={this.state.day7}
                                    onChange={this.day7Change.bind(this)}
                                    className="mp-g"
                                >
                                    <option value="" disabled>Patients active for last 7 days</option>
                                    <option value="1" >Past 7 days only</option>
                                    <option value="0" >All</option>
                                </select>
                            </Col>
                        </Row>
                    </Col>
                    <Col width="20">
                        <Row>
                            <Col width="100" medium="50">
                                <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearchPatient.bind(this)} >Search</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col width="100" medium="50">
                                <Button type="button" outline raised className="outline-pink-btn" onClick={this.btnClear.bind(this)}>Clear</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {AuthGuard.CanViewMonitoringPatients() ?
                    <div>
                        {this.renderUserListTable()}
                    </div>
                    : <div className="permission-prompt">You are not authorized to view this page</div>}

                < Popover className="popoverModal popover-column" closeByOutsideClick={false}>
                    <BlockTitle>Table Filters
                        <Block className="navTitle footerBtn">
                            <Button className="fill-but-abb" large raised fill onClick={this.onMoviesChange.bind(this)}>Reset To Default</Button>
                        </Block>
                    </BlockTitle>
                    <div id="table-checkbox">
                        <List>
                            {
                                this.state.patientFilterHeader.map((item, index) => {

                                    return <ListItem key={"patientFilter" + index} checkbox checked={Boolean(item.checked)} onChange={this.handleFilterCheck.bind(this)} title={item.name} name={item.code} value={item.code}></ListItem>
                                })
                            }
                        </List>
                    </div>
                    {/* <div className="popFooter">
                        <Button type="button" outline raised className="outline-pink-btn" popoverClose>Cancel</Button>
                        <Button type="button" fill raised className="fill-pink-btn" onClick={this.onChangeChecked.bind()}>Done</Button>
                    </div> */}
                </Popover>
            </FramePage >
        );
    }
}

const mapStateToProps = (state) => {
    const { patientList } = state;
    return {
        patientListData: patientList.patientListData
    };
};

export default connect(
    mapStateToProps,
    {
        GetPatientList
    }
)(PatientList)