import React, { Component } from 'react';
import { f7 as app, Page, Button, View, Row, Col, Icon } from 'framework7-react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { GetSessionList, RedeemSessionStatus } from '../redux/actions'
import SideBar from '../components/SideBar'
import moment from 'moment';
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { showDate } from '../commons/Helpers';

class SessionList extends Component {
    constructor(props) {
        super(props);
        this.fetchDataIsLoading = false;
        this.state = {
            session_id: '',
            mobile_number: '',
            email: '',
            status_code: '',
            total: 0,
            coachID: '',
            coachName: '',
            sessionList: []
        }
    }

    componentDidMount() {
        let self = this;
        this.$f7ready((f7) => {
            // f7.dialog.alert('Component mounted');
            if (!self.fetchDataIsLoading) {
                setTimeout(() => {
                    self.getListSession();
                }, 100);
            }
        });
    }

    componentWillReceiveProps(props) {
        const { sessionList, userLogin } = props
        this.fetchDataIsLoading = false;
        if (sessionList.sessionListData.result != null && sessionList.sessionListData.result !== undefined) {
            this.setState({
                total: sessionList.sessionListData.total,
                sessionList: sessionList.sessionListData.result,
                coachID: userLogin.userLoginData.coachID,
                coachName: userLogin.userLoginData.coachName,
            });
        } else {
            this.setState({
                total: 0,
                sessionList: [],
            });
        }
    }

    componentDidUpdate() {
        // console.log(this.props);
        app.preloader.hide();
    }

    btnSearchSession() {
        this.getListSession();
    }

    btnClearSession() {
        this.setState({
            session_id: '',
            mobile_number: '',
            email: '',
            status_code: ''
        });
        this.getListSession();
    }

    getListSession() {
        this.fetchDataIsLoading = true;
        app.preloader.show();

        this.props.GetSessionList(
            this.state.session_id,
            this.state.mobile_number,
            this.state.email,
            this.state.status_code
        );
    }

    btnRedeeam = (sessionId, e) => {
        app.preloader.show();
        this.props.RedeemSessionStatus(sessionId);
    }

    renderSessionListTable() {
        const sessionTR = [];
        var no = 0;
        this.state.sessionList.map((session) => {
            var _created_at = showDate(session.created_at);
            var dayCount = moment(moment(new Date()), "YYYY-MM-DD").diff(moment(session.expired_at, "YYYY-MM-DD"), 'days');
            var leftDays = '';
            if (dayCount < 0) {
                leftDays = Math.abs(dayCount) + ' days left';
            }
            sessionTR.push(
                <tr key={session.id}>
                    <td className="label-cell">{++no}</td>
                    <td className="numeric-cell">{session.session_id}</td>
                    <td className="medium-only">{session.name}</td>
                    <td className="medium-only">{session.email.split("@")[0]} <span className="table-cell-desc">@{session.email.split("@")[1]}</span> </td>
                    <td className="medium-only">{session.dialing_code} {session.mobile_number}</td>
                    <td className="medium-only">{_created_at} <span className="table-cell-desc">{leftDays}</span></td>
                    <td className="medium-only">
                        {session.status_code === 'new' ?
                            (<span style={{ color: '#5BB3C4', fontWeight: '700' }}>{session.status_display}</span>) :
                            (<span style={{ color: 'grey', fontWeight: '700' }}>{session.status_display}</span>)
                        }
                    </td>
                    <td className="medium-only">
                        {AuthGuard.CanEditSessionIdStatus() && session.status_code === 'new' ?
                            (<Button onClick={this.btnRedeeam.bind(this, session.id)} fill style={{ backgroundColor: '#5BB3C4', color: 'white' }}> REDEEM</Button>) : ''
                        }
                    </td>
                </tr>
            );
        });
        return (
            <div className="data-table card">
                <table>
                    <thead>
                        <tr>
                            <th className="label-cell">NO.</th>
                            <th className="numeric-cell">SESSION ID</th>
                            <th className="medium-only">NAME</th>
                            <th className="medium-only">EMAIL</th>
                            <th className="medium-only">MOBILE</th>
                            <th className="medium-only">CREATED ON</th>
                            <th className="medium-only">STATUS</th>
                            <th className="medium-only">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessionTR}
                    </tbody>
                </table>
            </div>
        )
    }

    render() {
        return (
            <FramePage
                name={ROUTES.sessionlist}
            >
                <Row className="navTitleBar">
                    <Col> Session List </Col>
                    <Col>
                        No. of Session ID : <span className="fb">{this.state.total}</span>
                    </Col>
                </Row>
                <div className="box-header">
                    <Row className="search-panel">
                        <Col width="10" className="search-filter">
                            Filter:
                            </Col>
                        <Col width="35">
                            <Row>
                                <Col className="search-input">
                                    <input
                                        type='text'
                                        name="session_id"
                                        className="sessionId"
                                        placeholder="Session ID"
                                        value={this.state.session_id}
                                        onChange={(e) => {
                                            this.setState({ session_id: e.target.value.trim() });
                                        }}
                                    />
                                    <Icon f7="search" size="20px" color="gray"></Icon>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="search-input">
                                    <input
                                        type="text"
                                        name="mobile_number"
                                        placeholder="Mobile No."
                                        value={this.state.mobile_number}
                                        onChange={(e) => {
                                            this.setState({ mobile_number: e.target.value.trim() });
                                        }}
                                    />
                                    <Icon f7="search" size="20px" color="gray"></Icon>
                                </Col>
                            </Row>
                        </Col>
                        <Col width="35">
                            <Row>
                                <Col className="search-input">
                                    <input
                                        type='text'
                                        name="email"
                                        placeholder="Email"
                                        value={this.state.email}
                                        onChange={(e) => {
                                            this.setState({ email: e.target.value.trim() });
                                        }}
                                    />
                                    <Icon f7="search" size="20px" color="gray"></Icon>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="search-input input-dropdown-wrap">
                                    <select
                                        name="status_code"
                                        value={this.state.status_code}
                                        onChange={(e) => {
                                            this.setState({ status_code: e.target.value });
                                        }}
                                    >
                                        <option value="">Status</option>
                                        <option value="new">NEW</option>
                                        <option value="redeemed">Redeemed</option>
                                        <option value="expired">Expired</option>
                                    </select>
                                </Col>
                            </Row>
                        </Col>
                        <Col width="20">
                            <Row>
                                <Col width="50">
                                    <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearchSession.bind(this)}>Search</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col width="50">
                                    <Button type="button" outline raised className="outline-pink-btn" onClick={this.btnClearSession.bind(this)}>Clear</Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                {AuthGuard.CanSetting() || AuthGuard.CanEditSessionIdStatus() ?
                    <div>
                        {this.renderSessionListTable()}
                    </div>
                    : <div className="permission-prompt">You are not authorized to view this page</div>}
            </FramePage>
        );
    }
}

SessionList.propTypes = {
    GetSessionList: PropTypes.func.isRequired,
    sessionList: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    const { userLogin, sessionList } = state;
    return {
        userLogin,
        sessionList
    };
};

export default connect(
    mapStateToProps,
    {
        GetSessionList,
        RedeemSessionStatus
    }
)(SessionList)