// 1. cancel btn:
// 'pending', 'rescheduled';

// 2. rescheduled btn:
// 'pending',

// 3. confirm btn
// 'pending'; 'rescheduled';
import React, { Component } from 'react';
import { f7 as app, Page, Row, Col, Button, Appbar, View, Link, Tabs, Tab, Badge, Popup, Block, Icon, Preloader, Fab, PageContent } from 'framework7-react';
import { ROUTES, AlertMsg, WeekEveryDay } from '../commons/Constants'
import ChatPopUp from '../components/ChatPopUp'
import ReschedulePopUp from '../components/ReschedulePopUp'
import { ShowToast, setCache, showDate, getSlotTimes, diffDateTime, isInvalid } from '../commons/Helpers'
import AuthGuard from '../services/AuthGuard'
import IconCalling from '../images/icons/icon_calling.gif'
import IconCancel from '../images/icons/icon_cancel.svg'
import RingtoneMp3 from '../sounds/ringtone.mp3'
import CallingMp3 from '../sounds/calling.mp3'
import EndCallMp3 from '../sounds/endCall.mp3'

import moment from 'moment';
import FramePage from '../components/FramePage';
import { tsObjectKeyword } from '@babel/types';
import events from '../services/events';
import sousuo from '../images/icons/sousuo.png';
import info from '../images/icons/info.png';

let timer = '';
class Appointments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoCallId: "",
            pendingCallData: [],
            historyCallData: [],
            pendingChatTotal: 0,
            historyChatTotal: 0,
            popupOpened: false,
            dialogID: "",
            chat_userId: "",
            user_profile_id: "",
            userName: "",
            userAvatar: false,
            popupOpened1: false,
            accountIDorName: "",
            accountDate: "",
            accountTime: "",
            mobile_no: "",
            lastTime: "",
            tabType: "pending", //pending
            originUser: {},
            countHistoryUserQueue: 0,
            countTodayUserQueue: 0,
            countUpcomingUserQueue: 0,
            _tempCountUpcomingUserQueue: 0,
            firstPatientName: "",
            firstPatientQueueInfo: "",
            getPatientQueueNotice: true,
            coachStatus: "",
            cancelQueneId: "",
            cancelQueneUserName: "",
            rescheduleQid: "",
            timeSlotsDrId: "",
            timeSlotsDrUserProfileId: "",
            loadingCancelBtn: false,
            requestLoading: false,
            pageContentScroolHeight: 0,
            showScroolTopBtn: false,
            drList: [],
            confirmLoading: false,
            startCallLoading: false,
            searching: false,
            upconmingsearching: false,
            noMoreResult: false,
            firstLoad: true,
            role_code: "",
            todayCallData: [],
            dates: [],
            times: [],
            searchFlag: true,
            searchTimeFlag: true,
            coachName: '',
        };

        this.coachProfile = AuthGuard.getCoachProfile();
        this.limit = 10;
        this.page = 1;
        this.is_reject = "";
    }
    componentDidMount() {
        this.getDrList();
        setTimeout(() => {
            if (this.state.firstLoad) app.preloader.show();
            if (AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming()) {

                this.getListUpComing();
            } else {
                this.getListHistory();
                // this.getListToday();
                this.setState({
                    tabType: 'history'
                })
            }

        }, 200);

        this.setState({
            pageContentScroolHeight: this.$$('.page-content').prop('scrollHeight')
        })
        let self = this;
        this.$$('.page-content').on('scroll', function () {
            if (this.scrollTop > 20) {
                self.setState({
                    showScroolTopBtn: true
                })
            } else {
                self.setState({
                    showScroolTopBtn: false
                })
            }
        });

        AuthGuard.dateTimefilter({
            type: 'incoming'
        }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    dates: res.date ? res.date : [],
                    times: res.time ? res.time : [],
                    accountDate: '',
                    accountTime: ''
                })
            }
        })

    }

    componentWillReceiveProps(nextProps) {
        // const { pendingCallData } = nextProps;
    }

    componentDidUpdate() {
        // app.preloader.hide();
    }

    componentWillMount() {
        // console.log(timer);
        if (timer) clearInterval(timer)
        // console.log('componentWillMount');
    }

    componentWillUnmount() {
        app.popup.close('.CallNotification-popup');
    }

    getCoachStatus() {
        let self = this;
        if (this.coachProfile.coachId && !this.state.coachStatus) {
            AuthGuard.getCoachByID({ id: this.coachProfile.coachId }, (isOk, res) => {
                if (isOk) {
                    self.setState({
                        coachStatus: res.status,
                        coachName: res.coachName
                    })
                }
                // setTimeout(() => {
                app.preloader.hide()
                this.setState({
                    firstLoad: false
                })
                // }, 2 * 1000);
            })
        } else {
            app.preloader.hide()
            this.setState({
                firstLoad: false
            })
        }
    }

    getDrList() {
        let { clinic_info_id } = this.coachProfile;
        AuthGuard.getDrlist({
            clinic_id: clinic_info_id
        }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    drList: res
                })
            }
        })
    }

    handlyDr(_queue_id, e) {
        app.preloader.show();
        AuthGuard.handlyDr({
            queue_id: _queue_id,
            doctor_user_profile_id: e.target.value
        }, (isOk, msg) => {
            if (isOk) {
                ShowToast(app, msg);
            }
            app.preloader.hide();
        })
    }

    getUpcomingConsCount() {
        this.setState({
            countHistoryUserQueue: AuthGuard.countHistoryUserQueue,
            countTodayUserQueue: AuthGuard.countTodayUserQueue,
            countUpcomingUserQueue: AuthGuard.countUpcomingUserQueue,
        })
        // AuthGuard.getUpcomingConsultationsCount({ },(isOk ,res)=>{
        //     if (isOk){
        //         let { countTodayUserQueue } = res;
        //         self.setState({
        //             ...res,
        //         });
        //     }
        // })
    }

    getListUpComing(accountIDorName = '', mobile_no = '', accountDate = '', accountTime = '') {
        try {
            if (timer) clearInterval(timer)
            timer = setInterval(() => {
                this.getCoachStatus();
                this.getUpcomingConsCount();

                this.getAppointments(accountIDorName, mobile_no, accountDate, accountTime)

            }, 3000);
        } catch (e) {
            console.log(e);
        }
    }

    getAppointments = (accountIDorName = '', mobile_no = '', accountDate = '', accountTime = '') => {
        let self = this;
        // console.log(this.state.requestLoading, this.state.upconmingsearching)
        // if (this.state.requestLoading && this.state.upconmingsearching){
        //     return;
        // }

        if (this.state.upconmingsearching) {
            if (timer) clearInterval(timer)
        }

        if (this.state.requestLoading) {
            return;
        }

        this.setState({
            requestLoading: true
        })

        AuthGuard.getVideoCall({
            limit: 20000,
            page: 1,
            nameOrAccountID: accountIDorName,
            mobile: mobile_no,
            lastTime: '',
            type: 'incoming',
            date: accountDate,
            time: accountTime
        }, (isOk, res) => {
            if (isOk) {
                self.setState({
                    pendingCallData: res.result,
                    pendingChatTotal: res.total,
                    confirmLoading: false
                });
                let _videoCallId = AuthGuard.videoCallInfo.videoCallId ? AuthGuard.videoCallInfo.videoCallId : '';

                if (_videoCallId) {
                    // in video call

                    this.setState({
                        requestLoading: false,
                        upconmingsearching: false
                    })
                    this.getCurAppointmentInfo(_videoCallId);
                    return;
                }

                // console.log(self.state.getPatientQueueNotice);
                let tempArr = res.result.filter((item, index) => {
                    return item.status === 'confirmed';
                })

                if (AuthGuard.CanMangeAppointments()) {
                    // console.log(tempArr);
                    if (tempArr.length >= 1 && self.state.getPatientQueueNotice) {
                        let _exTime = tempArr[0].date + ' ' + tempArr[0].appointment_time;
                        let _uq_id = tempArr[0].id;
                        if (diffDateTime(_exTime, new Date()) && AuthGuard.videoCallTime <= 0 && _uq_id !== _videoCallId) {
                            self.callBtn();
                        }
                    } else if (tempArr.length >= 1 && !self.state.getPatientQueueNotice) {
                        if (self.state.countUpcomingUserQueue > self.state._tempCountUpcomingUserQueue) {
                            self.setState({
                                getPatientQueueNotice: true
                            })
                        }
                    }
                }
            } else {

            }

            this.setState({
                requestLoading: false,
                upconmingsearching: false
            })
        });
    }

    getCurAppointmentInfo(id){
        if(this.is_reject == "Y") return;
        AuthGuard.getCurAppointmentInfo({
            id: id
        }, (isOk, res) => {
            this.is_reject = "N";
            if(isOk && res.result.is_user_reject === 'N'){
                events.emit('UserRejectVideoCall', { at: res.callApiAt });
            }
        })
    }

    getListHistory(accountIDorName = '', mobile_no = '', accountDate = '', accountTime = '') {
        try {
            this.$f7ready(async (f7) => {
                this.getCoachStatus()

                let self = this;
                if (this.state.requestLoading) return;
                this.setState({
                    requestLoading: true
                })
                app.preloader.show();
                await AuthGuard.getVideoCall({
                    limit: this.limit,
                    page: this.page,
                    nameOrAccountID: accountIDorName,
                    mobile: mobile_no,
                    // lastTime: this.state.lastTime,
                    type: 'history',
                    date: accountDate,
                    time: accountTime
                }, (isOk, res) => {
                    this.page = this.page + 1
                    app.preloader.hide();
                    if (isOk) {
                        this.setState({
                            searching: false
                        })
                        if (this.state.lastTime) {
                            let _historyCallData = this.state.historyCallData;
                            if (res.result.length > 0) {
                                this.setState(state => {
                                    var hash = {};
                                    let historyCallData = [];
                                    historyCallData = state.historyCallData.concat(res.result);
                                    historyCallData = historyCallData.reduce((item, next) => {
                                        (hash[next.id] ? '' : hash[next.id] = true) && item.push(next);
                                        return item
                                    }, [])

                                    return {
                                        historyCallData
                                    }
                                })

                                this.setState({
                                    noMoreResult: false
                                })
                            } else {
                                this.setState({
                                    noMoreResult: true
                                })
                            }

                        } else {

                            //如果已经有数据的话，自动请求是数组拼接
                            if (self.state.historyCallData.length > 0 && res.result.length > 0) {

                                let hash = {};
                                let newList = res.result.concat(self.state.historyCallData);
                                newList = newList.reduce((item, next) => {
                                    (hash[next.id] ? '' : hash[next.id] = true) && item.push(next);
                                    return item
                                }, [])
                                self.setState({
                                    historyCallData: newList
                                });
                            } else {
                                self.setState({
                                    historyCallData: res.result
                                });
                            }

                        }

                    } else {

                    }

                    this.setState({
                        requestLoading: false,
                        lastTime: ''
                    })
                });

            })
        } catch (e) {
            // console.log(e);
        }
    }


    getListToday(accountIDorName = '', mobile_no = '', accountDate = '', accountTime = '') {
        try {
            this.$f7ready(async (f7) => {
                this.getCoachStatus()
                let self = this;
                if (this.state.requestLoading) return;
                this.setState({
                    requestLoading: true
                })
                app.preloader.show();
                await AuthGuard.getVideoCall({
                    limit: this.limit,
                    page: this.page,
                    nameOrAccountID: accountIDorName,
                    mobile: mobile_no,
                    // lastTime: this.state.lastTime,
                    type: 'today',
                    date: accountDate,
                    time: accountTime
                }, (isOk, res) => {
                    this.page = this.page + 1
                    app.preloader.hide();
                    if (isOk) {
                        this.setState({
                            searching: false
                        })
                        if (this.state.lastTime) {
                            let _todayCallData = this.state.todayCallData;
                            if (res.result.length > 0) {
                                this.setState(state => {
                                    var hash = {};
                                    let todayCallData = [];
                                    todayCallData = state.todayCallData.concat(res.result);
                                    todayCallData = todayCallData.reduce((item, next) => {
                                        (hash[next.id] ? '' : hash[next.id] = true) && item.push(next);
                                        return item
                                    }, [])

                                    return {
                                        todayCallData
                                    }
                                })

                                this.setState({
                                    noMoreResult: false
                                })
                            } else {
                                this.setState({
                                    noMoreResult: true
                                })
                            }

                        } else {

                            //如果已经有数据的话，自动请求是数组拼接
                            if (self.state.todayCallData.length > 0 && res.result.length > 0) {

                                let hash = {};
                                let newList = res.result.concat(self.state.todayCallData);
                                newList = newList.reduce((item, next) => {
                                    (hash[next.id] ? '' : hash[next.id] = true) && item.push(next);
                                    return item
                                }, [])
                                self.setState({
                                    todayCallData: newList
                                });
                            } else {
                                self.setState({
                                    todayCallData: res.result
                                });
                            }

                        }

                    } else {

                    }

                    this.setState({
                        requestLoading: false,
                        lastTime: ''
                    })
                });

            })
        } catch (e) {
            // console.log(e);
        }
    }


    goToConsultationSummary(id, e) {
        if (timer) clearInterval(timer)
        app.views.main.router.navigate(ROUTES.consultationSummary, {
            props: { videoCallId: id },
            reloadCurrent: true,
            history: true
        });
    }

    openVideoCall(chat, e) {
        let _id = chat.id
        // console.log(_id);
        let self = this;
        this.setState({
            videoCallId: _id
        });

        if (!AuthGuard.consultationDetailPopupOpen) {
            this.setState({
                startCallLoading: _id,
            })
            if (timer) clearInterval(timer)
            app.preloader.show();
            AuthGuard.drStartCall({ id: _id }, (isok, res) => {
                console.log('drStartCall', { originUser: res, videoCallId: _id, chat: chat })
                if (isok) {
                    res.userProfileId = chat.user_profile_id;
                    self.setState({
                        originUser: res
                    })
                    AuthGuard.setVideoCallInfo({ originUser: res, videoCallId: _id, chat: chat, canConnect: false });
                    AuthGuard.twilioInitialized = false
                    app.popup.open('.consultationDetailPage-popup');
                } else {
                    app.dialog.alert(AlertMsg.videoCallStartFail, 'Notice');
                }
                this.setState({
                    startCallLoading: false
                })
                app.preloader.hide();
                this.getListUpComing();
            })
        } else {
            app.dialog.alert('Oops! you are currently in a consultation session with a patient.', 'Notice');
        }
    }


    chatPop(dialogID, chatUserId, userProfileId, userName, e) {
        let self = this;
        new Promise((resolve, reject) => { this.getProfileImage(userProfileId, resolve, reject) }).then(data => {
            self.setState({
                popupOpened: true,
                dialogID: dialogID,
                chat_userId: chatUserId,
                user_profile_id: userProfileId,
                userName: userName,
                userAvatar: data.url ? data.url.s : false,
                popupOpened1: true
            });
        })
    }

    callBtn() {
        let self = this;
        app.popup.open('.CallNotification-popup');
        this.setState({
            getPatientQueueNotice: false,
            _tempCountUpcomingUserQueue: this.state.countUpcomingUserQueue
        })
        if (this.coachProfile.userType === 'COACH') {
            this.playAudio('ringtoneMp3');
        }
        let confirmedQue = this.state.pendingCallData.length > 0 ? this.state.pendingCallData.filter((item, index) => {
            return item.status === 'confirmed';
        }) : '';
        this.setState({
            firstPatientName: confirmedQue[0].user_name,
            firstPatientQueueInfo: confirmedQue[0]
        });
        // this.playAudio('callingMp3');
    }

    acceptCallNow = () => {
        this.CNPopupClose();
        setTimeout(() => {
            this.setState({
                getPatientQueueNotice: false
            });

            app.popup.close('.CallNotification-popup');

            this.openVideoCall(this.state.firstPatientQueueInfo, this);
        }, 100);
    }


    closeCallNotificationPopup = () => {
        app.popup.close('.CallNotification-popup');
    }

    closeCancelConsultationPopup = () => {
        app.popup.close('.CancelConsultation-popup');
    }

    playAudio(audioID) {
        var audio = document.getElementById(audioID);
        if (audio !== null) {
            if (audio.paused) {
                audio.play();// 播放
                document.getElementById(audioID).loop = true;
            }
        }
    }

    closeAudio(audioID) {
        var audio = document.getElementById(audioID);
        if (audio !== null) {
            if (!audio.paused) {
                audio.pause();// 播放
                audio.load();
                document.getElementById(audioID).loop = false;
            }
        }
    }

    CNPopupClose() {
        this.setState({
            getPatientQueueNotice: false
        })
        if (this.coachProfile.userType === "COACH") {
            this.closeAudio("ringtoneMp3");
        }
        // this.closeAudio('callingMp3');
    }

    getProfileImage(userProfileId, resolve, reject) {
        AuthGuard.getProfileImage({ userProfileId: userProfileId }, (isOk, res) => {
            if (isOk) {
                resolve(res);
            } else {
                resolve(res);
            }
            // reject('');
        });
    }

    chatPopExpired() {
        app.dialog.alert(AlertMsg.chatServiceError, 'Notice');
    }

    onPageBeforeRemove() {
        const self = this;
        // console.log('onPageBeforeRemove')
        // Destroy popup when page removed
        if (self.popup) self.popup.destroy();
        if (self.calendar) self.calendar.destroy('#reschedule-calendar');
        if (timer) clearInterval(timer)
        // console.log(timer);
    }

    onPageBeforeOut() {
        if (timer) clearInterval(timer)
    }

    onPageReinit = (e) => {
        this.setState({
            historyCallData: []
        }, () => {
            this.tabClick(this.state.tabType, this);
        })

        setTimeout(() => {
            this.getCoachStatus();
        }, 200);

    }

    handlePopClosed(e) {
        this.setState({
            popupOpened: false
        })
    }

    btnSearch() {
        if (timer) clearInterval(timer);
        let { accountIDorName, mobile_no, accountDate, accountTime } = this.state;
        this.page = 1
        if (this.state.tabType === 'history') {
            this.setState({
                historyCallData: [],
                searching: true
            }, () => {
                this.getListHistory(accountIDorName, mobile_no, accountDate, accountTime);
            })
        } else if (this.state.tabType === 'today') {
            this.setState({
                todayCallData: [],
                // searching: true
            }, () => {
                this.getListToday(accountIDorName, mobile_no, accountDate, accountTime);
            })
        } else {
            let needtimer = false
            if (accountIDorName || mobile_no || accountDate || accountTime) {
                needtimer = true
            } else {
                needtimer = false
            }
            this.setState({
                requestLoading: false,
                upconmingsearching: needtimer
            }, () => {
                this.getListUpComing(accountIDorName, mobile_no, accountDate, accountTime);
                // this.getAppointments(accountIDorName, mobile_no)

            })
        }
    }

    onChange = e => {
        // console.log(e)
        let { name, value } = e.target;
        this.setState({ [name]: value });
    }

    _changeStatus(status) {
        app.preloader.show();
        AuthGuard.changeStatus({ status: status }, (isok, res) => {
            app.preloader.hide();
            ShowToast(app, res);
        })
    }
    changeLineStatus(type, e) {
        let self = this;
        if (type === 'online') {
            if (self.$$('.offline').hasClass('activeBtn')) {
                self.$$('.offline').removeClass('activeBtn');
                self.$$('.online').addClass('activeBtn');
                this._changeStatus(type);
            }
        }

        if (type === 'offline') {
            if (self.$$('.online').hasClass('activeBtn')) {
                self.$$('.online').removeClass('activeBtn');
                self.$$('.offline').addClass('activeBtn');
                this._changeStatus(type);
            }
        }
    }

    onUnload(event) {
        alert('ap page Refreshed')
    }


    tabClick(type, e) {

        // console.log(type)
        this.page = 1
        this.setState({
            tabType: type,
            requestLoading: false
        }, () => {
            if (timer) clearInterval(timer)
            if (type === 'history') {
                this.getListHistory();

                AuthGuard.dateTimefilter({
                    type: 'history'
                }, (isOk, res) => {
                    console.log(isOk, res)
                    if (isOk) {
                        this.setState({
                            dates: res.date ? res.date : [],
                            times: res.time ? res.time : [],
                            accountDate: '',
                            accountTime: '',
                            searchFlag: true
                        })
                    }
                })

            } else if (type === 'today') {

                this.getListToday();

                AuthGuard.dateTimefilter({
                    type: 'today'
                }, (isOk, res) => {
                    console.log(isOk, res)
                    if (isOk) {
                        this.setState({
                            dates: res.date ? res.date : [],
                            times: res.time ? res.time : [],
                            accountDate: '',
                            accountTime: '',
                            searchFlag: true
                        })
                    }
                })

            } else {
                this.getListUpComing();

                AuthGuard.dateTimefilter({
                    type: 'incoming'
                }, (isOk, res) => {
                    console.log(isOk, res)
                    if (isOk) {
                        this.setState({
                            dates: res.date ? res.date : [],
                            times: res.time ? res.time : [],
                            accountDate: '',
                            accountTime: '',
                            searchFlag: true
                        })
                    }
                })
            }
        });
    }

    videoCallTabShow(type, e) {
    }

    videoCallTabHide(type, e) {
    }

    openReschedulePop(qid, drId) {
        let drInfo = this.state.drList.find(item => item.user_profile_id === drId)
        // console.log(drInfo)
        //timeSlotsDrId 是drInfo 的 id
        //timeSlotsDrUserProfileId 是drInfo 的 UserProfileId 也是 appointment list 里面的 handleby
        this.setState({
            rescheduleQid: qid,
            timeSlotsDrId: !isInvalid(drInfo) ? drInfo.id : '',
            timeSlotsDrUserProfileId: drId
        }, () => {
            app.popup.open('.Reschedule-popup');
        })
    }


    openCancelVideoCall(id, name) {
        this.setState({
            cancelQueneId: id,
            cancelQueneUserName: name
        }, () => {
            app.popup.open('.CancelConsultation-popup');
        })
    }

    confirmChat = (qid, e) => {
        this.setState({
            confirmLoading: qid
        })
        if (timer) clearInterval(timer)
        app.preloader.show()
        AuthGuard.queneCofirm({
            id: qid
        }, (isok, res) => {

            if (isok) {
                let _drList = this.state.pendingCallData
                let findIndex = _drList.findIndex((item) => item.id === qid)
                if (findIndex > -1) {
                    _drList[findIndex].status = 'confirmed'
                    this.setState({
                        pendingCallData: _drList
                    })
                }
            }
            app.preloader.hide()
            this.getListUpComing()
        })
    }
    cancelVideoCall = () => {
        let self = this;
        let pendingCallingArry = this.state.pendingCallData;
        let cancelQueneId = this.state.cancelQueneId;
        // Confirm
        this.setState({
            loadingCancelBtn: true
        })
        if (timer) clearInterval(timer)
        app.preloader.show()
        AuthGuard.cancelVideoCall({ id: cancelQueneId }, (isOk, message) => {
            if (isOk) {
                app.dialog.alert(message, 'Alert');
                pendingCallingArry.splice(pendingCallingArry.findIndex(item => item.id === cancelQueneId), 1)
                self.setState({
                    pendingCallData: pendingCallingArry
                });
            }
            this.setState({
                loadingCancelBtn: false
            })
            app.preloader.hide()
            this.getListUpComing()
            self.closeCancelConsultationPopup();
        });
    }

    renderHistoryTable() {
        return this.renderTable(this.state.historyCallData);
    }
    renderPendingTable() {
        return this.renderTable(this.state.pendingCallData);
    }

    renderTodayTable() {
        return this.renderTable(this.state.todayCallData);
    }


    goPatientDetail(user_profile_id, e) {
        app.views.main.router.navigate(ROUTES.patientInfo, {
            props: { user_profile_id: user_profile_id },
        });
    }

    loadMoreData() {
        if (timer) clearInterval(timer)
        let { accountIDorName, mobile_no, historyCallData, accountDate, accountTime } = this.state;
        if (historyCallData.length > 0) {
            let lastData = [...historyCallData].pop();
            this.setState({
                lastTime: lastData.updated_at
            }, () => {
                this.getListHistory(accountIDorName, mobile_no, accountDate, accountTime);
            })
        }
    }

    loadMoreTodayData() {
        if (timer) clearInterval(timer)
        let { accountIDorName, mobile_no, todayCallData, accountDate, accountTime } = this.state;
        if (todayCallData.length > 0) {
            let lastData = [...todayCallData].pop();
            this.setState({
                lastTime: lastData.updated_at
            }, () => {
                this.getListToday(accountIDorName, mobile_no, accountDate, accountTime);
            })
        }
    }

    scrollTop() {
        this.$$('.page-content').scrollTop(0, 600);
    }

    dateChange(e) {
        this.setState({
            accountDate: e.target.value,
            searchFlag: false
        })
    }

    timeChange(e) {
        this.setState({
            accountTime: e.target.value,
            searchTimeFlag: false
        })
    }

    btnClear(e) {
        this.page = 1
        this.setState({
            accountDate: '',
            accountTime: '',
            accountIDorName: '',
            mobile_no: '',
            unReadHistoryCount: '',
            unReadTodayCount: '',
            searchFlag: true,
            searchTimeFlag: true
        }, () => {

            this.btnSearch()
            // console.log('1' + this.state.accountIDorName, '2' + this.state.accountTime, '3' + this.state.accountTime, '4' + this.state.mobile_no)
        })

    }

    renderTable(dataList = []) {
        const chatTR = []
        var no = 0;
        if (dataList.length > 0) {
            dataList.map((chat) => {
                const { date, status } = chat;
                var _created_at = moment(date).format('DD MMMM YYYY');
                var status_name = '';
                if (status === 'cancel') {
                    status_name = 'CANCELLED';
                } else if (status === 'pick_up') {
                    status_name = 'PICK UP';
                } else if (status === 'on_route') {
                    status_name = 'ON ROUTE';
                } else if (status === 'progress') {
                    status_name = 'IN PROGRESS';
                } else if (status === 'no_show') {
                    status_name = 'NO SHOW';
                } else {
                    status_name = status.toUpperCase();
                }
                chatTR.push(
                    <tr key={chat.id}>
                        <td className="label-cell">{chat.queue_number}</td>
                        {this.state.tabType === 'history' || this.state.tabType === 'today' ? (
                            <td className="label-cell">{chat.order_no ? chat.order_no : '--'}</td>
                        ) : null}
                        <td className="numeric-cell">{_created_at}<br /><span className="table-cell-desc tableThC">{chat.time}</span> </td>

                        {this.state.tabType === 'history' || this.state.tabType === 'today' ? (
                            <td className="numeric-cell">{chat.video_call_date ? chat.video_call_date : ''}<br /><span className="table-cell-desc tableThC">{chat.video_call_time ? chat.video_call_time : ''}</span> </td>
                        ) : null}
                        <td className="numeric-cell">
                            <span className="canClick" onClick={this.goPatientDetail.bind(this, chat.user_profile_id)}>
                                {chat.user_name}
                            </span></td>
                        <td className="numeric-cell">
                            {AuthGuard.CanMangeAppointments() && this.state.tabType === 'pending' ?
                                <Row>
                                    <Col className="noline-input search-input input-dropdown-wrap">
                                        <select
                                            name="doctor_user_profile_id"
                                            value={chat.handle_by ? chat.handle_by : ''}
                                            onChange={this.handlyDr.bind(this, chat.id)}
                                        >
                                            <option value="">--</option>
                                            {this.state.drList && this.state.drList.map((item, index) => {
                                                return <option key={`dr-${item.user_profile_id}`} value={item.user_profile_id}>{item.user_name}</option>
                                            })}
                                        </select>
                                    </Col>
                                </Row> : chat.handle_by_name
                            }
                        </td>
                        <td className={`numeric-cell fwl ${chat.status === 'pending' || chat.status === 'paying' || chat.status === 'processing' || chat.status === 'rescheduled' || chat.status === 'paying' || chat.status === 'checkout' || chat.status === 'pick_up' || chat.status === 'on_route' ? 'txt-red' : (chat.status === 'confirmed' ? 'txt-green' : 'txt-gray')}`}>
                            {status_name}
                        </td>
                        {this.state.tabType === 'history' || this.state.tabType === 'today' ? (
                            <td className="numeric-cell fwl" align="center">{chat.delivery_info && chat.delivery_info.name ? chat.delivery_info.name : '--'}</td>
                        ) : null}
                        {AuthGuard.CanMangeAppointments() || AuthGuard.CanEditConsultationSummary() ?
                            <td width={this.state.tabType === 'history' || this.state.tabType === 'today' ? '' : '35%'} className="numeric-cell fwl " align='center'>
                                <Row>
                                    {AuthGuard.CanMangeAppointments() ? (
                                        <>
                                            {this.state.tabType === 'pending' || this.state.tabType === 'rescheduled' ? (
                                                <Col>
                                                    <Button outline raised onClick={this.openCancelVideoCall.bind(this, chat.id, chat.user_name)} className="outline-blue-btn" > Cancel</Button>
                                                </Col>
                                            ) : ''}
                                            {this.state.tabType === 'pending' ? (
                                                <Col>
                                                    <Button
                                                        outline
                                                        raised
                                                        onClick={this.openReschedulePop.bind(this, chat.id, chat.handle_by)} className="outline-blue-btn" > Reschedule </Button>
                                                </Col>
                                            ) : ''}

                                            {chat.status !== 'confirmed' && chat.status !== 'progress' ? (
                                                this.state.tabType === 'pending' || this.state.tabType === 'rescheduled' ? (
                                                    <Col>
                                                        <Button disabled={this.state.confirmLoading === chat.id || chat.item_status === "doctor_rescheduled" || chat.item_status === "doctor_scheduled" || (chat.item_status === "pending"  && chat.channel === "dashboard")} outline raised onClick={this.confirmChat.bind(this, chat.id)} className="outline-blue-btn" > Confirm  </Button>
                                                    </Col>) : ''
                                            ) : (
                                                    chat.status === 'confirmed' || chat.status == 'progress' ? (
                                                        <Col>
                                                            <Button fill raised disabled={this.state.startCallLoading === chat.id || this.coachProfile.userType !== 'COACH'} onClick={this.openVideoCall.bind(this, chat)} className="fill-blue-btn" > Call</Button>
                                                        </Col>
                                                    ) : ''
                                                )}
                                        </>
                                    ) : ''}
                                    {this.state.tabType === 'history' || this.state.tabType === 'today' && AuthGuard.CanEditConsultationSummary() ? (
                                        <Col className="cell_center">
                                            <Button fill raised onClick={this.goToConsultationSummary.bind(this, chat.id)} className="fill-blue-btn cell_btn" > View</Button>
                                        </Col>
                                    ) : ''}
                                </Row>
                            </td>
                            : null}
                    </tr>
                );
            });
        }

        return (
            <>
                <Row className="search-panel" style={{ paddingBottom: '0px' }}>
                    <Col width="10" className="search-filter">
                        Filters:
                    </Col>
                    <Col width="35">
                        <Row>
                            <Col className="search-input">
                                <input
                                    type='text'
                                    name="accountIDorName"
                                    className="nameOrId"
                                    placeholder="Name"
                                    value={this.state.accountIDorName}
                                    onChange={this.onChange}
                                />
                                {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                                <img src={sousuo} className="img-block" />
                            </Col>
                        </Row>
                    </Col>
                    <Col width="35">
                        <Row>
                            <Col className="search-input">
                                <input
                                    type='text'
                                    name="mobile_no"
                                    placeholder="Mobile No."
                                    onChange={this.onChange}
                                    value={this.state.mobile_no}
                                />
                                {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                                <img src={sousuo} className="img-block" />
                            </Col>
                        </Row>
                    </Col>
                    <Col width="20">
                        <Row>
                            <Col width="100" medium="50">
                                {this.state.tabActive !== 'history' ?
                                    <Button type="button" fill raised disabled={this.state.upconmingsearching} className="fill-pink-btn" onClick={this.btnSearch.bind(this)} >{this.state.upconmingsearching ? 'Searching' : 'Search'}</Button>
                                    :
                                    <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearch.bind(this)} >Search</Button>

                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="search-panel" style={{ paddingTop: '0px' }}>
                    <Col width="10" className="search-filter">
                    </Col>
                    <Col width="35">
                        <Row>
                            <Col className="formSelect search-input input-dropdown-wrap">
                                <select
                                    id={`accountDate${this.state.tabType}`}
                                    name="accountDate"
                                    // className="mp-g"
                                    style={{ color: this.state.searchFlag === true ? 'gray' : 'black' }}
                                    value={this.state.accountDate}
                                    onChange={this.dateChange.bind(this)}
                                >
                                    <option value="" disabled>Date</option>
                                    {this.state.dates && this.state.dates.map((item, index) => {
                                        return <option value={item} key={index}>{item}</option>
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </Col>
                    <Col width="35">
                        <Row>
                            <Col className="formSelect search-input input-dropdown-wrap">
                                <select
                                    id={`accountTime${this.state.tabType}`}
                                    name="accountTime"
                                    // className="mp-g"
                                    style={{ color: this.state.searchTimeFlag === true ? 'gray' : 'black' }}
                                    value={this.state.accountTime}
                                    onChange={this.timeChange.bind(this)}
                                >
                                    <option value="" disabled>Time</option>
                                    {this.state.times && this.state.times.map((item, index) => {
                                        return <option value={item} key={index}>{item}</option>
                                    })}
                                </select>
                            </Col>
                        </Row>
                    </Col>
                    <Col width="20">
                        <Row>
                            <Col width="100" medium="50">
                                <Button type="button" outline raised className="outline-pink-btn" onClick={this.btnClear.bind(this)}>Clear</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className="data-table">
                    <table>
                        <thead>
                            <tr>
                                <th className="label-cell">QUEUE NO. </th>
                                {this.state.tabType == 'history' || this.state.tabType === 'today' ? (
                                    <th className="label-cell">ORDER NO. </th>
                                ) : null}
                                <th className="numeric-cell">DATE & TIME</th>
                                {this.state.tabType === 'history' || this.state.tabType === 'today' ? (
                                    <th className="numeric-cell">CALL DATE & TIME</th>
                                ) : null}
                                <th className="numeric-cell">NAME</th>
                                <th className="numeric-cell">DOCTOR</th>
                                <th className="numeric-cell">STATUS&nbsp;
                           
                                    <Icon f7="info_circle_fill" className="tooltipIcon" tooltip="
                                        <div>
                                            <b>Pending</b> <br />
                                            Appointment pending clinic confirmation
                                        </div><br />
                                        <div>
                                            <b>Rescheduled</b> <br />
                                            Appointment pending clinic confirmation<br />
                                            doctor/patient has not confirmed appointment
                                            </div><br />
                                        <div>
                                            <b>In Progress</b> <br />
                                            Video call is in progress. Provider has not ended the teleconsultation.
                                            </div><br />
                                        <div>
                                            <b>Checkout</b> <br />
                                            appointment confirmed<br />
                                            video call end, clinic has not confirmed total payable<br />
                                        </div><br />
                                        <div>
                                        <b>Paying</b> <br />
                                            total payable confirmed, pending patient to make payment<br />
                                        </div><br />
        
                                        <div>
                                            <b>Paid</b> <br />
                                            patient has completed payment and delivery is not required
                                        </div><br />
                                        <div>
                                            <b>Pick up (Standard, Express, Self-Collect, Clinic Delivery)</b> <br />
                                            Patient has completed payment. Pending delivery pick up from clinic.
                                        </div><br />
                                        <div>
                                            <b>On route</b> <br />
                                            Picked up from clinic, delivering to patient
                                        </div><br />
                                        <div>
                                            <b>Delivered</b> <br />
                                            Delivered to patient
                                        </div><br />
                                        <div>
                                            <b>Cancelled</b> <br />
                                            appointment cancelled
                                        </div><br />
                                    "></Icon>
                                </th>
                                {this.state.tabType === 'history' || this.state.tabType === 'today' ? (
                                    <th className="numeric-cell">DELIVERY </th>
                                ) : null}
                                <th width={this.state.tabType === 'history' || this.state.tabType === 'today' ? '' : '35%'} className="numeric-cell thCenter">ACTION</th>
                            </tr>
                        </thead>
                        <tbody>
                            {chatTR.length > 0 ? chatTR : (

                                <tr>
                                    <td colSpan={this.state.tabType === 'history' || this.state.tabType === 'today' ? '9' : '7'} align="center">
                                        <span className="txt-gray"> {this.state.upconmingsearching || this.state.requestLoading ? 'Searching please wait...' : 'No new appointments'} </span>
                                    </td>
                                </tr>
                            )}
                            {this.state.tabType === 'history' && this.state.historyCallData.length > 0 && this.state.historyCallData.length < this.state.countHistoryUserQueue ?
                                <tr>
                                    <td colSpan={this.state.tabType === 'history' ? '9' : '7'} align="center">
                                        {this.state.requestLoading ?
                                            <Preloader></Preloader> :
                                            <span onClick={this.loadMoreData.bind(this)} className="txt-gray canClick"> {this.state.noMoreResult ? 'No More Result' : 'Click to load more...'} </span>
                                        }
                                    </td>
                                </tr> : null}
                            {this.state.tabType === 'today' && this.state.todayCallData.length > 0 && this.state.todayCallData.length < this.state.countTodayUserQueue ?
                                <tr>
                                    <td colSpan='8' align="center">
                                        {this.state.requestLoading ?
                                            <Preloader></Preloader> :
                                            <span onClick={this.loadMoreTodayData.bind(this)} className="txt-gray canClick"> {this.state.noMoreResult ? 'No More Result' : 'Click to load more...'} </span>
                                        }
                                    </td>
                                </tr> : null}
                        </tbody>
                    </table>
                </div>
            </>
        )
    }

    render() {
        return (
            <FramePage
                name={ROUTES.appointments}
                onPageBeforeOut={this.onPageBeforeOut.bind(this)}
                onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}
                onPageReinit={this.onPageReinit}
            >
                <Fab className='scrolltotop' style={{ display: this.state.showScroolTopBtn ? 'block' : 'none' }} position="right-bottom" onClick={this.scrollTop.bind(this)}>
                    <Icon ios="f7:arrow_up" aurora="f7:arrow_up" md="material:arrow_upward"></Icon>
                </Fab>
                <div className="navBar"></div>
                <Row className="navTitleBar navTitleName">
                    <Col> Video Calls</Col>
                    <Col className="changeLiveStatus">
                        {/* {this.coachProfile.coachId ?
                            <div className="statusBox">
                                <Button className={`liveStatus offline ${this.state.coachStatus === 'offline' ? 'activeBtn' : ''}`} onClick={this.changeLineStatus.bind(this, 'offline')}>OFFLINE</Button>
                                <Button className={`liveStatus online ${this.state.coachStatus === 'online' ? 'activeBtn' : ''}`} onClick={this.changeLineStatus.bind(this, 'online')}>ONLINE</Button>
                            </div>
                            : ''} */}
                        <Row>
                            <Col width="70"></Col>
                            <Col width="30">{this.state.coachName}</Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="viewContent">
                    <Col>
                        <div className="cellTabbar tabbar">
                            {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() ?
                                <Link
                                    tabLink="#chatMonitoringTab"
                                    className="badgeTab"
                                    tabLinkActive
                                    onClick={this.tabClick.bind(this, 'pending')}
                                >
                                    Upcoming({this.state.countUpcomingUserQueue ? this.state.countUpcomingUserQueue : '0'})
                                </Link>
                                : ''}
                            {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewHistoryVideoCallList() ?
                                <Link
                                    tabLink="#todayTab"
                                    className="badgeTab"
                                    onClick={this.tabClick.bind(this, 'today')}
                                    tabLinkActive={AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() ? false : true}
                                >
                                    Today({this.state.countTodayUserQueue ? this.state.countTodayUserQueue : '0'})
                                    {/* today */}
                                </Link>
                                : ''}
                            {
                                AuthGuard.CanMangeAppointments() || AuthGuard.CanViewHistoryVideoCallList() ?
                                    <Link
                                        tabLink="#chatHistoryTab"
                                        className="badgeTab"
                                        onClick={this.tabClick.bind(this, 'history')}
                                        tabLinkActive={AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() ? false : true}
                                    >
                                        {this.state.unReadHistoryCount ? <Badge color="red">{this.state.unReadHistoryCount}</Badge> : null}

                                    History({this.state.countHistoryUserQueue ? this.state.countHistoryUserQueue : '0'})
                                </Link>
                                    : ""}
                            {/* <div className="upcomingCount">
                                {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() ?
                                    <span style={{ color: 'grey' }}>No. of Consultations today: <b className="fwb">{this.state.countTodayUserQueue}</b></span>
                                    : ""}
                            </div> */}
                        </div>
                        <Tabs animated>
                            {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() ?
                                <Tab onTabShow={this.videoCallTabShow.bind(this, 'pending')} onTabHide={this.videoCallTabHide.bind(this, 'pending')} id="chatMonitoringTab" className="" tabActive>
                                    {this.renderPendingTable()}
                                </Tab>
                                : ''}
                            {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewHistoryVideoCallList() ?
                                <Tab onTabShow={this.videoCallTabShow.bind(this, 'today')} onTabHide={this.videoCallTabHide.bind(this, 'today')} id="todayTab" className="">
                                    {this.renderTodayTable()}
                                </Tab>
                                : ''}
                            {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewHistoryVideoCallList() ?
                                <Tab onTabShow={this.videoCallTabShow.bind(this, 'history')} onTabHide={this.videoCallTabHide.bind(this, 'history')} id="chatHistoryTab" className=""
                                    tabActive={AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() ? false : true}
                                >
                                    {this.renderHistoryTable()}
                                </Tab>
                                : ''}
                        </Tabs>
                    </Col>
                </Row>
                {/* <ChatPopUp
                    popupOpened={this.state.popupOpened}
                    onPopupClosed={(e) => this.handlePopClosed(e)}
                    dialogID={this.state.dialogID}
                    chat_userId={this.state.chat_userId} //user的chatUserID
                    user_profile_id={this.state.user_profile_id}
                    userName={this.state.userName}
                    userAvatar={this.state.userAvatar}
                    type="appointmentsChatPop"
                /> */}
                <Popup
                    className="CancelConsultation-popup"
                    swipeToClose
                    swipeHandler=".swipe-handler"
                >
                    <Page>
                        <div>
                            <img src={IconCancel} alt="" className="call-cancel" />
                        </div>
                        <div>
                            <div> Cancel Consultation </div>
                            <h4 className='fwb'> {this.state.cancelQueneUserName} </h4>
                        </div>
                        <div className="footerCallPop">
                            <Row>
                                <Col>
                                    <Button fill disabled={this.state.loadingCancelBtn} raised onClick={this.cancelVideoCall} className="fill-blue-btn" > {this.state.loadingCancelBtn ? 'Canceling' : 'Cancel'} </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button outline raised onClick={this.closeCancelConsultationPopup} className="outline-blue-btn"> Back  </Button>
                                </Col>
                            </Row>
                        </div>
                    </Page>
                </Popup>
                {AuthGuard.CanMangeAppointments() && this.coachProfile.userType === 'COACH' ?
                    <Popup
                        className="CallNotification-popup"
                        swipeToClose
                        swipeHandler=".swipe-handler"
                        onPopupClose={this.CNPopupClose.bind(this)}
                    >
                        <Page>
                            <div>
                                <img src={IconCalling} alt="" className="call-pending" />
                            </div>
                            <div>
                                <div> New Video Consultation </div>
                                <h4 className='fwb'> {this.state.firstPatientName} </h4>
                            </div>
                            <div className="footerCallPop">
                                <Row>
                                    <Col>
                                        <Button fill raised onClick={this.acceptCallNow.bind(this)} className="fill-blue-btn" > Accept Call Now </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button outline raised onClick={this.closeCallNotificationPopup.bind(this)} className="outline-blue-btn"> Close  </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Page>
                    </Popup>
                    : ''}


                <ReschedulePopUp
                    rescheduleQid={this.state.rescheduleQid}
                    timeSlotsDrId={this.state.timeSlotsDrId}
                    timeSlotsDrUserProfileId={this.state.timeSlotsDrUserProfileId}
                />


                <audio src={RingtoneMp3} controls="controls" id="ringtoneMp3" hidden>
                    Your browser does not support the <code>audio</code> element.
                    </audio>
                <audio src={CallingMp3} controls="controls" id="callingMp3" hidden>
                    Your browser does not support the <code>audio</code> element.
                    </audio>
                <audio src={EndCallMp3} controls="controls" id="endCallMp3" hidden>
                    Your browser does not support the <code>audio</code> element.
                    </audio>
            </FramePage>
        );
    }
}

export default Appointments;