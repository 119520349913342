import React, { Component } from 'react';
import { f7 as app, Button } from 'framework7-react';
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { convertImgToBase64 } from '../commons/Helpers';
import AuthGuard from '../services/AuthGuard';
import lrz from "lrz";

class CropperImg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            temp_img_file: ''
        }
    }

    componentDidMount() {
        // this.converIMgtoBase54()
        // this.getBase64Image(this.props.initImg).then( data=>{
        //     console.log(data)
        // })
        let self = this
        // this.getBase64Image(this.props.initImg, 'coopCachetImg')
        // http://img1.imgtn.bdimg.com/it/u=3287613756,3276986963&fm=26&gp=0.jpg
        // let url = 'https://dev.api.ourheartvoice.com/account_service/qrcode/generate/whatsapp?code_name=shen17'
        let url = this.props.initImg
        convertImgToBase64(url, function (base64Img) {
            //转化后的base64
            // alert(base64Img);
            self.setState({
                temp_img_file: base64Img
            })
        });
    }

    getBase64Image(url, ref) {
        var that = this
        var image = new Image()
        image.src = url + '?v=' + Math.random() // 处理缓存
        image.crossOrigin = '*' // 支持跨域图片
        image.onload = function () {
            var base64 = that.drawBase64Image(image)
            console.log(base64)
            that.$refs[ref].src = base64
        }
    }

    drawBase64Image(img) {
        var canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        var ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0, img.width, img.height)
        var dataURL = canvas.toDataURL('image/png')
        console.log(dataURL)
        return dataURL
    }



    fileChange() {
        let file = document.getElementById("tempImgFile").files[0];
        if (!file) {
            return false
        }
        if (file.size > 5 * 1000 * 1000) {
            alert('File size must < 5M.')
            return false
        }        
        let type_type = file.type
        let notImg = true
        switch (type_type) {
            case 'image/jpg':
            case 'image/jpeg':
            case 'image/bmp':
            case 'image/png':
            case 'image/tif':
                notImg = false
                break;
            default:
                notImg = true
                file = '';
        }

        if (notImg) {
            alert('File not a image type.')
            return false
        }

        let reader = new FileReader();//new一个FileReader实例
        let self = this;
        reader.readAsDataURL(file)
        reader.onload = (event) => {
            self.setState({
                temp_img_file: event.srcElement.result
            })
        }

    }

    cropImage() {
        if (this.cropper.getCroppedCanvas() === 'null') {
            return false
        }
        app.preloader.show()
        lrz(this.cropper.getCroppedCanvas().toDataURL(), {
          quality: 0.6,
        }).then((results) => {
          this.props.saveImg(results.base64);
        }); 
        // let content;
        // let blob = new Blob([content], { type: "text/plain;charset=utf-8" });
        // FileSaver.saveAs(blob, "hello world.txt");
        // var arr = this.cropper.getCroppedCanvas().toDataURL().split(","),
        //   mime = arr[0].match(/:(.*?);/)[1],
        //   bstr = atob(arr[1]),
        //   n = bstr.length,
        //   u8arr = new Uint8Array(n);

        // while (n--) {
        //   u8arr[n] = bstr.charCodeAt(n);
        // }
        // var obj = new Blob([u8arr], { type: mime });
        // var fd = new FormData();
        // fd.append("files", obj, "image.png");
        // AuthGuard.uploadDrAvatar(fd, (isOk ,res) => {
        //     console.log(isOk, res);
        // });
    }

    cropCancel() {
        this.props.saveImg(this.props.initImg, 'cancel')
    }

    uploadImgFile() {
        this.$$('input[name=imgfile]').click()
    }

    converIMgtoBase54 = () => {
        console.log()
    }

    render() {
        return (
            <div className="cropperCon">
                <div className="cropperTool">
                    <Cropper
                        src={this.state.temp_img_file ? this.state.temp_img_file : this.props.initImg}
                        ref={cropper => {
                            this.cropper = cropper;
                        }}
                        style={{ height: '100%', width: "100%" }}
                        aspectRatio={500 / 500}
                        guides={false}
                        checkCrossOrigin={false}
                    />
                </div>
                <div className="cropperBtns">
                    <div style={{ display: 'none' }}>
                        <input type="file" name="imgfile" id="tempImgFile" onChange={this.fileChange.bind(this)} accept="image/gif, image/jpeg, image/png" />
                    </div>
                    <Button className="outline-blue-btn" onClick={this.uploadImgFile.bind(this)}>
                        Select Image
                    </Button>
                    <Button className="fill-blue-btn" onClick={this.cropImage.bind(this)} style={{ marginTop: '10px' }}>
                        Confirm cropping
                    </Button>
                    <Button className="fill-gradient-red-btn" onClick={this.cropCancel.bind(this)} style={{ marginTop: '10px' }}>
                        Cancel
                    </Button>
                </div>
            </div>
        );
    }
}

export default CropperImg;