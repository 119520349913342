import React, { Component } from 'react';
import { f7 as app, Popup, Page, Link, Navbar, NavLeft, Block, NavTitle, Row, Col, Preloader, PageContent, Fab, Button, FabButton, Icon } from 'framework7-react';
import AuthGuard from '../services/AuthGuard';
import { getSlotTimes, showDate, ShowToast, isIntersect } from '../commons/Helpers';
import moment from 'moment';
import { WeekEveryDay } from '../commons/Constants';
import _ from "lodash"


class ReschedulePopUp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dayEveryTime: [],
            timeslotDays: [],
            booked_timeslot: [],
            unavaliable_day: [],
            timeslot_day: '',
            rescheduleTime: '',
            weekDayKey: Number(moment().day()),
            rescheduleDate: '',
            doctor_user_profile_id: '',
            doctor_id: ''
        }

        this.coachProfile = AuthGuard.getCoachProfile();
        this.rescheduleCalendar = null;
    }

    componentDidMount() {
        this.createCalendar();
    }


    createCalendar() {
        this.$$('.reschedule-calendar').html('')
        var $$ = this.$$;
        let self = this;
        var monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        var calendarInline = app.calendar.create({
            locale: 'en',
            containerEl: '.reschedule-calendar',
            value: [new Date()],
            disabled: self.unavaliableDay,
            dayNamesShort: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
            events: [
                // {
                //     date: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
                //     color: '#ff0000'
                // },
            ],
            rangesClasses: [
                {
                    cssClass: 'day-unavaliable',
                    range: function (date) {
                        let _date = showDate(date, 2);
                        if (self.state.unavaliable_day.length > 0) {
                            return self.state.unavaliable_day.includes(_date);
                        }
                        // console.log(date.getDate() + '/'+date.getMonth() + '/' + date.getFullYear());
                        return false;
                    }
                },
                {
                    cssClass: 'day-holiday',
                    range: {
                        from: new Date(2020, 3, 1),
                        to: new Date(2020, 3, 10)
                    }
                }
            ],
            formatValue: (values) => {
                // console.log(values);
            },
            weekHeader: true,
            firstDay: 7,
            dateFormat: {
                // month: 'M', 
                // day: 'numeric'
            },
            renderToolbar: function () {
                return '<div class="toolbar calendar-custom-toolbar no-shadow">' +
                    '<div class="toolbar-inner">' +
                    '<div class="left">' +
                    '<a href="#" class="link icon-only"><i class="f7-icons  color-custom-blue' + (app.theme === 'md' ? 'color-black' : '') + '">arrowtriangle_left_fill</i></a>' +
                    '</div>' +
                    '<div class="center"></div>' +
                    '<div class="right">' +
                    '<a href="#" class="link icon-only"><i class="f7-icons color-custom-blue' + (app.theme === 'md' ? 'color-black' : '') + '">arrowtriangle_right_fill</i></a>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            },
            on: {
                init: function (c) {
                    $$('.calendar-custom-toolbar .center').html('<span class="monthName">' + monthNames[c.currentMonth] + '</span>  <span class="yearName">' + c.currentYear + '</span>');
                    $$('.calendar-custom-toolbar .left .link').on('click', function () {
                        calendarInline.prevMonth();
                    });
                    $$('.calendar-custom-toolbar .right .link').on('click', function () {
                        calendarInline.nextMonth();
                    });
                },
                monthYearChangeStart: function (c) {
                    $$('.calendar-custom-toolbar .center').html('<span class="monthName">' + monthNames[c.currentMonth] + '</span>  <span class="yearName">' + c.currentYear + '</span>');
                },
                calendarChange: function (c, value) {
                    // console.log(showDate(...value));
                    self.calculReschedule(showDate(...value, 2))
                }
            }
        });

        this.rescheduleCalendar = calendarInline;
    }

    calculReschedule(date) {

        let _weekDayKey = WeekEveryDay.findIndex((item, index) => {
            return showDate(date, 4).toUpperCase() === item;
        })

        this.setState({
            rescheduleDate: date,
            weekDayKey: parseInt(_weekDayKey) + 1,
            timeslot_day: '',
            rescheduleTime: ''
        }, () => {
            this.reCreatDayEveryTime(new Date());
            this.$$('input[name=time_slot]').each((e, v) => {
                if (this.$$(v).is(':checked')) {
                    this.$$(v).prop("checked", false)
                }
            })
        })
    }

    unavaliableDay = (date) => {
        let _date = showDate(date, 2)
        if (this.state.unavaliable_day.length > 0) {
            return this.state.unavaliable_day.includes(_date);
        }

        return false;
    }

    onReschedulePopupOpen() {
        this.getAvaliableDate();
    }
    getTimetoMM = (params) => {
        let arr = params.split(':')
        return arr[0] * 3600000 + arr[1] * 60000
    }
    getAvaliableDate = () => {
        let { coachId, clinic_info_id, userType } = this.coachProfile;
        let _doctor_id = ''

        if (userType === 'COACH') {
            _doctor_id = coachId
        } else {
            if (userType === "USER") {

                if (this.props.timeSlotsDrId) {

                    _doctor_id = this.props.timeSlotsDrId;
                } else {
                    _doctor_id = this.state.doctor_id;

                }

            }
        }
        if (!_doctor_id) return;
        AuthGuard.getAvaliableDate({
            clinic_id: clinic_info_id,
            doctor_id: _doctor_id
        }, (isOk, res) => {
            if (isOk) {
                let slotDays = getSlotTimes(res.timeslot.slots, res.timeslot.duration, res.timeslot.lead_time);

                this.setState({
                    timeslotDays: slotDays,
                    booked_timeslot: res.booked_timeslot,
                    unavaliable_day: res.unavaliable_day,
                    exception_slots: res.exception_slots
                }, () => {
                    this.reCreatDayEveryTime(new Date());
                })
            }
        })
    }

    reCreatDayEveryTime(day) {
        if (this.state.timeslotDays.length === 0) return false;
        let dayEveryTime = this.state.timeslotDays.length > 0 ? this.state.timeslotDays.find((item, index) => {
            // console.log(item.weekday, this.state.weekDayKey)
            return item.weekday === this.state.weekDayKey
        }) : '';


        let unTimeslot_day = this.state.booked_timeslot && this.state.booked_timeslot.length > 0 ?
            this.state.booked_timeslot.filter((item, index) => {
                return item.date === this.state.rescheduleDate;
            }) : '';

        let avaliableTime = [];
        let _time = showDate(day, 1);
        let _day = showDate(day, 2);

        let disableAll = moment(this.state.rescheduleDate).isBefore(_day);
        let enableAll = moment(this.state.rescheduleDate).isAfter(_day);
        if (dayEveryTime && dayEveryTime.slorts) {
            dayEveryTime.slorts.map((item, index) => {
                if(index == dayEveryTime.slorts.length - 1) return false
                let obj = {
                    slot: _.padStart(item.slot, 5, "0"),
                    duration: item.duration,
                    lead_time: item.lead_time,
                    disabled: false,
                };
                if (disableAll) {
                    obj.disabled = true
                }else if (moment(day).valueOf() + item.lead_time > moment(this.state.rescheduleDate).valueOf() + this.getTimetoMM(item.slot)) {
                    obj.disabled = true
                } else if (enableAll) {
                    let haveTime =
                        unTimeslot_day &&
                        unTimeslot_day.find((iitem, iindex) => {
                            return iitem.time == item.slot;
                        });
                    obj.disabled = !!haveTime;
                } else {
                    _time = _time.replace(":", "");
                    let _time2 = item.slot.replace(":", "");
                    if (parseInt(_time2) <= parseInt(_time)) {
                        obj.disabled = true;
                    } else {
                        let haveTime =
                            unTimeslot_day &&
                            unTimeslot_day.find((iitem, iindex) => {
                                return iitem.time == item.slot;
                            });
                        obj.disabled = !!haveTime;
                    }
                }
                this.state.booked_timeslot.map(ite => {
                    if (moment(ite.date).valueOf() == moment(this.state.rescheduleDate).valueOf()) {
                        if (ite.time && ite.time.slice(0, 5) == _.padStart(item.slot, 5, "0")) {
                            obj.disabled = true
                        }
                    }
                })
                this.state.exception_slots.map(exite => {
                    if (exite.day == showDate(this.state.rescheduleDate, 2)) {
                        exite.data.map(exites => {
                            if (isIntersect([this.getTimetoMM(exites.start), this.getTimetoMM(exites.end)], [this.getTimetoMM(obj.slot), this.getTimetoMM(obj.slot) + obj.duration * 60000])) {
                                obj.disabled = true
                            }
                        })
                    }
                })
                !obj.disabled &&
                    avaliableTime.push(obj)
            });

        }

        this.setState({
            dayEveryTime: avaliableTime
        }, () => {
            this.rescheduleCalendar.update();
        })

    }

    labelOnClick = (e) => {
        e.persist();

        let checked = this.$$(e.currentTarget).find('input').is(':checked');
        let value = this.$$(e.currentTarget).find('input').val();
        let timeslot_day = this.state.timeslot_day;
        if (checked) {
            this.$$(e.currentTarget).find('input').prop("checked", false);
            timeslot_day = ''
        } else {
            this.$$(e.currentTarget).find('input').prop("checked", true);
            timeslot_day = value
        }

        this.setState({
            timeslot_day: timeslot_day,
            rescheduleTime: timeslot_day,
        })

        // e.preventDefault();
        // console.log(this.state.timeslot_day);
        // console.log(e)        
        // this.inputChange(value, e);
    }

    inputChange(e) {
        e.preventDefault();
    }

    changeDrList = (e) => {

        let result = e.target.value.split(",")

        console.log(result[0], result[1])

        this.setState({ doctor_id: result[0], doctor_user_profile_id: result[1] }, () => {
            this.getAvaliableDate();
        });
    }

    rescheduleConfirm = () => {
        console.log('doctor_user_profile_id===>',this.state.doctor_user_profile_id)
        let { userType } = this.coachProfile;
        //如果是护士就不用，从appointment  list 页面进入这个组件， 就不需要选择医生了
        if (!this.props.timeSlotsDrUserProfileId) {
            if (userType === 'USER' && !this.state.doctor_user_profile_id) {
                ShowToast(app, 'Please Select a doctor!');
                return;
            }
        }


        if (this.props.userProfileId && this.state.rescheduleDate && this.state.rescheduleTime) {
            app.preloader.show();
            AuthGuard.appointmentSchedule({
                user_profile_id: this.props.userProfileId,
                date: this.state.rescheduleDate,
                time: this.state.rescheduleTime,
                doctor_user_profile_id: this.state.doctor_user_profile_id
            }, (isOk, res) => {
                if (isOk) {
                    // console.log(res)
                }
                app.preloader.hide();
                this.closeReschedulePopup();
            })
        } else if (this.props.rescheduleQid && this.state.rescheduleDate && this.state.rescheduleTime) {
            let _doctor_user_profile_id = ''
            if (userType === 'USER') {
                if (this.props.timeSlotsDrUserProfileId) {
                    _doctor_user_profile_id = this.props.timeSlotsDrUserProfileId
                }
            }
            app.preloader.show();
            AuthGuard.rescheduleConfirm({
                queue_id: this.props.rescheduleQid,
                date: this.state.rescheduleDate,
                time: this.state.rescheduleTime,
                doctor_user_profile_id: _doctor_user_profile_id
            }, (isOk, res) => {
                if (isOk) {
                    // console.log(res)
                }
                app.preloader.hide();
            })

            this.closeReschedulePopup();
        } else {
            ShowToast(app, 'Please Select a Date / Time First!');
        }

    }

    closeReschedulePopup = () => {
        app.popup.close('.Reschedule-popup')
    }

    onReschedulePopupClosed = () => {
        this.setState({
            dayEveryTime: [],
            timeslotDays: [],
            booked_timeslot: [],
            unavaliable_day: [],
            exception_slots: [],
            timeslot_day: "",
            rescheduleTime: "",
            weekDayKey: Number(moment().day()) === 0 ? 7 : Number(moment().day()),
            doctor_user_profile_id: "",
        });
    }

    render() {
        let { userType } = this.coachProfile;
        return (
            <Popup
                // opened
                className="Reschedule-popup"
                // swipeHandler=".swipe-handler"
                onPopupOpen={this.onReschedulePopupOpen.bind(this)}
                onPopupClosed={this.onReschedulePopupClosed.bind(this)}
            >
                <Page>
                    <Row style={{ display: this.props.drList && userType === 'USER' ? 'flex' : 'none' }}>
                        <Col>
                            <Col className="search-input input-dropdown-wrap">
                                <select
                                    name="doctor_user_profile_id"
                                    value={this.state.doctor_id + "," + this.state.doctor_user_profile_id}
                                    onChange={this.changeDrList.bind(this)}
                                >
                                    <option value=''> Select doctor </option>
                                    {this.props.drList && this.props.drList.length > 0 ? this.props.drList.map((item, index) => {
                                        return <option key={item.id} value={item.id + "," + item.user_profile_id}>{item.user_name}</option>
                                    }) : ''}
                                </select>
                            </Col>
                        </Col>
                    </Row>
                    <div className="reschedule-calendar-content">
                        <div className="reschedule-calendar"></div>
                    </div>
                    <Row>
                        <div className="checkboxGroup">
                            {this.state.dayEveryTime.length > 0?this.state.dayEveryTime.map((item, index) => {
                                let slot = item.slot;
                                if (item.disabled) {
                                    return (
                                        <div key={index}>
                                            <div className={`checkboxBtn  disabledcheckedBtn`}>
                                                <span>{slot}</span>
                                            </div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index} onClick={this.labelOnClick}>
                                            <div className={`checkboxBtn  ${this.state.timeslot_day === slot ? 'checkedBtn' : ''}`}>
                                                <input type="radio" name="time_slot" onChange={this.inputChange.bind(this)} value={slot} />
                                                <span>{slot}</span>
                                            </div>
                                        </div>
                                    )
                                }
                            }):'There are no appointment slots for this date. Please choose another date!'}
                        </div>
                    </Row>
                    <div className="footerCallPop">
                        <Row>
                            <Col>
                                <Button fill raised onClick={this.rescheduleConfirm.bind(this)} className="fill-blue-btn" > Confirm </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button outline raised onClick={this.closeReschedulePopup.bind(this)} className="outline-blue-btn" > Close </Button>
                            </Col>
                        </Row>
                    </div>
                </Page>
            </Popup>
        )
    }
}


export default ReschedulePopUp;