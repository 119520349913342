import React, { useEffect } from 'react';
import { FormControl, MenuItem, Typography } from '@material-ui/core';
import LocalAudioLevelIndicator from '../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { makeStyles } from '@material-ui/core/styles';
import { useAudioInputDevices } from '../deviceHooks/deviceHooks';
import useMediaStreamTrack from '../../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import AuthGuard from '../../../../../../services/AuthGuard';
import useLocalAudioToggle from '../../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { useAppState } from '../../../../state';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

export default function AudioInputList() {
  const classes = useStyles();
  const audioInputDevices = useAudioInputDevices();
  const { localTracks, getLocalAudioTrack } = useVideoContext();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const {endCall} = useAppState();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio');
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  useEffect(() => {
    if (endCall) {
      return;
    }
    if (!localAudioInputDeviceId) {
      if (!localAudioTrack) {
        getLocalAudioTrack().then(audioTrack => {
          audioTrack?.restart();
        })
      }
    } else if (localAudioInputDeviceId === 'default') {
      localAudioTrack?.restart({ deviceId: { exact: 'default' } });
    } else {
      let lost = true
      audioInputDevices.map(device => {
        if (device.deviceId === localAudioInputDeviceId) {
          lost = false
        }
      })
      if (lost) {
        localAudioTrack?.restart({ deviceId: { exact: 'default' } });
      }
    }
  }, [audioInputDevices.length, endCall])

  return (
    <div className={classes.container}>
      <div className="inputSelect">
        {audioInputDevices.length > 0 ? (
          <FormControl fullWidth>
            <Typography variant="h6">Microphone:</Typography>
            <span className="input-dropdown-wrap" style={{margin: 0}}>
                <select
                    value={localAudioInputDeviceId || ''}
                    onChange={e => replaceTrack(e.target.value as string)}
                >
                    {audioInputDevices.map(device => (
                        <option value={device.deviceId} key={device.deviceId}>
                          {device.label}
                        </option>
                    ))}
                </select>
            </span>
          </FormControl>
        ) : (
          <>
            <Typography variant="h6">Audio Input:</Typography>
            <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
          </>
        )}
        <LocalAudioLevelIndicator />
      </div>
    </div>
  );
}
