import React, { Component } from 'react';
import { f7 as app, Page, Row, Col, Button, Appbar, View, Link, Tabs, Tab, Badge, Popup, Block, Icon, Preloader, Fab, PageContent } from 'framework7-react';
import SideBar from '../components/SideBar'
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { InputBlur, VerificationFields, ShowToast } from '../commons/Helpers';
import Modal from '../components/Modal';


class AddMedication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            medication_id: props.medication_id,
            addedMedication: false,
            delMedicationModalOpen :false,
            loadingDelMedicationBtn: false,
            requestLoading: false,
            name: '',
            medication_type: '',
            unit_price: '',
            description: '',
            precautions: '',
            measurement_unit: '',
            method_of_consumption: '',
            quantity: '',
            dosage: '',
            frequency: '',
            duration: '',
            medication_status: '',
            inventory_id: '',
            expiry_date: '',
        }
    }

    componentDidMount() {
        if (this.state.medication_id) {
            AuthGuard.getMedicationDetail({
                id: this.state.medication_id
            }, (isOk, res) => {
                let new_res = res;
                console.log(res);
                if (isOk) {
                    this.setState({
                        name: new_res.name,
                        medication_type: new_res.medication_type,
                        unit_price: new_res.unit_price,
                        description: new_res.description,
                        precautions: new_res.precautions,
                        measurement_unit: new_res.measurement_unit,
                        method_of_consumption: new_res.method_of_consumption,
                        dosage: new_res.dosage,
                        frequency: new_res.frequency,
                        duration: new_res.duration,
                        medication_status: new_res.status,
                        inventory_id: new_res.inventory_id,
                        quantity: new_res.quantity,
                        status: new_res.status,
                        expiry_date: new_res.expiry_date
                        
                    })
                }
            })
        }
    }

    inputBlur(atrName, type, e) {
        console.log(atrName, type)
        let res = InputBlur(app, atrName, this.$$("input[name='" + atrName + "']"), type);
        console.log(res)
        if(!res){
            this.setState({
              [atrName]: ''
            });
        }
    }

    createMedicationBtn = () => {
        var formData = app.form.convertToData('#create-new-medication');
        // var noError = VerificationFields(this, app,
        //     [
        //         'name',
        //         'type',
        //         'selling_price',
        //         'purpose',
        //         'precautions',
        //         'measurement_unit',
        //         'method_of_consumption',
        //         'dosage',
        //         'frequency',
        //         'duration',
        //         'quantity',
        //     ]
        // );

        // if (noError) {
            app.preloader.show();
            let _formData = formData;
            if (this.state.medication_id) {
                _formData.id = this.state.medication_id;
                _formData.status = this.state.status;
                _formData.expiry_date = this.state.expiry_date;
                app.preloader.show();
                AuthGuard.editMedication(_formData, (isOk, res) => {
                    app.preloader.hide();
                    if (isOk) {
                        app.dialog.alert('Updated medication sucess.', 'Alert', () => {
                            app.views.main.router.back();
                        });
                    } else {
                        ShowToast(app, 'Updated medication fail.');
                    }
                });
            }else{
                AuthGuard.saveMedication(_formData, (isOk, res) => {
                    if (isOk) {
                        app.dialog.alert('Add medication success.', 'Alert', () => {
                            app.views.main.router.back();
                        });
                    } else {
                        ShowToast(app, 'Add medication fail.');
                    }
                    app.preloader.hide();
                });
            }
        // }else{
        //     console.log('Field Error!');
        // }
    }

    delMedicationBtn = (e) => {
        this.setState({
            delMedicationModalOpen: true
        })
    }

    delMedicationPopupclose() {
        this.setState({
            delMedicationModalOpen: false
        })
    }

    delMedicationSave() {
        app.preloader.show()
        this.delMedicationPopupclose()
        AuthGuard.delMedication({
            id: this.state.medication_id
        }, (isOk, msg) => {
            if (isOk) {
                // ShowToast(app, msg)
                app.dialog.alert('Delete medication sucess.', 'Alert', () => {
                    app.views.main.router.back();
                });
                // this.$f7.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'medicationListTab' } })
            } else {
                ShowToast(app, msg)
            }

            app.preloader.hide()
        })
    }

    changeLineStatus(type, e) {
        
        let self = this;
        if (type === 'inactive') {
            if (self.$$('.offline').hasClass('activeBtn')) {
                self.$$('.offline').removeClass('activeBtn');
                self.$$('.online').addClass('activeBtn');
                this.setState({
                    status: type,
                })
                this._changeStatus();
            }
        }

        if (type === 'active') {
            if (self.$$('.online').hasClass('activeBtn')) {
                self.$$('.online').removeClass('activeBtn');
                self.$$('.offline').addClass('activeBtn');
                this.setState({
                    status: type,
                })
                this._changeStatus();
            }
        }
    }

    _changeStatus() {
        this.createMedicationBtn();
    }

    AddMedicationForm() {
        return (
            <form id='create-new-medication'>
                {this.state.requestLoading ? <Preloader></Preloader> : ''}
                <Modal
                    tilte=""
                    modalOpened={this.state.delMedicationModalOpen}
                    modalPopupClose={this.delMedicationPopupclose.bind(this)}
                    descHtml={`
                        Are you sure you want to <span class="txt-red">Delete</span> this medication?<br/>
                    `}
                    onSave={this.delMedicationSave.bind(this)}
                    loadingBtn={this.state.loadingDelMedicationBtn}
                />

                {this.state.medication_id ?
                <Row>
                    <Col width="25" className="formLabel">Status:</Col>
                    <Col width="45" className="changeLiveStatusLeft">
                        
                            <div className="statusBoxLeft">
                                <Button className={`liveStatus offline ${this.state.medication_status === 'active' ? 'activeBtn' : ''}`} onClick={this.changeLineStatus.bind(this, 'active')}>ACTIVE</Button>
                                <Button className={`liveStatus online ${this.state.medication_status === 'inactive' ? 'activeBtn' : ''}`} onClick={this.changeLineStatus.bind(this, 'inactive')}>INACTIVE</Button>
                            </div>
                            
                    </Col>
                    <Col width="30"></Col>
                </Row>
                : ''}
                {this.state.medication_id ?
                <Row>
                    <Col width="25" className="formLabel">Inventory ID:</Col>
                    <Col width="45" className="changeLiveStatusLeft">
                            <p style={{fontWeight: 'bold'}}>{this.state.inventory_id}</p>
                    </Col>
                    <Col width="30"></Col>
                </Row>
                : ''}
                <Row>
                    <Col width="25" className="formLabel">
                        Name:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="name"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. Arcoxia 120 mg"
                            value={this.state.name}
                            onBlur={this.inputBlur.bind(this, 'name', 'text')}
                            onChange={(e) => {
                                this.setState({ name: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>

                <Row>
                    <Col width="25" className="formLabel">
                        Type
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="medication_type"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. Oral Tablets"
                            value={this.state.medication_type}
                            onBlur={this.inputBlur.bind(this, 'medication_type', 'text')}
                            onChange={(e) => {
                                this.setState({ medication_type: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>

                <Row>
                    <Col width="25" className="formLabel">
                    Unit Price:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="unit_price"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. 1.2"
                            value={this.state.unit_price}
                            onBlur={this.inputBlur.bind(this, 'unit_price', 'money')}
                            onChange={(e) => {
                                this.setState({ unit_price: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>

                <Row>
                    <Col width="25" className="formLabel">
                    Purpose:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="description"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. For Pain"
                            value={this.state.description}
                            onBlur={this.inputBlur.bind(this, 'description', 'text')}
                            onChange={(e) => {
                                this.setState({ description: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>
                
                <Row>
                    <Col width="25" className="formLabel">
                    Precautions:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="precautions"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. To take with food"
                            value={this.state.precautions}
                            onBlur={this.inputBlur.bind(this, 'precautions', 'text')}
                            onChange={(e) => {
                                this.setState({ precautions: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row> 

                <Row>
                    <Col width="25" className="formLabel">
                    Measurement Unit:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="measurement_unit"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. Tablet"
                            value={this.state.measurement_unit}
                            onBlur={this.inputBlur.bind(this, 'measurement_unit', 'text')}
                            onChange={(e) => {
                                this.setState({ measurement_unit: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>

                <Row>
                    <Col width="25" className="formLabel">
                    Method of Consumption:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="method_of_consumption"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. Take"
                            value={this.state.method_of_consumption}
                            onBlur={this.inputBlur.bind(this, 'method_of_consumption', 'text')}
                            onChange={(e) => {
                                this.setState({ method_of_consumption: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>
                
                <Row>
                    <Col width="25" className="formLabel">
                    Quantity:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="quantity"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. 1"
                            value={this.state.quantity}
                            onBlur={this.inputBlur.bind(this, 'quantity', 'number')}
                            onChange={(e) => {
                                this.setState({ quantity: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>

                <Row>
                    <Col width="25" className="formLabel">
                    Dosage:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="dosage"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. Tablet"
                            value={this.state.dosage}
                            onBlur={this.inputBlur.bind(this, 'dosage', 'text')}
                            onChange={(e) => {
                                this.setState({ dosage: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>

                <Row>
                    <Col width="25" className="formLabel">
                    Frequency:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="frequency"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. Daily"
                            value={this.state.frequency}
                            onBlur={this.inputBlur.bind(this, 'frequency', 'text')}
                            onChange={(e) => {
                                this.setState({ frequency: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>

                <Row>
                    <Col width="25" className="formLabel">
                    Duration:
                </Col>
                    <Col width="45">
                        <input
                            type='text'
                            name="duration"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g. 5 days"
                            value={this.state.duration}
                            onBlur={this.inputBlur.bind(this, 'duration', 'text')}
                            onChange={(e) => {
                                this.setState({ duration: e.target.value });
                            }}
                        />
                    </Col>
                    <Col width="30"></Col>
                </Row>
            </form>
        )
    }


    render() {
        return (
            <FramePage
                name={ROUTES.addMedication}
            >
                <div className="navBar">
                    <Link back animate={false} color='gray'> Settings </Link> &gt; <span>{this.state.medication_id ? "Medication Details" : "Add Medication"}</span>
                </div>
                <Row className="navTitleBar">
                    <Col> {this.state.medication_id ? "Medication Details" : "Add Medication"} </Col>
                    <Col>
                        <div className="footerBtn">
                            <Button back className="navbarBtn outline-blue-btn" raised >Back</Button>
                            {this.state.medication_id ? <Button className="navbarBtn" onClick={this.delMedicationBtn} fill color="red">Delete</Button> : null}
                            {this.state.addedMedication ? '' : <Button className="navbarBtn fill-blue-btn" onClick={this.createMedicationBtn} raised fill>{this.state.medication_id ? "Update" : "Confirm"} </Button>}
                        </div>
                    </Col>
                </Row>
                <div className="white-block">
                    {this.state.addedMedication ? <AddMedicationSuccess /> : this.AddMedicationForm()}
                </div>
                
            </FramePage>
        );
    }
}
const AddMedicationSuccess = (props) => {

    return (
        <div className="fwl">
            Add medication success.
        </div>
    )
}

export default AddMedication;
