import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import {useAppState} from "./state";
import useVideoContext from "./hooks/useVideoContext/useVideoContext";
import {f7 as app} from "framework7-react";
import AuthGuard from "../../services/AuthGuard";
import events from '../../services/events';
import useLocalVideoToggle from './hooks/useLocalVideoToggle/useLocalVideoToggle';
import useLocalAudioToggle from './hooks/useLocalAudioToggle/useLocalAudioToggle';
import {isMacOs, isSafari} from 'react-device-detect'


const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
});

export default function App() {
  const roomState = useRoomState();
  const {token, error, endCall, setEndCall} = useAppState();
  const {room, localTracks} = useVideoContext();
  const {connect, isConnecting} = useVideoContext();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();

  const audioTrack = localTracks.find(track => track.kind === 'audio');
  const videoTrack = localTracks.find(track => track.kind === 'video');
  // @ts-ignore
  if (token && AuthGuard.videoCallInfo.canConnect && audioTrack && videoTrack) {
    // @ts-ignore
    AuthGuard.videoCallInfo.canConnect = false
    setEndCall(false)
    connect(AuthGuard.twilioToken)
    localTracks.forEach(track => track.restart())
    if (!isVideoEnabled) {
      toggleVideoEnabled()
    }
    if (!isAudioEnabled) {
      toggleAudioEnabled()
    }
    if (isMacOs && isSafari) {
      window.addEventListener('blur', () => {
        // @ts-ignore
        window.audioTrackStop = true
      })
      window.addEventListener('focus', () => {
        setTimeout(() => {
        // @ts-ignore
        if (window.audioTrackStop && !isAudioEnabled) {
          const audioTrack = localTracks.find(track => track.kind === 'audio');
          audioTrack?.restart();
          audioTrack?.enable();
          // @ts-ignore
          window.audioTrackStop = false
        }
        }, 300)
      })
    }
  }

  events.on("EndConsultation", () => {
    setEndCall(true)
    audioTrack?.stop();
    videoTrack?.stop();
  })
  events.on("pushNotificationCall", () => {
    if (AuthGuard.twilioCanReconnect && roomState === 'disconnected') {
      AuthGuard.twilioCanReconnect = false;
      setEndCall(false)
      connect(AuthGuard.twilioToken) 
      toggleVideoEnabled()
      toggleAudioEnabled()
      toggleVideoEnabled()
      toggleAudioEnabled()
    }
  }) 

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  // const height = useHeight();

  return (
    <div>
      <Main className={"videoRoom " + roomState + (endCall ? " ended" : '') + (AuthGuard.patientInCall ? '' : ' wait')}>
          {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
        <Controls />
      </Main>
      {/*<ReconnectingNotification />*/}
    </div>
  );
}
