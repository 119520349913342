import React, { Component } from 'react'
import {
    f7 as app,
    Page,
    Button,
    LoginScreenTitle,
    List,
    Input
} from 'framework7-react';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { justEmail, ShowToast } from '../commons/Helpers';
export class ForgetPassWord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            submitLoading: false,
            sendOtp: false,
            otpCodeVal: '',
            seconds: 60
        }
    }

    onPageInit() {

    }

    componentDidMount() {
        if (this.props.SMS || this.props.Notifications) {
            this.setState({
                sendOtp: true
            })

            let timer = setInterval(() => {
                this.setState((preState) => ({
                    seconds: preState.seconds - 1,
                }), () => {
                    if (this.state.seconds == 0) {
                        clearInterval(timer);
                    }
                });
            }, 1000)
        }
    }

    inputBlur(atrName, type, e) {
        if (justEmail(this.$$("input[name='" + atrName + "']"))) {

        } else {
            ShowToast(app, 'Invalid email format.')
            this.setState({
                email: ''
            }, () => {
                this.$$("input[name='" + atrName + "']").focus()
            })
        }

    }

    sentOtp() {
        app.preloader.show()
        this.$$('.message').html('');
        this.setState({
            submitLoading: true
        })

        AuthGuard.getForgetPWOTP({
            email: this.state.email
        }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    sendOtp: true
                })

                let timer = setInterval(() => {
                    this.setState((preState) => ({
                        seconds: preState.seconds - 1,
                    }), () => {
                        if (this.state.seconds == 0) {
                            clearInterval(timer);
                        }
                    });
                }, 1000)

            } else {
                this.$$('.message').html(res.message);
            }
            app.preloader.hide()

            this.setState({
                submitLoading: false
            })
        })


    }

    resendOTP = () => {

        if (this.props.SMS == 'SMS') {

            AuthGuard.verifyCoachMobile({
                dialingCode: this.props.dialing_code,
                mobile: this.props.mobile,
            }, (isOk, res) => {

                if (isOk) {
                    this.state.seconds = 60;
                    let timer = setInterval(() => {
                        this.setState((preState) => ({
                            seconds: preState.seconds - 1,
                        }), () => {
                            if (this.state.seconds == 0) {
                                clearInterval(timer);
                            }
                        });
                    }, 1000)
                    this.state.seconds = 60;

                } else {
                    if (res.status_code !== 11015) {
                        this.$$('.message').html(res.message);
                    }
                }
            })

        } else if (this.props.Notifications == 'Notifications') {

            AuthGuard.sendSmsOtpDirect({
                dialingCode: this.props.dialing_code,
                mobile: this.props.mobile,
            }, (isOk, res) => {

                if (isOk) {
                    this.state.seconds = 60;
                    let timer = setInterval(() => {
                        this.setState((preState) => ({
                            seconds: preState.seconds - 1,
                        }), () => {
                            if (this.state.seconds == 0) {
                                clearInterval(timer);
                            }
                        });
                    }, 1000)
                    this.state.seconds = 60;
                } else {

                    if (res.status_code !== 11015) {
                        this.$$('.message').html(res.message);
                    }
                }
            })

        } else {

            app.preloader.show()
            AuthGuard.getForgetPWOTP({
                email: this.state.email
            }, (isOk, res) => {
                if (isOk) {
                    ShowToast(app, 'OTP Code has resend!')
                    this.$$('.message').html('');

                    this.state.seconds = 60;
                    let timer = setInterval(() => {
                        this.setState((preState) => ({
                            seconds: preState.seconds - 1,
                        }), () => {
                            if (this.state.seconds == 0) {
                                clearInterval(timer);
                            }
                        });
                    }, 1000)
                    this.state.seconds = 60;

                } else {
                    this.$$('.message').html(res.message);
                }
                app.preloader.hide()
            })
        }


    }

    checkOtpCode = (val) => {
        this.setState({ otpCodeVal: val }, () => {
            if ([...val].length >= 6) {
                this.verifiOTP()
            }
        });

    }

    verifiOTP = () => {
        app.preloader.show()

        if (this.props.SMS == 'SMS') {

            AuthGuard.updateMobileForCoach({
                dialing_code: this.props.dialing_code,
                mobile: this.props.mobile,
                otp_code: this.state.otpCodeVal,
            }, (isOk, res) => {
                if (isOk) {

                    app.views.main.router.navigate(ROUTES.setting, {
                        reloadCurrent: true,
                        ignoreCache: true,
                        history: false,
                        props: {
                            SMSVerify: 'SMSVerified',
                            dialing_code: this.props.dialing_code,
                            mobile: this.props.mobile,
                        }
                    })

                    sessionStorage.setItem('SMSVerify', 'SMSVerified');
                    sessionStorage.setItem('SMSVerify_dialing_code', this.props.dialing_code);
                    sessionStorage.setItem('SMSVerify_mobile', this.props.mobile);
                } else {
                    this.$$('.otpErr').html('verification failed!');

                }
                app.preloader.hide()
            })

        } else if (this.props.Notifications == 'Notifications') {
            AuthGuard.checkNotificationMobile({
                dialing_code: this.props.dialing_code,
                mobile: this.props.mobile,
                otp_code: this.state.otpCodeVal,
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        otpCodeVal: ''
                    })
                    this.$$('.otpErr').html('');

                    app.views.main.router.navigate(ROUTES.setting, {
                        reloadCurrent: true,
                        ignoreCache: true,
                        history: false,
                        props: {
                            NotificationsSMSVerify: 'NotificationsSMSVerify',
                            dialing_code: this.props.dialing_code,
                            mobile: this.props.mobile,
                        }
                    })

                    sessionStorage.setItem('NotificationsSMSVerify', 'NotificationsSMSVerify');
                    sessionStorage.setItem('NotVerify_dialing_code', this.props.dialing_code);
                    sessionStorage.setItem('NotVerify_mobile', this.props.mobile);

                } else {
                    this.$$('.otpErr').html('verification failed!');
                }
                app.preloader.hide()
            })
        } else {

            AuthGuard.verifiFWOTP({
                email: this.state.email,
                otp: this.state.otpCodeVal,
            }, (isOk, res) => {
                if (isOk) {
                    this.$$('.otpErr').html('');
                    app.views.main.router.navigate(ROUTES.restpw, {
                        reloadCurrent: true,
                        ignoreCache: true,
                        history: false,
                        props: {
                            userProfileID: res,
                            email: this.state.email,
                            otp: this.state.otpCodeVal,
                        }
                    })
                    this.setState({
                        otpCodeVal: ''
                    })
                } else {
                    this.$$('.otpErr').html(res.message);
                }
                app.preloader.hide()
            })
        }
    }

    renderCheckOtp() {
        return (
            <>
                <LoginScreenTitle>
                    <div>Authenticate</div>
                    {
                        this.props.SMS || this.props.Notifications ?
                            <div className="login-sub-title">We just sent you a SMS with a code</div> :
                            <div className="login-sub-title">We just sent you an email with a code</div>
                    }
                    <div className="login-sub-title"> Enter it to verify it is you. </div>
                </LoginScreenTitle>
                <List id="checkotp-form">
                    <div className="mt list-block">
                        <div className="mt">
                            <div className="col-right otpCodes">
                                <Input
                                    type="text"
                                    maxlength={6}
                                    noStoreData
                                    name="otpCode"
                                    className="otpCode"
                                    value={this.state.otpCodeVal}
                                    onInput={(e) => {
                                        this.checkOtpCode(e.target.value)
                                    }}
                                    placeholder="0 0 0 0 0 0"
                                />
                            </div>
                        </div>
                    </div>
                </List>
                <List>
                    <div className="otpErr"></div>
                    {/* <Button className="resendOTPBtn" color="gray" onClick={this.resendOTP}> Resend OTP </Button> */}
                    <Button disabled={this.state.seconds == 0 ? false : true} className="resendOTPBtn" color="gray" onClick={this.resendOTP} style={{ color: this.state.seconds == 0 ? '#56B3C4' : '#8e8e93' }}> Resend OTP </Button> <span style={{ display: this.state.seconds !== 0 ? 'inline-block' : 'none', fontSize: 'small' }}> <span style={{ color: '#8e8e93', fontWeight: 'bolder' }}>in</span> <span className="cb">{this.state.seconds} secs</span></span>
                </List>
            </>
        )
    }

    onFocusInput() {
        const forgetpwTitleEmail = document.querySelector(".forgetpw-title-email");
        forgetpwTitleEmail.style.display = "block";
    }

    onEmailInput(e) {
        const forgetpwTitleEmail = document.querySelector(".forgetpw-title-email")
        this.setState({ email: e.target.value });
        console.log(e.target.value)

        if (e.target.value == '') {
            forgetpwTitleEmail.style.display = "none"
        }
    }

    onBlur() {
        const forgetpwTitleEmail = document.querySelector(".forgetpw-title-email");
        forgetpwTitleEmail.style.display = "none";
    }

    renderFw() {
        return (
            <>
                <LoginScreenTitle>
                    <div>Forget Password</div>
                    <div className="login-sub-title">Please enter your registered email</div>
                </LoginScreenTitle>
                <List id="my-form">
                    <div className="mt list-block">
                        <div className="mt">
                            <div className="col-right">
                                <span className="forgetpw-title-email">Email</span>
                                <Input
                                    required
                                    noStoreData
                                    clearButton
                                    type="text"
                                    name="email"
                                    value={this.state.email}
                                    className="email"
                                    placeholder="Email"
                                    onFocus={this.onFocusInput}
                                    // onBlur={this.onBlur}
                                    // onBlur={this.inputBlur.bind(this, 'email', 'email')}
                                    // onInput={(e) => {
                                    //     this.setState({ email: e.target.value });
                                    // }}
                                    onInput={this.onEmailInput.bind(this)}
                                />
                            </div>
                        </div>
                    </div>
                </List>
                <List>
                    <div className="message"></div>
                    <Button color="white" raised large className={`btnPw mt fill-blue-btn ${this.state.submitLoading ? 'disabled' : ''}`} onClick={this.sentOtp.bind(this)}>
                        Next
                    </Button>
                </List>
            </>
        )
    }

    render() {
        return (
            <Page loginScreen name="forgetPwPage" id="forgetpw" onPageInit={this.onPageInit.bind(this)}>
                {this.state.sendOtp ? this.renderCheckOtp() : this.renderFw()}
            </Page>
        );
    }
}
export default ForgetPassWord;