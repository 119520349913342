import React, { useEffect } from 'react';
import {f7 as app} from 'framework7-react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import { DialogActions, DialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList/VideoInputList';
import { Button } from 'framework7-react';
import { playAudio } from '../../../../../commons/Helpers';
import { useState } from 'react';
import { useAppState } from '../../../state';

const useStyles = makeStyles({
  listSection: {
    margin: '2em 0',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
});

export function DeviceSelector({ className, hidden }: { className?: string; hidden?: boolean }) {
  const classes = useStyles();
  const [testingAudio, setTestingAudio] = useState(false)
  const [firstOpen, setFirstOpen] = useState(true)
  const { activeSinkId } = useAppState();

  //@ts-ignore
  let audio: HTMLMediaElement = document.getElementById("ringtoneMp3");
  const toggleTestAudio = () => {
    setTestingAudio(!testingAudio)

    if (audio !== null) {
      if (!testingAudio) {
        if (audio.paused) {
          audio.play();// 播放
          audio.loop = true;
        }
      } else {
        audio.pause()
      }
    }

    playAudio('ringtoneMp3', false)
  }

  useEffect(() => {
    if (audio) {
      audio.setSinkId?.(activeSinkId)
    }
  }, [activeSinkId])

  return (
    <>
      <DialogContent className={className} hidden={hidden}>
        {firstOpen && <p style={{ fontSize: '1.3em', margin: 0 }}>While you're waiting..</p>}
        {firstOpen && <p style={{ fontSize: '1.1em', margin: 0 }}>Test your Audio & Video to ensure you have a smooth call.</p>}
        <div className={classes.listSection}>
          <AudioOutputList />
          <Button
            style={{ display: 'inline-block', marginTop: '1em' }}
            className="btn-large outline-blue-btn mt2"
            raised
            onClick={toggleTestAudio}>
            {testingAudio ? "Stop Test" : "Test Speaker"}
          </Button>
        </div>
        <div className={classes.listSection}>
          <AudioInputList />
        </div>
        <div className={classes.listSection}>
          <VideoInputList />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {
          app.popup.close(".settings-popup")
          setFirstOpen(false)
          if (testingAudio) {
            toggleTestAudio()
          }
        }}>
          Close
        </Button>
      </DialogActions>
    </>
  );
}
