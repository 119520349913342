import React, { Component } from 'react';
import { f7 as app, Page, Row, Col, Button, Appbar, View, Link, List, ListInput, Tabs, Tab, Badge, Popup, Block, Icon, Preloader, Input } from 'framework7-react';
import { ROUTES, } from '../commons/Constants'
import FramePage from '../components/FramePage';
import AuthGuard from '../services/AuthGuard';
import { showGender, setCache, ShowToast, InputBlur, getCache, getLocalTime } from '../commons/Helpers';
import deleteIcon from '../images/icons/delete.png';

import Select from "react-dropdown-select";

class PatientInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_profile_id: props.user_profile_id,
      history_list: [],
      countryList: [],
      countryListKey: {},
      attribute: 'nationality',
      nationalityList: [],
      genderList: [{ code: 'male', name: 'Male' }, { code: 'female', name: 'Female' }],
      maritalStatusList: [{ label: 'Single', value: 'Single', }, { label: 'Married', value: 'Married', }, { label: 'Widowed', value: 'Widowed', }, { label: 'Divorced', value: 'Divorced', }],
      country: '',
      dob: '',
      drug_allergy: '',
      email: '',
      gender: '',
      id_number: '',
      marital_status: '',
      mobile_number: '',
      name: '',
      nationality: '',
      postal_code: '',
      street: '',
      unit_number: '',
      origin_dob: '',
      country_code: '',
      consultationHistoryOpen: false,
      programJoined: [],
      programPending: [],
      program_ids: [''],
      iframeUrl: '',
      programOptions: [{ label: 'Please select', value: '' }],
      dialing_code: '',
      mobile: '',
      dialingCodeList: [],
      sgDialingCode: []
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.getNationality();
      this.getPatientInfo();
      this.loadAvaliablePrograms();
      this.getPrograms();
    }, 100);
  }

  componentDidUpdate() {
    app.preloader.hide();
  }

  saveAppointment() {
    app.preloader.show();
    let _countryKey = this.state.countryListKey;
    let new_code = this.state.country_code;
    let countryName = _countryKey[new_code];
    AuthGuard.saveAppointment({
      user_profile_id: this.state.user_profile_id,
      name: this.state.name,
      gender: this.state.origin_gender,
      country_code: this.state.country_code,
      country_name: countryName,
      postal_code: this.state.postal_code,
      street: this.state.street,
      unit_number: this.state.unit_number,
      birthday: this.state.origin_dob,
      nationality: this.state.nationality,
      id_number: this.state.id_number,
      marital_status: this.state.marital_status,
      drug_allergy: this.state.drug_allergy,
    }, (isOk, res) => {
      app.preloader.hide();
      if (isOk) {
        app.dialog.alert('Updated sucess.', 'Alert', () => {
          this.goAppointment()
        });
      } else {
        ShowToast(app, 'Updated medication fail.');
      }
    })
  }

  getPrograms() {
    AuthGuard.findProgramByUserIdAndProgramType({
      user_profile_id: this.state.user_profile_id
    }, (ok, res) => {
      if (ok) {
        let _this = this
        this.setState({
          programJoined:[],
          programPending:[]
        },()=>{
            let programJoined = _this.state.programJoined;
            let programPending = _this.state.programPending;
            res.buy_program_array.map(i => programJoined.unshift({ label: i.program_name, value: i.program_id, service_access_id: i.service_access_id, created_at: getLocalTime(i.created_at) }))
            res.invitation_array.map(i => programPending.unshift({ label: i.program_name, value: i.program_id, service_access_id: i.service_access_id }))
            _this.setState({
              programJoined: programJoined,
              programPending: programPending,
            })
        })
        
      }
    })
  }

  loadAvaliablePrograms() {
    let coachProfile = AuthGuard.getCoachProfile();
    AuthGuard.findProgramByMerchantId({
      clinicInfoId: coachProfile.clinic_info_id,
      status: 'active',
    }, (ok, res) => {
      let programOptions = [{ label: 'Please select', value: '' }]
      if (ok) {
        res.map(item => {
          programOptions.push({
            label: item.name,
            value: item.id
          })
        })
      }

      this.setState({
        programOptions: programOptions
      })
    })

  }

  onProgramChange(idx, evt) {
    let program_ids = this.state.program_ids
    program_ids[idx] = evt.target.value
    this.setState({ program_ids: program_ids })
  }

  onAddProgram = (idx) => {
    let program_ids = this.state.program_ids
    program_ids.push('')

    this.setState({ program_ids: program_ids }, () => {
      this.onConfirmAddProgram(idx)

    })
  }

  onConfirmAddProgram(idx) {
    let joined = this.state.programJoined.map(i => i.value)
    let added = this.state.program_ids.filter(i => i !== '' && !joined.includes(i))

    if (!added.length) {

      let _delList = this.state.program_ids

      console.log(_delList)

      _delList.splice(idx, 1)

      this.setState({
        program_ids: _delList
      }, () => {
        console.log(this.state.program_ids)
      })

      return app.dialog.alert('please select a valid program')
    }

    added = [...new Set(added)]
    AuthGuard.addProgramsByUserId({
      user_profile_id: this.state.user_profile_id,
      program_ids: JSON.stringify(added),
    }, (ok, res, msg) => {
      if (ok) {
        // added.map(i => this.state.programPending.unshift(this.state.programOptions.filter(o => o.value == i).pop()))
        this.setState({
          program_ids: [''],
          // programPending: this.state.programPending,
        }, () => {
          this.getPrograms()
          // AuthGuard.findProgramByUserIdAndProgramType({
          //   user_profile_id: this.state.user_profile_id
          // }, (ok, res) => {
          //   if (ok) {
          //     console.log(res)

          //     let _programPending = res.invitation_array.map(i => {
          //       return {
          //         label: i.program_name,
          //         value: i.program_id,
          //         service_access_id: i.service_access_id
          //       }
          //     })

          //     this.setState({
          //       programPending: _programPending,
          //     }, () => {
          //       console.log(_programPending, this.state.programPending)
          //     })
          //   }
          // })
        })
        return app.dialog.alert('add success')
      }
      return app.dialog.alert('add failed')
    })
  }

  getCountryInfo() {

    const colorStyle = {
      display: 'inline-block',
      verticalAlign: 'middle',
      width: '18px',
      height: '18px',
      marginRight: '10px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat'
    };

    AuthGuard.getCountryList({
      page: 1,
      limit: 99999
    }, (isOk, res) => {

      console.log(this.state.dialing_code)

      let dialingCodeList = []
      let sgDialingCode = []
      res.map(i => {

        if (this.state.dialing_code == i.dialing_code) {

          console.log(this.state.dialing_code)

          dialingCodeList.push({
            label: <span>
              <span style={{ ...colorStyle, backgroundImage: "url(" + i.flag_image_url.url.s + ")" }}></span>
                    +{i.dialing_code}
            </span>,
            value: i.dialing_code,
          })

          sgDialingCode.push({
            label: <span>
              <span style={{ ...colorStyle, backgroundImage: "url(" + i.flag_image_url.url.s + ")" }}></span>
                    +{i.dialing_code}
            </span>,
            value: i.dialing_code,
          })

        }

      })


      if (isOk) {
        let _country_lsit = {};
        if (res.length > 0) {
          res.map((item, index) => {
            _country_lsit[item.code] = item.name;
          });
        }

        this.setState({
          countryList: res,
          countryListKey: _country_lsit,
          dialingCodeList: dialingCodeList,
          sgDialingCode: sgDialingCode
        }, () => {
          console.log(dialingCodeList)
          console.log(sgDialingCode)
        })

      }
    })
  }


  getNationality() {
    AuthGuard.getNationalityList({
      attribute: this.state.attribute
    }, (isOk, res) => {
      if (isOk) {
        let { list: nationality } = res
        this.setState({
          nationalityList: nationality.SG,
        })
      }
    })
  }

  getPatientInfo() {
    app.preloader.show();
    AuthGuard.userFullInfo({
      user_profile_id: this.state.user_profile_id
    }, (isOk, res) => {
      if (isOk) {
        let { history_list, user_info } = res;
        this.setState({
          history_list: history_list,
          ...user_info
        }, () => {
          if (this.state.gender) {
            if (this.state.gender === 'M' || this.state.gender === 'Male' || this.state.gender === 'male') {
              this.setState({
                origin_gender: 'male',
              })
            } else if (this.state.gender === 'F' || this.state.gender === 'Female' || this.state.gender === 'female') {
              this.setState({
                origin_gender: 'female',
              })
            }
          }

          this.setState({
            dialing_code: res.user_info.dialing_code
          }, () => {
            this.getCountryInfo();

            console.log(this.state.dialing_code)
          })

        });
      }
    })
  }
  goAppointment() {
    if (getCache('DrRoute')) {
      app.views.main.router.back(getCache('DrRoute'), {
        reloadAll: true,
        reloadCurrent: true,
        ignoreCache: true
      })
    } else {
      app.views.main.router.back(ROUTES.appointments, {
        ignoreCache: true,
        force: true
      })
    }
  }

  inputBlur(atrName, type, e) {
    let res = InputBlur(app, atrName, this.$$("input[name='" + atrName + "']"), type);
    if (!res) {
      this.setState({
        [atrName]: ''
      });
    }
  }

  consultationOpen() {
    this.setState({
      consultationHistoryOpen: true
    })
  }

  consultationOpenFalse() {
    this.setState({
      consultationHistoryOpen: false
    })
  }

  handlePreview(url) {
    this.setState(
      {
        iframeUrl: url,
      },
      () => {
        app.popup.open(".previewLabRpFile-popup");
      }
    );
  }

  closePreviewLabRpFilePopUp() {
    app.popup.close(".previewLabRpFile-popup");
  }

  openRemovePop(uid, uname, type) {

    console.log(uid, uname, type)

    app.dialog.create({
      title: 'Warning!',
      text: 'Are you sure you want to remove user from ' + uname,
      buttons: [
        {
          text: 'Yes',
          color: 'red',
          onClick: () => {
            this.removeJoinedProgram(uid, type)
          }
        },
        {
          text: 'No',
          color: 'blue',
          onClick: () => {
            console.log('no')
          }
        },
      ],
      verticalButtons: true,
    }).open();

  }

  removeJoinedProgram(uid, type) {
    console.log(uid)

    AuthGuard.removeJoinedProgram({
      service_access_id: uid
    }, (ok, res) => {
      if (ok) {
        ShowToast(app, res.message)

        AuthGuard.findProgramByUserIdAndProgramType({
          user_profile_id: this.state.user_profile_id
        }, (ok, res) => {
          if (ok) {

            if (type == 'Pending') {
              let _programPending = res.invitation_array.map(i => {
                return {
                  label: i.program_name,
                  value: i.program_id,
                  service_access_id: i.service_access_id
                }
              })

              this.setState({
                programPending: _programPending,
              }, () => {
                console.log(res.invitation_array, _programPending, this.state.programPending)
              })
            } else {

              let _programJoined = res.buy_program_array.map(i => {
                return {
                  label: i.program_name,
                  value: i.program_id,
                  service_access_id: i.service_access_id
                }
              })

              this.setState({
                programJoined: _programJoined,
              }, () => {
                console.log(res.buy_program_array, _programJoined, this.state.programJoined)
              })
            }
          }
        })
      } else {
        ShowToast(app, res.message)
      }

    })
  }

  // removePendingProgram(uid) {
  //   AuthGuard.removeJoinedProgram({
  //     service_access_id: 'a80dc25e-c4eb-40a6-8d3f-e3e28abda22e'
  //   }, (ok, res) => {
  //     if (ok) {
  //       ShowToast(app, res.message)

  //       AuthGuard.findProgramByUserIdAndProgramType({
  //         user_profile_id: this.state.user_profile_id
  //       }, (ok, res) => {
  //         if (ok) {

  //           // let _programJoined = res.buy_program_array.map(i => {
  //           //   return {
  //           //     label: i.program_name,
  //           //     value: i.program_id,
  //           //     service_access_id: i.service_access_id
  //           //   }
  //           // })

  //           // this.setState({
  //           //   programJoined: _programJoined,
  //           // }, () => {
  //           //   console.log(res.buy_program_array, _programJoined, this.state.programJoined)
  //           // })
  //         }
  //       })
  //     } else {
  //       ShowToast(app, res.message)
  //     }

  //   })
  // }

  onDialingCodeChange(values) {

    console.log(values)

    this.setState({
      dialing_code: values[0].value
    })
  }


  render() {
    const addProgramDom = (
      <Col className="cell_item" style={{ padding: '1.5rem' }}>
        <Row>Monitoring Programs: </Row>
        <List inlineLabels noHairlinesMd className="thin-list-select background-gray">
          {this.state.programJoined.length > 0
            ? this.state.programJoined.map((i) => (
              // <ListInput
              //     key={i.value}
              //     type="select"
              //     style={{ width: "450px" }}
              //     value={i.value}
              //     disabled
              //     outline
              // >
              //     <option key={i.label} value={i.value}>
              //         {i.label}
              //     </option>
              // </ListInput>
              <div key={'programJoined' + i.value}>
                <span style={{ display: "inline-block", width: '470px' }}>{i.label}</span>
                <div style={{ display: "inline-block", color: "#5BB3C4", cursor: "pointer", marginLeft: '30px' }} onClick={this.openRemovePop.bind(this, i.service_access_id, i.label, 'programJoined')}>
                  <img src={deleteIcon} style={{ width: '20px' }} className="Delete" alt="Delete" />
                </div>
                <div>
                  <i style={{ fontSize: '12px' }}>joined {i.created_at}</i>
                </div>
              </div>

            ))
            : ""}
        </List>
        <List inlineLabels noHairlinesMd className="thin-list-select background-gray">
          {this.state.programPending.length > 0
            ? this.state.programPending.map((i) => (
              <ul key={i.value}>
                <div style={{ display: "inline-block", width: '500px' }}>
                  {/* <ListInput
                    key={i.value}
                    type="select"
                    style={{ width: "450px" }}
                    value={i.value}
                    disabled
                    outline
                  >
                    <option key={i.label} value={i.value}>
                      {i.label}
                    </option>
                  </ListInput> */}
                  {i.label}
                </div>
                <div style={{ display: "inline-block" }}>Pending</div>
                <div style={{ display: "inline-block", color: "#5BB3C4", cursor: "pointer", marginLeft: '30px' }} onClick={this.openRemovePop.bind(this, i.service_access_id, i.label, 'Pending')}>
                  <img src={deleteIcon} style={{ width: '20px' }} className="Delete" alt="Delete" />
                </div>
              </ul>
            ))
            : ""}
        </List>
        <List inlineLabels noHairlinesMd className="thin-list-select background-gray">
          {this.state.program_ids.length > 0 &&
            this.state.program_ids.map((i, idx) =>
              idx === 0 ? (
                <ul key={'ul' + i.value}>
                  <div style={{ display: "inline-block", marginRight: '36px' }}>
                    <ListInput
                      type="select"
                      style={{ width: "450px" }}
                      value={this.state.program_ids[idx]}
                      onChange={this.onProgramChange.bind(this, idx)}
                      outline
                    >
                      {this.state.programOptions.map((i) => (
                        <option key={i.value} value={i.value}>
                          {i.label}
                        </option>
                      ))}
                    </ListInput>
                  </div>
                  <Button
                    onClick={this.onAddProgram.bind(this.idx)}
                    style={{ marginTop: "-5px", display: "inline-block" }}
                    color="custom-blue"
                    borderColor="custom-blue"
                  >
                    <Icon f7="plus_app"></Icon>
                  </Button>
                  {/* <Button
                    onClick={this.onConfirmAddProgram.bind(this)}
                    style={{ marginTop: "-5px", display: "inline-block" }}
                    color="custom-blue"
                    borderColor="custom-blue"
                  >
                    <Icon f7="checkmark_alt"></Icon>
                  </Button> */}
                </ul>
              ) : (
                  <ListInput
                    type="select"
                    style={{ width: "450px" }}
                    value={this.state.program_ids[idx]}
                    onChange={this.onProgramChange.bind(this, idx)}
                    outline
                    key={'program_ids' + i.label}
                  >
                    {this.state.programOptions.map((i) => (
                      <option key={i.label} value={i.value}>
                        {i.label}
                      </option>
                    ))}
                  </ListInput>
                )
            )}
        </List>
      </Col>
    );
    return (
      <FramePage name={ROUTES.patientInfo}>
        <div className="navBar">
          <Link
            animate={false}
            color="gray"
            onClick={this.goAppointment.bind(this)}
          >
            Video Appointments
              </Link>
              &gt; <span>{this.state.name}</span>
        </div>
        <Row className="navTitleBar">
          <Col>Patient Information</Col>
          <Col>
            <Row>
              <Col width="100">
                <div className="footerBtn">
                  <Button onClick={this.goAppointment.bind(this)} raised className="btn-large outline-blue-btn">Back</Button>
                  <Button onClick={this.saveAppointment.bind(this)} className="navbarBtn fill-blue-btn" raised fill>Save</Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="white-block patient-info">
          <Row>
            <Col>
              <Row className="block_title mt20">
                <Col width="95">Patient Details</Col>
              </Row>
              <Row className="cell_list">
                <Col>
                  <Row className="cell_item">
                    <Col width="30">Name: </Col>
                    <Col width="70">
                      <input
                        type='text'
                        name="name"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.name === null ? '' : this.state.name}
                        onChange={(e) => {
                          this.setState({ name: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Mobile No.: </Col>
                    <Col width="70">
                      <div style={{ display: 'inline-block', width: '40%' }}>
                        <Select
                          options={this.state.dialingCodeList}
                          values={this.state.sgDialingCode.length > 0 ? this.state.sgDialingCode : []}
                          style={{ border: 'none' }}
                          placeholder="Dialing Code"
                          name='dialingCode'
                          // onChange={this.onDialingCodeChange.bind(this)}
                          className='country-select input-dropdown'
                        />
                      </div>
                      <div style={{ display: 'inline-block', width: '60%' }}>
                        <input
                          type='text'
                          name="mobile_number"
                          className="formInput input-with-value input-invalid"
                          value={this.state.mobile_number === null ? '' : this.state.mobile}
                          disabled
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Date of Birth : </Col>
                    <Col width="70">
                      <input
                        type='date'
                        name="dob"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.origin_dob}
                        onChange={(e) => {
                          this.setState({ origin_dob: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Nationality: </Col>
                    <Col width="70">
                      <div className="search-input input-dropdown-wrap">
                        <select
                          value={this.state.nationality}
                          onChange={(e) => {
                            this.setState({ nationality: e.target.value });
                          }}
                        >
                          <option value="" style={{ display: "none" }}></option>
                          {this.state.nationalityList.map((item, index) => {
                            return <option value={item} key={item}>{item}</option>
                          })}
                        </select>

                      </div>
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Identification No.: </Col>
                    <Col width="70">
                      <input
                        type='text'
                        name="id_number"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.id_number === null ? '' : this.state.id_number}
                        onChange={(e) => {
                          this.setState({ id_number: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Email: </Col>
                    <Col width="70">
                      <input
                        type='text'
                        name="email"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.email === null ? '' : this.state.email}
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Gender: </Col>
                    <Col width="70">
                      <div className="search-input input-dropdown-wrap">
                        <select
                          value={this.state.origin_gender}
                          onChange={(e) => {
                            this.setState({ origin_gender: e.target.value });
                          }}
                        >
                          <option value="" style={{ display: "none" }}></option>
                          {this.state.genderList.map((item, index) => {
                            return <option value={item.code} key={item.code}>{item.name}</option>
                          })}
                        </select>

                      </div>
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Marital Status: </Col>
                    <Col width="70">
                      <div className="search-input input-dropdown-wrap">
                        <select
                          value={this.state.marital_status ? this.state.marital_status : ""}
                          onChange={(e) => {
                            this.setState({ marital_status: e.target.value });
                          }}
                        >
                          <option value="" style={{ display: "none" }}></option>
                          {this.state.maritalStatusList.map((item, index) => {
                            return <option value={item.label} key={item.label}>{item.value}</option>
                          })}
                        </select>
                      </div>
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Drug Allergy: </Col>
                    <Col width="70">
                      <input
                        type='text'
                        name="drug_allergy"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.drug_allergy === null ? '' : this.state.drug_allergy}
                        onChange={(e) => {
                          this.setState({ drug_allergy: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="block_title mt20">
                <Col width="95">Patient Address</Col>
              </Row>
              <Row className="cell_list">
                <Col>
                  <Row className="cell_item">
                    <Col width="30">Country: </Col>
                    <Col width="70">
                      <div className="search-input input-dropdown-wrap">
                        <select
                          value={this.state.country_code}
                          onChange={(e) => {
                            this.setState({ country_code: e.target.value });
                          }}
                        >
                          <option value="" style={{ display: "none" }}></option>
                          {this.state.countryList.map((item, index) => {
                            return <option value={item.code} key={"countryList" + index}>{item.name}</option>
                          })}
                        </select>

                      </div>
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Postal Code: </Col>
                    <Col width="70">
                      <input
                        type='text'
                        name="postal_code"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.postal_code === null ? '' : this.state.postal_code}
                        onBlur={this.inputBlur.bind(this, 'postal_code', 'number')}
                        onChange={(e) => {
                          this.setState({ postal_code: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Street: </Col>
                    <Col width="70">
                      <input
                        type='text'
                        name="street"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.street === null ? '' : this.state.street}
                        onChange={(e) => {
                          this.setState({ street: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className="cell_item">
                    <Col width="30">Unit No.: </Col>
                    <Col width="70">
                      <input
                        type='text'
                        name="unit_number"
                        className="formInput input-with-value input-invalid"
                        placeholder=""
                        value={this.state.unit_number === null ? '' : this.state.unit_number}
                        onChange={(e) => {
                          this.setState({ unit_number: e.target.value });
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="white-block patient-info">
          {addProgramDom}
        </div>
        <div className="gray-block consultation-history" style={{ background: '#f8f8f8' }}>
          <Row className="block_title">
            <Col width="30">Consultation History</Col>
            <Col width="70">
              {this.state.consultationHistoryOpen === false ?
                <span className="consult-notes" onClick={this.consultationOpen.bind(this)}>+</span> : <span className="consult-notes" onClick={this.consultationOpenFalse.bind(this)}>-</span>}
            </Col>
          </Row>
          {this.state.consultationHistoryOpen ?
            <Row className="cell_list">
              <Col>
                {this.state.history_list
                  ? this.state.history_list.map((item, index) => {
                    let isLabIwisReports =
                      item.documents &&
                      typeof item.documents === "object" &&
                      [
                        "iwis_health_report",
                        "lab_report",
                      ].includes(item.documents.file_type);
                    return (
                      <div
                        key={item.id}
                        className="patient-all list-consultation"
                      >
                        <div className="item-header">
                          <div className="item-header-left">
                            <div className="item-header-left-info">
                              <div
                                className="consultation-date fwb"
                                style={{
                                  display: isLabIwisReports
                                    ? "none"
                                    : "block",
                                }}
                              >
                                {item.time}
                              </div>
                              <div
                                className={`consultation-time ${
                                  isLabIwisReports ? "fwb" : "txt-gray"
                                  }`}
                              >
                                {item.date}
                              </div>
                            </div>
                            <div className="content-all">
                              {isLabIwisReports ? (
                                <div className="content">
                                  <div className="txt-notes-title">
                                    Report:
                                      </div>
                                  <div className="diagnosis fwb">
                                    {item.documents &&
                                      item.documents.file_type ? (
                                        <span
                                          style={{
                                            marginRight: "10px",
                                          }}
                                        >
                                          {item.documents.file_type ===
                                            "iwis_health_report"
                                            ? "Health Report"
                                            : "Lab Results"}
                                        </span>
                                      ) : (
                                        "-"
                                      )}
                                  </div>
                                </div>
                              ) : (
                                  <div className="content">
                                    <div className="txt-notes-title">
                                      Diagnosis:
                                      </div>
                                    <div className="diagnosis">
                                      {item.diagnosis
                                        ? item.diagnosis.map(
                                          (
                                            diagnosis_items,
                                            diagnosis_index
                                          ) => {
                                            return (
                                              <span
                                                key={diagnosis_items.id}
                                                style={{
                                                  marginRight: "10px",
                                                }}
                                              >
                                                {diagnosis_items.name}
                                              </span>
                                            );
                                          }
                                        )
                                        : "-"}
                                    </div>
                                  </div>
                                )}
                              <div
                                className="content"
                                style={{
                                  visibility: isLabIwisReports
                                    ? "hidden"
                                    : "visible",
                                }}
                              >
                                <div className="txt-notes-title">
                                  Notes:
                                    </div>
                                <div className="notes">
                                  {item.doctor_notes
                                    ? item.doctor_notes
                                    : "-"}
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link
                            className="outline-blue-btn btn-normal"
                            href={ROUTES.consultationSummary}
                            reloadCurrent
                            routeProps={{ videoCallId: item.id }}
                            style={{
                              visibility: isLabIwisReports
                                ? "hidden"
                                : "visible",
                            }}
                          >
                            View Summary
                              </Link>
                          {/* <Button className="outline-blue-btn" onClick={this.ViewSummary.bind(this,item.id)}>View Summary </Button> */}
                        </div>
                        <div className="attachment">
                          Attachments:
                              {isLabIwisReports ? (
                            <span className="txt-green">
                              <Link
                                onClick={this.handlePreview.bind(this, item.documents.public_file_url)}
                              >
                                {item.documents.file_name}
                              </Link>
                            </span>
                          ) : item.documents &&
                            item.documents.length > 0 ? (
                                item.documents.map((iitem, iindex) => {
                                  return (
                                    <span
                                      key={iitem.id}
                                      className="txt-green attachment-item"
                                    >
                                      <Link
                                        onClick={this.handlePreview.bind(this, iitem.public_file_url)}
                                      >
                                        {iitem.file_name}
                                      </Link>
                                    </span>
                                  );
                                })
                              ) : (
                                <span className="txt-green">-</span>
                              )}
                        </div>
                      </div>
                    );
                  })
                  : ""}
              </Col>
            </Row>
            : ''}
        </div>
        <Popup
          className="previewLabRpFile-popup"
          tabletFullscreen
          swipeToClose
          swipeHandler=".swipe-handler"
        >
          <Page>
            <div className="previewLabRpFile-header">
              <Link
                onClick={this.closePreviewLabRpFilePopUp.bind(this)}
                iconOnly
              >
                <Icon
                  ios="f7:multiply_circle_fill"
                  aurora="f7:multiply_circle_fill"
                  md="material:multiply_circle_fill"
                  className="iconXmark"
                ></Icon>
              </Link>
            </div>
            <Row className="iframeBox1">
              <Col>
                <iframe src={this.state.iframeUrl} frameBorder="0" />
              </Col>
            </Row>
          </Page>
        </Popup>
      </FramePage>
    );
  }
}

export default PatientInfo;