import React, { useEffect } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS } from '../../../../constants';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useMediaStreamTrack from '../../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import { useVideoInputDevices } from '../deviceHooks/deviceHooks';
import AuthGuard from '../../../../../../services/AuthGuard';
import useLocalVideoToggle from '../../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { useAppState } from '../../../../state';

export default function VideoInputList() {
  const videoInputDevices = useVideoInputDevices();
  const { localTracks, getLocalVideoTrack } = useVideoContext();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const {endCall} = useAppState();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    localVideoTrack.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  useEffect(() => {
    if (endCall) {
      return;
    }
    if (!localVideoInputDeviceId) {
      if (!localTracks.find(track => track.kind === 'video')) {
        toggleVideoEnabled();
        toggleVideoEnabled();
      }
    } else {
      if (!videoInputDevices.find(device => device.deviceId === localVideoInputDeviceId)) {
        replaceTrack(videoInputDevices[0].deviceId)
      } else {
        localTracks.forEach(track => track.restart())
      }
    }
  }, [videoInputDevices.length, endCall])

  return (
    <div className="inputSelect">
      {videoInputDevices.length > 0 ? (
        <FormControl fullWidth>
          <Typography variant="h6">Video:</Typography>
          <span className="input-dropdown-wrap" style={{margin: 0}}>
            <select onChange={e => replaceTrack(e.target.value as string)} value={localVideoInputDeviceId || ''}>
              {videoInputDevices.map(device => (
                <option value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </option>
              ))}
            </select>
          </span>
        </FormControl>
      ) : (
        <>
          <Typography variant="h6">Video Input:</Typography>
          <Typography>{localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}</Typography>
        </>
      )}
      <div className="video-preview">
        {localVideoTrack ? (
          <VideoTrack isLocal track={localVideoTrack} />
        ) : <p>Camera not available</p>}
      </div>
    </div>
  );
}
