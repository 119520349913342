import { 
    FETCH_PATIENTLIST ,
    FETCH_PATIENTDETAIL,
    FETCH_CREATEDIALOG,
    FETCH_UPDATEUSERGOAL
} from "../actionTypes";

const initalState = {
    patientListData: {},
}

export default function (state = initalState, action) {
    
    switch (action.type) {
        case FETCH_PATIENTLIST:
            return {
                ...state,
                patientListData: action.patientListResult,
            };
        case FETCH_CREATEDIALOG:
            return {
                ...state,
                dialogInfo: action.dialogInfo,
            };    
        case FETCH_PATIENTDETAIL:
            return {
                ...state,
                patientDetail: action.patientDetail,
            };
        case FETCH_UPDATEUSERGOAL:
            // if (state.patientDetail.user_profile_id === action.userGoal.userProfileId){
            //     state.patientDetail.sys_goal = action.userGoal.sysGoal;
            //     state.patientDetail.dia_goal = action.userGoal.diaGoal;
            // }
            return {
                ...state,
                updatedGoal: action.userGoal.sysGoal ? true : false
            };
        default:
            return state;
    }
}