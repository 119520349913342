import React, { Component } from 'react';
import SideBar from '../components/SideBar'
import { WeekEveryDay, dayEveryTime, ROUTES } from '../commons/Constants'
import AuthGuard from '../services/AuthGuard'
import FramePage from '../components/FramePage';
import cceye from '../images/icons/cceye.png'
import eye from '../images/icons/eye.png'
import { f7 as app, Row, Col, Block, Page, Button, Icon, Link, Tabs, Tab, Input, Popup, List, ListItem, Checkbox, Radio } from 'framework7-react';
import html2canvas from 'html2canvas';
import { ShowToast, InputBlur, InputFoucs, VerificationFields, GetValue, encryptRequest, CheckTimeSlots, checkValidTimeSlots, insertStr, CompareTime, convertTimeStamp, convertImgToBase64, setCache, getCache } from '../commons/Helpers'
import IconCancel from '../images/icons/icon_cancel.svg'
import IconClinicLogo from '../images/icons/icon_clinicLogo.png'
import IconIconHVLong from '../images/icons/icon_HV_Long.png'
import IconCalendar from '../images/icons/icon_calendar.png'
import IconBgVc from '../images/icons/icon_bg_vc.png'
import IconAb from '../images/icons/icon_ab.png'
import SignatureCanvas from 'react-signature-canvas'
import TimeSlots from '../components/TimeSlots'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import CropperImg from '../components/CropperImg'
import MultiSelect from "@khanacademy/react-multi-select";
import CountryProvinceCity from '../components/CountryProvinceCity';
import { GOOGLEMAP_KEY } from '../commons/CountryService';
import Modal from '../components/Modal';
import IconDoctor from '../images/icons/icon_doctor.png';
import MedicationList from './MedicationList';
// import download from 'image-downloader'
import Downloader from 'js-file-downloader';
import QRCode from 'qrcode';
import weixuanzhongyuanquan from '../images/icons/weixuanzhongyuanquan.png';
import checkedyuanquan from '../images/icons/gouxuan-xuanzhong-yuankuang.png';
import deleteImg from '../images/icons/delete.png';
import sousuo from '../images/icons/sousuo.png';

class Setting extends Component {
    constructor(props) {
        super(props);
        let activeTabType = 'GeneralTab'

        if (AuthGuard.isRedirect) {
            activeTabType = 'manageAccountsTab'
        } else {
            if (this.props.activeTabType) {
                activeTabType = this.props.activeTabType
            }
        }
        let activePopup = ''
        if (this.props.popup) {
            activePopup = this.props.popup
        }
        this.state = {
            activePopup: activePopup,
            calendarConfig: null,
            clinic_id: '',
            clinic_email: '',
            clinic_name: '',
            clinic_contact_no: '',
            clinic_emergency_no: '',
            clinic_address: '',
            postal_code: '',
            unit_no: '',
            delivery_time: '',
            user_registration_from_link: '',
            working_days: [],
            doctor_name: '',
            dialingCode: '',
            mobile: '',
            roleList: [],
            staffList: [],
            duration: 30,
            durationPerSession: [
                5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60
            ],
            timeslotInfoId: '',
            delivery_price: 0,
            delivery_time: 0,
            accountIDorName: '',
            eyeUrl: eye,
            eye1Url: eye,
            eye2Url: eye,
            oldFlag: 0,
            newFlag: 0,
            conFlag: 0,
            selectedWeeks: [],
            timeslot_start: '',
            timeslot_end: '',
            openSignaturePad: false,
            activeTabType: activeTabType,
            clinicTimeSlotsShifts: [],
            clinicTimeslotLoaded: false,
            drTimeSlotsShifts: [],
            drProfile: {},

            dr_code_name: '',
            dr_code_name_set: false,
            dr_consultation_fee: '',
            dr_dialing_code: '',
            dr_duration: 5,
            dr_is_public: '',
            dr_login_email: '',
            dr_mcr_no: '',
            dr_mobile_number: '',
            dr_name: '',
            dr_photo: '',
            dr_professional_profile: '',
            dr_professional_profile_length: 0,
            dr_profile_is_complete: '',
            dr_signature_S3Image: '',
            dr_signature_url: '',
            dr_specialtyId: [],
            dr_specialtyList: '',
            doCropper: false,
            specialtyOptions: [],
            selected: [],
            introduction_length: 0,
            whatsappqr: '',
            whatsapp_default: '',
            whatsapp_dialing_code_default: '',
            saveVC: false,
            service_country: [],
            countryListOptions: [],
            countryList: [],
            code_word: '',
            myprofileModalOpen: false,
            loadingSaveMyprofile: false,
            // logoutMinutes: '',
            user_profile_id: '',
            loadingClinic: false,
            clinicModalOpen: false,
            clinic_dialing_code: '65',
            generaVCloading: false,
            delRoleModalOpen: false,
            loadingDelRoleBtn: false,
            delStaffModalOpen: false,
            loadingDelStaffBtn: false,
            delRoleItem: {},
            delStaffId: '',
            merchantSettingLogouTimeList: [],
            getLogouTimes: '',
            manageAccounts: 0,
            findProfileForStaffName: '',
            findProfileForStaffEmail: '',
            auto_confirm_appointment: '',
            findProfileForStaffRoleType: '',
            send_otp_type: 'Email',
            send_mobile_to: false,
            dialing_code: '',
            country_national_list: '',
            user_Email: '',
            user_Mobile: '',
            receive_by_mobile: '',
            send_Notifications_Mobile: '',
            send_otp_Mobile: '',
            verify_flag: false,
            verify_flag2: false,
            send_otp_email: '',
            email_datas: [],
            send_type: '',
            notification_types_Data: [
                { "notification_type": "join_program", "type": "MOBILE" },
                { "notification_type": "join_program", "type": "EMAIL" }
            ],
            wait_verify_flag: false,
            wait_verify_flag2: false,
            email_duplicate_flag: true,
            is_choose: 'N',
            doctordetail_url: ''
        }

        this.timeSlotsShiftsIndex = 5
        this.coachProfile = AuthGuard.getCoachProfile();

        console.log(this.props)

    }

    componentWillMount() {

    }

    componentDidMount() {
        this.getCountryList()
        this.getDrProfile()
        this.getRoleListAndStaffList()
        this.getMerchantSettingLogouTime()

        if (this.coachProfile.userType === 'USER') {
            this.findProfileForStaffList()
        }

        this.getCalendarConfig()
        this.getGeneralInfo()

        if (this.state.receive_by_mobile == '') {
            this.setState({
                receive_by_mobile: '65'
            }, () => {
                console.log(this.state.receive_by_mobile)
            })
        }

    }

    componentWillUpdate() {
        app.preloader.hide();
    }
    componentWillUnmount() {
        console.log('componentWillUnmount')
    }

    getGeneralInfo() {
        let appointments_mobile = document.querySelector('.appointments_mobile').childNodes[0].childNodes[0]
        let delivery_mobile = document.querySelector('.delivery_mobile').childNodes[0].childNodes[0]
        let notification_SMS = document.querySelector('.notification_SMS').childNodes[0].childNodes[0]

        let settingReceiveByAppointments = document.querySelector('.settingReceiveByAppointments').childNodes[0].childNodes[0]
        let settingReceiveDelivery = document.querySelector('.settingReceiveDelivery').childNodes[0].childNodes[0]

        AuthGuard.getGeneralForCoach({

        }, (isOk, res) => {
            if (isOk) {

                console.log(res)

                this.setState({
                    send_otp_type: res.one_time_password ? res.one_time_password : 'Email',
                    send_otp_email: res.login_email ? res.login_email : '',
                    send_otp_Mobile: res.one_time_password_login_mobile ? res.one_time_password_login_mobile : '',
                    verify_flag: res.one_time_password_login_mobile && res.one_time_password_login_mobile !== '' ? true : false,
                    dialing_code: res.one_time_password_login_dialing_code ? res.one_time_password_login_dialing_code : ''
                }, () => {


                    if (this.state.send_otp_type == 'Mobile') {
                        if (this.state.dialing_code == '') {
                            this.setState({
                                dialing_code: '65'
                            })
                        }
                    }


                    var _list = this.state.email_datas
                    if (res.notification_receive_by_result && res.notification_receive_by_result.length > 0) {

                        console.log(res.notification_receive_by_result)

                        res.notification_receive_by_result.map((item, index) => {
                            if (item.type == "EMAIL") {
                                // console.log(item)
                                this.setState({
                                    email_datas: res.notification_receive_by_result
                                })
                            }

                            if (item.type == "MOBILE") {
                                // console.log(item)

                                this.setState({
                                    receive_by_mobile: item.dialing_code,
                                    send_Notifications_Mobile: item.mobile,
                                    verify_flag2: true,
                                    is_choose: item.is_choose,
                                }, () => {
                                    console.log(this.state.is_choose)
                                    if (this.state.is_choose == 'Y') {
                                        notification_SMS.checked = true
                                    }
                                })
                            }

                        })

                        if (res.notification_receive_by_result.length == 1 && res.notification_receive_by_result[0].type == "MOBILE") {
                            _list.push({
                                dialing_code: "",
                                email: res.login_email,
                                id: "",
                                mobile: "",
                                type: "EMAIL",
                            })

                            this.setState({
                                email_datas: _list
                            }, () => {
                                console.log(_list)
                            })
                        }

                    } else {

                        // console.log(_list)
                        _list.push({
                            dialing_code: "",
                            email: res.login_email,
                            id: "",
                            mobile: "",
                            type: "EMAIL",
                        })

                        this.setState({
                            email_datas: _list
                        })
                    }


                    if (res.notification_type_result) {
                        res.notification_type_result.map((item, index) => {
                            if (item.type == 'MOBILE') {

                                if (item.notification_type == 'appointments_updates') {

                                    appointments_mobile.checked = true

                                }
                                if (item.notification_type == 'delivery') {

                                    delivery_mobile.checked = true

                                }
                            }
                            if (item.type == 'EMAIL') {
                                if (item.notification_type == 'appointments_updates') {

                                    settingReceiveByAppointments.checked = true
                                }
                                if (item.notification_type == 'delivery') {

                                    settingReceiveDelivery.checked = true
                                }
                            }
                        })
                    } else {

                        settingReceiveByAppointments.checked = true;
                        settingReceiveDelivery.checked = true;
                    }

                    if (sessionStorage.getItem('SMSVerify_mobile') || this.props.SMS) {
                        this.setState({
                            verify_flag: true,
                            send_otp_type: 'Mobile',
                            dialing_code: sessionStorage.getItem('SMSVerify_dialing_code'),
                            send_otp_Mobile: sessionStorage.getItem('SMSVerify_mobile')
                        }, () => {
                            console.log(this.state.send_otp_type)
                        })

                    }

                    if (sessionStorage.getItem('NotVerify_mobile') || this.props.Notifications) {
                        this.setState({
                            is_choose: 'Y',
                            verify_flag2: true,
                            receive_by_mobile: sessionStorage.getItem('NotVerify_dialing_code'),
                            send_Notifications_Mobile: sessionStorage.getItem('NotVerify_mobile'),
                        }, () => {
                            console.log(this.state.is_choose)
                        })
                        notification_SMS.checked = true
                    }

                })

            }
        })


    }

    getCalendarConfig() {
        AuthGuard.calendarSyncInfo({}, (ok, res) => {
            if (typeof res === 'undefined') {
                this.setState({
                    calendarConfig: {
                        sync_on: 0
                    }
                })
                return
            }
            this.setState({
                calendarConfig: res
            })

        })
    }

    calendarSignIn() {
        app.preloader.show()
        AuthGuard.googleConnect({}, (ok, res) => {
            app.preloader.hide()
            if (ok) {
                window.location.href = res
            }
        })
    }

    calendarEditSettings() {
        app.views.main.router.navigate(ROUTES.googleConnect, { reloadAll: true });
    }

    calendarDisconnect() {
        app.popup.close('.DisconnectGoogleCalendar-popup')
        app.preloader.show()
        AuthGuard.calendarSyncOff({}, (ok, res) => {
            app.preloader.hide()
            if (ok) { }
            this.getCalendarConfig()
        })
    }

    findProfileForStaffList() {
        AuthGuard.findProfileForStaff({

        }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    findProfileForStaffName: res.name,
                    findProfileForStaffEmail: res.email,
                    findProfileForStaffRoleName: res.role_name,
                    findProfileForStaffRoleType: res.role_type

                })
            }
        })
    }

    getMerchantSettingLogouTime() {

        let { clinic_user_profile_id } = this.coachProfile

        AuthGuard.getUserLogoutTime({
            // id: clinic_user_profile_id
        }, (isOk, res) => {
            if (isOk) {

                this.setState({
                    getLogouTimes: res.value,
                })

            }
        })


        AuthGuard.getGeneralTimer({
        }, (isOk, res) => {
            // console.log(res)
            if (isOk) {

                this.setState({
                    merchantSettingLogouTimeList: res,
                })

                // console.log(this.state.merchantSettingLogouTimeList)
            }
        })
    }



    eyeShow(type = 'passProfile', v) {
        var oldPass = document.querySelector("#oldPassword")
        var newPass = document.querySelector("#newPassword")
        var conPass = document.querySelector("#confirmPassword")

        // console.log(v)
        if (type == 'passProfile') {
            if (this.state.oldFlag == 0) {
                oldPass.type = 'text';
                this.setState({
                    eyeUrl: cceye,
                    oldFlag: 1
                })
            } else {
                oldPass.type = 'password';
                this.setState({
                    eyeUrl: eye,
                    oldFlag: 0
                })
            }
        } else if (type == 'newPassProfile') {
            if (this.state.newFlag == 0) {
                newPass.type = 'text';
                this.setState({
                    eye1Url: cceye,
                    newFlag: 1
                })
            } else {
                newPass.type = 'password';
                this.setState({
                    eye1Url: eye,
                    newFlag: 0
                })
            }
        } else {
            if (this.state.conFlag == 0) {
                conPass.type = 'text';
                this.setState({
                    eye2Url: cceye,
                    conFlag: 1
                })
            } else {
                conPass.type = 'password';
                this.setState({
                    eye2Url: eye,
                    conFlag: 0
                })
            }
        }
    }


    fillFormData() {
        let self = this;
        app.form.fillFromData('#settting-clinic-detail', this.state);
        AuthGuard.getTimeslot({
            clinic_id: this.coachProfile.clinic_info_id
        }, (isOk, res) => {

            // console.log(res)

            if (isOk) {
                let _clinicTimeSlotsShifts = []
                if (res.slots) {
                    _clinicTimeSlotsShifts = JSON.parse(res.slots)
                    _clinicTimeSlotsShifts = _clinicTimeSlotsShifts.filter(item => item.data && item.data.length > 0)
                }

                self.setState({
                    clinicTimeslotInfoId: res.id,
                    clinicDuration: res.duration ? res.duration : 5,
                    clinicTimeSlotsShifts: _clinicTimeSlotsShifts
                })
            }
            this.setState({
                clinicTimeslotLoaded: true
            })
        })
    }

    getClinicInfo() {
        let self = this
        let { countryList } = this.state
        AuthGuard.getClinicProfile({}, (isOk, res) => {
            if (isOk) {
                let _service_country = []
                if (res.countries_of_practice && res.countries_of_practice.length > 0) {
                    res.countries_of_practice.map((item, index) => {
                        let _code = item.country_code
                        let getSelectCountty = countryList.find(iitem => iitem.code === _code)
                        if (getSelectCountty.id) {
                            _service_country.push(getSelectCountty.id)
                        }
                    })
                }

                self.setState({
                    clinic_id: res.id ? res.id : '',
                    clinic_address: res.clinic_address ? res.clinic_address : '',
                    clinic_contact_no: res.clinic_contact_no ? res.clinic_contact_no : '',
                    clinic_email: res.clinic_email ? res.clinic_email : '',
                    clinic_emergency_no: res.clinic_emergency_no ? res.clinic_emergency_no : '',
                    clinic_name: res.clinic_name ? res.clinic_name : '',
                    delivery_price: res.delivery_price ? res.delivery_price : '',
                    delivery_time: res.delivery_time ? res.delivery_time : '',
                    introduction: res.introduction ? res.introduction : '',
                    introduction_length: res.introduction ? res.introduction.length : 0,
                    postal_code: res.postal_code ? res.postal_code : '',
                    unit_no: res.unit_no ? res.unit_no : '',
                    countries_of_practice: res.countries_of_practice ? res.countries_of_practice : '',
                    service_country: _service_country ? _service_country : '',
                    code_word: res.code_word ? res.code_word : '',
                    country: res.country ? res.country : '',
                    country_code: res.country_code ? res.country_code : '',
                    city_code: res.city_code ? res.city_code : '',
                    province_code: res.province_code ? res.province_code : '',
                    clinic_dialing_code: res.clinic_dialing_code ? res.clinic_dialing_code : '',
                    clinic_logo: res.clinic_logo ? res.clinic_logo : '',
                    clinic_logo_name: res.clinic_logo ? res.clinic_logo : '',
                    clinic_logo_url: res.clinic_logo_url ? res.clinic_logo_url : '',
                    auto_confirm_appointment: res.auto_confirm_appointment ? res.auto_confirm_appointment == 'Y' : false,
                }, () => {
                    this.refs.countryProvinceCity && this.refs.countryProvinceCity.updateSelectList()
                    if (this.coachProfile.userType === 'COACH') {
                        this.getWAQR()

                    }

                })
            }

            self.fillFormData();
        })
    }

    getDrProfile() {
        app.preloader.show();

        if (!this.state.findProfileForStaffName) {

            AuthGuard.getDrProfile({}, (isOk, res) => {
                // console.log(res)
                if (isOk) {
                    let specialtyOptions = res.specialtyList.length > 0 ? res.specialtyList.map(item => {
                        return {
                            label: item.display_name,
                            value: item.id
                        }
                    }) : []

                    let _drTimeSlotsShifts = []
                    if (res.doctorTimeSlot.slots) {
                        _drTimeSlotsShifts = JSON.parse(res.doctorTimeSlot.slots)
                        _drTimeSlotsShifts = _drTimeSlotsShifts.filter(item => item.data.length > 0)
                    }

                    // let state = {
                    this.setState({
                        specialtyOptions: specialtyOptions,
                        dr_code_name: res.code_name ? res.code_name : '',
                        dr_code_name_set: res.code_name,
                        dr_consultation_fee: res.consultation_fee ? res.consultation_fee : '',
                        dr_dialing_code: res.dialing_code ? res.dialing_code : '',
                        drTimeSlotsShifts: _drTimeSlotsShifts,
                        dr_duration: res.doctorTimeSlot.duration ? res.doctorTimeSlot.duration : 5,
                        dr_timeslotId: res.doctorTimeSlot.id ? res.doctorTimeSlot.id : '',
                        dr_is_public: res.is_public ? res.is_public : 'Y',
                        dr_login_email: res.login_email ? res.login_email : '',
                        dr_mcr_no: res.mcr_no ? res.mcr_no : '',
                        dr_mobile_number: res.mobile_number ? res.mobile_number : '',
                        dr_name: res.name ? res.name : '',
                        dr_photo: res.photo ? res.photo : '',
                        dr_photo_name: res.photo ? res.photo : '',
                        dr_photo_url: res.photo_url ? res.photo_url : '',
                        dr_professional_profile: res.professional_profile ? res.professional_profile : '',
                        dr_professional_profile_length: res.professional_profile ? res.professional_profile.length : 0,
                        dr_profile_is_complete: res.profile_is_complete ? res.profile_is_complete : '',
                        dr_signature_url: res.signature_url ? res.signature_url : '',
                        dr_signature_S3Image: res.signature_S3Image ? res.signature_S3Image : '',
                        dr_specialtyId: res.specialty ? res.specialty : '',
                        dr_specialtyList: res.specialtyList ? res.specialtyList : '',
                        whatsapp_default: res.whatsapp_default ? res.whatsapp_default : '',
                        whatsapp_dialing_code_default: res.whatsapp_dialing_code_default ? res.whatsapp_dialing_code_default : '',
                        // webpage_profile_url: res.webpage_profile_url ? res.webpage_profile_url : '',
                        webpage_profile_url: res.webpage_profile_simple_url ? res.webpage_profile_simple_url : '',
                        url_merchantID: res.url_merchantID ? res.url_merchantID : '',
                        url_accountID: res.url_accountID ? res.url_accountID : '',
                        isHaveHS:res.simple_HSPG.isHaveHS ? res.simple_HSPG.isHaveHS : false,
                        isHaveHP:res.simple_HSPG.isHaveHP ? res.simple_HSPG.isHaveHP : false,
                        doctordetail_url: res.url_accountID && res.webpage_profile_simple_url ? res.webpage_profile_simple_url.replace(res.url_accountID,'') + res.url_merchantID : ''
                    }, () => {
                        this.getWAQR()
                    })
                }

                app.preloader.hide()


                // console.log(this.state)

            })
        } else {
            AuthGuard.findProfileForStaff({}, (isOk, res) => {
                this.setState({
                    findProfileForStaffName: res.name,
                    findProfileForStaffEmail: res.email,
                    findProfileForStaffRoleName: res.role_name,
                    findProfileForStaffRoleType: res.role_type
                })
            })
        }

    }

    getRoleListAndStaffList() {
        let self = this;
        AuthGuard.getClinicRoleList({}, (isOk, res) => {

            // console.log(res)

            if (isOk) {
                self.setState({
                    roleList: res
                })
            }
        })

        this.getStaffList()
    }

    getCountryList() {
        AuthGuard.getCountryList({
            page: 1,
            limit: 99999
        }, (isoK, res) => {
            if (isoK) {
                this.setState({
                    countryList: res
                }, () => {
                    this.getCountryOptions()
                    this.getClinicInfo()

                })

            }
        })
    }

    getCountryOptions() {
        let { countryList } = this.state
        let countryListOptions = []
        if (countryList.length > 0) {
            countryListOptions = countryList.map(item => {
                return {
                    label: item.name,
                    value: item.id
                }
            })
            this.setState({
                countryListOptions: countryListOptions
            })
        }

    }

    getWAQR() {
        // convertImgToBase64(AuthGuard.generateWhatsappQr('hello' + this.state.dr_code_name), (base64Img) => {
        //     this.setState({
        //         whatsappqr: base64Img
        //     })
        // })
        try {
            QRCode.toDataURL(this.state.webpage_profile_url, {
                width: 100,
                margin: 0
            }, (err, url) => {
                this.setState({
                    whatsappqr: url
                })
                // console.log(this.state.webpage_profile_url)
            })            
        } catch { }

        convertImgToBase64(this.state.dr_photo_url, (base64Img) => {
            this.setState({
                dr_photo_url: base64Img,
            })
        })

        convertImgToBase64(this.state.clinic_logo_url, (base64Img) => {
            this.setState({
                clinic_logo_url: base64Img,
            })
        })

    }

    browserIsIe() {
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
            return true;
        }
        else {
            return false;
        }
    }

    canDownloadCard() {
        return (this.state.dr_name !== ''
            && this.state.dr_code_name !== ''
            && this.state.dr_professional_profile !== ''
            && this.state.dr_specialtyId !== ''
            && this.state.dr_consultation_fee !== ''
            && String(this.state.clinic_logo_url).startsWith('data:image/png;base64')
            // && String(this.state.dr_photo_url).startsWith('data:image/png;base64')
        )
    }
    downloadImg() {
        if (!this.canDownloadCard()) {
            return
        }
        app.preloader.show()
        const printArea = document.getElementById("virtualCard");
        const saveAs = 'Virtual Card of ' + this.state.dr_name + '.png'
        html2canvas(printArea, {
            scale: 2,
            useCORS: false,
            crossOrigin: 'Anonymous'
        }).then(function (canvas) {
            const base64Byte = canvas.toDataURL();

            new Downloader({
                url: base64Byte,
                filename: saveAs
            }).then(function () {
                app.preloader.hide()
            });

        }).catch(error => {
            app.preloader.hide()
        });
    }

    getStaffList(_name = '') {
        AuthGuard.getStaffList({
            name: _name
        }, (isOk, res) => {

            // console.log(res)

            if (isOk) {
                this.setState({
                    staffList: res
                })
            }
        })
    }

    inputBlur(atrName, type, e) {
        let res = InputBlur(app, atrName, this.$$("input[name='" + atrName + "']"), type);
        if (!res) {
            this.setState({
                [atrName]: ''
            });
        }
    }

    signature() {
        this.setState({
            openSignaturePad: true
        })
    }

    canvas = {
        clear: () => { },
        toDataURL: (param) => {
            return "";
        },
    }

    // 重置
    reset() {
        let { dr_signature_url, openSignaturePad } = this.state
        if (openSignaturePad) {
            this.canvas.clear();
            this.setState({
                dr_signature_url: ''
            })
        } else {
            this.setState({
                openSignaturePad: true
            }, () => {

            })
        }

    }
    // 保存
    save() {
        let base64Byte = this.canvas.toDataURL("image/png");
        app.preloader.show()
        AuthGuard.uploadBase64Img({
            base64_image: base64Byte
        }, (isOk, res) => {
            if (isOk) {
                this.setState({
                    dr_signature_S3Image: res.s3_image_url,
                    dr_signature_url: res.name,
                    openSignaturePad: false
                })
            } else {
                ShowToast(app, 'Signature upload fail.')
            }
            app.preloader.hide()
        })
    }


    modalSaveClinic() {

        let {
            selected_country_code,
            selected_country_name,
            selected_province_code,
            selected_province_name,
            selected_city_code,
            selected_city_name,
        } = this.refs.countryProvinceCity.state

        let {
            service_country,
            countryList,
            clinic_logo_name,
            clinic_name,
            clinic_email,
            clinic_contact_no,
            clinic_emergency_no,
            clinic_address,
            code_word,
            introduction,
            clinic_dialing_code,
            unit_no,
            postal_code,
            delivery_time,
            delivery_price
        } = this.state

        let _service_country = []
        if (service_country.length > 0) {
            _service_country = service_country.map((id, index) => {
                let _getSelectedCountry = countryList.find(item => item.id === id)
                return {
                    country_code: _getSelectedCountry.code,
                    country: _getSelectedCountry.name
                }
            })
        }

        let formData = {}
        formData.country_code = selected_country_code
        formData.country = selected_country_name
        formData.province_code = selected_province_code
        formData.province = selected_province_name
        formData.city_code = selected_city_code
        formData.city = selected_city_name

        formData.service_country = JSON.stringify(_service_country)
        formData.logo = clinic_logo_name


        formData.clinic_logo_name = clinic_logo_name
        formData.clinic_name = clinic_name
        formData.clinic_email = clinic_email
        formData.clinic_dialing_code = clinic_dialing_code
        formData.clinic_contact_no = clinic_contact_no

        formData.clinic_emergency_no = clinic_emergency_no
        formData.clinic_address = clinic_address
        formData.introduction = introduction

        formData.unit_no = unit_no
        formData.postal_code = postal_code
        formData.code_word = code_word
        formData.delivery_time = delivery_time
        formData.delivery_price = delivery_price

        app.preloader.show();
        this.clinicPopupclose()
        AuthGuard.setClinicInfo(formData, (isOk, res) => {
            console.log(isOk, res)
            if (isOk) {
                this.getCountryList()
                ShowToast(app, 'Update Success!')
                setCache('clinicInfoUpdated', true)
            } else {
                ShowToast(app, res.message);
            }
            app.preloader.hide();
        });
    }

    saveClinicDetail() {

        let {
            clinic_logo_name,
            clinic_name,
            clinic_email,
            clinic_contact_no,
            clinic_emergency_no,
            clinic_address,
            code_word,
            introduction,
            clinic_dialing_code
        } = this.state


        let {
            selected_country_code,
            selected_country_name,
            selected_province_code,
            selected_province_name,
            selected_city_code,
            selected_city_name,
        } = this.refs.countryProvinceCity.state


        if (!clinic_logo_name) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                clinic_logo_url_error: true
            })
            return false

        } else {
            this.setState({
                clinic_logo_url_error: false
            })
        }

        if (!clinic_name) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                clinic_name_error: true
            })
            return false

        } else {
            this.setState({
                clinic_name_error: false
            })


        }

        if (!clinic_email) {
            app.popover.open('.fillInAllCompulsory-popup');

            this.setState({
                clinic_email_error: true
            })
            return false

        } else {
            this.setState({
                clinic_email_error: false
            })
        }

        if (!clinic_dialing_code) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                clinic_dialing_code_error: true
            })
            return false

        } else {
            this.setState({
                clinic_dialing_code_error: false
            })

        }

        if (!clinic_contact_no) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                clinic_contact_no_error: true
            })
            return false

        } else {
            this.setState({
                clinic_contact_no_error: false
            })

        }

        if (!clinic_address) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                clinic_address_error: true
            })
            return false

        } else {
            this.setState({
                clinic_address_error: false
            })

        }

        if (!selected_country_code) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                country_code_error: true
            })
            return false

        } else {
            this.setState({
                country_code_error: false
            })

        }

        if (!selected_province_code) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                province_code_error: true
            })
            return false

        } else {
            this.setState({
                province_code_error: false
            })

        }

        if (!selected_city_code) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                city_code_error: true
            })
            return false

        } else {
            this.setState({
                city_code_error: false
            })


        }

        if (!code_word) {
            app.popover.open('.fillInAllCompulsory-popup');
            this.setState({
                code_word_error: true
            })
            return false

        } else {
            this.setState({
                code_word_error: false
            })

        }

        let self = this
        self.setState({
            clinicModalOpen: true,
            generaVCloading: false
        })
    }


    saveAppointment() {

        let { clinicTimeSlotsShifts, clinicDuration, clinicTimeslotInfoId } = this.state

        console.log(clinicTimeSlotsShifts)

        if (clinicTimeSlotsShifts.length > 0) {

            if (clinicTimeslotInfoId) {
                this.$f7.preloader.show();
                AuthGuard.updateTimeslot({
                    id: clinicTimeslotInfoId,
                    timeslot: JSON.stringify(clinicTimeSlotsShifts),
                    duration: clinicDuration
                }, (isOk, res) => {
                    if (isOk) {

                    } else {
                        setTimeout(() => {
                            ShowToast(app, 'Oops! Get error will revert to show previous data.');
                            this.fillFormData();
                        }, 200);
                    }
                    this.$f7.preloader.hide();
                })
            } else {
                this.$f7.preloader.show();
                AuthGuard.addTimeslot({
                    timeslot: JSON.stringify(clinicTimeSlotsShifts),
                    clinic_id: this.coachProfile.clinic_info_id,
                    // duration: clinicDuration
                    duration: '30'
                }, (isOk, res) => {
                    // console.log(isOk, res);
                    if (isOk) {

                    } else {
                        setTimeout(() => {
                            ShowToast(app, 'Oops! Get errot will revert to show previous data.');
                            this.fillFormData();
                        }, 200);
                    }
                    this.$f7.preloader.hide();
                })
            }
        } else {
            ShowToast(app, 'You must complete Clinic TimeSlots.');
        }
    }

    setTimeSlotsToClinic = (timeSlots) => {
        let before = JSON.stringify(this.state.clinicTimeSlotsShifts)
        let after = JSON.stringify(timeSlots)
        if (before != after) {
            this.setState({
                clinicTimeSlotsShifts: timeSlots
            }, () => {
                this.saveAppointment()

                console.log(timeSlots)
            })
        }
    }

    setTimeSlotsToDr = (timeSlots) => {
        this.setState({
            drTimeSlotsShifts: timeSlots
        })
    }

    saveDrAvatar = (base64Byte, type) => {
        let self = this;
        if (type === 'cancel') {
            this.setState({
                dr_photo_url: base64Byte,
                dr_photo_name: this.state.dr_photo,
                doCropper: false
            })
        } else {
            app.preloader.show()
            AuthGuard.uploadBase64({
                base64_image: base64Byte
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        dr_photo_url: res.s3_image_url,
                        dr_photo_name: res.name,
                        doCropper: false
                    }, () => {
                        self.getWAQR()
                    })
                } else {
                    ShowToast(app, 'Avatar upload fail.')
                }
                app.preloader.hide()
            })
        }
    }

    saveClinicLogo = (base64Byte, type) => {
        let self = this;
        if (type === 'cancel') {
            this.setState({
                clinic_logo: base64Byte,
                clinic_logo_name: this.state.clinic_logo,
                doLogoCropper: false
            })
        } else {
            app.preloader.show()
            AuthGuard.uploadBase64Img({
                base64_image: base64Byte
            }, (isOk, res) => {
                if (isOk) {
                    this.setState({
                        clinic_logo_url: res.s3_image_url,
                        clinic_logo_name: res.name,
                        doLogoCropper: false
                    }, () => {
                        self.getWAQR()
                    })
                } else {
                    ShowToast(app, 'Logo upload fail.')
                }
                app.preloader.hide()
            })
        }
    }

    saveDrProfile = () => {


        if (!this.state.findProfileForStaffName) {
            let {
                dr_name,
                dr_mcr_no,
                dr_professional_profile,
                dr_specialtyId,
                dr_consultation_fee,
                dr_signature_url,
                dr_code_name
            } = this.state

            let has_error = false

            if (!dr_name) {
                this.setState({
                    dr_name_error: true
                })
                has_error = true
            } else {
                this.setState({
                    dr_name_error: false
                })
            }

            if (!dr_professional_profile) {
                this.setState({
                    dr_professional_profile_error: true
                })
                has_error = true
            } else {
                this.setState({
                    dr_professional_profile_error: false
                })
            }


            if (!dr_specialtyId) {
                this.setState({
                    dr_specialtyId_error: true
                })
                has_error = true
            } else {
                this.setState({
                    dr_specialtyId_error: false
                })
            }

            if (!dr_consultation_fee) {
                this.setState({
                    dr_consultation_fee_error: true
                })
                has_error = true
            } else {
                this.setState({
                    dr_consultation_fee_error: false
                })
            }

            if (!dr_signature_url) {
                this.setState({
                    dr_signature_url_error: true
                })
                has_error = true
            } else {
                this.setState({
                    dr_signature_url_error: false
                })
            }

            if (!dr_code_name) {
                this.setState({
                    dr_code_name_error: true
                })
                has_error = true
            } else {
                this.setState({
                    dr_code_name_error: false
                })
            }



            if (has_error) {
                app.popover.open('.fillInAllCompulsory-popup');
            } else {
                let self = this
                self.setState({
                    myprofileModalOpen: true,
                    generaVCloading: false
                })
            }



        } else {
            let self = this
            self.setState({
                myprofileModalOpen: true,
                generaVCloading: false
            })
        }

    }

    myprofilePopupclose() {
        this.setState({
            myprofileModalOpen: false
        })
    }

    clinicPopupclose() {
        this.setState({
            clinicModalOpen: false
        })
    }

    delRolePopupclose() {
        this.setState({
            delRoleModalOpen: false
        })
    }

    delStaffPopupclose() {
        this.setState({
            delStaffModalOpen: false
        })
    }

    modalSave() {
        if (!this.state.findProfileForStaffName) {
            this.setState({
                loadingSaveMyprofile: true
            })
            this.myprofilePopupclose()
            let itv = setInterval(() => {
                app.preloader.visible || app.preloader.show();
            }, 100)
            app.preloader.show()
            AuthGuard.saveCoachProfile({
                photo: this.state.dr_photo_name,
                name: this.state.dr_name,
                mrcNo: this.state.dr_mcr_no,
                codeName: this.state.dr_code_name,
                professionalProfile: this.state.dr_professional_profile,
                specialty: this.state.dr_specialtyId ? JSON.stringify(this.state.dr_specialtyId) : [],
                consultationFee: this.state.dr_consultation_fee,
                signatureUrl: this.state.dr_signature_url,
                is_public: this.state.dr_is_public,
                virtualCard: '',
                timeslotId: this.state.dr_timeslotId,
                timeslot: JSON.stringify(this.state.drTimeSlotsShifts),
                duration: this.state.dr_duration
            }, (isOk, res) => {
                console.log(isOk, res)
                if (isOk) {
                    ShowToast(app, 'Update Success!')

                    this.getDrProfile();
                    setCache('drInfoUpdated', true)

                } else {
                    ShowToast(app, res.message)

                }

                this.setState({
                    loadingSaveMyprofile: false
                })

                app.preloader.hide()
                clearInterval(itv)
            })
        } else {
            let itv = setInterval(() => {
                app.preloader.visible || app.preloader.show();
            }, 100)
            app.preloader.show()
            AuthGuard.saveCoachProfile({
                name: this.state.findProfileForStaffName,
                email: this.state.findProfileForStaffEmail,
                role_type: this.state.findProfileForStaffRoleType,
                is_staff: 1
            }, (isOk, res) => {
                app.preloader.hide()
                clearInterval(itv)
                // console.log(isOk, res)
                let self = this
                self.setState({
                    myprofileModalOpen: false,
                })
                if (isOk) {
                    ShowToast(app, 'Update Success!')
                } else {
                    ShowToast(app, res.message)
                }
            })
        }
    }

    toUploadAvatora() {
        this.setState({
            doCropper: true
        })
    }

    toUploadLogo() {
        this.setState({
            doLogoCropper: true
        })
    }

    toRestPassword() {
        this.tabClick('resetPasswordTab')
    }


    aaa = (e) => {
        this.setState({ dr_name: e.target.value });
        console.log(e.target.value)
    }


    renderMyProfile() {
        return (
            <>
                <Modal
                    tilte=""
                    modalOpened={this.state.myprofileModalOpen}
                    modalPopupClose={this.myprofilePopupclose.bind(this)}
                    descHtml={`
                        Are you sure you want to update?<br/>
                        your profile?
                    `}
                    onSave={this.modalSave.bind(this)}
                    loadingBtn={this.state.loadingSaveMyprofile}
                />
                <Row width="80">
                    <Col>
                        <Row className="bg-gray">
                            <Col width="50">
                                <h3 style={{ margin: '0' }}>Profile Information</h3>
                            </Col>
                            <Col width="45" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button className="settingSaveBtn outline-blue-btn" disabled={this.state.generaVCloading} onClick={this.getDrProfile.bind(this)}> Cancel </Button>&nbsp;&nbsp;&nbsp;
                                <Button className="settingSaveBtn fill-blue-btn" disabled={this.state.generaVCloading} onClick={this.saveDrProfile.bind(this)}> Update </Button>

                            </Col>
                        </Row>
                        {this.coachProfile.userType === 'COACH' ?

                            <Row>
                                <Col>
                                    <form id="settting-myprofile-detail">
                                        {/* <br /> */}
                                        <Col className="bg-gray">
                                            <Row>
                                                <Col className="avatar">
                                                    {this.state.doCropper ?
                                                        <CropperImg
                                                            initImg={this.state.dr_photo_url}
                                                            saveImg={this.saveDrAvatar.bind(this)}
                                                        /> :
                                                        this.state.dr_photo_url ?
                                                            <div className="avatarImg canClick" onClick={this.toUploadAvatora.bind(this)}>
                                                                <img src={this.state.dr_photo_url} alt="" />
                                                            </div>
                                                            :
                                                            <div className="txt-green avatarImg canClick" onClick={this.toUploadAvatora.bind(this)}>
                                                                Upload Image
                                                                {/* <img src={IconDoctor}></img> */}
                                                            </div>
                                                    }
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col width="50">
                                                    Profile Photo:&nbsp;
                                                    <Icon f7="info_circle_fill" className="tooltipIcon" tooltip="
                                            Max Size 5MB <br/>
                                            Prefereed size : 500 x 500 pixel"></Icon>
                                                </Col>
                                                <Col width="50"></Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Name:
                                                </Col>
                                                <Col width="35">
                                                    <input
                                                        type='text'
                                                        name="dr_name"
                                                        className="formInput input-with-value input-invalid"
                                                        placeholder="Enter Name"
                                                        value={this.state.dr_name}
                                                        onChange={(e) => {
                                                            this.setState({ dr_name: e.target.value });
                                                        }}
                                                    // onChange={this.aaa.bind()}
                                                    />
                                                    {this.state.dr_name_error ? <span className="errorMsg">Doctor Name is Required.</span> : null}
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="25" className="formLabel">
                                                    MCR No.:
                                                </Col>
                                                <Col width="35">
                                                    <input
                                                        type='text'
                                                        name="dr_mcr_no"
                                                        className="formInput input-with-value input-invalid"
                                                        placeholder="Enter MCR No."
                                                        value={this.state.dr_mcr_no}
                                                        onChange={(e) => {
                                                            this.setState({ dr_mcr_no: e.target.value });
                                                        }}
                                                    />
                                                    {/* {this.state.dr_mcr_no_error ? <span className="errorMsg">MCR No. is Required.</span> : null} */}
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Login Email:
                                                </Col>
                                                <Col width="35" style={{ marginTop: '10px' }}>
                                                    {this.state.dr_login_email}
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Reset Password:
                                                </Col>
                                                <Col width="35">
                                                    <Button className="form-button outline-blue-btn" onClick={this.toRestPassword.bind(this)}>Reset Password</Button>
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Codename:
                                                </Col>
                                                <Col width="35" className="form-controls">
                                                    <span className="txt-green"> hello </span>
                                                    <input
                                                        type='text'
                                                        name="dr_code_name"
                                                        placeholder="e.g. drjohnsmith, 1 word"
                                                        value={(this.state.dr_code_name).replace(/[^a-zA-Z]/g, '')}
                                                        style={{ paddingLeft: '2.75em' }}
                                                        onChange={(e) => {
                                                            this.setState({ dr_code_name: e.target.value.trim().toLowerCase() }, () => {
                                                                this.getWAQR()
                                                            });
                                                        }}
                                                    />
                                                    <Icon f7="info_circle_fill" tooltip="User will say this to our Whatsapp <br/> bot to start a consult with you. This<br/> should be 1 word with no space."></Icon>
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width='25'></Col>
                                                <Col width='35'>
                                                    {this.state.dr_code_name_error ? <span className="errorMsg" style={{ fontSize: '0.75rem' }}>Codename is Required.</span> : null}
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Professional Profile:
                                                </Col>
                                                <Col width="35" className="formTextarea-count">
                                                    <textarea
                                                        rows="8"
                                                        type='text'
                                                        placeholder='e.g. qualifications, experiences, language'
                                                        onChange={(e) => {
                                                            this.setState({
                                                                dr_professional_profile: e.target.value,
                                                                dr_professional_profile_length: e.target.value.length
                                                            });
                                                        }}
                                                        value={this.state.dr_professional_profile}
                                                    >
                                                    </textarea>
                                                    <div className={`countTextarea ${this.state.dr_professional_profile_length >= 150 ? 'exceed' : ''}`}>
                                                        {/* <span>{this.state.dr_professional_profile_length}</span> / 150 words */}
                                                    </div>
                                                    {this.state.dr_professional_profile_error ? <span className="errorMsg">Professional Profile is Required.</span> : null}
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Specialty:
                                                </Col>
                                                <Col width="35">
                                                    <div>{this.state.specialtyOptions && this.state.specialtyOptions.length
                                                        ? <MultiSelect
                                                            className="multiSelect"
                                                            options={this.state.specialtyOptions}
                                                            selected={this.state.dr_specialtyId}
                                                            onSelectedChanged={selected => this.setState({ dr_specialtyId: selected })}
                                                        />
                                                        : null
                                                    }</div>
                                                    {this.state.dr_specialtyId_error ? <span className="errorMsg">Specialty is Required.</span> : null}
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Consultations Fee $:
                                                </Col>
                                                <Col width="35">
                                                    <input
                                                        type='text'
                                                        name="dr_consultation_fee"
                                                        className="formInput input-with-value input-invalid"
                                                        placeholder="$ 0.00"
                                                        value={this.state.dr_consultation_fee}
                                                        onBlur={this.inputBlur.bind(this, 'dr_consultation_fee', '')}
                                                        onChange={(e) => {
                                                            this.setState({ dr_consultation_fee: e.target.value });
                                                        }}
                                                    />
                                                    {this.state.dr_consultation_fee_error ? <span className="errorMsg">Consultations Fee is Required.</span> : null}
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="25" className="formLabel InputRequired">
                                                    Doctor Signature:
                                                </Col>
                                                <Col width="35" >
                                                    <div className="signature canClick">
                                                        {this.state.openSignaturePad
                                                            ? <SignatureCanvas
                                                                penColor='green'
                                                                backgroundColor='rgb(255,255,255)'
                                                                canvasProps={{ className: 'sigCanvas' }}
                                                                ref={(ref) => {
                                                                    this.canvas = ref;
                                                                }}
                                                            />
                                                            : (this.state.dr_signature_S3Image
                                                                ? <img src={this.state.dr_signature_S3Image} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                                                                : <p className="txt-green" onClick={this.signature.bind(this)}>
                                                                    Tap here and use your touchpad / <br />
                                                                    mouse to draw your signature
                                                                </p>
                                                            )
                                                        }
                                                    </div>
                                                    {this.state.dr_signature_url_error ? <span className="errorMsg">Doctor Signature is Required.</span> : null}
                                                </Col>
                                                <Col width="10">
                                                    {(this.state.openSignaturePad || this.state.dr_signature_S3Image) ? <Button onClick={this.reset.bind(this)} className="outline-blue-btn">Reset</Button> : null}
                                                    <br />
                                                    {this.state.openSignaturePad ? <Button onClick={this.save.bind(this)} className="fill-blue-btn">Save</Button> : null}
                                                </Col>
                                                <Col width="30"></Col>
                                            </Row>
                                            {/* <br /><br />
                                            <Row>
                                                <Col width="50">
                                                    Default duration per teleconsultation session:&nbsp;
                                        <Icon f7="info_circle_fill" className="tooltipIcon" tooltip="Appointment timeslots will be<br/> created based on this duration & <br/>clinic opening hours for telemedicine."></Icon>
                                                </Col>
                                                <Col width="50"></Col>
                                            </Row>
                                            <Row>
                                                <Col width="20" className="search-input input-dropdown-wrap mt20">
                                                    <select
                                                        name="dr_duration"
                                                        value={this.state.dr_duration}
                                                        onChange={(e) => {
                                                            this.setState({ dr_duration: e.target.value });
                                                        }}
                                                    >
                                                        {this.state.durationPerSession.map((item, index) => {
                                                            return (
                                                                <option key={`${index}_dr_time_duration`} value={item}> {item === 60 ? '1 hour' : item + ' min'}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </Col>
                                                <Col width="80" ></Col>
                                            </Row> */}
                                        </Col>
                                        <br /><br />

                                        <Col className="bg-gray">
                                            {/* <br /><br /> */}
                                            <Row>
                                                <Col width="50">
                                                    Default duration per teleconsultation session:&nbsp;
                                                    <Icon f7="info_circle_fill" className="tooltipIcon" tooltip="Appointment timeslots will be<br/> created based on this duration & <br/>clinic opening hours for telemedicine."></Icon>
                                                </Col>
                                                <Col width="50"></Col>
                                            </Row>
                                            <Row style={{ position: 'relative', marginBottom: '50px' }}>
                                                <Col width="30" className="search-input input-dropdown-wrap mt20" style={{ position: 'absolute' }}>
                                                    <select
                                                        name="dr_duration"
                                                        value={this.state.dr_duration}
                                                        onChange={(e) => {
                                                            this.setState({ dr_duration: e.target.value });
                                                        }}
                                                    >
                                                        {this.state.durationPerSession.map((item, index) => {
                                                            return (
                                                                <option key={`${index}_dr_time_duration`} value={item}> {item === 60 ? '1 hour' : item + ' min'}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col width="50">
                                                    Available Time:&nbsp;
                                                    <Icon f7="info_circle_fill" className="tooltipIcon" tooltip="
                                            The last teleconsultation for all <br/>
                                            doctors will end at least 1 hour <br/>
                                            before clinic closing time. This is to <br/>
                                            allow buffer time for our delivery <br/> 
                                            personnel to reach the clinic to <br/>
                                            collect the parcel before closing."></Icon>
                                                </Col>
                                                <Col width="50"></Col>
                                            </Row>
                                            <p style={{ color: 'gray' }}> The last teleconsultation for all doctors should end at least 1 hour before clinic closing time.<br />
                                                This is to allow buffer time for our delivery personnel to reach the clinic to collect the parcel  <br />
                                                before closing.</p>
                                            <br />
                                            {this.state.activeTabType === 'myProfileTab' && this.state.clinicTimeslotLoaded ?
                                                <TimeSlots
                                                    availableTimeSlotsShifts={this.state.clinicTimeSlotsShifts}
                                                    checkAvailableTimeSlotsShifts={true}
                                                    timeSlotsShifts={this.state.drTimeSlotsShifts}
                                                    setTimeSlots={this.setTimeSlotsToDr.bind(this)}
                                                /> : <p>Loading...</p>
                                            }
                                        </Col>
                                        <br />
                                        <br />
                                        <Col className="bg-gray">
                                            <Row>
                                                <Col width="50">
                                                    <div className="subTitle">
                                                        Share your contact with patients
                                                    </div>
                                                </Col>
                                                <Col width="50">
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col width="25">
                                                    Go public on HeartVoice website :
                                                </Col>
                                                <Col width="35">
                                                    {
                                                        this.state.dr_is_public !== '' &&
                                                        <Checkbox
                                                            className="checkBox"
                                                            onChange={(e) => {
                                                                this.setState({ dr_is_public: e.target.checked ? 'Y' : 'N' });
                                                            }}
                                                            defaultChecked={this.state.dr_is_public === 'Y'}
                                                            name="dr_is_public"
                                                        ></Checkbox>
                                                    }
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col width="25">
                                                    Webpage Profile Url :
                                                </Col>
                                                <Col width="25" style={{ width: '75%' }}>
                                                    <div className="txt-green">
                                                        <span>{this.state.dr_name !== '' && this.state.dr_code_name !== '' && this.state.dr_professional_profile !== '' && this.state.dr_specialtyId !== '' && this.state.dr_consultation_fee !== '' ? this.state.webpage_profile_url : '— —'}</span>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <br />
                                            {/* <Row>
                                                <Col width="20" style={{ marginLeft: '25%' }}>
                                                    <CopyToClipboard text={this.state.webpage_profile_url}
                                                        onCopy={() => {
                                                            this.setState({ copied: true })
                                                            setTimeout(() => {
                                                                this.setState({ copied: false })
                                                            }, 3000);
                                                        }}>
                                                        <Button className="form-button outline-blue-btn" >Copy Link</Button>
                                                    </CopyToClipboard>
                                                </Col>
                                                <Col width="30" className="copiedAlert">
                                                    {
                                                        this.state.copied ? <div className="txt-red">
                                                            You have Copied this link
                                                        </div> : null
                                                    }
                                                </Col>
                                            </Row>
                                            <br /> */}
                                            {this.state.doctordetail_url && 
                                                <>
                                                    <Row>
                                                        <Col width="25">
                                                            Clinic Details Url (About Clinic):
                                                        </Col>
                                                        <Col width="25" style={{ width: '75%' }}>
                                                            <div className="txt-green">
                                                                <span>
                                                                    {this.state.doctordetail_url}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </>
                                            }
                                            
                                            {this.state.isHaveHS && 
                                                <>
                                                    <Row>
                                                        <Col width="25">
                                                            Clinic Details Url (Health Program):
                                                        </Col>
                                                        <Col width="25" style={{ width: '75%' }}>
                                                            <div className="txt-green">
                                                                <span>{this.state.doctordetail_url + '/3'}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </>
                                            }
                                            {this.state.isHaveHP && 
                                                <>
                                                    <Row>
                                                        <Col width="25">
                                                            Clinic Details Url (Health Screening):
                                                        </Col>
                                                        <Col width="25" style={{ width: '75%' }}>
                                                            <div className="txt-green">
                                                            <span>{this.state.doctordetail_url + '/4'}</span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br />
                                                </>
                                            }
                                            <Row>
                                                <Col width="25">
                                                    Virtual Card :
                                                </Col>
                                                <Col width="35">
                                                    {this.canDownloadCard()
                                                        ? <Button className={'form-button fill-pink-btn'} onClick={this.downloadImg.bind(this)}>Download Image</Button>
                                                        : <Button className={'form-button fill-gray-btn'}>Download Image</Button>
                                                    }
                                                </Col>
                                                <Col width="40">
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col width="25">
                                                    Coupon Code :
                                                </Col>
                                                <Col width="35">
                                                    {this.state.dr_code_name_set
                                                        ? <span> hello{this.state.dr_code_name}</span>
                                                        : <span style={{ color: 'gray' }}>This will be the same as your codename.</span>
                                                    }
                                                </Col>
                                                <Col width="40"></Col>
                                            </Row>
                                        </Col>
                                        {/* <div>
                                    <input value={this.state.canvas}/>
                                </div> */}
                                    </form>
                                </Col>
                            </Row>
                            : <Row className='mt20 staff-view-page bg-gray'>
                                <form id="settting-myprofile-detail">
                                    <br />
                                    <Row>
                                        <Col width="25" className="formLabel InputRequired">
                                            Name:
                                        </Col>
                                        <Col width="35">
                                            <input
                                                type='text'
                                                name="findProfileForStaffName"
                                                className="formInput input-with-value input-invalid"
                                                placeholder="Enter Name"
                                                value={this.state.findProfileForStaffName}
                                                onChange={(e) => {
                                                    this.setState({ findProfileForStaffName: e.target.value });
                                                }}
                                            />
                                            {this.state.findProfileForStaffName_error ? <span className="errorMsg">Doctor Name is Required.</span> : null}
                                        </Col>
                                        <Col width="40"></Col>
                                    </Row>
                                    <Row>
                                        <Col width="25" className="formLabel InputRequired">
                                            Role:
                                        </Col>
                                        <Col width="35" style={{ marginTop: '10px' }}>
                                            {/* {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() || AuthGuard.CanViewHistoryVideoCallList() ?
                                                <span>staff</span> : <span>doctor </span>} */}
                                            {this.state.findProfileForStaffRoleName}

                                        </Col>
                                        <Col width="40"></Col>
                                    </Row>
                                    <Row>
                                        <Col width="25" className="formLabel InputRequired">
                                            Login Email:
                                        </Col>
                                        <Col width="35" style={{ marginTop: '10px' }}>
                                            {this.state.findProfileForStaffEmail}
                                        </Col>
                                        <Col width="40"></Col>
                                    </Row>
                                    <Row>
                                        <Col width="25" className="formLabel InputRequired">
                                            Reset Password:
                                        </Col>
                                        <Col width="35">
                                            <Button className="form-button outline-blue-btn" onClick={this.toRestPassword.bind(this)}>Reset Password</Button>
                                        </Col>
                                        <Col width="40"></Col>
                                    </Row>


                                </form>
                            </Row>}

                    </Col>
                </Row>
            </>
        )
    }

    cancelAllInput() {
        let self = this
        let { countryList } = this.state
        AuthGuard.getClinicProfile({}, (isOk, res) => {
            if (isOk) {
                let _service_country = []
                if (res.countries_of_practice && res.countries_of_practice.length > 0) {
                    res.countries_of_practice.map((item, index) => {
                        let _code = item.country_code
                        let getSelectCountty = countryList.find(iitem => iitem.code === _code)
                        if (getSelectCountty.id) {
                            _service_country.push(getSelectCountty.id)
                        }
                    })
                }

                self.setState({
                    clinic_id: res.id ? res.id : '',
                    clinic_address: res.clinic_address ? res.clinic_address : '',
                    clinic_contact_no: res.clinic_contact_no ? res.clinic_contact_no : '',
                    clinic_email: res.clinic_email ? res.clinic_email : '',
                    clinic_emergency_no: res.clinic_emergency_no ? res.clinic_emergency_no : '',
                    clinic_name: res.clinic_name ? res.clinic_name : '',
                    delivery_price: res.delivery_price ? res.delivery_price : '',
                    delivery_time: res.delivery_time ? res.delivery_time : '',
                    introduction: res.introduction ? res.introduction : '',
                    introduction_length: res.introduction ? res.introduction.length : 0,
                    postal_code: res.postal_code ? res.postal_code : '',
                    unit_no: res.unit_no ? res.unit_no : '',
                    countries_of_practice: res.countries_of_practice ? res.countries_of_practice : '',
                    service_country: _service_country ? _service_country : '',
                    code_word: res.code_word ? res.code_word : '',
                    country: res.country ? res.country : '',
                    country_code: res.country_code ? res.country_code : '',
                    city_code: res.city_code ? res.city_code : '',
                    province_code: res.province_code ? res.province_code : '',
                    clinic_dialing_code: res.clinic_dialing_code ? res.clinic_dialing_code : '',
                    clinic_logo: res.clinic_logo ? res.clinic_logo : '',
                    clinic_logo_name: res.clinic_logo ? res.clinic_logo : '',
                    clinic_logo_url: res.clinic_logo_url ? res.clinic_logo_url : '',
                }, () => {
                    this.refs.countryProvinceCity.updateSelectList()
                })
            }

            self.fillFormData();
        })
    }

    renderClinicDetails() {
        return (
            <>
                <Modal
                    tilte="Alert"
                    modalOpened={this.state.clinicModalOpen}
                    modalPopupClose={this.clinicPopupclose.bind(this)}
                    descHtml={`
                            Are you sure you want to update<br/>
                            your profile?
                        `}
                    onSave={this.modalSaveClinic.bind(this)}
                    loadingBtn={this.state.loadingClinic}
                />

                <Row width="80">
                    <Col>
                        <Row className="bg-gray">
                            <Col width="50">
                                <h3 style={{ margin: '0' }}>Company Profile</h3>
                            </Col>
                            <Col width="45" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button className="settingSaveBtn outline-blue-btn" onClick={this.cancelAllInput.bind(this)}> Cancel </Button>&nbsp;&nbsp;&nbsp;

                                <Button className="settingSaveBtn fill-blue-btn" disabled={this.state.generaVCloading} onClick={this.saveClinicDetail.bind(this)}> Update </Button>

                            </Col>
                        </Row>
                        <Row className='mt20'>
                            <Col>
                                <form id="settting-clinic-detail">
                                    <Col className="bg-gray">

                                        <Row>
                                            <Col width="25" className="formLabel">
                                                Company Logo:*&nbsp;
                                                <Icon f7="info_circle_fill" className="tooltipIcon" tooltip="
                                                Preferred Dimension: 500px by 500px <br />
                                                File size: maximum 5 MB <br/>
                                                File type: png, jpg"></Icon>

                                            </Col>

                                            <Col width="50">
                                                <Col className="clinic_logo">
                                                    {this.state.doLogoCropper ?
                                                        <CropperImg
                                                            initImg={this.state.clinic_logo_url}
                                                            saveImg={this.saveClinicLogo.bind(this)}
                                                        /> :
                                                        this.state.clinic_logo_url ?
                                                            <div className="clinic_logoImg canClick" onClick={this.toUploadLogo.bind(this)}>
                                                                <img src={this.state.clinic_logo_url} alt="" />
                                                            </div>
                                                            :
                                                            <div className="txt-green clinic_logoImg canClick" onClick={this.toUploadLogo.bind(this)}>
                                                                Company Logo
                                                            </div>
                                                    }
                                                    {this.state.clinic_logo_url_error ? <span className="errorMsg">Company Logo is Required.</span> : null}
                                                </Col>
                                            </Col>
                                            <Col width="25"></Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col width="25" className="formLabel InputRequired">
                                                Company Name:
                                            </Col>
                                            <Col width="25" className="formLabel">
                                                {this.state.clinic_name}
                                                {/* <input
                                                type='text'
                                                name="clinic_name"
                                                className="formInput"
                                                placeholder="Clinic Name"
                                                value={this.state.clinic_name}
                                                required
                                                validate={this.state.clinic_name}
                                                onChange={(e) => {
                                                    this.setState({ clinic_name: e.target.value });
                                                }}
                                            /> */}
                                                {/* {this.state.clinic_name_error ? <span className="errorMsg">Clinic Name is Required.</span> : null} */}
                                            </Col>
                                            <Col width="50"></Col>
                                        </Row>
                                        <Row>
                                            <Col width="25" className="formLabel InputRequired">
                                                Company Email:
                                            </Col>
                                            <Col width="25">
                                                <input
                                                    type='email'
                                                    name="clinic_email"
                                                    className="formInput input-with-value input-invalid"
                                                    placeholder="email@clinic.com"
                                                    value={this.state.clinic_email}
                                                    onBlur={this.inputBlur.bind(this, 'clinic_email', 'email')}
                                                    onChange={(e) => {
                                                        this.setState({ clinic_email: e.target.value });
                                                    }}
                                                />
                                                {this.state.clinic_email_error ? <span className="errorMsg">Company Email is Required.</span> : null}
                                            </Col>
                                            <Col width="50"></Col>
                                        </Row>
                                        <Row>
                                            <Col width="25" className="formLabel InputRequired">
                                                Contact No.:
                                            </Col>
                                            <Col width="10" >
                                                <input
                                                    type="text"
                                                    className="formInput"
                                                    name="clinic_dialing_code"
                                                    placeholder="e.g. 65"
                                                    value={this.state.clinic_dialing_code}
                                                    onBlur={this.inputBlur.bind(this, 'clinic_dialing_code', 'number')}
                                                    onChange={(e) => {
                                                        this.setState({ clinic_dialing_code: e.target.value });
                                                    }}
                                                />
                                                {this.state.clinic_dialing_code_error ? <span className="errorMsg">Dialing Code. is Required.</span> : null}
                                            </Col>
                                            <Col width="25">
                                                <input
                                                    type='text'
                                                    className="formInput"
                                                    name="clinic_contact_no"
                                                    placeholder="e.g. 6123 4567"
                                                    value={this.state.clinic_contact_no}
                                                    onBlur={this.inputBlur.bind(this, 'clinic_contact_no', 'number')}
                                                    onChange={(e) => {
                                                        this.setState({ clinic_contact_no: e.target.value });
                                                    }}
                                                />
                                                {this.state.clinic_contact_no_error ? <span className="errorMsg">Contact No. is Required.</span> : null}
                                            </Col>
                                            <Col width="40"></Col>
                                        </Row>
                                        {/* <Row>
                                        <Col width="25" className="formLabel">
                                            Emergency No.:
                                            </Col>
                                        <Col width="25">
                                            <input
                                                type='text'
                                                name="clinic_emergency_no"
                                                className="formInput"
                                                placeholder="e.g. 6123 4567"
                                                value={this.state.clinic_emergency_no}
                                                // onBlur={this.inputBlur.bind(this, 'clinic_emergency_no', 'number')}
                                                onChange={(e) => {
                                                    this.setState({ clinic_emergency_no: e.target.value });
                                                }}
                                            />
                                        </Col>
                                        <Col width="50"></Col>
                                    </Row> */}
                                        <Row>
                                            <Col width="25" className="formLabel InputRequired">
                                                Company Address:
                                            </Col>
                                            <Col width="50">
                                                <input
                                                    type='text'
                                                    name="clinic_address"
                                                    className="formInput"
                                                    value={this.state.clinic_address}
                                                    placeholder="e.g. Bedok Street 12, Block 6,#01-11"
                                                    onChange={(e) => {
                                                        this.setState({ clinic_address: e.target.value });
                                                    }}
                                                />
                                                {this.state.clinic_address_error ? <span className="errorMsg">Company Address is Required.</span> : null}
                                            </Col>
                                            <Col width="25">
                                                <input
                                                    type='text'
                                                    name="unit_no"
                                                    className="formInput"
                                                    placeholder="Unit No. (e.g. #23-34)"
                                                    onChange={(e) => {
                                                        this.setState({ unit_no: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col width="25">

                                            </Col>
                                            <Col width="50">
                                                <CountryProvinceCity
                                                    ref='countryProvinceCity'
                                                    selectedCountryCode={this.state.country_code}
                                                    selectedProvinceCode={this.state.province_code}
                                                    selectedCityCode={this.state.city_code}
                                                />
                                                {this.state.country_code_error ? <span className="errorMsg">Country is Required.</span> : null}
                                                {this.state.province_code_error ? <span className="errorMsg">Province is Required.</span> : null}
                                                {this.state.city_code_error ? <span className="errorMsg">City is Required.</span> : null}
                                            </Col>
                                            <Col width="25">
                                                <input
                                                    type='text'
                                                    name="postal_code"
                                                    className="formInput"
                                                    placeholder="Postal Code (e.g. 6123456)"
                                                    // onBlur={this.inputBlur.bind(this, 'postal_code', 'number')}
                                                    onChange={(e) => {
                                                        this.setState({ postal_code: e.target.value });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col width="25" className="formLabel InputRequired">
                                                Company Codeword:
                                            </Col>
                                            <Col width="35" className="form-controls">
                                                <span className="txt-green"> hello </span>
                                                <input
                                                    type='text'
                                                    name="code_word"
                                                    className="sessionId"
                                                    placeholder="e.g. WellneessClinic, 1 word"
                                                    value={(this.state.code_word).replace(/[^a-zA-Z]/g, '')}
                                                    onChange={(e) => {
                                                        this.setState({ code_word: (e.target.value.toLowerCase()) });
                                                    }}
                                                />
                                                <Icon f7="info_circle_fill" tooltip="User can say this to our Whatsapp<br/>bot to start a consult with any<br/> available doctor in the clinic."></Icon>
                                            </Col>
                                            <Col width="40">
                                                {this.state.code_word_error ? <span className="form-error errorMsg">Company Codeword is Required.</span> : null}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col width="25">
                                                About:
                                            </Col>
                                            <Col width="35" className="formTextarea-count">
                                                <textarea
                                                    rows="8"
                                                    type='text'
                                                    placeholder="e.g. company backgroud etc."
                                                    name='introduction'
                                                    value={this.state.introduction}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            introduction: e.target.value,
                                                            introduction_length: e.target.value.length
                                                        });
                                                    }}
                                                >
                                                </textarea>
                                                <div className={`countTextarea ${this.state.introduction_length >= 150 ? 'exceed' : ''}`}>
                                                    {/* <span>{this.state.introduction_length}</span> / 150 words */}
                                                </div>
                                                {this.state.introduction_error ? (this.state.introduction.length > 150 ? <span className="errorMsg">String length cannot exceed 150.</span> : <span className="errorMsg">This is Required.</span>) : null}

                                            </Col>
                                            <Col width="40"></Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col width="25" className="formLabel InputRequired">
                                                Countries of Service:
                                            </Col>
                                            <Col width="35">
                                                <MultiSelect
                                                    className="multiSelect"
                                                    options={this.state.countryListOptions}
                                                    selected={this.state.service_country}
                                                    onSelectedChanged={selected => this.setState({ service_country: selected })}
                                                />
                                            </Col>
                                            <Col width="40" className="formTooltipIcon">
                                                <Icon className="tooltipIcon" f7="info_circle_fill" tooltip="Do you have patients from other <br/> countries? You can select multiple <br/> countries."></Icon>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <br />
                                    <br />
                                    <br />

                                    <Col className="bg-gray">
                                        <Row>
                                            <Col>
                                                <h3>Delivery: </h3>
                                                <i style={{ fontWeight: '400' }}>
                                                    If you would like to use your own delivery service, we will show <br />
                                                    users this option instead of HeartVoice delivery.
                                                </i>
                                            </Col>
                                        </Row>
                                        <br />
                                        <Row>
                                            <Col width="25" className="formLabel">
                                                Delivery Time (hrs):
                                            </Col>
                                            <Col width="35" className="form-controls">
                                                <input
                                                    type='text'
                                                    name="delivery_time"
                                                    className="fullInput"
                                                    placeholder="e.g. 6hrs"
                                                    value={this.state.delivery_time}
                                                    onBlur={this.inputBlur.bind(this, 'delivery_time', 'decimal')}
                                                    onChange={(e) => {
                                                        this.setState({ delivery_time: e.target.value });
                                                    }}
                                                />
                                                <Icon f7="info_circle_fill" tooltip="How many hours does it usually take <br/>to reach patients?"></Icon>
                                            </Col>
                                            <Col width="40">

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col width="25" className="formLabel">
                                                Delivery Price ($):
                                            </Col>
                                            <Col width="35" className="form-controls">
                                                <input
                                                    type='text'
                                                    name="delivery_price"
                                                    className="fullInput"
                                                    placeholder="e.g. $15.00"
                                                    value={this.state.delivery_price}
                                                    onBlur={this.inputBlur.bind(this, 'delivery_price', 'money')}
                                                    onChange={(e) => {
                                                        this.setState({ delivery_price: e.target.value });
                                                    }}
                                                />
                                                <Icon f7="info_circle_fill" tooltip="How much are you charging for each <br/> delivery?"></Icon>
                                            </Col>
                                            <Col width="40">

                                            </Col>
                                        </Row>
                                    </Col>
                                </form>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </>
        )
    }

    renderAppointment() {
        return (
            <>
                <Popup
                    className="DisconnectGoogleCalendar-popup"
                    swipeToClose
                    swipeHandler=".swipe-handler"
                >
                    <Page>
                        <div className="popHeader">
                            <div>Are you sure you want to <br />disconnect Google Calendar?</div>
                        </div>
                        <div className="footerCallPop">
                            <Row>
                                <Col>
                                    <Button fill raised onClick={this.calendarDisconnect.bind(this)} className="fill-blue-btn" >YES</Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button outline raised onClick={() => { app.popup.close('.DisconnectGoogleCalendar-popup') }} className="outline-blue-btn">NO</Button>
                                </Col>
                            </Row>
                        </div>
                    </Page>
                </Popup>
                <Row>
                    <Col>
                        <form id="form-save-appointment">
                            <Row style={{ display: 'none' }}>
                                <Col width='30'>
                                    <div>Duration per Session</div>
                                    <div className="search-input input-dropdown-wrap mt20">
                                        <select
                                            name="duration"
                                            value={this.state.clinicDuration}
                                            onChange={(e) => {
                                                this.setState({ clinicDuration: e.target.value });
                                            }}
                                        >
                                            {this.state.durationPerSession.map((item, index) => {
                                                return (
                                                    <option key={`${index}_time_duration`} value={item}> {item === 60 ? '1 hour' : item + ' min'}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <div className="bg-gray">
                                <span className='multipleSelect-title'>Clinic opening hours for telemedicine & medication pick up:</span>
                                <Icon f7="info_circle_fill" className="tooltipIcon" tooltip="
                                    <b>Shift Time</b> <br/> 
                                    When would you like to conduct <br/> 
                                    teleconsultation? <br/><br/> 
                                    <b> Shift End Time </b><br/>
                                    The shift should end at least 1 hour  <br/>
                                    before the clinic closes. This is also  <br/> 
                                    the last order time for medication.   <br/> 
                                    This is to allow buffer time for our <br/> 
                                    delivery personnel to reach the clinic  <br/>
                                    to collect the parcel before closing.  <br/>  <br/> 
                                    If a delivery is placed and clinic is  <br/> 
                                    closed for the day,"></Icon>
                                <p style={{ color: 'gray', fontSize: '1em' }}>Each shift should end at least 1 hour before the clinic closes. This is also the last order time for <br />
                                    medication.</p>
                            </div>
                            <br />
                            <Col className="bg-gray">
                                <Row className="mt20">
                                    <Col width="20">
                                        <div className="workingDays">
                                            <div className="cell_title">Working Days:</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            this.state.activeTabType === 'appointmentTab' && this.state.clinicTimeslotLoaded ?
                                                <TimeSlots
                                                    timeSlotsShifts={this.state.clinicTimeSlotsShifts}
                                                    setTimeSlots={this.setTimeSlotsToClinic.bind(this)}
                                                    duration={this.state.duration}
                                                /> : <p>Loading...</p>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                            <div className="gray-block">
                                <Row>
                                    <Col>
                                        <div className="cell_title">Sync to Google Calendar</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="syncToGoogleCalendar">
                                            <div className="icon_calendar">
                                                <img src={IconCalendar} alt="" />
                                            </div>
                                            <div className="txt_GoogleCalender">
                                                Google Calender
                                            </div>
                                            <div className="signin_GoogleCalender">
                                                {this.state.calendarConfig ? (
                                                    this.state.calendarConfig.sync_on == 1
                                                        ? <Button onClick={() => { app.popup.open('.DisconnectGoogleCalendar-popup') }} className="fill-blue-btn">Disconnect</Button>
                                                        : <Button onClick={this.calendarSignIn.bind(this)} className="fill-blue-btn">Sign in</Button>
                                                ) : <Button className="fill-blue-btn" disabled>Loading</Button>}
                                                {this.state.calendarConfig && this.state.calendarConfig.sync_on == 1 && <Button onClick={this.calendarEditSettings.bind(this)} className="outline-blue-btn">Edit Settings</Button>}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="gray-block">
                                <Row>
                                    <Col>
                                        <div className="cell_title">Appointment Status</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col width="40">
                                        <List
                                            noHairlines
                                            noHairlinesBetween
                                            className="normalFormCheckBox"
                                        >
                                            {this.state.auto_confirm_appointment === ''
                                                ? <ListItem checkbox disabled title="Loading"></ListItem>
                                                : <ListItem checkbox value={this.state.auto_confirm_appointment ? 1 : 0}
                                                    title="Auto confirm appointments" name="auto_confirm_appointment"
                                                    defaultChecked={Boolean(this.state.auto_confirm_appointment)}
                                                    disabled={this.coachProfile.clinic_info_id === ''}
                                                    onClick={this.onAutoConfirmAppointmentChange.bind(this)}
                                                ></ListItem>
                                            }
                                        </List>
                                    </Col>
                                </Row>
                            </div>
                        </form>
                    </Col>
                </Row>
            </>
        )
    }

    onAutoConfirmAppointmentChange() {
        let auto_confirm_appointment = !this.state.auto_confirm_appointment;
        app.preloader.show()
        AuthGuard.clinicSettingEdit({
            auto_confirm_appointment: auto_confirm_appointment ? 'Y' : 'N',
            id: this.coachProfile.clinic_info_id,
        }, (ok, res) => {
            app.preloader.hide()
            if (!ok) {
                return ShowToast(app, res)
            }
            this.setState({
                auto_confirm_appointment: auto_confirm_appointment
            })
        })
    }

    onSearchChange = (e) => {
        this.setState({
            accountIDorName: e.target.value
        })
    }

    btnSearch = () => {
        this.getStaffList(this.state.accountIDorName);
    }

    addRolesBtn = (id, role_accounts, e) => {
        // console.log(id, role_accounts)
        app.views.main.router.navigate(ROUTES.addAccountRole, {
            props: { role_id: id, role_accounts: role_accounts },
        });
    }

    delRoleSave = () => {

        this.delRolePopupclose();

        let itv = setInterval(() => {
            app.preloader.visible || app.preloader.show();
        }, 100)
        app.preloader.show()
        AuthGuard.delClinicRole({
            role_name: this.state.delRoleItem.name,
            role_id: this.state.delRoleItem.id
        }, (isOk, res) => {
            if (isOk) {
                app.preloader.hide()
                clearInterval(itv)
                app.popover.close('.managePrompt2-popup')
                setTimeout(() => {
                    this.getRoleListAndStaffList();
                }, 200);
            }
            app.preloader.hide()
        })


    }


    delRolesBtn = (_id, _name, _accounts, e) => {
        this.setState({
            delRoleItem: { id: _id, name: _name, accounts: _accounts },
        }, () => {
            if (typeof _accounts === 'number' && !isNaN(_accounts)) {
                if (_accounts !== 0) {
                    app.preloader.show()

                    app.popover.open('.managePrompt-popup')
                }
            } else {
                if (_accounts == false) {

                    app.popover.open('.managePrompt2-popup')
                }
            }
            app.preloader.hide()
        })

        // console.log(_id, _name, _accounts)
        // console.log(this.state.delRoleItem.accounts)
    }

    addStaffBtn = (id, e) => {
        console.log(id)
        app.views.main.router.navigate(ROUTES.addAccount, {
            props: { user_profile_id: id },
        });
    }

    delStaffSave() {
        app.preloader.show()
        this.delStaffPopupclose()
        AuthGuard.delStaff({
            user_profile_id: this.state.delStaffId
        }, (isOk, msg) => {
            if (isOk) {
                ShowToast(app, msg)
                setTimeout(() => {
                    this.getStaffList()
                }, 200);
                setTimeout(() => {
                    this.getRoleListAndStaffList();
                }, 200);
            } else {
                ShowToast(app, msg)
            }

            app.preloader.hide()
        })
    }

    delStaffBtn = (id, e) => {
        this.setState({
            delStaffId: id,
            delStaffModalOpen: true
        })
    }

    addProviderBtn = (id, e) => {
        app.views.main.router.navigate(ROUTES.addProvider, {
            props: { user_profile_id: id },
        });

    }

    onPageBeforeRemove = () => {
        // console.log('setting onPageBeforeRemove');
    }

    onPageReinit = () => {
        setTimeout(() => {
            this.getRoleListAndStaffList();
            // 3 通过 this.child 调用子组件的方法
            this.child.childFunction();
        }, 200);
    }

    tabClick = (activeTabType) => {
        this.setState({
            activeTabType: activeTabType
        }, () => {
            let force = ''
            if (AuthGuard.isDoctor() && !AuthGuard.isDoctorProfileComplete()) {
                force = 'myProfileTab'
            }
            // if (!AuthGuard.isClinicProfileComplete()) {
            //     force = 'clinicDetailsTab'
            // }
            if (force && this.state.activeTabType != force) {
                this.setState({
                    activeTabType: force
                })
                app.popover.open('.fillInAllCompulsory-popup');
            }
        })
    }


    clearActivePopup = () => {
        this.setState({
            activePopup: ''
        })

        app.popover.close('.fillInAllCompulsory-popup')

    }

    resetPassword = () => {
        var formData = app.form.convertToData('#reset-password-form')
        if (formData.newPassword === formData.oldPassword) {
            ShowToast(app, 'New password should be different from old password');
            app.form.fillFromData('#reset-password-form', {
                // oldPassword:'',
                newPassword: '',
                confirmPassword: '',
            });
            return;
        }
        if (formData.newPassword !== formData.confirmPassword) {
            ShowToast(app, 'The new password does not match, please check and try again');
            app.form.fillFromData('#reset-password-form', {
                // oldPassword:'',
                newPassword: '',
                confirmPassword: '',
            });
            return;
        }

        var noError = VerificationFields(this, app,
            [
                'oldPassword',
                'newPassword',
                'confirmPassword',
            ]
        );

        if (noError) {
            app.preloader.show();
            // console.log(formData);
            let oldPassword = encryptRequest(formData.oldPassword);
            let newPassword = encryptRequest(formData.newPassword);
            let confirmPassword = encryptRequest(formData.confirmPassword);

            AuthGuard.resetPw({ oldPassword: oldPassword, confirmPassword: confirmPassword, newPassword: newPassword }, (isOk, res) => {
                // ShowToast(app, res);
                if (isOk) {
                    // ShowToast(app, 'Success! Password has been changed');
                    // AuthGuard.setLogout();
                    app.views.main.router.navigate(ROUTES.setting, {
                    });
                }
                app.preloader.hide();
            });
        } else {
            ShowToast(app, 'Invalid value!');
        }

    }

    clearManagePopup() {
        app.popover.close('.managePrompt-popup')
    }

    clearManage2Popup() {
        app.popover.close('.managePrompt2-popup')
    }

    natificationChecked(event) {
        const target = event.target;
        const _checked = target.type === 'checkbox' ? target.checked : target.value;
        console.log(target.value, _checked)

    }

    saveLogoutIfInactive = (id, e) => {
        let appointments_mobile = document.querySelector('.appointments_mobile').childNodes[0].childNodes[0].checked
        let delivery_mobile = document.querySelector('.delivery_mobile').childNodes[0].childNodes[0].checked

        let notification_SMS = document.querySelector('.notification_SMS').childNodes[0].childNodes[0]
        let settingReceiveByAppointments = document.querySelector('.settingReceiveByAppointments').childNodes[0].childNodes[0].checked
        let settingReceiveDelivery = document.querySelector('.settingReceiveDelivery').childNodes[0].childNodes[0].checked
        console.log(settingReceiveByAppointments, settingReceiveDelivery)

        if (this.state.is_choose == 'Y') {

            if (appointments_mobile == true) {

                let _appoin = { "notification_type": "appointments_updates", "type": "MOBILE" }
                this.state.notification_types_Data.push(_appoin)
            }

            if (delivery_mobile == true) {
                let _delivery = { "notification_type": "delivery", "type": "MOBILE" }
                this.state.notification_types_Data.push(_delivery)
            }
        }

        if (settingReceiveByAppointments == true) {
            let _settingReceiveByAppointments = { "notification_type": "appointments_updates", "type": "EMAIL" }
            this.state.notification_types_Data.push(_settingReceiveByAppointments)
        }

        if (settingReceiveDelivery == true) {
            let _settingReceiveDelivery = { "notification_type": "delivery", "type": "EMAIL" }
            this.state.notification_types_Data.push(_settingReceiveDelivery)
        }

        if (this.state.send_otp_type == 'Email') {
            sessionStorage.removeItem('MonitoringColumns');
            sessionStorage.removeItem('SMSVerify_dialing_code');
            sessionStorage.removeItem('SMSVerify');
            sessionStorage.removeItem('SMSVerify_mobile');
            sessionStorage.removeItem('NotificationsSMSVerify');
            sessionStorage.removeItem('NotVerify_dialing_code');
            sessionStorage.removeItem('NotVerify_mobile');
        }
        // console.log(this.state.notification_types_Data)

        if (this.state.send_otp_type == 'Mobile') {

            if (this.state.dialing_code == '' || this.state.send_otp_Mobile == '') {
                ShowToast(app, 'Please fill in the OTP mobile phone number!');
            }
            if (this.state.dialing_code !== '' || this.state.send_otp_Mobile !== '') {
                if (this.state.verify_flag == false) {
                    ShowToast(app, 'Please verify the mobile phone number!');
                } else if (this.state.verify_flag == true) {
                    if (this.state.is_choose == 'Y') {
                        if (this.state.receive_by_mobile !== '' && this.state.send_Notifications_Mobile !== '') {
                            if (this.state.verify_flag2 == true) {
                                this.saveGeneral()
                            } else {
                                ShowToast(app, 'Please verify the mobile number receiving notifications!');
                            }

                        } else if (this.state.receive_by_mobile == '' || this.state.send_Notifications_Mobile == '') {
                            ShowToast(app, ' Please fill in the mobile number to receive notifications!')
                        }
                    } else {
                        if (this.state.receive_by_mobile !== '' && this.state.send_Notifications_Mobile !== '') {
                            if (this.state.verify_flag2 == true) {

                                if (appointments_mobile == true || delivery_mobile == true) {
                                    ShowToast(app, 'Please select the mobile phone number to receive notifications!');
                                } else {
                                    this.saveGeneral()
                                }
                            } else {

                                // ShowToast(app, 'Please verify the mobile number receiving notifications!');
                                this.saveGeneral()

                            }

                        } else if (this.state.receive_by_mobile == '' || this.state.send_Notifications_Mobile == '') {
                            if (this.state.verify_flag2 == false) {
                                this.saveGeneral()
                                sessionStorage.removeItem('NotificationsSMSVerify');
                                sessionStorage.removeItem('NotVerify_dialing_code');
                                sessionStorage.removeItem('NotVerify_mobile');
                            } else {
                                ShowToast(app, 'Please select the mobile phone number to receive notifications!');
                            }
                        }
                    }
                }
            }

        } else if (this.state.send_otp_type == 'Email') {

            if (this.state.is_choose == 'Y') {
                if (this.state.receive_by_mobile !== '' && this.state.send_Notifications_Mobile !== '') {
                    if (this.state.verify_flag2 == true) {
                        this.saveGeneral()
                    } else {
                        ShowToast(app, 'Please verify the mobile number receiving notifications!');
                    }

                } else if (this.state.receive_by_mobile == '' || this.state.send_Notifications_Mobile == '') {
                    ShowToast(app, ' Please fill in the mobile number to receive notifications!')
                }
            } else {

                this.setState({
                    receive_by_mobile: '',
                    send_Notifications_Mobile: '',
                    verify_flag2: false
                })

                if (this.state.receive_by_mobile !== '' && this.state.send_Notifications_Mobile !== '') {
                    if (this.state.verify_flag2 == true) {
                        if (appointments_mobile == true || delivery_mobile == true) {
                            ShowToast(app, 'Please select the mobile phone number to receive notifications!');
                        } else {
                            this.saveGeneral()
                        }
                    } else {
                        // ShowToast(app, 'Please verify the mobile number receiving notifications!');
                        this.saveGeneral()
                    }

                } else if (this.state.receive_by_mobile == '' || this.state.send_Notifications_Mobile == '') {
                    if (this.state.verify_flag2 == false) {
                        this.saveGeneral()
                        sessionStorage.removeItem('NotificationsSMSVerify');
                        sessionStorage.removeItem('NotVerify_dialing_code');
                        sessionStorage.removeItem('NotVerify_mobile');
                    } else {
                        ShowToast(app, 'Please select the mobile phone number to receive notifications!');
                    }
                }
            }

        }


    }

    saveGeneral() {

        // console.log(this.state.email_datas)

        let emailDatas = []
        let _emailDatas = []

        if (this.state.email_datas.length > 0) {
            emailDatas = this.state.email_datas.map((item, index) => {
                if (item.type == 'EMAIL') {
                    // console.log(item, index)
                    return item.email
                }
            })

            _emailDatas = emailDatas.filter(function (s) {
                return s && s.trim();
            });
        }

        // console.log(_emailDatas)

        let ss = _emailDatas.join(",") + ",";
        for (var i = 0; i < _emailDatas.length; i++) {
            if (ss.replace(_emailDatas[i] + ",", "").indexOf(_emailDatas[i] + ",") > -1) {
                // console.log("数组中有重复元素：" + _emailDatas[i]);
                ShowToast(app, 'There are duplicate emails, please modify the email!')
                this.setState({
                    email_duplicate_flag: false
                })
            } else {
                this.setState({
                    email_duplicate_flag: true
                })
            }
        }

        console.log(this.state.email_duplicate_flag)

        if (this.state.email_duplicate_flag == true) {
            app.preloader.show()
            AuthGuard.logoutTimeSetting({
                attribut_code: 'merchant_setting_logout_time',
                value: this.state.getLogouTimes,
                one_time_password: this.state.send_otp_type,
                email_datas: JSON.stringify(_emailDatas),
                notification_type: JSON.stringify(this.state.notification_types_Data),
                dialing_code: this.state.is_choose == 'Y' ? this.state.receive_by_mobile : '',
                mobile: this.state.is_choose == 'Y' ? this.state.send_Notifications_Mobile : '',
                is_choose: this.state.is_choose,
            }, (isOk, res) => {
                app.preloader.hide();
                if (isOk) {
                    ShowToast(app, res.message);
                    this.setState({
                        notification_types_Data: [{ "notification_type": "join_program", "type": "MOBILE" },
                        { "notification_type": "join_program", "type": "EMAIL" }]
                    }, () => {
                        this.getGeneralInfo()
                    })
                } else {
                    if (res.status_code !== 11015) {
                        ShowToast(app, res.message);
                    }
                }
            })
        }


    }

    receiveByType(type) {
        if (type == 'Email') {
            this.setState({
                send_otp_type: 'Email'
            }, () => {
                // console.log(this.state.send_otp_type)
            })
        }
        if (type == 'Mobile') {

            if (this.state.dialing_code == '') {
                this.setState({
                    dialing_code: '65'
                })
            }

            this.setState({
                send_otp_type: 'Mobile',
            }, () => {
                // console.log(this.state.send_otp_type)
            })
        }
        // console.log(type)
    }


    addEmailData() {

        let _list = this.state.email_datas

        console.log(_list)
        _list.push({
            dialing_code: "",
            email: "",
            id: "",
            mobile: "",
            type: "EMAIL",
        })

        this.setState({
            email_datas: _list
        })
    }

    userEmail = (value, index) => {
        // console.log(value, index)
        let _email_datas = this.state.email_datas
        _email_datas[index].email = value

        this.setState({
            email_datas: _email_datas
        }, () => {
            // console.log(this.state.email_datas)
        })
    }

    deleteEmailDataList(uid, index) {

        let _delList = this.state.email_datas

        // console.log(_delList[index])

        if (uid !== '') {
            AuthGuard.notificationDeleteById({
                id: uid
            }, (isOk, res) => {
                if (isOk) {
                    this.getGeneralInfo()
                }
            })
        } else {
            _delList.splice(index, 1)

            this.setState({
                onSemail_datasubsidy: _delList
            })
        }

    }

    sendSmsOtpDirect(type) {

        if (this.state.receive_by_mobile == '') {
            this.setState({
                receive_by_mobile: '65'
            })
        }

        if (this.state.dialing_code == '') {
            this.setState({
                dialing_code: '65'
            })
        }
        if (type == 'otp') {
            if (this.state.send_otp_Mobile == '') {
                ShowToast(app, 'Please fill in the OTP mobile phone number!');
            } else {
                this.setState({
                    send_type: type
                }, () => {
                    app.popup.open('.verify-popup')
                })
            }
        } else {
            if (this.state.send_Notifications_Mobile == '') {
                ShowToast(app, 'Please fill in the mobile number to receive notifications!');
            } else {
                this.setState({
                    send_type: type
                }, () => {
                    app.popup.open('.verify-popup')
                })
            }
        }


    }

    cancelVerify() {

        app.popup.close('.verify-popup')

    }

    verifyed() {

        if (this.state.send_type === 'otp') {
            if (this.state.wait_verify_flag) {
                return false
            }
            this.setState({
                wait_verify_flag: true
            })
            this.cancelVerify()
            AuthGuard.verifyCoachMobile({
                dialingCode: this.state.dialing_code,
                mobile: this.state.send_otp_Mobile
            }, (isOk, res) => {
                this.setState({
                    wait_verify_flag: false
                })

                console.log(isOk, res)
                if (isOk) {
                    setTimeout(() => {
                        app.views.main.router.navigate(ROUTES.forgetPassword, {
                            reloadCurrent: true,
                            ignoreCache: true,
                            history: false,
                            props: {
                                SMS: 'SMS',
                                dialing_code: this.state.dialing_code,
                                mobile: this.state.send_otp_Mobile
                            }
                        })
                    }, 300);
                } else {
                    if (res.status_code !== 11015) {
                        ShowToast(app, res.message);
                    }
                }
            })
        } else if (this.state.send_type == 'notifications') {

            if (this.state.wait_verify_flag2) {
                return false
            }
            this.setState({
                wait_verify_flag2: true
            })
            this.cancelVerify()
            AuthGuard.sendSmsOtpDirect({
                dialingCode: this.state.receive_by_mobile,
                mobile: this.state.send_Notifications_Mobile
            }, (isOk, res) => {
                this.setState({
                    wait_verify_flag2: false
                })

                if (isOk) {
                    setTimeout(() => {
                        app.views.main.router.navigate(ROUTES.forgetPassword, {
                            reloadCurrent: true,
                            ignoreCache: true,
                            history: false,
                            props: {
                                Notifications: 'Notifications',
                                dialing_code: this.state.receive_by_mobile,
                                mobile: this.state.send_Notifications_Mobile
                            }
                        })
                    }, 300);
                } else {

                    if (res.status_code !== 11015) {
                        ShowToast(app, res.message);
                    }
                }
            })
        }


        console.log(this.state.send_type)


    }

    CheckedNotificationsMobile(event) {
        const target = event.target;
        const _checked = target.type === 'checkbox' ? target.checked : target.value;
        // console.log(target.value, _checked)
        let appointments_mobile = document.querySelector('.appointments_mobile').childNodes[0].childNodes[0];
        let delivery_mobile = document.querySelector('.delivery_mobile').childNodes[0].childNodes[0];

        if (_checked == true) {
            this.setState({
                is_choose: 'Y',
            }, console.log(this.state.is_choose))
            appointments_mobile.disabled = false;
            delivery_mobile.disabled = false;
        } else {
            this.setState({
                is_choose: 'N',
            })
            appointments_mobile.checked = false;
            delivery_mobile.checked = false;
            appointments_mobile.disabled = true;
            delivery_mobile.disabled = true;
        }

    }

    changeDialingCode(event) {
        const target = event.target;
        console.log(target.value)
        this.setState({
            dialing_code: target.value,
            verify_flag: false
        }, () => {
            if (this.state.receive_by_mobile == '') {
                if (sessionStorage.getItem('SMSVerify_mobile')) {
                    this.setState({
                        receive_by_mobile: target.value,
                        verify_flag2: false
                    })
                }

            }
        })
    }

    changeSendOtpMobile(event) {
        const target = event.target;
        console.log(target.value)
        this.setState({
            send_otp_Mobile: target.value,
            verify_flag: false
        }, () => {
            if (this.state.send_Notifications_Mobile == '') {
                if (sessionStorage.getItem('SMSVerify_mobile')) {
                    this.setState({
                        send_Notifications_Mobile: target.value,
                        verify_flag2: false
                    })
                }

            }
        })
    }

    ChangeReceiveByMobile(event) {
        const target = event.target;
        console.log(target.value)
        this.setState({
            receive_by_mobile: target.value,
            verify_flag2: false
        })

    }

    changeSendNotificationsMobile(event) {
        const target = event.target;
        console.log(target.value)
        this.setState({
            send_Notifications_Mobile: target.value,
            verify_flag2: false
        })
    }


    renderManageAccounts() {
        return (
            <>
                {/* <Modal
                    tilte=""
                    modalOpened={this.state.delRoleModalOpen}
                    modalPopupClose={this.delRolePopupclose.bind(this)}
                    descHtml={`
                    is there staff account with this role?
                    `}
                    // onSave={this.delRoleSave.bind(this)}
                    onSave={this.openManagePop.bind(this)}
                    loadingBtn={this.state.loadingDelRoleBtn}
                /> */}
                <Modal
                    tilte=""
                    modalOpened={this.state.delStaffModalOpen}
                    modalPopupClose={this.delStaffPopupclose.bind(this)}
                    descHtml={`
                        Are you sure you want to <span class="txt-red">Delete</span> this staff Account?<br/>
                    `}
                    onSave={this.delStaffSave.bind(this)}
                    loadingBtn={this.state.loadingDelStaffBtn}
                />
                <Row>
                    <Col>
                        <Button className="settingSaveBtn fill-blue-btn" onClick={this.addRolesBtn.bind(this, '', '')}> Add Roles </Button>
                        <Row className="mt20">
                            <Col>
                                <div className="data-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="label-cell">ROLE NAME</th>
                                                <th className="numeric-cell">PERMISSIONS</th>
                                                <th width='400' className="medium-only">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.roleList.map((item, index) => {
                                                return (
                                                    <tr key={item.id}>
                                                        <td className="label-cell">{item.role_name}</td>
                                                        <td className="numeric-cell">
                                                            {item.role_code === 'doctor_user' ? 'All Permissions, Initiate Teleconsultation' : (item.function ?
                                                                item.function.map((iitem, iindex) => {
                                                                    return iitem.function_name + ', '
                                                                }) : '')}
                                                        </td>
                                                        <td className="medium-only footerBtn" style={{ display: 'flex', justifyContent: 'flex-start', padding: '6px 0px' }}>
                                                            <Button

                                                                className="navbarBtn settingEditBtn outline-blue-btn"
                                                                onClick={this.delRolesBtn.bind(this, item.role_id, item.role_name, item.role_accounts)}
                                                                disabled={item.role_code === 'doctor_user' ? true : false}
                                                                style={{ display: item.role_code === 'doctor_user' ? 'none' : 'block' }}
                                                            >
                                                                Delete
                                                            </Button>

                                                            <Button

                                                                className="navbarBtn settingEditBtn fill-blue-btn"
                                                                onClick={this.addRolesBtn.bind(this, item.role_id, item.role_accounts)}
                                                                disabled={item.role_code === 'doctor_user' ? true : false}
                                                                style={{ display: item.role_code === 'doctor_user' ? 'none' : 'block' }}
                                                            >
                                                                Edit
                                                            </Button>

                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Block className="mt20"></Block>
                <Row>
                    <Col width='20'>

                        <Button className="fillBtn fill-blue-btn" onClick={this.addStaffBtn.bind(this, '')}> Add  Staff</Button>

                    </Col>
                    <Col width='30'>
                        <Button className="fillBtn fill-blue-btn" onClick={this.addProviderBtn.bind(this, '')}> Add  Teleconsultation Provider</Button>
                    </Col>
                    <Col width='50'>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col>
                        <Row className="search-panel">
                            <Col width="10" className="search-filter">
                                Filters:
                            </Col>
                            <Col width="35">
                                <Row>
                                    <Col className="search-input">
                                        <input
                                            type='text'
                                            name="accountIDorName"
                                            className="nameOrId"
                                            placeholder="Name or Email"
                                            onChange={this.onSearchChange}
                                        />
                                        {/* <Icon f7="search" size="20px" color="gray"></Icon> */}
                                        <img src={sousuo} className="img-block" />
                                    </Col>
                                </Row>
                            </Col>
                            <Col width="35">
                            </Col>
                            <Col width="20">
                                <Row>
                                    <Col width="100" medium="50">
                                        <Button type="button" fill raised className="fill-pink-btn" onClick={this.btnSearch.bind(this)} >Search</Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='mt20'>
                            <Col>
                                <div className="data-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th className="label-cell">NO.</th>
                                                <th className="numeric-cell">ROLE</th>
                                                <th className="numeric-cell">NAME</th>
                                                <th className="numeric-cell">EMAIL</th>
                                                <th width='400' className="medium-only">ACTION</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.staffList.map((item, index) => {
                                                return (
                                                    <tr key={"staff" + index}>
                                                        <td className="label-cell">{index + 1}</td>
                                                        <td className="numeric-cell">{item.role_name}</td>
                                                        <td className="numeric-cell">{item.name}</td>
                                                        <td className="numeric-cell">{item.email}</td>
                                                        <td className="medium-only footerBtn" style={{ display: 'flex', justifyContent: 'flex-start', paddingLeft: 0 }}>
                                                            <Button
                                                                className="navbarBtn settingEditBtn outline-blue-btn"
                                                                onClick={this.delStaffBtn.bind(this, item.id)}
                                                                disabled={(item.role_code === 'doctor_user' || item.role_code === 'coach_user') ? true : false}
                                                                style={{ zIndex: (item.role_code === 'doctor_user' || item.role_code === 'coach_user') ? '-1' : 'auto' }}
                                                            >
                                                                Delete
                                                            </Button>
                                                            <Button

                                                                className="navbarBtn settingEditBtn fill-blue-btn"
                                                                onClick={this.addStaffBtn.bind(this, item.id)}
                                                            >
                                                                Edit
                                                            </Button>

                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }

    renderResetPassword() {
        return (
            <>
                <Row>
                    <Col width="50">
                        <h3 style={{ margin: '0' }}>Reset Password</h3>
                    </Col>
                    <Col width="45" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button className="outline-blue-btn resetPwBtn" onClick={this.tabClick.bind(this, 'myProfileTab')} > Cancel </Button>
                        &nbsp;&nbsp;&nbsp;
                        <Button className="fill-blue-btn resetPwBtn" onClick={this.resetPassword} > Update </Button>
                    </Col>
                </Row>
                <Row>
                    <Col width="80">
                        <form id="reset-password-form">
                            <Row className="mt20">
                                <Col>
                                    <Row>
                                        <Col width="25" className="formLabel InputRequired">
                                            Old  Password
                                        </Col>
                                        <Col width="25">
                                            <input
                                                type='password'
                                                name="oldPassword"
                                                id="oldPassword"
                                                className="formInput input-with-value input-invalid"
                                                placeholder="Old  Password"
                                                onBlur={this.inputBlur.bind(this, 'oldPassword', 'password')}
                                                onChange={(e) => {
                                                    this.setState({ clinic_email: e.target.value });
                                                }}
                                            />
                                            <img src={this.state.eyeUrl} id="eye" alt="" onClick={this.eyeShow.bind(this, 'passProfile')} />
                                        </Col>
                                        <Col width="50"></Col>
                                    </Row>
                                    <Row>
                                        <Col width="25" className="formLabel InputRequired">
                                            New Password
                                        </Col>
                                        <Col width="25">
                                            <input
                                                type='password'
                                                name="newPassword"
                                                id="newPassword"
                                                className="formInput"
                                                placeholder="New Password"
                                                required
                                                onBlur={this.inputBlur.bind(this, 'newPassword', 'password')}
                                                onChange={(e) => {
                                                    this.setState({ newPassword: e.target.value });
                                                }}
                                            />
                                            <img src={this.state.eye1Url} id="eye" alt="" onClick={this.eyeShow.bind(this, 'newPassProfile')} />
                                        </Col>
                                        <Col width="50"></Col>
                                    </Row>
                                    <Row>
                                        <Col width="25" className="formLabel InputRequired">
                                            Confirm New Password
                                        </Col>
                                        <Col width="25">
                                            <input
                                                type='password'
                                                name="confirmPassword"
                                                id="confirmPassword"
                                                className="formInput"
                                                placeholder="Confirm Password"
                                                onBlur={this.inputBlur.bind(this, 'newPassword', 'password')}
                                                required
                                                onChange={(e) => {
                                                    this.setState({ confirmPassword: e.target.value });
                                                }}
                                            />
                                            <img src={this.state.eye2Url} id="eye" alt="" onClick={this.eyeShow.bind(this, 'conPassProfile')} />
                                        </Col>
                                        <Col width="50"></Col>
                                    </Row>
                                    <Block></Block>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </>
        )
    }

    renderGeneral() {
        return (
            <div className="bg-gray setting-auto-logout">
                <Row>
                    <Col width="85"></Col>
                    <Col width="10">
                        <Button className="settingSaveBtn outline-blue-btn" onClick={this.saveLogoutIfInactive.bind(this)}> Save </Button>
                    </Col>
                </Row>
                <Row>
                    <h3>Auto Logout</h3>
                </Row>
                <Row style={{ borderBottom: '1px solid gainsboro', paddingBottom: '1rem' }}>
                    <Col width="15" className="formLabel">
                        Logout if inactive for
                    </Col>
                    <Col width="35">
                        <div className="search-input input-dropdown-wrap mt20">
                            <select
                                value={this.state.getLogouTimes}
                                onChange={(e) => {
                                    this.setState({ getLogouTimes: e.target.value });
                                }}
                            >
                                {this.state.merchantSettingLogouTimeList.map((item, index) => {
                                    return <option value={item.code} key={item.id}>{item.display_name}</option>
                                })}
                            </select>

                        </div>
                    </Col>
                    <Col width="45"></Col>
                </Row>
                <Row>
                    <h3>Alerts</h3>
                </Row>
                <Row>
                    <Col width="15" className="formLabel">
                        One-Time Password (OTP) for login
                    </Col>
                    <Col width="40" className="formLabel">

                        <div className="div_radio">
                            <div className="div_radio_right">
                                <Row>
                                    <Col width="40" onClick={this.receiveByType.bind(this, 'Email')}>

                                        {
                                            this.state.send_otp_type === 'Email' ? <img src={checkedyuanquan} /> : <img src={weixuanzhongyuanquan} />
                                        }

                                        Send Email to
                                    </Col>
                                    <Col width="60">

                                        <div className="" style={{ display: this.state.send_otp_type === 'Email' ? 'block' : 'none' }}>{this.state.send_otp_email}</div>
                                    </Col>
                                </Row>
                            </div>
                            <div className="div_radio_left">
                                <Row>
                                    <Col width="40" onClick={this.receiveByType.bind(this, 'Mobile')}>

                                        {
                                            this.state.send_otp_type === 'Mobile' ? <img src={checkedyuanquan} /> : <img src={weixuanzhongyuanquan} />
                                        }
                                        Send SMS to
                                    </Col>

                                    <Col width="60" style={{ display: this.state.send_otp_type === 'Mobile' ? 'block' : 'none' }}>
                                        <Row>
                                            <Col width="35">
                                                <div className="search-input input-dropdown-wrap">
                                                    <select
                                                        value={this.state.dialing_code}
                                                        onChange={this.changeDialingCode.bind(this)}
                                                        style={{ padding: 0 }}
                                                    >
                                                        {this.state.countryList.map((item, index) => {

                                                            return <option value={item.dialing_code} key={item.id}>+ {item.dialing_code}</option>
                                                        })}
                                                    </select>

                                                </div>
                                            </Col>
                                            <Col width="45">

                                                <div className="search-input iconPreInput">
                                                    <input className="user_mobile"
                                                        name="user_mobile"
                                                        type="text"
                                                        value={this.state.send_otp_Mobile}
                                                        onChange={this.changeSendOtpMobile.bind(this)}
                                                    />

                                                </div>

                                            </Col>
                                            <Col width="20">
                                                <div className="addSub" style={{ display: this.state.verify_flag === false ? 'block' : 'none' }}>
                                                    <span className="consult-notes" onClick={this.sendSmsOtpDirect.bind(this, 'otp')} style={{ marginLeft: 0, padding: '5px', color: this.state.wait_verify_flag == true ? 'gray' : '#56B3C4', borderColor: this.state.wait_verify_flag == true ? 'gray' : '#56B3C4' }}> Verify </span>
                                                </div>
                                                <div style={{ display: this.state.verify_flag === true ? 'block' : 'none', color: '#56B3C4' }}>
                                                    Verified
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>

                        </div>

                    </Col>
                    <Col width="40" className="formLabel"></Col>
                </Row>
                <Row>
                    <h3>Notifications</h3>
                </Row>
                <Row>
                    <Col width="15" className="formLabel">
                        Receive by:
                    </Col>
                    <Col width="40" className="formLabel settingReceiveBy">

                        <Row>
                            <Col width="40">
                                <List>
                                    <ListItem checkbox checked className="settingReceiveByEmail" title='Send Email to' name='Send Email to' value='Send Email to'>
                                    </ListItem>
                                </List>
                            </Col>
                            <Col width="60">

                                {this.state.email_datas && this.state.email_datas.map((item, index) => {

                                    // console.log(this.state.email_datas)

                                    return (
                                        <div key={index}>
                                            {item.type == "EMAIL" ?
                                                <div>

                                                    <Row style={{ marginBottom: '5px' }}>

                                                        <Col style={{ display: 'flex' }}>

                                                            <div className="iconPreInput search-input">
                                                                <Input
                                                                    className="doctorNotes user_email"
                                                                    type="text"
                                                                    placeholder=""
                                                                    name="user_email"
                                                                    value={item.email}
                                                                    onChange={(e) => this.userEmail(e.target.value, index)}
                                                                    resizable
                                                                />
                                                            </div>

                                                            {
                                                                index == 0 ? <div className="addSub" style={{ width: '39px' }}>
                                                                    <span className="consult-notes" onClick={this.addEmailData.bind(this)}> + </span>
                                                                </div> : ''
                                                            }

                                                            {
                                                                index > 0 ?
                                                                    <div className="delete-email">
                                                                        <Button onClick={this.deleteEmailDataList.bind(this, item.id, index)}
                                                                        >
                                                                            {/* <Icon f7="xmark_circle" size="22px" color="red"></Icon> */}
                                                                            <img src={deleteImg} />
                                                                        </Button>
                                                                    </div> : ''

                                                            }
                                                        </Col>

                                                    </Row>

                                                </div> : ''}
                                        </div>
                                    )

                                })}
                            </Col>
                        </Row>

                    </Col>

                    <Col width="40" className="formLabel settingReceiveBy">
                        {/* {console.log(this.state.is_choose)} */}
                        <Row>
                            <Col width="40">
                                <List>
                                    <ListItem checkbox className="notification_SMS" title='Send SMS to' name='Send SMS to' value='Send SMS to' defaultChecked={this.state.is_choose == 'Y' ? true : false} onChange={this.CheckedNotificationsMobile.bind(this)}></ListItem>
                                </List>
                            </Col>
                            <Col width="60">
                                <Row style={{ marginTop: '3px' }}>
                                    <Col width="35">
                                        <div className="search-input input-dropdown-wrap" style={{ display: this.state.is_choose === 'Y' ? 'block' : 'none' }}>
                                            <select
                                                value={this.state.receive_by_mobile}
                                                onChange={this.ChangeReceiveByMobile.bind(this)}
                                                style={{ padding: 0 }}
                                            >
                                                {this.state.countryList.map((item, index) => {

                                                    return <option value={item.dialing_code} key={item.id}>+ {item.dialing_code}</option>
                                                })}
                                            </select>

                                        </div>
                                    </Col>
                                    <Col width="45">

                                        <div className="search-input iconPreInput" style={{ width: '100%', display: this.state.is_choose === 'Y' ? 'block' : 'none' }}>
                                            <input className="user_mobile"
                                                name="send_Notifications_Mobile"
                                                type="text"
                                                value={this.state.send_Notifications_Mobile}
                                                onChange={this.changeSendNotificationsMobile.bind(this)}
                                            />
                                        </div>
                                    </Col>
                                    <Col width="20">
                                        <div style={{ display: this.state.is_choose === 'Y' ? 'block' : 'none' }}>
                                            <div className="addSub" style={{ display: this.state.verify_flag2 === false ? 'block' : 'none', marginLeft: '5px' }}>
                                                <span className="consult-notes" onClick={this.sendSmsOtpDirect.bind(this, 'notifications')} style={{ marginLeft: 0, padding: '5px', color: this.state.wait_verify_flag2 == true ? 'gray' : '#56B3C4', borderColor: this.state.wait_verify_flag2 == true ? 'gray' : '#56B3C4' }}> Verify </span>
                                            </div>
                                            <div style={{ display: this.state.verify_flag2 === true ? 'block' : 'none', color: '#56B3C4' }}>
                                                Verified
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                        </Row>


                    </Col>
                </Row>
                <Row>
                    <Col width="15" className="formLabel"></Col>
                    <Col width="40" className="formLabel">
                        <Row>
                            <Col width="40" className="formLabel">
                                Notification Types:
                            </Col>
                            <Col width="60" className="formLabel">
                                <List>
                                    <ListItem checkbox className="settingReceiveByAppointments" title='Appointments & Updates' name='Appointments & Updates' value='Appointments & Updates'></ListItem>
                                    <ListItem checkbox className="settingReceiveDelivery" title='Delivery' name='Delivery' value='Delivery'></ListItem>
                                    <ListItem checkbox checked className="settingReceiveByEmail" title='Join Program' name='Join Program' value='Join Program'></ListItem>
                                </List>
                            </Col>
                        </Row>

                    </Col>
                    <Col width="40" className="formLabel">
                        <Row>
                            <Col width="40" className="formLabel">
                                Notification Types:
                            </Col>

                            <Col width="60" className="formLabel">
                                <List>
                                    <ListItem checkbox className="appointments_mobile" title='Appointments & Updates' name='Appointments & Updates' value='Appointments' onChange={this.natificationChecked.bind(this)}></ListItem>
                                    <ListItem checkbox className="delivery_mobile" title='Delivery' name='Delivery' value='Delivery' onChange={this.natificationChecked.bind(this)}></ListItem>
                                    <ListItem checkbox checked className="settingReceiveByEmail" title='Join Program' name='Join Program' value='Join Program'></ListItem>
                                </List>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>)
    }

    backToSetting() {
        app.views.main.router.navigate(ROUTES.setting)
    }

    render() {
        return (
            <FramePage
                name={ROUTES.setting}
                onPageBeforeRemove={this.onPageBeforeRemove}
                onPageReinit={this.onPageReinit}
            >
                {this.state.activeTabType !== 'resetPasswordTab'
                    ? <Row className="navTitleBar navTitleName">
                        <Col>Settings</Col>
                        <Col>
                            <Row>
                                <Col width="70"></Col>
                                <Col width="30">{this.coachProfile.coachName}</Col>
                            </Row>
                        </Col>
                    </Row>
                    : <div className="navBar">
                        <Link animate={false} color='gray' onClick={this.backToSetting}> Settings </Link> &gt; <span>Reset Password</span>
                    </div>
                }
                {/* {AuthGuard.CanViewHistoryVideoCallList() ? */}
                {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() || AuthGuard.CanViewHistoryVideoCallList() ?
                    <div className="clinicDetail white-block">
                        <div
                            id="virtualCard"
                            className="virtualCard-popup"
                        >
                            <Page className="virtualCardPage">
                                <div className="IconBgVc">
                                    <img src={IconBgVc} alt="" />
                                </div>
                                <div className="clinic_Logo">
                                    {/* <img src={IconClinicLogo} alt="" /> */}
                                    {this.state.clinic_logo_url
                                        ? <img src={this.state.clinic_logo_url} alt="" style={{ borderRadius: '50%' }} />
                                        : <img src={IconClinicLogo} />
                                    }
                                </div>
                                <div className="dr_avatar">
                                    <div className="imgCon">
                                        {this.state.dr_photo_url
                                            ? <img src={this.state.dr_photo_url} alt="" />
                                            : <img src={IconDoctor} />
                                        }
                                    </div>
                                </div>
                                <div className="dr_name">
                                    {this.state.dr_name}
                                </div>
                                <div className="qr_code">
                                    <img src={this.state.whatsappqr} alt="" />
                                </div>
                                <div className="dr_desc">
                                    Whatsapp <b>“hello{this.state.dr_code_name}”</b> to <br />
                                    +{this.state.whatsapp_dialing_code_default} {this.state.whatsapp_default} to start a <br />
                                    teleconsultation.
                                </div>
                                <div className="hv_logo">
                                    <img src={IconIconHVLong} alt="" />
                                </div>
                            </Page>
                        </div>
                        {this.state.activeTabType == 'resetPasswordTab' && this.renderResetPassword()}
                        {this.state.activeTabType == 'resetPasswordTab' || <Row>
                            <Col>
                                <div className="cellTabbar tabbar">
                                    <Link
                                        tabLink="#GeneralTab"
                                        tabLinkActive={this.state.activeTabType == 'GeneralTab'}
                                        onClick={this.tabClick.bind(this, 'GeneralTab')}
                                    >
                                        General
                                    </Link>
                                    <Link
                                        tabLink="#manageAccountsTab"
                                        tabLinkActive={this.state.activeTabType == 'manageAccountsTab'}
                                        onClick={this.tabClick.bind(this, 'manageAccountsTab')}
                                    >
                                        Manage Accounts
                                    </Link>
                                    <Link
                                        tabLink="#clinicDetailsTab"
                                        tabLinkActive={this.state.activeTabType == 'clinicDetailsTab'}
                                        onClick={this.tabClick.bind(this, 'clinicDetailsTab')}
                                    >
                                        Company Profile
                                    </Link>
                                    <Link
                                        tabLink="#appointmentTab"
                                        tabLinkActive={this.state.activeTabType == 'appointmentTab'}
                                        onClick={this.tabClick.bind(this, 'appointmentTab')}
                                    >
                                        Company Hours
                                    </Link>
                                    <Link
                                        tabLink="#myProfileTab"
                                        tabLinkActive={this.state.activeTabType == 'myProfileTab'}
                                        onClick={this.tabClick.bind(this, 'myProfileTab')}
                                    >
                                        My Profile
                                    </Link>
                                    {/* <Link
                                    tabLink="#resetPasswordTab"
                                    onClick={this.tabClick.bind(this, 'resetPasswordTab')}
                                >
                                    Reset Password
                                </Link> */}
                                    <Link
                                        tabLink="#medicationListTab"
                                        onClick={this.tabClick.bind(this, 'medicationListTab')}
                                    >
                                        Medication List
                                    </Link>
                                </div>
                                <Tabs>
                                    <Tab id="GeneralTab" className="page-content tabContent" tabActive={this.state.activeTabType == 'GeneralTab'}>
                                        {this.renderGeneral()}
                                    </Tab>
                                    <Tab id="myProfileTab" className="page-content tabContent" tabActive={this.state.activeTabType == 'myProfileTab'}>
                                        {this.renderMyProfile()}
                                    </Tab>
                                    <Tab id="clinicDetailsTab" className="page-content tabContent" tabActive={this.state.activeTabType == 'clinicDetailsTab'}>
                                        {this.renderClinicDetails()}
                                    </Tab>
                                    <Tab id="appointmentTab" className="page-content tabContent" tabActive={this.state.activeTabType == 'appointmentTab'}>
                                        {this.renderAppointment()}
                                    </Tab>
                                    <Tab id="manageAccountsTab" className="page-content tabContent" tabActive={this.state.activeTabType == 'manageAccountsTab'}>
                                        {this.renderManageAccounts()}
                                    </Tab>
                                    <Tab id="medicationListTab" className="page-content tabContent">
                                        {/* 1 父组件调用子组件时传递一个 onRef 函数 */}
                                        <MedicationList onRef={(child) => { this.child = child }} />
                                    </Tab>
                                </Tabs>
                            </Col>
                        </Row>}
                    </div>
                    : <div className="permission-prompt">You are not authorized to view this page</div>}
                <Popup
                    opened={['fillMyProfile', 'fillClinicProfile'].includes(this.state.activePopup)}
                    className="fillInAllCompulsory-popup"
                    swipeToClose
                    swipeHandler=".swipe-handler"
                >
                    <Page>
                        <div>
                            {/* <img src={IconCancel} alt="" className="call-cancel" /> */}
                        </div>
                        <div style={{ marginTop: '35%' }}>
                            <div> Please fill in all compulsory fields <br /> to proceed </div>
                            {/* <h4 className='fwb'> {this.state.cancelQueneUserName} </h4> */}
                        </div>
                        <div className="footerCallPop">
                            <Row>
                                <Col>
                                    <Button fill raised className="fill-blue-btn" onClick={this.clearActivePopup}> OKAY </Button>
                                </Col>
                            </Row>
                        </div>
                    </Page>
                </Popup>

                <Popup
                    className="managePrompt-popup"
                >
                    <Page>
                        <div style={{ marginTop: '25%', textAlign: 'center' }}>
                            <div>
                                There are {this.state.delRoleItem.accounts} accounts with this role. Please reassign them
                                another role first before deleting this role.
                            </div>
                        </div>
                        <div className="footerCallPop">
                            <Row>
                                <Col>
                                    <Button fill raised className="fill-blue-btn" onClick={this.clearManagePopup}> OKAY </Button>
                                </Col>
                            </Row>
                        </div>
                    </Page>
                </Popup>


                <Popup
                    className="managePrompt2-popup"
                >
                    <Page>
                        <div style={{ marginTop: '10%', textAlign: 'center' }}>
                            <div>
                                Are you sure you want to delete this role?
                            </div>
                        </div>
                        <div className="footerCallPop">
                            <Row style={{ marginBottom: '20px' }}>
                                <Col>
                                    <Button fill raised className="fill-blue-btn" onClick={this.delRoleSave.bind(this)}> Delete </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button fill raised className="fill-blue-btn" onClick={this.clearManage2Popup}> Cancel </Button>
                                </Col>
                            </Row>
                        </div>
                    </Page>
                </Popup>



                <Popup
                    className="verify-popup"
                >
                    <Page>
                        <div className="verify-body">
                            <h4>Verify Mobile</h4>
                            <div>
                                Please verify your mobile to use it for receiving OTP for future logins.
                            </div>
                        </div>
                        <div className="footerCallPop">
                            <Row>
                                <Col>
                                    <Button raised disabled={this.state.wait_verify_flag} className="outline-blue-btn" onClick={this.cancelVerify.bind(this)}> Cancel </Button>
                                </Col>
                                <Col>
                                    <Button fill raised disabled={this.state.wait_verify_flag} className="fill-blue-btn" onClick={this.verifyed.bind(this)}> verify </Button>
                                </Col>
                            </Row>

                        </div>
                    </Page>
                </Popup>


            </FramePage>
        );
    }
}

export default Setting;