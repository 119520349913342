import { FETCH_CHATLIST, FETCH_GETMESSAGELIST} from "../actionTypes";

const initalState = {
    chatListData: {},
}

export default function (state = initalState, action) {
    switch (action.type) {
        case FETCH_CHATLIST:
            return {
                ...state,
                chatListData: action.chatListResult
            };
        default:
            return state;
    }
}