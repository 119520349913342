import { f7, App as Framework7App, View, Views, f7 as app } from 'framework7-react';
import React, { Component } from 'react';
import { routes } from './routes.js';
import { ROUTES } from './commons/Constants'
import AuthGuard from './services/AuthGuard';
import Login from './screens/Login';
import ConsultationDetail from './screens/ConsultationDetail'
import { getCache } from './commons/Helpers.js';
import events from './services/events.js';
import { Base64 } from "js-base64";

const f7params = {
  routes,
  name: 'Dr-Dashboard',
  theme: "ios",
  id: 'com.iapps.heartvoice',
  version: '2.0.0',
  on: {
    pageBeforeIn: function (f7Page) {
      // console.log(AuthGuard.isAuthenticated);
      if (AuthGuard.isAuthenticated) {
        // console.log(f7Page);
        // this.$f7.views.main.router.navigate(ROUTES.appointments)
      }

    },
    pageAfterIn: function (page) {
      // console.log(page);      
    },
  }
  // view: {
  //   pushState: true,
  //   iosDynamicNavbar: false,
  //   xhrCache: false,
  // },
  // autoDarkTheme:true  
  // ...
};

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogin: false,
      user_id: '',
      merchant_setting_logout_time: '',
      // logoutTime: ''
    }
  }



  componentDidMount() {
    this.initRoute();
    events.on('loginSuccess', () => {
      this.setState({ isLogin: true })
    })
  }


  componentWillReceiveProps(props) {
    console.log(props);
  }

  getLoginUserInfo(token){
    return new Promise((resolve, reject) => {
      AuthGuard.getLoginUserInfo({ token }, (isOk, res) => {
        console.log(isOk, res)        
        if (isOk){
          resolve(res)
        }else{
          reject(false);
        }        
      });
    })
  }

  setLoginUserInfo(token){
    return new Promise(async (resolve, reject) => {      
      let coachProfile = AuthGuard.getCoachProfile()
      if (coachProfile?.token){
        coachProfile.token = token
        AuthGuard.SetAuthenticated(coachProfile)        
        await this.getLoginUserInfo(token)  
        resolve(coachProfile)
      }else{
        resolve(true)
      }      
    })
  }

  async initRoute() {
    let hostName = window.location.protocol + "//" + window.location.host; //
    let canAccess = await AuthGuard.checkIpWhiteList();
    if (!canAccess) {
      window.location.href = hostName + "/downtime.html";
      return;
    }
    f7.dialog.preloader();
    var obj = {};
    var params = this.props.location.search.substr(1);
    params.replace(/([^&=]+)=([^&=]*)/gi, function (rs, $1, $2) {
      obj[$1] = decodeURIComponent($2);
    });
    
    let self = this
    if (obj?.type === "redirect") {   
      let res = await this.setLoginUserInfo(obj.token)
      
      AuthGuard.checkAuthenticated( async (isAuth) => {
        if (!isAuth) {
          try {
            let loginUserInfo = await this.getLoginUserInfo(obj.token)  
            if(loginUserInfo) window.location.reload()
          } catch (error) {
            
          }
        } else {
          self.setState({
            isLogin: true,
          });
          // await AuthGuard.initNewDrE3kit();
          let _params = {};
          if(obj?.params){
            _params = JSON.parse(Base64.decode(obj.params));
          }
          f7.views.main.router.navigate(obj.route, {
            pushState: true,
            props: {
              ..._params
            }
          });
        }
        f7.dialog.close();
      })            
      AuthGuard.setIsRedirect(true)
      return false
    }else{
      AuthGuard.setIsRedirect(false);
    }
    

    AuthGuard.checkAuthenticated((isAuth) => {
      if (isAuth) {
        this.setState({
          isLogin: true
        })
        // AuthGuard.initNewDrE3kit();
        // todo
        // app.popup.open('.consultationDetailPage-popup');

        // console.log(isAuth)
        if (this.props.location.pathname === '/payback') {
          f7.views.main.router.navigate(ROUTES.addProvider, {
            props: {
              params: obj
            }
          });
        } else if (this.props.location.pathname === '/googleConnect') {
          f7.views.main.router.navigate(ROUTES.googleConnect, {
            props: {
              params: obj
            }
          });
          // } else if (this.props.location.pathname === '/rpw') {
          //   f7.views.main.router.navigate(ROUTES.setPw, {
          //     props: {
          //       params: obj
          //     }
          //   });
        } else if (AuthGuard.isDoctor() && !AuthGuard.isDoctorProfileComplete()) {
          f7.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'myProfileTab' } })
        } else if (AuthGuard.isAdmin() && !AuthGuard.isClinicProfileComplete()) {
          f7.views.main.router.navigate(ROUTES.setting, { props: { activeTabType: 'clinicDetailsTab' } })
        } else if (AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() || AuthGuard.CanViewHistoryVideoCallList() || AuthGuard.CanEditConsultationSummary()) {
          if (getCache('DrRoute')) {
            f7.views.main.router.navigate(getCache('DrRoute'), {
              reloadAll: true,
              reloadCurrent: true,
              ignoreCache: true
            })
          } else {
            f7.views.main.router.navigate(ROUTES.appointments, {
              pushState: true,
            })
          }
        } else if (AuthGuard.CanViewMonitoringPatients()) {
          f7.views.main.router.navigate(ROUTES.patientlist)
        }
      }
      f7.dialog.close();
    });
  }


  render() {
    return (
      <Framework7App params={f7params}>
        <Views>
          <View main>
            {this.state.isLogin ? '' : <Login />}
            {/* {this.state.isLogin ? '' : <FP />} */}
          </View>
          {this.state.isLogin && <ConsultationDetail
          // onRef={(ref) => { this.refConsultationDetail = ref }}
          // videoCallId={this.state.videoCallId}
          // originUser={this.state.originUser}
          // setPatientQueueNotice={(e) => this.setPatientQueueNotice(e)}
          />}
        </Views>
      </Framework7App>
    );
  }
}
