import React, { Component } from 'react';
import { f7 as app, View, Page, Link, Row, Col, Button, List, ListItem, Block, Popup } from 'framework7-react';
import SideBar from '../components/SideBar'
import FramePage from '../components/FramePage';
import { ROUTES } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard';
import { InputBlur, VerificationFields, ShowToast } from '../commons/Helpers';
import Modal from '../components/Modal';
class AddAccountRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            role_name: '',
            role_temp_name: '',
            rolePermissions: [],
            roleFunctionList: [],
            role_id: props.role_id,
            title: 'Add New Role',
            delRoleModalOpen: false,
            loadingDelRoleBtn: false,
            delRoleItem: {},
            updateRoleModalOpen: false,
            loadingUpdateRoleBtn: false,
            updateRoleItem: {},
            // manageRoleAccounts: this.props.role_accounts
        }

    }

    componentDidMount() {
        if (this.state.role_id) {
            this.getRoleDetail();
        }

        setTimeout(() => {
            this.getRoleList();
        }, 1000);
    }

    componentDidUpdate() {
        app.preloader.hide();
    }

    inputBlur(atrName, type, e) {
        let res = InputBlur(app, atrName, this.$$("input[name='" + atrName + "']"), type);
        if (!res) {
          this.setState({
            [atrName]: "",
          });
        }
    }

    createAccountBtn = () => {
        if (this.state.role_id) {
            if (!this.state.updateRoleModalOpen) {
                return this.updateRoleBtn()
            } else {
                this.updateRolePopupclose()
            }
        }
        var formData = app.form.convertToData('#create-new-role');

        var noError = VerificationFields(this, app,
            [
                'role_name',
            ]
        );

        if (noError) {
            app.preloader.show();
            console.log(formData);
            var permissions = [];
            formData.permissions.map((item, index) => {
                permissions.push({
                    function_id: item
                })
            })

            var _formData = {
                role_name: formData.role_name,
                permissions: JSON.stringify(permissions),
                role_id: this.state.role_id
            };
            AuthGuard.saveClinicRole(_formData, (isOk, msg) => {
                if (isOk) {
                    app.dialog.alert(msg, 'Alert', () => {
                        app.views.main.router.back();
                    });
                }
                app.preloader.hide();
            })
        } else {
            console.log('Field Error!')
        }
    }

    delRolePopupclose() {
        this.setState({
            delRoleModalOpen: false
        })
    }


    delRoleSave = () => {

        this.delRolePopupclose()
        let itv = setInterval(() => {
            app.preloader.visible || app.preloader.show();
        }, 100)
        app.preloader.show()
        AuthGuard.delClinicRole({
            role_name: this.state.delRoleItem.name,
            role_id: this.state.delRoleItem.id
        }, (isOk, msg) => {
            if (isOk) {
                app.preloader.hide()
                clearInterval(itv)
                this.$f7router.back(ROUTES.setting, {
                    ignoreCache: true,
                    force: true
                })

            }
            //  else {

            //     this.setState({
            //         manageRoleAccounts: msg
            //     })
            //     app.popover.open('.deleteRolePrompt-popup')
            //     app.preloader.hide()
            //     clearInterval(itv)
            // }

        })
    }

    clearManagePopup() {
        app.popover.close('.deleteRolePrompt-popup')
    }


    delRoleBtn = () => {
        let { role_id, role_temp_name } = this.state
        this.setState({
            delRoleItem: { id: role_id, name: role_temp_name },
        }, () => {
            // console.log(this.state)
            if (this.props.role_accounts === false) {
                this.setState({
                    delRoleModalOpen: true
                })
            } else {
                app.popover.open('.deleteRolePrompt-popup')

            }

        })
    }

    updateRolePopupclose() {
        this.setState({
            updateRoleModalOpen: false
        })
    }

    updateRoleBtn = () => {
        let { role_id, role_temp_name } = this.state
        this.setState({
            updateRoleItem: { id: role_id, name: role_temp_name },
        }, () => {
            this.setState({
                updateRoleModalOpen: true
            })
        })
    }

    getRoleList = () => {
        let self = this;
        app.preloader.show();
        AuthGuard.roleFunctionList({}, (isOk, res) => {
            if (isOk) {
                self.setState({
                    roleFunctionList: res
                })
            }
        })
    }

    getRoleDetail() {
        let self = this;
        AuthGuard.getRoleDetail({ role_id: this.state.role_id }, (isOk, res) => {
            if (isOk) {
                let roles = []
                res.function.map((item, index) => {
                    roles.push(item.function_id);
                })
                // console.log(roles);
                self.setState({
                    rolePermissions: roles,
                    role_name: res.name,
                    role_temp_name: res.name,
                })

                console.log(this.state.rolePermissions)
            }
        })
    }

    backSettingPage = () => {
        // app.views.main.router.back(ROUTES.setting, {
        //     reloadCurrent: true
        // });
    }

    render() {
        return (
            <FramePage
                name={ROUTES.addAccountRole}
            >
                <Modal
                    tilte=""
                    modalOpened={this.state.delRoleModalOpen}
                    modalPopupClose={this.delRolePopupclose.bind(this)}
                    descHtml={`
                        Are you sure you want to delete this role?
                    `}
                    onSave={this.delRoleSave.bind(this)}
                    loadingBtn={this.state.loadingDelRoleBtn}
                />
                <Modal
                    tilte=""
                    modalOpened={this.state.updateRoleModalOpen}
                    modalPopupClose={this.updateRolePopupclose.bind(this)}
                    descHtml={`
                        Are you sure you want to <span class="txt-red">Update</span> this role?<br/>
                        All accounts with this role will be updated.
                    `}
                    onSave={this.createAccountBtn.bind(this)}
                    loadingBtn={this.state.loadingUpdateRoleBtn}
                />
                <div className="navBar">
                    <Link back animate={false} color='gray'> Settings </Link> &gt; <span>{this.state.role_id ? 'Edit Role' : 'Add New Role'}</span>
                </div>
                <Row className="navTitleBar">
                    <Col> {this.state.role_id ? 'Edit Role' : 'Add New Role'} </Col>
                    <Col>
                        <div className="footerBtn">
                            <Button back className="navbarBtn outline-blue-btn" raised >Cancel</Button>
                            {this.state.role_id ? <Button className="navbarBtn" onClick={this.delRoleBtn} fill color="red">Delete</Button> : null}
                            <Button className="navbarBtn fill-blue-btn" onClick={this.createAccountBtn} raised fill>{this.state.role_id ? 'Update' : 'Confirm'} </Button>
                        </div>
                    </Col>
                </Row>
                <div className="white-block">
                    <form id='create-new-role'>
                        <Row>
                            <Col width="25" className="formLabel InputRequired">
                                Role Name
                        </Col>
                            <Col width="25">
                                <input
                                    type='text'
                                    name="role_name"
                                    className="formInput input-with-value input-invalid"
                                    placeholder="Role Name"
                                    value={this.state.role_name}
                                    onBlur={this.inputBlur.bind(this, 'role_name', 'text')}
                                    onChange={(e) => {
                                        this.setState({ role_name: e.target.value });
                                    }}
                                />
                            </Col>
                            <Col width="50"></Col>
                        </Row>
                        <Row>
                            <Col width="25" className="formLabel InputRequired">
                                Role Permissions
                        </Col>
                            <Col width="75">
                                <List
                                    noHairlines
                                    noHairlinesBetween
                                    className="FormCheckBox"
                                >
                                    {this.state.roleFunctionList.map((item, index) => {
                                        // console.log(this.state.rolePermissions, this.state.rolePermissions.includes(item.id));
                                        return this.state.rolePermissions.length
                                            ? <ListItem key={item.id} checkbox value={item.id} title={item.description} name="permissions" defaultChecked={this.state.rolePermissions.includes(item.id)} ></ListItem>
                                            : <ListItem key={'e' + item.id} checkbox value={item.id} title={item.description} name="permissions" defaultChecked={false} ></ListItem>
                                    })}
                                    {/* <ListItem checkbox title="Manage Appointments" name="demo-checkbox1" defaultChecked></ListItem>
                                    <ListItem checkbox title="View upcoming video call list" name="demo-checkbox2"></ListItem>
                                    <ListItem checkbox title="View history video call list" name="demo-checkbox3"></ListItem>
                                    <ListItem checkbox title="Edit consultation summary" name="demo-checkbox"></ListItem>
                                    <ListItem checkbox title="View monitoring patients" name="demo-checkbox"></ListItem>
                                    <ListItem checkbox title="Edit Session ID Status" name="demo-checkbox"></ListItem> */}
                                </List>
                            </Col>
                        </Row>
                    </form>
                </div>

                <Popup
                    className="deleteRolePrompt-popup"
                >
                    <Page>
                        <div style={{ marginTop: '25%', textAlign: 'center' }}>
                            <div>
                                There are {this.props.role_accounts} accounts with this role. Please reassign them
another role first before deleting this role.
                            </div>
                        </div>
                        <div className="footerCallPop">
                            <Row>
                                <Col>
                                    <Button fill raised className="fill-blue-btn" onClick={this.clearManagePopup}> OKAY </Button>
                                </Col>
                            </Row>
                        </div>
                    </Page>
                </Popup>

            </FramePage>
        )
    }
}

export default AddAccountRole;