import { f7 as app } from "framework7-react";
import API from "./API";
import { RespondCode, ROUTES, IP_WHITELIST } from "../commons/Constants";
import {
  get_local_cache,
  set_local_cache,
  remove_local_cache,
  setCache,
  getCache,
  removeCache,
  DispalyDateTime,
  diffDateTime,
  getTimestamp,
  playAudio,
  currentUtcTime,
  isInvalid,
} from "../commons/Helpers";
import { EThree } from "@virgilsecurity/e3kit-browser";
import E3Kit from "../services/E3Kit";
import { ErrorCode } from "../commons/E3KitConstants";
import events from "../services/events";
import publicIp from "public-ip";
import md5 from "js-md5";
const Api = new API();
var _ = require("lodash");
class AuthGuard {
  isAuthenticated = null;
  coachProfile = {};
  videoCallInfo = {};
  consultationDetailPopupOpen = false;
  videoCallTime = 0;
  VideoCallTimer = null;
  twilioToken = "";
  twilioInitialized = false;
  twilioCanReconnect = false;
  patientInCall = false;
  tellPatientIncallLock = false;
  localVideoTrack = null;
  listerNewUserComingTimer = null;
  listerNewUserComingLoading = false;
  listerMedicationsLoading = false;
  listenerHeartbeatLoading = false;
  invalidOauth = false;
  functionRoles = [];
  listMedications = [];
  countryListCache = [];
  countHistoryUserQueue = 0;
  countTodayUserQueue = 0;
  countUpcomingUserQueue = 0;
  medicationsUpdateTime = "";
  localIpV4 = "";
  isRedirect = false;
  filtterHeaderArr = [
    {
      name: "Risk",
      code: "risk",
      checked: true,
      uid: "dda94d8f-9b99-4dae-9b2b-33fae4392a75",
    },
    {
      name: "ID",
      code: "mon-id",
      checked: true,
      uid: "a3ca2a0a-1113-4a9f-bc4d-e4696dd90dda",
    },
    {
      name: "NAME",
      code: "mon-name",
      checked: true,
      uid: "76a74985-cf84-44b7-8e5a-b45c8062303d",
    },
    {
      name: "SEX",
      code: "sex",
      checked: true,
      uid: "85d6cf6d-2aeb-4274-8d43-f06f5510b596",
    },
    {
      name: "AGE",
      code: "age",
      checked: "245f640a-58bb-43de-980d-17bec6a3f89b",
    },
    {
      name: "AVG BMI",
      code: "bmi",
      checked: true,
      uid: "c7aaf4b0-99c8-479c-8740-0cb6773c0055",
    },
    {
      name: "AVG BP",
      code: "bp",
      checked: true,
      uid: "2676b4d6-2a12-4d9d-a5cc-e590b1709f80",
    },
    {
      name: "AVG PR",
      code: "pr",
      checked: true,
      uid: "a1ec102d-c64e-4b51-a002-b5a9c4462d40",
    },
    {
      name: "IHB",
      code: "ihb",
      checked: true,
      uid: "f100d285-dc6d-496b-b20d-3ac9e1263752",
    },
    {
      name: "SpO2",
      code: "spO2",
      checked: true,
      uid: "d22f7708-5d8d-40bc-9d12-bb7cc846b63c",
    },
    {
      name: "Glucose",
      code: "glucose",
      checked: true,
      uid: "774444ab-27e6-4c15-867a-efb7dc4a7958",
    },
    {
      name: "CHAT",
      code: "chat",
      checked: true,
      uid: "5a541c95-0920-4396-aab8-4be28d190b91",
    },
  ];
  allDrInfo = null;

  DrDevice = null;

  newDrDevice = null;

  LeaderDevice = [];

  curLeaderDevice = null;

  ChatDeviceInfo = [];

  constructor() {
    this.checkAuth = this.checkAuth.bind(this);
  }

  getNewAllDrInfo(data, callback = () => {}) {
    if (this.allDrInfo) {
      callback(true);
    } else {
      this.getAllDrInfo(data, (isOk, res) => {
        if (isOk) {
          this.allDrInfo = !isInvalid(res) ? res : {};
          callback(true);
        } else {
          this.allDrInfo = {};
          callback(true);
        }
      });
    }
  }

  getDRNameByAccountID = (cahtID) => {
    let result = {};
    if (
      this.allDrInfo &&
      this.allDrInfo.clinic_staff_data &&
      this.allDrInfo.user_profile
    ) {
      let clinic_staff_data = this.allDrInfo.clinic_staff_data;
      if (clinic_staff_data.length > 0) {
        result = clinic_staff_data.find(
          (iteam) => iteam.chat_user_id == cahtID
        );
      }
      if (!result) {
        if (this.allDrInfo.user_profile.chat_user_id == cahtID) {
          result = this.allDrInfo.user_profile;
        }
      }
    }
    return result;
  };

  async initDrE3kit() {
    if (!this.DrDevice) {
      let drLoginInfo = this.getCoachProfile();
      if (drLoginInfo) {
        var drGetE3kitToken = await this.getE3Token({
          user_profile_id: drLoginInfo.userProfileId,
        });
        var CurDrDevice = new E3Kit({
          tokenFunction: drGetE3kitToken(),
          register: drLoginInfo.userProfileId,
        });
        try {
          await CurDrDevice.initEthree();
          let hasPKRes = await CurDrDevice.hasLocalPrivateKey();
          console.log("drlocalhasPKRes", hasPKRes);
          this.setChatLog("drlocalhasPKRes: " + hasPKRes);
          // 如果本地么有私钥
          if (!hasPKRes) {
            let drSalt = await this.getDrE3salt();
            console.log("drlocalsalt", drSalt);
            if (drSalt) {
              let e3backupPrivateKey = this.generateJWTbackupKey(
                drLoginInfo.userProfileId,
                drSalt
              );
              //判断云端有没有备份
              //如果云端有私钥帮用户恢复私钥到本地
              let restorePrivateKeyRes = await CurDrDevice.restorePrivateKey(
                e3backupPrivateKey
              );
              console.log("drrestorePrivateKeyRes", restorePrivateKeyRes);
              if (restorePrivateKeyRes && !restorePrivateKeyRes.status) {
                await CurDrDevice.cleanupEthree();
                console.log(
                  "restorePrivateKeyResfail",
                  restorePrivateKeyRes.res
                );
                this.setChatLog(
                  "drrestorePrivateKeyResfail" + restorePrivateKeyRes.res
                );
                if (
                  restorePrivateKeyRes.res == ErrorCode.PrivateKeyNoBackupError
                ) {
                  await CurDrDevice.unregisterEthree();
                }
                let refisterResult = await CurDrDevice.registerEthree(
                  e3backupPrivateKey
                );
                console.log("drregistor", refisterResult);
                this.setChatLog("drregistor" + refisterResult.errorCode);
                if (refisterResult && refisterResult.status) {
                  this.DrDevice = CurDrDevice;
                }
              } else {
                this.DrDevice = CurDrDevice;
              }
            }
          } else {
            this.DrDevice = CurDrDevice;
          }
        } catch (error) {
          console.log("e3kit init fail.");
        }
      }
    }
  }

  async initNewDrE3kit() {
    if (!this.newDrDevice) {
      let drLoginInfo = this.getCoachProfile();
      if (drLoginInfo) {
        var drGetE3kitToken = await this.getE3Token({
          user_profile_id: drLoginInfo.userProfileId,
        });
        var CurDrDevice = new E3Kit({
          tokenFunction: drGetE3kitToken(),
          register: drLoginInfo.userProfileId,
        });
        try {
          await CurDrDevice.initEthree();
          let hasPKRes = await CurDrDevice.hasLocalPrivateKey();
          console.log("drlocalhasPKRes", hasPKRes);
          this.setChatLog("drlocalhasPKRes: " + hasPKRes);
          // 如果本地么有私钥
          if (!hasPKRes) {
            let drSalt = await this.getDrE3salt();
            console.log("drlocalsalt", drSalt);
            if (drSalt) {
              let e3backupPrivateKey = this.generateJWTbackupKey(
                drLoginInfo.userProfileId,
                drSalt
              );
              //判断云端有没有备份
              //如果云端有私钥帮用户恢复私钥到本地
              let restorePrivateKeyRes = await CurDrDevice.restorePrivateKey(
                e3backupPrivateKey
              );
              console.log("drrestorePrivateKeyRes", restorePrivateKeyRes);
              if (restorePrivateKeyRes && !restorePrivateKeyRes.status) {
                await CurDrDevice.cleanupEthree();
                console.log(
                  "restorePrivateKeyResfail",
                  restorePrivateKeyRes.res
                );
                this.setChatLog(
                  "drrestorePrivateKeyResfail" + restorePrivateKeyRes.res
                );
                if (
                  restorePrivateKeyRes.res == ErrorCode.PrivateKeyNoBackupError
                ) {
                  await CurDrDevice.unregisterEthree();
                }
                let refisterResult = await CurDrDevice.registerEthree(
                  e3backupPrivateKey
                );
                console.log("drregistor", refisterResult);
                this.setChatLog("drregistor" + refisterResult.errorCode);
                if (refisterResult && refisterResult.status) {
                  this.newDrDevice = CurDrDevice;
                }
              } else {
                this.newDrDevice = CurDrDevice;
              }
            }
          } else {
            this.newDrDevice = CurDrDevice;
          }
        } catch (error) {
          console.log("e3kit init fail.");
        }
      }
    }
  }

  setGroupLeaderHasInitE3kitGroup = async (
    defultPrograms,
    isdelect = false
  ) => {
    //groupLeaderE3Three = e3Token, program_id - 组长 - program初始化实例
    this.curLeaderDevice = null;
    if (this.LeaderDevice && this.LeaderDevice.length > 0) {
      let _curLeaderDevice = this.LeaderDevice.find(
        (item) => item.id == defultPrograms.program_id
      );
      if (_curLeaderDevice) {
        this.curLeaderDevice = _curLeaderDevice.e3kit;
        if (isdelect) {
          //delect user in group
          // await this.clearGoupUser(defultPrograms)
        }
        await this.initE3Kit(defultPrograms);
      } else {
        await this.initLeaderE3kit(defultPrograms, isdelect);
      }
    } else {
      await this.initLeaderE3kit(defultPrograms, isdelect);
    }
  };

  // clearGoupUser = async (defultPrograms) => {
  //     let drLoginInfo = this.getCoachProfile();
  //     let checkLoad = await this.curLeaderDevice.loadGroup(defultPrograms._id,defultPrograms.programs_id);
  //     if(checkLoad.status){
  //         await this.curLeaderDevice.delGroupUser(drLoginInfo.userProfileId);
  //     }
  // }

  initLeaderE3kit = async (defultPrograms, is_del = false) => {
    let userProfileID = defultPrograms.program_id;
    let tokenRes = await this.getE3Token({
      user_profile_id: defultPrograms.program_id,
    });
    console.log("groupLeader-Token->>>>");
    if (!tokenRes()) {
      console.error(`groupLeader get E3 Token Error!!`);
      return false;
    }
    console.log("<<<<<-groupLeader-Token");
    var GroupLeaderDevice = new E3Kit({
      tokenFunction: tokenRes(),
      register: userProfileID,
    });
    let initRes = await GroupLeaderDevice.initEthree();
    await GroupLeaderDevice.cleanupEthree();
    let hasPKRes = await GroupLeaderDevice.hasLocalPrivateKey();
    console.log(`initE3Kit-initRes: ${initRes}`);
    console.log(`initE3Kit-hasPKRes: ${hasPKRes}`);
    let e3backupPrivateKey = this.generateJWTbackupKeyGroup(userProfileID);
    console.log(
      `GroupLeaderDevice_E3backupPrivateKey---->${e3backupPrivateKey}`
    );
    // 如果本地么有私钥
    if (!hasPKRes) {
      //判断云端有没有备份
      //如果云端有私钥帮用户恢复私钥到本地
      let restorePrivateKeyRes = await GroupLeaderDevice.restorePrivateKey(
        e3backupPrivateKey
      );

      if (restorePrivateKeyRes.status) {
        console.log("组长私钥恢复成功");
      } else {
        console.log(`组长私钥恢复失败 :${userProfileID}`);
        if (restorePrivateKeyRes.res == ErrorCode.PrivateKeyNoBackupError) {
          await GroupLeaderDevice.unregisterEthree();
        }
        //如果私钥不存在本地，需要注册
        let registerRes = await GroupLeaderDevice.registerEthree(
          e3backupPrivateKey
        );
        console.log(
          `GroupLeaderDevice-registerRes: ${JSON.stringify(registerRes)}`
        );
        //组长
        if (registerRes.status) {
          console.log("组长E3注册成功！");
        } else {
          console.error(
            `组长E3注册失败 :${userProfileID}`,
            registerRes.errorCode
          );
          return;
        }
        this.setChatLog("LeaderInitInfo:1.register" + registerRes.errorCode);
      }
      this.setChatLog(
        "LeaderInitInfo:1.hasPKRes:" +
          hasPKRes +
          " 2.restorePrivateKeyRes:" +
          restorePrivateKeyRes.status +
          " 3.leader_id:" +
          userProfileID
      );
      hasPKRes = await GroupLeaderDevice.hasLocalPrivateKey();
      if (!hasPKRes) {
        return false;
      }
    }
    this.LeaderDevice.push({ id: userProfileID, e3kit: GroupLeaderDevice });
    this.curLeaderDevice = GroupLeaderDevice;
    if (is_del) {
      // await this.clearGoupUser(defultPrograms)
    }
    await this.initE3Kit(defultPrograms);
  };

  initE3Kit = async (selectedDialog) => {
    let userProfileID = selectedDialog.program_id;
    console.log("initE3KitForGroupLeader");
    let group_id = selectedDialog._id ? selectedDialog._id : "";
    let user_profile_ids = selectedDialog.user_profile_ids
      ? selectedDialog.user_profile_ids
      : null;
    console.log(user_profile_ids);
    console.log(`group_id--->${group_id}`);
    try {
      //本地有私钥 遍历所有成员
      //遍历所有组员(医生 和 病人 的 user_profile_id)，不包含组长(program_id) 是否在云端注册 (user_profile_id 对应的 public key 是否已经在云端注册 register())
      let E3UsersId = [];
      let unRegE3UsersId = [];
      for (let i = 0; i < user_profile_ids.length; i++) {
        let e3user = user_profile_ids[i];
        let findUsersRes = await this.curLeaderDevice.findUsers(e3user);
        if (findUsersRes.status) {
          let userIdentity = findUsersRes.res.identity
            ? findUsersRes.res.identity
            : "";
          E3UsersId.push(userIdentity);
        } else {
          unRegE3UsersId.push(e3user);
          console.error(`cant findUsers: ${e3user}`);
        }
      }
      //判断是否已经创建过加密组
      let loadGroupRes = await this.curLeaderDevice.loadGroup(
        group_id,
        userProfileID
      );
      //判断是否本地有加密组
      let getGroupRes = await this.curLeaderDevice.getGroup(group_id);
      console.log("getGroupRes>>>");
      console.log(this.curLeaderDevice.group);
      console.log("<<<getGroupRes");
      if (!loadGroupRes.status)
        this.setChatLog(
          "LeaderLoading:1.load:" +
            loadGroupRes.errorCode +
            "group_id:" +
            group_id +
            "program_id" +
            userProfileID
        );
      //本地已经有组了
      if (loadGroupRes.status && getGroupRes) {
        let _E3UsersId = [...E3UsersId, userProfileID];
        let noInGroupUser = _.xorWith(
          _E3UsersId,
          this.curLeaderDevice.group.participants,
          _.isEqual
        );
        if (_.size(noInGroupUser) > 0) {
          for (let i = 0; i < noInGroupUser.length; i++) {
            let userId = noInGroupUser[i];
            console.error(`needAddGrpupUser: ${userId}`);
            let addGroupUserRes = await this.curLeaderDevice.addGroupUser(
              userId
            );
            if (addGroupUserRes.status) {
              console.log("添加用户成功：", addGroupUserRes);
              // setClientUserCallBack(true);
            } else {
              this.setChatLog(
                "LeaderaddUserfail:" +
                  addGroupUserRes.errorCode +
                  "userid:" +
                  userId
              );
              console.error("添加用户失败：", addGroupUserRes);
            }
          }
        }
        let leader_success_load = await this.DrDevice.loadGroup(
          group_id,
          userProfileID
        );
        let load_result = await this.DrDevice.getGroup(group_id, userProfileID);
        if (leader_success_load.status && load_result) {
          this.ChatDeviceInfo.push({
            programs_id: userProfileID,
            e3kit: load_result,
            user_profile_id: selectedDialog.user_profile_id,
          });
        }
      } else {
        //如果还没有创建组
        let createGroupRes = await this.curLeaderDevice.createGroup(
          group_id,
          E3UsersId
        );
        if (!createGroupRes.status) {
          console.error("没有组时候，创建组失败：", createGroupRes);
          this.setChatLog("LeaderCreatefail:" + createGroupRes.errorCode);
          // await initE3Kit(userProfileID, selectedDialog, E3token);
        } else {
          console.log("没有组时候，创建组成功：", createGroupRes);
          let leader_success_load = await this.DrDevice.loadGroup(
            group_id,
            userProfileID
          );
          let load_result = await this.DrDevice.getGroup(
            group_id,
            userProfileID
          );
          if (leader_success_load.status && load_result) {
            this.ChatDeviceInfo.push({
              programs_id: userProfileID,
              e3kit: load_result,
              user_profile_id: selectedDialog.user_profile_id,
            });
          }
          // setClientUserCallBack(true);
        }
      }
    } catch (error) {
      console.error(`initE3Kit: ${error}`);
    }
    console.groupEnd("initE3KitForCurrentLeader");
  };

  initE3kitStart = async (initInfo) => {
    if (this.ChatDeviceInfo && this.ChatDeviceInfo.length > 0) {
      let curDevice = this.ChatDeviceInfo.find((item) => {
        return (
          item.programs_id == initInfo.program_id &&
          item.user_profile_id == initInfo.user_profile_id
        );
      });
      if (curDevice) {
        return true;
      }
    }
    let _dialog_id = initInfo._id;
    let _programs_id = initInfo.program_id;
    let new_DrDevice = this.DrDevice;
    if (!this.DrDevice) {
      await this.initDrE3kit();
      if (this.DrDevice) {
        new_DrDevice = this.DrDevice;
        let checkLoad = await new_DrDevice.loadGroup(_dialog_id, _programs_id);
        //log
        if (!checkLoad.status)
          this.setChatLog("curdrLoadResult" + checkLoad.errorCode);
        if (checkLoad.status) {
          let checkGroup = await new_DrDevice.getGroup(_dialog_id);
          if (checkGroup) {
            this.ChatDeviceInfo.push({
              programs_id: _programs_id,
              e3kit: checkGroup,
              user_profile_id: initInfo.user_profile_id,
            });
          } else {
            await this.setGroupLeaderHasInitE3kitGroup(initInfo);
          }
        } else if (
          !checkLoad.status &&
          checkLoad.errorCode &&
          (ErrorCode.GroupIdNotFoundError == checkLoad.errorCode ||
            ErrorCode.UserNotAcceptError == checkLoad.errorCode)
        ) {
          await this.setGroupLeaderHasInitE3kitGroup(initInfo, true);
        } else if (
          ErrorCode.UsersFoundWithMultipleCardsError == checkLoad.errorCode
        ) {
          return;
        } else {
          await this.setGroupLeaderHasInitE3kitGroup(initInfo);
        }
      } else {
        console.log("init fail.");
        return false;
      }
    } else {
      let checkLoad = await new_DrDevice.loadGroup(_dialog_id, _programs_id);
      //log
      if (!checkLoad.status)
        this.setChatLog("curdrLoadResult" + checkLoad.errorCode);
      if (checkLoad.status) {
        let checkGroup = await new_DrDevice.getGroup(_dialog_id);
        if (checkGroup) {
          this.ChatDeviceInfo.push({
            programs_id: _programs_id,
            e3kit: checkGroup,
            user_profile_id: initInfo.user_profile_id,
          });
        } else {
          await this.setGroupLeaderHasInitE3kitGroup(initInfo);
        }
      } else if (
        !checkLoad.status &&
        checkLoad.errorCode &&
        ErrorCode.UserJWTExpired == checkLoad.errorCode
      ) {
        this.DrDevice = null;
      } else if (
        !checkLoad.status &&
        checkLoad.errorCode &&
        (ErrorCode.GroupIdNotFoundError == checkLoad.errorCode ||
          ErrorCode.UserNotAcceptError == checkLoad.errorCode)
      ) {
        await this.setGroupLeaderHasInitE3kitGroup(initInfo, true);
      } else if (
        ErrorCode.UsersFoundWithMultipleCardsError == checkLoad.errorCode
      ) {
        return;
      } else {
        await this.setGroupLeaderHasInitE3kitGroup(initInfo);
      }
    }
    return true;
  };

  getDrE3salt = () => {
    return new Promise((resolve, reject) => {
      this.generateE3Salt({}, (isOk, res) => {
        if (isOk) {
          resolve(res.salt);
        } else {
          reject(false);
        }
      });
    });
  };

  getE3Token = (identity) => () => {
    return new Promise((resolve, reject) => {
      this.generateE3Token(identity, (isOk, res) => {
        if (isOk) {
          resolve(res);
        } else {
          reject(false);
        }
      });
    });
  };

  generateJWTbackupKey(userProfileId, saltCode = "") {
    return md5(userProfileId + saltCode);
  }

  generateJWTbackupKeyGroup(userProfileId) {
    return md5(userProfileId);
  }

  setIsRedirect(bool) {
    this.isRedirect = bool;
    return true;
  }

  setChatLog(error_log) {
    let drLoginInfo = this.getCoachProfile();
    let log_data = {
      platform: "WEB",
      error_log: error_log,
      user_profile_id: drLoginInfo.userProfileId,
    };
    setTimeout(() => {
      this.chatLog(log_data);
    }, 200);
  }

  setSearchPatientsFilter(_code = "", _checked = true) {
    console.log(_code, _checked);
    if (_code) {
      this.filtterHeaderArr.map((item, index) => {
        if (item.code === _code) {
          item.checked = _checked;
          return item;
        }
      });
    } else {
      this.filtterHeaderArr.map((item, index) => {
        item.checked = _checked;
        return item;
      });
    }
    return true;
  }

  getSearchPatientsFilter(_code = "") {
    if (_code) {
      return this.filtterHeaderArr.filter((item) => item.code === _code);
    } else {
      return this.filtterHeaderArr;
    }
  }

  setConsultationDetailPopupOpen(type) {
    this.consultationDetailPopupOpen = type;
  }

  switchVideoCallTimer(type) {
    if (type === "on") {
      events.emit("SetConsultationDetailPopupOpen", { show: true });
      if (this.VideoCallTimer) clearInterval(this.VideoCallTimer);

      this.VideoCallTimer = setInterval(() => {
        this.videoCallTime = ++this.videoCallTime;
        events.emit("IntervalVideoCallTime", {});
      }, 1000);
    } else {
      events.emit("SetConsultationDetailPopupOpen", { show: false });
      clearInterval(this.VideoCallTimer);
      this.videoCallTime = 0;
    }
  }

  setVideoCallInfo(obj) {
    this.videoCallInfo = obj;
  }

  setInvalidOauth() {
    this.invalidOauth = true;
  }

  setValidOauth() {
    this.invalidOauth = false;
  }

  checkAuth(to, from, resolve, reject) {
    this.checkAuthenticated();
    if (this.listerNewUserComingTimer)
      clearInterval(this.listerNewUserComingTimer);

    let tempMin = 0;

    let { clinic_info_id } = this.getCoachProfile();
    this.listerNewUserComingTimer = setInterval(() => {
      if (this.invalidOauth) {
        if (this.listerNewUserComingTimer)
          clearInterval(this.listerNewUserComingTimer);
        this.setLogout();
        return;
      }
      if (this.listerNewUserComingLoading) return;
      this.listerNewUserComingLoading = true;
      let date = new Date();
      let _min = date.getMinutes();
      if (tempMin !== _min) {
        tempMin = _min;
        if (this.listerMedicationsLoading) return;
        this.listerMedicationsLoading = true;
        this.findMedicationAll(
          {
            time: this.medicationsUpdateTime,
          },
          (isOk, res) => {
            this.listerMedicationsLoading = false;
            if (isOk) {
              this.listMedications = !isInvalid(res.medicationList)
                ? res.medicationList
                : [];
              this.medicationsUpdateTime = !isInvalid(res.time) ? res.time : [];
            }
          }
        );
      }

      this.newUserComing(
        {
          type: "incoming",
          time: currentUtcTime() - 2,
        },
        (isOk, res) => {
          if (isOk) {
            let {
              countHistoryUserQueue,
              countTodayUserQueue,
              countUpcomingUserQueue,
              history_count,
              upcoming_count,
            } = res;
            this.countHistoryUserQueue = countHistoryUserQueue;
            this.countTodayUserQueue = countTodayUserQueue;
            this.countUpcomingUserQueue = countUpcomingUserQueue;
            if (upcoming_count) {
              playAudio("comingMP3", false);
            }
          }
          this.listerNewUserComingLoading = false;
        }
      );

      this.listenerHeartbeat({}, (isok, res) => {
        // console.log(isok, res)
        if (isok) {
        }
      });
    }, 2 * 1000);

    // console.log('checkAuth To : ' + to.url, ' ==>checkAuth From : ' + from.url)
    if (true) {
      resolve();
    } else {
      reject();
    }
    // console.log('checkAuth')
  }

  isDoctorProfileComplete() {
    let p = this.getCoachProfile();
    return (
      typeof p.profile_is_complete === "undefined" ||
      getCache("drInfoUpdated") || p.profile_is_complete
    );
  }

  isClinicProfileComplete() {
    let p = this.getCoachProfile();
    return (
      typeof p.clinic_is_complete === "undefined" ||
      getCache("clinicInfoUpdated") || p.clinic_is_complete
    );
  }

  hasRole(role) {
    let rtn = false;
    let p = this.getCoachProfile();
    if (p.coachRole) {
      p.coachRole.map((i) => {
        if (i.code == role) {
          rtn = true;
        }
      });
    }
    return rtn;
  }

  isDoctor() {
    let p = this.getCoachProfile();
    return (
      p.userType == "COACH" &&
      this.hasRole("doctor_user") &&
      !this.hasRole("coach_user")
    );
  }

  isAdmin() {
    let p = this.getCoachProfile();
    return p.userType == "COACH" && this.hasRole("coach_user");
  }

  isProfileComplete(to, from, resolve, reject) {
    if (this.isDoctor() && !this.isDoctorProfileComplete()) {
      reject();
    } else {
      resolve();
    }
  }

  checkPermission(to, from, resolve, reject) {
    // console.log('checkPermission To : ' + to.url, ' ==>checkPermission From : ' + from.url)
    setCache("DrRoute", to.url);
    if (true) {
      resolve();
    } else {
      reject();
    }
    // console.log('checkPermission')
  }

  SetAuthenticated(AuthInfo) {
    set_local_cache("Auth", AuthInfo);
    this.setCoachProfile(AuthInfo);
    this.authenticate(AuthInfo.token);
    this.setFunctionRole();
  }

  /**
   * Login adn set authenticate
   *
   * @param {any} username
   * @param {any} password
   * @memberof AuthGuard
   */
  login({ userID, password }, callback = (isOk, res) => {}) {
    Api.login({
      data: { userID, password },
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.loginSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  /**
   * Set logout and set unauthenticate
   *
   * @memberof AuthGuard
   */
  setLogout() {
    this.clearDrDashboardAuthData();
    app.views.main.router.back(ROUTES.logout, {
      force: true,
    });
    return this.authenticate(null);
  }

  clearDrDashboardAuthData() {
    remove_local_cache("Auth");
    removeCache("doctorNotes");
    removeCache("DrRoute");
    sessionStorage.removeItem("MonitoringColumns");
    sessionStorage.removeItem("SMSVerify_dialing_code");
    sessionStorage.removeItem("SMSVerify");
    sessionStorage.removeItem("SMSVerify_mobile");
    sessionStorage.removeItem("NotificationsSMSVerify");
    sessionStorage.removeItem("NotVerify_dialing_code");
    sessionStorage.removeItem("NotVerify_mobile");
    if (this.listerNewUserComingTimer) {
      clearInterval(this.listerNewUserComingTimer);
    }
    this.isAuthenticated = null;
    app.views.main.router.clearPreviousHistory();
    app.popover.destroy();
    app.popup.destroy();
  }

  logout(callback = (isOk, res) => {}) {
    let self = this;
    Api.logout()
      .then((res) => {
        callback(true, res);
      })
      .catch((res) => {
        // console.log(res);
        callback(false, res);
      });
    setTimeout(() => {
      self.setLogout();
    }, 100);
  }

  authenticate(auth, callback = () => {}) {
    this.isAuthenticated = auth;
    // console.log(this.isAuthenticated);
    setTimeout(() => {
      callback();
    }, 100);
  }

  getCoachProfile() {
    if (JSON.parse(get_local_cache("Auth"))) {
      let AuthInfo = JSON.parse(get_local_cache("Auth"));
      // console.log(AuthInfo);
      return AuthInfo;
    } else {
      return {};
    }
  }

  setCoachProfile(profile, callback = () => {}) {
    this.coachProfile = {
      ...profile,
    };
  }

  generateJWTbackupKey(userProfileId, saltCode) {
    return md5(userProfileId + saltCode);
  }

  generateJWTbackupKeyv2(userProfileId) {
    return md5(userProfileId);
  }

  getAppointmentsCount() {
    return {
      countHistoryUserQueue: this.countHistoryUserQueue,
      countTodayUserQueue: this.countTodayUserQueue,
      countUpcomingUserQueue: this.countUpcomingUserQueue,
    };
  }

  setFunctionRole() {
    let roleArr = [];
    if (this.coachProfile.function && this.coachProfile.function.length > 0) {
      this.coachProfile.function.map((item, index) => {
        roleArr.push(item.code);
      });
    }

    this.functionRoles = roleArr;
  }

  CanSetting() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("clinic");
    }
    return false;
  }

  CanTeleconsultationProvider() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("teleconsultation Provider");
    }
    return false;
  }

  CanMangeAppointments() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("manage_appointments");
    }
    return false;
  }

  CanViewAppointmentsUpcoming() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("view_upcomming_video_call_list");
    }
    return false;
  }

  CanViewHistoryVideoCallList() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("view_history_video_call_list");
    }
    return false;
  }

  CanEditConsultationSummary() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("edit_consultation_summary");
    }
    return false;
  }

  CanViewMonitoringPatients() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("view_monitoring_patients");
    }
    return false;
  }

  CanEditSessionIdStatus() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("edit_session_id_status");
    }
    return false;
  }

  CanViewPatientData() {
    if (this.functionRoles.length > 0) {
      return this.functionRoles.includes("access_all_patients_data");
    }
    return false;
  }

  /**
   * Check authenticate
   *
   * @memberof AuthGuard
   */
  checkAuthenticated(callback = () => {}) {
    if (get_local_cache("Auth")) {
      let AuthInfo =
        get_local_cache("Auth") && JSON.parse(get_local_cache("Auth"));
      if (AuthInfo) {
        this.SetAuthenticated(AuthInfo);
        callback(true);
      } else {
        callback(false);
      }
    } else {
      callback(false);
    }
  }

  autoRefreshToken(data, callback = (isOk, res) => {}) {
    if (this.listenerHeartbeatLoading) return;
    let auth = this.getCoachProfile();
    data = {
      refresh_token: auth.refresh_token,
    };
    let self = this;
    Api.autoRefreshToken({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.autoRefreshTokenSuccess) {
          let auth = self.getCoachProfile();
          if (auth) {
            auth.token = result.token;
            auth.refresh_token = result.refresh_token;
            auth.token_expired_at = result.expired_at;
            self.SetAuthenticated(auth);
            setTimeout(() => {
              callback(true, result);
            }, 100);
          }
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getSessionList(data, callback = (isOk, res) => {}) {
    Api.sessionList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.sessionSuccess) {
          if (result === undefined || result.length <= 0) {
            app.dialog.alert(message, "Alert");
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  redeemSessionStatus(data, callback = (isOk, res) => {}) {
    Api.redeemSessionStatus({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.redeemSessionStatusSuccess) {
          app.dialog.alert(message, "Alert");
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPatientList(data, callback = (isOk, res) => {}) {
    Api.patientList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.patientListSuccess) {
          if (result === undefined || result.length <= 0) {
            app.dialog.alert("No results found", "Alert");
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getChatList(data, callback = (isOk, res) => {}) {
    Api.chatList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.chatListSuccess) {
          if (result === undefined || result.length <= 0) {
            app.dialog.alert(message, "Alert");
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPatientDetail(data, callback = (isOk, res) => {}) {
    Api.getPatientDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.getPatientDetailSuccess) {
          if (result === undefined) {
            app.dialog.alert(message, "Alert");
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        console.log(res);
        callback(false, res);
      });
  }

  createDialog(data, callback = (isOk, res) => {}) {
    Api.createDialog({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.createChatDialogSuccess) {
          if (result === undefined) {
            app.dialog.alert(message, "Alert");
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        console.log(res);
        if (res.message !== undefined) {
          callback(false, res.message);
        } else {
          callback(false, res);
        }
      });
  }

  getMsgList(data, callback = (isOk, res) => {}) {
    Api.getMsgList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.getMessageSuccess) {
          if (result === undefined) {
            app.dialog.alert(message, "Alert");
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        console.log(res);
        if (res.status_code == RespondCode.getMessageSuccess) {
          callback(true, {});
        } else {
          callback(false, res);
        }
      });
  }

  sendMsg(data, callback = (isOk, res) => {}) {
    Api.sendMsg({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.sendMessageSuccess) {
          if (result === undefined) {
            app.dialog.alert(message, "Alert");
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        console.log(res);
        callback(false, res);
      });
  }

  getProfileImage(data, callback = (isOk, res) => {}) {
    Api.getProfileImage({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.getProfileImageSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  upateUserGoalBP(data, callback = (isOk, res) => {}) {
    let params = data;
    Api.updateGoalBP({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.updateGoalBPSuccess) {
          app.dialog.alert(message, "Alert");
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  upateUserInfo(data, callback = (isOk, res) => {}) {
    let params = data;
    Api.updateUserInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.updateUserInfoSuccess) {
          app.dialog.alert(message, "Alert");
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getNotiList(data, callback = (isOk, res) => {}) {
    Api.getNotificationList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.getNotiListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getGraph(data, callback = (isOk, res) => {}) {
    Api.getGraphData({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.getGraphSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  pushNotification(data, callback = (isOk, res) => {}) {
    Api.pushNotifi({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.pushNotificationToUserAppSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  pushNotificationCall(data, callback = (isOk, res) => {}) {
    Api.pushNotifiCall({
      data,
    })
      .then((res) => {
        // fix callback(true, {})
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.pushNotificationCall) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  forgetpassword(data, callback = (isOk, res) => {}) {
    Api.forgetPassword({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.forgetPwSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  resetPw(data, callback = (isOk, res) => {}) {
    Api.resetPw({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.resetPwSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
        app.dialog.alert(message, "Alert");
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getClinicInfo(data, callback = (isOk, res) => {}) {
    Api.getClinicInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getClinicInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  setClinicInfo(data, callback = (isOk, res) => {}) {
    Api.setClinicInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.setClinicInfoSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getGoalInfo(data, callback = (isOk, res) => {}) {
    Api.getGoalInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getGoalInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  setGoalUpdate(data, callback = (isOk, res) => {}) {
    Api.setGoalUpdate({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.setGoalUpdateSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getLimitInfo(data, callback = (isOk, res) => {}) {
    Api.getLimitInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getLimitInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  setLimitUpdate(data, callback = (isOk, res) => {}) {
    Api.setLimitUpdate({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.setLimitUpdateSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  addDataInfo(data, callback = (isOk, res) => {}) {
    Api.addDataInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.addDataInfoSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  tmpertureHisotry(data, callback = (isOk, res) => {}) {
    Api.tmpertureHisotry({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.tmpertureHisotrySuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  spo2History(data, callback = (isOk, res) => {}) {
    Api.spo2History({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.spo2HistorySuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getVideoCall(data, callback = (isOk, res) => {}) {
    let callApiAt = new Date();
    Api.getVideoCall({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message, total } = data;
        if (data.status_code === RespondCode.getVideoCallSuccess) {
          callback(true, {
            result: result,
            total: total,
            callApiAt: callApiAt,
          });
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getCurAppointmentInfo(data, callback = (isOk, res) => {}) {
    let callApiAt = new Date();
    Api.getCurAppointmentInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getCurAppointmentInfo) {
          callback(true, { result: result, callApiAt: callApiAt });
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  cancelVideoCall(data, callback = (isOk, res) => {}) {
    Api.cancelVideoCall({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.cancelVideoCallSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getVideoCallInfo(data, callback = (isOk, res) => {}) {
    const { id } = data;
    if (!id) return false;
    Api.getVideoCallInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getVideoCallInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  drStartCall(data, callback = (isOk, res) => {}) {
    Api.drStartCall({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.drStartCallSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        console.log(res);
        callback(false, res);
      });
  }

  readyCall(data, callback = (isOk, res) => {}) {
    Api.readyCall({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.readyCallSuccss) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  createBill(data, callback = (isOk, res) => {}) {
    Api.createBill({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.createBillSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  tellPatientIncall(data, callback = (isOk, res) => {}) {
    Api.tellPatientIncall({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.tellPatientIncallSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  drEndcall(data, callback = (isOk, res) => {}) {
    Api.drEndcall({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.endCallSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  drCloseVideo(data, callback = (isOk, res) => {}) {
    Api.drCloseVideo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.drCloseVideoSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  changeStatus(data, callback = (isOk, res) => {}) {
    Api.changeStatus({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.onlineOfflineSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getUpcomingConsultationsCount(data, callback = (isOk, res) => {}) {
    Api.getUpcomingConsultationsCount({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (
          data.status_code === RespondCode.getUpcomingConsultationsCountSuccess
        ) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getCoachByID(data, callback = (isOk, res) => {}) {
    Api.getCoachByID({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getCoachByIDSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  sendOtp(data, callback = (isOk, res) => {}) {
    Api.sendOtp({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.sendOtpSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  verifyOtp(data, callback = (isOk, res) => {}) {
    Api.verifyOtp({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.verifyOtpSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  otpLogin(data, callback = (isOk, res) => {}) {
    Api.otpLogin({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.otpLoginSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMeasuredataHistory(data, callback = (isOk, res) => {}) {
    Api.getMeasuredataHistory({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMeasuredataHistorySuccess) {
          if (result === undefined || result.length <= 0) {
            // app.dialog.alert(message, 'Alert');
          }
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  delPdf(data, callback = (isOk, res) => {}) {
    Api.delPdf({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.delPdfSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
        app.dialog.alert(message, "Alert");
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  roleFunctionList(data, callback = (isOk, res) => {}) {
    Api.roleFunctionList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.roleFunctionListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveClinicRole(data, callback = (isOk, res) => {}) {
    Api.saveClinicRole({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveClinicRoleSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getClinicRoleList(data, callback = (isOk, res) => {}) {
    Api.getClinicRoleList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getClinicRoleListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getRoleDetail(data, callback = (isOk, res) => {}) {
    Api.getClinicRoleDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getClinicRoleDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getStaffList(data, callback = (isOk, res) => {}) {
    Api.getStaffList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getStaffListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveStaff(data, callback = (isOk, res) => {}) {
    Api.saveStaff({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveStaffSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getStaffDetail(data, callback = (isOk, res) => {}) {
    Api.getStaffDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getStaffDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  addTimeslot(data, callback = (isOk, res) => {}) {
    Api.addTimeslot({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.addTimeslotSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }

        app.dialog.alert(message, "Alert");
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getTimeslot(data, callback = (isOk, res) => {}) {
    Api.getTimeslot({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getTimeslotSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  updateTimeslot(data, callback = (isOk, res) => {}) {
    Api.updateTimeslot({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.updateTimeslotSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
        app.dialog.alert(message, "Alert");
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getAvaliableDate(data, callback = (isOk, res) => {}) {
    Api.getAvaliableDate({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getAvaliableDateSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  queneCofirm(data, callback = (isOk, res) => {}) {
    app.preloader.show();
    Api.queneCofirm({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.queneCofirmSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
        // app.dialog.alert(message, 'Alert');
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  rescheduleConfirm(data, callback = (isOk, res) => {}) {
    Api.rescheduleConfirm({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.rescheduleConfirm) {
          callback(true, result);
        } else {
          callback(false, message);
        }
        app.dialog.alert(message, "Alert");
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getStepsHistory(data, callback = (isOk, res) => {}) {
    Api.getStepsHistory({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getStepsHistorySuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getSleepHistory(data, callback = (isOk, res) => {}) {
    Api.getSleepHistory({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getSleepHistorySuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getBloodGlucoseHistory(data, callback = (isOk, res) => {}) {
    Api.getBloodGlucoseHistory({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getBloodGlucoseHistorySuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getNonceNum(data, callback = (isOk, res) => {}) {
    Api.getNonceNum({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getNonceNumSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  verifyNonceNum(data, callback = (isOk, res) => {}) {
    Api.verifyNonceNum({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.verifyNonceNumSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveconsultationSummary(data, callback = (isOk, res) => {}) {
    Api.saveconsultationSummary({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveconsultationSummarySuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
        // app.dialog.alert(message, 'Alert');
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  userFullInfo(data, callback = (isOk, res) => {}) {
    Api.userFullInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.userFullInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getQBLoinPw(data, callback = (isOk, res) => {}) {
    Api.getQBLoinPw({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getQBLoinPwSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveNotes(data, callback = (isOk, res) => {}) {
    Api.saveNotes({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveNotesSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getNotes(data, callback = (isOk, res) => {}) {
    Api.getNotes({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getNotesSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  appointmentSchedule(data, callback = (isOk, res) => {}) {
    Api.appointmentSchedule({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.appointmentScheduleSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
        app.dialog.alert(message, "Alert");
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  newUserComing(data, callback = (isOk, res) => {}) {
    Api.newUserComing({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.newUserComingSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  listenerHeartbeat(data, callback = (isOk, res) => {}) {
    if (this.listenerHeartbeatLoading) return;
    this.listenerHeartbeatLoading = true;
    Api.listenerHeartbeat({
      data,
    })
      .then((res) => {
        this.listenerHeartbeatLoading = false;
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.newUserComingSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        console.log(res);
        callback(false, res);
        this.listenerHeartbeatLoading = false;
      });
  }

  getDrlist(data, callback = (isOk, res) => {}) {
    Api.getDrlist({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getDrlistSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  findClinicInfoByClinicid(data, callback = (isOk, res) => {}) {
    Api.findClinicInfoByClinicid({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getDrlistSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  handlyDr(data, callback = (isOk, res) => {}) {
    Api.handlyDr({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.handlyDrSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getForgetPWOTP(data, callback = (isOk, res) => {}) {
    Api.getForgetPWOTP({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getForgetPWOTPSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  resetPassword(data, callback = (isOk, res) => {}) {
    Api.resetPassword({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.resetPasswordSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  verifiFWOTP(data, callback = (isOk, res) => {}) {
    Api.verifiFWOTP({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.verifiFWOTPSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getDrProfile(data, callback = (isOk, res) => {}) {
    Api.getCoachProfile({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getCoachProfile) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveCoachProfile(data, callback = (isOk, res) => {}) {
    Api.saveCoachProfile({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveCoachProfile) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getClinicProfile(data, callback = (isOk, res) => {}) {
    Api.getClinicProfile({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getClinicProfile) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  clinicSettingEdit(data, callback = (isOk, res) => {}) {
    Api.clinicSettingEdit({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.clinicSettingEditSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  uploadBase64Img(data, callback = (isOk, res) => {}) {
    Api.uploadBase64Img({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.uploadBase64ImgSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  uploadBase64(data, callback = (isOk, res) => {}) {
    Api.uploadBase64({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.uploadBase64Success) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getLogouTime(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.getLogouTime({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getLogouTimeSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  generateWhatsappQr(code) {
    return Api.generateWhatsappQr(code);
  }

  delClinicRole(data, callback = (isOk, res) => {}) {
    Api.delClinicRole({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.delClinicRoleSuccess) {
          callback(true, result);
        } else {
          callback(false, result);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getCountryList(data, callback = (isOk, res) => {}) {
    if (AuthGuard.countryListCache && AuthGuard.countryListCache.length) {
      return callback(true, AuthGuard.countryListCache);
    }

    Api.getCountryList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.countryListSuccess) {
          AuthGuard.countryListCache = result;
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveGeneral(data, callback = (isOk, res) => {}) {
    Api.saveGeneral({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveGeneralSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getProvinceList(data, callback = (isOk, res) => {}) {
    Api.getProvinceList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.provinceListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getGeneralTimer(data, callback = (isOk, res) => {}) {
    Api.getGeneralTimer({
      data,
    })
      .then((res) => {
        // console.log(res.data)
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getGeneralTimerSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getCityList(data, callback = (isOk, res) => {}) {
    Api.getCityList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.cityListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  // getLatLong(data, callback = (isOk, res) => { }) {
  //     Api.getLatLong({
  //         data
  //     })
  //         .then((res) => {
  //             console.log(res)
  //             // const { data } = res;

  //             // const { result, message } = data;
  //             // if (data.status_code === RespondCode.cityListSuccess) {
  //             //     callback(true, result);
  //             // } else {
  //             //     callback(false, message);
  //             // }

  //         })
  //         .catch((res) => {
  //             callback(false, res);
  //         });
  // }

  addProvider(data, callback = (isOk, res) => {}) {
    Api.addProvider({
      data,
    })
      .then((res) => {
        const { data } = res;

        if (data.status_code === RespondCode.addProviderSuccess) {
          if (data.result.status_code === RespondCode.addProviderGoPayment) {
            callback(true, data.result.result);
          } else {
            callback(false, data.result.message);
          }
        } else {
          callback(false, data.message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  merchantDetail(data, callback = (isOk, res) => {}) {
    Api.merchantDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.merchantDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkoutInfo(data, callback = (isOk, res) => {}) {
    Api.checkoutInfo({
      data,
    })
      .then((res) => {
        console.log(res);
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.addProviderSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  reportsSettlementList(data, callback = (isOk, res) => {}) {
    Api.reportsSettlementList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.reportsSettlementListSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  reportsSubsidies(data, callback = (isOk, res) => {}) {
    Api.reportsSubsidies({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.reportsSubsidiesSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  reportsDispensary(data, callback = (isOk, res) => {}) {
    Api.reportsDispensary({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.reportsDispensarySuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  reportsSubExport(data, callback = (isOk, res) => {}) {
    Api.reportsSubExport({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.reportsSubExportSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  reportsDisExport(data, callback = (isOk, res) => {}) {
    Api.reportsDisExport({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.reportsDisExportSuccess) {
          callback(true, data);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  reportsExport(data, callback = (isOk, res) => {}) {
    Api.reportsExport({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.reportsExportSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  delStaff(data, callback = (isOk, res) => {}) {
    Api.delStaff({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.delStaffSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMedication(data, callback = (isOk, res) => {}) {
    Api.getMedication({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message, total } = data;
        if (data.status_code === RespondCode.getMedicationSuccess) {
          callback(true, result?.result || [], result?.total || 0);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveMedication(data, callback = (isOk, res) => {}) {
    Api.saveMedication({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.saveMedicationSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  editMedication(data, callback = (isOk, res) => {}) {
    Api.editMedication({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        console.log(res);
        if (data.status_code === RespondCode.editMedicationSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMedicationDetail(data, callback = (isOk, res) => {}) {
    Api.getMedicationDetail({
      data,
    })
      .then((res) => {
        console.log(res);
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMedicationDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  delMedication(data, callback = (isOk, res) => {}) {
    Api.delMedication({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.delMedicationSuccess) {
          callback(true, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMedicationCsvUrl(data, callback = (isOk, res) => {}) {
    Api.getMedicationCsvUrl({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMedicationCsvUrlSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  uploadMedicationCsv(data, callback = (isOk, res) => {}) {
    Api.uploadMedicationCsv({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.uploadMedicationCsv) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  findProfileForStaff(data, callback = (isOk, res) => {}) {
    Api.findProfileForStaff({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.findProfileForStaffSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  changeCoachPwd(data, callback = (isOk, res) => {}) {
    Api.changeCoachPwd({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.codeChangeCoachPwdSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  mcUrl(data, callback = (isOk, res) => {}) {
    Api.mcUrl({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.mcUrlSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  queryMc(data, callback = (isOk, res) => {}) {
    Api.queryMc({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.queryMcSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveMc(data, callback = (isOk, res) => {}) {
    Api.saveMc({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.saveMcSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  queryPaymentList(data, callback = (isOk, res) => {}) {
    Api.queryPaymentList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.queryPaymentListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  findProgramByUserIdAndProgramType(data, callback = (isOk, res) => {}) {
    Api.findProgramByUserIdAndProgramType({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (
          data.status_code ===
          RespondCode.findProgramByUserIdAndProgramTypeSuccess
        ) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  addProgramsByUserId(data, callback = (isOk, res, msg) => {}) {
    Api.addProgramsByUserId({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.addProgramsByUserIdSuccess) {
          callback(true, result, message);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  findProgramByMerchantId(data, callback = (isOk, res) => {}) {
    Api.findProgramByMerchantId({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.codeProductListRetrieved) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  googleConnect(data, callback = (isOk, res) => {}) {
    Api.googleConnect({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getGoogleAuthSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, "error request");
      });
  }

  googleConnectInfo(data, callback = (isOk, res) => {}) {
    Api.googleConnectInfo({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getGoogleInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, "error request");
      });
  }

  calendarSyncInfo(data, callback = (isOk, res) => {}) {
    Api.calendarSyncInfo({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getCalendarInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, "error request");
      });
  }

  calendarSyncOn(data, callback = (isOk, res) => {}) {
    Api.calendarSyncOn({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.calendarSyncOnSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, "error request");
      });
  }

  calendarSyncOff(data, callback = (isOk, res) => {}) {
    Api.calendarSyncOff({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.calendarSyncOffSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, "error request");
      });
  }

  calendarSyncUpdate(data, callback = (isOk, res) => {}) {
    Api.calendarSyncUpdate({ data })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.calendarUpdateSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, "error request");
      });
  }

  addUser(data, callback = (isOk, res) => {}) {
    app.preloader.show();
    Api.addUser({
      data,
    })
      .then((res) => {
        app.preloader.hide();
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.addUserSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        app.preloader.hide();
        callback(false, res);
      });
  }

  saveAppointmentDraft(data, callback = (isOk, res) => {}) {
    Api.saveAppointmentDraft({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.saveAppointmentDraft) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  diagnosisSearch(data, callback = (isOk, res) => {}) {
    Api.diagnosisSearch({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.diagnosisSearchSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  findMedicationAll(data, callback = (isOk, res) => {}) {
    Api.findMedicationAll({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.findMedicationAllSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  mcDelete(data, callback = (isOk, res) => {}) {
    Api.mcDelete({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.mcDeleteSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  mcUpdate(data, callback = (isOk, res) => {}) {
    Api.mcUpdate({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.mcUpdateSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  AGTken(data, callback = (isOk, res) => {}) {
    Api.AGTken({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.AGTkenSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveAppointment(data, callback = (isOk, res) => {}) {
    Api.saveAppointment({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.saveAppointmentSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getNationalityList(data, callback = (isOk, res) => {}) {
    Api.nationalityList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.nationalityListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  dateTimefilter(data, callback = (isOk, res) => {}) {
    Api.dateTimefilter({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.dateTimefilterSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  uploadDrAvatar(data, callback = (isOk, res) => {}) {
    Api.uploadDrAvatar({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.uploadDrAvatarSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  logoutTimeSetting(data, callback = (isOk, res) => {}) {
    Api.logoutTimeSetting({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.logoutTimeSettingSuccess) {
          callback(true, data);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  async checkIpWhiteList() {
    let valid_ip = true;
    let ips = IP_WHITELIST || "";
    if (ips) {
      if (!this.localIpV4) {
        this.localIpV4 = await publicIp.v4();
      }
      // let ipv6 = await publicIp.v6();
      let ipsArr = ips.split(",");
      valid_ip = ipsArr.includes(this.localIpV4);
    } else {
      this.localIpV4 = "";
    }
    if (!valid_ip) this.clearDrDashboardAuthData();
    // console.log(valid_ip);
    return valid_ip;
  }

  uploadTwilioLog(data, callback = (isOk, res) => {}) {
    Api.uploadTwilioLog({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.uploadTwilioLogSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
  getUserLogoutTime(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.getUserLogoutTime({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getUserLogoutTimeSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getGeneralForCoach(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.getGeneralForCoach({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getGeneralForCoachSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  checkNotificationMobile(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.checkNotificationMobile({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.checkNotificationMobileSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  sendSmsOtpDirect(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.sendSmsOtpDirect({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.sendSmsOtpDirectSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  verifyCoachMobile(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.verifyCoachMobile({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.verifyCoachMobileSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  updateMobileForCoach(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.updateMobileForCoach({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.updateMobileForCoachSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  notificationDeleteById(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.notificationDeleteById({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.notificationDeleteByIdSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getLabReports(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.getLabReports({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, total, message } = data;
        if (data.status_code === RespondCode.getLabReportsSuccess) {
          callback(true, { result, total });
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  updateLabReports(datas, callback = (isOk, res) => {}) {
    let self = this;
    let ids = JSON.parse(datas.ids);
    Api.updateLabReports({
      data: datas,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.updateLabReportsSuccess) {
          if (ids.length === result.length || datas.status == "merge") {
            app.dialog.alert(message, "Success");
          } else {
            app.dialog.alert(message, "Error");
          }
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  uploadLabReportsCheck(datas, callback = (isOk, res) => {}) {
    let self = this;
    let ids = JSON.parse(datas.ids);
    Api.uploadLabReportsCheck({
      data: datas,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.uploadLabReportsCheckSuccess) {
          // if (ids.length === result.length||datas.status=='merge' ){
          //     app.dialog.alert(message, 'Success');
          // }else{
          //     app.dialog.alert(message, 'Error');
          // }
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  updateLabReportsStatusCheck(datas, callback = (isOk, res) => {}) {
    let self = this;
    let ids = JSON.parse(datas.ids);
    Api.updateLabReportsStatusCheck({
      data: datas,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (
          data.status_code === RespondCode.updateLabReportsStatusCheckSuccess
        ) {
          // if (ids.length === result.length||datas.status=='merge' ){
          //     app.dialog.alert(message, 'Success');
          // }else{
          //     app.dialog.alert(message, 'Error');
          // }
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  generateE3Token(data, callback = (isOk, res) => {}) {
    Api.generateE3Token({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.generateE3TokenSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  generateE3Salt(data, callback = (isOk, res) => {}) {
    Api.generateE3Salt({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.generateE3SaltSuccess) {
          callback(true, result);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  removeJoinedProgram(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.removeJoinedProgram({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.removeJoinedProgramSuccess) {
          callback(true, data);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  loginSendEmail(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.loginSendEmail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.loginSendEmailSuccess) {
          callback(true, data);
        } else {
          callback(false, data);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getAllDrInfo(data, callback = (isOk, res) => {}) {
    Api.getAllDrInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.getAllDrInfoSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  chatLog(data, callback = (isOk, res) => {}) {
    Api.chatLog({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (data.status_code === RespondCode.chatLogSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  reportAddFile(data, callback = (isOk, res) => {}) {
    let self = this;
    Api.reportAddFile({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;

        if (
          data.status_code === RespondCode.reportAddFileSuccess ||
          data.status_code === RespondCode.reportAddFileSuccessV2
        ) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getLoginUserInfo(data, callback = (isoK, res) => {}) {
    Api.getLoginUserInfo({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getLoginUserInfoSuccess) {
          this.SetAuthenticated(result);
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getReportMedicationNameList(data, callback = (isoK, res) => {}) {
    Api.getReportMedicationNameList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (
          data.status_code === RespondCode.getReportMedicationNameListSuccess
        ) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  searchPatientList(data, callback = (isoK, res) => {}) {
    Api.advanceSearch({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.searchPatientSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getPatientDateList(data, callback = (isoK, res) => {}) {
    Api.getPatientDateList({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getPatientDateListSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  getMeasurementsDetail(data, callback = (isoK, res) => {}) {
    Api.getMeasurementsDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMeasurementsDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  saveMeasurementsDetail(data, callback = (isoK, res) => {}) {
    Api.saveMeasurementsDetail({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.getMeasurementsDetailSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }

  generateMeasurementsReport(data, callback = (isoK, res) => {}) {
    Api.generateMeasurementsReport({
      data,
    })
      .then((res) => {
        const { data } = res;
        const { result, message } = data;
        if (data.status_code === RespondCode.generateMeasurementsReportSuccess) {
          callback(true, result);
        } else {
          callback(false, message);
        }
      })
      .catch((res) => {
        callback(false, res);
      });
  }
}

export default new AuthGuard();
