import {useEffect, useState} from 'react';
import {Participant} from 'twilio-video';

export default function useParticipantNetworkQualityLevel(participant: Participant) {
    let lv: any = 0;
    if (typeof participant !== "undefined") {
        lv = participant.networkQualityLevel
    }

    const [networkQualityLevel, setNetworkQualityLevel] = useState(lv);

    useEffect(() => {
        if (typeof participant == "undefined") {
            setNetworkQualityLevel(0);
            return;
        }

        const handleNewtorkQualityLevelChange = (newNetworkQualityLevel: number) =>
            setNetworkQualityLevel(newNetworkQualityLevel);

        setNetworkQualityLevel(participant.networkQualityLevel);
        participant.on('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        return () => {
            participant.off('networkQualityLevelChanged', handleNewtorkQualityLevelChange);
        };
    }, [participant]);

    return networkQualityLevel;
}
