import React, { Component } from "react";
import md5 from "js-md5";
import { f7 as app, Button, Row, Col, Link, Input, Icon } from "framework7-react";
import { ROUTES } from "../commons/Constants";
import { filter } from 'fuzzy';
import AuthGuard from "../services/AuthGuard";
import MultiSelect from "@khanacademy/react-multi-select";
import TouchRippleComponent from "framework7/components/touch-ripple/touch-ripple";
import { ShowToast, isFunction, justFloat, showDate } from "../commons/Helpers";
import events from "../services/events";

let itv = null;

class VideoCallForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medicationList: [],
      medicationOptions: [],
      medicationLastSelected: [],
      medicationSelected: [],
      lastDiagnosisSearch: '',
      isDiagnosisSearchTyping: false,
      diagnosisList: [],
      diagnosisOptions: [],
      diagnosisSelected: [],
      showHistoryList: true,
      formHash: '',
      history_list: [],
      user_info: {
        name: '',
        email: '',
        mobile_number: '',
        id_number: '',
        drug_allergy: '',
      },
    };
  }

  async btnViewVitalGraph() {
    await this.closeVideoCallPopUp();
    app.views.main.router.navigate(ROUTES.patientdetail, {
      props: { userId: AuthGuard.videoCallInfo.chat.user_profile_id },
      reloadCurrent: true,
      animate: false,
    });
  };

  async btnViewPatientInfo() {
    await this.closeVideoCallPopUp();
    app.views.main.router.navigate(ROUTES.patientInfo, {
      props: { user_profile_id: AuthGuard.videoCallInfo.chat.user_profile_id },
      reloadCurrent: true,
      animate: false,
    });
  }

  async btnViewConsultationSummary(props) {
    await this.closeVideoCallPopUp();
    let currentRoute = app.views.main.router.currentRoute.path
    app.views.main.router.clearPreviousHistory()
    if (currentRoute === ROUTES.consultationSummary) {
      events.emit('SetConsultationSummaryVideoCallID', { ...props });
    } else {
      app.views.main.router.navigate(ROUTES.consultationSummary, {
        props: props,
        reloadCurrent: true
      });
    }
  }

  closeVideoCallPopUp() {
    return new Promise(resolve => {
      if (this.state.formHash == this.getFormHash()) {
        resolve()
        app.popup.close('.consultationDetailPage-popup');
        return
      }
      app.dialog.confirm('Please save changes before leave', () => {
        this.btnSave(() => {
          resolve()
          app.popup.close('.consultationDetailPage-popup');
        })
      })
    });
  }

  checkChangesBeforeCloseVideo() {
    return new Promise(resolve => {
      if (this.state.formHash == this.getFormHash()) {
        resolve()
        return
      }
      app.dialog.confirm('Please save changes before leave', () => {
        this.btnSave(() => {
          resolve()
        })
      })
    });
  }

  getFormHash() {
    let formData = app.form.convertToData('#save-form');
    let _diagnosisSelected = this.state.diagnosisSelected && this.state.diagnosisSelected.length > 0 ? this.state.diagnosisSelected : ''
    let _medicationList = this.state.medicationList && this.state.medicationList.length > 0 ? this.state.medicationList : ''
    formData.diagnosis = _diagnosisSelected ? JSON.stringify(this.state.diagnosisSelected) : ''
    formData.medication_list = _medicationList ? JSON.stringify(this.state.medicationList) : ''
    return md5(JSON.stringify(formData))
  }

  setFormHash() {
    this.setState({
      formHash: this.getFormHash(),
    })
  }

  btnSave(resolve) {
    var formData = app.form.convertToData('#save-form');
    let _diagnosisSelected = this.state.diagnosisSelected && this.state.diagnosisSelected.length > 0 ? this.state.diagnosisSelected : ''
    let _medicationList = this.state.medicationList && this.state.medicationList.length > 0 ? this.state.medicationList : ''
    formData.diagnosis = _diagnosisSelected ? JSON.stringify(this.state.diagnosisSelected) : ''
    formData.medication_list = _medicationList ? JSON.stringify(this.state.medicationList) : ''
    formData.id = AuthGuard.videoCallInfo.videoCallId
    if (formData.temp != '' && !justFloat(formData.temp)) {
      return app.dialog.alert('Temperature should be a number');
    }
    if ((formData.bp_hight != '' && !justFloat(formData.bp_hight)) || (formData.bp_low != '' && !justFloat(formData.bp_low))) {
      return app.dialog.alert('BP should be a number');
    }
    if (formData.spo2 != '' && !justFloat(formData.spo2)) {
      return app.dialog.alert('Spo2 should be a number');
    }
    if (formData.weight != '' && !justFloat(formData.weight)) {
      return app.dialog.alert('Weight should be a number');
    }

    app.preloader.show();
    AuthGuard.saveAppointmentDraft(formData, (ok, res) => {
      app.preloader.hide()
      if (!ok) {
        if (typeof res === 'string') {
          return app.dialog.alert(res)
        }
        return app.dialog.alert('please check your input')
      }
      app.dialog.alert('save success',()=>{
        this.setState({
          formHash: this.getFormHash(),
        }, () => {
          if (typeof resolve != 'undefined' && isFunction(resolve)) {
            resolve()
          }
        })
      })
    })
  }

  getSummaryinfo() {
    let self = this
    AuthGuard.getVideoCallInfo({ id: AuthGuard.videoCallInfo.videoCallId }, (isok, res) => {
      if (!isok) {
        return setTimeout(this.setFormHash.bind(this), 1000)
      }

      let filled = Object.keys(app.form.convertToData('#save-form'))
      if (res.doctor_notes) {
        filled.doctor_notes = res.doctor_notes
      }
      app.form.fillFromData('#save-form', filled)
      setTimeout(this.setFormHash.bind(this), 1000)

      if (res.diagnosis && res.diagnosis.length && res.diagnosis.length >= 1) {
        self.setState({
          diagnosisList: res.diagnosis.map((i => {
            let item = {
              label: i.name,
              value: i.id,
            }
            return item
          }))
        }, this.setFormHash.bind(this))
      }

      if (res.transaction_id && res.userQueueId) {
        AuthGuard.queryPaymentList({
          transaction_id: res.transaction_id,
          user_queue_id: res.userQueueId
        }, (isok, res) => {
          if (isok && res.medication) {
            let selected = res.medication.map(i => i.id);
            let see_more = false
            let medicationList = AuthGuard.listMedications
              .filter(i => selected.includes(i.id))
              .map((i, idx) => {
                let prev = this.state.medicationList.filter(ex => ex.medication_list_id == i.id)
                if (prev.length) {
                  let rtn = prev.pop()
                  return rtn
                }
                let saved = res.medication.filter(s => s.id == i.id).pop()
                let item = {
                  medication_list_id: saved.id,
                  name: saved.name,
                  selling_price: saved.unit_price,
                  purpose: saved.purpose,
                  precautions: saved.precautions,
                  quantity: saved.qty,
                  dosage: saved.dosage,
                  inventory_id: saved.inventory_id,
                  inventory_id: i.inventory_id,
                  discount: saved.discount,
                  see_more: !see_more,
                  inventoryID: saved.inventoryID,
                  expiry_date: showDate(saved.expiry_date,2),
                  inventory_type: saved.inventory_type,
                  frequency: saved.frequency,
                }
                see_more = true
                this.getMedTotalPrice(item)
                return item
              })

            this.setState({
              medicationSelected: selected,
              medicationList: medicationList
            }, this.setFormHash.bind(this))
          }
        })
      }
    })
  }

  componentDidMount() {
    this.getVideoInfo()
    events.on('StartVideoCall', this.getVideoInfo.bind(this))
  }

  componentWillUnmount() {
    if (itv) clearInterval(itv)
  }

  getVideoInfo() {
    if (itv) clearInterval(itv)
    itv = setInterval(() => {
      this.getMedicationOptions()
      this.getPatientInfo()
      if (AuthGuard.listMedications && AuthGuard.listMedications.length && Object.keys(AuthGuard.videoCallInfo).length) {
        clearInterval(itv)
      }
    }, 1000)
    this.getSummaryinfo()
  }

  endVideoCall() {
    let filled = Object.keys(app.form.convertToData('#save-form'))
    let cleared = {}
    filled.map(i => cleared[i] = '')
    app.form.fillFromData('#save-form', cleared)

    this.setState({
      medicationList: [],
      medicationOptions: [],
      medicationLastSelected: [],
      medicationSelected: [],
      lastDiagnosisSearch: '',
      isDiagnosisSearchTyping: false,
      diagnosisList: [],
      diagnosisOptions: [],
      diagnosisSelected: [],
      showHistoryList: true,
      formHash: this.getFormHash(),
      history_list: [],
      user_info: {
        name: '',
        email: '',
        mobile_number: '',
        id_number: '',
        drug_allergy: '',
      },
    })
  }

  getPatientInfo() {
    if (!Object.keys(AuthGuard.videoCallInfo).length) {
      return;
    }
    let userProfileId = AuthGuard.videoCallInfo.chat.user_profile_id
    this.setState({
      userProfileId: userProfileId
    })

    AuthGuard.userFullInfo({
      user_profile_id: userProfileId
    }, (isOk, res) => {
      if (isOk) {
        let { history_list, user_info } = res;
        // history_list = [
        //   {
        //     id: 1,
        //     doctor_notes: 'Velit commodo ocMagna nisi eu consectetur non proident ad duis velit quis veniam nostrud est.Officia adipisicing enim nostrud esse culpa nostrud sit qui id nulla Lorem.caecat quis aliqua minim ipsum.Non ea exercitation nulla consequat aute esse enim do minim.',
        //     date: '31 Aug 2020',
        //     time: '01:29:42 - 01:29:42',
        //     diagnosis: [
        //       {
        //         id: 1,
        //         name: 'Proident ad minim eu veniam adEt Lorem fugiat cillum nisi aliqua qui et adipisicing.Cillum id est cupidatat sint excepteur excepteur qui duis adipisicing deserunt.pisicing labore sint eiusmod ipsum culpa tempor sit reprehenderit dolore.',
        //       }
        //     ]
        //   },
        //   {
        //     id: 2,
        //     doctor_notes: 'hahah',
        //     date: '31 Aug 2020',
        //     time: '01:29:42 - 01:29:42',
        //     diagnosis: [
        //       {
        //         id: 2,
        //         name: 'haha',
        //       }
        //     ]
        //   },
        //   {
        //     id: 3,
        //     doctor_notes: 'Velit commodo occaecat quis aliqua minim ipsum.Non ea exercitation nulla consequat aute esse enim do minim.',
        //     date: '31 Aug 2020',
        //     time: '01:29:42 - 01:29:42',
        //     diagnosis: [
        //       {
        //         id: 3,
        //         name: 'Proident ad minim eu veniam adipisicing labore sint eiusmod ipsum culpa tempor sit reprehenderit dolore.',
        //       }
        //     ]
        //   }
        // ];
        this.setState({
          history_list: history_list,
          user_info: user_info
        })
      }
    })
  }

  getMedicationOptions() {
    if (!AuthGuard.listMedications || !AuthGuard.listMedications.length) {
      return;
    }

    let medicationOptions = AuthGuard.listMedications.map(i => {
      return {
        label: i.name,
        value: i.id
      }
    })
    this.setState({
      medicationOptions: medicationOptions
    })
  }

  onMedicationSearch(options, txt) {
    let medicationOptions = filter(txt, AuthGuard.listMedications, {
      pre: '<i>',
      post: '</i>',
      extract: el => el.name ? el.name : ''
    }).slice(0, 10).map(i => {
      return {
        label: i.original.name,
        value: i.original.id
      }
    })
    return medicationOptions
  }

  onMedPropChange(prop, idx, v) {
    this.state.medicationList[idx][prop] = v.target.value
    this.getMedTotalPrice(this.state.medicationList[idx], idx)
  }

  onMedSeeMoreClick(idx) {
    this.state.medicationList[idx].see_more = !this.state.medicationList[idx].see_more
    this.setState({ medicationList: this.state.medicationList })
  }

  getMedTotalPrice(med, idx) {
    if (med.discount < 0) {
      med.discount = 0;
    }
    if (med.discount > 100) {
      med.discount = 100;
    }
    let total_price = med.quantity * med.selling_price * (1 - med.discount / 100);
    if (isNaN(total_price)) {
      ShowToast(app, 'Please input a valid number')
      return
    }
    med.total_price = Math.ceil(total_price * 100) / 100;
    if (typeof idx !== 'undefined') {
      let medicationList = this.state.medicationList;
      medicationList[idx] = med;
      this.setState({
        medicationList: medicationList
      })
    }
  }

  onMedicationChange(selected) {
    let medicationList = AuthGuard.listMedications
      .filter(i => selected.includes(i.id))
      .map((i, idx) => {
        let see_more = false
        if (this.state.medicationSelected.length < selected.length) {
          see_more = !this.state.medicationSelected.includes(i.id)
        } else {
          see_more = selected[0] && selected[0] == i.id
        }
        let prev = this.state.medicationList.filter(ex => ex.medication_list_id == i.id)
        if (prev.length) {
          let rtn = prev.pop()
          rtn.see_more = see_more
          return rtn
        }
        let item = {
          medication_list_id: i.id,
          name: i.name,
          selling_price: i.selling_price,
          purpose: i.purpose,
          precautions: i.precautions,
          quantity: i.quantity,
          dosage: i.dosage,
          inventory_id: i.inventory_id,
          discount: 0,
          see_more: see_more,
          inventoryID: i.inventory_id,
          expiry_date: showDate(i.expiry_date,2),
          inventory_type: i.inventory_type,
          frequency: i.frequency,
        }
        this.getMedTotalPrice(item)
        return item
      })
    
    medicationList = medicationList.reverse().filter(i => i.see_more)
      .concat(medicationList.reverse().filter(i => !i.see_more))

    this.setState({
      medicationSelected: selected,
      medicationList: medicationList
    })
  }

  deleteMedicationSelected(deleteId) {
    let selected = this.state.medicationSelected.filter(i => i !== deleteId)
    this.onMedicationChange(selected)
  }

  onDiagnosisSearch(options, txt) {
    if (txt === this.state.lastDiagnosisSearch) {
      return this.state.diagnosisOptions
    }
    this.setState({
      lastDiagnosisSearch: txt,
      isDiagnosisSearchTyping: true,
    })
    setTimeout(this.doDiagnosisSearch.bind(this, txt), 500)
    return this.state.diagnosisOptions;
  }

  doDiagnosisSearch(txt) {
    if (txt !== this.state.lastDiagnosisSearch) {
      return this.state.diagnosisOptions;
    }
    this.setState({
      isDiagnosisSearchTyping: false
    })
    let diagnosisOptions = JSON.parse(JSON.stringify(this.state.diagnosisList));
    AuthGuard.diagnosisSearch({
      code: txt
    }, (ok, res) => {
      if (!ok || this.state.lastDiagnosisSearch !== res.code) {
        return
      }

      res.list.slice(0, 100).map(i => {
        if (this.state.diagnosisSelected.includes(i.id)) {
          return;
        }
        diagnosisOptions.push({
          label: i.display,
          value: i.id,
        })
      })

      this.setState({
        diagnosisOptions: diagnosisOptions,
      })
    })
    return diagnosisOptions;
  }

  onDiagnosisChange(selected) {
    this.setState({
      diagnosisSelected: selected,
      diagnosisList: this.state.diagnosisOptions.filter(i => selected.includes(i.value))
    })
  }

  deleteDiagnosisSelected(deleteId) {
    let selected = this.state.diagnosisSelected.filter(i => i !== deleteId)
    this.onDiagnosisChange(selected)
  }

  render() {
    return (
      <div className="videoCallForm">
        {/*<br/><br/>*/}
        {/*<div>*/}
        {/*  <div className="footerBtn">*/}
        {/*    <Button*/}
        {/*      className="navbarBtn btn-large outline-blue-btn"*/}
        {/*      onClick={this.btnViewVitalGraph.bind(this)}*/}
        {/*      raised*/}
        {/*    >*/}
        {/*      View Vital Graph*/}
        {/*    </Button>*/}
        {/*    <Button*/}
        {/*      className="navbarBtn btn-large fill-blue-btn"*/}
        {/*      onClick={this.btnSave.bind(this)}*/}
        {/*      raised*/}
        {/*      fill*/}
        {/*    >*/}
        {/*      Save*/}
        {/*    </Button>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="gray-block">
          <Row className="block_title">
            <Col width="50">Patient Details</Col>
            <Col width="50">
              <Row style={{ float: "right" }}>
                <Col>
                  <Link
                    className="txt-green canClick"
                    onClick={this.btnViewPatientInfo.bind(this)}
                  >
                    View full patient information
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="cell_list">
            <Col width="50">
              <Row className="cell_item">
                <Col width="30">Name: </Col>
                <Col width="70"> {this.state.user_info.name} </Col>
              </Row>
              <Row className="cell_item">
                <Col width="30">Identification No.: </Col>
                <Col width="70"> {this.state.user_info.id_number} </Col>
              </Row>
            </Col>
            <Col width="50">
              <Row className="cell_item">
                <Col width="30">Mobile No.: </Col>
                <Col width="70"> {this.state.user_info.mobile_number} </Col>
              </Row>
              <Row className="cell_item">
                <Col width="30">Email: </Col>
                <Col width="70"> {this.state.user_info.email} </Col>
              </Row>
              <Row className="cell_item">
                <Col width="30">Drug Allergy: </Col>
                <Col width="70">
                  <span className="txt-red">{this.state.user_info.drug_allergy}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <form className="gray-block" id='save-form'>
          <Row>
            <Col width="50">
              <Row className="block_title">
                <Col width="50">Consultation Notes</Col>
              </Row>
              <Row>
                <Col width="50">
                  {/* <div className="subTitle">1 Apr 2020 ,12:00 pm</div> */}
                </Col>
              </Row>
              <Row className="cell_list">
                <Col width="50">
                  <textarea name="doctor_notes" className="formTextarea mt20" rows="20"></textarea>
                </Col>
                <Col width="50">
                  <Row className="cell_list mt20">
                    <Col width="25" className="formLabel">
                      Temp:
                    </Col>
                    <Col width="35">
                      <input
                        type="text"
                        name="temp"
                        className="formInput input-with-value input-invalid"
                        placeholder="e.g 37.5"
                      />
                    </Col>
                    <Col width="40"></Col>
                  </Row>
                  <Row className="cell_list">
                    <Col width="25" className="formLabel">
                      BP:
                    </Col>
                    <Col width="35">
                      <Row noGap>
                        <Col width="45">
                          <input
                            type="text"
                            name="bp_hight"
                            className="formInput input-with-value input-invalid"
                            placeholder="134"
                          />
                        </Col>
                        <Col width="10" style={{ lineHeight: '2em' }}>&nbsp;/&nbsp;</Col>
                        <Col width="45">
                          <input
                            type="text"
                            name="bp_low"
                            className="formInput input-with-value input-invalid"
                            placeholder="80"
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col width="40"></Col>
                  </Row>
                  <Row className="cell_list">
                    <Col width="25" className="formLabel">
                      SPO2:
                    </Col>
                    <Col width="35">
                      <input
                        type="text"
                        name="spo2"
                        className="formInput input-with-value input-invalid"
                        placeholder="e.g 95"
                      />
                    </Col>
                    <Col width="40"></Col>
                  </Row>
                  <Row className="cell_list">
                    <Col width="25" className="formLabel">
                      Weight:
                    </Col>
                    <Col width="35">
                      <input
                        type="text"
                        name="weight"
                        className="formInput input-with-value input-invalid"
                        placeholder="e.g 50.0"
                      />
                    </Col>
                    <Col width="40"></Col>
                  </Row>
                </Col>
              </Row>
              <Row className="addDiagnosisRow">
                <Col width="70">
                  <Row className="cell_list mt20" noGap>
                    <Col width="30" className="formLabel">
                      Diagnosis:
                    </Col>
                    <Col width="70" className="multiSelect disableSelectAll">
                      <MultiSelect
                        name="diagnosis"
                        options={this.state.diagnosisOptions}
                        selected={this.state.diagnosisSelected}
                        filterOptions={this.onDiagnosisSearch.bind(this)}
                        onSelectedChanged={this.onDiagnosisChange.bind(this)}
                      ></MultiSelect>
                    </Col>
                  </Row>
                </Col>
                <Col width="30"></Col>
              </Row>
              {this.state.diagnosisList.map((item, index) =>
                <Row>
                  <Col width="70">
                    <Row>
                      <Col width="30"></Col>
                      <Col width="70">{item.label}</Col>
                    </Row>
                  </Col>
                  <Col width="30">
                    <Button onClick={this.deleteDiagnosisSelected.bind(this, item.value)}>
                      <Icon f7="xmark_circle" size="20px" color="red"></Icon>
                    </Button>
                  </Col>
                </Row>
              )}
            </Col>
            <Col width="50">
              <Row className="block_title">
                <Col width="50">Medication</Col>
              </Row>
              <Row className="cell_list">
                <Col width="25" className="formLabel">
                  Search Name:
                </Col>
                <Col width="60" className="multiSelect disableSelectAll">
                  <MultiSelect
                    name="medication_list"
                    options={this.state.medicationOptions}
                    selected={this.state.medicationSelected}
                    filterOptions={this.onMedicationSearch.bind(this)}
                    onSelectedChanged={this.onMedicationChange.bind(this)}
                  ></MultiSelect>
                </Col>
                <Col width="15"></Col>
              </Row>
              <div className="greenLine mt10"></div>
              <div className="searchMedicationResults">
                {this.state.medicationList.map((item, idx) => {
                  const rows = []
                  if (item.see_more) {
                    rows.push(
                      <Row className="cell_list">
                        <Col width="25" className="formLabel">
                          Inventory ID:
                          </Col>
                        <Col width="60">
                          <input
                            type="text"
                            disabled
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g 95"
                            value={item.inventory_id}
                          />
                        </Col>
                        <Col width="15"></Col>
                      </Row>
                    )
                    rows.push(
                      <Row className="cell_list">
                        <Col width="25" className="formLabel">
                          Unit Price $:
                          </Col>
                        <Col width="60">
                          <input
                            type="text"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g 95"
                            value={item.selling_price}
                            onChange={this.onMedPropChange.bind(this, 'selling_price', idx)}
                          />
                        </Col>
                        <Col width="15"></Col>
                      </Row>
                    )
                    rows.push(
                      <Row className="cell_list">
                        <Col width="25" className="formLabel">
                          Total Qty:
                          </Col>
                        <Col width="60">
                          <input
                            type="text"
                            className="formInput input-with-value input-invalid"
                            placeholder="e.g 5"
                            value={item.quantity}
                            onChange={this.onMedPropChange.bind(this, 'quantity', idx)}
                          />
                        </Col>
                        <Col width="15"></Col>
                      </Row>
                    )
                    rows.push(
                      <Row className="cell_list">
                        <Col width="25" className="formLabel">
                          Purpose:
                          </Col>
                        <Col width="60">
                          <input
                            type="text"
                            className="formInput input-with-value input-invalid"
                            placeholder="purpose"
                            value={item.purpose}
                            onChange={this.onMedPropChange.bind(this, 'purpose', idx)}
                          />
                        </Col>
                        <Col width="15"></Col>
                      </Row>
                    )
                    rows.push(
                      <Row className="cell_list">
                        <Col width="25" className="formLabel">
                          Precautions:
                          </Col>
                        <Col width="60">
                          <input
                            type="text"
                            className="formInput input-with-value input-invalid"
                            placeholder="precautions"
                            value={item.precautions}
                            onChange={this.onMedPropChange.bind(this, 'precautions', idx)}
                          />
                        </Col>
                        <Col width="15"></Col>
                      </Row>
                    )
                    rows.push(
                      <Row className="cell_list">
                        <Col width="25" className="formLabel">
                          Discount %:
                          </Col>
                        <Col width="60">
                          <input
                            type="text"
                            className="formInput input-with-value input-invalid"
                            value={item.discount}
                            onChange={this.onMedPropChange.bind(this, 'discount', idx)}
                            placeholder="e.g 10"
                          />
                        </Col>
                        <Col width="15"></Col>
                      </Row>
                    )
                  }
                  return (
                    <div key={item.id}>
                      <div className="mt20">
                        <Row className="cell_list">
                          <Col width="25" className="formLabel">
                            Name:
                          </Col>
                          <Col width="60">
                            <input
                              type="text"
                              className="formInput input-with-value input-invalid"
                              placeholder="name"
                              value={item.name}
                              onChange={this.onMedPropChange.bind(this, 'name', idx)}
                            />
                          </Col>
                          <Col width="15">
                            <Button onClick={this.deleteMedicationSelected.bind(this, item.medication_list_id)}>
                              <Icon f7="xmark_circle" size="20px" color="red"></Icon>
                            </Button>
                          </Col>
                        </Row>
                        <Row className="cell_list">
                          <Col width="25" className="formLabel">
                            Dosage:
                          </Col>
                          <Col width="60">
                            <input
                              type="text"
                              className="formInput input-with-value input-invalid"
                              placeholder="dosage"
                              value={item.dosage}
                              onChange={this.onMedPropChange.bind(this, 'dosage', idx)}
                            />
                          </Col>
                          <Col width="15"></Col>
                        </Row>
                        {rows}
                        <Row className="cell_list">
                          <Col width="25" className="formLabel">
                            Price:
                          </Col>
                          <Col width="60">
                            <input
                              disabled
                              type="text"
                              value={item.total_price}
                              className="formInput input-with-value input-invalid"
                            />
                          </Col>
                          <Col width="15">
                            {/* id != 0 && see_more = false */}
                            {(idx && !item.see_more) && <Link className="txt-green canClick"
                              onClick={this.onMedSeeMoreClick.bind(this, idx)}>
                              View More
                            </Link>}
                          </Col>
                        </Row>
                      </div>
                      <div className="greenLine"></div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </form>
        <div className="gray-block consulation-history">
          <Row className="block_title">
            <Col width="50">Consultation History</Col>
            <Col width="50" className="showMoreConsulationHistory">
              <Button onClick={() => this.setState({ showHistoryList: !this.state.showHistoryList })}>
                <Icon f7={this.state.showHistoryList ? "minus_square" : "plus_app"}></Icon>
              </Button>
            </Col>
          </Row>
          <Row className="cell_list">
            <Col>
              {this.state.showHistoryList && this.state.history_list
                ? this.state.history_list.map((item, index) => {
                  return (
                    <div key={item.id} className="video-call-form list-consultation">
                      <div className="item-header">
                        <div className="item-header-left">
                          <div className="item-header-left-info">
                            <div className="consultation-date fwb">
                              {item.time}
                            </div>
                            <div className="consultation-time txt-gray">
                              {item.date}
                            </div>
                          </div>
                          <div>
                            <div className="content">
                              <div className="txt-notes-title">Diagnosis:</div>
                              <div className="diagnosis">{item.diagnosis ? item.diagnosis.map((diagnosis_items, diagnosis_index) => { return (<span key={diagnosis_items.id} style={{ marginRight: '10px' }}>{diagnosis_items.name}</span>) }) : "-"}</div>
                            </div>
                            <div className="content">
                              <div className="txt-notes-title">Notes:</div>
                              <div className="notes">{item.doctor_notes ? item.doctor_notes : "-"}</div>
                            </div>
                          </div>
                        </div>
                        <Link
                          className="outline-blue-btn btn-normal"
                          onClick={this.btnViewConsultationSummary.bind(this, { videoCallId: item.id })}
                        >
                          View Summary
                          </Link>
                        {/* <Button className="outline-blue-btn" onClick={this.ViewSummary.bind(this,item.id)}>View Summary </Button> */}
                      </div>
                      <div className="attachment">
                        Attachments:
                      {item.documents && item.documents.length > 0 ? (
                          item.documents.map((iitem, iindex) => {
                            return (
                              <span key={iitem.id} className="txt-green attachment-item">
                                {iitem.file_name}
                              </span>
                            );
                          })
                        ) : (
                            <span className="txt-green">-</span>
                          )}
                      </div>
                    </div>
                  );
                })
                : ""}
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default VideoCallForm