import React, { Component } from 'react';
import DropzoneComponent from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server'
import { ApiUrl, RespondCode, XAPP } from '../commons/Constants';
import AuthGuard from '../services/AuthGuard'
import { ShowToast } from '../commons/Helpers'
import { f7 as app, Icon  , Button} from 'framework7-react';


var dropzone = '';
class UploadFiles extends Component {
    constructor(props) {
        super(props);
        // console.log(props);
        this.state = {  
            onLoading:false
        }

        this.handleAddedfile = this.handleAddedfile.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        // this.callbackDropArray = [() => console.log('Hi!'), () => console.log('Ho!')];
        this.handleSuccess = this.handleSuccess.bind(this);
        this.handleError = this.handleError.bind(this);
        this.uploadprogress = this.uploadprogress.bind(this);
        this.handleComplete = this.handleComplete.bind(this);
        this.handleCanceled = this.handleCanceled.bind(this);
        this.handleSending = this.handleSending.bind(this);

        this.componentConfig = {
            iconFiletypes: ['.jpg', '.png', '.gif'],
            showFiletypeIcon: true,
            postUrl: ApiUrl.uploadFiles,
            dropzoneSelector: 'div.' + props.dropzoneSelector,
        };
        this.djsConfig = {
            autoProcessQueue: true,
            addRemoveLinks: true,
            maxFiles: props.maxFiles,
            maxFilesize:5,
            // acceptedFiles: "image/jpeg,image/png,image/gif,image/bmp,application/pdf",
            acceptedFiles: props.acceptedFiles,
            headers:{
                "X-authorization": AuthGuard.isAuthenticated,
                "X-app": XAPP
            },
            timeout:120*1000 , //x 秒
            previewsContainer:'.dPBox',
            previewTemplate: ReactDOMServer.renderToStaticMarkup(
                <div className="dz-preview dz-file-preview">
                    <div className="dz-details">
                        <div className="dz-filename"><span data-dz-name="true"></span></div>
                        <img data-dz-thumbnail="true" />
                    </div>
                    <div className="dz-progress"><span className="dz-upload" data-dz-uploadprogress="true"></span></div>
                    <div className="dz-success-mark"><span>✔</span></div>
                    <div className="dz-error-mark"><span>✘</span></div>
                    <div className="dz-error-message"><span data-dz-errormessage="true"></span></div>
                </div>
            ),
        }
        this.eventHandlers = {            
            init: dz => dropzone = dz,
            addedfile: this.handleAddedfile,
            removedfile: this.handleRemove,
            // drop: this.callbackDropArray,            
            uploadprogress: this.uploadprogress,
            canceled: this.handleCanceled,
            sending: this.handleSending,
            success: this.handleSuccess,
            error: this.handleError,
            complete: this.handleComplete,
        }
        this.uploadDialog = null;
        this.uploadingProgress = 0;
    }


    componentDidMount() {}

    componentWillReceiveProps(props){
        let { dropzoneSelector} = props;
        if (dropzoneSelector.indexOf('medication') !== -1 ){
            this.componentConfig = {
                postUrl: ApiUrl.addMedicationCsv,
            }
            this.djsConfig = {
                params: {
                    file_type: dropzoneSelector.toUpperCase()
                },
                paramName: "files",
            }
        }else{
            let { user_profile_id, user_queue_id} = props.uploadConfig;
            this.djsConfig = {
                params: {
                    user_profile_id: user_profile_id,
                    user_queue_id: user_queue_id,
                    file_type: dropzoneSelector.toUpperCase()
                }
            }
        }
        
    }

    componentDidUpdate(){
        // console.log('componentDidUpdate');
    }

    handleAddedfile = async (file)=>{
        // console.log(this.dropzone);
        // console.log(file);
        
        this.uploadDialog = app.dialog.progress('Uploading', this.uploadingProgress);
        this.uploadDialog.setText('uploaded (0%)');
        const {
            onFileUploaded, fileType = 'contract', maxFiles, maxFilesize
        } = this.props;
        
        // 限制文件大小
        if (file.size > 1024 * 1014 * parseInt(maxFilesize)) {
            ShowToast(app,'Only pdf files are accepted max size is 5MB');
            return;
        }

        // 到达最大个数后不执行上传
        if (file.name.indexOf('&') !== -1 || file.name.indexOf('/') !== -1 || file.name.indexOf('\\') !== -1) {
            ShowToast(app, 'File name cannot contain special characters "&"/""\\"');
            return;
        }
        if (file.contentFiles && file.contentFiles >= maxFiles) {
            ShowToast(app, 'Reached the maximum number of uploaded files');
            return;
        }
    }

    handleRemove(file) {
        console.log('file has been removed')        
    }

    callbackDropArray(file){
        console.log('callbackDropArray')
    }

    handleSuccess(file ,apiBackRes){
        console.log('handleSuccess')
        if (apiBackRes.status_code === RespondCode.uploadFilesSuccess || apiBackRes.status_code === 3500){
            console.log('handleSuccess',apiBackRes)
            let { message , result} = apiBackRes;
            if (this.props.dropzoneSelector.indexOf('medication') !== -1){
                ShowToast(app, 'Upload file success');
            }else{
                ShowToast(app, apiBackRes.message);    
            }
            this.props.uploadSuccess(this.props.dropzoneSelector, result)
        }
        app.dialog.close();
    }

    handleError(file, errorMessage){
        console.log('handleError')
        let errMsg = 'Upload File';
        console.log(errorMessage);
        if (errorMessage.message !== undefined && errorMessage.message && errorMessage.message !== ''){
            errMsg = errorMessage.message
        }else{
            errMsg = errorMessage;
        }
        if (this.props.dropzoneSelector.indexOf('medication') !== -1){
            this.props.uploadError(this.props.dropzoneSelector, errMsg)
        }else{
            ShowToast(app, errMsg,'center',3000);
        }
        
        app.dialog.close();
    }

    uploadprogress(file ,progress, bytesSent){
        this.uploadDialog.setProgress(progress);
        this.uploadDialog.setText('uploaded (' + (parseInt(progress)) + '%)');
        // console.log(progress, bytesSent);
    }

    handleComplete(file , msg){
        console.log(msg);
        dropzone.removeFile(file);
        // this.dropzone.removeAllFiles();
        app.dialog.close();
    }

    handleCanceled(file , msg){
        console.log(file);
        console.log(msg);
    }

    handleSending(file, xhr, formData){
        xhr.ontimeout = function (e) {
            console.log("Argh Upload timed out")
            app.dialog.close();
            ShowToast(app, "Argh Upload timed out!", 'center', 3000);
        };
    }

    uploadFile = () => {
        this.$$("." + this.props.dropzoneSelector).click();
    }
    
    render() { 
        return ( 
            <div className="">
                <div className="dPBox" style={{display:'none'}}>
                    <div className="dropzone-previews"></div>
                </div> 
                <div className={` uploadFileBlock ${this.props.dropzoneSelector}`}>
                    <Icon f7="doc" className="iconArrow" ></Icon>
                    <div>
                        Drag & drop or
                    </div>
                        <div className="ml20">                        
                        <Button onClick={this.uploadFile} className="outline-blue-btn">Browse</Button>
                        </div>
                        <div className="ml20">
                            to upload document
                    </div>
                </div>
                
                <DropzoneComponent 
                    config={this.componentConfig}
                    eventHandlers={this.eventHandlers}
                    djsConfig={this.djsConfig} 
                />
            </div>    
        );
    }
}
 
export default UploadFiles;