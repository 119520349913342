import React, {useState, useRef, useCallback, useEffect} from 'react';
import {
    f7 as app,
    Icon,
    Menu as M,
    MenuItem,
    MenuDropdown,
    MenuDropdownItem,
    Button,
    Popover,
    List, ListItem, Popup, Page
} from 'framework7-react'
import AboutDialog from '../AboutDialog/AboutDialog';
import IconButton from '@material-ui/core/IconButton';
import MenuContainer from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import SettingsDialog from '../SettingsDialog/SettingsDialog';

import {useAppState} from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import IconVCLayout from "../../../../../images/videoCall/IconVClayout.png";
import IconVCshare from "../../../../../images/videoCall/IconVCshare.png";
import IconVCquality from "../../../../../images/videoCall/IconVCquality.png";
import useScreenShareToggle from "../../../hooks/useScreenShareToggle/useScreenShareToggle";
import useScreenShareParticipant from "../../../hooks/useScreenShareParticipant/useScreenShareParticipant";
import {isMacOs, isWindows, isTablet} from 'react-device-detect'

export default function Menu() {
    const {user, signOut, getToken} = useAppState();
    const {room, localTracks, connect} = useVideoContext();

    const [aboutOpen, setAboutOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [isScreenShared, toggleScreenShare] = useScreenShareToggle();
    const screenShareParticipant = useScreenShareParticipant();
    const disableScreenShareButton = screenShareParticipant && screenShareParticipant !== room.localParticipant;
    const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    const isDisabled = disableScreenShareButton || !isScreenShareSupported;


    const anchorRef = useRef<HTMLDivElement>(null);

    const handleSignOut = useCallback(() => {
        room.disconnect?.();
        localTracks.forEach(track => track.stop());
        signOut?.();
    }, [room.disconnect, localTracks, signOut]);

    const settingClick = (actionType: string) => {
        console.log(actionType)

        if (actionType === "layout") {
            app.popup.open(".changeVideoLayout-popup");
        }
        if (actionType === "settings") {
            app.popup.open(".settings-popup");
        }
        if (actionType === "share") {
            if (!isDisabled && room.state == 'connected') {
                toggleScreenShare();
            }
        }
        app.popover.close(".popover-moremenu");
    };

    useEffect(() => {
    })

    return (
        <>
            <Button
                popoverOpen=".popover-moremenu"
                // raised
                className="IconMoreSetting"
            >
                <MoreIcon/>
            </Button>
            <Popover className="popover-moremenu">
                <List inset>
                    <ListItem
                        popoverClose
                        className="canClick"
                        title="Change Video Layout"
                        onClick={() => settingClick("layout")}
                    >
                        <img
                            className="IconForMoreSetting"
                            src={IconVCLayout}
                            alt=""
                        />
                    </ListItem>
                    {(!isTablet) && <ListItem
                        popoverClose
                        className="canClick"
                        title="Share Screen"
                        onClick={() => settingClick("share")}
                    >
                        <img
                            className="IconForMoreSetting"
                            src={IconVCshare}
                            alt=""
                        />
                    </ListItem>}
                    <ListItem
                        popoverClose
                        className="canClick"
                        title="Settings"
                        onClick={() => settingClick("settings")}
                    >
                        <img
                            className="IconForMoreSetting"
                            src={IconVCquality}
                            alt=""
                        />
                    </ListItem>
                </List>
            </Popover>
            <Popup className="settings-popup">
                <SettingsDialog
                    onClose={() => {
                        app.popup.close(".settings-popup")
                    }}
                />
            </Popup>
        </>
    )

    // return (
    //   <div ref={anchorRef}>
    //     <IconButton color="inherit" onClick={() => setMenuOpen(state => !state)}>
    //       <MoreIcon />
    //     </IconButton>
    //     <Menu open={menuOpen} onClose={() => setMenuOpen(state => !state)} anchorEl={anchorRef.current}>
    //       <MenuItem onClick={() => setAboutOpen(true)}>About</MenuItem>
    //       <MenuItem onClick={() => setSettingsOpen(true)}>Settings</MenuItem>
    //     </Menu>
    //     <AboutDialog
    //       open={aboutOpen}
    //       onClose={() => {
    //         setAboutOpen(false);
    //         setMenuOpen(false);
    //       }}
    //     />
    //     <SettingsDialog
    //       open={settingsOpen}
    //       onClose={() => {
    //         setSettingsOpen(false);
    //         setMenuOpen(false);
    //       }}
    //     />
    //   </div>
    // );
}
