import {
  CountryServiceApi,
  CountryServiceApiSuccessCode,
} from "./CountryService";

export const OS_NAME = {
  android: "android",
  ios: "ios",
};

export const RSAPublicKey = process.env.REACT_APP_RSA_PUBLIC_KEY;
export const XAPP = process.env.REACT_APP_APPAUTH;

export const QB_APPID = process.env.REACT_APP_QB_APPID;
export const QB_AUTHKEY = process.env.REACT_APP_QB_AUTHKEY;
export const QB_AUTHSECRET = process.env.REACT_APP_QB_AUTHSECRET;
export const AGORA_APPID = process.env.REACT_APP_AGORA_APPID;
export const APPENV = process.env.REACT_APP_ENV;
/**
 * BASE API URL FOR EACH ENVIRONMENT
 */
export const apiBaseUrl = process.env.REACT_APP_API_URL;
export const nonceUrl = process.env.REACT_APP_NONCE_URL;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const IP_WHITELIST = process.env.REACT_APP_IP_WHITELIST;

export const ROUTES = {
  main: "/",
  login: "/login",
  forgetPassword: "/forgetPassword",
  restpw: "/restpw",
  fwOtp: "/fwOtp",
  logout: "/logout",
  appointments: "/appointments",
  sessionlist: "/sessionlist",
  patientlist: "/patientlist",
  patientdetail: "/patientDetail",
  setting: "/setting",
  consultationSummary: "/consultationSummary",
  addAccount: "/addAccount",
  addAccountRole: "/addAccountRole",
  patientInfo: "/patientInfo",
  // help: '/help',
  registerNewUser: "/registerNewUser",
  addProvider: "/addProvider",
  googleConnect: "/googleConnect",
  addMedication: "/addMedication",
  reports: "/reports",
  labReports: "/labReports",
};

export const WeekEveryDay = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export const dayEveryTime = [
  "00:00",
  "00:30",
  "01:00",
  "01:30",
  "02:00",
  "02:30",
  "03:00",
  "03:30",
  "04:00",
  "04:30",
  "05:00",
  "05:30",
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];
export const INDEX_HIGH = "H";
export const INDEX_MID_HIGH = "MH";
export const INDEX_MEDIUM = "M";
export const INDEX_LOW = "L";
export const INDEX_NA = "-";

export const BG_RED = "bgRed";
export const BG_ORANGE = "bgOrange";
export const BG_YELLOW = "bgYellow";
export const BG_WHITE = "";

export const RespondCode = {
  ...CountryServiceApiSuccessCode,
  loginSuccess: 3019,
  sessionSuccess: 6703,
  redeemSessionStatusSuccess: 6710,
  patientListSuccess: 10014,
  chatListSuccess: 3019,
  getPatientDetailSuccess: 10014,
  createChatDialogSuccess: 3010,
  getMessageSuccess: 3022,
  sendMessageSuccess: 3014,
  getProfileImageSuccess: 6731,
  updateGoalBPSuccess: 10018,
  updateUserInfoSuccess: 10018,
  getNotiListSuccess: 3004,
  getGraphSuccess: 10030,
  pushNotificationToUserAppSuccess: 3014,
  pushNotificationCall: 10047,
  forgetPwSuccess: 6216,
  getClinicInfoSuccess: 10034,
  setClinicInfoSuccess: 10036,
  getVideoCallSuccess: 10045,
  getCurAppointmentInfo: 10045,
  cancelVideoCallSuccess: 10047,
  getVideoCallInfoSuccess: 10045,
  drStartCallSuccess: 10045,
  readyCallSuccss: 1001,
  createBillSuccess: 10058,
  // patientInCallSuccess: 1003,
  tellPatientIncallSuccess: 10047,
  endCallSuccess: 1005,
  drCloseVideoSuccess: 11005,
  onlineOfflineSuccess: 10007,
  getUpcomingConsultationsCountSuccess: 10030,
  getCoachByIDSuccess: 10005,
  sendOtpSuccess: 3004,
  verifyOtpSuccess: 3718,
  otpLoginSuccess: 3019,
  uploadFilesSuccess: 10010,
  getMeasuredataHistorySuccess: 3004,
  resetPwSuccess: 6654,
  delPdfSuccess: 11003,
  roleFunctionListSuccess: 11009,
  saveClinicRoleSuccess: 11011,
  getClinicRoleListSuccess: 6733,
  getClinicRoleDetailSuccess: 6733,
  getStaffListSuccess: 6733,
  saveStaffSuccess: 11011,
  getStaffDetailSuccess: 6733,
  addTimeslotSuccess: 10050,
  getTimeslotSuccess: 10052,
  updateTimeslotSuccess: 10050,
  getAvaliableDateSuccess: 15004,
  queneCofirmSuccess: 10047,
  rescheduleConfirm: 15002,
  getStepsHistorySuccess: 10030,
  getSleepHistorySuccess: 10030,
  getBloodGlucoseHistorySuccess: 10030,
  autoRefreshTokenSuccess: 3090,
  getNonceNumSuccess: 90001,
  verifyNonceNumSuccess: 90002,
  userFullInfoSuccess: 11007,
  saveconsultationSummarySuccess: 11005,
  getQBLoinPwSuccess: 3004,
  saveNotesSuccess: 10047,
  getNotesSuccess: 10045,
  appointmentScheduleSuccess: 15002,
  newUserComingSuccess: 10030,
  getDrlistSuccess: 10005,
  handlyDrSuccess: 11005,
  getGoalInfoSuccess: 10014,
  setGoalUpdateSuccess: 10020,
  getLimitInfoSuccess: 10014,
  setLimitUpdateSuccess: 10020,
  getCalendarInfoSuccess: 10014,
  calendarSyncOnSuccess: 10020,
  calendarSyncOffSuccess: 11001,
  calendarUpdateSuccess: 10020,
  getGoogleAuthSuccess: 6733,
  getGoogleInfoSuccess: 11019,
  addUserSuccess: 10020,
  addDataInfoSuccess: 11001,
  tmpertureHisotrySuccess: 10030,
  spo2HistorySuccess: 10030,
  getForgetPWOTPSuccess: 6616,
  resetPasswordSuccess: 6216,
  verifiFWOTPSuccess: 6616,
  getCoachProfile: 10005,
  saveCoachProfile: 10007,
  getClinicProfile: 10034,
  clinicSettingEditSuccess: 10036,
  saveClinicProfile: 10036,
  // addDoctorSuccess:11011,
  saveGeneralSuccess: 6052,
  getGeneralTimerSuccess: 1111,
  getLogouTimeSuccess: 6050,
  uploadBase64ImgSuccess: 10010,
  uploadBase64Success: 10010,
  generateWhatsappQrSuccess: 1234,
  delClinicRoleSuccess: 11003,
  addProviderSuccess: 11011,
  merchantDetailSuccess: 6050,
  reportsSettlementListSuccess: 8052,
  reportsExportSuccess: 8052,
  addProviderGoPayment: 8236,
  delStaffSuccess: 11019,
  getMedicationSuccess: 10030,
  saveMedicationSuccess: 11001,
  editMedicationSuccess: 11005,
  getMedicationDetailSuccess: 10030,
  delMedicationSuccess: 11003,
  getMedicationCsvSuccess: 0,
  getMedicationCsvUrlSuccess: 10010,
  uploadMedicationCsv: 6006,
  findProfileForStaffSuccess: 10005,
  codeChangeCoachPwdSuccess: 6654,
  listenerHeartbeatSuccess: 3027,
  findProgramByUserIdAndProgramTypeSuccess: 8224,
  addProgramsByUserIdSuccess: 8245,
  codeProductListRetrieved: 8207,
  mcUrlSuccess: 11007,
  queryMcSuccess: 11007,
  saveMcSuccess: 11001,
  queryPaymentListSuccess: 11005,
  diagnosisSearchSuccess: 11007,
  saveAppointmentDraft: 11005,
  findMedicationAllSuccess: 6050,
  mcDeleteSuccess: 11003,
  mcUpdateSuccess: 11008,
  AGTkenSuccess: 10045,
  reportsSubsidiesSuccess: 8052,
  reportsDispensarySuccess: 8052,
  reportsSubExportSuccess: 8052,
  reportsDisExportSuccess: 8052,
  nationalityListSuccess: 3057,
  saveAppointmentSuccess: 3025,
  uploadDrAvatarSuccess: 3500,
  uploadTwilioLogSuccess: 16015,
  logoutTimeSettingSuccess: 6052,
  dateTimefilterSuccess: 10045,
  getUserLogoutTimeSuccess: 6050,
  getGeneralForCoachSuccess: 16023,
  checkNotificationMobileSuccess: 16019,
  sendSmsOtpDirectSuccess: 3004,
  verifyCoachMobileSuccess: 3004,
  updateMobileForCoachSuccess: 3063,
  getLabReportsSuccess: 10030,
  updateLabReportsSuccess: 10030,
  notificationDeleteByIdSuccess: 16021,
  generateE3TokenSuccess: 3048,
  generateE3SaltSuccess: 3046,
  removeJoinedProgramSuccess: 8226,
  loginSendEmailSuccess: 3004,
  getAllDrInfoSuccess: 3019,
  chatLogSuccess: 3000,
  reportUploadFilesSuccess: 10014,
  reportAddFileSuccess: 10010,
  reportAddFileSuccessV2: 10011,
  getLoginUserInfoSuccess: 3019,
  getReportMedicationNameListSuccess: 8052,
  searchPatientSuccess: 21000,
  getPatientDateListSuccess: 21000,
  getMeasurementsDetailSuccess: 21000,
  uploadLabReportsCheckSuccess: 21000,
  updateLabReportsStatusCheckSuccess: 25000,
  generateMeasurementsReportSuccess:11005
};

export const ShowErrorMsgCode = [
  3017, // get Chat Dialog Fail
  3032, // get Chat service Fail
  3021, // User has no access
  3011, // create dialog fail,
  1002,
  6007, //user not exist
  // 10046,//Find user queue fail.
  10048, //Update user queue fail.
  11013,
  11014,
  6004,
  3042, //add staff fail
  15001, // not effective TimeSlot
  6655, //Update Password Fail
  10059, //create bill fail
  11015, //OTP每分钟只能发送一次
  11016, //用户登录5次失败需冻结该账户1个小时,每用户每小时访问不能超过30次
  5011,
  5010, //firebase/pushNotificationWithContent
  11012,
];

export const AlertMsg = {
  chatServiceError: "This user is not under your health program",
  videoCallStartFail: "Video Call Start Fail!",
};

export const AutoRefreshTokenCode = [3721, 401];

/**
 * API URL FOR EACH FUNCTION
 */
export const ApiUrl = {
  ...CountryServiceApi,
  login: "account_service/account/coach/login",
  logout: "account_service/account/coach/logout",
  sessionList: "account_service/activesg/referrer/list",
  redeemSessionStatus: "account_service/activesg/referrer/redeemed",
  patientList: "health_service/userinfo/userList",
  getPaitentDetail: "health_service/userinfo/find/info",
  getGoalInfo: "health_service/usergoal/info",
  setGoalUpdate: "health_service/usergoal/update",
  addDataInfo: "health_service/userinfo/clinicBatchAddData",
  getLimitInfo: "health_service/userlimit/info",
  setLimitUpdate: "health_service/userlimit/update",
  chatList: "chat_service/v2/dialog/userDialogList",
  createChatDialog: "chat_service/v2/dialog/coachCreatDialog",
  getMessageList: "chat_service/message/getMessageList",
  sendMessage: "chat_service/message/createMessage",
  getProfileImage: "account_service/v2/user/findProfileImage",
  updateGoalBP: "health_service/userinfo/update/goalBP",
  updateUserInfo: "health_service/userinfo/update/info",
  getNotiList: "communicate_service/v2/pushNotification/findForCoach",
  getGraph: "health_service/userinfo/findForGraph",
  pushNotificationToUserApp:
    "communicate_service/firebase/pushNotificationWithContentV2",
  pushNotificationCall: "health_service/appointment/pushNotification/call",
  forgetPw: "account_service/account/coach/forgotPassword",
  getClinicInfo: "health_service/clinicinfo/find",
  clinicSettingEdit: "health_service/clinic/setting/edit",
  // setClinicInfo: 'health_service/clinicinfo/handle',
  setClinicInfo: "health_service/clinicinfo/updateDetail",
  // getVideoCall: 'health_service/userqueue/findPage',
  getVideoCall: "health_service/appointment/list",
  getCurAppointmentInfo: "health_service/userqueue/findQueueById",
  cancelVideoCall: "health_service/appointment/cancel",
  getVideoCallInfo: "health_service/appointment/detail",
  drStartCall: "health_service/coach/start_call",
  readyCall: "promote_service/ready_call",
  createBill: "health_service/appointment/complete",
  tellPatientIncall: "health_service/appointment/processing",
  endCall: "health_service/appointment/video/end",
  drCloseVideo: "health_service/appointment/doctor/endCall",
  onlineOffline: "health_service/coach/updateStatus",
  getUpcomingConsultationsCount: "health_service/coach/realData",
  getCoachByID: "health_service/coach/findById",
  sendOtp: "account_service/account/coach/sendOtp",
  verifyOtp: "account_service/users/otp/verify", //弃用
  otpLogin: "account_service/account/coach/otpLogin",
  uploadFiles: apiBaseUrl + "health_service/coach/document/upload",
  medTemplateFile:
    apiBaseUrl + "account_service/upload/csv/Medication_list.csv",
  reportUploadFiles: apiBaseUrl + "health_service/health/report/upload/files",
  getMeasuredataHistory: "connect_service/v2/measuredata/history",
  resetPw: "account_service/account/coach/changePassword",
  delPdf: "health_service/coach/document/delete",
  roleFunctionList: "account_service/clinic/function/list",
  saveClinicRole: "account_service/clinic/role/save",
  getClinicRoleList: "account_service/clinic/role/list",
  getClinicRoleDetail: "account_service/clinic/role/detail",
  getStaffList: "account_service/clinic/staff/list",
  saveStaff: "account_service/clinic/staff/save",
  getStaffDetail: "account_service/clinic/staff/detail",
  addTimeslot: "health_service/clinic/timeslot/add",
  getTimeslot: "health_service/clinic/timeslot/get",
  updateTimeslot: "health_service/clinic/timeslot/update",
  getAvaliableDate: "health_service/appointment/avaliable/date",
  queneCofirm: "health_service/appointment/confirm",
  rescheduleConfirm: "health_service/appointment/reschedule",
  getBloodGlucoseHistory: "health_service/userbloodglucose/history",
  getSleepHistory: "health_service/sleep/detail/history",
  getStepsHistory: "health_service/step/detail/history",
  autoRefreshToken: "account_service/account/refresh/token",
  getNonceNum: nonceUrl + "nonc/get",
  verifyNonceNum: nonceUrl + "nonc/check",
  userFullInfo: "health_service/appointment/user/detail",
  saveconsultationSummary: "health_service/appointment/detail/save",
  diagnosisSearch: "health_service/diagnosis/search",
  saveAppointmentDraft: "health_service/appointment/draft/save",
  getQBLoinPw: "chat_service/userchat/find",
  saveNotes: "health_service/userqueue/doctornotes/save",
  getNotes: "health_service/userqueue/findQueueById",
  appointmentSchedule: "health_service/appointment/schedule",
  newUserComing: "health_service/appointment/queue/summary",
  getDrlist: "health_service/coach/findByClinicId",
  handlyDr: "health_service/appointment/reassign",
  tmpertureHisotry: "health_service/user/temperature/history",
  spo2History: "health_service/user/spo2/history",
  getForgetPWOTP: "account_service/account/coach/sendOTPEmail",
  // resetPassword: 'account_service/account/coach/resetPassword',
  resetPassword: "account_service/account/coach/resetPasswordV2",
  verifiFWOTP: "account_service/account/coach/checkOtp",
  //get dr my profile info
  getCoachProfile: "health_service/coach/findProfile",
  saveCoachProfile: "health_service/coach/saveProfile",
  getClinicProfile: "health_service/clinicinfo/findDetail",
  saveClinicProfile: "health_service/clinicinfo/updateDetail",
  uploadDrAvatar: "account_service/upload/image/files",
  // addDoctor:'account_service/clinic/staff/save/listDoctor',
  saveGeneral: "account_service/microservice/merchant/attribute/save",
  getGeneralTimer:
    "account_service/merchant/dropdown/merchant_setting_logout_time",
  getLogouTime: "account_service/merchant/find/detail",
  uploadBase64Img: "health_service/common/file/uploadBase64Image",
  uploadBase64: "health_service/common/file/uploadBase64ImageToS3ForPublic",
  generateWhatsappQr: "account_service/qrcode/generate/whatsapp",
  delClinicRole: "account_service/clinic/role/delete",
  addProvider: "account_service/clinic/staff/save/listDoctor",
  checkoutInfo: "account_service/clinic/staff/checkout/listDoctor",
  merchantDetail: "account_service/microservice/merchant/find/detail",
  reportsSettlementList: "merchant_service/partner/report/settlement/list",
  reportsSubsidies: "merchant_service/partner/report/subsidies/list",
  reportsDispensary: "merchant_service/partner/report/dispensary/list",
  reportsExport: "merchant_service/partner/report/settlement/export",
  reportsSubExport: "merchant_service/partner/report/subsidies/export",
  reportsDisExport: "merchant_service/partner/report/dispensary/export",
  delStaff: "account_service/clinic/staff/remove",
  //get medication
  // getMedication: 'account_service/v2/user/pageForMedication',
  getMedication: "health_service/inventory/pageForMedication",
  // saveMedication: 'account_service/v2/user/addMedication',
  saveMedication: "health_service/inventory/insertInventory",
  // editMedication: 'account_service/v2/user/editMedication',
  editMedication: "health_service/inventory/updateInventory",
  // getMedicationDetail: 'account_service/v2/user/findMedication',
  getMedicationDetail: "health_service/inventory/getInventoryDetail",
  // delMedication: 'account_service/v2/user/deleteMedication',
  delMedication: "health_service/inventory/delInventory",
  addMedicationCsv: apiBaseUrl + "account_service/upload/csv/medication/files",
  uploadMedicationCsv: "account_service/v2/user/addMedicationList",
  getMedicationCsvUrl: "health_service/common/file/queryCSVToS3",
  findProfileForStaff: "health_service/coach/findProfileForStaff",
  changeCoachPwd: "account_service/account/coach/changPasswordForEmail_v2",
  listenerHeartbeat: "account_service/account/coach/addUserStatus",
  findProgramByUserIdAndProgramType:
    "merchant_service/service/access/findActiveAndInvitationInClinic",
  findProgramByMerchantId: "merchant_service/program/findForClinic",
  addProgramsByUserId:
    "merchant_service/programrequestcode/requestForCodeByCoach",
  addUser: "health_service/userinfo/addUser",
  googleConnect: "account_service/user/connect/google",
  googleConnectInfo: "account_service/user/connect/info",
  calendarSyncInfo: "health_service/calendar/sync/info",
  calendarSyncOn: "health_service/calendar/sync/on",
  calendarSyncOff: "health_service/calendar/sync/off",
  calendarSyncUpdate: "health_service/calendar/sync/update",
  findClinicInfoByClinicid: "health_service/coach/search/clinic",
  mcUrl: "health_service/appointment/medical/certificate/queryById",
  queryMc: "health_service/appointment/medical/certificate/query",
  saveMc: "health_service/appointment/medical/certificate/save",
  queryPaymentList: "health_service/appointment/user/paymentList",
  findMedicationAll: "account_service/v2/user/findMedicationAll",
  mcDelete: "health_service/appointment/medical/certificate/delete",
  mcUpdate: "health_service/appointment/medical/certificate/update",
  AGTken: "health_service/video/generate/shareId",
  nationalityList: "account_service/attribute/value/get",
  saveAppointment: "account_service/v2/user/editInfoForUser",
  logoutTimeSetting: "account_service/merchant/logout/attribute/save",
  uploadTwilioLog: "health_service/system/videolog/add",
  dateTimefilter: "health_service/appointment/datetime/filter",
  getUserLogoutTime: "account_service/merchant/find/detail/userId",
  getGeneralForCoach:
    "health_service/notificationreceiveby/findGeneralForCoach",
  checkNotificationMobile:
    "health_service/notificationreceiveby/checkNotificationMobile",
  sendSmsOtpDirect: "account_service/v2/otp/sendSmsOtp/sendSmsOtpDirect",
  verifyCoachMobile: "account_service/v2/otp/sendSmsOtp/sendSmsOtpDirect",
  updateMobileForCoach: "account_service/account/coach/setMobileForOtpLogin",
  getLabReports: "health_service/health/report/list",
  updateLabReports: "health_service/health/report/update",

  uploadLabReportsCheck: "health_service/v6/report/upload/files/check", // patient document 上传之前先掉这个接口
  updateLabReportsStatusCheck: "health_service/health/report/check", // lab report assign 之前先掉这个接口

  notificationDeleteById: "health_service/notificationreceiveby/deleteById",
  generateE3Token: "chat_service/user/generate/e3/token",
  generateE3Salt: "chat_service/user/get/salt",
  removeJoinedProgram: "merchant_service/service/access/delete",
  loginSendEmail: "account_service/v2/otp/sendOtp",
  getAllDrInfo: "chat_service/v3/userdialog/findUserInfoForDialog",
  chatLog: "chat_service/chat/errorLog",
  reportAddFile: "health_service/health/report/upload",
  getLoginUserInfo: "account_service/account/coach/findLoginInfo",
  getReportMedicationNameList:
    "merchant_service/partner/report/dispensary/medication/list",
  advanceSearch: "health_service/v6/queue/advance/search",
  getPatientDateList: "health_service/patient/appointment/date/list",
  getMeasurementsDetail: "health_service/patient/measurements/detail",
  saveMeasurementsDetail: "health_service/patient/measurements/save",
  generateMeasurementsReport:"health_service/patient/measurements/generate/report"
};

export const dontShowRequestTimedOutNotice = [
  ApiUrl.getVideoCall,
  ApiUrl.getUpcomingConsultationsCount,
  ApiUrl.autoRefreshToken,
  ApiUrl.newUserComing,
  ApiUrl.listenerHeartbeat,
];
