import React, { Component } from 'react';
import { connect } from 'react-redux'
import { f7 as app, Link, Popup, Page, Button } from 'framework7-react';
import logo from '../images/icons/Omron.png'
import { UserLogout } from '../redux/actions'
import { APP_VERSION, ROUTES } from '../commons/Constants'
// import logOutSelected from '../images/home/icon_logOut_selected.png'
import logOutUnselected from '../images/home/icon_logOut_unselected.png'
import patientListSelected from '../images/home/icon_patientList_selected.png'
import patientListUnselected from '../images/home/icon_patientList_unselected.png'
import sessionIDSelected from '../images/home/icon_sessionID_selected.png'
import sessionIDUnselected from '../images/home/icon_sessionID_unselected.png'
import settingsSelected from '../images/home/icon_settings_selected.png'
import settingsUnselected from '../images/home/icon_settings_unselected.png'
import appointmentSelected from '../images/home/icon_today_selected.png'
import appointmentUnselected from '../images/home/icon_today_unselected.png'
import labRepSelected from '../images/home/icon_lab_selected.png'
import labRepUnselected from '../images/home/icon_lab_unselected.png'
import helpUnSelected from '../images/home/help.png'
import helpSelected from '../images/home/help_selected.png'
import reportsSelected from '../images/home/Reports_white.png'
import reportsUnSelected from '../images/home/Reports.png'
import AuthGuard from '../services/AuthGuard';


class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appointmentImgUrl: appointmentSelected,
            patientListImgUrl: patientListUnselected,
            sessionIDImgUrl: sessionIDUnselected,
            settingsImgUrl: settingsUnselected,
            labRepImgUrl: labRepUnselected,
            logOutImgUrl: logOutUnselected,
            helpImgUrl: helpUnSelected,
            reportsUrl: reportsUnSelected,
            subMenuBackList: [
                ROUTES.addAccountRole,
            ],
            patientlistSubMenuBack: [
                ROUTES.patientdetail,
            ],
            VideoCallSubMenu: [
                ROUTES.consultationSummary,
                ROUTES.patientInfo
            ],
            // HelpMenu: [
            //     ROUTES.helpMenu,
            //     ROUTES.faq
            // ],
            ReportsMenu: [
                ROUTES.reportsMenu
            ]
        }
        this.coachProfile = AuthGuard.getCoachProfile();
    }

    componentDidMount() {
        const { page } = this.props;
        this.$$('a.menu-link-active').attr('href', '#');
        switch (page) {
          case ROUTES.appointments:
            this.setState({
              appointmentImgUrl: appointmentSelected,
              patientListImgUrl: patientListUnselected,
              labRepImgUrl: labRepUnselected,
              sessionIDImgUrl: sessionIDUnselected,
              settingsImgUrl: settingsUnselected,
              logOutImgUrl: logOutUnselected,
              helpImgUrl: helpUnSelected,
              reportsUrl: reportsUnSelected,
            });
            break;
          case ROUTES.patientlist: case ROUTES.patientdetail: case ROUTES.registerNewUser:
            this.setState({
              appointmentImgUrl: appointmentUnselected,
              patientListImgUrl: patientListSelected,
              labRepImgUrl: labRepUnselected,
              sessionIDImgUrl: sessionIDUnselected,
              settingsImgUrl: settingsUnselected,
              logOutImgUrl: logOutUnselected,
              helpImgUrl: helpUnSelected,
              reportsUrl: reportsUnSelected,
            });
            break;
          case ROUTES.labReports:
            this.setState({
              appointmentImgUrl: appointmentUnselected,
              labRepImgUrl: labRepSelected,
              patientListImgUrl: patientListUnselected,
              sessionIDImgUrl: sessionIDUnselected,
              settingsImgUrl: settingsUnselected,
              logOutImgUrl: logOutUnselected,
              helpImgUrl: helpUnSelected,
              reportsUrl: reportsUnSelected,
            });
            break;
          case ROUTES.sessionlist:
            this.setState({
              appointmentImgUrl: appointmentUnselected,
              patientListImgUrl: patientListUnselected,
              labRepImgUrl: labRepUnselected,
              sessionIDImgUrl: sessionIDSelected,
              settingsImgUrl: settingsUnselected,
              logOutImgUrl: logOutUnselected,
              helpImgUrl: helpUnSelected,
              reportsUrl: reportsUnSelected,
            });
            break;
          case ROUTES.setting: case ROUTES.addAccountRole: case ROUTES.addAccount: case ROUTES.addMedication:
            this.setState({
              appointmentImgUrl: appointmentUnselected,
              patientListImgUrl: patientListUnselected,
              labRepImgUrl: labRepUnselected,
              sessionIDImgUrl: sessionIDUnselected,
              settingsImgUrl: settingsSelected,
              logOutImgUrl: logOutUnselected,
              helpImgUrl: helpUnSelected,
              reportsUrl: reportsUnSelected,
            });
            break;
          case ROUTES.help:
            this.setState({
              appointmentImgUrl: appointmentUnselected,
              patientListImgUrl: patientListUnselected,
              labRepImgUrl: labRepUnselected,
              sessionIDImgUrl: sessionIDUnselected,
              settingsImgUrl: settingsUnselected,
              logOutImgUrl: logOutUnselected,
              helpImgUrl: helpSelected,
              reportsUrl: reportsUnSelected,
            });
            break;
          case ROUTES.reports:
            this.setState({
              appointmentImgUrl: appointmentUnselected,
              patientListImgUrl: patientListUnselected,
              labRepImgUrl: labRepUnselected,
              sessionIDImgUrl: sessionIDUnselected,
              settingsImgUrl: settingsUnselected,
              logOutImgUrl: logOutUnselected,
              helpImgUrl: helpUnSelected,
              reportsUrl: reportsSelected,
            });
            break;
          case ROUTES.logout:
            this.setState({
              // appointmentImgUrl: appointmentUnselected,
              // patientListImgUrl: patientListUnselected,
              // sessionIDImgUrl: sessionIDUnselected,
              // settingsImgUrl: settingsUnselected,
              // logOutImgUrl: logOutSelected,
            });
            // this.onLogout();
            break;

          default:
            break;
        }
    }

    componentDidUpdate() {
        // console.log(app.views.main.router.previousRoute);
    }

    componentWillReceiveProps() {
        // console.log(app.views.main.router.currentRoute)
    }


    showPopupVersion() {
        app.dialog.alert(APP_VERSION, 'App Version');
    }

    /**
     * Logout
     *
     * @param {any} evt
     * @memberof Logout
     */
    onLogout() {
        console.log(app.popup.close('.logout-popup'))
        if (app.popup.close('.logout-popup')) {
            this.props.UserLogout();
        }
    }

    onPageBeforeRemove() {
        console.log('onPageBeforeRemove');
        if (this.popup) this.popup.destroy();
    }

    menuLinks() {
        console.log(this.props.page)
        if (this.state.VideoCallSubMenu.find((v) => {
            return v === this.props.page
        })) {
            app.views.main.router.navigate(ROUTES.appointments, {
                reloadAll: true
            });
        }
        // else if (this.state.HelpMenu.find((v) => {
        //     return v === this.props.page
        // })) {
        //     app.views.main.router.navigate(ROUTES.helpMenu, {
        //         reloadAll: true
        //     });
        // }
        else if (this.state.ReportsMenu.find((v) => {
            return v === this.props.page
        })) {
            app.views.main.router.navigate(ROUTES.ReportsMenu, {
                reloadAll: true
            });
        }
        else if (
            this.state.patientlistSubMenuBack.find((v) => {
                return v === this.props.page
            })
        ) {
            app.views.main.router.navigate(ROUTES.patientlist, {
                reloadAll: true
            });
        } else if (
            this.state.subMenuBackList.find((v) => {
                return v === this.props.page
            })
        ) {
            app.views.main.router.back()
        }
    }

    render() {
        const { page } = this.props
        return (
            <>
                <div className="leftNav">
                    <div className="logo" >
                        <Link href="/appointments/" reloadAll onClick={this.menuLinks.bind(this)} >
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                    <ul>
                        {AuthGuard.CanMangeAppointments() || AuthGuard.CanViewAppointmentsUpcoming() || AuthGuard.CanViewHistoryVideoCallList() ?
                            <li>
                                <Link href="/appointments/" reloadCurrent onClick={this.menuLinks.bind(this)} className={`menu-link  ${page === ROUTES.appointments || page === ROUTES.consultationSummary || page === ROUTES.patientInfo ? "menu-link-active" : null}`} >
                                    {/* <span className="badge color-red">32</span> */}
                                    <img src={this.state.appointmentImgUrl} alt="" />
                                    <div>Video Call </div>
                                </Link>
                            </li>
                            : null}
                        {/* {AuthGuard.CanViewMonitoringPatients() ? */}
                        <li>
                            <Link href="/patientList/" reloadCurrent onClick={this.menuLinks.bind(this)} className={`menu-link  ${page === ROUTES.patientlist || page === ROUTES.patientdetail  || page === ROUTES.registerNewUser ? "menu-link-active" : null}`} >
                                {/* <span className="badge color-red">10</span> */}
                                <img src={this.state.patientListImgUrl} alt="" />
                                <div>Monitoring </div>
                            </Link>
                        </li>
                        {/* : null} */}
                        {/* {AuthGuard.CanSetting() ? */}
                        <li>
                            <Link href="/labReports/" reloadCurrent onClick={this.menuLinks.bind(this)} className={`menu-link  ${page === ROUTES.labReports ? "menu-link-active" : null}`} >
                                {/* <span className="badge color-red">10</span> */}
                                <img src={this.state.labRepImgUrl} alt="" />
                                <div>Lab Reports </div>
                            </Link>
                        </li>
                        <li>
                            <Link href="/reports/" reloadCurrent onClick={this.menuLinks.bind(this)} className={`menu-link  ${page === ROUTES.reports || page === ROUTES.reportsMenu ? "menu-link-active" : null}`} >
                                <img src={this.state.reportsUrl} alt="" />
                                <div>Reports </div>
                            </Link>
                        </li>
                        {/* : ''} */}
                        {/* {AuthGuard.CanSetting() || AuthGuard.CanEditSessionIdStatus() ? */}
                        {/* <li>
                            <Link href="/Sessionlist/" reloadCurrent onClick={this.menuLinks.bind(this)} className={`menu-link  ${page === ROUTES.sessionlist ? "menu-link-active" : null}`} >
                                <img src={this.state.sessionIDImgUrl} alt="" />
                                <div>Session ID</div>
                            </Link>
                        </li> */}
                        {/* : null} */}
                        {/* {AuthGuard.CanSetting() ? */}
                        <li>
                            <Link href="/Setting/" reloadCurrent onClick={this.menuLinks.bind(this)} className={`menu-link  ${page === ROUTES.setting || page === ROUTES.addAccountRole || page === ROUTES.addAccount  || page === ROUTES.addMedication ? "menu-link-active" : null}`} >
                                <img src={this.state.settingsImgUrl} alt="" />
                                <div>Setting </div>
                            </Link>
                        </li>
                        {/* : ''} */}
                        {/* {AuthGuard.CanSetting() ? */}
                        <li>
                            <Link href="https://www.ourheartvoice.com/faq-doctor-dashboard-telemed/" external target='_blank' >
                                <img src={this.state.helpImgUrl} alt="" />
                                <div>Help </div>
                            </Link>
                        </li>
                        {/* : ''} */}
                        <li>
                            <Link preventRouter popupOpen=".logout-popup">
                                <img src={this.state.logOutImgUrl} alt="" />
                                <div>Log Out </div>
                            </Link>
                        </li>
                    </ul>
                    <Popup className="logout-popup">
                        <Page onPageBeforeRemove={this.onPageBeforeRemove.bind(this)}>
                            <h3>Log out</h3>
                             Are you sure you want to log out?
                             <br /> <br /><br />
                            <div>
                                <p>
                                    <Button raised popupClose fill className="fill-blue-btn" onClick={this.onLogout.bind(this)}>Log out</Button>
                                </p>
                                <p>
                                    <Button popupClose raised className="outline-blue-btn" >Back</Button>
                                </p>
                            </div>
                        </Page>
                    </Popup>
                </div>
            </>
        );
    }
}
export default connect(
    '',
    { UserLogout }
)(SideBar);