import { FETCH_LOGIN, FETCH_OTPLOGIN ,FETCH_LOGOUT } from "../actionTypes";

const initalState = {
    userLoginData:{}
}

export default function (state = initalState, action) {
    switch (action.type) {        
        case FETCH_LOGIN:
            return {
                ...state,
                userLoginData: action.loginResult
            };

        case FETCH_OTPLOGIN:
            return {
                ...state,
                userLoginData: action.otpLoginResult
            };
        case FETCH_LOGOUT:
            return {
                userLoginData : {}
            };    
        default:
            return  state;
    }
}