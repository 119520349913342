import { apiBaseUrl, ApiUrl, XAPP, ShowErrorMsgCode, dontShowRequestTimedOutNotice, AutoRefreshTokenCode, APPENV } from '../commons/Constants';
import moment from 'moment';
import axios from 'axios'
import qs from 'qs'
import AuthGuard from '../services/AuthGuard'
import { f7 } from 'framework7-react'
import { ShowToast, set_local_cache, get_local_cache, remove_local_cache } from '../commons/Helpers';
import events from './events';
// const utcOffset = moment().utcOffset();


let fetch = axios.create({
    baseURL: apiBaseUrl,
    // timeout: 7 * 1000 // request timeout    
    timeout: 1 * 3600 * 1000, // request timeout   1 hour，
    retry: 2,
    retryDelay: 5000
})

let pending = [] //; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
// let cancelToken = axios.CancelToken;
let removePending = (ever) => {
    for (let p in pending) {
        // console.log(pending[p])
        if (pending[p].u === ever.url + '&' + ever.method) { //当当前请求在数组中存在时执行函数体
            pending[p].f(); //执行取消操作
            pending.splice(p, 1); //把这条记录从数组中移除
        }
    }
}

let dialogOpened = false
fetch.interceptors.request.use(
    async (config) => {
        let hostNmae = window.location.protocol + "//" + window.location.host; //
        let canAccess = await AuthGuard.checkIpWhiteList();
        if (!canAccess) {
            window.location.href = hostNmae + "/downtime.html";
            return;
        }
        AuthGuard.checkAuthenticated((isAuth) => {
            // console.log('isAuth:' + isAuth);
            if (isAuth) {
                // console.log(AuthGuard.isAuthenticated);
                config.headers["X-authorization"] = AuthGuard.isAuthenticated;
            } else {
                let { url } = config;
                if (
                    ![
                        ApiUrl.otpLogin,
                        ApiUrl.sendOtp,
                        ApiUrl.forgetPw,
                        ApiUrl.getNonceNum,
                        ApiUrl.verifyNonceNum,
                        ApiUrl.getForgetPWOTP,
                        ApiUrl.resetPassword,
                        ApiUrl.verifiFWOTP,
                        ApiUrl.getClinicInfo,
                        ApiUrl.loginSendEmail,
                        ApiUrl.getVideoCallInfo
                    ].includes(url)
                ) {
                    AuthGuard.setLogout();
                }

                // f7.views.main.router.refreshPage();
            }
        });

        if (config.method === "files") {
            config.method = "post";
            config.headers["Content-Type"] = "multipart/form-data";
        } else {
            if (
                config.method === "post" ||
                config.method === "put" ||
                config.method === "delete"
            ) {
                if (
                    typeof config.data !== "string" &&
                    config.headers["Content-Type"] !== "multipart/form-data"
                ) {
                    config.data = qs.stringify(config.data);
                }
            }
        }

        //------------------------
        removePending(config); //在一个ajax发送前执行一下取消操作
        // config.cancelToken = new cancelToken((c) => {
        // console.log(pending)
        // console.log(c)
        // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
        //     pending.push({ u: config.url + '&' + config.method, f: c });
        // });
        //---------------------------

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

fetch.interceptors.response.use(async data => {
    let { config, status } = data
    if (APPENV === 'LOCAL') {
        if (config.url.indexOf('ourheartvoice.com/nonc/get') !== -1) {
            remove_local_cache('dr_log')
        }
        let obj = {
            d: moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
            u: config.url,
            s: status
        }

        let logArry = []

        if (get_local_cache('dr_log')) {
            logArry = JSON.parse(get_local_cache('dr_log'))
        }
        logArry.unshift(obj)
        set_local_cache('dr_log', logArry)
    }

    dialogOpened = false
    // ------------------------------------------------------------------------------------------
    removePending(data.config);  //在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
    // -------------------------------------------------------------------------------------------
    return data
}, error => {
    f7.preloader.hide();
    // console.log(error.response);    
    let { config, code, message } = error
    let { url } = config
    console.log('errr')
    console.log(config)
    if (APPENV === 'LOCAL') {
        let obj = {
            d: moment().locale('zh-cn').format('YYYY-MM-DD HH:mm:ss'),
            u: url,
            s: error.response.status
        }

        let logArry = []
        if (get_local_cache('dr_log')) {
            logArry = JSON.parse(get_local_cache('dr_log'))
        }
        logArry.unshift(obj)
        set_local_cache('dr_log', logArry)
    }

    if (code === 'ECONNABORTED' || message.includes('timeout of')) { // 请求超时
        // console.log(config)
        if (dontShowRequestTimedOutNotice.find((v) => {
            return v === url
        })) {

        } else {
            f7.dialog.close();
            f7.dialog.alert('Oops ,Request timed out, please try again!', 'Warning!', () => {

            });
        }
        return Promise.reject(error.response.data)
    }

    if (error.response) {
        if (error.response.status === 401) {
            if (!dialogOpened) {
                events.emit('AutoLogout')
                f7.dialog.close();
                if (AuthGuard.listerNewUserComingTimer) {
                    clearInterval(AuthGuard.listerNewUserComingTimer);
                }
                f7.dialog.alert('Your login authentication is invalid. Please Log in again.', 'Warning!', () => {
                    AuthGuard.setLogout();
                });
                dialogOpened = true
            }
            // if (dontShowRequestTimedOutNotice.find((v) => {
            //     return v === url
            // })) {
            //     AuthGuard.setLogout();
            // } else {
            //     console.log(url)
            //     f7.dialog.close();
            //     // AuthGuard.setInvalidOauth()
            //     f7.dialog.alert('Your login authentication is invalid. Please Log in again.', 'Warning!', () => {
            //         AuthGuard.setLogout();
            //     });
            // }

        } else if (error.response.status === 500) {
            f7.dialog.close();
            ShowToast('Request failed, please try again')
            console.error('Server Error', url)
            // f7.dialog.alert('Opps! Server Error.', 'Alert', () => {
            //     // f7.dialog.destroy();
            // });
        } else if (error.response.status === 404 && error.response.data !== undefined && error.response.data.status_code !== undefined) {

            let existence = ShowErrorMsgCode.find((v) => {
                return v === error.response.data.status_code
            });
            if (existence) {

                if (config.url !== "account_service/account/coach/sendOtp" && error.response.data.status_code === 11016) {
                    f7.dialog.alert(error.response.data.message, 'Notice');
                }
            }
        } else if (error.response.status === 403) {
            if (!config || !config.retry) return Promise.reject(error);
            if (error.response.data.status_code !== 400) {
                f7.dialog.alert(error.response.data.message, 'Notice');
            }
        }
        // console.log(error.response);     
        return Promise.reject(error.response.data)
    } else {
        return Promise.reject(error)
    }
})

export const AuthRefresh = (config, error) => {
    console.log(config, error)
    config.__retryCount = config.__retryCount || 0;
    if (config.__retryCount >= config.retry) {
        // Reject with the error
        return Promise.reject(error);
    }
    config.__retryCount += 1;

    var backoff = new Promise(function (resolve) {
        setTimeout(function () {
            // console.log('后台默认排队中,请等待5秒')
            // Indicator.close();
            // Indicator.open("后台默认排队中,请等待5秒");
            resolve();
        }, config.retryDelay || 1);
    });

    console.log(dialogOpened)

    return backoff.then(function () {
        console.log(dialogOpened)
        if (dialogOpened) { return false }
        console.log(dialogOpened)
        AuthGuard.autoRefreshToken({}, (isok, res) => {
            config.headers["X-authorization"] = AuthGuard.isAuthenticated;
            if (isok) {
                AuthGuard.setValidOauth()
                return axios(config);
            } else {
                console.log(dialogOpened);
                if (!dialogOpened) {
                    events.emit('AutoLogout')
                    f7.dialog.close();
                    f7.dialog.alert(
                        "Your login authentication is invalid. Please Log in again.",
                        "Warning!",
                        () => {
                            AuthGuard.setLogout();
                        }
                    );
                    // AuthGuard.setInvalidOauth()
                }
                dialogOpened = true;
                return Promise.reject(error.response.data);
            }
        });

    });


}

export default class API {
    static AJAX({ url, data, method, headers, jsonp, statusCode, timeout }) {
        return fetch({
            headers: {
                ...headers,
                'X-app': XAPP,
                ...timeout
            },
            url: url,
            method: method,
            data
        })
    };

    static GET({ url, data, headers = {} }) {
        if (data) {
            url = `${url}?${f7.utils.serializeObject(data)}`;
        }
        return API.AJAX({ url, data, method: 'GET', headers });
    }

    static POST({ url, data, headers = {} }) {
        if (dontShowRequestTimedOutNotice.find((v) => {
            return v === url
        })) {
            return API.AJAX({ url, data, method: 'POST', headers, timeout: { timeout: 2.5 } });

        } else {
            return API.AJAX({ url, data, method: 'POST', headers });
        }
    }

    static FILES({ url, data, headers = {} }) {
        return API.AJAX({ url, data, method: "FILES", headers });
    }

    static DELETE({ url, data }) {
        return API.AJAX({ url, data, method: 'DELETE' });
    }

    login({ data }) {
        return API.POST({
            url: ApiUrl.login,
            data
        });
    }

    logout() {
        return API.GET({
            url: ApiUrl.logout
        });
    }

    sessionList({ data }) {
        return API.POST({
            url: ApiUrl.sessionList,
            data
        });
    }

    redeemSessionStatus({ data }) {
        return API.POST({
            url: ApiUrl.redeemSessionStatus,
            data
        });
    }

    patientList({ data }) {
        return API.GET({
            url: ApiUrl.patientList,
            data
        });
    }

    chatList({ data }) {
        return API.GET({
            url: ApiUrl.chatList,
            data
        });
    }

    getPatientDetail({ data }) {
        return API.POST({
            url: ApiUrl.getPaitentDetail,
            data
        });
    }

    createDialog({ data }) {
        return API.POST({
            url: ApiUrl.createChatDialog,
            data
        });
    }

    getMsgList({ data }) {
        return API.POST({
            url: ApiUrl.getMessageList,
            data
        });
    }

    sendMsg({ data }) {
        return API.POST({
            url: ApiUrl.sendMessage,
            data
        });
    }

    getProfileImage({ data }) {
        return API.POST({
            url: ApiUrl.getProfileImage,
            data
        });
    }
    updateGoalBP({ data }) {
        return API.POST({
            url: ApiUrl.updateGoalBP,
            data
        });
    }
    updateUserInfo({ data }) {
        return API.POST({
            url: ApiUrl.updateUserInfo,
            data
        });
    }

    getNotificationList({ data }) {
        return API.GET({
            url: ApiUrl.getNotiList,
            data
        });
    }

    getGraphData({ data }) {
        return API.POST({
            url: ApiUrl.getGraph,
            data
        });
    }

    pushNotifi({ data }) {
        return API.POST({
            url: ApiUrl.pushNotificationToUserApp,
            data
        });
    }

    pushNotifiCall({ data }) {
        return API.POST({
            url: ApiUrl.pushNotificationCall,
            data
        });
    }

    forgetPassword({ data }) {
        return API.POST({
            url: ApiUrl.forgetPw,
            data
        });
    }

    resetPw({ data }) {
        return API.POST({
            url: ApiUrl.resetPw,
            data
        });
    }

    getClinicInfo({ data }) {
        return API.POST({
            url: ApiUrl.getClinicInfo,
            data
        });
    }

    setClinicInfo({ data }) {
        return API.POST({
            url: ApiUrl.setClinicInfo,
            data
        });
    }

    getGoalInfo({ data }) {
        return API.GET({
            url: ApiUrl.getGoalInfo,
            data
        });
    }

    setGoalUpdate({ data }) {
        return API.POST({
            url: ApiUrl.setGoalUpdate,
            data
        });
    }

    getLimitInfo({ data }) {
        return API.GET({
            url: ApiUrl.getLimitInfo,
            data
        });
    }

    setLimitUpdate({ data }) {
        return API.POST({
            url: ApiUrl.setLimitUpdate,
            data
        });
    }

    addDataInfo({ data }) {
        return API.POST({
            url: ApiUrl.addDataInfo,
            data
        });
    }

    tmpertureHisotry({ data }) {
        return API.GET({
            url: ApiUrl.tmpertureHisotry,
            data
        });
    }

    spo2History({ data }) {
        return API.GET({
            url: ApiUrl.spo2History,
            data
        });
    }

    getVideoCall({ data }) {
        return API.POST({
            url: ApiUrl.getVideoCall,
            data
        });
    }

    getCurAppointmentInfo({ data }) {
        return API.POST({
            url: ApiUrl.getCurAppointmentInfo,
            data
        });
    }

    cancelVideoCall({ data }) {
        return API.POST({
            url: ApiUrl.cancelVideoCall,
            data
        });
    }

    getVideoCallInfo({ data }) {
        return API.POST({
            url: ApiUrl.getVideoCallInfo,
            data
        });
    }

    drStartCall({ data }) {
        return API.POST({
            url: ApiUrl.drStartCall,
            data
        });
    }

    readyCall({ data }) {
        return API.POST({
            url: ApiUrl.readyCall,
            data
        });
    }

    createBill({ data }) {
        return API.POST({
            url: ApiUrl.createBill,
            data
        });
    }

    tellPatientIncall({ data }) {
        return API.POST({
            url: ApiUrl.tellPatientIncall,
            data
        });
    }

    drEndcall({ data }) {
        return API.POST({
            url: ApiUrl.endCall,
            data
        });
    }

    drCloseVideo({ data }) {
        return API.POST({
            url: ApiUrl.drCloseVideo,
            data
        });
    }

    changeStatus({ data }) {
        return API.POST({
            url: ApiUrl.onlineOffline,
            data
        });
    }

    getUpcomingConsultationsCount({ data }) {
        return API.POST({
            url: ApiUrl.getUpcomingConsultationsCount,
            data
        });
    }

    getCoachByID({ data }) {
        return API.POST({
            url: ApiUrl.getCoachByID,
            data
        });
    }

    sendOtp({ data }) {
        return API.POST({
            url: ApiUrl.sendOtp,
            data
        });
    }

    verifyOtp({ data }) {
        return API.POST({
            url: ApiUrl.verifyOtp,
            data
        });
    }

    otpLogin({ data }) {
        return API.POST({
            url: ApiUrl.otpLogin,
            data
        });
    }

    getMeasuredataHistory({ data }) {
        return API.POST({
            url: ApiUrl.getMeasuredataHistory,
            data
        });
    }

    delPdf({ data }) {
        return API.POST({
            url: ApiUrl.delPdf,
            data
        });
    }

    roleFunctionList({ data }) {
        return API.POST({
            url: ApiUrl.roleFunctionList,
            data
        });
    }

    saveClinicRole({ data }) {
        return API.POST({
            url: ApiUrl.saveClinicRole,
            data
        });
    }

    getClinicRoleList({ data }) {
        return API.POST({
            url: ApiUrl.getClinicRoleList,
            data
        });
    }

    getClinicRoleDetail({ data }) {
        return API.POST({
            url: ApiUrl.getClinicRoleDetail,
            data
        });
    }

    getStaffList({ data }) {
        return API.POST({
            url: ApiUrl.getStaffList,
            data
        });
    }

    saveStaff({ data }) {
        return API.POST({
            url: ApiUrl.saveStaff,
            data
        });
    }

    getStaffDetail({ data }) {
        return API.POST({
            url: ApiUrl.getStaffDetail,
            data
        });
    }

    addTimeslot({ data }) {
        return API.POST({
            url: ApiUrl.addTimeslot,
            data
        });
    }

    getTimeslot({ data }) {
        return API.POST({
            url: ApiUrl.getTimeslot,
            data
        });
    }

    updateTimeslot({ data }) {
        return API.POST({
            url: ApiUrl.updateTimeslot,
            data
        });
    }

    getAvaliableDate({ data }) {
        return API.POST({
            url: ApiUrl.getAvaliableDate,
            data
        });
    }

    queneCofirm({ data }) {
        return API.POST({
            url: ApiUrl.queneCofirm,
            data
        });
    }

    rescheduleConfirm({ data }) {
        return API.POST({
            url: ApiUrl.rescheduleConfirm,
            data
        });
    }

    getStepsHistory({ data }) {
        return API.POST({
            url: ApiUrl.getStepsHistory,
            data
        });
    }

    getSleepHistory({ data }) {
        return API.POST({
            url: ApiUrl.getSleepHistory,
            data
        });
    }

    getBloodGlucoseHistory({ data }) {
        return API.POST({
            url: ApiUrl.getBloodGlucoseHistory,
            data
        });
    }

    autoRefreshToken({ data }) {
        return API.POST({
            url: ApiUrl.autoRefreshToken,
            data
        });
    }

    getNonceNum({ data }) {
        return API.POST({
            url: ApiUrl.getNonceNum,
            data
        });
    }

    verifyNonceNum({ data }) {
        return API.POST({
            url: ApiUrl.verifyNonceNum,
            data
        });
    }

    saveconsultationSummary({ data }) {
        return API.POST({
            url: ApiUrl.saveconsultationSummary,
            data
        });
    }

    userFullInfo({ data }) {
        return API.POST({
            url: ApiUrl.userFullInfo,
            data
        });
    }

    getQBLoinPw({ data }) {
        return API.POST({
            url: ApiUrl.getQBLoinPw,
            data
        });
    }

    saveNotes({ data }) {
        return API.POST({
            url: ApiUrl.saveNotes,
            data
        });
    }

    getNotes({ data }) {
        return API.POST({
            url: ApiUrl.getNotes,
            data
        });
    }

    appointmentSchedule({ data }) {
        return API.POST({
            url: ApiUrl.appointmentSchedule,
            data
        });
    }

    newUserComing({ data }) {
        return API.POST({
            url: ApiUrl.newUserComing,
            data
        });
    }

    getDrlist({ data }) {
        return API.POST({
            url: ApiUrl.getDrlist,
            data
        });
    }

    findClinicInfoByClinicid({ data }) {
        return API.POST({
            url: ApiUrl.findClinicInfoByClinicid,
            data
        });
    }

    handlyDr({ data }) {
        return API.POST({
            url: ApiUrl.handlyDr,
            data
        });
    }

    getForgetPWOTP({ data }) {
        return API.POST({
            url: ApiUrl.getForgetPWOTP,
            data
        });
    }

    resetPassword({ data }) {
        return API.POST({
            url: ApiUrl.resetPassword,
            data
        });
    }
    verifiFWOTP({ data }) {
        return API.POST({
            url: ApiUrl.verifiFWOTP,
            data
        });
    }

    getCoachProfile({ data }) {
        return API.POST({
            url: ApiUrl.getCoachProfile,
            data
        });
    }

    saveCoachProfile({ data }) {
        return API.POST({
            url: ApiUrl.saveCoachProfile,
            data
        });
    }

    getClinicProfile({ data }) {
        return API.POST({
            url: ApiUrl.getClinicProfile,
            data
        });
    }

    clinicSettingEdit({ data }) {
        return API.POST({
            url: ApiUrl.clinicSettingEdit,
            data
        });
    }

    uploadBase64Img({ data }) {
        return API.POST({
            url: ApiUrl.uploadBase64Img,
            data
        });
    }

    uploadBase64({ data }) {
        return API.POST({
            url: ApiUrl.uploadBase64,
            data
        });
    }

    generateWhatsappQr(code) {
        return apiBaseUrl + ApiUrl.generateWhatsappQr + '?code_name=' + code;
    }

    delClinicRole({ data }) {
        return API.POST({
            url: ApiUrl.delClinicRole,
            data
        });
    }

    getCountryList({ data }) {
        return API.GET({
            url: ApiUrl.countryList,
            data
        });
    }

    getProvinceList({ data }) {
        return API.GET({
            url: ApiUrl.provinceList,
            data
        });
    }

    getCityList({ data }) {
        return API.GET({
            url: ApiUrl.cityList,
            data
        });
    }

    // getLatLong({ data }) {
    //     return API.GET({
    //         url: ApiUrl.getAddressLatLong,
    //         data
    //     });
    // }

    addProvider({ data }) {
        return API.POST({
            url: ApiUrl.addProvider,
            data
        });
    }

    merchantDetail({ data }) {
        return API.POST({
            url: ApiUrl.merchantDetail,
            data
        });
    }

    checkoutInfo({ data }) {
        return API.POST({
            url: ApiUrl.checkoutInfo,
            data
        })
    }

    // addDoctor({ data }) {
    //     return API.POST({
    //         url: ApiUrl.addDoctor,
    //         data
    //     });
    // }

    saveGeneral({ data }) {
        return API.POST({
            url: ApiUrl.saveGeneral,
            data
        });
    }

    getGeneralTimer({ data }) {
        return API.POST({
            url: ApiUrl.getGeneralTimer,
            data
        });
    }

    getLogouTime({ data }) {
        return API.POST({
            url: ApiUrl.getLogouTime,
            data
        });
    }

    reportsSettlementList({ data }) {
        return API.POST({
            url: ApiUrl.reportsSettlementList,
            data
        });
    }

    reportsSubsidies({ data }) {
        return API.POST({
            url: ApiUrl.reportsSubsidies,
            data
        });
    }

    reportsDispensary({ data }) {
        return API.POST({
            url: ApiUrl.reportsDispensary,
            data
        });
    }

    reportsSubExport({ data }) {
        return API.POST({
            url: ApiUrl.reportsSubExport,
            data
        });
    }

    reportsDisExport({ data }) {
        return API.POST({
            url: ApiUrl.reportsDisExport,
            data
        });
    }

    reportsExport({ data }) {
        return API.POST({
            url: ApiUrl.reportsExport,
            data
        });
    }

    delStaff({ data }) {
        return API.POST({
            url: ApiUrl.delStaff,
            data
        });
    }

    getMedication({ data }) {
        return API.POST({
            url: ApiUrl.getMedication,
            data
        });
    }

    saveMedication({ data }) {
        return API.POST({
            url: ApiUrl.saveMedication,
            data
        });
    }

    editMedication({ data }) {
        return API.POST({
            url: ApiUrl.editMedication,
            data
        });
    }

    getMedicationDetail({ data }) {
        return API.GET({
            url: ApiUrl.getMedicationDetail,
            data
        });
    }

    delMedication({ data }) {
        return API.POST({
            url: ApiUrl.delMedication,
            data
        });
    }

    getMedicationCsvUrl({ data }) {
        return API.POST({
            url: ApiUrl.getMedicationCsvUrl,
            data
        });
    }

    uploadMedicationCsv({ data }) {
        return API.POST({
            url: ApiUrl.uploadMedicationCsv,
            data
        });
    }

    findProfileForStaff({ data }) {
        return API.POST({
            url: ApiUrl.findProfileForStaff,
            data
        });
    }

    changeCoachPwd({ data }) {
        return API.POST({
            url: ApiUrl.changeCoachPwd,
            data
        });
    }

    listenerHeartbeat({ data }) {
        return API.POST({
            url: ApiUrl.listenerHeartbeat,
            data
        });
    }

    mcUrl({ data }) {
        return API.POST({
            url: ApiUrl.mcUrl,
            data
        });
    }

    queryMc({ data }) {
        return API.POST({
            url: ApiUrl.queryMc,
            data
        });
    }

    saveMc({ data }) {
        return API.POST({
            url: ApiUrl.saveMc,
            data
        });
    }

    queryPaymentList({ data }) {
        return API.POST({
            url: ApiUrl.queryPaymentList,
            data
        });
    }

    findProgramByUserIdAndProgramType({ data }) {
        return API.POST({
            url: ApiUrl.findProgramByUserIdAndProgramType,
            data
        });
    }

    addProgramsByUserId({ data }) {
        return API.POST({
            url: ApiUrl.addProgramsByUserId,
            data
        });
    }

    findProgramByMerchantId({ data }) {
        return API.POST({
            url: ApiUrl.findProgramByMerchantId,
            data
        });
    }

    googleConnect() {
        return API.GET({
            url: ApiUrl.googleConnect,
        })
    }

    googleConnectInfo() {
        return API.GET({
            url: ApiUrl.googleConnectInfo,
        })
    }

    calendarSyncInfo() {
        return API.GET({
            url: ApiUrl.calendarSyncInfo,
        })
    }

    calendarSyncOn({ data }) {
        return API.POST({
            url: ApiUrl.calendarSyncOn,
            data
        });
    }

    calendarSyncOff({ data }) {
        return API.POST({
            url: ApiUrl.calendarSyncOff,
            data
        });
    }

    calendarSyncUpdate({ data }) {
        return API.POST({
            url: ApiUrl.calendarSyncUpdate,
            data
        });
    }

    addUser({ data }) {
        return API.POST({
            url: ApiUrl.addUser,
            data
        });
    }

    saveAppointmentDraft({ data }) {
        return API.POST({
            url: ApiUrl.saveAppointmentDraft,
            data
        });
    }

    diagnosisSearch({ data }) {
        return API.POST({
            url: ApiUrl.diagnosisSearch,
            data
        });
    }

    findMedicationAll({ data }) {
        return API.POST({
            url: ApiUrl.findMedicationAll,
            data
        });
    }

    mcDelete({ data }) {
        return API.POST({
            url: ApiUrl.mcDelete,
            data
        });
    }

    mcUpdate({ data }) {
        return API.POST({
            url: ApiUrl.mcUpdate,
            data
        });
    }

    AGTken({ data }) {
        return API.POST({
            url: ApiUrl.AGTken,
            data
        });
    }

    nationalityList({ data }) {
        return API.GET({
            url: ApiUrl.nationalityList,
            data
        });
    }
    saveAppointment({ data }) {
        return API.POST({
            url: ApiUrl.saveAppointment,
            data
        });
    }
    dateTimefilter({ data }) {
        return API.POST({
            url: ApiUrl.dateTimefilter,
            data
        });
    }
    uploadDrAvatar({ data }) {
        console.log(data)
        return API.FILES({
            url: ApiUrl.uploadDrAvatar,
            data,
        });
    }

    uploadTwilioLog({ data }) {
        return API.FILES({
            url: ApiUrl.uploadTwilioLog,
            data,
        });
    }

    logoutTimeSetting({ data }) {
        console.log(data)
        return API.POST({
            url: ApiUrl.logoutTimeSetting,
            data,
        });
    }

    getUserLogoutTime({ data }) {
        return API.POST({
            url: ApiUrl.getUserLogoutTime,
            data
        });
    }

    getGeneralForCoach({ data }) {
        return API.POST({
            url: ApiUrl.getGeneralForCoach,
            data
        });
    }
    checkNotificationMobile({ data }) {
        return API.POST({
            url: ApiUrl.checkNotificationMobile,
            data
        });
    }
    sendSmsOtpDirect({ data }) {
        return API.POST({
            url: ApiUrl.sendSmsOtpDirect,
            data
        });
    }

    verifyCoachMobile({ data }) {
        return API.POST({
            url: ApiUrl.verifyCoachMobile,
            data
        });
    }

    updateMobileForCoach({ data }) {
        return API.POST({
            url: ApiUrl.updateMobileForCoach,
            data
        });
    }

    notificationDeleteById({ data }) {
        return API.POST({
            url: ApiUrl.notificationDeleteById,
            data
        });
    }

    getLabReports({ data }) {
        return API.POST({
            url: ApiUrl.getLabReports,
            data
        });
    }

    updateLabReports({ data }) {
        return API.POST({
            url: ApiUrl.updateLabReports,
            data
        });
    }

    uploadLabReportsCheck({ data }) {
        return API.POST({
            url: ApiUrl.uploadLabReportsCheck,
            data
        });
    }

    updateLabReportsStatusCheck({ data }) {
        return API.POST({
            url: ApiUrl.updateLabReportsStatusCheck,
            data
        });
    }

    removeJoinedProgram({ data }) {
        return API.POST({
            url: ApiUrl.removeJoinedProgram,
            data
        });
    }

    generateE3Token({ data }) {
        return API.POST({
            url: ApiUrl.generateE3Token,
            data
        });
    }

    generateE3Salt({ data }) {
        return API.POST({
            url: ApiUrl.generateE3Salt,
            data
        });
    }

    loginSendEmail({ data }) {
        return API.POST({
            url: ApiUrl.loginSendEmail,
            data
        });
    }
    getAllDrInfo({ data }) {
        return API.POST({
            url: ApiUrl.getAllDrInfo,
            data
        });
    }
    chatLog({ data }) {
        return API.POST({
            url: ApiUrl.chatLog,
            data
        });
    }
    reportAddFile({ data }) {
        return API.POST({
            url: ApiUrl.reportAddFile,
            data
        });
    }

    getLoginUserInfo({ data }) {
        console.log(data)
        return API.POST({
            url: ApiUrl.getLoginUserInfo,
            headers: {
                'X-authorization': data.token
            }
        });
    }

    getReportMedicationNameList({ data }) {
        return API.POST({
            url: ApiUrl.getReportMedicationNameList,
            data
        });
    }

    advanceSearch({ data }) {
        return API.POST({
            url: ApiUrl.advanceSearch,
            data
        });
    }

    getPatientDateList({ data }) {
        return API.POST({
            url: ApiUrl.getPatientDateList,
            data
        });
    }

    getMeasurementsDetail({ data }) {
        return API.POST({
            url: ApiUrl.getMeasurementsDetail,
            data
        });
    }
    
    saveMeasurementsDetail({ data }) {
        return API.POST({
            url: ApiUrl.saveMeasurementsDetail,
            data
        });
    }

    generateMeasurementsReport({ data }) {
        return API.POST({
            url: ApiUrl.generateMeasurementsReport,
            data
        });
    }
}