export const ErrorCode = {
  GroupError: "GroupError",
  GroupTicketAlreadyExistsError: "GroupTicketAlreadyExistsError",
  MissingPrivateKeyError: "MissingPrivateKeyError",
  IdentityAlreadyExistsError: "IdentityAlreadyExistsError",
  PrivateKeyAlreadyExistsError: "PrivateKeyAlreadyExistsError",
  PrivateKeyNoBackupError: "PrivateKeyNoBackupError",
  VirgilCryptoError: "VirgilCryptoError",
  UsersNotFoundError: "UsersNotFoundError",
  UsersFoundWithMultipleCardsError: "UsersFoundWithMultipleCardsError",
  CloudEntryExistsError: "CloudEntryExistsError",
  MultipleCardsError: "MultipleCardsError",
  GroupIdNotFoundError: "Group with given id and initiator could not be found",
  UserNotAcceptError: "Current user has no access to the group ticket",
  UserJWTExpired: "JWT is expired",
};